import * as React from 'react';
import { arrayToClass } from '../../../../utilities/helper-fuctions';
import { InputWrapper } from './input-wrapper';

export class Input extends InputWrapper<any, any> {
	public renderInput(): any {
		const classes = arrayToClass([
			'w-100',
			this.props.inputClasses ? this.props.inputClasses : '',
			this.props.disabled
				? 'palette--bc-neutral-2 palette--bgc-neutral-2'
				: 'palette--bc-neutral-4 palette--bgc-neutral-1',
		]);

		return (
			<input
				type='text'
				placeholder={this.props?.placeholder || ''}
				autoFocus={this.props.autoFocus || false}
				value={this.state.value}
				onChange={(event) => this.handleChange(event)}
				disabled={this.props.disabled || false}
				className={classes}
				onBlur={(e) => this.props.onBlur && this.props.onBlur(e)}
				onFocus={(e) => this.props.onFocus && this.props.onFocus(e)}
				{...this.props?.extras}
			/>
		);
	}
}
