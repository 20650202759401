import { get } from 'lodash';
import * as React from 'react';
import { connectNLP } from '../../../../utilities/connect-navigate';
import { getQuery, replaceQuery } from '../../../../utilities/helper-fuctions';
import { Pagination } from '../pagination';
import { PerPage } from '../per-page-selector/per-page';

class PaginationFooterComponent extends React.Component<any, any> {
    public render(): React.ReactElement {
        const total = get(this.props, 'total');
        const totalParse = total && total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

        return (
            <div className="w-100 border-top-1 palette--bc-neutral-3 p-2 palette--bgc-neutral-1">
                <div className="display-flex align-items-center">
                    <div className="display-flex align-items-center flex-fill">
                        <PerPage />
                        <p className="m-0 palette--c-neutral-5 ml-2">item / page</p>
                    </div>
                    <p className="mb-0 palette--c-neutral-5">{totalParse} result</p>
                </div>
                <div className="display-flex justify-content-center w-100">{this.renderPagination(total)}</div>
            </div>
        );
    }

    private renderPagination(total): any {
        if (total === 0) {
            return null;
        }

        const query: any = getQuery();
        const page = parseFloat(query?.page || 0) + 1;
        const size = query?.size || 10;

        if (total / size < page && page > 1 && !query?.page) {
            this.handlePaginate(page - 1);
        }

        return (
            <div className="PaginationContainer w-100 display-flex justify-content-center my-3">
                <Pagination
                    className="Pagination"
                    current={page}
                    size={size}
                    total={total}
                    onClick={(number: any) => this.handlePaginate(number)}
                />
            </div>
        );
    }

    private handlePaginate(number: number) {
        const newQuery = replaceQuery({
            page: number - 1,
        });

        this.props?.router?.navigate({ search: newQuery });
    }
}

export const PaginationFooter: any = connectNLP(PaginationFooterComponent);
