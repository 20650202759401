import * as React from 'react';
import { connectNLP } from '../../../../utilities/connect-navigate';
import { debounce, getQuery, replaceQuery } from '../../../../utilities/helper-fuctions';
import { Form } from '../../form/form';
import { formatOptions } from '../../inputs/select/formatter';
import { Select } from '../../inputs/select/select';
import './per-page.scss';

const defaultOptions: any = [{ value: '10' }, { value: '50' }, { value: '100' }];

class PerPageComponent extends React.Component<any, any> {
    public form = new Form({
        size: {
            value: getQuery().size || formatOptions(defaultOptions)[0].value,
            trigger: () => debounce(() => this.handleSizeChange())(),
        },
    });

    public handleSizeChange() {
        const size = this.form.getValue('size');

        if (size !== this.previousPage) {
            const newQuery = replaceQuery({ size: size, page: 0 });
            this.props?.router?.navigate({ search: newQuery });
            this.previousPage = size;
        }
    }

    public previousPage = 10;

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps?.location?.search !== this.props?.location?.search) {
            this.form.setValue('size', getQuery().size || formatOptions(defaultOptions)[0].value);
        }
    }

    public render(): React.ReactElement {
        return (
            <form>
                <Select
                    field={this.form.getField('size')}
                    classes="SelectSizeComponent"
                    options={formatOptions(defaultOptions, false)}
                />
            </form>
        );
    }
}

export const PerPage: any = connectNLP(PerPageComponent);
