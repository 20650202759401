import * as React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { arrayToClass, debounce } from '../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../utilities/request';
import { validate, validationErrors } from '../../../../utilities/validators';
import { Label } from '../label/label';
import './inputs.scss';

export class ValidationInput extends React.Component<any, any> {
	public state = {
		error: false,
		[this.props?.type]: this.props?.field?.newValue || '',
		country: (this.props.country || '').toLowerCase(),
	};

	public componentDidUpdate(prevProps) {
		if (this.props.country !== prevProps.country && !!this.props.country) {
			this.setState({ country: this.props.country.toLowerCase() });
		}
	}

	public render() {
		const type = this.props?.type;
		const value: any = this.state?.[type];
		const label = this.props?.labelInfos;
		const isPhone = type === 'phone' || type === 'fax';

		const wrapperClassName = arrayToClass([
			this.props.classes ? this.props.classes : '',
			`${isPhone ? 'PhoneNumber' : 'Validation'}Input w-100`,
		]);

		const classes = arrayToClass([
			this.state.error ? 'red' : 'neutral',
			'w-100',
			this.props.inputClasses ? this.props.inputClasses : '',
		]);

		return (
			<div className={wrapperClassName}>
				{!!label && (
					<Label
						info={label.info || false}
						label={label.label || false}
						className={`${label.classes} mb-2`}
						ignoreTopMargin={label.ignoreTopMargin || false}
					/>
				)}
				{isPhone && (
					<PhoneInput
						disabled={this.props?.disabled || false}
						value={value}
						onChange={(event) =>
							this.handleInputChange(event, type)
						}
						onFocus={() => this.setState({ error: false })}
						onBlur={() => this.validate(type)}
						international={true}
						numberInputProps={{ className: classes }}
					/>
				)}
				{!isPhone && (
					<input
						disabled={this.props?.disabled || false}
						placeholder={this.props?.placeholder || ''}
						value={value}
						className={classes}
						onBlur={() => this.validate(type)}
						onFocus={() => this.setState({ error: false })}
						onChange={(event) =>
							this.handleInputChange(event?.target?.value, type)
						}
					/>
				)}
				{this.state?.error && (
					<div className='position-relative'>
						<span className='ErrorMessage pt-1 palette--c-red-2'>
							{validationErrors[type]}
						</span>
					</div>
				)}
			</div>
		);
	}

	public validate(type) {
		let value = this.state?.[type];

		if (!value) {
			this.props.field.setValue(value);
			return;
		}

		const isError = value ? !validate(value, type) : false;

		if (this.props.onError && !!isError) {
			this.props.onError();
		} else if (this.props.onValid && !isError) {
			this.props.onValid();
		}

		this.setState({ error: isError });
		this.props.field.setValue(value);
	}

	public handleInputChange(newValue, type) {
		this.setState(
			{ [type]: newValue },
			debounce(() => this.validate(type), 1000)
		);
	}

	public async getCountry(c) {
		try {
			const response = await RequestManager.get(
				'/free/countries/search',
				{ query: c.name || '', size: 20 }
			);

			if (!response) {
				throw new Error('Network error');
			}

			const country = response.content.find(
				(country) => c.countryCode.toUpperCase() === country.iso2
			);

			this.props.setCountry(country);
		} catch (err) {
			//silence is golden;
		}
	}
}
