import { configureStore } from '@reduxjs/toolkit';
import { configReducer } from './reducers/config.reducer';
import { detailsReducer } from './reducers/details.reducer';
import { localstorageReducer } from './reducers/localstorage.reducer';
import { measurementsReducer } from './reducers/measurements.reducer';
import { userDetailsReducer } from './reducers/user-details.reducer';
import { userReducer } from './reducers/user.reducer';

export const reducers = {
    config: configReducer,
    details: detailsReducer,
    measures: measurementsReducer,
    user: userReducer,
    localStorage: localstorageReducer,
    userDetails: userDetailsReducer,
};

export const reduxStore = configureStore({ reducer: { ...reducers } });
