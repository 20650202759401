import { get, uniq } from 'lodash';
import { stringify } from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { AirportCard } from '../../components/cards/airport/airport.card';
import { Footer } from '../../components/footer/footer';
import { MainHeaderButtons } from '../../components/header/main-header-buttons/main-header-buttons';
import { ToastMessages } from '../../components/notifications/toast-messages';
import { Form } from '../../components/ui-components/form/form';
import { HoverableButton } from '../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { TextInput } from '../../components/ui-components/inputs/inputs/text-input';
import { Pagination } from '../../components/ui-components/pagination/pagination';
import { PerPage } from '../../components/ui-components/pagination/per-page-selector/per-page';
import { Spinner } from '../../components/ui-components/spinner/spinner';
import { GeneralDataForm } from '../../components/widgets/airport/general-data/general-data-form';
import { SearchLayout } from '../../layouts/search/search.layout';
import {
	arrayToClass,
	debounce,
	getQuery,
	isInQuery,
	redirectToLogin,
	replaceQuery,
} from '../../utilities/helper-fuctions';
import { LocalStorage } from '../../utilities/local-storage';
import { RequestManager } from '../../utilities/request';
import { logoLink } from '../../utilities/variables';
import './search.screen.scss';

const mapStateProps = (store: any) => ({
	search: store.localStorage.search,
	config: store.config.routeConfig,
	runway: store.userDetails.runway,
	heliports: store.userDetails.heliports,
	h24: store.userDetails.h24,
});

const mapDispatchProps = (dispatch: any) => ({});

class SearchScreenComponent extends React.Component<any, any> {
	public form = new Form({
		query: {
			value: getQuery().query,
			trigger: () => debounce(() => this.handleQueryChange())(),
		},
	});

	public state = {
		loading: false,
		error: false,
		items: [],
		total: 0,
		page: 0,
		weathers: false,
		isScrolled: false,
	};

	constructor(props) {
		super(props);

		document.title = 'aviowiki';
	}

	public handleQueryChange() {
		const value = this.form.getValue('query');

		if (value !== getQuery().query) {
			this.triggerSearch(value);
		}

		const newQuery = replaceQuery({ query: value, page: 0 });
		this.props?.router?.navigate({ search: newQuery });
	}

	public async componentDidMount(): Promise<void> {
		this.triggerSearch();

		const doc: any = document.getElementById('Scrollable');
		doc.addEventListener('scroll', (e) => {
			const scrollTop = e.target.scrollTop;

			if (scrollTop < 110 && this.state.isScrolled) {
				this.setState({ isScrolled: false });
			} else if (!this.state.isScrolled && scrollTop > 110) {
				this.setState({ isScrolled: true });
			}
		});
	}

	public async triggerSearch(query: any = null) {
		const currentQueries: any = getQuery();
		try {
			this.setState({ loading: true });
			const queries = {
				size: currentQueries.size || 10,
				page: currentQueries || 0,
				query: query || currentQueries.query || null,
			};

			const response = await RequestManager.get(
				'/airports/search?includeArchived=true',
				queries
			);

			if (!response || !response.content) {
				throw new Error('there was no data');
			}

			const newItems = get(response, 'content', []).filter(
				(item) => item.aid !== this.props.aid
			);

			this.setState({
				items: newItems,
				total: get(response, 'page.totalResults'),
			});

			this.fetchWeather(newItems || []);
		} catch (err) {
			this.setState({ error: get(err, 'response.data.message') });
		} finally {
			this.setState({ loading: false });
		}
	}

	public async fetchWeather(items): Promise<void> {
		const ids = items.map((item: any) => `${item.aid}`).join(',');
		try {
			const response = await RequestManager.get('/weather', { aid: ids });

			if (!response) {
				throw new Error('There is no weather');
			}

			this.setState({ weathers: response });
		} catch (err) {
			this.setState({
				error:
					get(err, 'response.data.message', null) || err || 'Error',
			});
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			redirectToLogin(err);
		}
	}

	public render(): React.ReactElement {
		return (
			<div className='SearchScreen w-100'>
				{this.renderHeader()}
				<div className='Margin' />
				<div
					className='AllAirportsContentContainer display-flex flex-column align-items-center'
					id='Scrollable'
				>
					{this.renderAirportslist()}
					{!this.state.loading &&
						!this.state.error &&
						this.renderFooter()}
					<Footer isLanding={true} />
				</div>
				{this.props.modalIdentifier === 'create_new_airport' && (
					<GeneralDataForm
						isEdit={false}
						updateName={this.form.getValue('query')}
						onClose={() => this.handleModalToggle(null)}
					/>
				)}
			</div>
		);
	}

	public renderHeader(): React.ReactElement {
		const classes = arrayToClass([
			'Scroll w-100 display-flex flex-column align-items-center p-3 position-fixed',
			this.state.isScrolled ? 'White elevation-1' : 'Grey',
		]);

		return (
			<div className={classes}>
				<div className='w-100 display-flex align-items-center'>
					<div
						className='ImageContainer display-flex align-items-center w-100'
						onClick={() => this.navigate('/')}
					>
						<img
							src={logoLink}
							alt='aviowiki'
							className='AvioLogo mr-2'
						/>
					</div>

					<div className='ButtonContainer display-flex justify-content-end align-items-center'>
						<MainHeaderButtons isSearch={true} />
					</div>
				</div>
				{this.renderSearch()}
			</div>
		);
	}

	public renderSearch(): React.ReactElement {
		return (
			<div className='w-100'>
				<TextInput
					field={this.form.getField('query')}
					classes='SearchInputContainer py-2'
					inputClasses='SearchInput'
					textType='camel'
					placeholder='Start typing Location, ICAO or IATA'
				/>
			</div>
		);
	}

	public renderNotFound(): React.ReactElement {
		return (
			<div className='border-radius-1 palette--bgc-neutral-1 w-100 p-6 display-flex flex-column justify-content-center align-items-center'>
				<HoverableButton
					colorType='avio-green'
					titleLeft='Help us and create airport'
					icon='arrow_forward'
					className='border-radius-1'
					onClick={() => this.handleModalToggle('create_new_airport')}
				/>
			</div>
		);
	}

	public handleModalToggle(identifier): void {
		if (this.props.setIdentifier) {
			this.props.setIdentifier(identifier);
		}
	}

	public renderAirportslist(): React.ReactElement {
		const allAirport = get(this.state, 'items', []);

		const classes = arrayToClass([
			'row w-100 display-flex justify-content-center',
			!allAirport.length ? 'h-100' : '',
		]);

		return (
			<div className={classes}>
				<div className='mt-8 col-22 col-sm-18'>
					{this.state.loading && !allAirport.length && (
						<Spinner size='large' />
					)}
					{!this.state.loading &&
						!allAirport.length &&
						this.renderNotFound()}
					{allAirport.map((item: any, index: number) =>
						this.renderCard(item, index)
					)}
				</div>
			</div>
		);
	}

	private renderCard(item, index) {
		return (
			<AirportCard
				data={item}
				key={index}
				className='elevation-0 mb-4 elevation-1'
				onClick={() => this.pushToDetails(item)}
				weather={get(this.state.weathers, item.aid)}
				hasOwnWeather={true}
				enableIcon={true}
			/>
		);
	}

	private pushToDetails(item: any) {
		const prevSearched = LocalStorage.getItem('lastSearched') || [];
		prevSearched.unshift(item.aid);

		const sliced = prevSearched.slice(0, 3);

		const individualized = uniq(sliced);

		LocalStorage.setItem('lastSearched', individualized);

		const radius = getQuery().radius || 100;
		this.navigate(`/airports/${item?.aid}`, { radius: radius });
	}

	private renderPagination(): React.ReactElement {
		if (!this.state.loading && this.state.total === 0) {
			return <Spinner size='medium' />;
		}

		const query: any = getQuery();
		const page = parseFloat(query?.page || 0) + 1;
		const size = query?.size || 10;
		const total = this.state?.total;

		return (
			<div className='PaginationContainer w-100 display-flex justify-content-center my-5'>
				<Pagination
					className='Pagination'
					current={page}
					size={size}
					total={total}
					onClick={(number: any) => this.handlePaginate(number)}
				/>
			</div>
		);
	}

	private renderFooter(): any {
		const total = get(this.state, 'total', null);
		const totalParse =
			total && total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

		if (!total) {
			return null;
		}

		return (
			<div className='row w-100 display-flex justify-content-center'>
				<div className='col-22 col-sm-18 border-top-1 palette--bc-neutral-3'>
					<div className='display-flex align-items-center px-2'>
						<div className='display-flex align-items-center flex-fill'>
							<PerPage />
							<p className='m-0 palette--c-neutral-5 ml-2'>
								item / page
							</p>
						</div>
						<p className='mb-0 palette--c-neutral-5'>
							{totalParse} result
						</p>
					</div>
					<div className='display-flex justify-content-center w-100'>
						{this.renderPagination()}
					</div>
				</div>
			</div>
		);
	}

	private handlePaginate(event: any) {
		const newQuery = replaceQuery({
			page: (event || 1) - 1,
		});

		this.props?.router?.navigate({ search: newQuery });
		this.triggerSearch();
	}

	public navigate(path, params = {}) {
		const utmSource = isInQuery('utm_source') || '';
		const queries = `?${utmSource}${stringify(params)}`;
		this.props?.router?.navigate({ pathname: path, search: queries });
	}
}

export const SearchScreen: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(SearchLayout(SearchScreenComponent));
