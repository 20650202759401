import { differenceInDays, endOfDay, getISODay, startOfDay } from 'date-fns';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../../../../../../actions/details.actions';
import { toUtc } from '../../../../../../../utilities/helper-fuctions';
import { LocalStorage } from '../../../../../../../utilities/local-storage';
import { RequestManager } from '../../../../../../../utilities/request';
import { ToastMessages } from '../../../../../../notifications/toast-messages';
import { ResourceLoader } from '../../../../../../other/resource-loader';
import { WidgetModal } from '../../../../../widget-modal';
import { DAYS } from '../../../../opening-hours/opening-hours-contribution/constants';
import { StepFive } from '../../../../opening-hours/opening-hours-create/steps/step-five';
import { StepZero } from '../../../../opening-hours/opening-hours-create/steps/step-zero';
import '../../../../opening-hours/opening-hours-create/steps/step.scss';
import { ProviderStep } from './provider-step';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
	mergeContribution: (data, endpoint, widgetId, callback) =>
		dispatch(
			DetailsActions.mergeContribution(data, endpoint, widgetId, callback)
		),
});

class ProviderAvailabilityEditComponent extends WidgetModal<any, any> {
	public state = {
		...this.state,
		step: LocalStorage.getItem('provider-step') || 0,
		error: false,
		loadingSave: false,
	};

	public componentDidMount() {
		super.componentDidMount();

		if (!LocalStorage.getItem('provider-step')) {
			LocalStorage.setItem('provider-step', 0);
		}
	}

	public getTitle() {
		return true;
	}

	public renderHeader(): React.ReactElement {
		const progress = (((this.state.step || 0) + 1) / 3) * 100;

		return (
			<div className='position-relative'>
				<div className='w-100 p-4'>
					<div className='OpeningHoursHeader display-flex'>
						<h3 className='palette--c-neutral-6 fw-bold m-0 flex-fill'>
							Contribute opening hours
						</h3>
					</div>
					<ResourceLoader
						renderContent={(rl: ResourceLoader) =>
							this.renderAirport(rl)
						}
						endpoint={`/providers/${this.props.aid}`}
						renderError={() => this.renderError()}
					/>
				</div>
				<div className='w-100 palette--bgc-neutral-3'>
					<div
						className='py-9 palette--bgc-primary-4'
						style={{ width: `${progress}%` }}
					/>
				</div>
			</div>
		);
	}

	public renderAirport(ctx): React.ReactElement {
		return (
			<h5 className='palette--c-neutral-5'>
				{ctx.state?.response?.name}
			</h5>
		);
	}

	public renderForm(): React.ReactElement {
		return (
			<div className='w-100'>
				{this.state.step === 0 && (
					<StepZero
						goForward={() => this.stepForward()}
						stepName='provider-step-0'
					/>
				)}
				{this.state.step === 1 && (
					<ProviderStep
						goBack={() => this.stepBack()}
						save={() => this.save()}
						defaultDays={this.getDays()}
						loading={this.state?.loadingSave}
					/>
				)}
				{this.state.step === 2 && (
					<StepFive close={() => this.onClose()} />
				)}
			</div>
		);
	}

	public renderFooter() {
		return null;
	}

	public stepForward(): any {
		const step = this.state.step;

		if (step < 2) {
			this.setState({ step: step + 1 });
			LocalStorage.setItem('provider-step', step + 1);
		}
	}

	public stepBack(): any {
		const step = this.state.step;

		if (step > 0) {
			this.setState({ step: step - 1 });
			LocalStorage.setItem('provider-step', step - 1);
		}
	}

	public onClose(): void {
		LocalStorage.setItem('provider-step', 0);
		this.setState({ step: 0 });
		LocalStorage.removeItem('provider-step-0');
		LocalStorage.removeItem('provider-step-1');

		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	public getDays(): any {
		const { validFrom, validTo } = LocalStorage.getItem('provider-step-0');
		const from = startOfDay(new Date(validFrom));
		const to = endOfDay(new Date(validTo));

		const diff = differenceInDays(to, from);

		if (Math.ceil(diff) >= 7) {
			return DAYS;
		}

		const firstDay = getISODay(from);
		const lastDay = getISODay(to);

		if (lastDay < firstDay) {
			return DAYS.filter(
				(day, index) =>
					firstDay <= index + 1 ||
					lastDay >= index + 1 ||
					day.value === 'HOLIDAY'
			);
		}

		return DAYS.filter(
			(day, index) =>
				(firstDay <= index + 1 && lastDay >= index + 1) ||
				day.value === 'HOLIDAY'
		);
	}

	public save(): void {
		this.saveStep(1, '/provideravailabilities');
	}

	public async saveStep(step, endpoint): Promise<void> {
		const { data = [] } = LocalStorage.getItem(`provider-step-${step}`);

		const blocks = data.map((blockItem) => {
			const block = blockItem?.data;
			delete block.available;
			delete block.availablePerDay;
			return block;
		});

		const convertStepZero = this.convertDateToUTC();

		blocks.map(async (block): Promise<any> => {
			const body = {
				...convertStepZero,
				...block,
			};

			try {
				const response = await RequestManager.post(
					`${endpoint}`,
					body,
					{ parentAid: this.props.aid }
				);

				if (!response) {
					throw new Error('Error');
				}

				this.onSuccess(response, body);
			} catch (err: any) {
				toast.error(
					<ToastMessages
						error={`Error in Provider availability: ${
							err?.response?.data?.message ||
							'some blocks are not valid.'
						}`}
					/>,
					{ theme: 'light' }
				);
			}
		});
	}

	public async onSuccess(response, sentData) {
		if (
			(this.props?.user?.roles || []).includes('AUTO_MERGE_CONTRIBUTIONS')
		) {
			response.contribution.changes = Object.keys(sentData).filter(
				(key) =>
					sentData[key] !== null ||
					sentData[key] !== '' ||
					!isEmpty(sentData)
			);
			await this.props.mergeContribution(
				response,
				'/provideravailabilities/merge',
				'availability',
				() => this.stepForward()
			);
		} else {
			this.stepForward();
		}
	}

	public convertDateToUTC() {
		const dates = LocalStorage.getItem('provider-step-0');
		const { validFrom, validTo } = dates;

		return {
			...dates,
			validFrom: toUtc(validFrom),
			validTo: toUtc(validTo),
		};
	}
}

export const ProviderAvailabilityEdit: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(ProviderAvailabilityEditComponent);
