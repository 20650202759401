import * as React from 'react';
import { arrayToClass, randomId } from '../../../utilities/helper-fuctions';
import './pagination.scss';

export class Pagination extends React.Component<any, any> {
    public render() {
        const currentPage = this.props?.current;
        const total = this.props?.total;
        const size = this.props?.size;

        const renderableNumbers = Math.ceil(total / size);
        const numbersToArray: any = this.generateArray(renderableNumbers);

        return (
            <div className="PaginationWrapper display-flex justify-content-center align-items-center">
                {currentPage > 1 && this.renderArrow()}
                {numbersToArray.map((item) => this.renderItem(item))}
                {currentPage < renderableNumbers && this.renderArrow(true)}
            </div>
        );
    }

    public generateArray(limit) {
        const currentPage = this.props?.current;
        let array: any = Array(limit)
            .fill()
            .map((_, index) => 1 + index);

        if (currentPage <= 3 && limit >= 7) {
            array = [...array.slice(0, 4), '...', array[limit - 1]];
        } else if (currentPage > limit - 3 && limit >= 7) {
            array = [array[0], '...', ...array.slice(-4)];
        } else if (currentPage > 3 && currentPage <= limit - 3 && limit >= 7) {
            array = [array[0], '...', ...array.slice(currentPage - 2, currentPage + 1), '...', array[limit - 1]];
        }

        return array;
    }

    public renderItem(item) {
        const currentPage = this.props?.current;
        const itemClasses = arrayToClass([
            isNaN(item) ? 'PaginationDots' : item === currentPage ? 'SelectedItem' : 'PaginationItem',
            'm-0 mx-1 px-1',
        ]);

        return (
            <p key={randomId()} onClick={() => this.handleOnClick(item)} className={itemClasses}>
                {item}
            </p>
        );
    }

    public renderArrow(isAdd = false) {
        return (
            <p onClick={() => this.handleArrowClick(isAdd)} className="PaginationArrow material-icons m-0">
                keyboard_arrow_{isAdd ? 'right' : 'left'}
            </p>
        );
    }

    public handleOnClick(item) {
        const currentPage = this.props?.current;

        if (this.props.onClick && !isNaN(item) && item !== currentPage) {
            this.props.onClick(item);
        }
    }

    public handleArrowClick(isAdd = false) {
        const currentPage = this.props?.current;
        const newPage = isAdd ? currentPage + 1 : currentPage - 1;

        if (this.props.onClick) {
            this.props.onClick(newPage);
        }
    }
}
