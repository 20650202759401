import { arrayToClass } from '../../../utilities/helper-fuctions';
import { randomId } from '../../../utilities/helper-fuctions';
import jquery from 'jquery';
import { get, isEqual } from 'lodash';
import * as React from 'react';
import './thumbnail-presenter.scss';

export class ThumbnailsPresenter extends React.Component<any, any> {
    public state = {
        isLeft: false,
        isRight: false,
    };

    public componentDidMount(): void {
        this.isArrowsShown();
        window.addEventListener('resize', () => {
            this.isArrowsShown();
        });
    }

    public isArrowsShown() {
        const parentWidth: any = jquery('.ThumbnailsContainer').width();
        const boxWidth: any = 200;
        const scrollWidth = Math.round(boxWidth * Object.keys(get(this.props, 'pictures', {})).length);

        if (scrollWidth <= parentWidth) {
            this.setState({ isLeft: false, isRight: false });
            return;
        }

        this.setState({ isLeft: false, isRight: true });
    }

    public render() {
        const list = get(this.props, 'pictures') || [];

        return (
            <div className="ThumbnailsContainer display-flex w-100">
                {this.state.isLeft && this.renderArrow('chevron-left')}
                <div className="Thumbnails display-flex">
                    {list.map((thumbnail) => this.renderThumbnail(thumbnail))}
                </div>
                {this.state.isRight && this.renderArrow('chevron-right')}
            </div>
        );
    }

    public renderArrow(icon) {
        const direction = icon.split('-')[1];
        const containerClasses = arrayToClass([
            'ScrollArrows palette--bgc-neutral-3 position-absolute absolute-top-0 absolute-bottom-0 display-flex justify-content-center align-items-center',
            `absolute-${direction}-0`,
        ]);

        const iconClasses = arrayToClass(['palette--c-neutral-6 fas', `fa-${icon}`]);

        return (
            <div className={containerClasses} onClick={() => this.handleScroll(direction)}>
                <span className={iconClasses} />
            </div>
        );
    }

    public handleScroll(direction: string) {
        const parentLeft: any = jquery('.ThumbnailsContainer').scrollLeft();
        const parentWidth: any = jquery('.ThumbnailsContainer').width();
        const boxWidth: any = 200;
        const allBoxWidth: any = boxWidth * Object.keys(get(this.props, 'pictures', {})).length;
        const scrollWidth = allBoxWidth - parentWidth;

        if (direction === 'right') {
            const newRightWidth = parentLeft + boxWidth * 2;
            jquery('.ThumbnailsContainer').animate({ scrollLeft: newRightWidth }, 200);
            this.setState({ isLeft: true });

            if (newRightWidth >= scrollWidth) {
                this.setState({ isLeft: true, isRight: false });
                return;
            }
            return;
        }

        const newLeftWidth = parentLeft - boxWidth * 2;
        jquery('.ThumbnailsContainer').animate({ scrollLeft: newLeftWidth }, 200);
        this.setState({ isRight: true });

        if (newLeftWidth <= 0) {
            this.setState({ isLeft: false, isRight: true });
            return;
        }
        return;
    }

    public renderThumbnail(thumbnail) {
        const url = get(thumbnail, 'thumbnail') || get(thumbnail, 'file.path');
        const isSelected = isEqual(get(this.props, 'selected'), thumbnail);

        const className = arrayToClass(['pointer', isSelected ? 'SelectedThumbnail' : 'Thumbnail']);

        return (
            <div key={randomId()} className={className} onClick={() => this.select(thumbnail)}>
                <div className="ImageHolder">
                    <img src={url} alt={thumbnail?.description || 'provider-image'} />
                </div>
            </div>
        );
    }

    public select(thumbnail) {
        if (this.props.select) {
            this.props.select(thumbnail);
        }
    }
}
