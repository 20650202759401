import { get } from 'lodash';
import * as React from 'react';
import { Header } from '../../../components/header/header';
import { Form } from '../../../components/ui-components/form/form';
import { TextInput } from '../../../components/ui-components/inputs/inputs/text-input';
import { PaginationFooter } from '../../../components/ui-components/pagination/pagination-footer/pagination-footer';
import { Spinner } from '../../../components/ui-components/spinner/spinner';
import { arrayToClass, getQuery, replaceQuery } from '../../../utilities/helper-fuctions';
import { RequestManager } from '../../../utilities/request';
import './basic-admin.screen.scss';

export class BasicAdminScreen<P, S> extends React.Component<P & any, S & any> {
    public isMounted = false;

    public state = {
        loading: false,
        response: false,
        error: false,
        selected: false,
        isModal: !!this.props?.router?.params?.aid,
    };

    public form = new Form({
        search: {
            value: getQuery().query || '',
            trigger: () => this.handleQueryChange(),
        },
    });

    constructor(props) {
        super(props);

        document.title = 'aviowiki';
    }

    public handleQueryChange() {
        const newQuery = replaceQuery({ query: this.form.getValue('search'), page: 0 });
        this.props?.router?.navigate({ search: newQuery });
    }

    public async componentDidMount(): Promise<any> {
        this.isMounted = true;
        const roles = get(this.props.user, 'roles', []);

        if (!this.props.user || !roles.includes('ADMIN')) {
            
            this.props?.router?.navigate('/');
        } else {
            await this.fetch();
        }
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props?.router?.location?.search !== prevProps?.router?.location?.search) {
            this.fetch();
        }
    }

    public componentWillUnmount() {
        this.isMounted = false;
    }

    public getEndpoint(): any {
        return '/users/search';
    }

    public getTitle() {
        return 'Manages';
    }

    public async fetch(): Promise<any> {
        const currentQueries: any = getQuery();
        const query = {
            page: parseFloat(currentQueries.page || 0),
            size: parseFloat(currentQueries.size || 10),
            query: currentQueries.query || '',
        };

        try {
            const endpoint = this.getEndpoint();

            if (!endpoint) {
                return;
            }

            const response = await RequestManager.get(this.getEndpoint(), query);

            if (!response) {
                throw new Error('there was an error');
            }

            if (this.isMounted) {
                this.setState({ response: response.content || response, meta: response.page });
            }
        } catch (err) {
            this.setState({ error: err });
        } finally {
            this.setState({ loading: false });
        }
    }

    public render(): React.ReactElement {
        const classes = arrayToClass(['ManagesScreen display-flex flex-column Paddings']);

        const content: any = get(this.state, 'response', []) || [];

        const isEndpoint = this.getEndpoint();

        return (
            <div className={classes}>
                <div className={arrayToClass(['h-100'])}>
                    <Header title={this.getTitle()} />

                    {!!isEndpoint && (
                        <div className="w-100 display-flex align-items-end">
                            <TextInput
                                field={this.form.getField('search')}
                                className="pt-2 flex-fill"
                                textType="camel"
                                wrapperClassName="flex-fill"
                                placeholder="Start to type..."
                            />
                            {this.renderExtra()}
                        </div>
                    )}

                    <div className="ContentContainer position-relative my-4 palette--bgc-neutral-1 border-radius-1">
                        {this.state.loading && !this.state.error && !this.state.response && <Spinner size="large" />}
                        {!this.state.response && !this.state.loading && this.state.error && this.renderError()}
                        {this.state.response &&
                            !this.state.loading &&
                            !this.state.error &&
                            !content.length &&
                            this.renderEmpty()}
                        {((this.state.response && !this.state.loading && !this.state.loading && !!content.length) ||
                            !isEndpoint) &&
                            this.renderContent()}
                        {this.renderFooter(content)}
                        {this.state?.isModal && this.renderModal()}
                    </div>
                </div>
            </div>
        );
    }

    public renderError(): React.ReactElement {
        return (
            <div className="w-100 display-flex justify-content-center align-items-center">
                <p className="p-6 palette--c-neutral-5">
                    There was an error. We are working to fix the issue and will be back soon!
                </p>
            </div>
        );
    }

    public renderEmpty(): React.ReactElement {
        return (
            <div className="w-100 display-flex justify-content-center align-items-center">
                <p className="p-6 palette--c-neutral-5">This list is empty.</p>
            </div>
        );
    }

    public renderExtra(): any {
        return null;
    }

    public renderContent(): any {
        return null;
    }

    public renderFooter(content) {
        if (this.state.response && !this.state.loading && !this.state.error && !!content.length) {
            return <PaginationFooter total={get(this.state, 'meta.totalResults')} />;
        }

        return null;
    }

    public renderModal(): any {
        return null;
    }
}
