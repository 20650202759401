import * as React from 'react';
import { arrayToClass } from '../../../utilities/helper-fuctions';
import { HoverableButton } from '../hoverable-buttons/hoverable-buttons';
import './modal.scss';

export class Modal extends React.Component<any, any> {
    public modal$;

    public state = {
        open: false,
    };

    public open() {
        this.setState({ open: true });
    }

    public close() {
        this.setState({ open: false });
    }

    public render(): any {
        const wrapperClasses = arrayToClass(['ModalWrapper', this.state.open ? 'open' : '']);

        const modalClasses = arrayToClass([
            'Modal elevations-1 border-radius-1',
            this.props.wrapperClasses ? this.props.wrapperClasses : '',
        ]);

        return (
            <div className={wrapperClasses} ref={(ref: any) => (this.modal$ = ref)}>
                <div className={modalClasses}>
                    {this.renderHeader()}
                    {this.props.renderButtonOnly && this.renderButton()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </div>
        );
    }

    public renderButton() {
        return (
            <HoverableButton
                type="button"
                className="CloseButton border-radius-1"
                colorType="contrast"
                icon="close"
                onClick={() => this.handleButtonClick()}
            />
        );
    }

    public renderHeader() {
        if (!this.props.title) {
            return null;
        }

        const isString = typeof this.props.title === 'string';

        return (
            <div className="ModalHeader">
                {isString && (
                    <div className="Header display-flex align-items-center p-4">
                        <h4 className="palette--c-neutral-5">{this.props.title}</h4>
                    </div>
                )}
                {!isString && this.props.title()}
                {!this.props.hideClose && this.renderButton()}
            </div>
        );
    }

    public renderBody() {
        return <div className="ModalBody">{this.props.content && this.props.content()}</div>;
    }

    public renderFooter() {
        if (!this.props.footer) {
            return null;
        }

        return <div className="ModalFooter">{this.props.footer && this.props.footer()}</div>;
    }

    public handleButtonClick() {
        if (this.props.onDismissRequest) {
            this.props.onDismissRequest();
        } else {
            this.close();
        }
    }
}
