import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { arrayToClass, randomId } from '../../../utilities/helper-fuctions';
import { RequestManager } from '../../../utilities/request';
import { ConfirmationModal } from '../../notifications/confirmation-modal';
import { ToastMessages } from '../../notifications/toast-messages';
import { HoverableButton } from '../../ui-components/hoverable-buttons/hoverable-buttons';
import { Spinner } from '../../ui-components/spinner/spinner';
import { StatusMarker } from '../../ui-components/statusmarker/status-marker';
import { ManageWebhook } from './manage-webhook';
import { SecretKey } from './secret-key';
import './webhooks.scss';

const mapStateProps = (store: any) => ({
    dateFormat: store.userDetails.date,
    user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class WebhooksSubscriptionsComponent extends React.Component<any, any> {
    public deleteModal$: ConfirmationModal;
    public secret$: SecretKey;

    public state = {
        loading: false,
        webhooks: false,
        webhook: false,
        deletableAid: false,
        error: false,
        isUserHasPermission: (get(this.props, 'user.roles') || []).includes('WEBHOOK'),
        dontOpen: true,
    };

    public componentDidMount() {
        if (this.state.isUserHasPermission) {
            this.fetchSubscriptions();
        }
    }

    public async fetchSubscriptions(): Promise<any> {
        this.setState({ loading: true });

        try {
            const response = await RequestManager.get(`/webhooks`);

            if (!response) {
                throw new Error('There was an error');
            }

            this.setState({ webhooks: response, loading: false });
        } catch (err) {
            this.setState({ loading: false });
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
        }
    }

    public render(): React.ReactElement {
        if (this.state.loading) {
            return <Spinner size="large" />;
        }

        if (!this.state.isUserHasPermission) {
            return (
                <div className="w-100">
                    <div className="py-4 w-100 display-flex justify-content-center palette--c-neutral-5">
                        You don't have the permission to ask for webhooks.
                    </div>
                </div>
            );
        }

        return (
            <div className="w-100">
                <div className="py-4 w-100">
                    <div className="display-flex align-items-center mb-4">
                        <h6 className="fw-bold text-uppercase palette--c-neutral-5 flex-fill">Subscriptions</h6>

                        <HoverableButton
                            title="Add webhook"
                            colorType="avio-green"
                            className="border-radius-1 ml-2 elevation-1"
                            onClick={() => this.setState({ dontOpen: false })}
                        />
                    </div>
                </div>

                {this.renderWebhooks()}

                <div className="display-flex w-100 justify-content-end pt-4">
                    <HoverableButton
                        title="Back"
                        colorType="cancel"
                        type="button"
                        onClick={() => this.props.close && this.props.close()}
                    />
                </div>

                {!this.state.dontOpen && (
                    <ManageWebhook webhook={this.state?.webhook} onClose={(changed) => this.handleClose(changed)} />
                )}
                <SecretKey ref={(ref: any) => (this.secret$ = ref)} />
                <ConfirmationModal
                    title="Are you sure you want to delete this webhook?"
                    onClick={() => this.handleDelete()}
                    close={() => this.deleteModal$.close()}
                    ref={(ref: any) => (this.deleteModal$ = ref)}
                />
            </div>
        );
    }

    public renderWebhooks() {
        const webhooks: any = get(this.state, 'webhooks');

        if (!(webhooks || []).length) {
            return (
                <div className="w-100 display-flex justify-content-center palette--bc-neutral-4 border-1 border-radius-1">
                    <p className="m-0 py-5 palette--c-neutral-5">Your webhooks will be listed here.</p>
                </div>
            );
        }

        return (
            <div className="w-100">
                <div className="m-2">
                    <div className="row px-2">
                        <h5 className="palette--c-neutral-5 text-uppercase col-24 col-sm-12 col-md-4">aid</h5>
                        <h5 className="palette--c-neutral-5 text-uppercase col-24 col-sm-24 col-md-6">types</h5>
                        <h5 className="palette--c-neutral-5 text-uppercase col-24 col-sm-24 col-md-8">url</h5>
                        <h5 className="palette--c-neutral-5 text-uppercase col-2">secret key</h5>
                        <h5 className="palette--c-neutral-5 text-uppercase col-2">edit</h5>
                        <h5 className="palette--c-neutral-5 text-uppercase col-2">delete</h5>
                    </div>
                </div>
                <div className="m-2 palette--bc-neutral-4 border-1 border-radius-1">
                    {(webhooks || []).map((webhook, index) => {
                        const classes = arrayToClass([
                            `palette--bgc-neutral-${index % 2 === 0 ? '1' : '3'}`,
                            'w-100 p-2',
                        ]);

                        return (
                            <div key={randomId()} className={classes}>
                                <div className="row display-flex align-items-center">
                                    <div className="col-4">
                                        <StatusMarker
                                            tColor="secondary-4"
                                            bgColor="secondary-1"
                                            text={get(webhook, 'aid')}
                                            type="tag"
                                        />
                                    </div>
                                    <div className="col-6">
                                        {(get(webhook, 'types') || []).map((item) => (
                                            <StatusMarker
                                                key={item}
                                                tColor="secondary-4"
                                                bgColor="secondary-1"
                                                text={item.replace(/_/gi, ' ')}
                                                type="tag"
                                            />
                                        ))}
                                    </div>
                                    <div className="col-8">
                                        <p className="m-0 palette--c-neutral-6">{get(webhook, 'url')}</p>
                                    </div>
                                    <div className="col-2">
                                        <HoverableButton
                                            colorType="transparent-grey"
                                            className="w-100"
                                            onClick={() => this.showKey(get(webhook, 'secret'))}
                                            icon="vpn_key"
                                        />
                                    </div>
                                    <div className="col-2">
                                        <HoverableButton
                                            className="w-100"
                                            colorType="transparent-green"
                                            onClick={() => this.handleWebhookEdit(webhook)}
                                            icon="edit"
                                        />
                                    </div>
                                    <div className="col-2">
                                        <HoverableButton
                                            className="w-100"
                                            colorType="delete"
                                            onClick={() => this.handleDeleteOnFE(get(webhook, 'aid'))}
                                            icon="delete"
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    public handleClose(changed) {
        this.setState({ dontOpen: true });

        if (changed) {
            this.fetchSubscriptions();
        }
    }

    public showKey(key) {
        this.secret$.open(key);
    }

    public handleWebhookEdit(webhook) {
        this.setState({ dontOpen: false, webhook: webhook });
    }

    public handleDeleteOnFE(aid) {
        this.setState({ deletableAid: aid });
        this.deleteModal$.open();
    }

    public async handleDelete(): Promise<any> {
        try {
            await RequestManager.delete(`/webhooks/${this.state.deletableAid}`);

            this.fetchSubscriptions();
        } catch (err) {
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
        }
    }
}

export const WebhooksSubscriptions: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    WebhooksSubscriptionsComponent
);
