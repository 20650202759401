import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { getData, getFields } from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { Form } from '../../../../ui-components/form/form';
import { WidgetContributionPresenter } from '../../../shared/contributions/widget-contribution-presenter/widget-contribution-presenter';
import { limitedMovementFields } from './availability-contribution-helpers';
import { FlightContribution } from './flight-types-contribution';

const mapStateProps = (store: any) => ({
    user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class OpenForsContributionComponent extends React.Component<any, any> {
    public flightTypes$: { [key: string]: FlightContribution } = {};

    public state = {
        isAdmin: get(this.props, 'user.roles', []).includes('ADMIN'),
        type: get(this.props, 'type'),
        rejected: [],
        editable: [],
        buttonDisabled: false,
    };

    public form = new Form({
        priorNoticeRequired: this.props?.item?.priorNoticeRequired,
        costsInvolved: this.props?.item?.costsInvolved,
        other: this.props?.item?.other,
    });

    public clearForm(): void {
        this.form.clearForm();
    }

    public getOpenFors() {
        const form: any = this.form.generateJSON();

        form.contribution.changes = getFields(this, limitedMovementFields(this)).map((item) => item.type);

        return form;
    }

    public renderEmpty(): React.ReactElement {
        return (
            <div className="display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2">
                <p className="palette--c-neutral-5 m-0">Nothing changed.</p>
            </div>
        );
    }

    public render(): React.ReactElement {
        return <div className="Content w-100">{this.renderContent()}</div>;
    }

    public renderContent(): React.ReactElement {
        const limitedMovementList = getFields(this, limitedMovementFields(this));

        return (
            <div className="w-100 py-2 pl-4 pr-0">
                <p className="m-0 fw-bold palette--c-neutral-6">Open for: </p>
                {limitedMovementList.map((item: any, index: number) => {
                    return this.renderFields(item, index);
                })}
                {this.renderFlightTypes()}
            </div>
        );
    }

    public renderFlightTypes() {
        return (get(this.props.item, 'flightTypes', []) || []).map((item, index) => {
            return (
                <FlightContribution
                    ref={(ref: any) => (this.flightTypes$[`flightTypes-${index}`] = ref)}
                    key={`flightTypes-${index}`}
                    original={get(this.props.item, 'flightTypes', [])[index]}
                    item={item}
                    isAdmin={this.state.isAdmin}
                    type={this.state.type}
                />
            );
        });
    }

    public renderFields(field: any, index): React.ReactElement {
        const rejected: any = get(this.state, 'rejected', []);
        const editable: any = get(this.state, 'editable', []);

        const wrapperClassName = arrayToClass([
            rejected.includes(field.type)
                ? 'palette--bgc-neutral-3'
                : `palette--bgc-${
                      this.state.type === 'UPDATE' ? 'yellow-1' : this.state.type === 'CREATE' ? 'green-1' : 'red-1'
                  }`,
            'border-radius-1 my-2 p-2',
            'display-flex align-items-center',
        ]);

        const element = {
            type: field.type,
            content: this.getData(field.type),
            component: field.component,
        };

        return (
			<WidgetContributionPresenter
				isAdmin={this.state.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(field.type)}
				toValue={element}
				isEditable={editable.includes(field.type)}
				isRejected={rejected.includes(field.type)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				className={wrapperClassName}
			/>
		);
    }

    public getData(field): any {
        const form = this.form.generateJSON();
        const value = get(form, field, null);

        if (this.state.type === 'DELETE') {
            return null;
        }

        return getData(field, value, true, []);
    }

    public getOriginalData(field): any {
        const value = get(this.props.original, field, null);

        if (get(this.props.item, 'contribution.action') === 'CREATE') {
            return null;
        }

        return getData(field, value, false, []);
    }

    public async handleEdit(type: string = 'editAll'): Promise<void> {
        const editable: any = get(this.state, 'editable', []);

        if (type === 'editAll') {
            const editableArray = limitedMovementFields(this).map((item: any) => item.type);
            await this.setState({ editable: editableArray });

            Object.keys(this.flightTypes$).map((key: string) => this.flightTypes$[key].handleEdit('editAll'));
        }

        if (!editable.includes(type)) {
            await this.setState({ editable: [...this.state.editable, type] });
        }
    }

    private async handleMissEdit(type: string): Promise<void> {
        const editable: any = get(this.state, 'editable', []);

        if (editable.includes(type)) {
            const editableArray = this.state.editable.filter((item) => item !== type);
            await this.setState({ editable: editableArray });
        }
    }

    private handleReject(type: string): void {
        const rejected: any = get(this.state, 'rejected', []);

        if (!rejected.includes(type)) {
            this.setState({ rejected: [...this.state.rejected, type] });
        }
    }

    private handleMissReject(type: string) {
        const rejected: any = get(this.state, 'rejected', []);

        if (rejected.includes(type)) {
            const rejectedArray = this.state.rejected.filter((item) => item !== type);
            this.setState({ rejected: rejectedArray });
        }
    }
}

export const OpenForsContribution: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    OpenForsContributionComponent
);
