import { get, orderBy } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { SettingsActions } from '../../actions/settings.actions';
import { arrayToClass } from '../../utilities/helper-fuctions';
import { LocalStorage } from '../../utilities/local-storage';
import './grid.container.scss';

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
	saveWidgetSettings: (settings) =>
		dispatch(SettingsActions.saveWidgetSettings(settings)),
});

class GridContainerComponent extends React.Component<any, any> {
	public airportsList() {
		const lists = LocalStorage.getItem('widgetOrderAndSize');
		return (
			lists?.airportList || [
				{ identifier: 'general-data', size: 24, order: 1 },
				{ identifier: 'authorities', size: 24, order: 2 },
				{ identifier: 'weather', size: 24, order: 3 },
				{ identifier: 'availability', size: 24, order: 4 },
				{ identifier: 'map', size: 24, order: 5 },
				{ identifier: 'runways', size: 12, order: 6 },
				{ identifier: 'operations', size: 12, order: 7 },
				{ identifier: 'operational-notes', size: 24, order: 8 },
				{ identifier: 'providers', size: 24, order: 9 },
			]
		);
	}

	public countriesList() {
		const lists = LocalStorage.getItem('widgetOrderAndSize');

		// don't forget to remove this code after 1 year: 2022.07.06.
		const newNames = {
			'country-details': 'country',
			'country-authorities': 'authorities',
			'country-notes': 'operational-notes',
		};

		if (lists?.countriesList) {
			const countryList = lists?.countriesList.map((item) => ({
				...item,
				identifier: newNames?.[item?.identifier] || item?.identifier,
			}));
			return countryList;
		} else {
			return [
				{ identifier: 'country', size: 24, order: 1 },
				{ identifier: 'operational-notes', size: 24, order: 2 },
				{ identifier: 'authorities', size: 24, order: 3 },
			];
		}
	}

	public availableWidgets() {
		return orderBy(
			this.props.type === 'countries'
				? this.countriesList()
				: this.airportsList(),
			'order'
		);
	}

	public render(): React.ReactElement {
		return <div className='GridContainer row'>{this.renderChildren()}</div>;
	}

	public renderClasses(identifier: string): string {
		const key = this.getKey(identifier);
		let state = LocalStorage.getItem(`${key}-size`);

		if (!state) {
			const element = this.availableWidgets().find(
				(c) => c.identifier === identifier
			);

			state = get(element, 'size');
		}

		return arrayToClass([`col-24 col-md-${state}`]);
	}

	private renderChildren(): any {
		if (!this.props.children) {
			return null;
		}

		const widgetList = this.availableWidgets();

		return widgetList.map((spot: any, index: number) => {
			const item: any = ((this.props.children as any[]) || []).find(
				(child: any) => {
					return child.props.identifier === spot.identifier;
				}
			);
			const identifier = get(item, 'props.identifier');

			return React.createElement(item?.type, {
				...item.props,
				key: index,
				order: spot.order,
				isFirst: index === 0,
				isLast:
					index === ((this.props.children as any[]) || []).length - 1,
				isToggled: spot.size !== 24,
				isEditable: get(this.props, 'editable'),
				aid: get(this.props, 'aid'),
				onReorder: (direction) =>
					this.handleReorder(identifier, direction),
				onToggleSize: () => this.handleItemToggleSize(identifier),
				className: arrayToClass([
					item.props.className,
					this.renderClasses(identifier),
				]),
			});
		});
	}

	private handleReorder(identifier: any, direction: number) {
		const currentList = this.availableWidgets();
		const currentIndex = currentList
			.map((c) => c.identifier)
			.indexOf(identifier);
		const newIndex = currentIndex + direction;

		const element = currentList[newIndex];
		currentList[newIndex] = currentList[currentIndex];
		currentList[currentIndex] = element;

		currentList.forEach((item: any, index: number) => {
			item.order = index + 1;
		});

		this.props?.saveWidgetSettings({
			airportList:
				this.props.type === 'countries'
					? this.airportsList()
					: currentList,
			countriesList:
				this.props.type === 'countries'
					? currentList
					: this.countriesList(),
		});

		this.forceUpdate();
	}

	private toggleItem(id: string) {
		const newList = (this.availableWidgets() || []).map((item) => {
			if (get(item, 'identifier') === id) {
				item.size = item.size === 24 ? 12 : 24;
			}
			return item;
		});

		this.props?.saveWidgetSettings({
			airportList:
				this.props.type === 'countries' ? this.airportsList() : newList,
			countriesList:
				this.props.type === 'countries'
					? newList
					: this.countriesList(),
		});

		this.forceUpdate();
	}

	private handleItemToggleSize(id: string) {
		this.toggleItem(id);
	}

	private getKey(id: string) {
		return `widget-${id}`;
	}
}

export const GridContainer: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(GridContainerComponent);
