import L from 'leaflet';
import { get } from 'lodash';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, Popup } from 'react-leaflet';
import { MapMarker } from '../../widgets/airport/map/map-marker/map-marker';
import { TooltipLoader } from './tooltip-loader';

export class HoverableMarker extends React.Component<any, any> {
    public state = {
        hovered: false,
    };

    public render(): React.ReactElement {
        const airport = get(this.props, 'airport');
        const marker = this.getIcon(airport);
        const coordinates = get(airport, 'coordinates');
        const link = window.location.origin;

        return (
            <Marker
                icon={marker}
                position={[coordinates.latitude, coordinates.longitude]}
                zIndexOffset={1}
                riseOnHover={true}
                eventHandlers={{
                    click: () => window.open(`${link}/airports/${get(airport, 'aid')}`, '_blank'),
                    mouseover: (e: any) => e.target.openPopup && e.target.openPopup(),
                    mouseout: (e: any) => e.target.closePopup && e.target.closePopup(),
                }}
            >
                <Popup minWidth={200} maxWidth={300} style={{ background: 'black' }} closeButton={false}>
                    <TooltipLoader airport={airport} />
                </Popup>
            </Marker>
        );
    }

    public getIcon(airport: any) {
        const i = get(airport, 'color', 'green');
        const src = `../../../../../assets/info-marker/${i}.svg`;

        return L.divIcon({
            html: ReactDOMServer.renderToString(<MapMarker src={src} />),
            className: 'leaflet-div-maps-marker nearby-contributions-list',
            iconAnchor: [20, 25],
        });
    }
}
