import * as React from 'react';
import { Input } from '../../../../../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../../../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../../../../../ui-components/inputs/select/formatter';
import { SearchableSelect } from '../../../../../../../ui-components/inputs/select/searchable-select';
import { Select } from '../../../../../../../ui-components/inputs/select/select';
import { SwitchSelect } from '../../../../../../../ui-components/inputs/select/switch-select';
import { CURRENCIES } from '../../../../provider-contribution/constants';

export const fuelCostUnits = [
	{ value: 'UPLIFT', title: 'UPLIFT' },
	{ value: 'LTR', title: 'LTR' },
	{ value: 'USG', title: 'USG' },
	{ value: 'IMP', title: 'IMP' },
	{ value: 'PERCENT', title: 'PERCENT' },
];

export const fuelCostsFields = (elmnt) => [
	{
		type: 'description',
		title: 'Notes',
		component: (
			<TextInput
				field={elmnt.form.getField('description')}
				classes='NewData'
				textType='camel'
			/>
		),
	},
	{
		type: 'tax',
		title: 'Tax',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('tax')}
			/>
		),
	},
	{
		type: 'unit',
		title: 'Unit',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('unit')}
				options={formatOptions(fuelCostUnits, false)}
			/>
		),
	},
	{
		type: 'currency',
		title: 'Currency',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('currency')}
				options={formatOptions(CURRENCIES, false)}
			/>
		),
	},
	{
		type: 'price',
		title: 'Price',
		component: (
			<Input
				inputType='number'
				field={elmnt.form.getField('price')}
				classes='NewData'
			/>
		),
	},
	{
		type: 'appliesToCommercial',
		title: 'Applies to commercial',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('appliesToCommercial')}
			/>
		),
	},
	{
		type: 'appliesToInternational',
		title: 'Applies to international',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('appliesToInternational')}
			/>
		),
	},
	{
		type: 'excludedCountries',
		title: 'Excluded countries',
		component: (
			<SearchableSelect
				isMulti={true}
				value='name'
				title='name'
				field={elmnt.form.getField('excludedCountries')}
				classes='NewData'
				endpoint={'/free/countries/search'}
			/>
		),
	},
];
