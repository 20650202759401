import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../../actions/details.actions';
import { connectNLP } from '../../../../utilities/connect-navigate';
import { AuthorityCard } from '../../shared/authority/authority-card';
import { ContributeButton } from '../../../other/contribute-button';
import { Widget } from '../../widget';
import { isInQuery } from '../../../../utilities/helper-fuctions';

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
    setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

class CountryAuthoritiesComponent extends Widget<any, any> {
    public getEndpoint(): any {
        return `/countries/${get(this.props, 'country.iso3')}/authorities/all`;
    }

    public renderContent(): React.ReactElement {
        const authoritiesList = get(this.state, 'response', null);

        if (!authoritiesList || !(authoritiesList || []).length) {
            return (
                <div className="w-100 display-flex justify-content-center py-6">
                    <ContributeButton
                        openModal={() => this.navigate()}
                        text={'Awaiting for authority information.'}
                        isTwoLine={true}
                    />
                </div>
            );
        }

        return (
            <div className="GeneralDataWidget w-100 p-2">
                {authoritiesList.map((authority) => (
                    <AuthorityCard key={get(authority, 'name')} authority={authority} className="border-1" />
                ))}
            </div>
        );
    }

    public navigate() {
        const utmSource = isInQuery('utm_source');
        this.props?.router?.navigate({
            pahtname: `/countries/${this.props?.airport?.aid}/country-authorities`,
            search: utmSource,
        });
    }
}

export const CountryAuthorities: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    connectNLP(CountryAuthoritiesComponent)
);
