import { DetailsActions } from '../../actions/details.actions';
import { reduxStore } from '../../reducers';
import { redirectToLogin } from '../helper-fuctions';
import { RequestManager } from '../request';

const store = reduxStore;
const user = store.getState().user.user;
const merge = (resp, endpoint, type, callback) =>
	store.dispatch(
		DetailsActions.mergeContribution(resp, endpoint, type, callback)
	);

export const request = (mergeEndpoint, mergeType, success, error) => {
	const edit = async (saveEndpoint, data, changes, query = {}) => {
		// DELETE THIS IF FAA IS REMOVED FROM BE!
		delete data.faa;

		try {
			const response = await RequestManager.put(
				saveEndpoint,
				data,
				query
			);

			if (!response) {
				throw new Error('Something went wrong');
			}

			await handleSuccess(response, changes);
		} catch (err) {
			handleError(err, error);
		}
	};

	const create = async (saveEndpoint, data, changes, query = {}) => {
		try {
			const response = await RequestManager.post(
				saveEndpoint,
				data,
				query
			);

			if (!response) {
				throw new Error('Something went wrong');
			}

			await handleSuccess(response, changes);
		} catch (err) {
			handleError(err, error);
		}
	};

	const deleteF = async (saveEndpoint, changes, query = {}) => {
		try {
			const response = await RequestManager.delete(saveEndpoint, query);

			if (!response) {
				throw new Error('Something went wrong');
			}

			await handleSuccess(response, changes);
		} catch (err) {
			handleError(err, error);
		}
	};

	const handleSuccess = async (response, changes) => {
		if ((user?.roles || []).includes('AUTO_MERGE_CONTRIBUTIONS')) {
			if (typeof changes === 'function') {
				response = changes(response);
			} else {
				response.contribution.changes = changes;
			}

			await merge(response, mergeEndpoint, mergeType, () => success());
		} else {
			success();
		}
	};

	const handleError = (err, error) => {
		error(err);
		redirectToLogin(err);
	};

	return {
		edit,
		create,
		deleteF,
	};
};
