import $ from 'jquery';
import { get, uniq } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { connectNLP } from '../../../utilities/connect-navigate';
import { dimConvert } from '../../../utilities/dim.transform';
import { arrayToClass, isInQuery } from '../../../utilities/helper-fuctions';
import { LocalStorage } from '../../../utilities/local-storage';
import { RequestManager } from '../../../utilities/request';
import { AirportCard } from '../../cards/airport/airport.card';
import { NearbyFilters } from '../../filters/nearby-filters';
import { Searchable } from '../../search/searchable';
import { Spinner } from '../../ui-components/spinner/spinner';
import { VisitComponent } from '../visit-component';
import './nearby-airports.scss';

const mapStateProps = (store: any) => ({
    open: !store.details.open,
    openMobile: store.details.openMobile,
    config: store.config.routeConfig,
    measure: store.localStorage.nearbyMeasure,
    radius: store.localStorage.radius,
    runway: store.userDetails.runway,
    heliports: store.userDetails.heliports,
    h24: store.userDetails.h24,
});

const mapDispatchProps = (dispatch: any) => ({});

class NearbyAirportsComponent extends Searchable<any> {
    public parentRef$ = React.createRef();

    public state: any = {
        ...this.state,
        items: [],
        currentItems: [],
        counter: 0,
        weathers: false,
    };

    public async componentDidMount(): Promise<void> {
        await this.fetch(false);
    }

    public async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): Promise<void> {
        if (
            prevProps.aid !== this.props.aid ||
            prevProps.radius !== this.props.radius ||
            prevProps.measure !== this.props.measure ||
            prevProps.heliports !== this.props.heliports ||
            prevProps.runway !== this.props.runway
        ) {
            await this.fetch(false);
        }

        if (this.state.currentItems !== prevState.currentItems) {
            await this.fetchWeather();
        }
    }

    public async fetch(enableAppend = true) {
        try {
            if (!this.props.aid) {
                return;
            }

            const page = enableAppend ? get(this.state, 'counter') : 0;

            this.setState({ loading: true, error: null, counter: page });

            const airport = await RequestManager.get(`/airports/${get(this.props, 'aid')}`);

            const lon = get(airport, 'coordinates.longitude');
            const lat = get(airport, 'coordinates.latitude');
            const radius =
                (await dimConvert(get(this.props, 'radius', 100), get(this.props, 'measure', 'km'), 'km', 0)) || 100;

            const isRunway = get(this.props, 'runway', false);
            const runway = isRunway ? { minimumRunwayLength: isRunway } : {};
            const heliport = this.props.heliports ? {} : { types: 'AIRPORT, AIRSTRIP, WATER, UNKNOWN' };

            await this.search({
                longitude: lon,
                latitude: lat,
                distance: radius,
                page: page,
                ...runway,
                ...heliport,
            });

            const newItems = get(this.state.response, 'content', []).filter((item) => item.aid !== this.props.aid);

            if (enableAppend) {
                this.setState({
                    loading: false,
                    error: null,
                    items: [...this.state.items, ...newItems],
                    currentItems: newItems,
                });
            } else {
                this.setState({
                    loading: false,
                    error: null,
                    items: newItems,
                    currentItems: newItems,
                });
            }
        } catch (err) {
            this.setState({
                loading: false,
                response: null,
                error: get(err, 'response.data.message', null) || err || 'Error',
            });
        }
    }

    public async fetchWeather(): Promise<void> {
        const items = get(this.state, 'currentItems', []);

        if (!items.length) {
            return;
        }

        try {
            const ids = items.map((item: any) => `${item.aid}`).join(',');

            const response = await RequestManager.get('/weather', { aid: ids });

            if (!response) {
                throw new Error('There is no weather');
            }

            if (!this.state.weathers) {
                this.setState({ weathers: response });
            } else {
                this.setState({ weathers: { ...this.state.weathers, ...response } });
            }
        } catch (err) {
            this.setState({ weathers: { error: err } });
        }
    }

    public render(): React.ReactElement {
        const classes = arrayToClass(['NearbyAirports', 'palette--bgc-neutral-2', 'w-100 mt-5']);

        return (
            <div className={classes}>
                <div className="ListTitle px-3 pt-3 pb-2 display-flex align-items-center">
                    <h6 className="m-0 text-uppercase palette--c-neutral-5 flex-fill">Nearby airports</h6>
                    <NearbyFilters showRadius={true} />
                </div>

                <div
                    className="List w-100 h-100 px-3 display-flex flex-column"
                    ref={(ref: any) => (this.parentRef$ = ref)}
                >
                    {this.state.error && this.renderNotFound()}
                    {this.state.loading && !this.state.response && this.renderLoading()}
                    {this.state.response && this.renderCards()}
                </div>
            </div>
        );
    }

    public renderNotFound(): React.ReactElement {
        return (
            <div className="w-100 h-100 p-3 display-flex flex-column justify-content-center align-items-center">
                <img src="/assets/no-nearby-airport.svg" alt="no-nearby" className="NoNearbyAirportLogo" />
                <p className="palette--neutral-5 mt-3">There are no other airports in the vicinity.</p>
            </div>
        );
    }

    public renderLoading(): React.ReactElement {
        return <Spinner size="large" />;
    }

    public hasMore() {
        const totalPages = get(this.state.response, 'page.totalPages', 0);
        return this.state.counter !== totalPages && this.state.counter < totalPages;
    }

    public renderCards(): React.ReactElement {
        let allAirport = get(this.state, 'items', false) || [];

        if (!allAirport || !allAirport.length) {
            return this.renderNotFound();
        }

        const opened = ($('.List').width() || 0) > 0;

        return (
            <div className="AirportsList w-100">
                {allAirport.map((airport: any, index: number) => (
                    <AirportCard
                        key={index}
                        data={airport}
                        className="my-2 elevation-1"
                        onClick={() => this.changeAirport(airport.aid)}
                        enableIcon={get(this.props.config, 'wide')}
                        weather={get(this.state.weathers, airport.aid)}
                        hasOwnWeather={true}
                        hasWeatherError={!!get(this.state, 'weathers.error')}
                    />
                ))}
                {opened && (
                    <VisitComponent
                        parentClass="List"
                        fetch={() => this.handleFetch()}
                        itemLength={(get(this.state, 'items', false) || []).length}
                    />
                )}
                {this.state.loading && !!(allAirport || []).length && <Spinner size="large" />}
                {!this.hasMore() && this.renderNoMore()}
            </div>
        );
    }

    public changeAirport(aid: any) {
        this.setState({ counter: 0 });
        const prevSearched = LocalStorage.getItem('lastSearched') || [];
        prevSearched.unshift(aid);

        const sliced = prevSearched.slice(0, 3);

        const individualized = uniq(sliced);

        LocalStorage.setItem('lastSearched', individualized);

        const utmSource = isInQuery('utm_source') || '';
        this.props?.router?.navigate({ pathname: `/airports/${aid}`, search: utmSource });
    }

    public async handleFetch() {
        if (this.hasMore()) {
            await this.setState({ counter: this.state.counter + 1 });
            await this.fetch();
        }
    }

    public renderNoMore(): React.ReactElement {
        return (
            <div className="display-flex justify-content-center p-2 mb-2">
                <p className="palette--c-neutral-5 m-0">There are no more airports on this list.</p>
            </div>
        );
    }
}

export const NearbyAirports: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    connectNLP(NearbyAirportsComponent)
);
