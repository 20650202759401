import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserActions } from '../../actions/user.actions';
import { WhyAvioModal } from '../../components/notifications/why-avio-modal';
import { Form } from '../../components/ui-components/form/form';
import { HoverableButton } from '../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { Input } from '../../components/ui-components/inputs/inputs/input';
import { Password } from '../../components/ui-components/inputs/inputs/password';
import { Label } from '../../components/ui-components/inputs/label/label';
import { LoginLayout } from '../../layouts/login/login.layout';
import './login.screen.scss';

const mapStateProps = (store: any) => ({
    loginInProgress: store.user.loginInProgress,
    loginError: store.user.loginError,
    loginSuccess: store.user.loginSuccess,
});

const mapDispatchProps = (dispatch: any) => ({
    goToLogin: () => dispatch(UserActions.setLoginError(null)),
    login: (data) => dispatch(UserActions.login(data)),
    tryRedirectIfAuthenticated: () => dispatch(UserActions.tryRedirectIfAuthenticated()),
});

class LoginScreenComponent extends React.Component<any, any> {
    public whyAvio$: WhyAvioModal;

    public form: any = new Form({
        login: '',
        password: '',
    });

    public render(): React.ReactElement {
        return (
            <div className="LoginScreen w-100 display-flex justify-content-center">
                <div className="LoginContainer w-100 palette--bgc-neutral-1 border-radius-1 p-6 elevation-1">
                    <h2 className="palette--c-neutral-6 mb-4">Log in</h2>

                    <div className="display-flex flex-column flex-sm-row">
                        <p className="palette--c-neutral-5">You don't have an account?</p>
                        <Link to="/registration" className="text-decoration-none">
                            <p className="LoginLink fw-bold">&nbsp;Create free account</p>
                        </Link>
                    </div>

                    <div className="display-flex flex-column flex-sm-row mb-6">
                        <p className="palette--c-neutral-5">Why do I need to provide this </p>
                        <div onClick={() => this.whyAvio$.open()}>
                            <p className="LoginLink fw-bold pointer">&nbsp;information?</p>
                        </div>

                        <WhyAvioModal ref={(ref: any) => (this.whyAvio$ = ref)} />
                    </div>

                    <form>
                        <Input
                            type="text"
                            field={this.form.getField('login')}
                            classes="mb-6"
                            labelInfos={{ label: 'Email' }}
                        />

                        <div className="display-flex w-100 align-items-center">
                            <Label label="Password" className="mb-2 flex-fill" />
                            <a href={`/forgot-password`} className="text-decoration-none">
                                <p className="LoginLink">Forgot password?</p>
                            </a>
                        </div>
                        <Password field={this.form.getField('password')} classes="mb-6" />

                        <div className="w-100 display-flex justify-content-end">
                            <HoverableButton
                                icon="arrow_forward"
                                colorType="avio-green"
                                titleLeft="Log in"
                                className="border-radius-1 elevation-1"
                                isSave={true}
                                onClick={() => this.handleLogin(this.form.generateJSON())}
                            />
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    public async handleLogin(form): Promise<void> {
        if (!form.login || !form.password) {
            toast.warn('You have to fill the email and password fields.', { theme: 'light' });
            return;
        }

        await this.props.login(form);

        if (this.props.loginError) {
            this.setState({ error: this.props.loginError.response });
        }

        if (this.props.loginSuccess) {
            this.props.tryRedirectIfAuthenticated();
        }
    }
}

export const LoginScreen: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    LoginLayout(LoginScreenComponent)
);
