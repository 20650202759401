import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
    checkData,
    getData,
    getFields,
} from '../../../../../../../../screens/admin-screens/contributions/helper-functions/getters';
import { arrayToClass, redirectToLogin } from '../../../../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../../../../utilities/request';
import { ToastMessages } from '../../../../../../../notifications/toast-messages';
import { Form } from '../../../../../../../ui-components/form/form';
import { ContributionFooter } from '../../../../../../shared/contributions/contribution-footer';
import { WidgetContributionPresenter } from '../../../../../../shared/contributions/widget-contribution-presenter/widget-contribution-presenter';
import { fuelCostsFields } from './fuel-costs-constants';

const mapStateProps = (store: any) => ({
    user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class FuelFeesContributionComponent extends React.Component<any, any> {
    public state = {
        isAdmin: get(this.props, 'user.roles', []).includes('ADMIN'),
        type: get(this.props.item, 'contribution.action'),
        rejected: [],
        editable: [],
        buttonDisabled: false,
    };

    public form: any = new Form({
        description: this.props?.item?.description || '',
        tax: this.props?.item?.tax || '',
        unit: this.props?.item?.unit || '',
        currency: this.props?.item?.currency || '',
        price: this.props?.item?.price || '',
        appliesToCommercial: this.props?.item?.appliesToCommercial || '',
        appliesToInternational: this.props?.item?.appliesToInternational || '',
        excludedCountries: this.props?.item?.excludedCountries || '',
    });

    public renderEmpty(): React.ReactElement {
        return (
            <div className="display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2">
                <p className="palette--c-neutral-5 m-0">Nothing changed.</p>
            </div>
        );
    }

    public render(): React.ReactElement {
        const fuelList = getFields(this, fuelCostsFields(this)) || [];

        return (
            <div className="Content w-100">
                <div className="p-2">
                    {(!fuelList || !fuelList.length) && this.renderEmpty()}
                    {!!fuelList.length && this.renderContent(fuelList)}
                </div>
                <ContributionFooter
                    buttonDisabled={this.state.buttonDisabled}
                    isAdmin={this.state.isAdmin}
                    handleButtonClick={(isReject: boolean = false) => this.handleButtonClick(isReject)}
                    close={() => this.props.close()}
                    handleEdit={() => this.handleEdit()}
                />
            </div>
        );
    }

    public renderContent(list): React.ReactElement {
        return (
            <div className="w-100">
                <p className="m-0 fw-bold palette--c-neutral-6">Changes in Provider widget</p>
                <div className="p-2 display-none display-md-flex">
                    <h6 className="Property palette--c-neutral-5 fw-bold m-0">Property</h6>
                    <h6 className="CurrentData palette--c-neutral-5 fw-bold m-0">Current data</h6>
                    <span className="ArrowIcons material-icons palette--c-neutral-1 fw-bold">arrow_forward</span>
                    <h6 className="NewData palette--c-neutral-5 fw-bold m-0">New data</h6>
                </div>
                {list.map((item: any, index: number) => {
                    return this.renderFields(item, index);
                })}
            </div>
        );
    }

    public renderFields(field: any, index): React.ReactElement {
        const rejected: any = get(this.state, 'rejected', []);
        const editable: any = get(this.state, 'editable', []);

        const wrapperClassName = arrayToClass([
            rejected.includes(field.type)
                ? 'palette--bgc-neutral-3'
                : `palette--bgc-${
                      this.state.type === 'UPDATE' ? 'yellow-1' : this.state.type === 'CREATE' ? 'green-1' : 'red-1'
                  }`,
            'border-radius-1 my-2 p-2',
            'display-flex align-items-center',
        ]);

        const element = {
            type: field.type,
            content: this.getData(field.type),
            component: field.component,
        };

        return (
			<WidgetContributionPresenter
				isAdmin={this.state.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(field.type)}
				toValue={element}
				isEditable={editable.includes(field.type)}
				isRejected={rejected.includes(field.type)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				className={wrapperClassName}
			/>
		);
    }

    public getData(field): any {
        const form = this.form.generateJSON();
        const value = get(form, field, null);

        if (this.state.type === 'DELETE') {
            return null;
        }

        const bools: any[] = ['appliesToCommercial', 'appliesToInternational', 'tax'];
        return getData(field, value, true, bools);
    }

    public getOriginalData(field): any {
        const value = get(this.props.original, field, null);

        if (this.state.type === 'CREATE') {
            return null;
        }

        const bools: any[] = ['appliesToCommercial', 'appliesToInternational', 'tax'];
        return getData(field, value, false, bools);
    }

    private async handleEdit(type: string = 'editAll'): Promise<void> {
        const editable: any = get(this.state, 'editable', []);

        if (type === 'editAll') {
            const editableArray = fuelCostsFields(this).map((item: any) => item.type);
            await this.setState({ editable: editableArray });
        }

        if (!editable.includes(type)) {
            await this.setState({ editable: [...this.state.editable, type] });
        }
    }

    private async handleMissEdit(type: string): Promise<void> {
        const editable: any = get(this.state, 'editable', []);

        if (editable.includes(type)) {
            const editableArray = this.state.editable.filter((item) => item !== type);
            await this.setState({ editable: editableArray });
        }
    }

    private handleReject(type: string): void {
        const rejected: any = get(this.state, 'rejected', []);

        if (!rejected.includes(type)) {
            this.setState({ rejected: [...this.state.rejected, type] });
        }
    }

    private handleMissReject(type: string) {
        const rejected: any = get(this.state, 'rejected', []);

        if (rejected.includes(type)) {
            const rejectedArray = this.state.rejected.filter((item) => item !== type);
            this.setState({ rejected: rejectedArray });
        }
    }

    private clearForm(): void {
        this.form.clearForm();
    }

    private onSuccess(message: string): void {
        this.clearForm();
        toast.success(message, { theme: 'light' });
        this.props.onSuccess();
    }

    private onError(err): void {
        toast.error(<ToastMessages error={err} />, { theme: 'light' });
        redirectToLogin(err);
    }

    private async handleButtonClick(isReject = false): Promise<void> {
        this.setState({ buttonDisabled: true });
        let data = get(this.props, 'item');

        if (isReject) {
            data.contribution.status = 'REJECTED';
        } else {
            data = this.checkModifications();
        }

        try {
            const response = await RequestManager.post('/fuelcosts/merge', data);

            if (!response) {
                throw new Error('There was an error');
            }

            this.onSuccess(`Contribution is successfully ${isReject ? 'rejected' : 'accepted'}.`);
        } catch (err) {
            this.onError(err);
        } finally {
            this.setState({ buttonDisabled: false });
        }
    }

    private checkModifications(): any {
        const obj = get(this.props, 'item');
        const list = getFields(this, fuelCostsFields(this)) || [];
        const form = this.form.generateJSON();

        obj.contribution.rejectedChanges = this.state.rejected;
        obj.contribution.changes = checkData(list, obj, form, ['excludedCountries']);

        return obj;
    }
}

export const FuelFeesContribution: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    FuelFeesContributionComponent
);
