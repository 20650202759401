import * as React from 'react';
import { CoordinatesInput } from '../../../../ui-components/inputs/coordinates-input';
import { Input } from '../../../../ui-components/inputs/inputs/input';
import { MeasurementInput } from '../../../../ui-components/inputs/measure-input/measurement-input';
import { Select } from '../../../../ui-components/inputs/select/select';
import { SwitchSelect } from '../../../../ui-components/inputs/select/switch-select';
import { TextInput } from '../../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../../ui-components/inputs/select/formatter';
import { Checkbox } from '../../../../ui-components/inputs/checkboxes/checkbox/checkbox';

export const runwayOptions = {
	surfaceOpts: [
		{ value: 'ASPHALT', title: 'ASPHALT' },
		{ value: 'CONCRETE', title: 'CONCRETE' },
		{ value: 'GRAVEL', title: 'GRAVEL' },
		{ value: 'DIRT', title: 'DIRT' },
		{ value: 'GRASS', title: 'GRASS' },
		{ value: 'SNOW', title: 'SNOW' },
		{ value: 'WATER', title: 'WATER' },
	],
	vasiOpts: [
		{ value: 'NONE', title: 'NONE - No VASIS is installed or available.' },
		{
			value: 'P2L',
			title: 'P2L - PAPI with 2 lights installed left of the runway',
		},
		{
			value: 'P2R',
			title: 'P2R - PAPI with 2 lights installed right of the runway',
		},
		{ value: 'P2B', title: 'P2B - PAPI 2 lights to each side of the RWY' },
		{
			value: 'P4L',
			title: 'P4L - PAPI with 4 lights installed left of the runway',
		},
		{
			value: 'P4R',
			title: 'P4R - PAPI with 4 lights installed right of the runway',
		},
		{ value: 'P4B', title: 'P4B - PAPI 4 Lights to each side of the RWY' },
		{
			value: 'PSIL',
			title: 'PSIL - Pulsating/Steady burning VASI installed left of the runway',
		},
		{
			value: 'PSIR',
			title: 'PSIR - Pulsating/Steady burning VASI installed right of the runway',
		},
		{
			value: 'S2L',
			title: 'S2L - Simplified Abbreviated VASI with 2 lights installed left of the runway',
		},
		{
			value: 'S2R',
			title: 'S2R - Simplified Abbreviated VASI with 2 lights installed right of the runway',
		},
		{
			value: 'V2L',
			title: 'V2L - VASI with 2 lights installed left of the runway',
		},
		{
			value: 'V2R',
			title: 'V2R - VASI with 2 lights installed right of the runway',
		},
		{ value: 'V4L', title: 'V4L - VASI 4 Lights to the left of the RWY' },
		{
			value: 'V4R',
			title: 'V4R - VASI with 4 lights installed right of the runway',
		},
		{
			value: 'V6L',
			title: 'V6L - VASI with 6 lights installed left of the runway',
		},
		{
			value: 'V6R',
			title: 'V6R - VASI with 6 lights installed right of the runway',
		},
		{
			value: 'V12',
			title: 'V12 - VASI with 12 lights installed on both sides',
		},
		{
			value: 'V16',
			title: 'V16 - VASI with 16 lights installed on both sides',
		},
	],
	typeOpts: [
		{ value: 'R', title: 'R' },
		{ value: 'F', title: 'F' },
	],
	strengthOpts: [
		{ value: 'A', title: 'A' },
		{ value: 'B', title: 'B' },
		{ value: 'C', title: 'C' },
		{ value: 'D', title: 'D' },
	],
	tirePressureOpts: [
		{ value: 'W', title: 'W' },
		{ value: 'X', title: 'X' },
		{ value: 'Y', title: 'Y' },
		{ value: 'Z', title: 'Z' },
	],
	methodOpts: [
		{ value: 'T', title: 'T' },
		{ value: 'U', title: 'U' },
	],
	alcOpts: [
		{ value: 'NONE', title: 'NONE' },
		{ value: 'MALSR', title: 'MALSR' },
		{ value: 'MALSF', title: 'MALSF' },
		{ value: 'SALS', title: 'SALS' },
		{ value: 'SSALS', title: 'SSALS' },
		{ value: 'SSALR', title: 'SSALR' },
		{ value: 'SSALF', title: 'SSALF' },
		{ value: 'ODALS', title: 'ODALS' },
		{ value: 'ALSF1', title: 'ALSF1' },
		{ value: 'ALSF2', title: 'ALSF2' },
		{ value: 'ICAO1_HIALS', title: 'ICAO1 HIALS' },
		{ value: 'ICAO2_HIALS', title: 'ICAO2 HIALS' },
		{ value: 'LDIN', title: 'LDIN' },
		{ value: 'REIL', title: 'REIL' },
		{ value: 'RAIL', title: 'RAIL' },
		{ value: 'MALS', title: 'MALS' },
	],
};

export const runwayFields = (elmnt) => [
	{
		type: 'identifier',
		title: 'Identifier',
		component: (
			<TextInput
				field={elmnt.form.getField('identifier')}
				classes='NewData'
				textType='special'
				format={(text) => text.toUpperCase()}
			/>
		),
	},
	{
		type: 'magneticBearing',
		title: 'Magnetic bearing',
		component: (
			<Input
				field={elmnt.form.getField('magneticBearing')}
				classes='NewData'
				inputType='number'
			/>
		),
		unit: '°',
	},
	{
		type: 'trueBearing',
		title: 'True bearing',
		component: (
			<Input
				field={elmnt.form.getField('trueBearing')}
				classes='NewData'
				inputType='number'
			/>
		),
		unit: '°',
	},
	{
		type: 'helipad',
		title: 'Helipad',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('helipad')}
			/>
		),
	},
	{
		type: 'surface',
		title: 'Surface',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('surface')}
				options={formatOptions(runwayOptions.surfaceOpts)}
			/>
		),
	},
	{
		type: 'thresholdCoordinates.latitude',
		title: 'Threshold latitude',
		component: (
			<CoordinatesInput
				isContribute={true}
				lat={elmnt.form.getField('thresholdCoordinates.latitude')}
				classes='NewData'
			/>
		),
	},
	{
		type: 'thresholdCoordinates.longitude',
		title: 'Threshold longitude',
		component: (
			<CoordinatesInput
				isContribute={true}
				lng={elmnt.form.getField('thresholdCoordinates.longitude')}
				classes='NewData'
			/>
		),
	},
	{
		type: 'thresholdElevation',
		title: 'Threshold elevation',
		component: (
			<MeasurementInput
				field={elmnt.form.getField('thresholdElevation')}
				type='length'
				classes='NewData'
			/>
		),
		unit: 'm',
	},
	{
		type: 'slope',
		title: 'Slope',
		component: (
			<Input
				field={elmnt.form.getField('slope')}
				classes='NewData'
				inputType='number'
			/>
		),
		unit: '%',
		ignoreTransform: true,
	},
	{
		type: 'grooved',
		title: 'Grooved',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('grooved')}
			/>
		),
	},
	{
		type: 'tora',
		title: 'TORA',
		component: (
			<MeasurementInput
				classes='NewData'
				field={elmnt.form.getField('tora')}
				type='length'
			/>
		),
		unit: 'm',
	},
	{
		type: 'toda',
		title: 'TODA',
		component: (
			<MeasurementInput
				classes='NewData'
				field={elmnt.form.getField('toda')}
				type='length'
			/>
		),
		unit: 'm',
	},
	{
		type: 'asda',
		title: 'ASDA',
		component: (
			<MeasurementInput
				classes='NewData'
				field={elmnt.form.getField('asda')}
				type='length'
			/>
		),
		unit: 'm',
	},
	{
		type: 'lda',
		title: 'LDA',
		component: (
			<MeasurementInput
				classes='NewData'
				field={elmnt.form.getField('lda')}
				type='length'
			/>
		),
		unit: 'm',
	},
	{
		type: 'width',
		title: 'Width',
		component: (
			<MeasurementInput
				classes='NewData'
				field={elmnt.form.getField('width')}
				type='length'
			/>
		),
		unit: 'm',
	},
	{
		type: 'visualApproachSlopeIndicator',
		title: 'Visual Approach Slope Indicator System',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('visualApproachSlopeIndicator')}
				options={runwayOptions.vasiOpts}
			/>
		),
	},
	{
		type: 'pcnNumerical',
		title: 'Numerical',
		component: (
			<Input
				inputType='number'
				field={elmnt.form.getField('pcnNumerical')}
				classes='NewData'
			/>
		),
	},
	{
		type: 'pcnType',
		title: 'Type',
		component: (
			<Select
				classes='NewData'
				options={formatOptions(runwayOptions.typeOpts)}
				field={elmnt.form.getField('pcnType')}
			/>
		),
	},
	{
		type: 'pcnStrength',
		title: 'Strength',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('pcnStrength')}
				options={formatOptions(runwayOptions.strengthOpts)}
			/>
		),
	},
	{
		type: 'pcnTirePressure',
		title: 'Tire pressure',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('pcnTirePressure')}
				options={formatOptions(runwayOptions.tirePressureOpts)}
			/>
		),
	},
	{
		type: 'pcnMethod',
		title: 'Method',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('pcnMethod')}
				options={formatOptions(runwayOptions.methodOpts)}
			/>
		),
	},
	{
		type: 'approachLightConfiguration',
		title: 'Approach Light System',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('approachLightConfiguration')}
				options={formatOptions(runwayOptions.alcOpts)}
			/>
		),
	},
	{
		type: 'thresholdLights',
		title: 'Threshold lights',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('thresholdLights')}
			/>
		),
	},
	{
		type: 'touchDownZoneLights',
		title: 'Touch-Down lights',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('touchDownZoneLights')}
			/>
		),
	},
	{
		type: 'centerLights',
		title: 'Center Lights',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('centerLights')}
			/>
		),
	},
	{
		type: 'centerLightsSpacing',
		title: 'Centerline Lights Spacing',
		component: (
			<Input
				field={elmnt.form.getField('centerLightsSpacing')}
				classes='NewData'
				inputType='number'
			/>
		),
		unit: 'm',
	},
	{
		type: 'edgeLights',
		title: 'Edge lights',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('edgeLights')}
			/>
		),
	},
	{
		type: 'endLights',
		title: 'End lights',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('endLights')}
			/>
		),
	},
	{
		type: 'instrumental',
		title: 'Instrumental',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('instrumental')}
			/>
		),
	},
	{
		type: 'precision',
		title: 'Precision',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('precision')}
			/>
		),
	},
	{
		type: 'separate',
		title: 'Separate',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('separate')}
			/>
		),
	},
	{
		type: 'archived',
		title: 'Archived',
		component: (
			<Checkbox
				classes='NewData'
				field={elmnt.form.getField('archived')}
				labelInfos={{
					label: 'Permanently Closed',
				}}
			/>
		),
	},
	{
		type: 'standardPattern',
		title: 'Standard pattern',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('standardPattern')}
			/>
		),
	},
];
