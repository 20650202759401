import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from '../../../components/ui-components/dropdown/dropdown';
import { Form } from '../../../components/ui-components/form/form';
import { TextInput } from '../../../components/ui-components/inputs/inputs/text-input';
import { Tooltip } from '../../../components/ui-components/popover/popover';
import { Spinner } from '../../../components/ui-components/spinner/spinner';
import { StatusMarker } from '../../../components/ui-components/statusmarker/status-marker';
import { arrayToClass, debounce } from '../../../utilities/helper-fuctions';
import { RequestManager } from '../../../utilities/request';
import './search.scss';

const mapStateProps = (store: any) => ({
    search: store.localStorage.search,
});

const mapDispatchProps = (dispatch: any) => ({});

class SearchComponent extends React.Component<any, any> {
    public dd$: Dropdown;

    public form = new Form({
        query: { value: '', trigger: () => debounce(() => this.fetch(this.form.getValue('query')), 600)() },
    });

    public state = {
        hovered: null,
        loading: false,
        error: false,
        response: false,
    };

    public async fetch(search): Promise<any> {
        this.setState({ loading: true });

        const params = {
            query: search,
            size: 20,
        };

        try {
            const response = await RequestManager.get(
				'/airports/search?includeArchived=true',
				params
			);

            if (!response) {
                throw new Error('Error');
            }

            this.setState({ response: response });
        } catch (err) {
            this.setState({ error: err });
        } finally {
            this.setState({ loading: false });
        }
    }

    public render(): React.ReactElement {
        const classes = arrayToClass([this.props.className, 'AirportSearch w-100']);

        return (
            <div className={classes}>
                <Dropdown
                    backdropClasses="EditBackdrop palette--bgc-neutral-transparent"
                    ignoreMaxWidth={true}
                    ignoreMinWidth={true}
                    direction="left"
                    ignoreBackdrop={true}
                    ignoreTriggerClick={true}
                    trigger={() => this.renderSearch()}
                    dropdown={() => this.renderDropdown()}
                    ref={(ref: Dropdown) => (this.dd$ = ref)}
                    onBackdropClick={() => this.handleClose()}
                />
            </div>
        );
    }

    public renderSearch() {
        return (
            <TextInput
                field={this.form.getField('query')}
                inputClasses="SearchInput"
                textType="camel"
                onFocus={() => this.dd$.open()}
                placeholder="Start typing Location, ICAO or IATA"
            />
        );
    }

    public renderNotFound(): React.ReactElement {
        const form = this.form.getValue('query');

        return (
            <div className="w-100 display-flex flex-column justify-content-center align-items-center palette--bgc-neutral-1 p-6">
                <h4 className="m-0 palette--c-neutral-6 mb-6">We cant find {form}. Try something else!</h4>
            </div>
        );
    }

    public renderLoading(): React.ReactElement {
        return <Spinner size="large" />;
    }

    public renderDropdown() {
        const items = get(this.state, 'response.content', []);
        const query = this.form.getValue('query');

        return (
            <div className="AirportSearchDropdown mb-4 w-100 palette--bgc-neutral-1 border-radius-1 elevation-3">
                {!this.state.loading &&
                    !!items &&
                    !!items.length &&
                    !!query &&
                    items.map((item: any, index: number) => this.renderCard(item))}
                {!this.state.loading && (!items || !items.length) && !!query && this.renderNotFound()}
                {!this.state.loading && !query && this.renderBeforeType()}
                {this.state.loading && this.renderLoading()}
            </div>
        );
    }

    private renderBeforeType(): React.ReactElement {
        return (
            <div className="w-100 display-flex flex-column justify-content-center align-items-center palette--bgc-neutral-1 p-6">
                <h4 className="m-0 p-0 palette--c-neutral-6">
                    Start typing Location, ICAO or IATA and search results will appear here.
                </h4>
            </div>
        );
    }

    private renderCard(item: any): React.ReactElement {
        return (
            <div
                className="AirportCard w-100 palette--bgc-neutral-1 border-radius-1 display-flex flex-column flex-sm-row"
                key={item.aid}
                onClick={() => this.handleCardClick(item)}
            >
                <div className="w-100 px-4 py-2 display-flex flex-column">
                    <div className="display-flex mb-2">
                        <div className="flex-fill">{this.renderAid(get(item, 'aid'))}</div>
                    </div>
                    <h4 className="palette--c-neutral-6 fw-bold">{get(item, 'name', '')}</h4>
                    <div className="w-100 flex-fill display-flex align-items-end">
                        <div className="flex-fill">
                            {this.renderCodes(item)}
                            {this.renderCity(item)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderAid(aid): React.ReactElement {
        return (
            <Tooltip
                tooltip={this.renderPopover(
                    "The AID is the Aviowiki ID of this airport. It uniquely identifies any airport in the world, even if they don't have any other code."
                )}
                trigger={this.renderAidBadge(aid)}
            />
        );
    }

    private renderAidBadge(data: any) {
        return (
            <div className="display-inline-flex mb-1 cursor-pointer">
                <StatusMarker tColor="secondary-4" bgColor="secondary-1" text={data} type="tag" />
            </div>
        );
    }

    private renderCodes(item): React.ReactElement {
        const localIdentifier = get(item, 'localIdentifier', null);
        const iata = get(item, 'iata', null);
        const icao = get(item, 'icao', null);

        return (
            <div className="Codes display-flex mb-2">
                {localIdentifier && (
                    <Tooltip
                        tooltip={this.renderPopover('Local Identifier')}
                        trigger={this.renderPopoverChildren(localIdentifier)}
                    />
                )}
                {localIdentifier && (iata || icao) && <h4 className="palette--c-neutral-5 px-1 m-0 fw-bold">|</h4>}
                {iata && <Tooltip tooltip={this.renderPopover('IATA')} trigger={this.renderPopoverChildren(iata)} />}
                {iata && icao && <h4 className="palette--c-neutral-5 px-1 m-0 fw-bold">|</h4>}
                {icao && <Tooltip tooltip={this.renderPopover('ICAO')} trigger={this.renderPopoverChildren(icao)} />}
            </div>
        );
    }

    private renderCity(item): React.ReactElement {
        const country = get(item, 'country.name', null);
        const city = get(item, 'servedCity', null);
        const content = city ? city : country;
        const iso = get(item, 'country.iso2');

        const flagClasses = arrayToClass([
            'flag-map-marker',
            iso ? `fi fi-${iso.toLowerCase()}` : 'display-none',
            'mr-2',
        ]);

        return (
            <div className="display-flex align-items-center">
                <Tooltip tooltip={this.renderPopover(country)} trigger={<span className={flagClasses} />} />
                <p className="palette--c-neutral-5 m-0">{content}</p>
            </div>
        );
    }

    private renderPopover(description: any): any {
        return (
            <div className="PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1">
                <span className="palette--c-neutral-1">{description}</span>
            </div>
        );
    }

    private renderPopoverChildren(code: string) {
        return (
            <div className="pointer">
                <h4 className="palette--c-neutral-5 m-0 fw-bold">{code}</h4>
            </div>
        );
    }

    private handleClose(): void {
        this.form.setValue('query', '');
        this.dd$.close();
    }

    private handleCardClick(item) {
        this.handleClose();

        if (this.props.onClick) {
            this.props.onClick(item);
        }
    }
}

export const Search: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(SearchComponent);
