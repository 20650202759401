import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../actions/details.actions';
import { ManagesLayout } from '../../../layouts/manages/manages.layout';
import { BasicAdminScreen } from '../basic-admin/basic-admin.screen';
import '../basic-admin/basic-admin.screen.scss';

const mapStateProps = (store: any) => ({
    user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
    toggleMobile: () => dispatch(DetailsActions.toggleOpenMobile()),
});

class PlausibleComponent extends BasicAdminScreen<any, any> {
    public getTitle(): string {
        return 'Monitor plausible';
    }

    public getEndpoint() {
        return null;
    }

    public renderContent(): React.ReactElement {
        return (
            <div className="Contents w-100 border-radius-1">
                <iframe
                    src="https://plausible.io/share/app.aviowiki.com?auth=o93H-Rdwg-PZAvptKbVRl&embed=true"
                    scrolling="no"
                    frameBorder="0"
                    loading="lazy"
                    style={{ width: '1px', minWidth: '100%', height: '1600px', padding: '0 8px' }}
                ></iframe>
                <script async src="https://plausible.io/js/embed.host.js"></script>
            </div>
        );
    }

    public renderFooter() {
        return (
            <div style={{ fontSize: '14px', padding: '8px' }} className="position-absolute absolute-bottom-0">
                Stats powered by{' '}
                <a
                    target="_blank"
                    style={{ color: '#4F46E5', textDecoration: 'underline' }}
                    href="https://plausible.io"
                >
                    Plausible Analytics
                </a>
            </div>
        );
    }
}

export const Plausible: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    ManagesLayout(PlausibleComponent)
);
