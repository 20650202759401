import { connect } from 'react-redux';
import { LocalStorage } from '../../utilities/local-storage';
import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { CountryCard } from '../../components/cards/country/country-card';
import { ToastMessages } from '../../components/notifications/toast-messages';
import { RequestManager } from '../../utilities/request';
import { redirectToLogin } from '../../utilities/helper-fuctions';

const mapStateProps = (store: any) => ({
    lastSearchedCountries: store.userDetails.lastSearchedCountries,
});

const mapDispatchProps = (dispatch: any) => ({});

class RecommendedCountriesComponent extends React.Component<any, any> {
    public state = {
        recommendedCountries: [],
        loading: false,
        error: false,
    };

    public componentDidMount() {
        this.getThreeCountries();
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.lastSearchedCountries !== prevProps.lastSearchedCountries) {
            this.getThreeCountries();
        }
    }

    public async getThreeCountries() {
        this.setState({ loading: true });
        const lastSearched = this.props.lastSearchedCountries || [];

        if (lastSearched.length > 0) {
            await this.updateLastSearch();
        } else {
            await this.updateNearbyCountries();
        }

        if (get(this.state, 'recommendedCountries').length === 0) {
            this.getRandomCountries();
        }
        this.setState({ loading: false });
    }

    public async getRandomCountries() {
        try {
            const response = await RequestManager.get('/countries', { size: 3 });

            if (!response) {
                throw new Error('missing.country');
            }

            this.setState({
                recommendedCountries: response.content,
                type: 'Countries',
            });
        } catch (err) {
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
            redirectToLogin(err);
        }
    }

    public async updateLastSearch() {
        try {
            const lastSearchedIsos = LocalStorage.getItem('lastSearchedCountries') || [];
            const countries: any[] = [];

            for (const iso of lastSearchedIsos) {
                const response = await RequestManager.get(`/countries/iso3/${iso}`);

                if (!response) {
                    throw new Error('missing.countries');
                }

                countries.push(response);
            }

            this.setState({
                recommendedCountries: countries,
                type: 'last searched',
            });
        } catch (e) {
            // silence is golden
        }
    }

    public async updateNearbyCountries() {
        try {
            const position = await this.getPosition();

            const result = await RequestManager.get('/countries/coordinates', {
                lat: position.coords.latitude,
                lon: position.coords.longitude,
                size: 3,
            });

            if (!result || !result.content) {
                throw new Error('something.went.wrong');
            }

            this.setState({
                recommendedCountries: result.content,
                type: 'nearby airports',
            });
        } catch (e) {
            // silence is golden
        }
    }

    public getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position: any) => resolve(position),
                (err) => reject(err)
            );
        });
    }

    public render(): React.ReactElement {
        return (
            <div className="LastSearchedContainer w-100 mt-8">
                {this.state.loading}
                <h6 className="text-uppercase palette--c-neutral-1 mb-4">{get(this.state, 'type', 'last searched')}</h6>

                {(get(this.state, 'recommendedCountries', false) || []).map((item: any, index: number) => (
                    <CountryCard
                        key={`${item.aid}-${index}`}
                        country={item}
                        className="mb-4 elevation-1"
                        onClick={() => this.props.onClick && this.props.onClick(get(item, 'iso3'))}
                    />
                ))}
            </div>
        );
    }
}

export const RecommendedCountries: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    RecommendedCountriesComponent
);
