import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../actions/details.actions';
import { connectNLP } from '../../utilities/connect-navigate';
import { arrayToClass, isInQuery } from '../../utilities/helper-fuctions';
import { logoLink } from '../../utilities/variables';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';
import { MainHeaderButtons } from './main-header-buttons/main-header-buttons';

const mapStateProps = (store: any) => ({
    user: store.user.user,
    open: store.details.open,
});

const mapDispatchProps = (dispatch: any) => ({
    setOpen: (bool: boolean) => dispatch(DetailsActions.setOpen(bool)),
    setOpenMobile: (bool: boolean) => dispatch(DetailsActions.setOpenMobile(bool)),
    toggleMobile: () => dispatch(DetailsActions.toggleOpenMobile()),
});

class HeaderComponent extends React.Component<any, any> {
    public render(): React.ReactElement {
        const isAdmin = (get(this.props.user, 'roles') || []).includes('ADMIN');
        const classes = arrayToClass(['Header', 'w-100']);

        const imageContainerClasses = arrayToClass([
            'ImageContainer display-flex align-items-center pointer',
            this.props?.open ? 'imageOpen' : 'imageClosed',
        ]);

        return (
            <div className={classes}>
                <div className="HeaderTitle my-3 w-100 display-flex align-items-center">
                    <div className={imageContainerClasses} onClick={(ev: any) => this.handleClick(ev)}>
                        <img src={logoLink} alt="aviowiki" className="AvioLogo display-lg-flex" />
                    </div>
                    <div className="flex-fill display-flex display-lg-none justify-content-center" />
                    <div className="flex-fill display-none display-lg-flex justify-content-center">
                        <h2 className="PageTitle palette--c-neutral-6 fw-bold m-0 mr-4">{this.props.title}</h2>
                    </div>
                    <div className="ButtonsContainer display-flex justify-content-end">
                        {this.renderButton()}
                        <MainHeaderButtons />
                    </div>
                </div>
                <div className="w-100 display-block display-lg-none">
                    <div className="display-flex display-lg-none">
                        <h2 className="PageTitle palette--c-neutral-6 fw-bold m-0 mr-4">{this.props.title}</h2>
                    </div>
                </div>
                {isAdmin && (
                    <div className="display-lg-none w-100 display-flex justify-content-start py-3">
                        <HoverableButton
                            type="button"
                            className="border-radius-1"
                            colorType="contrast"
                            onClick={() => this.props.toggleMobile && this.props.toggleMobile()}
                            titleLeft="Menu"
                            icon="arrow_forward"
                        />
                    </div>
                )}
            </div>
        );
    }

    private handleClick(event: any): void {
        if (this.props.handleClick) {
            this.props.handleClick();
        } else {
            const utmSource = isInQuery('utm_source') || '';
            this.props?.router?.navigate({ pathname: '/', search: utmSource });
            this.props?.setOpen(false);
            this.props?.setOpenMobile(false);
        }
    }

    private renderButton(): any {
        if (this.props.renderButton) {
            return this.props.renderButton();
        }

        return null;
    }
}

export const Header: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    connectNLP(HeaderComponent)
);
