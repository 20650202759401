import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../../actions/details.actions';
import { Spinner } from '../../../ui-components/spinner/spinner';
import '../../airport/operational-notes/operational-notes.scss';
import { OperationalNotesCard } from '../../shared/notes/operational-notes-card';
import { Widget } from '../../widget';

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
	setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

export class CountryOperationalNotesComponent extends Widget<any, any> {
	public modal$;

	public getEndpoint(): string {
		return `/countries/${get(
			this.props,
			'country.iso3'
		)}/operationalnotes/all`;
	}

	public renderContent(): React.ReactElement {
		if (this.state.loading || (!this.state.response && !this.state.error)) {
			return <Spinner size='large' />;
		}

		if (this.state.error) {
			return (
				<div className='w-100 display-flex align-items-center justify-content-center py-6'>
					<p className='m-0 palette--c-neutral-4'>
						{this.state.error}
					</p>
				</div>
			);
		}

		const notes = get(this.state, 'response', []);

		return <OperationalNotesCard items={notes} />;
	}
}

export const CountryOperationalNotes: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{
		forwardRef: true,
	}
)(CountryOperationalNotesComponent);
