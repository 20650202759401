import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../../../actions/details.actions';
import {
	arrayToClass,
	redirectToLogin,
} from '../../../../utilities/helper-fuctions';
import { PreLoader } from '../../../../utilities/pre-loader';
import { RequestManager } from '../../../../utilities/request';
import { request } from '../../../../utilities/widget-helpers/request';
import { MapDrawing } from '../../../map/map-drawing/map-drawing';
import { ToastMessages } from '../../../notifications/toast-messages';
import { Form } from '../../../ui-components/form/form';
import { HoverableButton } from '../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Checkbox } from '../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { TextInput } from '../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../ui-components/inputs/select/formatter';
import { SearchableSelect } from '../../../ui-components/inputs/select/searchable-select';
import { Select } from '../../../ui-components/inputs/select/select';
import { Tooltip } from '../../../ui-components/popover/popover';
import { Spinner } from '../../../ui-components/spinner/spinner';
import { WidgetModal } from '../../widget-modal';
import { ListContacts } from './authority-contacts/authority-contact-form/list-contacts';
import {
	countryTypes,
	services,
	types,
	visaProcessed,
} from './authority-contrtibution/authority-helper';
import './authority-details.scss';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
	mergeContribution: (data, endpoint, widgetId, callback) =>
		dispatch(
			DetailsActions.mergeContribution(data, endpoint, widgetId, callback)
		),
});

class AuthorityEditComponent extends WidgetModal<any, any> {
	public contacts$;

	public state: any = {
		...this.state,
		isCustom: this.props?.response?.type === 'CUSTOMS',
		disableDelete: true,
		isContactsChanged: true,
		region: false,

		flightOther: false,
		passengerOther: false,
		crewOther: false,

		flightsCountry: false,
		passengersCountry: false,
		crewCountry: false,

		title: null,
		field: null,
		multi: false,
		formType: null,
		isMapDrawOpen: false,
	};

	public form = new Form(
		{
			name: { value: this.props?.response?.name || '', mandatory: true },
			type: {
				value: this.props?.response?.type || '',
				trigger: () =>
					this.setState({
						isCustom: this.form.getValue('type') === 'CUSTOMS',
					}),
				mandatory: true,
			},
			landsideArea: {
				value: this.props?.response?.landsideArea || '',
				trigger: () => this.forceUpdate(),
			},
			airsideArea: {
				value: this.props?.response?.airsideArea || '',
				trigger: () => this.forceUpdate(),
			},
		},
		(changed) => this.handleFormChange(changed, true)
	);

	public customForm = new Form(
		{
			allowCrewFromCountries:
				this.props?.response?.customDetails?.allowCrewFromCountries ||
				'',
			allowCrewFromRegions:
				this.props?.response?.customDetails?.allowCrewFromRegions || '',
			allowPassengersFromCountries:
				this.props?.response?.customDetails
					?.allowPassengersFromCountries || '',
			allowPassengersFromRegions:
				this.props?.response?.customDetails
					?.allowPassengersFromRegions || '',
			allowFlightsFromCountries:
				this.props?.response?.customDetails
					?.allowFlightsFromCountries || '',
			allowFlightsFromRegions:
				this.props?.response?.customDetails?.allowFlightsFromRegions ||
				'',
			services: this.props?.response?.customDetails?.services || '',
			visaProcessed:
				this.props?.response?.customDetails?.visaProcessed || '',
		},
		(changed) => this.handleFormChange(changed)
	);

	public helper = new Form({
		allowCrewFromRegions: {
			value: !!this.props?.response?.customDetails?.allowCrewFromRegions,
			trigger: () => this.handleToggle('Crew'),
		},
		allowPassengersFromRegions: {
			value: !!this.props?.response?.customDetails
				?.allowPassengersFromRegions,
			trigger: () => this.handleToggle('Passengers'),
		},
		allowFlightsFromRegions: {
			value: !!this.props?.response?.customDetails
				?.allowFlightsFromRegions,
			trigger: () => this.handleToggle('Flights'),
		},
	});

	public handleToggle(type) {
		const stateName = `${type.toLowerCase()}Country`;
		const helperField = `allow${type}FromRegions`;
		const fieldName = `allow${type}FromCountries`;
		const value = this.helper.getValue(helperField);

		if (!!value) {
			if (this.state.region) {
				this.setState({ [stateName]: true });
				this.customForm.setValue(helperField, this.state.region);
			} else {
				this.fetchRegion(helperField, stateName);
			}

			this.customForm.setValue(fieldName, []);
		} else {
			this.customForm.setValue(helperField, []);
			this.setState({ [stateName]: false });
		}
	}

	public handleFormChange(changed, isForm = false) {
		const formValid = this.form.validateMandatory();
		const otherForm = isForm ? this.customForm : this.form;
		const isContactsChanged = this.state?.isContactsChanged;
		const isOtherFormChanged =
			formValid &&
			(changed || otherForm.hasChanged() || isContactsChanged);

		this.setState({ buttonDisabled: !isOtherFormChanged });
	}

	public isAdmin(): any {
		if (this.props.user) {
			return get(this.props.user, 'roles', []).includes('ADMIN');
		}

		return false;
	}

	public async fetchRegion(field, stateField) {
		try {
			const response = await RequestManager.get('/regions/search', {
				query: 'Worldwide',
			});

			if (!response) {
				throw new Error('Error in region loading');
			}

			if (!(get(response, 'content', []) || []).length) {
				throw new Error(
					'We could not find our Worldwide region, sorry'
				);
			}

			const region = (get(response, 'content') || []).filter(
				(item) => item.aid === 'RGN-WRLD-WIDE'
			);

			await this.customForm.setValue(field, region);
			this.setState({ region: region, [stateField]: true });
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			await this.customForm.setValue(field, []);
			await this.helper.setValue(field, false);
			this.setState({ [stateField]: false });
		}
	}

	public getTitle() {
		return this.props?.isEdit ? 'Edit authority' : 'Add new authority';
	}

	public renderForm(): React.ReactElement {
		return (
			<div className='CreateAuthority'>
				<form>
					<div className='p-4'>
						<div className='display-flex flex-column flex-md-row'>
							<TextInput
								field={this.form.getField('name')}
								classes='py-2'
								textType='camel'
								labelInfos={{ label: 'Name' }}
							/>
							<Select
								field={this.form.getField('type')}
								classes='left-m py-2'
								options={formatOptions(
									this.props.isCountry ? countryTypes : types,
									false
								)}
								labelInfos={{
									label: 'Type',
								}}
							/>
						</div>

						{!this.props.isCountry && (
							<div>
								<label className=''>Landside location</label>
								<div className='display-flex align-items-center'>
									<HoverableButton
										type='button'
										className='border-radius-1'
										colorType='avio-green'
										title='Edit landside location '
										onClick={() =>
											this.onClick(
												'Landside area polygon',
												'landsideArea',
												'form',
												false
											)
										}
									/>
									{!!this.form.getValue('landsideArea') && (
										<span className='CheckMapValues material-icons palette--c-primary-4 ml-4'>
											check
										</span>
									)}
								</div>

								<label className=''>Airside location</label>
								<div className='display-flex align-items-center'>
									<HoverableButton
										type='button'
										className='border-radius-1'
										colorType='avio-green'
										title='Edit airside location'
										onClick={() =>
											this.onClick(
												'Airside area polygon',
												'airsideArea',
												'form',
												false
											)
										}
									/>
									{!!this.form.getValue('airsideArea') && (
										<span className='CheckMapValues material-icons palette--c-primary-4 ml-4'>
											check
										</span>
									)}
								</div>
							</div>
						)}
					</div>
				</form>

				{this.state.isCustom && !this.props.isCountry && (
					<form>
						<div className='p-4'>
							<label className='py-2'>
								Crew can be cleared from:
							</label>
							<div className='FormContainer align-items-end'>
								<SearchableSelect
									isMulti={true}
									disabled={this.state.crewCountry}
									value='name'
									title='name'
									classes='py-2'
									field={this.customForm.getField(
										'allowCrewFromCountries'
									)}
									endpoint={'/free/countries/search'}
									labelInfos={{ label: 'Countries' }}
								/>
								<Checkbox
									field={this.helper.getField(
										'allowCrewFromRegions'
									)}
									labelInfos={{ label: 'Worldwide' }}
									classes='py-1'
									labelOnLeft={true}
								/>
							</div>
							<div className='pt-4'>
								<HoverableButton
									type='button'
									className='border-radius-1'
									colorType='avio-green'
									title='Other'
									onClick={() =>
										this.setState({
											crewOther: !this.state.crewOther,
										})
									}
								/>
								{this.state.crewOther && (
									<span className='CheckMapValues palette--c-neutral-5 pt-2'>
										To add a missing Region or request the
										creation of a Region, please send an
										email to&nbsp;
										<a
											href='mailto:support@aviowiki.com'
											className='palette--c-blue-2 text-decoration-none'
										>
											support@aviowiki.com
										</a>
										.
									</span>
								)}
							</div>
						</div>

						<div className='p-4'>
							<label className='py-2'>
								Passengers can be cleared from:
							</label>
							<div className='FormContainer align-items-end'>
								<SearchableSelect
									isMulti={true}
									disabled={this.state.passengersCountry}
									value='name'
									title='name'
									classes='py-2'
									field={this.customForm.getField(
										'allowPassengersFromCountries'
									)}
									endpoint={'/free/countries/search'}
									labelInfos={{ label: 'Countries' }}
								/>
								<Checkbox
									field={this.helper.getField(
										'allowPassengersFromRegions'
									)}
									labelInfos={{ label: 'Worldwide' }}
									labelOnLeft={true}
									classes='py-1'
								/>
							</div>

							<div className='pt-4'>
								<HoverableButton
									colorType='avio-green'
									type='button'
									className='border-radius-1'
									title='Other'
									onClick={() =>
										this.setState({
											passengerOther:
												!this.state.passengerOther,
										})
									}
								/>
								{this.state.passengerOther && (
									<span className='CheckMapValues palette--c-neutral-5 pt-2'>
										To add a missing Region or request the
										creation of a Region, please send an
										email to&nbsp;
										<a
											href='mailto:support@aviowiki.com'
											className='palette--c-blue-2 text-decoration-none'
										>
											support@aviowiki.com
										</a>
										.
									</span>
								)}
							</div>
						</div>

						<div className='p-4'>
							<label className='py-2'>
								Flights can be cleared from:
							</label>
							<div className='FormContainer align-items-end'>
								<SearchableSelect
									isMulti={true}
									disabled={this.state.flightsCountry}
									value='name'
									title='name'
									classes='py-2'
									field={this.customForm.getField(
										'allowFlightsFromCountries'
									)}
									endpoint={'/free/countries/search'}
									labelInfos={{ label: 'Countries' }}
								/>
								<Checkbox
									field={this.helper.getField(
										'allowFlightsFromRegions'
									)}
									labelInfos={{ label: 'Worldwide' }}
									labelOnLeft={true}
									classes='py-1'
								/>
							</div>

							<div className='pt-4'>
								<HoverableButton
									type='button'
									className='border-radius-1'
									colorType='avio-green'
									title='Other'
									onClick={() =>
										this.setState({
											flightOther:
												!this.state.flightOther,
										})
									}
								/>
								{this.state.flightOther && (
									<span className='CheckMapValues palette--c-neutral-5 pt-2'>
										To add a missing Region or request the
										creation of a Region, please send an
										email to&nbsp;
										<a
											href='mailto:support@aviowiki.com'
											className='palette--c-blue-2 text-decoration-none'
										>
											support@aviowiki.com
										</a>
										.
									</span>
								)}
							</div>
						</div>

						<div className='p-4'>
							<Select
								classes='py-2'
								field={this.customForm.getField('services')}
								isMulti={true}
								options={formatOptions(services)}
								labelInfos={{
									label: 'Services',
								}}
							/>
							<Select
								classes='py-2'
								field={this.customForm.getField(
									'visaProcessed'
								)}
								isMulti={true}
								options={formatOptions(visaProcessed)}
								labelInfos={{
									label: 'Visa processed',
								}}
							/>
						</div>
					</form>
				)}

				<ListContacts
					innerRef={(ref: any) => (this.contacts$ = ref)}
					endpoint={`/${
						this.props.isCountry
							? 'countryauthorities'
							: 'airportauthorities'
					}/${get(this.props, 'aid')}/${
						this.props.isCountry
							? 'countryAuthorityContacts'
							: 'airportAuthorityContacts'
					}/all`}
					isEdit={this.props?.isEdit}
					handleSuccessModal={() => this.handleSuccessModal()}
					disableDelete={(isDisabled) =>
						this.setState({ disableDelete: isDisabled })
					}
					setChange={(validity) =>
						this.setState({ isContactsChanged: validity }, () =>
							this.handleFormChange(false)
						)
					}
				/>

				{!!this.state?.isMapDrawOpen && (
					<MapDrawing
						isMulti={this.state.multi}
						title={this.state.title}
						field={this.getField()}
						polygon={this.getField().newValue}
						endpoint={`/airports/${this.props?.router?.params?.aid}`}
						isEdit={true}
						onClose={() => this.setState({ isMapDrawOpen: false })}
					/>
				)}
			</div>
		);
	}

	public async onClick(title, field, formType, multi: boolean = true) {
		this.setState({
			title: title,
			field: field,
			formType: formType,
			multi: multi,
			isMapDrawOpen: true,
		});
	}

	public getField() {
		const form = this[this.state.formType];

		if (!form) {
			return false;
		}

		return form.getField(this.state.field);
	}

	public renderFooter(): React.ReactElement {
		return (
			<div className='w-100 display-flex p-4'>
				{!!this.props?.isEdit && (
					<Tooltip
						isOpen={!!this.state?.deleteHovered}
						tooltip={this.renderPopover()}
						trigger={
							<div
								onMouseEnter={() =>
									!!this.state?.disableDelete &&
									this.setState({ deleteHovered: true })
								}
								onMouseLeave={() =>
									!!this.state?.disableDelete &&
									this.setState({ deleteHovered: false })
								}
							>
								<HoverableButton
									disabled={
										this.state?.disableDelete ||
										this.state?.saveLoading ||
										false
									}
									className='mr-2'
									colorType={
										this.state?.disableDelete ||
										this.state?.saveLoading
											? 'disabled'
											: 'delete'
									}
									title='Delete'
									onClick={() => this.deleteModal$.open()}
								/>
							</div>
						}
					/>
				)}
				<div className='display-flex align-items-center justify-content-end flex-fill'>
					<HoverableButton
						className='border-radius-1 mr-2'
						colorType={
							this.state?.saveLoading ? 'disabled' : 'cancel'
						}
						title='Cancel'
						onClick={() => this.onClose()}
					/>
					<HoverableButton
						disabled={this.props.buttonDisabled}
						className='border-radius-1'
						colorType={
							this.state?.buttonDisabled
								? 'disabled'
								: 'avio-green'
						}
						renderChildren={(colors) =>
							this.renderButtonChildren(colors)
						}
						onClick={() => this.onSave()}
					/>
				</div>
			</div>
		);
	}

	public renderButtonChildren(colors) {
		if (this.state?.saveLoading) {
			return <Spinner size='small' className='position-absolute' />;
		} else {
			const classes = arrayToClass(['mr-1 mb-0', colors]);
			return (
				<div className='w-100 display-flex align-items-center'>
					<span className={classes}>Send</span>
				</div>
			);
		}
	}

	public renderPopover() {
		return (
			<div className='PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1 display-flex'>
				<p className='palette--c-neutral-1 m-0'>
					You have to remove all the contacts first to be able to
					delete this authority.
				</p>
			</div>
		);
	}

	public getType() {
		return 'authority';
	}

	public onClose(isDelete = false): void {
		if (this.props.setModalDetails) {
			this.props.setModalDetails({ authority: this.state.response });
		}

		if (this.props.onClose) {
			this.props.onClose(isDelete);
		}

		this.modal$.close();
		this.setState({ buttonDisabled: false });
	}

	public async onDelete(): Promise<void> {
		this.setState({ buttonDisabled: true, saveLoading: true });

		await request(
			`/${this.props.isCountry ? 'country' : 'airport'}authorities/merge`,
			'authority-delete',
			() => this.handleSuccessModal(true),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		).deleteF(
			`/${this.props.isCountry ? 'country' : 'airport'}authorities/${
				this.props?.aid
			}`,
			[
				...this.form.getFieldsWhereHasValue(),
				...this.customForm.getFieldsWhereHasValue(),
			]
		);

		this.setState({ buttonDisabled: false, saveLoading: false });
	}

	public generateCustomProperties() {
		const { crewCountry, flightsCountry, passengersCountry } = this.state;
		const custom: any = this.customForm.generateJSON();

		custom[`allowCrewFrom${crewCountry ? 'Countries' : 'Regions'}`] = null;
		custom[`allowFlightsFrom${flightsCountry ? 'Countries' : 'Regions'}`] =
			null;
		custom[
			`allowPassengersFrom${passengersCountry ? 'Countries' : 'Regions'}`
		] = null;

		return custom;
	}

	public checkData(): any {
		const obj: any = this.form.generateJSON();
		const custom = this.generateCustomProperties();
		obj.customsDetails = this.state.isCustom ? custom : null;

		if (this.props.isCountry) {
			delete obj.customsDetails;
			delete obj.landsideArea;
			delete obj.airsideArea;
		}

		return obj;
	}

	public async onSave() {
		this.setState({ buttonDisabled: true, saveLoading: true });
		const authority = await this.checkData();

		if (this.props?.isEdit) {
			this.handleEditSave(authority);
		} else {
			this.handleCreateSave(authority);
		}
	}

	public async handleCreateSave(authority): Promise<void> {
		const aid = this.props?.aid;

		if (!aid) {
			return;
		}

		try {
			const isCountry = this.props.isCountry;

			if (isCountry) {
				const parent = await this.getParentCountry();
				authority.parent = parent;
			}

			const response = await RequestManager.post(
				`/${isCountry ? 'country' : 'airport'}authorities`,
				authority,
				{
					[isCountry ? 'parentId' : 'parentAid']: aid,
				}
			);

			if (!response) {
				throw new Error('Error in create authority');
			}

			this.onSuccess(response);
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			redirectToLogin(err);
		} finally {
			this.setState({ buttonDisabled: false, saveLoading: false });
		}
	}

	public async handleEditSave(authority): Promise<void> {
		const aid = get(this.props, 'aid');

		if (!aid) {
			return;
		}

		if (!this.form.hasChanged() && !this.customForm.hasChanged()) {
			await this.handleContactsSave(aid, true);
			return;
		}

		const data = { ...this.state.response, ...authority };

		try {
			const response = await RequestManager.put(
				`/${this.props.isCountry ? 'country' : 'airport'}authorities/${
					this.props.aid
				}`,
				data
			);

			if (!response) {
				return;
			}

			this.onSuccess(response, data);
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			redirectToLogin(err);
		} finally {
			this.setState({ buttonDisabled: false });
		}
	}

	public async onSuccess(response, isDelete = false) {
		if (
			(get(this.props.user, 'roles') || []).includes(
				'AUTO_MERGE_CONTRIBUTIONS'
			)
		) {
			response.contribution.changes = [
				...this.form.getDifferences(),
				...this.customForm.getDifferences(),
			];

			this.props.mergeContribution(
				response,
				`/${
					this.props.isCountry ? 'country' : 'airport'
				}authorities/merge`,
				'authority',
				async (aid) => await this.handleContactsSave(aid, true)
			);
		} else {
			if (isDelete) {
				this.handleSuccessModal();
			} else {
				await this.handleContactsSave(response?.aid || response?.id);
			}
		}
	}

	public async handleContactsSave(id, isMerge = false) {
		await this.contacts$?.handleSaveContacts(id, isMerge);
	}

	public async getParentCountry() {
		const iso = this.props.parentAid;

		const response = await RequestManager.get(`/countries/iso3/${iso}`);

		if (!response) {
			throw new Error('There is no any country with this iso.');
		}

		return response;
	}

	public handleSuccessModal(isDelete = false) {
		if (this.notif$) {
			this.notif$.open(isDelete);
		}

		if (this.modal$) {
			this.modal$.close();
		}

		if (this.deleteModal$) {
			this.deleteModal$.close();
		}
	}
}

export const AuthorityForm: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(PreLoader(AuthorityEditComponent));
