import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
	checkData,
	getData,
	getFields,
} from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import {
	arrayToClass,
	redirectToLogin,
} from '../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../utilities/request';
import { ToastMessages } from '../../../../notifications/toast-messages';
import { Form } from '../../../../ui-components/form/form';
import { ContributionFooter } from '../../../shared/contributions/contribution-footer';
import { WidgetContributionPresenter } from '../../../shared/contributions/widget-contribution-presenter/widget-contribution-presenter';
import { airportFields, airportOptions } from './airport-contribution-helpers';

interface AirportContributionProps {
	item: any;
	original: any;
	onSuccess: () => void;
	user?: any;
	close: () => void;
}

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class AirportContributionComponent extends React.Component<
	AirportContributionProps,
	any
> {
	public state: any = {
		isAdmin: get(this.props, 'user.roles', []).includes('ADMIN'),
		type: this.props?.item?.contribution?.action,
		editable: [],
		rejected: [],
		buttonDisabled: false,
	};

	public form: any = new Form({
		name: this.props?.item?.name,
		icao: this.props?.item?.icao,
		iata: this.props?.item?.iata,
		localIdentifier: this.props?.item?.localIdentifier,
		elevation: this.props?.item?.elevation,
		variation: this.props?.item?.variation,
		'coordinates.latitude': this.props?.item?.coordinates?.latitude,
		'coordinates.longitude': this.props?.item?.coordinates?.longitude,
		country: this.props?.item?.country,
		servedCity: this.props?.item?.servedCity,
		timeZone: this.props?.item?.timeZone,
		referenceWeatherStation: this.props?.item?.referenceWeatherStation,
		type: this.props?.item?.type,
		operator: this.props?.item?.operator,
		accessibleFor: this.props?.item?.accessibleFor,
		ifr: this.props?.item?.ifr,
		fuelsAvailable: this.props?.item?.fuelsAvailable,
		mandatoryHandling: this.props?.item?.mandatoryHandling,
		mandatoryQualification: this.props?.item?.mandatoryQualification,
		qualificationLink: this.props?.item?.qualificationLink,
		qualificationNotes: {
			value: this.props?.item?.qualificationNotes,
			trigger: () => this.checkString(),
		},
		nonScheduledPermission: this.props?.item?.nonScheduledPermission,
		nonScheduledPermissionNotes:
			this.props?.item?.nonScheduledPermissionNotes,
		paxLimit: this.props?.item?.paxLimit,
		weightLimit: this.props?.item?.weightLimit,
		wingspanLimit: this.props?.item?.wingspanLimit,
		archived: this.props?.item.archived,
	});

	public checkString() {
		const value = this.form.getValue('qualificationNotes');

		if (value.length > 255) {
			this.form.setValue('qualificationNotes', value.slice(0, 254));
		}
	}

	public render(): React.ReactElement {
		const airportList = getFields(this, airportFields(this));

		return (
			<div className='Content w-100'>
				<div className='p-2'>
					{(!airportList || !airportList.length) &&
						this.renderEmpty()}
					{!!airportList.length && this.renderContent(airportList)}
				</div>
				<ContributionFooter
					buttonDisabled={this.state.buttonDisabled}
					isAdmin={this.state.isAdmin}
					handleButtonClick={(isReject: boolean = false) =>
						this.handleButtonClick(isReject)
					}
					close={() => this.props.close()}
					handleEdit={() => this.handleEdit()}
				/>
			</div>
		);
	}

	public renderContent(list): React.ReactElement {
		return (
			<div className='w-100'>
				<p className='m-0 fw-bold palette--c-neutral-6'>
					Changes in General data widget
				</p>
				<div className='p-2 display-none display-md-flex'>
					<h6 className='Property palette--c-neutral-5 fw-bold m-0'>
						Property
					</h6>
					<h6 className='CurrentData palette--c-neutral-5 fw-bold m-0'>
						Current data
					</h6>
					<span className='ArrowIcons material-icons palette--c-neutral-1 fw-bold'>
						arrow_forward
					</span>
					<h6 className='NewData palette--c-neutral-5 fw-bold m-0'>
						New data
					</h6>
				</div>
				{list.map((item: any, index: number) => {
					return this.renderChangedFields(item, index);
				})}
			</div>
		);
	}

	public renderEmpty(): React.ReactElement {
		return (
			<div className='display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2'>
				<p className='palette--c-neutral-5 m-0'>Nothing changed.</p>
			</div>
		);
	}

	public async handleEdit(type: string = 'editAll'): Promise<void> {
		if (type === 'editAll') {
			const editableArray = airportFields(this).map(
				(item: any) => item.type
			);
			await this.setState({ editable: editableArray });
		}

		if (!this.state.editable.includes(type)) {
			await this.setState({ editable: [...this.state.editable, type] });
		}
	}

	private renderChangedFields(field, index): any {
		const wrapperClassName = arrayToClass([
			this.state.rejected.includes(field.type)
				? 'palette--bgc-neutral-3'
				: `palette--bgc-${
						this.state.type === 'UPDATE'
							? 'yellow-1'
							: this.state.type === 'CREATE'
							? 'green-1'
							: 'red-1'
				  }`,
			'border-radius-1 my-2 p-2',
			'display-flex align-items-center',
		]);

		const element = {
			type: field.type,
			content: this.getData(field.type),
			component: field.component,
			unit: field.unit,
			...field,
		};

		return (
			<WidgetContributionPresenter
				isAdmin={this.state.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(field.type)}
				toValue={element}
				isEditable={(this.state.editable || []).includes(field.type)}
				isRejected={(this.state.rejected || []).includes(field.type)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				className={wrapperClassName}
			/>
		);
	}

	private getOriginalData(field: any) {
		let value = this.props?.original?.[field];

		if (this.state.type === 'CREATE') {
			return null;
		}

		if (field === 'ifr' && value !== null) {
			const opt = airportOptions?.ifr?.find(
				(opt) => opt?.value === value
			);
			value = opt?.title;
		}

		return getData(field, value);
	}

	private getData(field: any): any {
		const form = this.form.generateJSON();
		let value = get(form, field, null);

		if (this.state.type === 'DELETE') {
			return null;
		}

		if (field === 'ifr' && value !== null) {
			const opt = airportOptions?.ifr?.find(
				(opt) => opt?.value === value
			);
			value = opt?.title;
		}

		return getData(field, value, true);
	}

	private async handleMissEdit(type: string): Promise<void> {
		if (this.state.editable.includes(type)) {
			const editableArray = this.state.editable.filter(
				(item) => item !== type
			);
			await this.setState({ editable: editableArray });
		}
	}

	private handleReject(type: string): void {
		if (!this.state.rejected.includes(type)) {
			this.setState({ rejected: [...this.state.rejected, type] });
		}
	}

	private handleMissReject(type: string) {
		if (this.state.rejected.includes(type)) {
			const rejectedArray = this.state.rejected.filter(
				(item) => item !== type
			);
			this.setState({ rejected: rejectedArray });
		}
	}

	private checkModifications(): any {
		const obj = get(this.props, 'item');
		const list = getFields(this, airportFields(this)) || [];
		const form = this.form.generateJSON();

		obj.contribution.rejectedChanges = this.state.rejected;
		obj.contribution.changes = checkData(list, obj, form);

		return obj;
	}

	private onSuccess(message: string): void {
		this.form.clearForm();
		toast.success(message, { theme: 'light' });
		this.props.onSuccess();
	}

	private onError(err): void {
		toast.error(<ToastMessages error={err} />, { theme: 'light' });
		redirectToLogin(err);
	}

	private async handleButtonClick(isReject = false): Promise<void> {
		this.setState({ buttonDisabled: true });
		let data = get(this.props, 'item');

		if (isReject) {
			data.contribution.status = 'REJECTED';
		} else {
			data = this.checkModifications();
		}

		try {
			const response = await RequestManager.post('/airports/merge', data);

			if (!response) {
				throw new Error('There was an error');
			}

			this.onSuccess(
				`Contribution is successfully ${
					isReject ? 'rejected' : 'accepted'
				}.`
			);
		} catch (err) {
			this.onError(err);
		} finally {
			this.setState({ buttonDisabled: false });
		}
	}
}

export const AirportContribution: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(AirportContributionComponent);
