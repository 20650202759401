import { get } from 'lodash';
import * as React from 'react';
import {
	arrayToClass,
	randomId,
} from '../../../../../../../utilities/helper-fuctions';
import { HoverableButton } from '../../../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { formatOptions } from '../../../../../../ui-components/inputs/select/formatter';
import {
	AIRFRAME,
	DIRECTION,
	FILING,
	PURPOSE,
	RULES,
	STATUSES,
} from '../../../opening-hours-contribution/constants';

export class MovementAdditionText extends React.Component<any, any> {
	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (this.props.data !== prevProps.data && !!this.props.data) {
			this.forceUpdate();
		}
	}

	public render(): React.ReactElement {
		const className = arrayToClass([
			'Additions palette--bc-neutral-1 border-1 border-radius-1 palette--bgc-neutral-3 m-2',
		]);

		return <div className={className}>{this.renderText()}</div>;
	}

	public renderText() {
		const data = get(this.props, 'data.data');

		if (!data) {
			return null;
		}

		return (
			<div className='p-4 position-relative'>
				<div className='position-absolute absolute-top-2 absolute-right-2 display-flex'>
					{this.props.openEdit && (
						<HoverableButton
							colorType='transparent-grey'
							onClick={() =>
								this.handleOpenEdit(get(this.props, 'data'))
							}
							icon='edit'
						/>
					)}
					{this.props.delete && (
						<HoverableButton
							icon='close'
							colorType='transparent-grey'
							onClick={() =>
								this.handleDelete(get(this.props, 'data'))
							}
						/>
					)}
				</div>
				<p className='peltte--c-neutral-6 fw-bold'>Flight types:</p>
				{this.renderFlightTypes(get(data, 'flightTypes'))}
				<div className='row'>
					{get(data, 'priorNoticeRequired') !== null && (
						<div className='col-24 col-sm-8 dispaly-flex flex-row flex-sm-column py-2 mr-2'>
							<p className='peltte--c-neutral-6 fw-bold'>
								Prior notice required:
							</p>
							<p className='peltte--c-neutral-6'>
								{get(data, 'priorNoticeRequired')} hour
								{get(data, 'priorNoticeRequired') > 1
									? 's'
									: ''}
							</p>
						</div>
					)}

					<div className='col-24 col-sm-8 dispaly-flex flex-row flex-sm-column py-2 mr-2'>
						<p className='peltte--c-neutral-6 fw-bold'>
							Cost involved:
						</p>
						<p className='peltte--c-neutral-6'>
							{get(data, 'costsInvolved')
								? 'Yes'
								: get(data, 'costsInvolved') === null
								? 'Not specified'
								: 'No'}
						</p>
					</div>

					{get(data, 'other') && (
						<div className='col-24 dispaly-flex flex-row flex-sm-column py-2 mr-2'>
							<p className='peltte--c-neutral-6 fw-bold'>
								Other:
							</p>
							<p className='peltte--c-neutral-6'>
								{get(data, 'other')}
							</p>
						</div>
					)}
				</div>
			</div>
		);
	}

	public renderFlightTypes(flightTypes) {
		return flightTypes.map((data) => {
			const originalRules = get(data, 'rules') || [];
			const originalFiling = get(data, 'filing') || [];
			const originalAirframe = get(data, 'airframe') || [];
			const originalStatuses = get(data, 'status') || [];
			const originalPurpose = get(data, 'purpose') || [];
			const originalDirection = get(data, 'direction') || [];

			return (
				<div
					className='row border-1 palette--c-neutral-1 palette--bgc-neutral-1 border-radius-1 my-2'
					key={randomId()}
				>
					<div className='col-24 col-sm-8 dispaly-flex flex-row flex-sm-column py-2'>
						<p className='peltte--c-neutral-6 fw-bold'>Rules:</p>
						{this.renderFields(originalRules, RULES)}
					</div>

					<div className='col-24 col-sm-8 dispaly-flex flex-row flex-sm-flex flex-column py-2'>
						<p className='peltte--c-neutral-6 fw-bold'>Filing:</p>
						{this.renderFields(originalFiling, FILING)}
					</div>

					<div className='col-24 col-sm-8 dispaly-flex flex-row flex-sm-flex flex-column py-2'>
						<p className='peltte--c-neutral-6 fw-bold'>Airframe:</p>
						{this.renderFields(originalAirframe, AIRFRAME)}
					</div>

					<div className='col-24 col-sm-8 dispaly-flex flex-row flex-sm-flex flex-column py-2'>
						<p className='peltte--c-neutral-6 fw-bold'>Status:</p>
						{this.renderFields(originalStatuses, STATUSES)}
					</div>

					<div className='col-24 col-sm-8 dispaly-flex flex-row flex-sm-flex flex-column py-2'>
						<p className='peltte--c-neutral-6 fw-bold'>Purpose:</p>
						{this.renderFields(originalPurpose, PURPOSE)}
					</div>

					<div className='col-24 col-sm-8 dispaly-flex flex-row flex-sm-flex flex-column py-2'>
						<p className='peltte--c-neutral-6 fw-bold'>
							Direction:
						</p>
						{this.renderFields(originalDirection, DIRECTION)}
					</div>
				</div>
			);
		});
	}

	public renderFields(data, options) {
		return (
			<div className='display-flex flex-wrap'>
				{!(data || []).length && <p>No requirements</p>}
				{!!(data || []).length &&
					(data || []).map((item, index) => {
						const itemLengthFromZero = data.length - 1;
						const itemOption = formatOptions(options).find(
							(option) => option.value === item
						);

						return (
							<p
								key={randomId()}
								className='peltte--c-neutral-6 mr-2'
							>
								{get(itemOption, 'title')}
								{index < itemLengthFromZero ? ',' : ''}
							</p>
						);
					})}
			</div>
		);
	}

	public handleOpenEdit(data) {
		if (this.props.openEdit) {
			this.props.openEdit(data);
		}
	}

	public handleDelete(data) {
		if (this.props.delete) {
			this.props.delete(data);
		}
	}
}
