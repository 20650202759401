import { get } from 'lodash';
import * as React from 'react';
import Masonry from 'react-masonry-css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../../../actions/details.actions';
import { connectNLP } from '../../../../utilities/connect-navigate';
import {
	arrayToClass,
	isInQuery,
	randomId,
} from '../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../utilities/request';
import { AuthorityMap } from '../../../map/widget-maps/authority-map';
import { ToastMessages } from '../../../notifications/toast-messages';
import { ContributeButton } from '../../../other/contribute-button';
import { EmailShower } from '../../../other/emial-shower';
import { PhoneNumber } from '../../../other/phone-number';
import { ModalButtons } from '../../../ui-components/hoverable-buttons/form-modal-buttons/modal-button';
import { HoverableButton } from '../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Modal } from '../../../ui-components/modal/modal';
import { AuthorityCard } from './authority-card';
import './authority-details.scss';

const mapStateProps = (store: any) => ({
	user: store.user.user,
	widgetSuccess: store.details.widgetSuccess,
});

const mapDispatchProps = (dispatch: any) => ({
	setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

class AuthorityDetailsComponent extends React.Component<any, any> {
	public modal$: Modal;
	public contacts$;

	public state = {
		authority: false,
		loading: false,
		error: false,
		contacts: false,
	};

	public componentDidMount() {
		this.modal$.open();

		this.fetch();
	}

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (
			this.props.widgetSuccess !== prevProps.widgetSuccess &&
			this.props.widgetSuccess === 'contact'
		) {
			this.fetch();
			this.props.setSuccess();
		}

		if (
			this.props.widgetSuccess !== prevProps.widgetSuccess &&
			this.props.widgetSuccess === 'authority-delete'
		) {
			this.handleClose();
		}
	}

	public async fetch() {
		try {
			this.setState({ loading: true });

			this.fetchAuthority();
			this.fetchContacts();
		} catch (err) {
			this.setState({ error: err });
		} finally {
			this.setState({ loading: false });
		}
	}

	public async fetchAuthority() {
		try {
			const response = await RequestManager.get(
				`/${
					this.props.isCountry
						? 'countryauthorities'
						: 'airportauthorities'
				}/${get(this.props, 'aid')}`
			);

			if (!response) {
				throw new Error('There was an error');
			}

			this.setState({ authority: response });
		} catch (err) {
			this.props?.onClose();
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}

	public async fetchContacts() {
		try {
			const response = await RequestManager.get(
				`/${
					this.props.isCountry
						? 'countryauthorities'
						: 'airportauthorities'
				}/${get(this.props, 'aid')}/${
					this.props.isCountry
						? 'countryAuthorityContacts'
						: 'airportAuthorityContacts'
				}/all`
			);

			if (!response) {
				throw new Error('There was an error');
			}

			this.setState({ contacts: response });
		} catch (err) {
			return err;
		}
	}

	public render(): React.ReactElement {
		return (
			<Modal
				ref={(ref: any) => (this.modal$ = ref)}
				content={() => this.renderContent()}
				onDismissRequest={() => this.handleClose()}
				footer={() => this.renderFooter()}
				wrapperClasses='AuthorityDetailsModal'
				renderButtonOnly={true}
			/>
		);
	}

	public renderContent() {
		return (
			<div className='AuthorityDetails w-100 palette--bgc-neutral-1 border-radius-1'>
				{this.renderMap()}
				<div className='p-4'>
					{this.renderDetails()}
					{this.renderContacts()}
				</div>
			</div>
		);
	}

	public renderDetails() {
		return (
			<div className='w-100 border-bottom-1 palette--bc-neutral-4'>
				<AuthorityCard
					isCountry={this.props.isCountry}
					authority={this.state.authority}
					className='border-0'
					isDetails={true}
					edit={() => this.props.edit && this.props.edit()}
				/>
			</div>
		);
	}

	public renderMap() {
		return <AuthorityMap content={this.state.authority} />;
	}

	public renderContacts() {
		const contacts: any = get(this.state, 'contacts', false);

		return (
			<div className='w-100 pt-4'>
				<div className='display-flex'>
					<h3 className='palette--c-neutral-6 fw-bold flex-fill'>
						Contacts
					</h3>
					<div className='display-flex mb-2'>
						<HoverableButton
							disabled={false}
							className='border-radius-1'
							colorType='avio-green'
							onClick={() => this.openContact()}
							title='Add contacts'
						/>
					</div>
				</div>
				{!(contacts || []).length && this.renderEmpty('Contacts', true)}
				{!!(contacts || []).length && this.renderContactsByTypes()}
			</div>
		);
	}

	public renderContactsByTypes() {
		const contacts: any = this.state?.contacts;
		let types = {};

		(contacts || []).map((contact) => {
			const type = get(contact, 'type', '').toLowerCase();
			types = {
				...types,
				[type]: [...(get(types, type, false) || []), contact],
			};
		});

		const breakpointColumnsObj = {
			default: 2,
			575: 1,
		};

		return (
			<Masonry
				breakpointCols={breakpointColumnsObj}
				className='my-masonry-grid'
				columnClassName='my-masonry-grid_column'
			>
				{Object.keys(types).map((type) =>
					this.renderContactsType(get(types, type, []) || [], type)
				)}
			</Masonry>
		);
	}

	public renderContactsType(list, type) {
		return (
			<div
				key={randomId()}
				className='MasonryItem m-1 border-1 palette--c-neutral-4 border-radius-1 overflow-hidden'
			>
				<div className='row p-2 border-bottom-1 palette--bc-neutral-4'>
					<p className='col-24 w-100 palette--c-neutral-5 text-uppercase m-0 fw-bold'>
						{type}
					</p>
				</div>
				{list.map((item, index) => {
					const classes = arrayToClass([
						'row display-flex align-items-center p-2',
						`palette--bgc-neutral-${index % 2 === 0 ? '1' : '2'}`,
					]);

					const isPhone = type === 'phone';
					const isEmail = type === 'email';
					const isWeb = type === 'website';

					return (
						<div key={randomId()} className={classes}>
							{
								<p className='palette--c-neutral-5 col-10 m-0'>
									{get(item, 'name')
										? `${get(item, 'name')}:`
										: ''}
								</p>
							}
							{!isPhone && !isEmail && !isWeb && (
								<p className='AuthorityContactsDetails palette--c-neutral-6 col-14 m-0 fw-bold'>
									{get(item, 'details')}
								</p>
							)}
							{isPhone && (
								<a
									href={`tel:${get(item, 'details')}`}
									className='AuthorityContactsDetails Link palette--c-neutral-6 col-14 m-0 fw-bold'
								>
									<PhoneNumber
										number={get(item, 'details')}
									/>
								</a>
							)}
							{isWeb && (
								<a
									href={
										get(item, 'details').match(
											/(http)[s]?/g
										)
											? get(item, 'details')
											: `https://${get(item, 'details')}`
									}
									target='_blank'
									className='AuthorityContactsDetails palette--c-neutral-6 col-14 m-0 fw-bold'
								>
									{get(item, 'details')}
								</a>
							)}
							{isEmail && (
								<div className='col-14'>
									<EmailShower
										email={get(item, 'details')}
										classNames='w-100'
									/>
								</div>
							)}
						</div>
					);
				})}
			</div>
		);
	}

	public renderEmpty(type, isPlural = false) {
		return (
			<div className='w-100'>
				<ContributeButton
					openModal={() => this.openContact()}
					label={type}
					isAre={isPlural}
				/>
			</div>
		);
	}

	public renderFooter() {
		const isAdmin = (this.props?.user?.roles || []).includes('ADMIN');
		const isContacts = !!((this.state?.contacts as any) || []).length;

		return (
			<ModalButtons
				onClose={() => this.handleClose()}
				isDelete={isAdmin && !isContacts}
				type='authority'
				merge={`/${
					this.props.isCountry ? 'country' : 'airport'
				}authorities/merge`}
				delete={`/${
					this.props.isCountry ? 'country' : 'airport'
				}authorities/${this.props?.aid}`}
				changes={(response) => this.getChanges(response)}
			/>
		);
	}

	public getChanges(response) {
		const authority: any = this.state?.authority;
		const main = Object.keys(authority).filter(
			(key) => response[key] !== null
		);

		if (authority.customDetails) {
			const custom = Object.keys(authority?.customsDetails || []).filter(
				(key) => response[key] !== null
			);
			response.customDetails.contribution.changes = custom;

			if (!!(custom || []).length) {
				response.contribution.changes = [custom];
			}
		}

		response.contribution.changes = [
			...response.contribution.changes,
			...main,
		];

		return response;
	}

	public openContact() {
		const utmSource = isInQuery('utm_source');
		this.props?.router?.navigate({
			pathname: `${this.props?.router?.location?.pathname}/contact`,
			search: utmSource,
		});
	}

	public handleClose() {
		this.props?.onClose();
		this.modal$.close();
	}
}

export const AuthorityDetails: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(connectNLP(AuthorityDetailsComponent));
