import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { OperationalNotesContribution } from '../../../../../components/widgets/shared/notes/operational-notes-contribution/operational-notes-contribution';
import { Contributions } from '../contributions';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class CountryOperationalNotesComponent extends Contributions<any, any> {
	public endpoint(): any {
		const user = get(this.props, 'user');
		const userRoles = get(user, 'roles');
		const isAdmin = userRoles.includes('ADMIN');
		const system = get(this.props, 'system');
		const systemInfo = system === true ? '/system' : `/users/${system}`;
		const otherInfo = isAdmin ? '' : `/users/${get(user, 'aid')}`;

		const systemAdminOrGeneral = isAdmin && system ? systemInfo : otherInfo;

		return `/countryoperationalnotes/contributions${systemAdminOrGeneral}`;
	}

	public getType(): any {
		return 'country';
	}

	public getModalEndpoint() {
		return `/countryoperationalnotes/${get(this.state.item, 'entityAid')}`;
	}

	public renderModalContent(ctx): React.ReactElement {
		return (
			<OperationalNotesContribution
				endpoint={'/countryoperationalnotes/merge'}
				original={get(ctx.state, 'response')}
				item={this.state.item}
				onSuccess={() => this.handleModalSuccess()}
				close={() => this.onModalClose()}
			/>
		);
	}
}

export const CountryOperationalNotes: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(CountryOperationalNotesComponent);
