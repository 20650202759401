import { LocalStorage } from '../utilities/local-storage';
import { AnyAction } from 'redux';
import { UserActions } from '../actions/user.actions';

const initial: any = {
    loginInProgress: false,
    loginError: false,
    loginSuccess: false,
    registrationInProgress: false,
    registrationError: false,
    registrationSuccess: false,
    token: LocalStorage.getItem('AccessToken'),
    tokenType: LocalStorage.getItem('AccessTokenType'),
    refreshToken: LocalStorage.getItem('RefreshToken'),
    user: LocalStorage.getItem('User') || null,
};

export const userReducer = (state = initial, action: AnyAction) => {
    switch (action.type) {
        case UserActions.LOGIN_IN_PROGRESS: {
            state = { ...state, loginInProgress: action.payload };
            break;
        }
        case UserActions.LOGIN_ERROR: {
            state = { ...state, loginError: action.payload };
            break;
        }
        case UserActions.LOGIN_SUCCESS: {
            state = { ...state, loginSuccess: action.payload };
            break;
        }
        case UserActions.SET_ACCESS_TOKEN: {
            state = { ...state, token: action.payload };
            break;
        }
        case UserActions.SET_ACCESS_TOKEN_TYPE: {
            state = { ...state, tokenType: action.payload };
            break;
        }
        case UserActions.SET_REFRESH_TOKEN: {
            state = { ...state, refreshToken: action.payload };
            break;
        }
        case UserActions.REGISTRATION_IN_PROGRESS: {
            state = { ...state, registrationInProgress: action.payload };
            break;
        }
        case UserActions.REGISTRATION_ERROR: {
            state = { ...state, registrationError: action.payload };
            break;
        }
        case UserActions.REGISTRATION_SUCCESS: {
            state = { ...state, registrationSuccess: action.payload };
            break;
        }
        case UserActions.SET_USER: {
            state = { ...state, user: action.payload };
            break;
        }
    }

    return state;
};
