import * as React from 'react';
import { connect } from 'react-redux';
import { dimConvert } from '../../../../../utilities/dim.transform';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { HoverableButton } from '../../../../ui-components/hoverable-buttons/hoverable-buttons';
import './widget-contribution-presenter.scss';

const mapStateProps = (store: any) => ({
	metric: store.userDetails.metric ? 1 : 0,
});

const mapDispatchProps = (dispatch: any) => ({});

class WidgetContributionPresenterComponent extends React.Component<any, any> {
	public render(): React.ReactElement {
		let {
			className,
			toValue,
			fromValue,
			title,
			renderValue,
			isEditable,
			isAdmin,
			metric,
		} = this.props;
		let { type, content, ignoreTransform, unit, component } = toValue;

		const wrapperClassName = arrayToClass([
			'WidgetContributionPresenter',
			className ? className : '',
			'flex-wrap',
		]);

		const helperTargetDim =
			!metric && !ignoreTransform
				? unit === 'm'
					? 'ft'
					: 'pounds'
				: unit;

		if (unit && !metric && !ignoreTransform) {
			content = content
				? dimConvert(content, unit, helperTargetDim, 0)
				: '';
			fromValue = fromValue
				? dimConvert(fromValue, unit, helperTargetDim, 0)
				: '';
		}

		return (
			<div className={wrapperClassName}>
				<h6 className='Property palette--c-neutral-5 fw-bold m-0 display-block display-md-none'>
					Property
				</h6>
				<p className='Property Bottom fw-bold m-0 palette--c-neutral-6'>
					{title}:
				</p>
				<h6 className='CurrentData palette--c-neutral-5 fw-bold m-0 display-block display-md-none'>
					Current data
				</h6>
				{!renderValue && (
					<p className='CurrentData Bottom palette--c-neutral-6 m-0'>
						{fromValue}&nbsp;
						{unit && !!fromValue && helperTargetDim}
					</p>
				)}
				{renderValue && renderValue(fromValue)}
				<span className='ArrowIcons material-icons palette--c-neutral-6 fw-bold display-none display-md-block'>
					arrow_forward
				</span>
				<span className='ArrowIcons Bottom material-icons palette--c-neutral-6 fw-bold display-block display-md-none'>
					arrow_downward
				</span>
				<h6 className='NewData palette--c-neutral-5 fw-bold m-0 display-block display-md-none'>
					New Data
				</h6>
				{!isEditable && !renderValue && (
					<p className='NewData palette--c-neutral-6 m-0'>
						{content}&nbsp;
						{unit && !!content && helperTargetDim}
					</p>
				)}
				{!isEditable && renderValue && renderValue(content, true)}
				{isEditable && component}
				{isAdmin && this.renderButtons(type)}
			</div>
		);
	}

	public renderButtons(type: string): React.ReactElement {
		const { isEditable, isRejected } = this.props;

		return (
			<div className='ButtonContainer display-flex align-items justify-content justify-content-end'>
				{!isEditable && (
					<HoverableButton
						colorType='transparent-grey'
						className='border-radius-1 mr-1'
						icon='edit'
						onClick={() => this.handleEdit(true, type)}
					/>
				)}
				{isEditable && (
					<HoverableButton
						colorType='transparent-grey'
						className='border-radius-1 mr-1'
						onClick={() => this.handleEdit(false, type)}
						icon='check'
					/>
				)}
				{!isRejected && (
					<HoverableButton
						onClick={() => this.handleReject(true, type)}
						icon='close'
						colorType='transparent-grey'
						className='border-radius-1'
					/>
				)}
				{isRejected && (
					<HoverableButton
						onClick={() => this.handleReject(false, type)}
						icon='check'
						colorType='transparent-grey'
						className='border-radius-1'
					/>
				)}
			</div>
		);
	}

	public handleEdit(isAdd: boolean, type: string): void {
		if (isAdd) {
			this.props.handleEdit(type);
		}
		this.props.missEdit(type);
	}

	public handleReject(isAdd: boolean, type: string): void {
		if (isAdd) {
			this.props.handleReject(type);
		}
		this.props.missReject(type);
	}
}

export const WidgetContributionPresenter: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(WidgetContributionPresenterComponent);
