import { get, isEqual } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { randomId } from '../../../utilities/helper-fuctions';
import { RequestManager } from '../../../utilities/request';
import { HoverableButton } from '../../ui-components/hoverable-buttons/hoverable-buttons';
import { Modal } from '../../ui-components/modal/modal';
import { ImageComponent } from './image-component';
import { ImageCropper } from './image-cropper/image-cropper';
import './image-uploader.scss';
import { ImagesWarningModal } from './images-warning-modal';
import { format } from 'date-fns';

export class ImageUploader extends React.Component<any, any> {
	public modal$: Modal;
	public logo$: ImageComponent;
	public images: { [key: string]: ImageComponent } = {};

	public state: any = {
		hovered: false,

		images: [],
		logo: false,

		croppedImages: [],
		croppedLogo: false,

		editable: [],
		deletable: [],

		errorInImages: [],
		error: false,
		isUploaded: false,
	};

	public componentDidMount() {
		this.setState({
			croppedLogo: this.props.logo || false,
			croppedImages: this.props.images || [],
		});
	}

	public open() {
		this.modal$.open();
	}

	public close() {
		const isEdit = this.props.isEdit;
		const images = get(this.props, 'images', []);
		const logo = get(this.props, 'logo', false);

		this.setState({
			croppedImages: isEdit ? images : [],
			croppedLogo: isEdit ? logo : false,
		});
		this.modal$.close();
	}

	public render(): React.ReactElement {
		return (
			<Modal
				ref={(ref: any) => (this.modal$ = ref)}
				title={() => this.renderHeader()}
				content={() => this.renderContent()}
				footer={() => this.renderFooter()}
				onDismissRequest={() => this.close()}
			/>
		);
	}

	public renderHeader(): React.ReactElement {
		return (
			<div className='Header display-flex align-items-center p-4'>
				<h4 className='palette--c-neutral-6 m-0'>
					Upload logo and images
				</h4>
			</div>
		);
	}

	public renderContent() {
		return (
			<div className='ImageUploader p-4'>
				<div className='w-100 display-flex pb-3'>
					<span className='InfoIcon material-icons palette--c-primary-4 mr-2 mb-0'>
						info_outline
					</span>
					<p className='palette--c-neutral-5 m-0'>
						To achieve the best quality we recommend images larger
						than 500px x 280px.
					</p>
				</div>
				<div className='w-100 pb-2'>
					<h4 className='palette--c-netural-5'>Logo:</h4>
					{!this.state.croppedLogo && (
						<div className='UploadButtonContainer display-flex align-items-center justify-content-center'>
							<label htmlFor={'logoUploadInput'}>
								<div className='UploadButton display-flex align-items-center justify-content-center border-2 border-radius-1'>
									<p className='material-icons m-0'>
										add_photo_alternate
									</p>
								</div>
							</label>
						</div>
					)}
					{this.renderFileOpener('logoUploadInput')}
					{this.renderPreview()}
				</div>

				<div className='w-100 pt-2'>
					<h4 className='palette--c-netural-5 mb-2'>Images:</h4>
					{!(this.state.croppedImages || []).length && (
						<div className='UploadButtonContainer display-flex align-items-center justify-content-center'>
							<label htmlFor={'imageUploadInput'}>
								<div className='UploadButton display-flex align-items-center justify-content-center border-2 border-radius-1'>
									<p className='material-icons m-0'>
										collections
									</p>
								</div>
							</label>
						</div>
					)}
					{this.renderFileOpener('imageUploadInput', true)}
					{this.renderPreview(true)}
				</div>

				{this.renderWarningModal()}
				{this.renderCropper()}
			</div>
		);
	}

	public renderPreview(isImages = false) {
		const list = isImages
			? this.state.croppedImages
			: this.state.croppedLogo
			? [this.state.croppedLogo]
			: this.state.croppedLogo;

		return (
			<div className={'w-100 ImagePreview'}>
				{(list || []).map((image, index) => {
					const id = `${isImages ? 'image' : 'logo'}${index}`;
					const oFile = isImages
						? (this.props.images || []).find(
								(i) =>
									get(i, 'contribution.aid') ===
									get(image, 'contribution.aid')
						  )
						: this.props.logo;

					return (
						<ImageComponent
							key={id}
							ref={(ref: any) =>
								isImages
									? (this.images[id] = ref)
									: (this.logo$ = ref)
							}
							isImage={isImages}
							imageId={id}
							original={oFile || false}
							image={image}
							deleteImage={(isImages, file) =>
								this.handleDelete(isImages, file)
							}
							setEdited={(edited, isRemove) =>
								this.handleEdit(edited, isRemove)
							}
							isImages={isImages}
						/>
					);
				})}

				{!!(this.state.croppedImages || []).length && isImages && (
					<div className='UploadButtonContainer display-flex align-items-center justify-content-center'>
						<label htmlFor={'imageUploadInput'}>
							<div className='UploadButton display-flex align-items-center justify-content-center border-2 border-radius-1'>
								<p className='material-icons m-0'>
									collections
								</p>
							</div>
						</label>
					</div>
				)}
			</div>
		);
	}

	public renderWarningModal() {
		const images = get(this.state, 'errorInImages') || [];
		const isPlural = (images || []).length > 1;
		const tooBigMessage = `${isPlural ? 'These' : 'This'} image${
			isPlural ? 's' : ''
		} ${
			isPlural ? 'are' : 'is'
		} too big. Each image must be less than 10MB.`;

		const message = this.state.isUploaded ? false : tooBigMessage;

		return (
			<ImagesWarningModal
				isOpen={!!(images || []).length}
				close={() =>
					this.setState({ errorInImages: [], isUploaded: false })
				}
				images={images || []}
				message={message}
				renderMessage={() =>
					this.state.isUploaded && this.renderErrorMessage()
				}
			/>
		);
	}

	public renderErrorMessage() {
		const errorData = get(this.props, 'errorData');
		const errorContent = get(this.state, 'errorInImages');

		const subject = 'Failed image upload';

		const body = `Hi aviowiki team! %0D I had an error in image upload for airport: ${get(
			errorData,
			'airport'
		)}, ${
			get(errorData, 'provider')
				? `provider: ${get(errorData, 'provider')}.`
				: 'with a new provider.'
		} %0D I came across the below error on your site. ${
			this.state.error
		}. Date: ${format(new Date(), 'YYYY. mm. dd.')} %0D ${
			!!(errorContent || []).length
				? 'Also I tried to upload the attached files (share your files with us!).'
				: ''
		}`;

		return (
			<div className='w-100 display-flex align-items-center py-3'>
				<p className='palette--c-neutral-5 mb-0'>
					Uh oh, this upload failed. Contact&nbsp;
				</p>
				<a
					href={`mailto:support@aviowiki.com?subject=${subject}&body=${body}`}
					className='ImageWarningLink text-decoration-none palette--c-blue-2'
				>
					aviowiki
				</a>
				<p className='palette--c-neutral-5 mb-0'>&nbsp;for support.</p>
			</div>
		);
	}

	public renderCropper() {
		const images = get(this.state, 'images');
		const logo = get(this.state, 'logo');
		const errorImages = get(this.state, 'errorInImages');
		const isOpen = !errorImages.length && (!!images.length || !!logo);
		const isLogo = !images.length;

		return (
			<ImageCropper
				isOpen={isOpen}
				images={images}
				logo={logo}
				isLogo={isLogo}
				onClose={() => this.handleCropClose()}
				onSave={(images) => this.handleCropSave(images, isLogo)}
			/>
		);
	}

	public renderFileOpener(id, isImages = false) {
		return (
			<input
				className={'display-none'}
				type={'file'}
				accept={'image/*'}
				multiple={isImages}
				id={id}
				onChange={(event) =>
					this.imageSelectSuccess(event.target.files, event, isImages)
				}
			/>
		);
	}

	public renderFooter(): React.ReactElement {
		return (
			<div className='w-100'>
				<div className='display-flex align-items-center justify-content-end p-4'>
					<HoverableButton
						id={
							get(this.state, 'buttonDisabled', null)
								? 'providerLast'
								: ''
						}
						className='border-radius-1 mr-2'
						colorType='cancel'
						title='Cancel'
						onClick={() => this.close()}
					/>
					<HoverableButton
						disabled={get(this.state, 'buttonDisabled')}
						className='border-radius-1'
						colorType={
							get(this.state, 'buttonDisabled')
								? 'disabled'
								: 'avio-green'
						}
						title='Save'
						onClick={() => this.handleSave()}
					/>
				</div>
			</div>
		);
	}

	public imageSelectSuccess(files, event, isImages = false) {
		if (!files || !files.length) {
			return;
		}

		if (isImages) {
			for (let i = 0; i < files.length; i++) {
				const image = files[i];

				this.getFile(image, true);
			}

			event.target.value = null;
			return;
		}

		const image = files[0];
		this.getFile(image);
		event.target.value = null;
	}

	public getFile(image, isImages = false) {
		const reader = new FileReader();

		reader.onload = (e) => {
			image.path = get(e, 'target.result');

			if (image.size / 1024 > 9990) {
				this.setState({
					errorInImages: [...this.state.errorInImages, image],
				});
				return;
			}

			if (isImages) {
				const isNewImage = (this.state.images || []).find(
					(i) => !!isEqual(get(i, 'file.path'), image.path)
				);

				if (!!isNewImage) {
					toast.warn('One of the images is already added!');
					return;
				}

				this.setState({
					images: [
						...this.state.images,
						{
							file: image,
							contribution: {
								name: (
									get(image, 'name') ||
									`img_${randomId()}.png`
								).replace(/./gi, ''),
							},
						},
					],
				});
				return;
			}

			this.setState({
				logo: {
					file: image,
					contribution: {
						name: (
							get(image, 'name') || `img_${randomId()}.png`
						).replace(/./gi, ''),
					},
				},
			});
		};
		reader.readAsDataURL(image);
	}

	public handleEdit(edited, isRemove) {
		if (isRemove) {
			this.setState({
				editable: (this.state.edited || []).filter(
					(aid) => aid !== edited
				),
			});
		} else {
			this.setState({ editable: [...(this.state.edited || []), edited] });
		}
	}

	public handleDelete(isImages, deletable) {
		if (isImages) {
			const newImages = (this.state.croppedImages || []).filter(
				(image) => !isEqual(image, deletable)
			);

			if (!!get(deletable, 'aid')) {
				this.setState({
					croppedImages: newImages,
					deletable: [...this.state.deletable, deletable],
				});
			} else {
				this.setState({ croppedImages: newImages });
			}
		} else {
			if (!!get(deletable, 'aid')) {
				this.setState({
					croppedLogo: false,
					deletable: [...this.state.deletable, deletable],
				});
			} else {
				this.setState({ croppedLogo: false });
			}
		}
	}

	public handleCropClose() {
		this.setState({ images: [], logo: false });
	}

	public handleCropSave(images, isLogo = false) {
		if (isLogo) {
			this.setState({ croppedLogo: images });
		} else {
			this.setState({
				croppedImages: [...this.state.croppedImages, ...images],
			});
		}

		this.handleCropClose();
	}

	public handleSave() {
		if (this.logo$) {
			const logo: any = this.logo$.saveToProviders();

			this.setState({ croppedLogo: logo });
		}

		let images: any = [];
		for (let key of Object.keys(this.images)) {
			if (this.images[key]) {
				const image: any = this.images[key].saveToProviders();

				images = [...images, image];
			}
		}
		this.setState({ croppedImages: images });

		if (this.props.imagesCheck) {
			const isContentAvailable =
				!!this.state.croppedLogo ||
				!!(this.state.croppedImages || []).length;
			this.props.imagesCheck(isContentAvailable);
		}

		this.modal$.close();
	}

	public async getProviderFileReferences() {
		const logo = get(this.state, 'croppedLogo') || false;
		const logoReference = logo
			? await this.handleImagesByActions(logo)
			: null;

		const images = [
			...(get(this.state, 'croppedImages') || []),
			...(get(this.state, 'deletable') || []),
		];
		let imageReferences: any = [];

		for (let i of images) {
			const ref = await this.handleImagesByActions(i);

			if (!!ref) {
				imageReferences = [...imageReferences, ref];
			}
		}

		return {
			logo: logoReference,
			pictures: imageReferences,
		};
	}

	public async handleImagesByActions(file) {
		const aid = get(file, 'contribution.aid');

		if (!aid) {
			return await this.createFileReference(file);
		} else if ((this.state.editable || []).includes(aid)) {
			return;
		} else if (
			!!this.state.deletable.find(
				(image) => get(image, 'contribution.aid') === aid
			)
		) {
			return;
		}
		return get(file, 'contribution');
	}

	public async createFileReference(file) {
		try {
			const form = get(file, 'contribution');
			const body = {
				name: form.name || `img_${randomId()}.png`,
				description: form.description,
			};

			const response = await RequestManager.post('/filereferences', body);

			if (!response) {
				return;
			}

			const id = get(response, 'id');
			const fileReference = await this.uploadFile(id, file.file);

			if (!fileReference) {
				this.setState({
					errorInImages: [
						...(this.state.errorInImages || []),
						get(file, 'file'),
					],
					isUploaded: true,
				});
			}

			return fileReference;
		} catch (err) {
			this.setState({ error: err });
		}
	}

	public async uploadFile(id, file) {
		try {
			const formData = await this.createFormdata(file);

			const response = await RequestManager.file(
				`/filereferences/${id}/upload`,
				formData
			);

			return response;
		} catch (err) {
			this.setState({ error: err });
		}
	}

	public async createFormdata(file) {
		const base64 = get(file, 'path');
		let encodedFile;

		await fetch(base64)
			.then((res) => (encodedFile = res.blob()))
			.then((blob) => (encodedFile = blob));

		const formData = new FormData();
		formData.append('file', encodedFile);

		return formData;
	}
}
