import { Modal } from '../../../ui-components/modal/modal';
import { get } from 'lodash';
import * as React from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { HoverableButton } from '../../../ui-components/hoverable-buttons/hoverable-buttons';
import { ThumbnailsPresenter } from '../../picture-presenter/thumbnail-presenter';
import './image-cropper.scss';

export class ImageCropper extends React.Component<any, any> {
    public modal$: Modal;
    public image$ = React.createRef();

    public state = {
        logo: get(this.props, 'logo'),
        images: get(this.props, 'images'),
        currentIndex: 0,
        zoom: 0,
    };

    public componentDidUpdate(prevProps) {
        if (this.props.isOpen !== prevProps.isOpen && !!this.props.isOpen) {
            this.setState({ logo: get(this.props, 'logo'), images: get(this.props, 'images') });
            this.modal$.open();
        }

        if (get(this.props.images, 'length') !== get(prevProps.images, 'length')) {
            this.setState({ images: get(this.props, 'images') });
        }
    }

    public render() {
        return (
            <Modal
                hideClose={true}
                ref={(ref: any) => (this.modal$ = ref)}
                title={() => this.renderHeader()}
                content={() => this.renderContent()}
                footer={() => this.renderFooter()}
            />
        );
    }

    public renderHeader() {
        return (
            <div className="w-100 p-4 display-flex">
                <h4 className="palette--c-neutral-6 flex-fill m-0 mr-2">Edit image</h4>
                <HoverableButton
                    className="border-radius-1 CropperClose"
                    colorType="contrast"
                    onClick={() => this.onClose()}
                    icon="close"
                />
            </div>
        );
    }

    public renderContent() {
        if (!this.props?.isOpen) {
            return null;
        }

        const image = this.getCurrentImage();
        const src = get(image, 'file.path');
        const aspectRatio = this.props?.isLogo ? undefined : 16 / 9;

        return (
            <div className="w-100 position-relative">
                <Cropper
                    ref={(ref: any) => (this.image$ = ref)}
                    style={{ height: '450px', width: '100%' }}
                    src={src}
                    checkOrientation={false}
                    aspectRatio={aspectRatio}
                    guides={true}
                    minCropBoxHeight={this.props.isLogo ? 0 : 280}
                    minCanvasHeight={this.props.isLogo ? 0 : 280}
                    minCropBoxWidth={this.props.isLogo ? 0 : 600}
                    minCanvasWidth={this.props.isLogo ? 0 : 600}
                    background={false}
                    highlight={true}
                    movable={true}
                    responsive={true}
                    ready={() => this.handleReady()}
                />

                {!this.props.isLogo && this.state.images.length > 1 && this.renderImageAdditios(image)}
            </div>
        );
    }

    public handleReady() {
        const image = this.getCurrentImage();
        const cropData = get(image, 'cropData') || false;

        if (!!cropData) {
            const imageElement: any = this.image$;
            const ctx: any = imageElement.cropper;
            ctx.setData(cropData.data).setCanvasData(cropData.canvas).setCropBoxData(cropData.cropBox);
        }
    }

    public renderImageAdditios(current) {
        return (
            <div className="w-100 position-relative">
                <ThumbnailsPresenter
                    select={(image) => this.handleSelect(image)}
                    selected={current}
                    pictures={get(this.state, 'images')}
                />
            </div>
        );
    }

    public renderFooter() {
        return (
            <div className="display-flex align-items-center justify-content-end p-4 w-100">
                <HoverableButton
                    id={get(this.state, 'buttonDisabled') ? 'adminEditRegionLast' : ''}
                    className="border-radius-1 mr-2"
                    colorType="cancel"
                    title={'Cancel'}
                    onClick={() => this.onClose()}
                />
                <HoverableButton
                    id="adminEditRegionLast"
                    className="border-radius-1"
                    colorType={get(this.state, 'buttonDisabled') ? 'disabled' : 'avio-green'}
                    title={`Use${this.state.images.length > 1 ? ' all' : ''}`}
                    onClick={(ev: any) => this.onSave()}
                />
            </div>
        );
    }

    public getCurrentImage() {
        if (this.props.isLogo) {
            return get(this.state, 'logo');
        } else {
            const index = get(this.state, 'currentIndex');
            const images = get(this.state, 'images');
            const currentImage = images[index];

            return currentImage;
        }
    }

    public handleCrop() {
        const imageElement: any = this.image$;
        const cropper: any = imageElement.cropper;

        const currentImage = this.getCurrentImage();
        const type = get(currentImage, 'file.type') || 'image/png';

        const image = cropper.getCroppedCanvas().toDataURL(type);
        const cropperStates = {
            data: cropper.getData(),
            canvas: cropper.getCanvasData(),
            cropBox: cropper.getCropBoxData(),
        };

        return {
            path: image,
            cropData: cropperStates,
        };
    }

    public onLocalSave() {
        const cropData = this.handleCrop();
        const index = get(this.state, 'currentIndex');
        const images = get(this.state, 'images');
        const image = images[index];

        image.croppedPath = cropData.path;
        image.cropData = cropData.cropData;

        this.setState({ images: images });
    }

    public handleSelect(image) {
        this.onLocalSave();
        const indexOf = get(this.state, 'images').indexOf(image);

        this.setState({ currentIndex: indexOf });
    }

    public onClose() {
        this.setState({ logo: false, currentIndex: 0, images: [] });

        if (this.props.onClose) {
            this.props.onClose();
        }

        this.modal$.close();
    }

    public saveLogo() {
        const logo = get(this.state, 'logo');
        const croppedImage = this.handleCrop();

        if (!!logo && !!croppedImage) {
            logo.file.path = croppedImage.path;
        }

        return logo;
    }

    public saveImages() {
        this.onLocalSave();
        const images = get(this.state, 'images');

        for (let image of images) {
            if (image.croppedPath) {
                image.file.path = image.croppedPath;
                delete image.croppedPath;
                delete image.cropData;
            }
        }

        return images;
    }

    public onSave() {
        if (this.props.onSave) {
            let images;

            if (this.props.isLogo) {
                images = this.saveLogo();
            } else {
                images = this.saveImages();
            }

            this.props.onSave(images);
        }

        this.modal$.close();
    }
}
