import $ from 'jquery';
import { get } from 'lodash';
import * as React from 'react';

export class VisitComponent extends React.Component<any, any> {
    public state = {
        itemLength: 0
    };

    public componentDidMount() {
        this.checkStatuses();
        this.onScroll();
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.itemLength !== prevProps.itemLength) {
            this.checkStatuses();
        }
    }

    public onScroll() {
        $(`.${this.props.parentClass}`).on('scroll', async () => {
            const parent = $(`.${this.props.parentClass}`);
            const parentTop = (parent.height() || 0) + get(parent.offset(), 'top', 0);

            const child = $('.Visitable');
            const childTop = get(child.offset(), 'top', 0);

            if (parentTop === childTop && this.props.fetch && this.props.itemLength !== this.state.itemLength) {
                this.setState({ itemLength: this.props.itemLength });
                await this.props.fetch();
            }
        });
    }

    public async checkStatuses() {
        const currentOffset = $('.Visitable').position();
        const currentTop = get(currentOffset, 'top', 0) || 0;
        const parentHeight = $(`.${this.props.parentClass}`).height() || 0;

        if (currentTop <= parentHeight && this.props.fetch) {
            await this.props.fetch();
        }
    }

    public render() {
        return <div className="Visitable"/>;
    }
}
