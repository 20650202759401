import { format } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
	checkData,
	getData,
	getFields,
} from '../../../../../../screens/admin-screens/contributions/helper-functions/getters';
import {
	arrayToClass,
	redirectToLogin,
} from '../../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../../utilities/request';
import { ToastMessages } from '../../../../../notifications/toast-messages';
import { Form } from '../../../../../ui-components/form/form';
import { ContributionFooter } from '../../../contributions/contribution-footer';
import { WidgetContributionPresenter } from '../../../contributions/widget-contribution-presenter/widget-contribution-presenter';
import { authorityContactsFields } from './authority-contacts.helper';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class AuthorityContactsContributionComponent extends React.Component<any, any> {
	public mapDraw$;

	public state: any = {
		isAdmin: (this.props?.user?.roles || []).includes('ADMIN'),
		type: this.props?.item?.contribution?.action,
		editable: [],
		rejected: [],
		buttonDisabled: false,
		title: null,
		field: null,
		formType: null,
		multi: false,
	};

	public form: any = new Form({
		name: this.props?.item?.name,
		type: this.props?.item?.type,
		details: this.props?.item?.details,
	});

	public render(): React.ReactElement {
		const contactsList = getFields(this, authorityContactsFields(this));

		return (
			<div className='Content w-100'>
				<div className='p-2'>
					{(!contactsList || !contactsList.length) &&
						this.renderEmpty()}
					{!!contactsList.length && this.renderContent(contactsList)}
				</div>
				<ContributionFooter
					buttonDisabled={this.state.buttonDisabled}
					isAdmin={this.state.isAdmin}
					handleButtonClick={(isReject: boolean = false) =>
						this.handleButtonClick(isReject)
					}
					close={() => this.close()}
					handleEdit={() => this.handleEdit()}
				/>
			</div>
		);
	}

	public renderContent(list): React.ReactElement {
		return (
			<div className='w-100'>
				<p className='m-0 fw-bold palette--c-neutral-6'>
					Changes in operational notes widget
				</p>
				<div className='p-2 display-none display-md-flex'>
					<h6 className='Property palette--c-neutral-5 fw-bold m-0'>
						Property
					</h6>
					<h6 className='CurrentData palette--c-neutral-5 fw-bold m-0'>
						Current data
					</h6>
					<span className='ArrowIcons material-icons palette--c-neutral-1 fw-bold'>
						arrow_forward
					</span>
					<h6 className='NewData palette--c-neutral-5 fw-bold m-0'>
						New data
					</h6>
				</div>
				{list.map((item: any, index: number) => {
					return this.renderFields(item, index);
				})}
			</div>
		);
	}

	public renderEmpty(): any {
		return null;
	}

	public async onClick(title, field, formType, multi: boolean = true) {
		await this.setState({
			title: title,
			field: field,
			formType: formType,
			multi: multi,
		});

		this.mapDraw$.open();
	}

	private renderFields(field, index): any {
		const wrapperClassName = arrayToClass([
			this.state.rejected.includes(field.type)
				? 'palette--bgc-neutral-3'
				: `palette--bgc-${
						this.state.type === 'UPDATE'
							? 'yellow-1'
							: this.state.type === 'CREATE'
							? 'green-1'
							: 'red-1'
				  }`,
			'border-radius-1 my-2 p-2',
			'display-flex align-items-center',
		]);

		const element = {
			type: field.type,
			content: this.getData(field.type),
			component: field.component,
		};

		return (
			<WidgetContributionPresenter
				isAdmin={this.state.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(field.type)}
				toValue={element}
				isEditable={this.state.editable.includes(field.type)}
				isRejected={this.state.rejected.includes(field.type)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				className={wrapperClassName}
			/>
		);
	}

	private getOriginalData(field: string): any {
		const form = this.props?.original?.[field];
		const value = form || null;

		if (this.state.type === 'CREATE') {
			return null;
		}

		const bools: any[] = [];
		return getData(field, value, false, bools);
	}

	private getData(field: any): any {
		const form = this.form.generateJSON();
		const value = form?.[field];

		if (this.state.type === 'DELETE') {
			return '';
		}

		if (field === 'validFrom' || field === 'validTo') {
			return format(new Date(form?.[field]), 'yyyy-MM-dd, HH:mm');
		}

		const bools: any[] = [];
		return getData(field, value, false, bools);
	}

	private async handleEdit(type: string = 'editAll'): Promise<void> {
		const { editable } = this.state;

		if (type === 'editAll') {
			const contacts =
				authorityContactsFields(this).map((item: any) => item.type) ||
				[];
			await this.setState({ editable: contacts });
		}

		if (!editable.includes(type)) {
			await this.setState({ editable: [...editable, type] });
		}
	}

	private async handleMissEdit(type: string): Promise<void> {
		const { editable } = this.state;

		if (editable.includes(type)) {
			const editableArray = editable.filter((item) => item !== type);
			await this.setState({ editable: editableArray });
		}
	}

	private handleReject(type: string): void {
		const { rejected } = this.state;

		if (!rejected.includes(type)) {
			this.setState({ rejected: [...rejected, type] });
		}
	}

	private handleMissReject(type: string) {
		const { rejected } = this.state;

		if (rejected.includes(type)) {
			const rejectedArray = rejected.filter((item) => item !== type);
			this.setState({ rejected: rejectedArray });
		}
	}

	private clearForm(): void {
		this.form.clearForm();
	}

	private checkModifications() {
		const { item } = this.props;
		const formList = getFields(this, authorityContactsFields(this)) || [];
		const form = this.form.generateJSON();

		item.contribution.rejectedChanges = this.state.rejected;
		item.contribution.changes = checkData(formList, item, form, [
			'details',
		]);

		return item;
	}

	private async handleButtonClick(isReject = false): Promise<void> {
		this.setState({ buttonDisabled: true });
		let { item } = this.props;

		if (isReject) {
			item.contribution.status = 'REJECTED';
		} else {
			item = this.checkModifications();
		}

		try {
			const response = await RequestManager.post(
				`/${
					this.props.isCountry ? 'country' : 'airport'
				}authoritycontacts/merge`,
				item
			);

			if (!response) {
				throw new Error('There was an error');
			}

			this.onSuccess(
				`Contribution is successfully ${
					isReject ? 'rejected' : 'accepted'
				}.`
			);
		} catch (err) {
			this.onError(err);
		}
	}

	private onSuccess(message: string): void {
		this.clearForm();
		toast.success(message, { theme: 'light' });
		this.props.onSuccess();
		this.setState({ buttonDisabled: false });
	}

	private onError(err): void {
		toast.error(<ToastMessages error={err} />, { theme: 'light' });
		this.setState({ buttonDisabled: false });
		redirectToLogin(err);
	}

	private close(): void {
		this.clearForm();
		this.props.close();
		this.setState({ buttonDisabled: false });
	}
}

export const AuthorityContactsContribution: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(AuthorityContactsContributionComponent);
