import { get } from 'lodash';
import * as React from 'react';
import { dimConvert } from '../../utilities/dim.transform';

interface LengthProps {
    value: number;
    sourceDimension: string;
    targetDimension: string;
    precision?: number;
    ignorePrecision?: boolean;
    ignoreDimension?: boolean;
    fromWhere?: string;
    query?: any;
}

export class Length extends React.Component<LengthProps, any> {
    public render(): any {
        const value = get(this.props, 'value');
        const sourceDimension = get(this.props, 'sourceDimension');
        const targetDimension = get(this.props, 'targetDimension');
        const precision = get(this.props, 'precision', 0);
        const ignorePrecision = get(this.props, 'ignorePrecision');
        const ignoreDimension = get(this.props, 'ignoreDimension');

        return dimConvert(value, sourceDimension, targetDimension, precision, ignorePrecision, ignoreDimension);
    }
}
