import { RequestManager } from '../utilities/request';
import { toast } from 'react-toastify';

export abstract class DetailsActions {
	public static readonly TOGGLE_OPEN = 'DetailsActions[TOGGLE_OPEN]';
	public static readonly SET_OPEN = 'DetailsActions[SET_OPEN]';
	public static readonly TOGGLE_OPEN_MOBILE =
		'DetailsActions[TOGGLE_OPEN_MOBILE]';
	public static readonly SET_OPEN_MOBILE = 'DetailsActions[SET_OPEN_MOBILE]';
	public static readonly SET_CURRENT_NAVIGATION_PATH =
		'DetailsActions[SET_CURRENT_NAVIGATION_PATH]';
	public static readonly TOGGLE_WIDGET_OPEN =
		'DetailsActions[TOGGLE_WIDGET_OPEN]';
	public static readonly SET_SUCCESS = 'DetailsActions[SET_SUCCESS]';

	public static toggleOpen() {
		return {
			type: DetailsActions.TOGGLE_OPEN,
		};
	}

	public static setOpen(value: boolean) {
		return {
			type: DetailsActions.SET_OPEN,
			payload: value,
		};
	}

	public static toggleOpenMobile() {
		return {
			type: DetailsActions.TOGGLE_OPEN_MOBILE,
		};
	}

	public static setOpenMobile(value: boolean) {
		return {
			type: DetailsActions.SET_OPEN_MOBILE,
			payload: value,
		};
	}

	public static setCurrentNavigationPath(path: string) {
		return {
			type: DetailsActions.SET_CURRENT_NAVIGATION_PATH,
			payload: path,
		};
	}

	public static mergeContribution(
		mergableData,
		endpoint,
		widgetId,
		onClose: any = false
	) {
		return async (dispatch: (action: any) => void) => {
			let aid = false;
			try {
				const response = await RequestManager.post(
					endpoint,
					mergableData
				);
				aid = response?.contribution?.entityAid;

				if (!response) {
					throw new Error('There was an error');
				}

				dispatch(this.setSuccess(widgetId));
			} catch (err: any) {
				toast.error(err?.response?.data?.message || err || 'Error', {
					theme: 'light',
				});
			} finally {
				if (!!onClose) {
					onClose(aid);
				}
			}
		};
	}

	public static setSuccess(widgetId) {
		return {
			type: DetailsActions.SET_SUCCESS,
			payload: widgetId,
		};
	}
}
