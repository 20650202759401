import * as React from 'react';
import { arrayToClass } from '../../../utilities/helper-fuctions';
import './spinner.scss';

export class Spinner extends React.Component<any, any> {
    public render(): React.ReactElement {
        const className = arrayToClass([
            this.props.isLight ? 'lightLoader' : 'loader',
            `${this.props.size}Loader`,
            this.props.className ? this.props.className : '',
        ]);

        const wrapperClassName = arrayToClass([
            'w-100 display-flex flex-column justify-content-center align-items-center',
            this.props.size === 'small' ? 'px-3' : 'p-3',
            this.props.wrapperClassName ? this.props.wrapperClassName : '',
        ]);

        const color = `palette--c-neutral-${this.props.isLight ? '2' : '5'}`;

        return (
            <div className={wrapperClassName}>
                <div className="Spinner w-100 display-flex justify-content-center align-items-center">
                    <div className={className} />
                </div>
                {this.props.size === 'large' && <p className={arrayToClass(['m-0', color])}>Loading data...</p>}
            </div>
        );
    }
}
