import { get, isEqual, sortBy } from 'lodash';

export const checkData = (list, form, response, ignoreList: any = []) => {
    return list.filter((key) => {
        const original = get(response, key, null);
        const newValue = get(form, key, null);

        if (ignoreList.includes(key)) {
            if (original === newValue) {
                return;
            }

            return key;
        }

        if (original === null && (newValue === null || newValue === '')) {
            return;
        }

        if (Array.isArray(newValue) && isEqual(sortBy(original), sortBy(newValue))) {
            return;
        }

        if (isEqual(original, newValue)) {
            return;
        }

        if (typeof newValue === 'boolean' && newValue !== original) {
            return key;
        }

        if (!isNaN(newValue) && original !== newValue) {
            if (newValue instanceof Date) {
                return new Date(newValue).getTime() !== new Date(original).getTime();
            }

            return key;
        }

        return newValue !== original && newValue;
    });
};
