import { LocalStorage } from '../utilities/local-storage';
import { AnyAction } from 'redux';
import { SettingsActions } from '../actions/settings.actions';
import { UserDetailsActions } from '../actions/user-details.actions';

const getDate = () => {
	const format = LocalStorage.getItem('mu-date') || '';
	if (!!(format || '').match(/y{4}/gi)) {
		return format;
	} else {
		UserDetailsActions.saveUserDetails({
			dateFormat: 'yyyy. MM. dd. hh:mm',
		});

		return 'yyyy. MM. dd.';
	}
};

const initialState = {
	date: getDate(),
	startOfTheWeek: LocalStorage.getItem('mu-startOfTheWeek'),
	heliports: LocalStorage.getItem('heliports'),
	runway: LocalStorage.getItem('runway'),
	metric: LocalStorage.getItem('mu-metric'),
	decimalCoordinates: LocalStorage.getItem('decimal_coordinates'),
	h24: LocalStorage.getItem('h24'),
	widgetOrderAndSize: LocalStorage.getItem('widgetOrderAndSize'),
	lastSearched: LocalStorage.getItem('lastSearched'),
	lastSearchedCountries: LocalStorage.getItem('lastSearchedCountries'),
	mapNewTab: LocalStorage.getItem('mapNewTab'),
};

export const userDetailsReducer = (
	state: any = initialState,
	action: AnyAction
) => {
	if (action.type === SettingsActions.SET_FORMAT) {
		LocalStorage.setItem('mu-date', action.payload.dateFormat);
		LocalStorage.setItem(
			'mu-startOfTheWeek',
			action.payload.startOfTheWeek
		);
		LocalStorage.setItem('mu-metric', action.payload.metric);
		LocalStorage.setItem(
			'decimal_coordinates',
			action.payload.decimalCoordinates
		);

		state = {
			...state,
			date: action.payload.dateFormat,
			startOfTheWeek: action.payload.startOfTheWeek,
			decimalCoordinates: action.payload.decimalCoordinates,
			metric: action.payload.metric,
		};
	}

	if (action.type === SettingsActions.SET_NEARBY_AIRPORT_FILTERS) {
		LocalStorage.setItem('heliports', action.payload.heliports);
		LocalStorage.setItem('runway', action.payload.runway);
		LocalStorage.setItem('h24', action.payload.h24);

		state = {
			...state,
			heliports: action.payload.heliports,
			runway: action.payload.runway,
			h24: action.payload.h24,
		};
	}

	if (action.type === SettingsActions.SET_WIDGET_SETTINGS) {
		LocalStorage.setItem('widgetOrderAndSize', action.payload);

		state = { ...state, widgetOrderAndSize: action.payload };
	}

	if (action.type === SettingsActions.SET_MAP_NEW_TAB) {
		LocalStorage.setItem('mapNewTab', action.payload);

		state = { ...state, mapNewTab: action.payload };
	}

	if (action.type === SettingsActions.SET_LAST_SEARCHED) {
		LocalStorage.setItem('lastSearched', action.payload.airports);
		LocalStorage.setItem('lastSearchedCountries', action.payload.countries);

		state = {
			...state,
			lastSearched: action.payload.airports,
			lastSearchedCountries: action.payload.countries,
		};
	}

	return state;
};
