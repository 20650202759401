import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { PreLoader } from '../../utilities/pre-loader';
import { RequestManager } from '../../utilities/request';
import { MapDrawing } from '../map/map-drawing/map-drawing';
import { ToastMessages } from '../notifications/toast-messages';
import { Form } from '../ui-components/form/form';
import { FormButtons } from '../ui-components/hoverable-buttons/form-modal-buttons/form-buttons';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';
import { TextInput } from '../ui-components/inputs/inputs/text-input';
import { Label } from '../ui-components/inputs/label/label';
import { SearchableSelect } from '../ui-components/inputs/select/searchable-select';
import { WidgetModal } from '../widgets/widget-modal';

class RegionFormComponent extends WidgetModal<any, any> {
    public state = {
        ...this.props.state,
        buttonDisabled: this.props?.isEdit,
        isMapOpen: false,
    };

    public form = new Form(
        {
            name: this.props?.response?.name || this.props?.data?.name || '',
            countries: this.props?.response?.countries || this.props?.data?.countries || '',
            multipolygon: this.props?.response?.multipolygon || this.props?.data?.multipolygon || '',
        },
        (changed) => this.setState({ buttonDisabled: !changed })
    );

    public onClose(isDelete = false) {
        this.props?.router?.navigate({
            pathname: '/admin/regions',
            search: window.location.search,
        });

        if (this.props.onClose) {
            this.props.onClose(isDelete);
        }

        this.form.clearForm();
        this.modal$.close();
    }

    public getTitle() {
        return this.props.isEdit ? 'Edit regions' : 'Create regions';
    }

    public renderForm(): React.ReactElement {
        return (
            <form className="p-4">
                <TextInput
                    id="adminEditRegionFirst"
                    field={this.form.getField('name')}
                    className="py-2"
                    textType="camel"
                    labelInfos={{
                        label: 'Name',
                        ignoreTopMargin: true,
                    }}
                />

                <SearchableSelect
                    isMulti={true}
                    disableDefault={true}
                    title="name"
                    classes="py-2"
                    field={this.form.getField('countries')}
                    endpoint={'/free/countries/search'}
                    labelInfos={{
                        label: 'Countries',
                    }}
                />

                <div className="w-100 py-2">
                    <Label label="Polygons" className="mb-2" />
                    <div className="display-flex align-items-center">
                        <HoverableButton
                            type="button"
                            className="border-radius-1"
                            colorType="avio-green"
                            title="Add polygons"
                            onClick={() => this.setState({ isMapOpen: true })}
                        />
                        {!!this.form.getValue('multipolygon') && (
                            <span className="CheckMapValues material-icons palette--c-primary-4 ml-4">check</span>
                        )}
                    </div>
                </div>

                {this.state.isMapOpen && (
                    <MapDrawing
                        isMulti={true}
                        title="Add polygons to region"
                        field={this.form.getField('multipolygon')}
                        polygons={this.form.getValue('multipolygon')}
                        onClose={() => this.setState({ isMapOpen: true })}
                    />
                )}
            </form>
        );
    }

    public renderFooter(): React.ReactElement {
        return (
            <FormButtons
                saveDisabled={this.state?.buttonDisabled}
                isDelete={this.props?.isEdit}
                onClose={() => this.onClose()}
                onSave={() => this.handleSave()}
                onDelete={() => this.deleteModal$.open()}
            />
        );
    }

    public getType() {
        return 'region';
    }

    public async handleSave() {
        const form: any = this.form.generateJSON();

        try {
            const response = await this.save(form);

            if (!response) {
                throw new Error('there was an error');
            }

            this.onClose();
        } catch (err) {
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
        }
    }

    public async onDelete() {
        try {
            await RequestManager.delete(`/regions/${get(this.props, 'aid')}`);

            this.deleteModal$.close();
            this.onClose();
        } catch (err) {
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
        }
    }

    public save(data) {
        if (this.props?.isEdit) {
            return RequestManager.put(`/regions/${get(this.props, 'aid')}`, data);
        }

        return RequestManager.post('/regions', data);
    }
}

export const RegionForm: any = PreLoader(RegionFormComponent);
