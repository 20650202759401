import { enGB } from 'date-fns/locale';
import { connect } from 'react-redux';
import { addDays, format, isSameMinute, isValid } from 'date-fns';
import * as React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { UserDetailsActions } from '../../../../actions/user-details.actions';
import { arrayToClass } from '../../../../utilities/helper-fuctions';
import { Label } from '../label/label';
import './date-picker.scss';
import { TimePicker } from './time-picker';

const mapStateProps = (store: any) => ({
	date: store.userDetails.date,
	startOfTheWeek: store.userDetails.startOfTheWeek,
});

const mapDispatchProps = (dispatch: any) => ({
	saveUserDetails: (data: any) =>
		dispatch(UserDetailsActions.saveUserDetails(data)),
});

class DatePickerComponent extends React.Component<any, any> {
	public state = {
		date: this.getValue(),
	};

	public getValue() {
		if (!!this.props?.field?.newValue) {
			return new Date(this.props?.field?.newValue);
		} else {
			let newValue = addDays(new Date(), this.props.days || 0);

			this.props?.field?.setValue(newValue);
			return newValue;
		}
	}

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (this.props.date !== prevProps.date) {
			this.forceUpdate();
		}

		if (this.props.startOfTheWeek !== prevProps.startOfTheWeek) {
			this.forceUpdate();
		}

		const current = this.props?.field?.newValue;
		const state = this.state?.date;

		if (!isSameMinute(current, state)) {
			this.setState({ date: this.getValue() });
		}
	}

	public render(): React.ReactElement {
		let date = this.props.dateFormat
			? this.props.dateFormat
			: this.props?.date;

		if ((date || '').match(/undefined/gi)) {
			date = `yyyy. MM. dd. ${(date || '').replace(
				/(hh:mm)( a|)/gi,
				''
			)}`;
		}

		if (!this.props.useHour) {
			date = (date || '').replace(/(hh:mm)( a|)/gi, '');
		}

		date = this.dateValidator(date);

		const timeProp = this.props.useHour
			? {
					customTimeInput: (
						<TimePicker
							time={format(new Date(this.state.date), 'HH:mm')}
							timeFormat={
								(this.props.date || '').match(/hh:mm a/gi)
									? 12
									: 24
							}
							setTime={(time) => this.handleChange(time, true)}
						/>
					),
			  }
			: {};

		const wrapperClassName = arrayToClass([
			'w-100',
			this.props.classes ? this.props.classes : '',
		]);
		const inputClasses = arrayToClass([
			'w-100 border-1 border-radius-1 palette--bgc-neutral-1 display-flex align-items-center',
			`palette--bc-${
				this.props?.error
					? 'red-2'
					: this.props?.disabled
					? 'neutral-2'
					: 'neutral-4'
			}`,
			`palette--bgc-neutral-${this.props?.disabled ? '2' : '1'}`,
		]);

		registerLocale('en-gb', enGB);
		return (
			<div className={wrapperClassName}>
				{this.renderLabel()}
				<div className={inputClasses}>
					<DatePicker
						{...this.props}
						{...timeProp}
						selected={this.state.date}
						onChange={(value) => this.handleChange(value)}
						locale={
							this.props.startOfTheWeek === 'Sunday'
								? ''
								: 'en-gb'
						}
						dateFormat={date}
					/>
					{!!this.props.isIcon && (
						<span className='material-icons palette--c-neutral-5 mx-2'>
							date_range
						</span>
					)}
				</div>
			</div>
		);
	}

	public renderLabel() {
		const label = this.props?.labelInfos;
		const labelClasses = arrayToClass([
			'mb-2',
			label?.classes ? label?.classes : '',
			this.props?.field?.mandatory ? 'asterix' : '',
		]);

		if (!label) {
			return null;
		}

		return (
			<Label
				info={label.info || false}
				label={label.label || false}
				className={labelClasses}
				ignoreTopMargin={label.ignoreTopMargin || false}
				isSimple={label.isSimple || false}
			/>
		);
	}

	public handleChange(newValue, isTime = false) {
		let value;

		if (!!isTime) {
			const date = format(new Date(this.state.date), 'yyyy-MM-dd');
			value = new Date(`${date} ${newValue}`);
		} else {
			value = new Date(newValue);
		}

		if (!isValid(value)) {
			return;
		}

		this.setState({ date: value });
		this.props.field.setValue(value);
	}

	public dateValidator(format) {
		if (this.props.saveUserDetails && !format) {
			this.props.saveUserDetails({
				dateFormat: 'yyyy. MM. dd. hh:mm',
			});

			return format;
		}

		return format;
	}
}

export const DateTimePicker: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(DatePickerComponent);
