import { format } from 'date-fns';
import * as React from 'react';
import { Form } from '../../form/form';
import { Input } from '../inputs/input';
import { Select } from '../select/select';

const dayOptions = [
	{ title: 'AM', value: 'am' },
	{ title: 'PM', value: 'pm' },
];

export class TimePicker extends React.Component<any, any> {
	public form = new Form(
		{
			time: {
				value: this.props?.time
					? this.convertTime(this.props?.time)
					: '',
				trigger: () => this.handleTimeChange(),
			},
			dayState: this.getDayState(),
		},
		() => this.handleFormChange()
	);

	public handleTimeChange() {
		const value = this.form.getField('time').newValue;
		const convertedTime = this.convertTime(value);

		if (convertedTime !== value && !!convertedTime) {
			this.form.setValue('time', convertedTime);
		}
	}

	public handleFormChange() {
		const form: any = this.form.generateJSON();
		const formattedHours = this.convertBackTime(
			form.time,
			form.dayState === 'pm'
		);

		if (this.props.setTime) {
			this.props?.setTime(formattedHours);
		}
	}

	public getDayState() {
		const { time } = this.props;
		const timeWithDate = `${format(new Date(), 'yyyy-MM-dd')} ${time}`;
		const hourOnly = format(new Date(timeWithDate), 'HH');

		return parseInt(hourOnly) > 12
			? dayOptions[1]?.value
			: dayOptions[0]?.value;
	}

	public render() {
		return (
			<div className='TimeInput display-flex'>
				<Input
					field={this.form.getField('time')}
					classes='Input mr-2'
				/>
				{this.props.timeFormat === 12 && (
					<Select
						classes='Select mr-2'
						field={this.form.getField('dayState')}
						options={dayOptions}
					/>
				)}
			</div>
		);
	}

	public convertTime(value) {
		if (!value) {
			return '';
		}

		let fullTime = value;

		if (!`${value}`.includes(':') && value.length >= 2) {
			const firstHalf = value.slice(0, 2);
			const secondHalf = value.slice(2);
			fullTime = `${firstHalf}:${secondHalf}`;

			this.form.setValue('time', fullTime);
		}

		const splitTime = value.split(':');
		const hour = splitTime[0];
		const minutes = splitTime[1] || '';
		const availableTwelveTimes = {
			'12': '12',
			'13': '01',
			'14': '02',
			'15': '03',
			'16': '04',
			'17': '05',
			'18': '06',
			'19': '07',
			'20': '08',
			'21': '09',
			'22': '10',
			'23': '11',
			'24': '12',
		};

		if (this.props.timeFormat === 12 && hour >= 12) {
			let checkedHour = hour;
			let checkedMinutes = minutes;

			if (checkedHour > 24) {
				checkedHour = 24;
			}

			const convertedHour = availableTwelveTimes[checkedHour];

			if (checkedMinutes > 59) {
				checkedMinutes = 59;
			}

			return `${convertedHour}:${checkedMinutes}`;
		} else {
			let checkedHour = hour;
			let checkedMinutes = minutes;

			if (checkedHour > 24) {
				checkedHour = 24;
			}

			if (checkedMinutes > 59) {
				checkedMinutes = 59;
			}

			return value;
		}
	}

	public convertBackTime(value, isPm) {
		const splitTime = value.split(':');
		const hour = splitTime[0];
		const minutes = splitTime[1] || '';
		const availableTwelveTimes = {
			'01': '13',
			'02': '14',
			'03': '15',
			'04': '16',
			'05': '17',
			'06': '18',
			'07': '19',
			'08': '20',
			'09': '21',
			'10': '22',
			'11': '23',
			'12': '24',
		};

		if (this.props.timeFormat === 12 && isPm) {
			return `${availableTwelveTimes[hour]}:${minutes}`;
		} else {
			return value;
		}
	}
}
