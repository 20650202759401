import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../../../../../actions/details.actions';
import { LocalstorageActions } from '../../../../../../actions/localstorage.actions';
import { connectNLP } from '../../../../../../utilities/connect-navigate';
import {
	debounce,
	isInQuery,
    toUtc,
} from '../../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../../utilities/request';
import { ErrorPresenter } from '../../../../../notifications/error-presenter';
import { ToastMessages } from '../../../../../notifications/toast-messages';
import { AvailabilityLines } from '../../../../../other/availability-lines';
import { ContributeButton } from '../../../../../other/contribute-button';
import { Form } from '../../../../../ui-components/form/form';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { DateTimePicker } from '../../../../../ui-components/inputs/date-picker/date-time-picker';
import { Spinner } from '../../../../../ui-components/spinner/spinner';
import { Background } from '../../../opening-hours/background';
import './providers-availability.scss';
import { startOfDay } from 'date-fns';

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
	fetchNewSun: (aid, date) =>
		dispatch(LocalstorageActions.fetchNewSun(aid, date)),
	setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

class ProvidersAvailabilityComponent extends React.Component<any, any> {
	public isMounted = false;

	public state = {
		loading: false,
		error: false,
		response: false,
	};

	public form = new Form({
		date: {
			value: new Date(),
			trigger: () => debounce(() => this.handleDateChange())(),
		},
	});

	public handleDateChange() {
		if ((this.form.getDifferences() || []).includes('date')) {
			const value = this.form.getValue('date');

			this.fetch();

			if (this.props.fetchNewSun) {
				this.props.fetchNewSun(this.props?.aid, value);
			}
		}
	}

	public componentDidMount() {
		this.isMounted = true;
		this.fetch();
	}

	public componentDidUpdate(prevProps) {
		if (this.props.refresh !== prevProps.refresh) {
			this.fetch();
			this.props.setSuccess();
		}
	}

	public componentWillUnmount() {
		this.isMounted = false;
	}

	public async fetch(): Promise<void> {
		try {
			if (this.isMounted) {
				this.setState({ loading: true });
			}

            const localDate = startOfDay(this.form.getValue('date'));
            const utcDate = toUtc(localDate);

			const dateTime = {
				dateTime: utcDate,
				local: true,
			};

			const response = await RequestManager.get(
				`/providers/${this.props?.providerAid}/availability`,
				dateTime
			);

			if (!response) {
				throw new Error('empty openings');
			}

			if (this.isMounted) {
				this.setState({ response: response });
			}
		} catch (err: any) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });

			if (this.isMounted) {
				this.setState({
					err: err?.response?.data?.message || err || 'Error',
				});
			}
		} finally {
			if (this.isMounted) {
				this.setState({ loading: false });
			}
		}
	}

	public render(): React.ReactElement {
		return (
			<div className='ProvidersAvailability w-100 mt-2 palette--bc-neutral-4 border-bottom-1 pb-3 mb-2'>
				<div className='display-flex w-100 mb-2'>
					<h3 className='palette--c-neutral-6 fw-bold m-0 flex-fill'>
						Availability
					</h3>
					<HoverableButton
						className='border-radius-1 ml-2'
						colorType='avio-green'
						title='Add availability'
						onClick={() => this.handleCreateAvailability()}
					/>
				</div>
				{this.state.loading &&
					!this.state.response &&
					!this.state.error &&
					this.renderLoading()}
				{!this.state.loading &&
					!this.state.response &&
					this.state.error &&
					this.renderError()}
				{!this.state.loading &&
					this.state.response &&
					!this.state.error &&
					this.renderContent()}
			</div>
		);
	}

	public renderLoading(): React.ReactElement {
		return <Spinner size='large' />;
	}

	public renderError(): React.ReactElement {
		return <ErrorPresenter error={this.state.error} />;
	}

	public renderContent(): React.ReactElement {
		return (
			<div className='w-100 border-radius-1 position-relative'>
				<Background
					classNames='Gradient'
					date={this.form.getValue('date')}
					aid={this.props?.aid}
				/>
				<div className='Content position-relative p-4'>
					<div className='display-flex align-items-center mb-4'>
						{this.renderDatePicker()}
						<div className='display-flex justify-content-center align-items-center ml-2'>
							<p className='palette--c-neutral-2 mb-0'>
								All times are local
							</p>
						</div>
					</div>
					{this.colorizeLineBoxes()}
				</div>
			</div>
		);
	}

	public renderDatePicker(): React.ReactElement {
		return (
			<form>
				<DateTimePicker
					field={this.form.getField('date')}
					inputClassName='palette--c-neutral-5'
					isIcon={true}
				/>
			</form>
		);
	}

	private colorizeLineBoxes(): React.ReactElement {
		const { response } = this.state;

		if (!response || !response[0]) {
			return (
				<ContributeButton
					openModal={() => open()}
					label={'Provider availability'}
					isAre={false}
				/>
			);
		}

		return (
			<AvailabilityLines
				airportId={this.props?.aid}
				states={response}
				isFooter={true}
				isMarker={true}
				type='provider'
			/>
		);
	}

	public handleCreateAvailability() {
		const utmSource = isInQuery('utm_source');
		this.props?.router?.navigate({
			pathname: `${window.location.pathname}/provider-availability`,
			search: utmSource,
		});
	}
}

export const ProvidersAvailability: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(connectNLP(ProvidersAvailabilityComponent));
