import * as React from 'react';
import { connect } from 'react-redux';
import { dimConvert } from '../../../../utilities/dim.transform';
import { arrayToClass, randomId } from '../../../../utilities/helper-fuctions';
import { Form } from '../../form/form';
import { Input } from '../inputs/input';
import { Label } from '../label/label';
import { Select } from '../select/select';
import './measure-input.scss';

const mapStateProps = (store: any) => ({
	metric: store.userDetails.metric ? 1 : 0,
});

const mapDispatchProps = (dispatch: any) => ({});

class MeasurementInputComponent extends React.Component<any, any> {
	public id = randomId();

	public state = {
		options: this.getOptions(),
	};

	public form = new Form({
		value: {
			value: this.convertMeasure(true),
			trigger: () => this.handleInputchange(),
		},
		measure: {
			value: this.getMeasure(),
			trigger: () => this.convertMeasure(),
		},
	});

	public prevMeasure = null;
	public valueWithDecimal: any = null;

	public componentDidUpdate(prevProps) {
		const value = this.form?.getValue('value');
		const target = this.props.type === 'length' ? 'm' : 'kg';
		const source = this.form.getValue('measure') || this.getMeasure();
		let convertedValue = value;

		if (!!value && target !== source) {
			convertedValue = dimConvert(value, source, target, 2);
		}

		if (
			convertedValue !== this.props?.field?.newValue &&
			!!this.props?.field
		) {
			this.form?.setValue('value', this.convertMeasure());
			this.forceUpdate();
		}
	}

	public getOptions() {
		if (this.props.type === 'length') {
			return [
				{ title: 'm', value: 'm' },
				{ title: 'ft', value: 'ft' },
			];
		} else {
			return [
				{ title: 'kg', value: 'kg' },
				{ title: 'pounds', value: 'pounds' },
			];
		}
	}

	public getMeasure() {
		const options = this.getOptions();
		const value = options[this.props.metric ? 0 : 1].value;

		return value;
	}

	public convertMeasure(initialize = false) {
		const value = this.valueWithDecimal || this.props?.field?.newValue;
		const measure: any = initialize
			? this.getMeasure()
			: this.form.getValue('measure');
		const measureType = this.props.type === 'length' ? 'm' : 'kg';

		const source: any = !this.prevMeasure ? measureType : this.prevMeasure;
		const target = measure;

		if ((source === target && !initialize) || !value) {
			return value;
		}

		const newRoundedValue = dimConvert(value, source, target, 2);

		this.prevMeasure = measure;
		this.valueWithDecimal = newRoundedValue;

		if (initialize) {
			return newRoundedValue;
		} else {
			this.form.setValue('value', newRoundedValue);
			this.forceUpdate();
		}
	}

	public render() {
		const wrapper = arrayToClass([
			this.props.classes,
			'MeasureWrapper limit-w',
		]);
		const classes = arrayToClass(['w-100 display-flex align-items-end']);

		return (
			<div className={wrapper}>
				{this.renderLabel()}
				<div className={classes}>
					<Select
						disabled={this.props?.disabled || false}
						id={this.id}
						field={this.form.getField('measure')}
						classes='MeasurementSelect'
						options={this.state.options || []}
					/>
					<Input
						field={this.form.getField('value')}
						inputType='number'
						disabled={this.props.disabled}
						maxLengthLimit={this.props.maxLimit || false}
						numeralPositiveOnly={
							this.props.numeralPositiveOnly || false
						}
					/>
				</div>
			</div>
		);
	}

	public renderLabel() {
		const label = this.props?.labelInfos;
		const labelClasses = arrayToClass([
			'mb-2',
			label?.classes ? label?.classes : '',
			this.props?.field?.mandatory ? 'asterix' : '',
		]);

		if (!label) {
			return null;
		}

		return (
			<Label
				info={label.info || false}
				label={label.label || false}
				className={labelClasses}
				ignoreTopMargin={label.ignoreTopMargin || false}
				isSimple={label.isSimple || false}
			/>
		);
	}

	public handleInputchange() {
		let value = this.form?.getValue('value');

		if (!value || value === '') {
			this.props.field.setValue('');
			return;
		}

		const source = this.form.getValue('measure');
		const target = this.props.type === 'length' ? 'm' : 'kg';
		this.valueWithDecimal = parseFloat(value as string);
		this.prevMeasure = source;

		if (source !== target) {
			value = dimConvert(value, source, target, 2);
		}

		const originalValue = this.props?.field?.newValue;

		if (value !== originalValue) {
			this.props.field.setValue(value);
		}
	}
}

export const MeasurementInput: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(MeasurementInputComponent);
