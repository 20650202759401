export const generateDMS = (number: number, lng: boolean = false): any => {
    if (number === null || typeof number === 'undefined') {
        return;
    }

    const absNum = Math.abs(number);

    let degrees: any = Math.trunc(absNum);

    let isPositive = number > 0 ? 'N' : 'S';

    if (lng) {
        degrees = degrees > 99 ? degrees : degrees > 9 ? `0${degrees}` : `00${degrees}`;
        isPositive = number > 0 ? 'E' : 'W';
    }

    const remaining = (absNum % 1) * 60;

    const minutes = Math.trunc(remaining);

    const secondRemaining = (remaining % 1) * 60;

    const seconds = Math.round(secondRemaining * 100) / 100;

    return `${isPositive} ${degrees}° ${minutes}' ${seconds}"`;
};
