import * as React from 'react';
import { toast } from 'react-toastify';
import { PreLoader } from '../../../../utilities/pre-loader';
import { airportInfos } from '../../../../utilities/widget-helpers/airport-infos';
import { request } from '../../../../utilities/widget-helpers/request';
import { ToastMessages } from '../../../notifications/toast-messages';
import { Form } from '../../../ui-components/form/form';
import { Checkbox } from '../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { Input } from '../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../ui-components/inputs/inputs/text-input';
import { Label } from '../../../ui-components/inputs/label/label';
import { MeasurementInput } from '../../../ui-components/inputs/measure-input/measurement-input';
import { formatOptions } from '../../../ui-components/inputs/select/formatter';
import { Select } from '../../../ui-components/inputs/select/select';
import { SwitchSelect } from '../../../ui-components/inputs/select/switch-select';
import { WidgetModal } from '../../widget-modal';
import { airportOptions } from '../general-data/airport-contribution/airport-contribution-helpers';

class OperationalDataFormComponent extends WidgetModal<any, any> {
	public state: any = {
		...this.state,
		errorInqualificationNotesLength: false,
		showMandatory: this.props?.response?.mandatoryQualification,
		paxLimitDisable: this.props?.response?.paxLimit === 999,
		weightLimitDisable: this.props?.response?.weightLimit === 9999999,
		wingspanLimitDisable: this.props?.response?.wingspanLimit === 999,
		accessibleForDisable:
			(this.props?.response?.accessibleFor || []).length ===
			(airportOptions.accessibleFor || []).length,
	};

	public form = new Form(
		{
			type: this.props?.response?.type,
			operator: this.props?.response?.operator,
			accessibleFor: this.props?.response?.accessibleFor,
			ifr: this.props?.response?.ifr,
			mandatoryHandling: this.props?.response?.mandatoryHandling,
			mandatoryQualification: {
				value: this.props?.response?.mandatoryQualification,
				trigger: () => this.hideOrShowMandatory(),
			},
			qualificationLink: this.props?.response?.qualificationLink,
			qualificationNotes: {
				value: this.props?.response?.qualificationNotes,
				trigger: () => this.checkTextLength('qualificationNotes'),
			},
			nonScheduledPermission:
				this.props?.response?.nonScheduledPermission,
			nonScheduledPermissionNotes:
				this.props?.response?.nonScheduledPermissionNotes,
			paxLimit: {
				value: this.props?.response?.paxLimit,
				trigger: () => this.handleDisables('paxLimit', 999),
			},
			weightLimit: {
				value: this.props?.response?.weightLimit,
				trigger: () => this.handleDisables('weightLimit', 9999999),
			},
			wingspanLimit: {
				value: this.props?.response?.wingspanLimit,
				trigger: () => this.handleDisables('wingspanLimit', 999),
			},
			fuelsAvailable: this.props?.response?.fuelsAvailable,
		},
		(changed) => this.handleFormChange(changed)
	);

	public helper = new Form({
		all: {
			value:
				(this.props?.response?.accessibleFor || []).length ===
				(airportOptions.accessibleFor || []).length,
			trigger: () => this.handleToggle('accessibleFor'),
		},
		paxLimit: {
			value: this.props?.response?.paxLimit === 999,
			trigger: () => this.handleToggle('paxLimit', 999),
		},
		weightLimit: {
			value: this.props?.response?.weightLimit === 9999999,
			trigger: () => this.handleToggle('weightLimit', 9999999),
		},
		wingspanLimit: {
			value: this.props?.response?.wingspanLimit === 999,
			trigger: () => this.handleToggle('wingspanLimit', 999),
		},
	});

	public handleFormChange(changed) {
		if (!this.state?.errorInqualificationNotesLength) {
			this.setState({ buttonDisabled: !changed });
		} else {
			this.setState({ buttonDisabled: true });
		}
	}

	public handleDisables(field, limit) {
		const newValue = this.form.getValue(field);
		const toNum = parseInt(newValue);

		if (toNum > limit) {
			this.helper.setValue(field, limit);
		}
	}

	public handleToggle(field, limit: any = []) {
		const stateName = `${field}Disable`;
		const newValue = !this.state?.[stateName];
		this.setState({ [stateName]: newValue });

		if (!!newValue && field !== 'accessibleFor') {
			this.form.setValue(field, limit);
			return;
		} else if (!!newValue && field === 'accessibleFor') {
			const opts = formatOptions(airportOptions.accessibleFor, false).map(
				(item) => item.value
			);
			this.form.setValue('accessibleFor', opts);
		}
	}

	public checkTextLength(type) {
		const stateName = `errorIn${type}Length`;
		const newValue = this.form.getValue(type);
		const valueLength = (newValue || '').length;

		const isChanged = this.form.hasChanged();

		if (valueLength > 255) {
			this.setState({ [stateName]: valueLength }, () =>
				this.handleFormChange(isChanged)
			);
		} else if (valueLength < 255 && !!this.state?.[stateName]) {
			this.setState({ [stateName]: false }, () =>
				this.handleFormChange(isChanged)
			);
		}
	}

	public hideOrShowMandatory() {
		const newValue = this.form.getValue('mandatoryQualification');
		this.setState({ showMandatory: newValue });

		if (!newValue) {
			this.form.setValue('qualificationLink', null);
			this.form.setValue('qualificationNotes', null);
		}
	}

	public onClose(): void {
		this.form.clearForm();

		if (this.props.onClose) {
			this.props.onClose();
		}

		if (this.modal$) {
			this.modal$.close();
		}
	}

	public getTitle() {
		return 'Operations';
	}

	public getType() {
		return 'operations';
	}

	public renderForm(): any {
		return (
			<form>
				<div className='border-bottom-1 palette--bc-neutral-3 p-4'>
					<h6 className='palette--c-neutral-5 fw-bold text-uppercase'>
						General Information
					</h6>
					<Select
						classes='py-2 limit-w'
						field={this.form.getField('type')}
						options={formatOptions(airportOptions.airportDefault)}
						labelInfos={{
							label: 'Location type',
							info: airportInfos.type,
						}}
					/>
					<div className='FormContainer'>
						<Select
							classes='py-2'
							field={this.form.getField('operator')}
							options={formatOptions(
								airportOptions.airportOperator
							)}
							labelInfos={{
								label: 'Operator',
								info: airportInfos.operator,
							}}
						/>
						<Select
							classes='py-2 left-m'
							field={this.form.getField('ifr')}
							options={formatOptions(airportOptions.ifr)}
							labelInfos={{
								label: 'Flight rules',
								info: airportInfos.ifr,
							}}
						/>
						<Select
							classes='py-2 left-m'
							field={this.form.getField('fuelsAvailable')}
							isMulti={true}
							options={formatOptions(
								airportOptions.fuelsAvailable,
								false
							)}
							labelInfos={{
								label: 'Fuels Available',
							}}
						/>
					</div>

					<div className='w-100 py-2'>
						<Label
							info={airportInfos.accessibleFor}
							label='Accepted traffic'
							className='mb-2'
						/>

						<div className='w-100 display-flex align-items-center'>
							<Select
								isMulti={true}
								field={this.form.getField('accessibleFor')}
								disabled={this.state?.accessibleForDisable}
								options={formatOptions(
									airportOptions.accessibleFor,
									false
								)}
							/>
							<Checkbox
								field={this.helper.getField('all')}
								labelOnLeft={true}
								labelInfos={{
									label: 'Select all',
								}}
							/>
						</div>
					</div>
				</div>
				<div className='border-bottom-1 palette--bc-neutral-3 p-4'>
					<h6 className='palette--c-neutral-5 fw-bold text-uppercase'>
						mandatory options
					</h6>
					<div className='FormContainer'>
						<SwitchSelect
							classes='py-2 limit-w'
							field={this.form.getField('mandatoryHandling')}
							labelInfos={{
								info: airportInfos.mandatoryHandling,
								label: 'Mandatory handling',
							}}
						/>
						<SwitchSelect
							classes='py-2 left-m limit-w'
							field={this.form.getField('mandatoryQualification')}
							labelInfos={{
								info: airportInfos.mandatoryQualification,
								label: 'Mandatory qualification',
							}}
						/>
					</div>

					{!!this.state?.showMandatory && (
						<div>
							<TextInput
								textType='camel'
								field={this.form.getField('qualificationLink')}
								classes='py-2'
								labelInfos={{
									label: 'Mandatory qualification link',
									info: airportInfos.qualificationLink,
								}}
							/>
							<div className='display-flex align-items-end mt-2'>
								<Label
									label='Mandatory qualification notes'
									className='flex-fill'
								/>
								{this.state.errorInqualificationNotesLength && (
									<div className='display-flex'>
										<span className='palette--c-neutral-5'>
											255/
										</span>
										<span className='palette--c-red-2 fw-bold'>
											{
												this.state
													.errorInqualificationNotesLength
											}
										</span>
									</div>
								)}
							</div>
							<TextInput
								element='textarea'
								rows={3}
								field={this.form.getField('qualificationNotes')}
								classes='py-2'
								error={
									this.state.errorInqualificationNotesLength
								}
								textType='sentences'
							/>
						</div>
					)}
				</div>
				<div className='border-bottom-1 palette--bc-neutral-3 p-4'>
					<h6 className='palette--c-neutral-5 fw-bold text-uppercase'>
						permission options
					</h6>
					<Select
						classes='py-2'
						field={this.form.getField('nonScheduledPermission')}
						options={formatOptions(airportOptions.permission)}
						labelInfos={{
							label: 'Non scheduled permission',
							info: airportInfos.nonScheduledPermission,
						}}
					/>
					<TextInput
						element='textarea'
						rows={3}
						field={this.form.getField(
							'nonScheduledPermissionNotes'
						)}
						classes='py-2'
						textType='sentences'
						labelInfos={{
							label: 'Permission notes',
						}}
					/>
				</div>
				<div className='p-4'>
					<h6 className='palette--c-neutral-5 fw-bold text-uppercase'>
						Limitations
					</h6>

					<Label
						info={airportInfos.paxLimit}
						label='Passenger limits'
						className='mb-2'
					/>
					<div className='display-flex align-items-center pt-2'>
						<Input
							disabled={this.state.paxLimitDisable}
							field={this.form.getField('paxLimit')}
							classes='mr-4 limit-w'
							inputType='number'
							maxLengthLimit={3}
						/>
						<Checkbox
							field={this.helper.getField('paxLimit')}
							labelInfos={{
								label: 'Not limited',
							}}
						/>
					</div>

					<Label
						info={airportInfos.weightLimit}
						label='Weight limit'
						className='mb-2'
					/>
					<div className='display-flex align-items-center pt-2'>
						<MeasurementInput
							classes='mr-4'
							type='mass'
							field={this.form.getField('weightLimit')}
							disabled={this.state.weightLimitDisable}
							maxLengthLimit={7}
						/>
						<Checkbox
							field={this.helper.getField('weightLimit')}
							labelInfos={{
								label: 'Not limited',
							}}
						/>
					</div>

					<Label
						info={airportInfos.wingspanLimit}
						label='Wingspan limit'
						className='mb-2'
					/>
					<div className='display-flex align-items-center pt-2'>
						<MeasurementInput
							classes='mr-4'
							type='length'
							field={this.form.getField('wingspanLimit')}
							disabled={this.state.wingspanLimitDisable}
							maxLengthLimit={3}
						/>
						<Checkbox
							field={this.helper.getField('wingspanLimit')}
							labelInfos={{
								label: 'Not limited',
							}}
						/>
					</div>
				</div>
			</form>
		);
	}

	public async onSave(): Promise<void> {
		if (!!this.state.errorInqualificationNotesLength) {
			toast.warning('Please check the errors before you press save.', {
				theme: 'light',
			});
		}

		this.setState({ saveLoading: true, buttonDisabled: true });

		const checkOperations = this.form.generateJSON();
		const data = { ...this.props?.response, ...checkOperations };

		const req = request(
			'/airports/merge',
			'operations',
			() => this.handleSuccessModal(),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		);

		await req.edit(
			`/airports/${this.props?.aid}`,
			data,
			this.form?.getDifferences()
		);

		this.setState({ saveLoading: false, buttonDisabled: false });
	}

	public handleSuccessModal() {
		if (this.notif$) {
			this.notif$.open();
		}
		this.form.clearForm();
		this.modal$.close();
	}
}

export const OperationalDataForm: any = PreLoader(OperationalDataFormComponent);
