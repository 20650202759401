import { Modal } from '../../ui-components/modal/modal';
import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { HoverableButton } from '../../ui-components/hoverable-buttons/hoverable-buttons';

export class SelfCreatedTokenModal extends React.Component<any, any> {
    public modal$: Modal;

    public open() {
        this.modal$.open();
    }

    public render() {
        return (
            <Modal
                ref={(ref: any) => (this.modal$ = ref)}
                title={() => this.renderHeader()}
                content={() => this.renderContent()}
                footer={() => this.renderFooter()}
            />
        );
    }

    public renderHeader() {
        return (
            <div className="w-100 p-4">
                <h3 className="palette--c-neutral-6 m-0 fw-bold">Your new token</h3>
            </div>
        );
    }

    public renderContent() {
        return (
            <div className="w-100 px-4 py-2">
                <p className="palette--c-neutral-5">This is your new token, save it to somewhere safe!</p>
                <div className="border-radius-1 palette--bc-neutral-4 border-1 w-100 display-flex overflow-hidden">
                    <div className="flex-fill py-1 px-2">
                        <p id="Token" className="palette--c-neutral-6 m-0">
                            {get(this.props, 'token')}
                        </p>
                    </div>
                    <HoverableButton title="Copy!" colorType="avio-green" onClick={() => this.handleCopy()} />
                </div>
            </div>
        );
    }

    public renderFooter() {
        return (
            <div className="w-100 p-4 display-flex justify-content-end">
                <HoverableButton
                    className="border-radius-1"
                    colorType="cancel"
                    title="Cancel"
                    onClick={() => this.close()}
                />
            </div>
        );
    }

    public close() {
        if (this.props.onClose) {
            this.props.onClose();
        }

        this.modal$.close();
    }

    public handleCopy() {
        const range = document.createRange();
        const element: any = document.getElementById('Token');
        range.selectNode(element);
        const selection: any = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
        toast.success('Token copied to clipboard!', { theme: 'light' });
    }
}
