import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../actions/details.actions';
import { checkData } from '../../utilities/check-data';
import {
	arrayToClass,
	handleCoordinates,
	redirectToLogin,
} from '../../utilities/helper-fuctions';
import { RequestManager } from '../../utilities/request';
import { ToastMessages } from '../notifications/toast-messages';
import { Form } from '../ui-components/form/form';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';
import { CoordinatesInput } from '../ui-components/inputs/coordinates-input';
import { Input } from '../ui-components/inputs/inputs/input';
import { Label } from '../ui-components/inputs/label/label';
import { MeasurementInput } from '../ui-components/inputs/measure-input/measurement-input';
import { formatOptions } from '../ui-components/inputs/select/formatter';
import { Select } from '../ui-components/inputs/select/select';
import { SwitchSelect } from '../ui-components/inputs/select/switch-select';
import { runwayOptions } from '../widgets/airport/runways/runways-contribution/runway-contribution-helpers';

const mapStateProps = (store: any) => ({
	user: store.user.user,
	widgetSuccess: store.userDetails.widgetSuccess,
});

const mapDispatchProps = (dispatch: any) => ({
	mergeContribution: (data, endpoint, widgetId, callback) =>
		dispatch(
			DetailsActions.mergeContribution(data, endpoint, widgetId, callback)
		),
});

class RunwayComponent extends React.Component<any, any> {
	public state = {
		error: false,
	};

	public form = new Form(
		{
			identifier: this.props?.data?.identifier,
			helipad: this.props?.data?.helipad,
			trueBearing: this.props?.data?.trueBearing,
			magneticBearing: this.props?.data?.magneticBearing,
			tora: this.props?.data?.tora,
			toda: this.props?.data?.toda,
			asda: this.props?.data?.asda,
			lda: this.props?.data?.lda,
			width: this.props?.data?.width,
			pcnNumerical: this.props?.data?.pcnNumerical,
			pcnType: this.props?.data?.pcnType,
			pcnStrength: this.props?.data?.pcnStrength,
			pcnTirePressure: this.props?.data?.pcnTirePressure,
			pcnMethod: this.props?.data?.pcnMethod,
			surface: this.props?.data?.surface,
			latitude: this.props?.data?.thresholdCoordinates?.latitude,
			longitude: this.props?.data?.thresholdCoordinates?.longitude,
			thresholdElevation: this.props?.data?.thresholdElevation,
			slope: this.props?.data?.slope,
			precision: this.props?.data?.precision,
			instrumental: this.props?.data?.instrumental,
			separate: this.props?.data?.separate,
			standardPattern: this.props?.data?.standardPattern,
			grooved: this.props?.data?.grooved,
			approachLightConfiguration:
				this.props?.data?.approachLightConfiguration,
			thresholdLights: this.props?.data?.thresholdLights,
			touchDownZoneLights: this.props?.data?.touchDownZoneLights,
			centerLights: this.props?.data?.centerLights,
			centerLightsSpacing: {
				value: this.props?.data?.centerLightsSpacing,
				trigger: () => this.handleSpacingChange(),
			},
			edgeLights: this.props?.data?.edgeLights,
			endLights: this.props?.data?.endLights,
			visualApproachSlopeIndicator:
				this.props?.data?.visualApproachSlopeIndicator,
		},
		(changed) => this.handleFormChange(changed)
	);

	public handleFormChange(changed) {
		this.props?.checkButtonState(changed);
		this.setState({ error: false });
	}

	public handleSpacingChange() {
		const value = this.form.getValue('centerLightsSpacing');

		if (value && this.props?.setSpacing) {
			this.props?.setSpacing(value);
		}
	}

	public componentDidMount() {
		if (!!this.props?.data?.centerLightsSpacing && this.props?.setSpacing) {
			this.props?.setSpacing(true);
		}
	}

	public render() {
		const wrapperClasses = arrayToClass([
			!!this.props.isTitle ? 'TitleWidth' : '',
			'position-relative mr-3',
		]);

		const buttonClasses = arrayToClass([
			this.props.isTitle ? 'AIPButtonWithTitle' : 'AIPButton',
			'display-flex justify-content-end position-absolute absolute-top-8 absolute-left-0',
		]);

		const formClasses = arrayToClass([
			this.state.error
				? 'border-1 palette--bc-red-2 border-radius-1'
				: '',
			'p-3',
			this.props.isTitle ? 'TitleWidth' : '',
		]);

		const classes = (ownCss) =>
			arrayToClass([
				ownCss,
				this.props.isTitle ? 'RunwayInputForTitle' : 'RunwayInput',
			]);

		return (
			<div className={wrapperClasses}>
				{this.props.removeRunway && (
					<div className={buttonClasses}>
						<HoverableButton
							onClick={() => this.props.removeRunway()}
							icon='close'
							colorType='transparent-green'
						/>
					</div>
				)}
				<form className={formClasses}>
					<div className='py-3'>
						{!!this.props.isTitle && (
							<h5 className='Spacing palette--c-neutral-5'>
								AD 2.12
							</h5>
						)}

						{!this.props.isTitle && (
							<div className='Spacing mb-2' />
						)}
						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('Designation')}
							{this.renderInput('identifier')}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('Helipad')}
							{this.renderSwitch('helipad')}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('TRUE BRG')}
							{this.renderInput('trueBearing', {
								type: 'number',
							})}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('MAGNETIC BRG')}
							{this.renderInput('magneticBearing', {
								type: 'number',
							})}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{!!this.props.isTitle && (
								<Label
									info={
										'Use only the second value of the AIP field, that is the width'
									}
									label='Dimensions (width)'
									className='Title mr-2'
									ignoreTopMargin={true}
								/>
							)}
							{this.renderMeasureInput('width', 'length')}
						</div>

						<div className='mb-2 display-flex'>
							<div className='py-2'>
								{this.renderTitle('Strength (PCN)')}
							</div>
							<div>
								{this.renderInput('pcnNumerical', {
									type: 'number',
								})}
								{this.renderSelect(
									'pcnType',
									formatOptions(runwayOptions.typeOpts)
								)}
								{this.renderSelect(
									'pcnStrength',
									formatOptions(runwayOptions.strengthOpts)
								)}
								{this.renderSelect(
									'pcnTirePressure',
									formatOptions(
										runwayOptions.tirePressureOpts
									)
								)}
								{this.renderSelect(
									'pcnMethod',
									formatOptions(runwayOptions.methodOpts)
								)}
							</div>
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('Surface')}
							{this.renderSelect(
								'surface',
								formatOptions(runwayOptions.surfaceOpts)
							)}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('Grooved')}
							{this.renderSwitch('grooved')}
						</div>

						<div className='mb-2 display-flex'>
							<div className='py-2'>
								{this.renderTitle('THR coordinates')}
							</div>
							<CoordinatesInput
								breakLines={true}
								lat={this.form.getField('latitude')}
								lng={this.form.getField('longitude')}
								classes={classes('py-2')}
								dms={this.props.isDMS}
								setDMS={(value) =>
									this.props.setDMS &&
									this.props.setDMS(value)
								}
							/>
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('THR elevation')}
							{this.renderMeasureInput(
								'thresholdElevation',
								'length'
							)}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('Slope')}
							<div
								className={classes(
									'display-flex align-items-end py-2'
								)}
							>
								<Input
									field={this.form.getField('slope')}
									inputType='number'
								/>
								<p className='palette--c-neutral-4 mb-0 ml-2'>
									%
								</p>
							</div>
						</div>
					</div>

					<div className='py-3'>
						{!!this.props.isTitle && (
							<h5 className='Spacing palette--c-neutral-5'>
								AD 2.13
							</h5>
						)}

						{!this.props.isTitle && (
							<div className='Spacing mb-2' />
						)}

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('TORA')}
							{this.renderMeasureInput('tora', 'length')}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('TODA')}
							{this.renderMeasureInput('toda', 'length')}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('ASDA')}
							{this.renderMeasureInput('asda', 'length')}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('LDA')}
							{this.renderMeasureInput('lda', 'length')}
						</div>
					</div>

					<div className='py-3'>
						{!!this.props.isTitle && (
							<h5 className='Spacing palette--c-neutral-5'>
								AD 2.14
							</h5>
						)}

						{!this.props.isTitle && (
							<div className='Spacing mb-2' />
						)}

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('APCH LGT type')}
							{this.renderSelect(
								'approachLightConfiguration',
								formatOptions(runwayOptions.alcOpts)
							)}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('THR LGT')}
							{this.renderSwitch('thresholdLights')}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('VASIS')}
							{this.renderSelect(
								'visualApproachSlopeIndicator',
								formatOptions(runwayOptions.vasiOpts)
							)}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('TDZ LGT')}
							{this.renderSwitch('touchDownZoneLights')}
						</div>

						<div className='mb-2 display-flex'>
							<div className='py-2'>
								{this.renderTitle('Centre Line LGT, Spacing')}
							</div>
							<div>
								{this.renderSwitch('centerLights')}
								{!!this.props.isSpacing &&
									this.renderSpacingInput()}
							</div>
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('Edge LGT')}
							{this.renderSwitch('edgeLights')}
						</div>

						<div className='mb-2 display-flex align-items-center'>
							{this.renderTitle('End LGT')}
							{this.renderSwitch('endLights')}
						</div>
					</div>

					<div className='mb-2 display-flex align-items-center'>
						{this.renderTitle('Instrumental')}
						{this.renderSwitch('instrumental')}
					</div>

					<div className='mb-2 display-flex align-items-center'>
						{this.renderTitle('Precision')}
						{this.renderSwitch('precision')}
					</div>

					<div className='mb-2 display-flex align-items-center'>
						{this.renderTitle('Separate')}
						{this.renderSwitch('separate')}
					</div>

					<div className='mb-2 display-flex align-items-center'>
						{this.renderTitle('Standard pattern')}
						{this.renderSwitch('standardPattern')}
					</div>
				</form>
			</div>
		);
	}

	public renderInput(field, extras = {}) {
		const classes = arrayToClass([
			'py-2',
			this.props.isTitle ? 'RunwayInputForTitle' : 'RunwayInput',
		]);

		return (
			<Input
				field={this.form.getField(field)}
				classes={classes}
				{...extras}
			/>
		);
	}

	public renderMeasureInput(field, type, extras = {}) {
		const classes = arrayToClass([
			'py-2',
			this.props.isTitle ? 'RunwayInputForTitle' : 'RunwayInput',
		]);

		return (
			<MeasurementInput
				type={type}
				field={this.form.getField(field)}
				classes={classes}
				{...extras}
			/>
		);
	}

	public renderSwitch(field) {
		const classes = arrayToClass([
			'py-2',
			this.props.isTitle ? 'RunwayInputForTitle' : 'RunwayInput',
		]);

		return (
			<div className={classes}>
				<SwitchSelect field={this.form.getField(field)} />
			</div>
		);
	}

	public renderSelect(field, options) {
		const classes = arrayToClass([
			'py-2',
			this.props.isTitle ? 'RunwayInputForTitle' : 'RunwayInput',
		]);

		return (
			<div className={classes}>
				<Select
					field={this.form.getField(field)}
					options={options || []}
					labelInfos={false}
				/>
			</div>
		);
	}

	public renderSpacingInput() {
		const value = this.form.getValue('centerLights');

		if (!!value) {
			return this.renderMeasureInput('centerLightsSpacing', 'length');
		} else {
			return <div className='Spacing my-2' />;
		}
	}

	public renderTitle(title) {
		if (!this.props.isTitle) {
			return null;
		}

		return <h5 className='Title mb-0 mr-2'>{title}</h5>;
	}

	public checkData(): any {
		const values: any = this.form.generateJSON();

		values.slope = values.slope
			? parseFloat((parseFloat(values.slope) / 100).toFixed(7))
			: values.slope === 0
			? 0
			: null;

		values.thresholdCoordinates =
			values.latitude && values.longitude
				? {
						latitude: values.latitude,
						longitude: values.longitude,
				  }
				: null;
		delete values.latitude;
		delete values.longitude;

		return values;
	}

	public async saveRunway(aid) {
		if (this.props.data) {
			return await this.update();
		} else {
			return await this.create(aid);
		}
	}

	public async update(): Promise<any> {
		const parsedRunway = await this.checkData();
		const originalRunway: any = get(this.props, 'data', {}) || {};
		const data = { ...originalRunway, ...parsedRunway };

		if (
			!(
				checkData(Object.keys(data), data, originalRunway, [
					'slope',
					'identifier',
				]) || []
			).length
		) {
			return false;
		}

		delete data.faa;

		try {
			const response = await RequestManager.put(
				`/runways/${get(this.props, 'data.aid')}`,
				data
			);

			if (!response) {
				throw new Error('Something went wrong');
			}

			return await this.onSuccess(response);
		} catch (err) {
			redirectToLogin(err);
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			this.setState({ error: true });
			return err;
		}
	}

	public async create(aid): Promise<any> {
		if (!aid) {
			toast.warn('There is no existing airport to add this runway for!', {
				theme: 'light',
			});
			return;
		}

		const data = await this.checkData();

		try {
			const response = await RequestManager.post('/runways', data, aid);

			if (!response) {
				throw new Error('Something went wrong');
			}

			return await this.onSuccess(response);
		} catch (err) {
			redirectToLogin(err);
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			this.setState({ error: true });
			return err;
		}
	}

	public async onSuccess(response) {
		if (
			(get(this.props.user, 'roles') || []).includes(
				'AUTO_MERGE_CONTRIBUTIONS'
			)
		) {
			response.contribution.changes = handleCoordinates(
				this.form.getDifferences(),
				'thresholdCoordinates',
				true
			);

			return await this.props.mergeContribution(
				response,
				'/runways/merge',
				'runways',
				() => this.handleSuccess()
			);
		} else {
			return this.handleSuccess();
		}
	}

	public handleSuccess() {
		return false;
	}
}

export const Runway: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(RunwayComponent);
