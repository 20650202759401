import * as React from 'react';
import { HoverableButton } from '../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Checkbox } from '../../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { Input } from '../../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../../ui-components/inputs/select/formatter';
import { SearchableSelect } from '../../../../ui-components/inputs/select/searchable-select';
import { Select } from '../../../../ui-components/inputs/select/select';
import { ValidationInput } from '../../../../ui-components/inputs/validation-input/validation-input';
import { Tooltip } from '../../../../ui-components/popover/popover';
import { ImagesContribution } from './image-contribution';

export const providerFields = (elmnt) => [
	{
		type: 'name',
		title: 'Name',
		component: (
			<TextInput
				field={elmnt.form.getField('name')}
				classes='NewData'
				textType='camel'
			/>
		),
	},
	{
		type: 'archived',
		title: 'Archived',
		component: (
			<Checkbox
				field={elmnt.form.getField('archived')}
				labelInfos={{
					label: 'Permanently Closed',
				}}
				classes='NewData'
			/>
		),
	},
	{
		type: 'website',
		title: 'Website',
		component: (
			<ValidationInput
				classes='NewData'
				type={'web'}
				field={elmnt.form.getField('website')}
			/>
		),
	},
	{
		type: 'phone',
		title: 'Phone',
		component: (
			<ValidationInput
				classes='NewData'
				type={'phone'}
				field={elmnt.form.getField('phone')}
			/>
		),
	},
	{
		type: 'email',
		title: 'Email',
		component: (
			<ValidationInput
				classes='NewData'
				type={'email'}
				field={elmnt.form.getField('email')}
			/>
		),
	},
	{
		type: 'category',
		title: 'Category',
		component: (
			<div className='NewData display-flex align-items-center'>
				<Select
					field={elmnt.form.getField('category')}
					options={formatOptions(
						PROVIDER_CATEGORY_OPTIONS.filter(
							(item) =>
								item?.value !== 'FUEL' &&
								item?.value !== 'HANDLING'
						),
						false
					)}
				/>
				<Tooltip
					tooltip={
						<div className='PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1'>
							<span className='palette--c-neutral-1'>
								Handling and Fuel category are disabled as they
								need additional properties which can't be added
								in the contribution panel.
							</span>
						</div>
					}
					trigger={
						<span className='ml-2 material-icons palette--c-primary-4'>
							info_outline
						</span>
					}
				/>
			</div>
		),
	},
	{
		type: 'outOfHoursPhone',
		title: 'Out Of Hours Phone',
		component: (
			<ValidationInput
				classes='NewData'
				type={'phone'}
				field={elmnt.form.getField('outOfHoursPhone')}
			/>
		),
	},
	{
		type: 'vhf',
		title: 'VHF',
		component: (
			<Input field={elmnt.form.getField('vhf')} classes='NewData' />
		),
	},
	{
		type: 'sita',
		title: 'SITA',
		component: (
			<ValidationInput
				classes='NewData'
				type={'sita'}
				field={elmnt.form.getField('sita')}
			/>
		),
	},
	{
		type: 'aftn',
		title: 'AFTN',
		component: (
			<ValidationInput
				classes='NewData'
				type={'aftn'}
				field={elmnt.form.getField('aftn')}
			/>
		),
	},
	{
		type: 'streetName',
		title: 'Street name',
		component: (
			<TextInput
				field={elmnt.form.getField('streetName')}
				classes='NewData'
				textType='camel'
			/>
		),
	},
	{
		type: 'locality',
		title: 'Locality',
		component: (
			<TextInput
				field={elmnt.form.getField('locality')}
				classes='NewData'
				textType='camel'
			/>
		),
	},
	{
		type: 'municipality',
		title: 'Municipality',
		component: (
			<TextInput
				field={elmnt.form.getField('municipality')}
				classes='NewData'
				textType='camel'
			/>
		),
	},
	{
		type: 'postalCode',
		title: 'Postal code',
		component: (
			<TextInput
				field={elmnt.form.getField('postalCode')}
				classes='NewData'
				textType='camel'
			/>
		),
	},
	{
		type: 'country',
		title: 'Country',
		component: (
			<SearchableSelect
				value='name'
				title='name'
				field={elmnt.form.getField('country')}
				classes='NewData'
				endpoint={'/free/countries/search'}
			/>
		),
	},
	{
		type: 'featuredOrder',
		title: 'Featured order',
		component: (
			<Input
				inputType='number'
				field={elmnt.form.getField('featuredOrder')}
				classes='NewData'
			/>
		),
	},
	{
		type: 'paymentMethods',
		title: 'Payment methods',
		component: (
			<Select
				isMulti={true}
				field={elmnt.form.getField('paymentMethods')}
				options={formatOptions(PAYMENT_METHOD_OPTIONS)}
				classes='NewData'
			/>
		),
	},
];

export const handlingFields = (elmnt) => [
	{
		type: 'accountsEmail',
		title: 'Accounts email',
		component: (
			<ValidationInput
				classes='NewData'
				type={'email'}
				field={elmnt.handling.getField('accountsEmail')}
			/>
		),
	},
	{
		type: 'accountsPhone',
		title: 'Accounts phone',
		component: (
			<ValidationInput
				classes='NewData'
				type={'phone'}
				field={elmnt.handling.getField('accountsPhone')}
			/>
		),
	},
	{
		type: 'fboBuildingPolygon',
		title: 'FBO Building Location',
		component: (
			<HoverableButton
				className='border-radius-1'
				colorType='avio-green'
				title='Add fbo building location'
				onClick={() =>
					elmnt.onClick('FBO Building Location', 'fboBuildingPolygon')
				}
			/>
		),
	},
	{
		type: 'aircraftParkingPolygon',
		title: 'Aircraft Parking Location',
		component: (
			<HoverableButton
				colorType='avio-green'
				className='border-radius-1'
				title='Add aircraft parking location'
				onClick={() =>
					elmnt.onClick(
						'Aircraft Parking Location',
						'aircraftParkingPolygon'
					)
				}
			/>
		),
	},
	{
		type: 'paxMeetingCoordinates',
		title: 'Pax meeting coordinates',
		component: (
			<HoverableButton
				colorType='avio-green'
				className='border-radius-1'
				title='Pax Meeting Coordinates'
				onClick={() =>
					elmnt.onClick(
						'Pax Meeting Coordinates',
						'paxMeetingCoordinates'
					)
				}
			/>
		),
	},
	{
		type: 'terminalServices',
		title: 'Terminal services',
		component: (
			<Select
				field={elmnt.handling.getField('terminalServices')}
				options={formatOptions(TERMINAL_SERVICE_OPTIONS)}
				classes='NewData'
			/>
		),
	},
	{
		type: 'aircraftServices',
		title: 'Aircraft services',
		component: (
			<Select
				field={elmnt.handling.getField('aircraftServices')}
				options={formatOptions(AIRCRAFT_SERVICE_OPTIONS)}
				classes='NewData'
			/>
		),
	},
	{
		type: 'serviceLevel',
		title: 'Level of service',
		component: (
			<Select
				field={elmnt.handling.getField('serviceLevel')}
				options={formatOptions(HANDLING_SERVICE_LEVEL)}
				classes='NewData'
			/>
		),
	},
];

export const fuelFields = (elmnt) => [
	{
		type: 'serviceLevel',
		title: 'Level of service',
		component: (
			<Select
				field={elmnt.fuel.getField('serviceLevel')}
				options={formatOptions(FUEL_SERVICE_LEVEL)}
				classes='NewData'
			/>
		),
	},
];

export const PROVIDER_CATEGORY_OPTIONS = [
	{
		title: 'Handling',
		value: 'HANDLING',
	},
	{
		title: 'Fuel',
		value: 'FUEL',
	},
	{
		title: 'Deicing',
		value: 'DEICING',
	},
	{
		title: 'Catering',
		value: 'CATERING',
	},
	{
		title: 'Private transfer',
		value: 'PRIVATE_TRANSFER',
	},
	{
		title: 'Taxi',
		value: 'TAXI',
	},
	{
		title: 'Car rental',
		value: 'CAR_RENTAL',
	},
	{
		title: 'Hotel',
		value: 'HOTEL',
	},
	{
		title: 'Restaurant',
		value: 'RESTAURANT',
	},
	{
		title: 'Cafeteria',
		value: 'CAFETERIA',
	},
	{
		title: 'Aircraft cleaning',
		value: 'AIRCRAFT_CLEANING',
	},
	{
		title: 'Laundry',
		value: 'LAUNDRY',
	},
	{
		title: 'Dish washing',
		value: 'DISH_WASHING',
	},
	{
		title: 'Maintenance',
		value: 'MAINTENANCE',
	},
	{
		title: 'Aircraft servicing',
		value: 'AIRCRAFT_SERVICING',
	},
	{
		title: 'Tax refund',
		value: 'TAX_REFUND',
	},
	{
		title: 'Supermarket',
		value: 'SUPERMARKET',
	},
	{
		title: 'Aircraft supplies',
		value: 'AIRCRAFT_SUPPLIES',
	},
];

export const PAYMENT_METHOD_OPTIONS = [
	{ title: 'AIRBP', value: 'AIRBP' },
	{ title: 'AMERICAN EXPRESS', value: 'AMERICAN_EXPRESS' },
	{ title: 'AVFUEL', value: 'AVFUEL' },
	{ title: 'BITCOIN', value: 'BITCOIN' },
	{ title: 'CASH', value: 'CASH' },
	{ title: 'COLT', value: 'COLT' },
	{ title: 'DINERS CLUB', value: 'DINERS_CLUB' },
	{ title: 'DISCOVER', value: 'DISCOVER' },
	{ title: 'INVOICE', value: 'INVOICE' },
	{ title: 'JCB', value: 'JCB' },
	{ title: 'MASTERCARD', value: 'MASTERCARD' },
	{ title: 'OTHER CARNET', value: 'OTHER_CARNET' },
	{ title: 'OTHER RELEASE', value: 'OTHER_RELASE' },
	{ title: 'SHELL', value: 'SHELL' },
	{ title: 'TOTAL', value: 'TOTAL' },
	{ title: 'UVAIR', value: 'UVAIR' },
	{ title: 'VISA', value: 'VISA' },
	{ title: 'WORLD FUEL SERVICES', value: 'WORLD_FUEL_SERVICES' },
];

export const AIRCRAFT_SERVICE_OPTIONS = [
	{
		title: 'AIR STARTER',
		value: 'AIR_STARTER',
	},
	{
		title: 'AIRCRAFT STAIRS',
		value: 'AIRCRAFT_STAIRS',
	},
	{
		title: 'AIRLINE CONTAINER LOADER',
		value: 'AIRLINE_CONTAINER_LOADER',
	},
	{
		title: 'CABIN COOLING HEATING CART',
		value: 'CABIN_COOLING_HEATING_CART',
	},
	{
		title: 'CHEMICAL TOILET SERVICE',
		value: 'CHEMICAL_TOILET_SERVICE',
	},
	{
		title: 'CREW LADDER',
		value: 'CREW_LADDER',
	},
	{
		title: 'DEDICATED PARKING',
		value: 'DEDICATED_PARKING',
	},
	{
		title: 'DEICING',
		value: 'DEICING',
	},
	{
		title: 'FORKLIFT',
		value: 'FORKLIFT',
	},
	{
		title: 'GPU 115V 440HZ',
		value: 'GPU_115V_440HZ',
	},
	{
		title: 'GPU 12V',
		value: 'GPU_12V',
	},
	{
		title: 'GPU 28V',
		value: 'GPU_28V',
	},
	{
		title: 'LUGGAGE BELT',
		value: 'LUGGAGE_BELT',
	},
	{
		title: 'LUGGAGE CARTS',
		value: 'LUGGAGE_CARTS',
	},
	{
		title: 'OXYGEN UPLOAD',
		value: 'OXYGEN_UPLOAD',
	},
	{
		title: 'POTABLE WATER',
		value: 'POTABLE_WATER',
	},
	{
		title: 'SELF MANEUVERING PARKING',
		value: 'SELF_MANEUVERING_PARKING',
	},
	{
		title: 'TKS FLUID',
		value: 'TKS_FLUID',
	},
	{
		title: 'TOW TRUCK',
		value: 'TOW_TRUCK',
	},
	{
		title: 'TYRE INFLATION',
		value: 'TYRE_INFLATION',
	},
	{
		title: 'VACUUM TOILET SERVICE',
		value: 'VACUUM_TOILET_SERVICE',
	},
	{
		title: 'WHEELCHAIR LOADER AIRLINER',
		value: 'WHEELCHAIR_LOADER_AIRLINER',
	},
	{
		title: 'WHEELCHAIR LOADER BIZJET',
		value: 'WHEELCHAIR_LOADER_BIZJET',
	},
];

export const TERMINAL_SERVICE_OPTIONS = [
	{
		title: 'AIRLINE MEET AND GREET',
		value: 'AIRLINE_MEET_AND_GREET',
	},
	{
		title: 'AIRSIDE BOARD TO BOARD',
		value: 'AIRSIDE_BOARD_TO_BOARD',
	},
	{
		title: 'CAR RENTAL',
		value: 'CAR_RENTAL',
	},
	{
		title: 'CATERING COLD STORAGE',
		value: 'CATERING_COLD_STORAGE',
	},

	{
		title: 'CHILDREN AREA',
		value: 'CHILDREN_AREA',
	},
	{
		title: 'COMPLIMENTARY REFRESHMENTS',
		value: 'COMPLIMENTARY_REFRESHMENTS',
	},
	{
		title: 'CONCIERGE SERVICES',
		value: 'CONCIERGE_SERVICES',
	},
	{
		title: 'CONFERENCE ROOMS',
		value: 'CONFERENCE_ROOMS',
	},
	{
		title: 'CREW CAR PARK',
		value: 'CREW_CAR_PARK',
	},
	{
		title: 'CREW COURTESY CAR',
		value: 'CREW_COURTESY_CAR',
	},
	{
		title: 'CREW SHOWERS',
		value: 'CREW_SHOWERS',
	},
	{
		title: 'CREW SNOOZE ROOM',
		value: 'CREW_SNOOZE_ROOM',
	},
	{
		title: 'CREW TRANSFER',
		value: 'CREW_TRANSFER',
	},
	{
		title: 'CUSTOMS ON SITE',
		value: 'CUSTOMS_ON_SITE',
	},
	{
		title: 'DEDICATED TERMINAL',
		value: 'DEDICATED_TERMINAL',
	},
	{
		title: 'LANDSIDE CONNECTION ASSISTANCE',
		value: 'LANDSIDE_CONNECTION_ASSISTANCE',
	},
	{
		title: 'LUGGAGE STORAGE',
		value: 'LUGGAGE_STORAGE',
	},
	{
		title: 'PASSENGER CAR PARK',
		value: 'PASSENGER_CAR_PARK',
	},
	{
		title: 'PASSENGER SHOWERS',
		value: 'PASSENGER_SHOWERS',
	},
	{
		title: 'PASSENGER SNOOZE ROOM',
		value: 'PASSENGER_SNOOZE_ROOM',
	},
	{
		title: 'RESTAURANTS ON SITE',
		value: 'RESTAURANTS_ON_SITE',
	},
	{
		title: 'SECURITY ON SITE',
		value: 'SECURITY_ON_SITE',
	},
	{
		title: 'SHOPS ON SITE',
		value: 'SHOPS_ON_SITE',
	},
	{
		title: 'TAX REFUND',
		value: 'TAX_REFUND',
	},
];

export const HANDLING_SERVICE_LEVEL = [
	{ title: 'Airline Handler', value: 'HANDLER' },
	{ title: 'GA/BA Handler / FBO', value: 'FBO' },
	{ title: 'Supervising Agent', value: 'SUPERVISION' },
	{ title: 'Trip Support', value: 'SUPPORT' },
];

export const FUEL_SERVICE_LEVEL = [
	{ title: 'Reseller', value: 'RESELLER' },
	{ title: 'Local', value: 'LOCAL' },
];

export const CURRENCIES = [
	{ value: 'AED' },
	{ value: 'AFN' },
	{ value: 'ALL' },
	{ value: 'AMD' },
	{ value: 'ANG' },
	{ value: 'AOA' },
	{ value: 'ARS' },
	{ value: 'AUD' },
	{ value: 'AWG' },
	{ value: 'AZN' },
	{ value: 'BAM' },
	{ value: 'BBD' },
	{ value: 'BDT' },
	{ value: 'BGN' },
	{ value: 'BHD' },
	{ value: 'BIF' },
	{ value: 'BMD' },
	{ value: 'BND' },
	{ value: 'BOB' },
	{ value: 'BOV' },
	{ value: 'BRL' },
	{ value: 'BSD' },
	{ value: 'BTC' },
	{ value: 'BTN' },
	{ value: 'BWP' },
	{ value: 'BYR' },
	{ value: 'BZD' },
	{ value: 'CAD' },
	{ value: 'CDF' },
	{ value: 'CHE' },
	{ value: 'CHF' },
	{ value: 'CHW' },
	{ value: 'CLF' },
	{ value: 'CLP' },
	{ value: 'CNY' },
	{ value: 'COP' },
	{ value: 'COU' },
	{ value: 'CRC' },
	{ value: 'CUC' },
	{ value: 'CUP' },
	{ value: 'CVE' },
	{ value: 'CZK' },
	{ value: 'DJF' },
	{ value: 'DKK' },
	{ value: 'DOP' },
	{ value: 'DZD' },
	{ value: 'EGP' },
	{ value: 'ERN' },
	{ value: 'ETB' },
	{ value: 'EUR' },
	{ value: 'FJD' },
	{ value: 'FKP' },
	{ value: 'GBP' },
	{ value: 'GEL' },
	{ value: 'GHS' },
	{ value: 'GIP' },
	{ value: 'GMD' },
	{ value: 'GNF' },
	{ value: 'GTQ' },
	{ value: 'GYD' },
	{ value: 'HKD' },
	{ value: 'HNL' },
	{ value: 'HRK' },
	{ value: 'HTG' },
	{ value: 'HUF' },
	{ value: 'IDR' },
	{ value: 'ILS' },
	{ value: 'INR' },
	{ value: 'IQD' },
	{ value: 'IRR' },
	{ value: 'ISK' },
	{ value: 'JMD' },
	{ value: 'JOD' },
	{ value: 'JPY' },
	{ value: 'KES' },
	{ value: 'KGS' },
	{ value: 'KHR' },
	{ value: 'KMF' },
	{ value: 'KPW' },
	{ value: 'KRW' },
	{ value: 'KWD' },
	{ value: 'KYD' },
	{ value: 'KZT' },
	{ value: 'LAK' },
	{ value: 'LBP' },
	{ value: 'LKR' },
	{ value: 'LRD' },
	{ value: 'LSL' },
	{ value: 'LTL' },
	{ value: 'LVL' },
	{ value: 'LYD' },
	{ value: 'MAD' },
	{ value: 'MGA' },
	{ value: 'MDL' },
	{ value: 'MKD' },
	{ value: 'MMK' },
	{ value: 'MNT' },
	{ value: 'MOP' },
	{ value: 'MRO' },
	{ value: 'MUR' },
	{ value: 'MVR' },
	{ value: 'MWK' },
	{ value: 'MXN' },
	{ value: 'MXV' },
	{ value: 'MYR' },
	{ value: 'MZN' },
	{ value: 'NAD' },
	{ value: 'NGN' },
	{ value: 'NIO' },
	{ value: 'NOK' },
	{ value: 'NPR' },
	{ value: 'NZD' },
	{ value: 'OMR' },
	{ value: 'PAB' },
	{ value: 'PEN' },
	{ value: 'PGK' },
	{ value: 'PHP' },
	{ value: 'PKR' },
	{ value: 'PLN' },
	{ value: 'PYG' },
	{ value: 'QAR' },
	{ value: 'RON' },
	{ value: 'RSD' },
	{ value: 'RUB' },
	{ value: 'RWF' },
	{ value: 'SAR' },
	{ value: 'SBD' },
	{ value: 'SCR' },
	{ value: 'SDG' },
	{ value: 'SEK' },
	{ value: 'SGD' },
	{ value: 'SHP' },
	{ value: 'SLL' },
	{ value: 'SOS' },
	{ value: 'SRD' },
	{ value: 'SSP' },
	{ value: 'STD' },
	{ value: 'SYP' },
	{ value: 'SZL' },
	{ value: 'THB' },
	{ value: 'TJS' },
	{ value: 'TMT' },
	{ value: 'TND' },
	{ value: 'TOP' },
	{ value: 'TRY' },
	{ value: 'TTD' },
	{ value: 'TWD' },
	{ value: 'TZS' },
	{ value: 'UAH' },
	{ value: 'UGX' },
	{ value: 'USD' },
	{ value: 'USN' },
	{ value: 'USS' },
	{ value: 'UYI' },
	{ value: 'UYU' },
	{ value: 'UZS' },
	{ value: 'VEF' },
	{ value: 'VND' },
	{ value: 'VUV' },
	{ value: 'WST' },
	{ value: 'XAF' },
	{ value: 'XAG' },
	{ value: 'XAU' },
	{ value: 'XBA' },
	{ value: 'XBB' },
	{ value: 'XBC' },
	{ value: 'XBD' },
	{ value: 'XCD' },
	{ value: 'XDR' },
	{ value: 'XFU' },
	{ value: 'XOF' },
	{ value: 'XPD' },
	{ value: 'XPF' },
	{ value: 'XPT' },
	{ value: 'XTS' },
	{ value: 'XXX' },
	{ value: 'YER' },
	{ value: 'ZAR' },
	{ value: 'ZMW' },
];
export const FUEL_PRODUCT_TYPES = [
	{ value: 'JET_A' },
	{ value: 'JET_A1' },
	{ value: 'JET_B' },
	{ value: 'TS1' },
	{ value: 'AVGAS_100_130' },
	{ value: 'AVGAS_100LL' },
	{ value: 'AVGAS_91_96UL' },
	{ value: 'JP5' },
	{ value: 'JP8' },
	{ value: 'MO_GAS' },
	{ value: 'DIESEL' },
];
export const PROVIDER_PRODUCT_UNITS = [
	{ value: 'LTR' },
	{ value: 'LBS' },
	{ value: 'KG' },
	{ value: 'USG' },
	{ value: 'IMP' },
	{ value: 'PIECE' },
	{ value: 'QRT' },
	{ value: 'PINT' },
	{ value: 'NIGHT' },
	{ value: 'H24' },
	{ value: 'HOUR' },
	{ value: 'MINUTE' },
	{ value: 'CALENDAR_DAY' },
	{ value: 'CALENDAR_MONTH' },
	{ value: 'METER' },
	{ value: 'SET' },
];
export const FUEL_PRODUCT_UNITS = [
	{ value: 'LTR' },
	{ value: 'USG' },
	{ value: 'IMP' },
];

export const imageFields = (elmnt: ImagesContribution) => [
	{
		type: 'description',
		title: 'Image',
		component: (
			<TextInput
				field={elmnt.form.getField('description')}
				classes='NewData pt-2'
				element='textarea'
				rows={3}
				textType='sentences'
			/>
		),
	},
];
