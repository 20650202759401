import { format } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { LocalstorageActions } from '../../actions/localstorage.actions';
import { HoverableButton } from '../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { connectNLP } from '../connect-navigate';
import { isInQuery } from '../helper-fuctions';
import './error-boundary.scss';

const logoWhite = require('../../assets/logo-white.png');

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
	setIdentifier: (identifier) =>
		dispatch(LocalstorageActions.setModalIdentifier(identifier)),
});
class ErrorBoundaryComponent extends React.Component<any, any> {
	public state = {
		hasError: false,
		error: false,
		errorInfo: false,
	};

	public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		this.setState({ hasError: true, error: error, errorInfo: errorInfo });
	}

	public render(): any {
		if (this.state.hasError) {
			return (
				<div className='ErrorScreen w-100 palette--bgc-neutral-2 p-2'>
					{this.renderLogo()}
					{this.renderErrorMessage()}
				</div>
			);
		}

		return this.props.children;
	}

	public renderErrorMessage(): React.ReactElement {
		const subject = `Error - ${format(new Date(), 'yyyy. MM. dd. HH:mm')}`;
		const body = this.generateEmailBody();

		return (
			<div className='display-flex flex-column align-items-center justify-content-center'>
				<h1 className='palette--c-neutral-1 mb-6'>
					Oops, something went wrong!
				</h1>
				<h3 className='palette--c-neutral-1 py-4'>
					It's not you, it's us.{' '}
				</h3>
				<h4 className='palette--c-neutral-1 pb-4'>
					Click the button below to report this bug to the team and we
					will have it fixed in no time!{' '}
				</h4>
				<HoverableButton
					colorType='cancel'
					titleLeft='Send bug report'
					icon='mail_outline'
					className='elevation-1'
					onClick={() =>
						(location.href = `mailto:support@aviowiki.com?subject=${subject}&body=${body}`)
					}
				/>
				<h4 className='palette--c-neutral-1 mt-2 py-4'>
					Or go back to search!
				</h4>
				<HoverableButton
					titleRight='Go back to search'
					icon='arrow_back'
					colorType='cancel'
					className='elevation-1'
					onClick={() => this.navigate('/')}
				/>
			</div>
		);
	}

	public renderLogo(): React.ReactElement {
		return (
			<div className='LandingLogoContainer w-100 display-block p-2 pt-4'>
				<div className='w-100 Logo display-flex justify-content-start'>
					<img src={logoWhite} alt='logo' className='display-block' />
				</div>
			</div>
		);
	}

	public generateEmailBody() {
		const { userAgent = '' } = window.navigator;

		const browser = userAgent.split(' ').find((line) => {
			if (line.includes('Edg')) {
				return line;
			} else if (line.includes('Chrome')) {
				return line;
			} else if (line.includes('Safari')) {
				return line;
			} else if (line.includes('Firefox')) {
				return line;
			}

			return;
		});

		const { href, hostname } = window.location;

		const os = (userAgent || '').split(/[(|)]/gi)[1];
		const platform = (hostname || '').includes('app')
			? 'PRODUCTION'
			: (hostname || '').includes('test')
			? 'TEST'
			: 'LOCALHOST';
		const date = format(new Date(), 'yyyy. MM. dd. HH:mm');
		const error = this.state?.error;
		const errorDetails = this.state?.errorInfo;
		const formattedErrorDetails = Object.keys(errorDetails).map((key) => {
			const data = errorDetails[key];

			return data.split(' in ').join('%0D');
		});

		return `Hi aviowiki team! %0D %0D I had an error on your site. %0D %0D The error I've got was: %0D ${error}.
        %0D %0D More details for you:
        %0D %0D OS: ${os} %0D Browser: ${browser} %0D Platform: ${platform} %0D Location: ${href} %0D Date: ${date} %0D %0D Error stack: ${formattedErrorDetails}.`;
	}

	public navigate(path) {
		this.props?.setIdentifier(null);
		const currentPath = this.props?.router?.location?.pathname;

		if (currentPath !== '/') {
			const utmSource = isInQuery('utm_source');
			this.props?.router?.navigate({ pathname: path, search: utmSource });
		}

		location.reload();
	}
}

export const ErrorBoundary: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(connectNLP(ErrorBoundaryComponent));
