import { LocalStorage } from '../utilities/local-storage';
import { AnyAction } from 'redux';
import { SettingsActions } from '../actions/settings.actions';

const initialState = {
	length: LocalStorage.getItem('mu-length') || { targetDimension: 'm' },
	mass: LocalStorage.getItem('mu-mass') || { targetDimension: 'kg' },
	temperature: LocalStorage.getItem('mu-temperature') || {
		targetDimension: 'C',
	},
};

export const measurementsReducer = (
	state: any = initialState,
	action: AnyAction
) => {
	if (action.type === SettingsActions.SET_LENGTH) {
		LocalStorage.setItem('mu-length', action.payload);

		state = { ...state, length: action.payload };
	}

	if (action.type === SettingsActions.SET_MASS) {
		LocalStorage.setItem('mu-mass', action.payload);

		state = { ...state, mass: action.payload };
	}

	if (action.type === SettingsActions.SET_TEMPERATURE) {
		LocalStorage.setItem('mu-temperature', action.payload);

		state = { ...state, temperature: action.payload };
	}

	return state;
};
