import { connect } from 'react-redux';
import L from 'leaflet';
import { get } from 'lodash';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker } from 'react-leaflet';
import { MapMarker } from '../../widgets/airport/map/map-marker/map-marker';
import { connectNLP } from '../../../utilities/connect-navigate';
import { isInQuery } from '../../../utilities/helper-fuctions';

const mapStateProps = (store: any) => ({
    mapNewTab: store.userDetails.mapNewTab,
});

const mapDispatchProps = (dispatch: any) => ({});

class NearbyMarkerComponent extends React.Component<any, any> {
    public render(): any {
        const airport = get(this.props, 'airport');

        const lng = get(airport, 'coordinates.longitude', null);
        const lat = get(airport, 'coordinates.latitude', null);
        let airportType = get(airport, 'icon');

        if (!airportType || airportType === 'OTHER') {
            airportType = 'CIVIL';
        }

        const marker = this.getIcon(airportType);

        if (!lng || !lat) {
            return null;
        }

        return (
            <Marker
                key={airport.aid}
                icon={marker}
                position={[lat, lng]}
                zIndexOffset={1}
                eventHandlers={{
                    click: () => this.props.clickable && this.handleOnMarkerClick(),
                }}
            />
        );
    }

    public handleOnMarkerClick() {
        const airport = get(this.props, 'airport');

        if (!this.props.mapNewTab) {
            this.props?.router?.navigate(`/airports/${get(airport, 'aid')}`);
        } else {
            const utmSource = isInQuery('utm_source') || false;
            const link = window.location.origin;
            window.open(`${link}/airports/${get(airport, 'aid')}${utmSource ? `?${utmSource}` : ''}`);
        }
    }

    public getIcon(i: string) {
        const icon = `../../../../../assets/pins/${this.props.isMain ? 'searched' : 'nearby'}/${i}.svg`;

        return L.divIcon({
            html: ReactDOMServer.renderToString(<MapMarker src={icon} />),
            className: 'leaflet-div-maps-marker nearby-helpers',
            iconAnchor: [20, 25],
        });
    }
}

export const NearbyMarker: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    connectNLP(NearbyMarkerComponent)
);
