import { Input } from '../../../../../../ui-components/inputs/inputs/input';
import { Select } from '../../../../../../ui-components/inputs/select/select';
import * as React from 'react';
import { TextInput } from '../../../../../../ui-components/inputs/inputs/text-input';
import { SwitchSelect } from '../../../../../../ui-components/inputs/select/switch-select';
import {
	CURRENCIES,
	PROVIDER_PRODUCT_UNITS,
} from '../../../provider-contribution/constants';
import { formatOptions } from '../../../../../../ui-components/inputs/select/formatter';

export const genericFields = (elmnt) => [
	{
		type: 'name',
		title: 'Name',
		component: (
			<TextInput
				field={elmnt.form.getField('name')}
				classes='NewData'
				textType='camel'
			/>
		),
	},
	{
		type: 'description',
		title: 'Description',
		component: (
			<TextInput
				field={elmnt.form.getField('description')}
				classes='NewData'
				textType='sentences'
			/>
		),
	},
	{
		type: 'unit',
		title: 'Unit',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('unit')}
				options={formatOptions(PROVIDER_PRODUCT_UNITS, false)}
			/>
		),
	},
	{
		type: 'currency',
		title: 'Currency',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('currency')}
				options={formatOptions(CURRENCIES, false)}
			/>
		),
	},
	{
		type: 'price',
		title: 'Price',
		component: (
			<Input
				inputType='number'
				field={elmnt.form.getField('price')}
				classes='NewData'
			/>
		),
	},
	{
		type: 'taxInclusive',
		title: 'Tax',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('taxInclusive')}
			/>
		),
	},
	{
		type: 'quantity',
		title: 'Quantity',
		component: (
			<Input field={elmnt.form.getField('quantity')} classes='NewData' />
		),
	},
];
