import { get } from 'lodash';
import * as React from 'react';
import { PaginationFooter } from '../../../../components/ui-components/pagination/pagination-footer/pagination-footer';
import { Spinner } from '../../../../components/ui-components/spinner/spinner';
import { ContributionHeader } from '../../../../components/widgets/shared/contributions/contribution-header/contribution-header';
import { arrayToClass, getQuery } from '../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../utilities/request';
import { ContributionModal } from './contribution-modal';

export class Contributions<P, S> extends React.Component<P & any, S & any> {
	public isMounted = false;

	public state = {
		total: this.props?.total || 0,
		response: false,
		error: false,
		loading: false,
		item: false,
	};

	public page = 0;

	public async componentDidMount(): Promise<any> {
		this.isMounted = true;

		if (!this.props.user) {
			this.props?.router?.navigate('/');
		} else if (!!this.props?.show) {
			await this.fetch();
		}
	}

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (this.props.show) {
			if (this.props.show !== prevProps.show) {
				this.fetch();
			}

			if (
				this.props?.router?.location?.search !==
				prevProps?.router?.location?.search
			) {
				this.fetch();
			}
		}
	}

	public componentWillUnmount() {
		this.isMounted = false;
	}

	public endpoint(): any {
		return '';
	}

	public async fetch(): Promise<any> {
		try {
			this.setStates({ loading: true });

			const endpoint = this.endpoint();
			const response = await RequestManager.get(endpoint, getQuery());

			if (!response) {
				throw new Error('There was an error');
			}

			const total = get(response, 'page.totalResults');

			if (this.props.onTotalChange) {
				this.props.onTotalChange(total);
			}

			this.setStates({ response: response.content, total: total });
		} catch (err) {
			this.setStates({ error: err });
		} finally {
			this.setStates({ loading: false });
		}
	}

	public setStates(states) {
		if (this.isMounted) {
			this.setState(states);
		}
	}

	public render(): any {
		if (!this.props.show) {
			return null;
		}

		return (
			<div className='Content position-relative w-100 palette--bgc-neutral-1 border-radius-1'>
				{this.state.loading && !this.state.error && (
					<Spinner size='large' />
				)}
				{!!this.state.response &&
					!this.state.error &&
					!this.state.loading &&
					this.renderContent()}
				{!this.state.loading && this.state.error && this.renderError()}
				<PaginationFooter total={get(this.state, 'total')} />
			</div>
		);
	}

	public renderError(): React.ReactElement {
		return (
			<div className='w-100 display-flex justify-content-center align-items-center'>
				<p className='p-6 palette--c-neutral-5'>
					There was an error. We are working to fix the issue and will
					be back soon!
				</p>
			</div>
		);
	}

	public renderEmpty(): React.ReactElement {
		return (
			<div className='w-100 display-flex justify-content-center align-items-center'>
				<p className='p-6 palette--c-neutral-5'>
					There is no contribution for now.
				</p>
			</div>
		);
	}

	public renderContent(): React.ReactElement {
		const response: any = this.state?.response || [];

		if (!response) {
			return this.renderEmpty();
		}

		return (
			<div className='w-100 Contribution'>
				<div className='w-100'>
					{response.map((el, index) => this.renderItem(el, index))}
				</div>
				{this.state?.item && (
					<ContributionModal
						modalClasses='ContributionModal'
						renderHeader={() => this.renderModalHeader()}
						renderBody={(ctx) => this.renderModalContent(ctx)}
						endpoint={this.getModalEndpoint()}
						item={this.state?.item}
						onClose={() => this.onModalClose()}
					/>
				)}
			</div>
		);
	}

	public renderModalHeader() {
		return (
			<ContributionHeader
				item={this.state.item}
				isTitle={true}
				type={this.getType()}
			/>
		);
	}

	public getModalEndpoint() {
		//
	}

	public renderModalContent(ctx): any {
		//
	}

	public onModalClose() {
		this.setState({ item: false });
	}

	public openModal(ev, item): any {
		if (ev.target.id === 'link') {
			return;
		}

		this.setState({ item: item });
	}

	public handleModalSuccess(): void {
		this.setState({ item: false });
		this.fetch();
	}

	public getType(): string {
		return 'airports';
	}

	public renderItem(item: any, index: number): React.ReactElement {
		const action = get(item, 'contribution.action');

		const className = arrayToClass([
			'Items',
			index % 2 === 1
				? 'palette--bgc-neutral-2'
				: 'palette--bgc-neutral-1',
			'py-2 px-1',
			'display-flex align-items-center',
		]);

		const markerboxClasses = arrayToClass([
			'MarkerBox border-radius-50',
			'mr-3',
			`palette--bgc-${
				action === 'CREATE'
					? 'primary-4'
					: action === 'UPDATE'
					? 'yellow-2'
					: 'red-2'
			}`,
		]);

		return (
			<div
				key={`item-${index}`}
				className={className}
				onClick={(event: any) => this.openModal(event, item)}
			>
				<div className={markerboxClasses} />
				<ContributionHeader
					item={item}
					isTitle={false}
					type={this.getType()}
				/>
			</div>
		);
	}
}
