import { LocalStorage } from '../utilities/local-storage';
import { AnyAction } from 'redux';
import { DetailsActions } from '../actions/details.actions';

const initialiState = {
    open: LocalStorage.getItem('airport-details-open'),
    openMobile: LocalStorage.getItem('airport-details-open-mobile'),
    currentNavigationPath: null,
    widgetSuccess: false,
};

export const detailsReducer = (state: any = initialiState, action: AnyAction) => {
    if (action.type === DetailsActions.TOGGLE_OPEN) {
        LocalStorage.setItem('airport-details-open', !state.open);

        state = { ...state, open: !state.open };
    }

    if (action.type === DetailsActions.SET_OPEN) {
        LocalStorage.setItem('airport-details-open', action.payload);

        state = { ...state, open: action.payload };
    }

    if (action.type === DetailsActions.TOGGLE_OPEN_MOBILE) {
        LocalStorage.setItem('airport-details-open-mobile', !state.openMobile);

        state = { ...state, openMobile: !state.openMobile };
    }

    if (action.type === DetailsActions.SET_OPEN_MOBILE) {
        LocalStorage.setItem('airport-details-open-mobile', action.payload);

        state = { ...state, openMobile: action.payload };
    }

    if (action.type === DetailsActions.SET_CURRENT_NAVIGATION_PATH) {
        state = {
            ...state,
            currentNavigationPath: action.payload,
        };
    }

    if (action.type === DetailsActions.SET_SUCCESS) {
        state = {
            ...state,
            widgetSuccess: action.payload,
        };
    }

    return state;
};
