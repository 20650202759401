import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastMessages } from './components/notifications/toast-messages';
import './index.scss';
import { reduxStore } from './reducers';
import { ContributionsScreen } from './screens/admin-screens/contributions/contributions.screen';
import { Plausible } from './screens/admin-screens/plausible/plausible.screen';
import { RegionsScreen } from './screens/admin-screens/regions/regions.screen';
import { TokenManagesScreen } from './screens/admin-screens/token-manages/token-manages.screen';
import { UserManagesScreen } from './screens/admin-screens/user-manages/user-manages.screen';
import { WebhooksScreen } from './screens/admin-screens/webhooks-management/webhooks-management';
import { DetailsScreen } from './screens/airport-details/details.screen';
import { CountryDetailsScreen } from './screens/country-details/country-details.screen';
import { ForgotPasswordFeedbackScreen } from './screens/forgot-password/forgot-password-feedback.screen';
import { ForgotPasswordScreen } from './screens/forgot-password/forgot-password.screen';
import { LandingScreen } from './screens/landing/landing.screen';
import { LoginScreen } from './screens/login/login.screen';
import { RegistrationScreen } from './screens/registration/registration.screen';
import { ReliefWingScreen } from './screens/relief-wing-app/relief-wing-screen';
import { ResetPasswordScreen } from './screens/reset-password/reset-password.screen';
import { SearchScreen } from './screens/search/search.screen';
import { UserProfile } from './screens/user-profile/user-profile';
import './scss/index.scss';
import { Env } from './utilities/environment';
import { ErrorBoundary } from './utilities/error-boundary/error-boundary';
import { AuthorizedRoute } from './utilities/routes/authorized.route';
import { PublicRoute } from './utilities/routes/public.route';
import { UnauthorizedRoute } from './utilities/routes/unauthorized.route';

const root$ = document.getElementById('application');
const store = reduxStore;

const renderApp = () => {
	ReactDOM.render(
		<Provider store={store} context={ReactReduxContext}>
			<BrowserRouter>
				<ErrorBoundary>
					<Routes>
						<Route
							path='/'
							element={
								<PublicRoute
									component={LandingScreen}
									config={null}
									identifier='landing'
								/>
							}
						/>

						<Route
							path='/countries'
							element={
								<PublicRoute
									component={LandingScreen}
									config={null}
									identifier='countriesLanding'
								/>
							}
						/>

						<Route
							path='/search'
							element={
								<PublicRoute
									component={SearchScreen}
									config={{ wide: true }}
									identifier='search'
								/>
							}
						/>

						<Route
							path='/user'
							element={
								<PublicRoute
									component={UserProfile}
									config={{ wide: true }}
									identifier='user'
								/>
							}
						>
							<Route
								path='/user/:userType'
								element={
									<PublicRoute
										component={UserProfile}
										config={{ wide: true }}
										identifier='user'
									/>
								}
							/>
						</Route>

						<Route
							path='/airports/:aid'
							element={
								<PublicRoute
									component={DetailsScreen}
									config={null}
									identifier='details'
								/>
							}
						>
							<Route
								path='/airports/:aid/:chldid'
								element={
									<PublicRoute
										component={DetailsScreen}
										config={null}
										identifier='details'
									/>
								}
							>
								<Route
									path='/airports/:aid/:chldid/:subId'
									element={
										<PublicRoute
											component={DetailsScreen}
											config={null}
											identifier='details'
										/>
									}
								>
									<Route
										path='/airports/:aid/:chldid/:subId/:subSubId'
										element={
											<PublicRoute
												component={DetailsScreen}
												config={null}
												identifier='details'
											/>
										}
									/>
								</Route>
							</Route>
						</Route>

						<Route
							path='/response'
							element={
								<PublicRoute
									component={ReliefWingScreen}
									config={null}
								/>
							}
						/>

						<Route
							path='/countries/:iso'
							element={
								<PublicRoute
									component={CountryDetailsScreen}
									config={null}
									identifier='countries'
								/>
							}
						>
							<Route
								path='/countries/:iso/:chldid'
								element={
									<PublicRoute
										component={CountryDetailsScreen}
										config={null}
										identifier='countries'
									/>
								}
							/>
						</Route>

						<Route
							path='/admin/contributions'
							element={
								<AuthorizedRoute
									component={ContributionsScreen}
									config={null}
									identifier='contributions'
								/>
							}
						>
							<Route
								path='/admin/contributions/:type'
								element={
									<AuthorizedRoute
										component={ContributionsScreen}
										config={null}
										identifier='contributions'
									/>
								}
							>
								<Route
									path='/admin/contributions/:type/:subType'
									element={
										<AuthorizedRoute
											component={ContributionsScreen}
											config={null}
											identifier='contributions'
										/>
									}
								>
									<Route
										path='/admin/contributions/:type/:subType/:subsubType'
										element={
											<AuthorizedRoute
												component={ContributionsScreen}
												config={null}
												identifier='contributions'
											/>
										}
									/>
								</Route>
							</Route>
						</Route>

						<Route
							path='/admin/system-contributions'
							element={
								<AuthorizedRoute
									component={ContributionsScreen}
									config={null}
									identifier='system-contributions'
								/>
							}
						>
							<Route
								path='/admin/system-contributions/:type'
								element={
									<AuthorizedRoute
										component={ContributionsScreen}
										config={null}
										identifier='system-contributions'
									/>
								}
							>
								<Route
									path='/admin/system-contributions/:type/:subType'
									element={
										<AuthorizedRoute
											component={ContributionsScreen}
											config={null}
											identifier='system-contributions'
										/>
									}
								>
									<Route
										path='/admin/system-contributions/:type/:subType/:subsubType'
										element={
											<AuthorizedRoute
												component={ContributionsScreen}
												config={null}
												identifier='system-contributions'
											/>
										}
									/>
								</Route>
							</Route>
						</Route>

						<Route
							path='/contributions'
							element={
								<AuthorizedRoute
									component={ContributionsScreen}
									config={null}
									identifier='contributions'
								/>
							}
						>
							<Route
								path='/contributions/:type'
								element={
									<AuthorizedRoute
										component={ContributionsScreen}
										config={null}
										identifier='contributions'
									/>
								}
							>
								<Route
									path='/contributions/:type/:subType'
									element={
										<AuthorizedRoute
											component={ContributionsScreen}
											config={null}
											identifier='contributions'
										/>
									}
								>
									<Route
										path='/contributions/:type/:subType/:subsubType'
										element={
											<AuthorizedRoute
												component={ContributionsScreen}
												config={null}
												identifier='contributions'
											/>
										}
									/>
								</Route>
							</Route>
						</Route>

						<Route
							path='/admin/users'
							element={
								<AuthorizedRoute
									component={UserManagesScreen}
									config={null}
									identifier='users'
								/>
							}
						>
							<Route
								path='/admin/users/:aid'
								element={
									<AuthorizedRoute
										component={UserManagesScreen}
										config={null}
										identifier='users'
									/>
								}
							/>
						</Route>

						<Route
							path='/admin/webhooks'
							element={
								<AuthorizedRoute
									component={WebhooksScreen}
									config={null}
									identifier='webhooks'
								/>
							}
						>
							<Route
								path='/admin/webhooks/:aid'
								element={
									<AuthorizedRoute
										component={WebhooksScreen}
										config={null}
										identifier='webhooks'
									/>
								}
							/>
						</Route>

						<Route
							path='/admin/tokens'
							element={
								<AuthorizedRoute
									component={TokenManagesScreen}
									config={null}
									identifier='tokens'
								/>
							}
						>
							<Route
								path='/admin/tokens/:aid'
								element={
									<AuthorizedRoute
										component={TokenManagesScreen}
										config={null}
										identifier='tokens'
									/>
								}
							>
								<Route
									path='/admin/tokens/:aid/:type'
									element={
										<AuthorizedRoute
											component={TokenManagesScreen}
											config={null}
											identifier='tokens'
										/>
									}
								/>
							</Route>
						</Route>

						{/* <AuthorizedRoute
                            component={DataEntryScreen}
                            path="/admin/data-entry"
                            exact={true}
                            config={null}
                            identifier="data-entry"
                        /> */}

						<Route
							path='/admin/regions'
							element={
								<AuthorizedRoute
									component={RegionsScreen}
									config={null}
									identifier='regions'
								/>
							}
						>
							<Route
								path='/admin/regions/:aid'
								element={
									<AuthorizedRoute
										component={RegionsScreen}
										config={null}
										identifier='regions'
									/>
								}
							/>
						</Route>

						<Route
							path='/admin/plausible'
							element={
								<AuthorizedRoute
									component={Plausible}
									config={null}
									identifier='plausible'
								/>
							}
						/>

						<Route
							path='/login'
							element={
								<UnauthorizedRoute
									component={LoginScreen}
									config={null}
								/>
							}
						/>

						<Route
							path='/registration'
							element={
								<UnauthorizedRoute
									component={RegistrationScreen}
									config={null}
								/>
							}
						/>

						<Route
							path='/forgot-password/feedback'
							element={
								<UnauthorizedRoute
									component={ForgotPasswordFeedbackScreen}
									config={null}
								/>
							}
						/>

						<Route
							path='/forgot-password'
							element={
								<UnauthorizedRoute
									component={ForgotPasswordScreen}
									config={null}
								/>
							}
						/>

						<Route
							path='/reset-password'
							element={
								<UnauthorizedRoute
									component={ResetPasswordScreen}
									config={null}
								/>
							}
						/>
					</Routes>
				</ErrorBoundary>
			</BrowserRouter>

			<ToastContainer
				position={toast.POSITION.BOTTOM_RIGHT}
				autoClose={7000}
				closeOnClick={true}
				pauseOnHover={true}
			/>
		</Provider>,
		root$
	);
};

const renderErrors = (err: any) => {
	return toast.error(<ToastMessages error={err} />, {
		position: toast.POSITION.BOTTOM_RIGHT,
		theme: 'light',
	});
};

Env.download()
	.then(() => renderApp())
	.catch((err) => renderErrors(err));
