import { get } from 'lodash';
import * as React from 'react';
import { RequestManager } from '../../../utilities/request';
import { Form } from '../../ui-components/form/form';
import { TextInput } from '../../ui-components/inputs/inputs/text-input';
import { Label } from '../../ui-components/inputs/label/label';
import { Spinner } from '../../ui-components/spinner/spinner';

const minHeight = 280;
const minWidth = 500;

export class ImageComponent extends React.Component<any, any> {
    public state = {
        hovered: false,
        loading: false,
        image: null,
        warning: false,
    };

    public form = new Form({
        description: this.props?.image?.contribution?.description || '',
    });

    public componentDidMount() {
        if (!get(this.props.image, 'file')) {
            this.fetchImage();
        }

        this.checkSizes();

        // this.subscriptions$.push(
        //     this.form.change$.pipe(debounceTime(600)).subscribe((value: any) => {
        //         const original = get(this.props.original, 'contribution');
        //         const aid = get(original, 'aid');

        //         if (!aid) {
        //             return;
        //         }

        //         if (original.description !== value.description) {
        //             this.props.setEdited(aid);
        //         } else {
        //             this.props.setEdited(aid, true);
        //         }
        //     })
        // )
    }

    public componentDidUpdate(prevProps) {
        if (get(this.props.image, 'contribution.aid') !== get(prevProps.image, 'contribution.aid')) {
            this.fetchImage();
            this.form.setValue('description', this.props?.image?.contribution?.description || '');
        }
    }

    public async fetchImage() {
        const aid = get(this.props.image, 'contribution.aid');

        if (!aid) {
            return;
        }

        this.setState({ loading: true });

        try {
            const image = await RequestManager.get(`/free/files/${aid}/info`);

            if (!image) {
                return;
            }

            this.setState({ loading: false, image: image });
        } catch (err) {
            this.setState({ loading: false });
        }
    }

    public render() {
        return (
            <div className="w-100">
                <div className="display-flex flex-column flex-sm-row align-items-center py-2">
                    {this.renderImage()}
                    <div className="InputContainer px-4 py-2 flex-fill">
                        {!this.props.original && (
                            <TextInput
                                renderLabel={() => (
                                    <Label
                                        label="Description"
                                        info="The image description will be visible on the listing."
                                    />
                                )}
                                field={this.form.getField('description')}
                                element="textarea"
                                rows={4}
                                textType="sentences"
                                labelInfos={{ label: 'Details' }}
                            />
                        )}
                        {!!this.props.original && <p>{this.form.getValue('description')}</p>}
                    </div>
                </div>
                {this.state.warning && this.renderWarning()}
            </div>
        );
    }

    public renderImage() {
        // const isImages = this.props.isImage;
        const id = this.props.imageId;
        const image =
            get(this.props, 'image.file.path') ||
            get(this.props, 'image.file.url') ||
            get(this.state.image, 'thumbnail');

        return (
            <div
                className={'Thumbnail border-radius-1 overflow-hidden position-relative'}
                key={id}
                onMouseEnter={() => this.setState({ hovered: id })}
                onMouseLeave={() => this.setState({ hovered: false })}
            >
                {(this.state.hovered as any) === id && !this.state.loading && (
                    <div className="Hovered h-100 w-100 position-absolute display-flex align-items-center justify-content-center">
                        {/* {!isImages && (
                            <label htmlFor={'logoUploadInput'} className="m-0 dispaly-flex align-items-center">
                                <span className="material-icons palette--c-neutral-5 pointer">edit</span>
                            </label>
                        )} */}
                        <span
                            onClick={() => this.handleDelete()}
                            className="material-icons palette--c-neutral-5 pointer"
                        >
                            delete
                        </span>
                    </div>
                )}
                {this.state.loading && (
                    <div className="Hovered h-100 w-100 position-absolute display-flex align-items-center justify-content-center">
                        <Spinner size="large" />
                    </div>
                )}
                <img src={image} alt={this.form.getValue('description') || 'provider-image'} />
            </div>
        );
    }

    public renderWarning() {
        return (
            <div className="w-100 display-flex align-items-center">
                <span className="material-icons palette--c-yellow-2">warning</span>
                <p className="palette--c-neutral-5 m-0 ml-2">
                    This image is too small. To achieve the best quality we recommend images larger than {minWidth}px x{' '}
                    {minHeight}px.
                </p>
            </div>
        );
    }

    public async handleDelete() {
        if (this.props.deleteImage) {
            this.props.deleteImage(this.props.isImage, this.props.image);
        }
    }

    public saveToProviders() {
        const form: any = this.form.generateJSON();
        const original = get(this.props, 'original');
        const current = get(this.props, 'image');

        const file = get(current, 'file');
        const name = !!original ? get(original, 'contribution.name') : get(current, 'file.name');

        const image = {
            contribution: {
                name: name,
                description: form.description,
            },
            file: file,
        };

        if (get(original, 'contribution.aid') === get(current, 'contribution.aid')) {
            image.contribution = {
                ...get(original, 'contribution'),
                ...image.contribution,
            };
        }

        return image;
    }

    public checkSizes() {
        if (this.props.isImages) {
            const path =
                get(this.props, 'image.file.path') || get(this.props, 'image.file.url') || get(this.state.image, 'url');
            const image = new Image();
            image.src = path;

            image.onload = () => {
                const height = image.height;
                const width = image.width;

                const isPortrait = height > width;

                const isSmall = isPortrait ? height < minHeight : width < minWidth;

                this.setState({ warning: isSmall });
            };
        }
    }
}
