import * as React from 'react';
import { arrayToClass, randomId } from '../../../utilities/helper-fuctions';
import './dropdown.scss';

export class Dropdown extends React.Component<any, any> {
    public state = {
        open: false,
    };

    public open() {
        this.setState({ open: true });
    }

    public close() {
        this.setState({ open: false });
    }

    public render() {
        const wrapperClasses = arrayToClass([
            'DropdownWrapper',
            this.state.open ? 'open' : '',
            this.props.className ? this.props.className : '',
        ]);

        const backdropClasses = arrayToClass([
            'Backdrop',
            this.props.backdropClasses ? this.props.backdropClasses : '',
        ]);

        return (
            <div className={wrapperClasses} id={this.props?.id || randomId()}>
                {!!this.state.open && !this.props.ignoreBackdrop && (
                    <div className={backdropClasses} onClick={() => this.handleBackdropClick()} />
                )}
                {this.renderTrigger()}
                {this.renderDropdown()}
            </div>
        );
    }

    public renderTrigger() {
        return (
            <div className="DropdownTrigger" onClick={() => this.handleTriggerClick()}>
                {this.props.trigger()}
            </div>
        );
    }

    public renderDropdown() {
        if (!this.state.open) {
            return null;
        }

        const dropdownMenuClasses = arrayToClass([
            'DropdownMenu',
            this.props.direction || 'right',
            this.props.ignoreMinWidth ? 'ignore-min-width' : '',
            this.props.ignoreMaxWidth ? 'ignore-max-width' : '',
        ]);

        return (
            <div className="DropdownContainer">
                <div className={dropdownMenuClasses}>{this.props.dropdown()}</div>
            </div>
        );
    }

    public handleTriggerClick() {
        if (this.props.ignoreTriggerClick) {
            return;
        }

        if (this.state.open) {
            this.close();
        } else {
            this.open();
        }
    }

    public handleBackdropClick() {
        if (this.props.onBackdropClick) {
            this.props.onBackdropClick();
        } else {
            this.close();
        }
    }
}
