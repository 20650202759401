import axios from 'axios';
import { addHours, format } from 'date-fns';
import { Env } from './environment';
import { LocalStorage } from './local-storage';

export class RequestManager {
	static getHeader = (config) => {
		const token = LocalStorage.getItem('AccessToken');
		const isAuthentication = !!config?.noHeader;
		let authentication = {};

		if (!!isAuthentication) {
			return authentication;
		}

		const fingerprint = this.genearteToken();
		authentication = { fingerprint: fingerprint };

		if (!!token) {
			authentication = {
				...authentication,
				Authorization: this.getAuthorizationHeader(),
			};
		}

		return {
			headers: { ...authentication, ...config?.headers },
		};
	};

	public static getAuthorizationHeader() {
		const tokenType = LocalStorage.getItem('AccessTokenType');
		const token = LocalStorage.getItem('AccessToken');

		return `${tokenType} ${token}`;
	}

	public static genearteToken() {
		const token = this.getToken();

		const today = new Date();
		const dateInUTC = addHours(today, today.getTimezoneOffset() / 60);
		const date = format(dateInUTC, 'ddMMHHmm').split('');

		const randomThirtyNineCharacter = date.reduce((acc, item, index) => {
			const newAcc = this.changeCharacter(item, index, acc);
			acc = newAcc;
			return newAcc;
		}, this.getRandomString(39));

		const fingerprint = `${token}-${randomThirtyNineCharacter}`;

		LocalStorage.setItem('fingerprint', fingerprint);
		return fingerprint;
	}

	public static getToken() {
		const userAid = LocalStorage.getItem('User')?.aid;

		if (!!userAid) {
			return `00${userAid.replace(/[-|USR]/gi, '')}`;
		}

		let token = LocalStorage.getItem('unauthorised-token');

		if (!token) {
			token = this.getRandomString(10);
			LocalStorage.setItem('unauthorised-token', token);
		}

		return token;
	}

	public static changeCharacter(string, index, replacable) {
		const magicArray = [1, 5, 12, 13, 20, 24, 34, 37];
		const magicIndex = magicArray[index];

		const list = replacable.split('');
		list[magicIndex] = string;
		return list.join('');
	}

	public static getRandomString(length) {
		const characters =
			'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

		return [...Array(length)]
			.map(() => {
				const randomNumber = Math.floor(
					Math.random() * (characters || '').length
				);
				return characters[randomNumber];
			})
			.join('');
	}

	public static parseUrl(path, url: any = false) {
		if (!!url) {
			return `${url}${path}`;
		}

		return `${Env.get('api')}${path}`;
	}

	static get = async (
		path: string,
		query?: any,
		config?: any,
		url?: string
	) =>
		await axios
			.get(this.parseUrl(path, url), {
				...this.getHeader(config),
				params: { ...query },
			})
			.then((response) => response.data);

	static post = async (
		path: string,
		data?: any,
		query?: any,
		config?: any,
		url?: string
	) =>
		await axios
			.post(
				this.parseUrl(path, url),
				{ ...data },
				{ ...this.getHeader(config), params: { ...query } }
			)
			.then((response) => response.data);

	static put = async (
		path: string,
		data?: any,
		query?: any,
		config?: any,
		url?: string
	) =>
		await axios
			.put(
				this.parseUrl(path, url),
				{ ...data },
				{ ...this.getHeader(config), params: { ...query } }
			)
			.then((response) => response.data);

	static delete = async (
		path: string,
		query?: any,
		config?: any,
		url?: string
	) =>
		await axios
			.delete(this.parseUrl(path, url), {
				...this.getHeader(config),
				params: { ...query },
			})
			.then((response) => response.data);

	static file = async (path: string, data: any, url?: string) =>
		await axios
			.put(this.parseUrl(path, url), data, {
				...this.getHeader({
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}),
			})
			.then((response) => response.data);
}
