import { connect } from 'react-redux';
import { format } from 'date-fns';
import * as React from 'react';
import { toast } from 'react-toastify';
import { LocalstorageActions } from '../../../../actions/localstorage.actions';
import { redirectToLogin } from '../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../utilities/request';
import { ToastMessages } from '../../../notifications/toast-messages';
import { StatusMarker } from '../../../ui-components/statusmarker/status-marker';
import { Widget } from '../../widget';
import { ReferenceAirports } from './reference-airports';
import './weather.scss';

const mapStateProps = (store: any) => ({
	temperature: store.measures.temperature,
	date: store.userDetails.date,
});

const mapDispatchProps = (dispatch: any) => ({
	setSunsetAndSunrise: (response) =>
		dispatch(LocalstorageActions.setSunsetAndSunrise(response)),
});

class WeatherComponent extends Widget<any, any> {
	public state: any = {
		...this.state,
		isOwn: true,
	};

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		if (this.props.date !== prevProps.date) {
			this.forceUpdate();
		}
	}

	public async fetch() {
		try {
			this.setState({ loading: true });

			await this.fetchWeather();
			await this.fetchSunset();
		} catch (err) {
			this.setState({ error: err });
			redirectToLogin(err);
		} finally {
			const { metar, taf, sunsets } = this.state?.response;
			const doesHaveData = metar?.raw || taf?.raw;

			if (!doesHaveData && !sunsets) {
				this.setState({ response: false, error: true, loading: false });
			} else {
				this.setState({ loading: false });
			}
		}
	}

	public async fetchWeather() {
		try {
			this.setState({ isOwn: true });

			const response = await RequestManager.get(
				`/airports/${this.props?.airport?.aid}/weather/full`,
				{
					fallback: 'NEAREST',
				}
			);

			if (!response) {
				return new Error();
			}

			return this.setState({
				response: {
					...this.state.response,
					metar: response.metar,
					taf: response.taf,
				},
			});
		} catch (err: any) {
			const { metar, taf } = err?.response?.data;

			if (err.response.status === 404) {
				return this.setState({
					response: {
						...this.state.response,
						metar,
						taf,
					},
				});
			}

			this.setState({
				error: {
					...this.state.error,
					metar: metar?.error,
					taf: taf.error,
				},
			});

			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}

	public async fetchSunset() {
		try {
			const response = await RequestManager.get(
				`/airports/${this.props?.airport?.aid}/sun`,
				{
					date: format(new Date(), 'yyyy-MM-dd'),
					local: true,
				}
			);

			if (!response) {
				return;
			}

			this.setState({
				response: { ...this.state.response, sunsets: response },
			});
			if (this.props.setSunsetAndSunrise) {
				this.props.setSunsetAndSunrise(response);
			}
		} catch (err: any) {
			this.setState({
				error: {
					...this.state.error,
					sunsets: err?.response?.data?.message || err || 'Error',
				},
			});
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}

	public renderError(): React.ReactElement {
		return (
			<div className='w-100 display-flex justify-content-center align-items-center'>
				<p className='palette--c-neutral-5 py-6'>
					METAR and TAF currently unavailable.
				</p>
			</div>
		);
	}

	public renderContent(): React.ReactElement {
		const {
			response: { metar, taf },
			error,
		} = this.state;
		const doesHaveData = metar?.raw || taf?.raw;

		if (!taf && !metar && !error?.metar && !error?.taf) {
			this.renderLoading();
		}

		return (
			<div className='WeatherWidget w-100 p-2'>
				{this.renderIsaDeviation()}
				{doesHaveData && this.renderWarning()}
				{this.renderWeather()}
				{this.renderSunset()}
			</div>
		);
	}

	public renderIsaDeviation(): any {
		const { metar } = this.state?.response;

		if (!metar || !metar.isaDeviation) {
			return null;
		}

		const { isaDeviation } = metar;

		return (
			<div className='AidContainer palette--bgc-secondary-1 display-inline-block p-1 px-2 mb-2 border-radius-1'>
				<p className='m-0 palette--c-secondary-4'>
					ISA deviation: {isaDeviation}
				</p>
			</div>
		);
	}

	public renderWarning(): any {
		const { metar, taf } = this.state?.response;
		const metarIcao = metar?.station;
		const tafIcao = taf?.station;
		const icao = this.props?.airport?.icao;
		const metarRef = metar?.reference;
		const tafRef = taf?.reference;

		if (
			(metarIcao !== icao && !!metarIcao) ||
			(tafIcao !== icao && !!tafIcao)
		) {
			return (
				<div className='WarningContainer w-100 palette--bgc-yellow-1 palette--bc-yellow-3 border-1 border-radius-1 mb-3 display-flex p-1 align-items-center'>
					<span className='WarningIcon material-icons palette--c-yellow-3 p-2'>
						report
					</span>
					<div>
						{metarIcao !== icao && !!metarIcao && (
							<ReferenceAirports
								aid={metarRef}
								type={
									metarRef !== tafRef
										? 'METAR'
										: 'METAR and TAF'
								}
								distance={metar?.distance}
							/>
						)}
						{tafIcao !== icao &&
							metarRef !== tafRef &&
							!!tafIcao && (
								<ReferenceAirports
									aid={tafRef}
									type='TAF'
									distance={taf?.distance}
								/>
							)}
					</div>
				</div>
			);
		}

		return null;
	}

	public renderWeather(): React.ReactElement {
		return (
			<div>
				{this.renderMetar()}
				{this.renderTaf()}
			</div>
		);
	}

	public renderFlightRule(flightRule: any): any {
		if (!flightRule) {
			return null;
		}

		if (flightRule === 'VFR' || flightRule === 'MVFR') {
			return (
				<StatusMarker
					tColor='green-3'
					bgColor='green-1'
					text={flightRule}
					type='badge'
					classNames='text-uppercast'
				/>
			);
		}

		if (flightRule === 'IFR' || flightRule === 'LIFR') {
			return (
				<StatusMarker
					tColor='yellow-3'
					bgColor='yellow-1'
					text={flightRule}
					type='badge'
					classNames='text-uppercast'
				/>
			);
		}

		return [
			<StatusMarker
				key={0}
				tColor='green-3'
				bgColor='green-1'
				text='VFR'
				type='badge'
				classNames='text-uppercast ml-2'
			/>,
			<StatusMarker
				key={1}
				tColor='yellow-3'
				bgColor='yellow-1'
				text='IFR'
				type='badge'
				classNames='text-uppercast'
			/>,
		];
	}

	public renderSunset(): React.ReactElement {
		const {
			error,
			response: {
				sunsets: { alwaysDown, alwaysUp, sunset, sunrise },
				timeZone = 'UTC',
			},
		} = this.state;

		if (alwaysUp || alwaysDown) {
			return (
				<div className='SunsetsContainer display-flex w-100 align-items-center mt-2'>
					{alwaysUp && (
						<div className='display-flex'>
							<span className='FAIcon fas fa-sun palette--c-neutral-5 mr-2' />
							<p className='palette--c-neutral-5 m-0 mr-1'>
								The sun never sets
							</p>
						</div>
					)}
					{alwaysDown && (
						<div className='display-flex align-items-center'>
							<span className='FAIcon fas fa-moon palette--c-neutral-5 mr-2' />
							<p className='palette--c-neutral-5 m-0 mr-1'>
								The sun never rises
							</p>
						</div>
					)}
				</div>
			);
		}

		let label = 'LT';

		if (!timeZone) {
			label = 'UTC';
		}

		const formatString = (this.props.date || '').match(/(hh:mm)( a|)/gi);
		const finalFormat = formatString ? formatString[0] : 'HH:mm';

		const formattedSunset = format(new Date(sunset), finalFormat);
		const formattedSunrise = format(new Date(sunrise), finalFormat);

		if (error?.sunsets) {
			return (
				<div className='display-flex align-items-center justify-content-center py-5'>
					<p className='palette--c-neutral-5'>{error?.sunsets}</p>
				</div>
			);
		}

		return (
			<div className='SunsetsContainer display-flex w-100 align-items-center'>
				<span className='FAIcon fas fa-sun palette--c-neutral-5 mr-2' />
				<p className='palette--c-neutral-5 m-0 mr-1'>
					{formattedSunrise}
				</p>
				{formattedSunset && (
					<p className='palette--c-neutral-5 m-0 mr-4'>{label}</p>
				)}
				<span className='FAIcon fas fa-moon palette--c-neutral-5 mr-2' />
				<p className='palette--c-neutral-5 m-0 mr-1'>
					{formattedSunset}
				</p>
				{formattedSunrise && (
					<p className='palette--c-neutral-5 m-0'>{label}</p>
				)}
			</div>
		);
	}

	private preprocessString(string) {
		if (!string) {
			return;
		}

		const newString = string
			.replace(/BECMG/g, ';BECMG')
			.replace(/(PROB[3|4]0 TEMPO| TEMPO|PROB[3|4]0)/g, ';$&')
			.replace(/FM\d{6}/g, ';$&');

		return newString.split(';');
	}

	private renderMetar() {
		const { response, error } = this.state;
		const metar = response?.metar?.raw;
		const metarError = error?.metar;

		console.log( this.state );
		console.log({ metarError });

		if (!metar || metarError) {
			return (
				<div className='w-100'>
					<h6 className='palette--c-neutral-5 mr-2'>METAR</h6>
					<p className='palette--c-neutral-5 mb-2'>
						Currently {this.props?.airport?.name} has no weather
						information.
					</p>
				</div>
			);
		}

		const metarArray: any = this.preprocessString(metar);

		return (
			<div className='w-100'>
				<div className='display-flex align-items-center my-3'>
					<h6 className='palette--c-neutral-5 text-uppercase mb-0 mr-2'>
						METAR
					</h6>
					<div className='display-flex align-items-start'>
						{this.renderFlightRule(response?.metar?.flightRules)}
					</div>
				</div>
				{metarArray.map((item: string, index: number) => {
					if (index === 0) {
						return (
							<p
								key={index}
								className='palette--c-neutral-6 text-uppercase'
							>
								{item}
							</p>
						);
					}

					return (
						<p
							key={index}
							className='palette--c-neutral-6 text-uppercase ml-4'
						>
							{item}
						</p>
					);
				})}
			</div>
		);
	}

	private renderTaf() {
		const { response, error } = this.state;
		const taf = response?.taf?.raw;
		const tafError = error?.taf;

		if (!taf || tafError) {
			return (
				<div className='w-100'>
					<h6 className='palette--c-neutral-5 mr-2 mb-3'>TAF</h6>
					<p className='palette--c-neutral-5'>
						Currently {this.props?.airport?.name} has no weather
						information.
					</p>
				</div>
			);
		}

		const tafArray: any = this.preprocessString(taf);

		return (
			<div className='w-100'>
				<h6 className='palette--c-neutral-5 text-uppercase mr-2 my-3'>
					TAF
				</h6>
				{tafArray.map((item: string, index: number) => {
					if (index === 0) {
						return (
							<p
								key={index}
								className='palette--c-neutral-6 text-uppercase'
							>
								{item}
							</p>
						);
					}

					return (
						<p
							key={index}
							className='palette--c-neutral-6 text-uppercase ml-4'
						>
							{item}
						</p>
					);
				})}
			</div>
		);
	}
}

export const Weather: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(WeatherComponent);
