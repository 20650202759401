import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { arrayToClass } from '../../../../utilities/helper-fuctions';
import { PreLoader } from '../../../../utilities/pre-loader';
import { providerInfos } from '../../../../utilities/widget-helpers/provider-infos';
import { request } from '../../../../utilities/widget-helpers/request';
import { ImageUploader } from '../../../image-management/image-uploader/image-uploader';
import { MapDrawing } from '../../../map/map-drawing/map-drawing';
import { ToastMessages } from '../../../notifications/toast-messages';
import { Form } from '../../../ui-components/form/form';
import { HoverableButton } from '../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Checkbox } from '../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { Input } from '../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../ui-components/inputs/inputs/text-input';
import { Label } from '../../../ui-components/inputs/label/label';
import { formatOptions } from '../../../ui-components/inputs/select/formatter';
import { SearchableSelect } from '../../../ui-components/inputs/select/searchable-select';
import { Select } from '../../../ui-components/inputs/select/select';
import { ValidationInput } from '../../../ui-components/inputs/validation-input/validation-input';
import { WidgetModal } from '../../widget-modal';
import {
	AIRCRAFT_SERVICE_OPTIONS,
	FUEL_SERVICE_LEVEL,
	HANDLING_SERVICE_LEVEL,
	PAYMENT_METHOD_OPTIONS,
	PROVIDER_CATEGORY_OPTIONS,
	TERMINAL_SERVICE_OPTIONS,
} from './provider-contribution/constants';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = () => ({});

class ProviderFormComponent extends WidgetModal<any, any> {
	public photoModal$: ImageUploader;

	public state: any = {
		...this.state,
		isHandling: this.props?.response?.category === 'HANDLING',
		isFuel: this.props?.response?.category === 'FUEL',
		disableForm: this.props?.response?.archived,

		title: null,
		field: null,
		marker: false,
		isMapOpen: false,

		imageAvailable: false,
		images: this.generateImages(),
		logo: this.generateLogo(),

		validationError: {},
	};

	public form: any = new Form(
		{
			name: { value: this.props?.response?.name, mandatory: true },
			category: {
				value: this.props?.response?.category,
				trigger: () => this.handleCategoryChange(),
				mandatory: true,
			},
			email: this.props?.response?.email,
			phone: this.props?.response?.phone,
			outOfHoursPhone: this.props?.response?.outOfHoursPhone,
			vhf: {
				value: this.props?.response?.vhf,
				trigger: () => this.handleVhfChange(),
			},
			website: this.props?.response?.website,
			sita: this.props?.response?.sita,
			aftn: this.props?.response?.aftn,
			streetName: this.props?.response?.streetName,
			locality: this.props?.response?.locality,
			municipality: this.props?.response?.municipality,
			/* governingDistrict: this.props?.response?.governingDistrict, */
			postalCode: this.props?.response?.postalCode,
			country: this.props?.response?.country,
			featuredOrder: this.props?.response?.featuredOrder,
			paymentMethods: this.props?.response?.paymentMethods,
			archived: {
				value: this.props?.response?.archived,
				trigger: () =>
					this.setState({
						disableForm: this.form.getValue('archived'),
					}),
			},
		},
		(changed) => this.handleFormChange(changed, 'form')
	);

	public handlingForm = new Form(
		{
			accountsPhone:
				this.props?.response?.handlingProvider?.accountsPhone,
			accountsEmail:
				this.props?.response?.handlingProvider?.accountsEmail,
			aircraftServices:
				this.props?.response?.handlingProvider?.aircraftServices,
			terminalServices:
				this.props?.response?.handlingProvider?.terminalServices,
			paxMeetingCoordinates:
				this.props?.response?.handlingProvider?.paxMeetingCoordinates,
			aircraftParkingPolygon:
				this.props?.response?.handlingProvider?.aircraftParkingPolygon,
			fboBuildingPolygon:
				this.props?.response?.handlingProvider?.fboBuildingPolygon,
			serviceLevel: this.props?.response?.handlingProvider?.serviceLevel,
		},
		(changed) => this.handleFormChange(changed, 'handling')
	);

	public fuelForm = new Form(
		{
			serviceLevel: {
				value: this.props?.response?.fuelProvider?.serviceLevel,
				trigger: () =>
					this.setState({
						fuelChanged: !!this.fuelForm.getValue('serviceLevel'),
					}),
				mandatory: true,
			},
		},
		(changed) => this.handleFormChange(changed, 'fuel')
	);

	public handleFormChange(changed, type) {
		const formValid = this.form.validateMandatory();
		const handlingValid = this.state?.isHandling
			? this.handlingForm.validateMandatory()
			: true;
		const fuelValid = this.state?.isFuel
			? this.fuelForm.validateMandatory()
			: true;
		const otherChanged = ['form', 'handling', 'fuel'].filter(
			(item) => item !== type && !!this.state?.[`${item}Changed`]
		);

		const buttonEnabled =
			formValid &&
			handlingValid &&
			fuelValid &&
			(changed || !!otherChanged?.length);

		this.setState({
			buttonDisabled: !buttonEnabled,
			[`${type}Changed`]: changed,
		});
	}

	public handleCategoryChange() {
		const category = this.form.getValue('category');

		this.setState({
			isHandling: category === 'HANDLING',
			isFuel: category === 'FUEL',
		});
	}

	public handleVhfChange() {
		const currentValue = this.form.getValue('vhf');

		if ((`${currentValue}` || '').includes(',')) {
			const newValue = currentValue.replace(',', '.');

			this.form.setValie('vhf', newValue);
		}
	}

	public generateImages() {
		const files = this.props?.response?.pictures;

		if (!files) {
			return [];
		}

		let images: any = [];
		for (let f of files) {
			images = [
				...images,
				{
					contribution: f,
					file: false,
				},
			];
		}

		return images;
	}

	public generateLogo() {
		const file = this.props?.response?.logo;

		if (!file) {
			return false;
		}

		const logo = {
			contribution: file,
			file: false,
		};

		return logo;
	}

	public getTitle() {
		return this.props?.isEdit ? 'Edit provider' : 'Create provider';
	}

	public getType() {
		return 'provider';
	}

	public renderForm(): any {
		const isAdmin = this.props?.user?.roles.includes('ADMIN');
		const buttonType = this.state?.disableForm ? 'disabled' : 'avio-green';

		return (
			<div className='ProviderForm'>
				<form>
					<div className='border-bottom-1 palette--bc-neutral-3 p-4'>
						<span className='palette--c-neutral-5 fw-bold text-uppercase'>
							General data
						</span>
						<TextInput
							field={this.form.getField('name')}
							classes='py-2'
							textType='camel'
							labelInfos={{
								label: 'Provider name',
							}}
							disabled={this.state?.disableForm}
						/>
						<div className='display-flex flex-column flex-md-row'>
							<Select
								field={this.form.getField('category')}
								options={formatOptions(
									PROVIDER_CATEGORY_OPTIONS,
									false
								)}
								classes='py-2 limit-w'
								labelInfos={{
									label: 'Category',
								}}
								disabled={this.state?.disableForm}
							/>
							{this.renderLevelOfService()}
						</div>
						<div className='display-flex flex-column flex-md-row'>
							{this.renderValidationInput('email', 'email', {
								label: 'Email',
							})}
							{this.renderValidationInput(
								'phone',
								'phone',
								{ label: 'Phone' },
								true
							)}
						</div>
						<div className='display-flex flex-column flex-md-row'>
							{this.renderValidationInput(
								'phone',
								'outOfHoursPhone',
								{ label: 'Out of hours' }
							)}
							<Input
								field={this.form.getField('vhf')}
								classes='py-2 limit-w left-m'
								inputType='number'
								labelInfos={{
									label: 'VHF',
									info: providerInfos.vhf,
								}}
								disabled={this.state?.disableForm}
							/>
						</div>
						{this.renderValidationInput('web', 'website', {
							label: 'Website',
						})}
					</div>
					<div className='border-bottom-1 palette--bc-neutral-3 p-3 mx-1'>
						<span className='palette--c-neutral-5 fw-bold text-uppercase'>
							Address options
						</span>
						<div className='display-flex flex-column flex-md-row'>
							{this.renderValidationInput('sita', 'sita', {
								label: 'SITA',
								info: providerInfos.sita,
							})}
							{this.renderValidationInput(
								'aftn',
								'aftn',
								{
									label: 'AFTN',
									info: providerInfos.aftn,
								},
								true
							)}
						</div>
						<TextInput
							field={this.form.getField('streetName')}
							classes='py-2 w-50'
							textType='camel'
							labelInfos={{
								label: 'Street name',
							}}
							disabled={this.state?.disableForm}
						/>
						<div className='display-flex flex-column flex-md-row'>
							<TextInput
								field={this.form.getField('locality')}
								classes='py-2'
								textType='camel'
								labelInfos={{
									label: 'Locality',
								}}
								disabled={this.state?.disableForm}
							/>
							<TextInput
								field={this.form.getField('municipality')}
								classes='py-2 left-m'
								textType='camel'
								labelInfos={{
									label: 'Municipality',
								}}
								disabled={this.state?.disableForm}
							/>
						</div>
						<Input
							field={this.form.getField('postalCode')}
							classes='py-2 limit-w'
							labelInfos={{
								label: 'Postal code',
							}}
							disabled={this.state?.disableForm}
						/>
						<SearchableSelect
							qeryValue='name'
							title='name'
							classes='py-2 limit-w'
							field={this.form.getField('country')}
							endpoint={'/free/countries/search'}
							labelInfos={{
								label: 'Country',
							}}
							disabled={this.state?.disableForm}
						/>
					</div>
					<div className='p-4'>
						<span className='palette--c-neutral-5 fw-bold text-uppercase'>
							Other options
						</span>
						{isAdmin && (
							<Input
								inputType='number'
								field={this.form.getField('featuredOrder')}
								classes='py-2 limit-w'
								labelInfos={{
									label: 'Feature order',
								}}
								disabled={this.state?.disableForm}
							/>
						)}
						<Select
							field={this.form.getField('paymentMethods')}
							isMulti={true}
							classes='py-2'
							options={formatOptions(PAYMENT_METHOD_OPTIONS)}
							labelInfos={{
								label: 'Payment methods',
							}}
							disabled={this.state?.disableForm}
						/>

						<div className='py-2'>
							<label>Images and logo</label>

							<div className='display-flex align-items-center'>
								<HoverableButton
									colorType={buttonType}
									className='border-radius-1'
									title='Select files'
									onClick={() => this.photoModal$.open()}
									disabled={this.state?.disableForm}
								/>
								{this.state.imageAvailable && (
									<span className='CheckMapValues material-icons palette--c-primary-4 ml-4'>
										check
									</span>
								)}

								<ImageUploader
									isEdit={this.props?.isEdit}
									images={this.state.images || false}
									logo={this.state.logo || false}
									ref={(ref: any) => (this.photoModal$ = ref)}
									imagesCheck={(status) =>
										this.setState({
											imageAvailable: status,
											buttonDisabled: !status,
										})
									}
									errorData={{
										airport:
											this.props?.response?.parent ||
											this.props?.aid,
										provider:
											this.props?.response?.aid || null,
									}}
								/>
							</div>
						</div>
					</div>
					{this.state.isHandling && (
						<div className='p-4 border-top-1 palette--bc-neutral-3'>
							<span className='palette--c-neutral-5 fw-bold text-uppercase'>
								Handling provider properties
							</span>
							<div className='display-flex flex-column flex-md-row'>
								{this.renderValidationInput(
									'email',
									'accountsEmail',
									{ label: 'Accounts email' },
									false,
									true
								)}
								{this.renderValidationInput(
									'phone',
									'accountsPhone',
									{ label: 'Accounts phone' },
									true,
									true
								)}
							</div>
							<div className='pb-2'>
								<Label
									info={
										providerInfos.handlingProvider
											.paxMeetingCoordinates
									}
									label='Passenger meeting point'
									className='mb-2'
								/>
								<div className='display-flex align-items-center'>
									<HoverableButton
										className='border-radius-1'
										colorType={buttonType}
										title='Add pax meeting coordinates'
										onClick={() =>
											this.onClick(
												'Pax meeting coordinates',
												'paxMeetingCoordinates',
												true
											)
										}
										disabled={this.state?.disableForm}
									/>
									{this.handlingForm?.getValue(
										'paxMeetingCoordinates'
									) && (
										<span className='CheckMapValues material-icons palette--c-primary-4 ml-4'>
											check
										</span>
									)}
								</div>
							</div>
							<div className='pb-2'>
								<Label
									info={
										providerInfos.handlingProvider
											.aircraftParkingPolygon
									}
									label='Aircraft parking location'
									className='mb-2'
								/>
								<div className='display-flex align-items-center'>
									<HoverableButton
										className='border-radius-1'
										colorType={buttonType}
										title='Add aircraft parking location'
										onClick={() =>
											this.onClick(
												'Aircraft parking location',
												'aircraftParkingPolygon',
												false
											)
										}
										disabled={this.state?.disableForm}
									/>
									{this.handlingForm?.getValue(
										'aircraftParkingPolygon'
									) && (
										<span className='CheckMapValues material-icons palette--c-primary-4 ml-4'>
											check
										</span>
									)}
								</div>
							</div>
							<div className='pb-2'>
								<Label
									info={
										providerInfos.handlingProvider
											.fboBuildingPolygon
									}
									label='Provider building location'
									className='mb-2'
								/>
								<div className='display-flex align-items-center'>
									<HoverableButton
										className='border-radius-1'
										colorType={buttonType}
										title='Add provider building location'
										onClick={() =>
											this.onClick(
												'Provider building location',
												'fboBuildingPolygon',
												false
											)
										}
										disabled={this.state?.disableForm}
									/>
									{this.handlingForm?.getValue(
										'fboBuildingPolygon'
									) && (
										<span className='CheckMapValues material-icons palette--c-primary-4 ml-4'>
											check
										</span>
									)}
								</div>
							</div>
							<Select
								isMulti={true}
								field={this.handlingForm.getField(
									'terminalServices'
								)}
								options={formatOptions(
									TERMINAL_SERVICE_OPTIONS
								)}
								classes='py-2'
								labelInfos={{
									label: 'Terminal services',
								}}
								disabled={this.state?.disableForm}
							/>
							<Select
								isMulti={true}
								field={this.handlingForm.getField(
									'aircraftServices'
								)}
								options={formatOptions(
									AIRCRAFT_SERVICE_OPTIONS
								)}
								classes='py-2'
								labelInfos={{
									label: 'Aircraft services',
								}}
								disabled={this.state?.disableForm}
							/>
						</div>
					)}
					<Checkbox
						field={this.form.getField('archived')}
						labelInfos={{
							label: 'Permanently closed',
						}}
						classes='py-2 px-4'
					/>
				</form>
				{this.state?.isMapOpen && (
					<MapDrawing
						isMarker={this.state.marker}
						title={this.state.title}
						field={this.getField()}
						polygon={
							!this.state.marker ? this.getField(true) : null
						}
						marker={this.state.marker ? this.getField(true) : null}
						endpoint={`/airports/${this.props?.response?.parent}`}
						isEdit={!!this.props?.response?.parent}
						onClose={() => this.setState({ isMapOpen: false })}
					/>
				)}
			</div>
		);
	}

	public renderLevelOfService() {
		const isHandling = this.state?.isHandling;
		const isFuel = this.state?.isFuel;

		if (!isFuel && !isHandling) {
			return null;
		}

		const form = isHandling ? this.handlingForm : this.fuelForm;
		const classes = arrayToClass([
			'limit-w py-2',
			isHandling || isFuel ? 'left-m' : '',
		]);
		const options = isHandling
			? HANDLING_SERVICE_LEVEL
			: FUEL_SERVICE_LEVEL;

		return (
			<Select
				field={form?.getField('serviceLevel')}
				options={formatOptions(options, isHandling)}
				classes={classes}
				labelInfos={{
					label: 'Level of service',
					info: isFuel
						? providerInfos.fuelProvider.serviceLevel
						: null,
				}}
				disabled={this.state?.disableForm}
			/>
		);
	}

	public renderValidationInput(
		type,
		name,
		label,
		isLeft = false,
		isHandling = false
	) {
		const form = isHandling ? this.handlingForm : this.form;
		const classes = arrayToClass([
			'py-2',
			isLeft ? 'left-m' : '',
			type === 'web' ? '' : 'limit-w',
		]);

		return (
			<ValidationInput
				classes={classes}
				type={type}
				field={form.getField(name)}
				onError={() => this.onInputError(name)}
				onSuccess={() => this.onInputSuccess(name)}
				renderLabel={
					label
						? () => <Label info={label.info} label={label.label} />
						: false
				}
				labelInfos={label}
				disabled={this.state?.disableForm}
			/>
		);
	}

	public onInputError(type) {
		this.setState({
			validationError: { ...this.state.validationError, [type]: true },
		});
	}

	public onInputSuccess(type) {
		this.setState({
			validationError: { ...this.state.validationError, [type]: false },
		});
	}

	public async onClick(title, field, marker) {
		await this.setState({
			title: title,
			field: field,
			marker: marker,
			isMapOpen: true,
		});
	}

	public getField(getValue: boolean = false) {
		if (!this.state.field) {
			return false;
		}

		if (getValue) {
			return this.handlingForm.getValue(this.state.field);
		}

		return this.handlingForm.getField(this.state.field);
	}

	public onClose(): void {
		this.form.clearForm();

		if (this.props.onClose) {
			this.props.onClose();
		}

		if (this.modal$) {
			this.modal$.close();
		}
	}

	public async onSave() {
		this.setState({ saveLoading: true, buttonDisabled: true });

		const provider = { ...this.form.generateJSON() };

		const handlingProvider = this.state?.isHandling
			? { ...this.handlingForm.generateJSON() }
			: null;
		provider.handlingProvider = handlingProvider;
		const fuelProvider = this.state?.isFuel
			? { ...this.fuelForm.generateJSON() }
			: null;
		provider.fuelProvider = fuelProvider;

		await this.finalSave(provider);
	}

	public async finalSave(data): Promise<void> {
		const isEdit = this.props?.isEdit;
		const aid = this.props?.aid;

		if (!aid) {
			this.setState({ saveLoading: false, buttonDisabled: false });
			return;
		}

		const files = await this.photoModal$.getProviderFileReferences();

		const body = { ...data, ...files };

		const req = request(
			'/providers/merge/withChildren',
			'provider',
			() => this.handleSuccessModal(),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		);

		if (isEdit) {
			await req.edit(`/providers/${aid}/withChildren`, body, (response) =>
				this.generateChanges(response, false)
			);
		} else {
			await req.create(
				'/providers',
				body,
				(response) => this.generateChanges(response, false),
				{
					parentAid: this.props?.aid,
				}
			);
		}

		this.setState({ saveLoading: false, buttonDisabled: false });
	}

	public async onDelete(): Promise<void> {
		this.setState({ saveLoading: true, buttonDisabled: true });

		const aid = this.props?.aid;

		if (!aid) {
			this.setState({ saveLoading: false, buttonDisabled: false });
			return;
		}

		request(
			'/providers/merge/withChildren',
			'provider',
			() => this.handleSuccessModal(true),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		).deleteF(`/providers/${aid}`, (resp) =>
			this.generateChanges(resp, true)
		);

		this.setState({ saveLoading: false, buttonDisabled: false });
	}

	public generateChanges(response, isDelete) {
		let mainChanges = isDelete
			? this.form.fetFieldsWhereHasValue()
			: this.form.getDifferences();

		if (this.state?.handlingChanged || !this.props?.isEdit) {
			if (this.state?.isHandling) {
				response.handlingProvider.contribution.changes = isDelete
					? this.handlingForm.getFieldsWhereHasValue()
					: this.handlingForm.getDifferences();
			}

			mainChanges = [...mainChanges, 'handlingProvider'];
		} else if (this.state?.fuelChanged || !this.props?.isEdit) {
			if (this.state?.isFuel) {
				response.fuelProvider.contribution.changes = isDelete
					? this.fuelForm.getFieldsWhereHasValue()
					: this.fuelForm.getDifferences();
			}
			mainChanges = [...mainChanges, 'fuelProvider'];
		}

		response.contribution.changes = mainChanges;

		return response;
	}

	public handleSuccessModal(isDelete = false) {
		if (!!(get(this.photoModal$.state, 'errorInImages') || []).length) {
			this.photoModal$.open();
			return;
		}

		this.form.clearForm();
		this.handlingForm.clearForm();

		if (this.notif$) {
			this.notif$.open(isDelete);
		}

		this.modal$.close();
	}
}

export const ProviderForm: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(PreLoader(ProviderFormComponent));
