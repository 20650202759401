import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../actions/details.actions';
import { ContributionFilter } from '../../../components/filters/contribution-filter';
import { Header } from '../../../components/header/header';
import { Spinner } from '../../../components/ui-components/spinner/spinner';
import { MainTab } from '../../../components/ui-components/tab/main-tab';
import { ManagesLayout } from '../../../layouts/manages/manages.layout';
import {
	arrayToClass,
	getQuery,
	replaceQuery,
} from '../../../utilities/helper-fuctions';
import { RequestManager } from '../../../utilities/request';
import { AirportContributions } from './contributions-by-type/airport-contributions';
import { AuthoritiesContributions } from './contributions-by-type/authorities/authorities-contributions';
import { AvailabilityContributions } from './contributions-by-type/availability-contributions/availability-contributions';
import { NotesContributions } from './contributions-by-type/notes-contributions/notes-contributions';
import { ProviderContributions } from './contributions-by-type/provider-contributions/provider-contributions';
import { RunwayContributions } from './contributions-by-type/runway-contributions';
import './contributions-list.screen.scss';

const tabData = [
	{
		title: 'Airports',
		type: 'airports',
		types: ['airport'],
	},
	{
		title: 'Authorities',
		type: 'authorities',
		types: [
			'airportAuthority',
			'airportAuthorityContact',
			'countryAuthority',
			'countryAuthorityContact',
		],
	},
	{
		title: 'Runways',
		type: 'runways',
		types: ['runway'],
	},
	{
		title: 'Operational notes',
		type: 'notes',
		types: ['airportOperationalNote', 'countryOperationalNote'],
	},
	{
		title: 'Availabilities',
		type: 'availabilities',
		types: [
			'movementAvailability',
			'atcavailability',
			'arffavailability',
			'ciqavailability',
		],
	},
	{
		title: 'Providers',
		type: 'providers',
		types: [
			'provider',
			'fuelProduct',
			'fuelCost',
			'providerProduct',
			'providerAvailability',
		],
	},
];

const mapStateProps = (store: any) => ({
	details: store.details,
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
	toggleMobile: () => dispatch(DetailsActions.toggleOpenMobile()),
});

class ContributionsScreenComponent extends React.Component<any, any> {
	public state = {
		systemUser: getQuery()?.systemUser || true,
		actions: getQuery()?.actions,
		loading: true,
		error: false,
	};

	constructor(props) {
		super(props);

		document.title = 'aviowiki';
	}

	public async componentDidMount(): Promise<void> {
		const roles = get(this.props.user, 'roles', []);

		if (!this.props.user) {
			this.props?.router?.navigate('/');
		}

		if (!roles.includes('ADMIN')) {
			this.props?.router?.navigate('/contributions');
		}

		this.fetchCounts();
	}

	public componentDidUpdate(prevProps, prevState) {
		if (this.state?.systemUser !== prevState.systemUser) {
			this.fetchCounts();
		}

		if (this.checkPathChange(prevProps)) {
			this.fetchCounts();
		}
	}

	public checkPathChange(prev) {
		let pathname = this.props?.router?.location?.pathname;
		let prevPathname = prev?.router?.location?.pathname;
		pathname = (pathname || '').split('/')[2];
		prevPathname = (prevPathname || '').split('/')[2];

		return pathname !== prevPathname;
	}

	public async fetchCounts() {
		this.setState({ loading: true });
		const isSystem = !!(
			this.props?.router?.location?.pathname || ''
		).includes('system');
		const user = this.props?.user;
		const isAdmin = !!(user?.roles || []).includes('ADMIN');
		const userAidIfNotAdmin = !isAdmin ? user?.aid : false;
		const systemUser =
			this.state?.systemUser !== true ? this.state?.systemUser : false;
		const userAid = isSystem && systemUser ? systemUser : userAidIfNotAdmin;

		try {
			const response = await RequestManager.get(
				`/${
					userAid ? `users/${userAid}` : 'management'
				}/contributions/count/${
					isSystem && !userAid ? 'system' : ''
				}?status=PENDING`
			);

			if (!response) {
				throw new Error('Bad request');
			}

			const totals: any = await this.calculate(response);
			this.setState({ ...totals, ...response, loading: false });
		} catch (err) {
			this.setState({ error: err });
		}
	}

	public calculate(response) {
		let totalStates = {};
		for (let tab of tabData) {
			let counter = 0;

			for (let type of tab?.types || []) {
				counter = counter + response?.[type];
			}

			totalStates = { ...totalStates, [tab.type]: counter };
		}

		return totalStates;
	}

	public render(): React.ReactElement {
		const isSystem = !!(
			this.props?.router?.location?.pathname || ''
		).includes('system');
		const isAdmin = !!(get(this.props.user, 'roles', []) || []).includes(
			'ADMIN'
		);
		const type = this.props?.router?.params?.type || false;
		const subType = this.props?.router?.params?.subType || false;
		const subsubType = this.props?.router?.params?.subsubType || false;

		const classes = arrayToClass([
			'ContributionListScreen display-flex flex-column Paddings',
		]);

		const totals: any = this.state;
		const tabs = tabData.map((tab: any) => ({
			...tab,
			total: this.state?.[tab.type],
		}));

		return (
			<div className={classes}>
				<Header
					title={
						isSystem
							? 'Manage system contributions'
							: isAdmin
							? 'Manage contributions'
							: 'My pending contributions'
					}
				/>

				{!!isSystem && (
					<ContributionFilter
						handleFilter={(value) => this.handleFilterChange(value)}
					/>
				)}

				{this.state?.loading && <Spinner size='large' />}

				{!this.state?.loading && (
					<div className='ContentContainer my-4 palette--bgc-neutral-1 border-radius-1'>
						<MainTab
							items={tabs}
							onItemClick={(item) =>
								this.handleMenuClick(item, isSystem)
							}
							selected={type}
						/>

						<AirportContributions
							show={type === 'airports' || !type}
							onTotalChange={(total) =>
								this.setTotals('airports', total)
							}
							total={totals.airport}
							isAdmin={isAdmin}
							system={isSystem ? this.state.systemUser : false}
						/>
						<AuthoritiesContributions
							show={type === 'authorities'}
							subTypes={{
								type: subType,
								authorities: subsubType,
							}}
							total={{
								airportAuthority: totals.airportAuthority,
								airportAuthorityContact:
									totals.airportAuthorityContact,
								countryAuthority: totals?.countryAuthority,
								countryAuthorityContact:
									totals?.countryAuthorityContact,
							}}
							onTotalChange={(total) =>
								this.setTotals('authorities', total)
							}
							isAdmin={isAdmin}
							system={isSystem ? this.state.systemUser : false}
						/>
						<RunwayContributions
							show={type === 'runways'}
							onTotalChange={(total) =>
								this.setTotals('runways', total)
							}
							total={totals.runway}
							isAdmin={isAdmin}
							system={isSystem ? this.state.systemUser : false}
						/>
						<NotesContributions
							show={type === 'notes'}
							subType={subType}
							onTotalChange={(total) =>
								this.setTotals('notes', total)
							}
							total={{
								airportoperationalnotes:
									totals?.airportOperationalNote,
								countryoperationalnotes:
									totals?.countryOperationalNote,
							}}
							isAdmin={isAdmin}
							system={isSystem ? this.state.systemUser : false}
						/>
						<AvailabilityContributions
							show={type === 'availabilities'}
							subType={subType}
							onTotalChange={(total) =>
								this.setTotals('availabilities', total)
							}
							total={{
								arff: totals?.arffavailability,
								atc: totals?.atcavailability,
								ciq: totals?.ciqavailability,
								movement: totals?.movementAvailability,
							}}
							isAdmin={isAdmin}
							system={isSystem ? this.state.systemUser : false}
						/>
						<ProviderContributions
							show={type === 'providers'}
							subTypes={{ provider: subType, fuel: subsubType }}
							onTotalChange={(total) =>
								this.setTotals('providers', total)
							}
							total={{
								provider: totals?.provider,
								fuels: {
									fuelCost: totals?.fuelCost,
									fuelProduct: totals?.fuelProduct,
								},
								availabilities: totals?.providerAvailability,
								products: totals?.providerProduct,
							}}
							isAdmin={isAdmin}
							system={isSystem ? this.state.systemUser : false}
						/>
					</div>
				)}

				{this.renderMarks()}
			</div>
		);
	}

	public handleFilterChange(value) {
		const systemUser = value?.systemUser;
		const actions = value?.actions;

		if (this.state.systemUser !== systemUser) {
			this.setState({ systemUser: systemUser });
		}

		if (this.state?.actions !== actions) {
			this.setState({ actions: actions });
		}

		const newQuery = replaceQuery(value);
		this.props?.router?.navigate({ search: newQuery });
		// this.fetchCounts();
	}

	public handleHeaderLogoClick() {
		const isAdmin = !!(get(this.props.user, 'roles', []) || []).includes(
			'ADMIN'
		);
		if (isAdmin && this.props.toggleMobile) {
			this.props.toggleMobile();
		} else {
			this.props?.router?.navigate('/');
		}
	}

	public handleMenuClick(item, isSystem) {
		const isAdmin = !!(get(this.props.user, 'roles', []) || []).includes(
			'ADMIN'
		);
		if (isAdmin) {
			this.props?.router?.navigate({
				pathname: `/admin/${isSystem ? 'system-' : ''}contributions/${
					item.type
				}`,
				search: isSystem
					? `actions=${this.state?.actions}&systemUser=${this.state?.systemUser}`
					: '',
			});
		} else {
			this.props?.router?.navigate(`/contributions/${item.type}`);
		}
	}

	public setTotals(field: string, total: number): void {
		this.setState({ [field]: total });
	}

	private renderMarks(): React.ReactElement {
		return (
			<div className='display-flex my-2 align-items-center'>
				<div className='LegendBox border-radius-50 p-1 palette--bgc-yellow-2' />
				<p className='m-0 neutral-6 mr-4 ml-2'>Edit</p>
				<div className='LegendBox border-radius-50 p-1 palette--bgc-red-2' />
				<p className='m-0 neutral-6 mr-4 ml-2'>Delete</p>
				<div className='LegendBox border-radius-50 p-1 palette--bgc-primary-4' />
				<p className='m-0 neutral-6 ml-2'>Create</p>
			</div>
		);
	}
}

export const ContributionsScreen: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(ManagesLayout(ContributionsScreenComponent));
