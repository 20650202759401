import * as React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ToastMessages } from '../components/notifications/toast-messages';
import { Spinner } from '../components/ui-components/spinner/spinner';
import { redirectToLogin } from './helper-fuctions';
import { RequestManager } from './request';

export const PreLoader = (Component) => (props: any) => {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();

	const [error, setError] = React.useState(false);
	const [response, setResponse] = React.useState(false);

	React.useEffect(() => {
		if (!!props.isEdit && !props.data) {
			RequestManager.get(props.endpoint)
				.then((resp) => {
					setResponse(resp);
					setError(false);
				})
				.catch((err) => {
					setResponse(false);
					setError(err);
					toast.error(<ToastMessages error={err} />, {
						theme: 'light',
					});
					redirectToLogin(err);
				});
		} else {
			setResponse(true);
			setError(false);
		}
	}, []);

	return (
		<div>
			{!error && !response && <Spinner size='large' />}
			{(!!error || !!response) && (
				<Component
					{...props}
					ref={props.innerRef}
					error={error}
					response={response}
					onRefresh={() => setResponse(false)}
					router={{ navigate, location, params }}
				/>
			)}
		</div>
	);
};
