import * as React from 'react';
import { Select } from './select';

export class SwitchSelect extends React.Component<any, any> {
    public options = [
        { title: 'Yes', value: true },
        { title: 'No', value: false },
        { title: 'Not Specified', value: null },
    ];

    public render(): React.ReactElement {
        return <Select {...this.props} options={this.options} />;
    }
}
