import * as React from 'react';
import { get } from 'lodash';
import { ThumbnailsPresenter } from './thumbnail-presenter';
import './picture-styles.scss';
import { Spinner } from '../../ui-components/spinner/spinner';

interface PostPicturePresenterProps {
    pictures: any;
    className?: string;
}

export class PicturePresenter extends React.Component<PostPicturePresenterProps, any> {
    public state = {
        current: get(this.props, 'pictures.[0]'),
        hovered: false,
        loading: false,
    };

    public render() {
        const current = get(this.state, 'current');
        const pictures = get(this.props, 'pictures');
        const description = get(this.state.current, 'description');

        const isThumbnails = (pictures || []).length > 1;

        return (
            <div className="ImagesPresenter w-100">
                <div
                    className="MainImageContainer"
                    onMouseEnter={() => this.setState({ hovered: true })}
                    onMouseLeave={() => this.setState({ hovered: false })}
                >
                    <img src={current.url} className="MainImage" alt={description} onLoad={({ target: img }) => this.resizeImage(img)} />
                    {description && !!this.state.hovered && (
                        <div className="DescriptionContainer">
                            <div className="Description">
                                <p className="palette--c-neutral-1">{description}</p>
                            </div>
                        </div>
                    )}
                    {!!this.state.loading && (
                        <div className="LoadingContainer">
                            <Spinner size="large" />
                        </div>
                    )}
                </div>
                {isThumbnails && (
                    <div className="position-relative">
                        <ThumbnailsPresenter
                            select={(image) => this.handleSelect(image)}
                            selected={current}
                            pictures={pictures}
                        />
                    </div>
                )}
            </div>
        );
    }

    public handleSelect(image) {
        if (get(image, 'aid') !== get(this.state.current, 'aid')) {
            this.setState({ current: image, loading: true });
        }
    }

    public resizeImage(img) {
        const isPortrait = img.height < img.width;

        img.id = isPortrait ? 'Landscape' : 'Portrait';

        this.setState({ loading: false });
    }
}
