import { format, isAfter, isSameDay } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../../../actions/details.actions';
import { UserActions } from '../../../../actions/user.actions';
import { arrayToClass, toUtc } from '../../../../utilities/helper-fuctions';
import { PreLoader } from '../../../../utilities/pre-loader';
import { RequestManager } from '../../../../utilities/request';
import { request } from '../../../../utilities/widget-helpers/request';
import { ToastMessages } from '../../../notifications/toast-messages';
import { Form } from '../../../ui-components/form/form';
import { Checkbox } from '../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { DateTimePicker } from '../../../ui-components/inputs/date-picker/date-time-picker';
import { TextInput } from '../../../ui-components/inputs/inputs/text-input';
import { Select } from '../../../ui-components/inputs/select/select';
import { Tooltip } from '../../../ui-components/popover/popover';
import { WidgetModal } from '../../widget-modal';
import {
	category,
	countryCategory,
	criticalityOpts,
} from './operational-notes-contribution/operational-notes-contribution-helpers';

const mapStateProps = (store: any) => ({
	user: store.user.user,
	format: store.userDetails.date,
});

const mapDispatchProps = (dispatch: any) => ({
	tryRedirectIfNotAuthenticated: () =>
		dispatch(UserActions.tryRedirectIfNotAuthenticated()),
	mergeContribution: (data, endpoint, widgetId, callback) =>
		dispatch(
			DetailsActions.mergeContribution(data, endpoint, widgetId, callback)
		),
});

class OperationalNotesFormComponent extends WidgetModal<any, any> {
	public date = React.createRef();

	public state: any = {
		...this.state,
		errorInDate: false,
		focused: false,
		isCountry: false,
		country: false,
	};

	public form = new Form(
		{
			validFrom: {
				value: this.props?.response?.validFrom
					? new Date(this.props?.response?.validFrom)
					: '',
				trigger: () => this.checkDates(),
				mandatory: true,
			},
			validTo: {
				value: this.props?.response?.validTo
					? new Date(this.props?.response?.validTo)
					: '',
				trigger: () => this.checkDates(true),
				mandatory: true,
			},
			criticality: {
				value: this.props?.response?.criticality || '',
				mandatory: true,
			},
			category: {
				value: this.props?.response?.category || '',
				mandatory: true,
			},
			source: {
				value: this.props?.response?.source || '',
				mandatory: true,
			},
			notes: {
				value: this.props?.response?.notes || '',
				mandatory: true,
			},
		},
		(changed) => this.setState({ buttonDisabled: !changed })
	);

	public helperForm = new Form({
		permanent: {
			value: isSameDay(
				new Date(this.props?.response?.validTo),
				new Date('2099.12.31. 23:59:59')
			),
			trigger: () => this.togglePermanent(),
		},
		isRelevantToCountry: {
			value: false,
			trigger: () => this.setState({ isCountry: !this.state?.isCountry }),
		},
	});

	public checkDates(isTo = false) {
		const field = (reverse = false) => (isTo && !reverse ? 'To' : 'From');
		const firstField = field();
		const secondField = field(true);
		const value = this.form.getValue(`valid${firstField}`);
		const otherValue = this.form.getValue(`valid${secondField}`);

		if (value) {
			const isAfterTime: boolean = isAfter(
				new Date(value),
				new Date(otherValue)
			);

			if (isAfterTime !== isTo) {
				this.setState({ buttonDisabled: true, errorInDate: true });

				const warningMessage = `${firstField} date should be ${
					isTo ? 'after' : 'before'
				} ${secondField} date`;

				toast.warn(warningMessage, {
					theme: 'light',
				});
			} else {
				this.setState({ errorInDate: false });
			}
		}
	}

	public togglePermanent() {
		const value = this.helperForm.getValue('permanent');

		if (!!value) {
			this.form.setValue('validTo', new Date('2099-12-31 23:59:59'));

			if (!this.form.getValue('validFrom')) {
				this.form.setValue('validFrom', new Date());

				const fromEl = document.getElementById('notesEditFirst');
				if (fromEl) {
					fromEl.focus();
				}
			}
		}

		this.setState({ disableTo: value });
	}

	public componentDidMount(): void {
		super.componentDidMount();

		if (!this.props?.isEdit && !this.props.isCountry) {
			this.fetchForCountry();
		}
	}

	public async fetchForCountry() {
		try {
			const response = await RequestManager.get(
				`/airports/${this.props.aid}`
			);

			if (!response) {
				throw new Error('error');
			}

			this.setState({ country: response?.country });
		} catch (err) {
			this.setState({ country: false });
		}
	}

	public onClose(isDelete = false): void {
		this.modal$?.close();
		this.form?.clearForm();
		this.props?.onClose(isDelete);
	}

	public getTitle() {
		return this.props?.isEdit ? 'Edit note' : 'Create note';
	}

	public renderForm(): React.ReactElement {
		const error = this.state.errorInDate?.type;
		const categoryOpts =
			this.state?.isCountry || this.props?.isCountry
				? countryCategory
				: category;

		return (
			<form className='p-4'>
				<div className='FormContainer'>
					<Tooltip
						isOpen={this.state.focused === 'from'}
						tooltip={this.renderPopover()}
						trigger={
							<DateTimePicker
								id='notesFirst'
								field={this.form.getField('validFrom')}
								inputClassName='palette--c-neutral-5'
								classes='py-2'
								error={error === 'from'}
								showTimeInput={true}
								useHour={true}
								shouldCloseOnSelect={false}
								onFocus={() =>
									this.setState({ focused: 'from' })
								}
								onBlur={() => this.setState({ focused: false })}
								isIcon={true}
								labelInfos={{ label: 'Valid from' }}
							/>
						}
					/>
					<Tooltip
						isOpen={this.state.focused === 'to'}
						tooltip={this.renderPopover()}
						trigger={
							<DateTimePicker
								field={this.form.getField('validTo')}
								days={7}
								inputClassName='palette--c-neutral-5'
								classes='left-m py-2'
								error={error === 'from'}
								disabled={this.state.disableTo}
								showTimeInput={true}
								useHour={true}
								shouldCloseOnSelect={false}
								onFocus={() => this.setState({ focused: 'to' })}
								onBlur={() => this.setState({ focused: false })}
								isIcon={true}
								labelInfos={{ label: 'Valid to' }}
							/>
						}
					/>
					<Checkbox
						field={this.helperForm.getField('permanent')}
						labelInfos={{ label: 'Permanent' }}
						classes='py-1 left-m'
					/>
				</div>
				{!this.props?.isCountry &&
					!this.props.isEdit &&
					this.renderCountry()}
				<div className='display-flex flex-column flex-md-row'>
					<Select
						classes='py-2'
						field={this.form.getField('criticality')}
						labelInfos={{
							label: 'Criticality',
						}}
						options={criticalityOpts}
					/>
					<Select
						classes='left-m py-2'
						field={this.form.getField('category')}
						labelInfos={{
							label: 'Category',
						}}
						options={categoryOpts}
					/>
				</div>
				<TextInput
					field={this.form.getField('source')}
					inputClassName='Source'
					classes='py-2'
					textType='camel'
					labelInfos={{ label: 'Source' }}
				/>
				<TextInput
					element='textarea'
					rows={5}
					field={this.form.getField('notes')}
					classes='py-2'
					textType='sentences'
					labelInfos={{ label: 'Notes' }}
				/>
			</form>
		);
	}

	public renderCountry() {
		if (!this.state?.country) {
			return null;
		}

		const country = this.state?.country?.name;
		const iso = this.state?.country?.iso2;

		const flagClasses = arrayToClass([
			'flag-map-marker',
			iso ? `fi fi-${iso.toLowerCase()}` : 'display-none',
			'mr-2',
		]);

		return (
			<div className='w-100 display-flex align-items-center my-2'>
				<Checkbox
					field={this.helperForm.getField('isRelevantToCountry')}
					classes='py-2'
				/>

				<div className='display-flex flex-wrap'>
					<p className='palette--c-neutral-6 m-0'>
						Is it relevant to the entire country?
					</p>
					<div className='display-flex align-items-center mx-0 mx-md-2'>
						<p className='palette--c-neutral-6 m-0'>(</p>
						<span className={flagClasses} />
						<p className='palette--c-neutral-5 m-0'>{country}</p>
						<p className='palette--c-neutral-6 m-0'>)</p>
					</div>
				</div>
			</div>
		);
	}

	public renderPopover(): any {
		const formatString = this.props.format || 'yyyy. MM. dd.';

		return (
			<div className='palette--bgc-neutral-6 border-radius-1 p-2'>
				<h5 className='palette--c-neutral-1 m-0'>Use this format</h5>
				<div className='row'>
					<span className='col-24 palette--c-neutral-1 py-2'>
						{format(new Date(), formatString)}
					</span>
				</div>
				<div className='row'>
					<span className='col-24 palette--c-neutral-1 m-0 fw-bold'>
						All times should be in local.
					</span>
				</div>
			</div>
		);
	}

	public getType() {
		return 'note';
	}

	public generateValues() {
		const values: any = this.form.generateJSON();
		const { validFrom, validTo } = values;

		values.validFrom = toUtc(validFrom);
		values.validTo = toUtc(validTo);

		return values;
	}

	public async onSave() {
		this.setState({ buttonDisabled: true, saveLoading: true });
		const values = this.generateValues();
		const changes: any = this.form?.getDifferences();
		const isCountry =
			this.props.isCountry ||
			(!this.props?.isEdit &&
				this.helperForm.getValue('isRelevantToCountry'));

		const req = request(
			`/${isCountry ? 'country' : 'airport'}operationalnotes/merge`,
			'operational-note',
			() => this.handleSuccessModal(),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		);

		if (this.props.isEdit) {
			const data = {
				...values,
				parent: this.props.parentAid,
			};

			await req.edit(
				`/${
					this.props.isCountry ? 'country' : 'airport'
				}operationalnotes/${this.props?.aid}`,
				data,
				changes
			);
		} else {
			const aid =
				!isCountry || this.props.isCountry
					? this.props?.aid
					: this.state?.country?.iso3;

			await req.create(
				`/${isCountry ? 'country' : 'airport'}operationalnotes`,
				values,
				changes,
				{
					[isCountry ? 'parentId' : 'parentAid']: aid,
				}
			);
		}

		this.setState({ buttonDisabled: false, saveLoading: false });
	}

	public async onDelete(): Promise<void> {
		this.setState({ buttonDisabled: true, saveLoading: true });

		request(
			`/${
				this.props.isCountry ? 'country' : 'airport'
			}operationalnotes/merge`,
			'operational-note-delete',
			() => this.handleSuccessModal(),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		).deleteF(
			`/${this.props.isCountry ? 'country' : 'airport'}operationalnotes/${
				this.props?.aid
			}`,
			this.form?.getFieldsWhereHasValue()
		);

		this.setState({ buttonDisabled: false, saveLoading: false });
	}

	public handleSuccessModal() {
		if (this.notif$) {
			this.notif$.open();
		}
	}
}

export const OperationalNotesForm: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(PreLoader(OperationalNotesFormComponent));
