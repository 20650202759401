import { get } from 'lodash';
import * as React from 'react';
import { CoordinatesInput } from '../../../../ui-components/inputs/coordinates-input';
import { Input } from '../../../../ui-components/inputs/inputs/input';
import { MeasurementInput } from '../../../../ui-components/inputs/measure-input/measurement-input';
import { SearchableSelect } from '../../../../ui-components/inputs/select/searchable-select';
import { Select } from '../../../../ui-components/inputs/select/select';
import { SwitchSelect } from '../../../../ui-components/inputs/select/switch-select';
import { TextInput } from '../../../../ui-components/inputs/inputs/text-input';
import { VariationInput } from '../../../../ui-components/inputs/variation-input';
import { formatOptions } from '../../../../ui-components/inputs/select/formatter';
import { Checkbox } from '../../../../ui-components/inputs/checkboxes/checkbox/checkbox';

export const airportFields = (elmnt) => [
	{
		type: 'accessibleFor',
		title: 'Accepted traffic',
		component: (
			<Select
				isMulti={true}
				classes='NewData'
				field={elmnt.form.getField('accessibleFor')}
				options={formatOptions(airportOptions.accessibleFor)}
			/>
		),
	},
	{
		type: 'coordinates.latitude',
		title: 'latitude',
		component: (
			<CoordinatesInput
				classes='NewData'
				isContribute={true}
				lat={elmnt.form.getField('coordinates.latitude')}
				ignoreLabel={true}
			/>
		),
	},
	{
		type: 'coordinates.longitude',
		title: 'longitude',
		component: (
			<CoordinatesInput
				classes='NewData'
				isContribute={true}
				lng={elmnt.form.getField('coordinates.longitude')}
				ignoreLabel={true}
			/>
		),
	},
	{
		type: 'country',
		title: 'Country',
		component: (
			<SearchableSelect
				value='name'
				title='name'
				field={elmnt.form.getField('country')}
				classes='NewData'
				endpoint={'/free/countries/search'}
			/>
		),
	},
	{
		type: 'elevation',
		title: 'Elevation',
		component: (
			<MeasurementInput
				field={elmnt.form.getField('elevation')}
				type='length'
				classes='NewData'
			/>
		),
		unit: 'm',
	},
	{
		type: 'variation',
		title: 'Variation',
		component: (
			<VariationInput
				field={elmnt.form.getField('variation')}
				classes='NewData'
			/>
		),
		ignoreTransform: true,
	},
	{
		type: 'localIdentifier',
		title:
			get(
				elmnt.props.item,
				'country.localIdentifierName',
				'Local Identifier'
			) || 'Local Identifier',
		component: (
			<TextInput
				field={elmnt.form.getField('localIdentifier')}
				classes='NewData'
				minLength={2}
				maxLength={64}
				textType='camel'
			/>
		),
	},
	{
		type: 'iata',
		title: 'IATA',
		component: (
			<TextInput
				field={elmnt.form.getField('iata')}
				classes='NewData'
				minLength={3}
				maxLength={3}
				textType='special'
				format={(text) => text.toUpperCase()}
			/>
		),
	},
	{
		type: 'icao',
		title: 'ICAO',
		component: (
			<TextInput
				field={elmnt.form.getField('icao')}
				classes='NewData'
				minLength={4}
				maxLength={4}
				textType='special'
				format={(text) => text.toUpperCase()}
			/>
		),
	},
	{
		type: 'ifr',
		title: 'Flight rules',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('ifr')}
				options={formatOptions(airportOptions.ifr)}
			/>
		),
	},
	{
		type: 'fuelsAvailable',
		title: 'Fuels Available',
		component: (
			<Select
				isMulti={true}
				classes='NewData'
				field={elmnt.form.getField('fuelsAvailable')}
				options={formatOptions(airportOptions.fuelsAvailable, false)}
			/>
		),
	},
	{
		type: 'operator',
		title: 'Operator',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('operator')}
				options={formatOptions(airportOptions.airportOperator)}
			/>
		),
	},
	{
		type: 'mandatoryHandling',
		title: 'Mandatory handling',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('mandatoryHandling')}
			/>
		),
	},
	{
		type: 'mandatoryQualification',
		title: 'Mandatory qualification',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('mandatoryQualification')}
			/>
		),
	},
	{
		type: 'name',
		title: 'Name',
		component: (
			<TextInput
				field={elmnt.form.getField('name')}
				classes='NewData'
				textType='camel'
			/>
		),
	},
	{
		type: 'nonScheduledPermission',
		title: 'Non scheduled permission',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('nonScheduledPermission')}
				options={formatOptions(airportOptions.permission)}
			/>
		),
	},
	{
		type: 'nonScheduledPermissionNotes',
		title: 'Permission notes',
		component: (
			<TextInput
				field={elmnt.form.getField('nonScheduledPermissionNotes')}
				classes='NewData'
				textType='sentences'
			/>
		),
	},
	{
		type: 'qualificationLink',
		title: 'Mandatory qualification link',
		component: (
			<TextInput
				textType='camel'
				field={elmnt.form.getField('qualificationLink')}
				classes='NewData'
			/>
		),
	},
	{
		type: 'qualificationNotes',
		title: 'Mandatory qualification notes',
		component: (
			<TextInput
				element='textarea'
				rows={5}
				field={elmnt.form.getField('qualificationNotes')}
				classes='NewData'
				textType='sentences'
			/>
		),
	},
	{
		type: 'referenceWeatherStation',
		title: 'Reference weather station',
		component: (
			<SearchableSelect
				value='aid'
				title='name'
				field={elmnt.form.getField('referenceWeatherStation')}
				classes='NewData'
				endpoint={'/free/airports/search?includeArchived=true'}
			/>
		),
	},
	{
		type: 'servedCity',
		title: 'City',
		component: (
			<TextInput
				field={elmnt.form.getField('servedCity')}
				classes='NewData'
				textType='camel'
			/>
		),
	},
	{
		type: 'timeZone',
		title: 'Timezone',
		component: (
			<Input field={elmnt.form.getField('timeZone')} classes='NewData' />
		),
	},
	{
		type: 'type',
		title: 'Airport type',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('type')}
				options={formatOptions(airportOptions.airportDefault)}
			/>
		),
	},
	{
		type: 'weightLimit',
		title: 'Weight limit',
		component: (
			<MeasurementInput
				field={elmnt.form.getField('weightLimit')}
				maxLengthLimit={7}
				classes='NewData'
				type='mass'
			/>
		),
		unit: 'kg',
	},
	{
		type: 'wingspanLimit',
		title: 'Wingspan limit',
		component: (
			<MeasurementInput
				type='length'
				field={elmnt.form.getField('wingspanLimit')}
				maxLengthLimit={3}
				classes='NewData'
			/>
		),
		unit: 'm',
	},
	{
		type: 'paxLimit',
		title: 'Passenger limit',
		component: (
			<Input
				inputType='number'
				field={elmnt.form.getField('paxLimit')}
				maxLengthLimit={3}
				classes='NewData border-radius-1'
			/>
		),
	},
	{
		type: 'archived',
		title: 'Archived',
		component: (
			<Checkbox
				labelInfos={{
					label: 'Permanently Closed',
				}}
				field={elmnt.form.getField('archived')}
				classes='NewData'
			/>
		),
	},
];

export const airportOptions = {
	airportDefault: [
		{ value: 'AIRPORT' },
		{ value: 'AIRSTRIP' },
		{ value: 'HELIPORT' },
		{ value: 'WATER' },
	],
	airportOperator: [
		{ value: 'JOINT' },
		{ value: 'MILITARY' },
		{ value: 'PRIVATE' },
		{ value: 'PUBLIC' },
	],
	accessibleFor: [
		{ value: 'BUSINESS_AVIATION' },
		{ value: 'GENERAL_AVIATION' },
		{ value: 'HELICOPTER' },
		{ value: 'MILITARY' },
		{ value: 'NON_SCHEDULED' },
		{ value: 'SCHEDULED' },
		{ value: 'TRAINING' },
	],
	fuelsAvailable: [
		{ value: 'JET' },
		{ value: 'AVGAS' },
		{ value: 'MOGAS' },
		{ value: 'SAF' },
	],
	ifr: [
		{ value: true, title: 'IFR and VFR' },
		{ value: false, title: 'VFR only' },
	],
	permission: [
		{ value: 'NONE' },
		{ value: 'PPR' },
		{ value: 'SLOT' },
		{ value: 'UNAVAILABLE' },
	],
};
