import * as React from 'react';
import { arrayToClass } from '../../../../utilities/helper-fuctions';
import { InputWrapper } from './input-wrapper';

export class Password extends InputWrapper<any, any> {
    public state = {
        ...this.state,
        isRevealed: false,
    };

    public renderInput(): any {
        const classes = arrayToClass(['border-0 w-100', this.props.inputClasses ? this.props.inputClasses : '']);

        return (
            <div className="display-flex border-1 palette--bc-neutral-4 palette--bgc-neutral-1 border-radius-1">
                <input
                    type={this.state?.isRevealed ? 'text' : 'password'}
                    value={this.state.value as any}
                    onChange={(event) => this.handleChange(event)}
                    className={classes}
                    {...this.props?.extras}
                />
                <div
                    className="display-flex align-items-center justify-content-center p-1"
                    onClick={() => this.setState({ isRevealed: !this.state?.isRevealed })}
                >
                    <span className="palette--c-neutral-4 material-icons">
                        {this.state?.isRevealed ? 'visibility_off' : 'visibility'}
                    </span>
                </div>
            </div>
        );
    }
}
