import { get } from 'lodash';
import * as React from 'react';
import { SubTab } from '../../../../../components/ui-components/tab/sub-tab';
import { connectNLP } from '../../../../../utilities/connect-navigate';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { CountryOperationalNotes } from './country-operational-notes';
import { NoteContributions } from './note-contributions';

const tabData = [
	{
		title: 'Airport operational notes',
		type: 'airportoperationalnotes',
	},
	{
		title: 'Country operational notes',
		type: 'countryoperationalnotes',
	},
];

class NotesContributionsComponent extends React.Component<any, any> {
	public state = {
		airportoperationalnotes: this.props?.total?.airportoperationalnotes,
		countryoperationalnotes: this.props?.total?.countryoperationalnotes,
	};

	public render(): React.ReactElement {
		const type = get(this.props, 'subType', false);

		const tabs = tabData.map((tab) => {
			return {
				...tab,
				total: get(this.state, tab.type),
			};
		});

		const classes = arrayToClass([
			'w-100',
			this.props.show ? 'SubContainer' : '',
		]);

		return (
			<div className={classes}>
				{this.props.show && (
					<SubTab
						items={tabs}
						onItemClick={(item) => this.handleClick(item)}
						selected={type}
					/>
				)}

				<NoteContributions
					system={this.props.system}
					show={
						this.props.show &&
						(type === 'airportoperationalnotes' || !type)
					}
					onTotalChange={(total) =>
						this.handleChange('airport', total)
					}
					total={this.props?.total?.airportOperationalNote}
				/>
				<CountryOperationalNotes
					system={this.props.system}
					show={this.props.show && type === 'countryoperationalnotes'}
					onTotalChange={(total) =>
						this.handleChange('country', total)
					}
					total={this.props?.total?.countryoperationalnotes}
				/>
			</div>
		);
	}

	public handleClick(item): any {
		if (this.props.isAdmin || this.props.system) {
			this.props?.router?.navigate(
				`/admin/${
					this.props.system ? 'system-' : ''
				}contributions/notes/${item.type}`
			);
		} else {
			this.props?.router?.navigate(`/contributions/notes/${item.type}`);
		}
	}

	public handleChange(type, total) {
		const airportoperationalnotes =
			type !== 'airport' ? this.state.airportoperationalnotes : total;
		const countryoperationalnotes =
			type !== 'country' ? this.state.countryoperationalnotes : total;

		const newTotal = airportoperationalnotes + countryoperationalnotes;

		this.setState({ [`${type}operationalnotes`]: total });
		this.props?.onTotalChange(newTotal);
	}
}

export const NotesContributions: any = connectNLP(NotesContributionsComponent);
