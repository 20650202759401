import { get } from 'lodash';
import * as React from 'react';
import { MainTab } from '../../../../../../components/ui-components/tab/main-tab';
import { connectNLP } from '../../../../../../utilities/connect-navigate';
import { arrayToClass } from '../../../../../../utilities/helper-fuctions';
import { FuelCostContributions } from './fuel-cost-contributions';
import { SubFuelContributions } from './sub-fuel-contributions';

const tabData = [
	{
		title: 'Fuel products',
		type: 'fuelProducts',
	},
	{
		title: 'Fuel costs',
		type: 'fuelCosts',
	},
];

class FuelContributionsComponent extends React.Component<any, any> {
	public state = {
		fuelProducts: this.props?.total?.fuelProduct,
		fuelCosts: this.props?.total?.fuelCost,
	};

	public render(): React.ReactElement {
		const type = get(this.props, 'fuelType', false);

		const tabs = tabData.map((tab) => {
			return {
				...tab,
				total: get(this.state, tab.type),
			};
		});

		const classes = arrayToClass([
			'w-100',
			this.props.show ? 'SubContainer' : '',
		]);

		return (
			<div className={classes}>
				{this.props.show && (
					<MainTab
						items={tabs}
						onItemClick={(item) => this.handleClick(item)}
						selected={type}
					/>
				)}

				<SubFuelContributions
					system={this.props.system}
					show={this.props.show && (type === 'fuelProducts' || !type)}
					onTotalChange={(total) =>
						this.handleChange('fuelProducts', total)
					}
					total={this.props?.total?.fuelProduct}
				/>
				<FuelCostContributions
					system={this.props.system}
					show={this.props.show && type === 'fuelCosts'}
					onTotalChange={(total) =>
						this.handleChange('fuelCosts', total)
					}
					total={this.props?.total?.fuelCost}
				/>
			</div>
		);
	}

	public handleClick(item): any {
		if (this.props.isAdmin || this.props.system) {
			this.props?.router?.navigate(
				`/admin/${
					this.props.system ? 'system-' : ''
				}contributions/providers/fuels/${item.type}`
			);
		} else {
			this.props?.router?.navigate(
				`/contributions/providers/fuels/${item.type}`
			);
		}
	}

	public handleChange(type, total) {
		const fuel = type !== 'fuelProducts' ? this.state.fuelProducts : total;
		const cost = type !== 'fuelCosts' ? this.state.fuelCosts : total;

		const newTotal = cost + fuel;
		this.setState({ [type]: total });
		this.props?.onTotalChange(newTotal);
	}
}

export const FuelContributions: any = connectNLP(FuelContributionsComponent);
