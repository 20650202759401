import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../actions/details.actions';
import { arrayToClass, redirectToLogin } from '../../utilities/helper-fuctions';
import { RequestManager } from '../../utilities/request';
import { ToastMessages } from '../notifications/toast-messages';
import { Form } from '../ui-components/form/form';
import { CoordinatesInput } from '../ui-components/inputs/coordinates-input';
import { TextInput } from '../ui-components/inputs/inputs/text-input';
import { MeasurementInput } from '../ui-components/inputs/measure-input/measurement-input';
import { formatOptions } from '../ui-components/inputs/select/formatter';
import { Select } from '../ui-components/inputs/select/select';
import { VariationInput } from '../ui-components/inputs/variation-input';
import { Tooltip } from '../ui-components/popover/popover';
import { StatusMarker } from '../ui-components/statusmarker/status-marker';
import { airportOptions } from '../widgets/airport/general-data/airport-contribution/airport-contribution-helpers';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
	mergeContribution: (data, endpoint, widgetId, callback) =>
		dispatch(
			DetailsActions.mergeContribution(data, endpoint, widgetId, callback)
		),
});

class AirportComponent extends React.Component<any, any> {
	public state = {
		error: false,
		icao: false,
	};

	public form = new Form(
		{
			icao: {
				value: this.props?.data?.icao,
				trigger: () => this.checkICAO(),
				mandatory: true,
			},
			type: this.props?.data?.type,
			operator: this.props?.data?.operator,
			name: this.props?.data?.name,
			latitude: this.props?.data?.coordinates?.latitude,
			longitude: this.props?.data?.coordinates?.longitude,
			servedCity: this.props?.data?.servedCity,
			elevation: this.props?.data?.elevation,
			variation: this.props?.data?.variation,
			ifr: this.props?.data?.ifr,
		},
		(changed) => this.handleButtonChange(changed)
	);

	public handleButtonChange(changed) {
		const icao: any = this.state?.icao;
		const buttonState = !icao?.error && changed && !this.state?.error;

		this.props?.disableButton(buttonState);
	}

	public async checkICAO() {
		try {
			const value = this.form.getValue('icao');

			const response = await RequestManager.get(
				`/free/airports/icao/${value}`,
				{},
				{ noHeader: true }
			);

			const ogAid = get(this.props, 'aid') || '';

			if (ogAid === get(response, 'aid')) {
				this.setButton(false);
				this.setState({ icao: false });
				return;
			}

			this.setButton(true);
			this.setState({ icao: { response: response, error: true } });
		} catch (err) {
			this.setButton(false);
			this.setState({ icao: false, error: false });
		}
	}

	public setButton(value) {
		if (this.props.disableButton) {
			this.props.disableButton(value);
		}
	}

	public render() {
		const classNames = arrayToClass([
			this.state?.error
				? 'border-1 palette--bc-red-2 border-radius-1'
				: '',
			'w-100 p-3',
		]);
		const classes = arrayToClass([
			`palette--bc-${
				!!get(this.state, 'icao.error') ? 'red-2' : 'neutral-4'
			}`,
		]);

		return (
			<div className={classNames}>
				<form>
					<div className='w-100 py-3'>
						<h5 className='palette--c-neutral-5'>AD 2.1</h5>
						<TextInput
							field={this.form.getField('name')}
							classes='py-2'
							textType='camel'
							labelInfos={{ label: 'Name' }}
						/>
						<Select
							field={this.form.getField('type')}
							classes='py-2'
							options={formatOptions(
								airportOptions.airportDefault
							)}
							labelInfos={{
								label: 'Type',
							}}
						/>
						<Tooltip
							isOpen={!!get(this.state, 'icao.response')}
							tooltip={this.renderPopover()}
							trigger={
								<TextInput
									textType='camel'
									field={this.form.getField('icao')}
									classes='py-2'
									inputClasses={classes}
									minLength={4}
									maxLength={4}
									labelInfos={{ label: 'ICAO' }}
								/>
							}
						/>
					</div>

					<div className='w-100 py-3'>
						<h5 className='palette--c-neutral-5'>AD 2.2</h5>
						<CoordinatesInput
							lat={this.form.getField('latitude')}
							lng={this.form.getField('longitude')}
							labelInfos={{ label: 'Geo ARP Location' }}
							classes='py-2'
							dms={true}
						/>
						<TextInput
							field={this.form.getField('servedCity')}
							classes='py-2'
							textType='camel'
							labelInfos={{ label: 'Served city' }}
						/>
						<MeasurementInput
							type='length'
							field={this.form.getField('elevation')}
							labelInfos={{ label: 'Elevation' }}
							classes='py-2'
						/>
						<VariationInput
							field={this.form.getField('variation')}
							labelInfos={{ label: 'Magnetic variation' }}
							classes='py-2'
						/>
						<Select
							classes='py-2'
							field={this.form.getField('operator')}
							options={formatOptions(
								airportOptions.airportOperator
							)}
							labelInfos={{
								label: 'Operator',
							}}
						/>
						<Select
							classes='py-2'
							field={this.form.getField('ifr')}
							options={formatOptions(airportOptions.ifr)}
							labelInfos={{
								label: 'Types of traffic permitted (IFR/VFR)',
							}}
						/>
					</div>
				</form>
			</div>
		);
	}

	public renderPopover(): any {
		const airport = get(this.state, 'icao.response');

		return (
			<div className='PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1 display-flex'>
				<div>
					<p className='palette--c-neutral-1 m-0'>
						You won't be able to create a new airport with this ICAO
						because there is already one existing with this code{' '}
						{get(airport, 'icao')} in our database.
					</p>
					<div className='display-flex align-items-center flex-wrap'>
						<p className='palette--c-neutral-1 m-0'>
							{' '}
							To contribute data to it, please visit
						</p>
						<div className='display-block'>
							<StatusMarker
								tColor='neutral-6'
								bgColor='neutral-1'
								text={get(airport, 'name', '')}
								type='aid'
								classNames='ml-2 pointer'
								onClick={() =>
									this.handleClick(get(airport, 'aid'))
								}
							/>
						</div>
						<p className='palette--c-neutral-1 m-0'>.</p>
					</div>
				</div>
				<div className='display-block'>
					<div
						className='pointer ml-2'
						onClick={() =>
							this.setState({
								icao: { response: false, error: true },
							})
						}
					>
						<p className='material-icons palette--c-neutral-1'>
							cancel
						</p>
					</div>
				</div>
			</div>
		);
	}

	public handleClick(aid) {
		window.open(
			`${window.location.origin}/airports/${aid}/general-data`,
			'_blank'
		);
	}

	public checkData(): any {
		const values: any = this.form.generateJSON();

		if (values.longitude || values.latitude) {
			values.coordinates = {
				latitude: values.latitude,
				longitude: values.longitude,
			};
			delete values.latitude;
			delete values.longitude;
		}

		return values;
	}

	public async saveAirport() {
		if (this.props.aid) {
			return await this.update();
		} else {
			return await this.create();
		}
	}

	public async update(): Promise<any> {
		const parsedAirport = await this.checkData();
		const originalAirport: any = get(this.props, 'data', {}) || {};
		const data = { ...originalAirport, ...parsedAirport };

		if (!(this.form.getDifferences() || []).length) {
			this.handleSuccess({ parentAid: get(this.props, 'data.aid') });
			return;
		}

		delete data.faa;

		try {
			const response = await RequestManager.put(
				`/airports/${get(this.props, 'aid')}`,
				data
			);

			if (!response) {
				throw new Error('Something went wrong');
			}

			await this.onSuccess(response);
		} catch (err) {
			redirectToLogin(err);
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			this.setState({ error: true });
		}
	}

	public async create(): Promise<void> {
		const data = await this.checkData();

		try {
			const response = await RequestManager.post('/airports', data);

			if (!response) {
				throw new Error('Something went wrong');
			}

			await this.onSuccess(response);
		} catch (err) {
			redirectToLogin(err);
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			this.setState({ error: true });
		}
	}

	public async onSuccess(response) {
		if (
			(get(this.props.user, 'roles') || []).includes(
				'AUTO_MERGE_CONTRIBUTIONS'
			)
		) {
			response.contribution.changes = this.form.getDifferences();

			const mergedResponse = await RequestManager.post(
				'/airports/merge',
				response
			);

			if (!response) {
				throw new Error('There was an error');
			}

			this.handleSuccess({
				parentAid:
					get(mergedResponse, 'entity.aid') ||
					get(mergedResponse, 'aid'),
			});
		} else {
			this.handleSuccess({ parentContributionId: get(response, 'id') });
		}
	}

	public handleSuccess(resp: any = false) {
		if (this.props.next) {
			this.props.next(resp || false);
		}
	}
}

export const Airport: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(AirportComponent);
