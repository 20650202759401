import React from 'react';
import { toast } from 'react-toastify';
import { arrayToClass } from '../../../../utilities/helper-fuctions';
import { request } from '../../../../utilities/widget-helpers/request';
import { ConfirmationModal } from '../../../notifications/confirmation-modal';
import { NotificationModal } from '../../../notifications/notification-modal';
import { ToastMessages } from '../../../notifications/toast-messages';
import { Spinner } from '../../spinner/spinner';
import { FormModalButtons } from './form-modal-button';

export class ModalButtons extends FormModalButtons<any, any> {
    public deleteModal$;
    public notif$;

    public renderExtra(): any {
        return (
            <div>
                <ConfirmationModal
                    title={`Are you sure you want to remove this ${(this.props?.type || '').replace(/-/gi, ' ')}?`}
                    onClick={() => this.onFinalDelete()}
                    close={() => this.deleteModal$.close()}
                    ref={(ref: any) => (this.deleteModal$ = ref)}
                />
                <NotificationModal ref={(ref: any) => (this.notif$ = ref)} onClose={() => this.props?.onClose()} />
            </div>
        );
    }

    public renderButtonChildren(colors) {
        if (this.props?.loading) {
            return <Spinner size="small" className="position-absolute" />;
        } else {
            const classes = arrayToClass(['mr-1 mb-0', colors]);
            return (
                <div className="w-100 display-flex align-items-center">
                    <span className={classes}>Send</span>
                </div>
            );
        }
    }

    public onDelete() {
        this.deleteModal$.open();
    }

    public async onFinalDelete() {
        await request(
            this.props?.merge,
            `${this.props?.type}-delete`,
            () => this.handleModalSuccess(),
            (err) => toast.error(<ToastMessages error={err} />, { theme: 'light' })
        ).deleteF(this.props?.delete, (resp) => this.props?.changes(resp));
    }

    public handleModalSuccess() {
        this.notif$?.open();
    }
}
