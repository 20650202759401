import { get, isEqual } from 'lodash';
import * as React from 'react';
import Masonry from 'react-masonry-css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { generateDMS } from '../../../../../utilities/coordinates-convereter';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../utilities/request';
import { Length } from '../../../../measurements/length';
import { ToastMessages } from '../../../../notifications/toast-messages';
import { ContributeButton } from '../../../../other/contribute-button';
import { ModalButtons } from '../../../../ui-components/hoverable-buttons/form-modal-buttons/modal-button';
import { HoverableButton } from '../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Modal } from '../../../../ui-components/modal/modal';
import { Spinner } from '../../../../ui-components/spinner/spinner';
import { StatusMarker } from '../../../../ui-components/statusmarker/status-marker';
import { runwayOptions } from '../runways-contribution/runway-contribution-helpers';
import './runway-details.scss';

const mapStateProps = (store: any) => ({
	user: store.user.user,
	mLength: store.measures.length,
	decimalCoordinates: store.userDetails.decimalCoordinates,
});

const mapDispatchProps = (dispatch: any) => ({});

class RunwayDetailsComponent extends React.Component<any, any> {
	public detailsModal$: Modal;

	public state = {
		loading: false,
		error: false,
		response: false,
	};

	public masonryContainerClasses = arrayToClass([
		'my-2 border-1 palette--bc-neutral-4 border-radius-1',
	]);
	public masonryTitleContainerClasses = arrayToClass([
		'palette--bc-neutral-4 border-bottom-1 p-2',
	]);
	public masonryTitleClasses = arrayToClass([
		'palette--c-neutral-6 fw-bold m-0',
	]);
	public titleClasses = arrayToClass(['Title palette--c-neutral-5 mb-0']);
	public valueClasses = arrayToClass([
		'RunwayValue palette--c-neutral-6 fw-bold display-flex justify-content-end m-0 p-0 align-items-center',
	]);
	public containerClasses = (bgClass) =>
		arrayToClass([
			'RowClasses display-flex m-0 p-2',
			`palette--bgc-neutral-${bgClass}`,
		]);

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (!isEqual(prevProps.modalDetails, this.props.modalDetails)) {
			this.setState({ response: get(this.props.modalDetails, 'runway') });
		}
	}

	public async componentDidMount(): Promise<void> {
		await this.fetch();

		if (this.detailsModal$) {
			this.detailsModal$.open();
		}
	}

	public async fetch(): Promise<void> {
		const aid = this.props?.aid;

		try {
			this.setState({ loading: true });

			const response = await RequestManager.get(`/runways/${aid}`);

			if (!response) {
				throw new Error('there was an error');
			}

			this.setState({ response: response });
		} catch (err) {
			this.handleClose();
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		} finally {
			this.setState({ loading: false });
		}
	}

	public render(): React.ReactElement {
		const loading = this.state.loading;
		const error = this.state.error;
		const response = this.state.response;

		return (
			<div className='RunwayDetails w-100'>
				{loading && !response && !error && this.renderLoading()}
				{!loading && !response && error && this.renderError()}
				{!loading && response && !error && this.renderContent()}
			</div>
		);
	}

	public renderLoading(): React.ReactElement {
		return <Spinner size='large' />;
	}

	public renderError(): React.ReactElement {
		return (
			<div className='w-100 display-flex justify-content-center py-5'>
				<p className='palette--c-neutral-4 m-0'>
					There was an error, sorry
				</p>
			</div>
		);
	}

	public renderEmpty(): React.ReactElement {
		return (
			<div className='w-100 display-flex justify-content-center py-5'>
				<p className='palette--c-neutral-4 m-0'>
					There is no content, sorry
				</p>
			</div>
		);
	}

	public renderContent(): React.ReactElement {
		if (!this.state.response) {
			return this.renderEmpty();
		}

		return (
			<Modal
				ref={(ref: any) => (this.detailsModal$ = ref)}
				title={() => this.renderHeader()}
				content={() => this.renderModalContent()}
				footer={() => this.renderFooter()}
				onDismissRequest={() => this.handleClose()}
				wrapperClasses='ConfirmationModal'
			/>
		);
	}

	public renderHeader(): React.ReactElement {
		const runway: any = this.state?.response;

		return (
			<div className='w-100 p-4'>
				<div className='display-flex align-items-center flex-wrap'>
					<h4 className='palette--c-neutral-6 fw-bold text-uppercase m-0 mr-3'>
						Runway details
					</h4>

					<div className='display-flex'>
						<h4 className='palette--c-neutral-6 fw-bold text-uppercase m-0 mr-3 flex-fill'>
							&nbsp;-&nbsp;
						</h4>
					</div>
					<div>
						<StatusMarker
							tColor='secondary-4'
							bgColor='secondary-1'
							text={get(this.state.response, 'identifier')}
							type='tag'
							classNames='RunwayIdentifier'
						/>
					</div>
				</div>
				{runway?.archived && (
					<p className='palette--c-neutral-5 m-0'>
						Permanently closed
					</p>
				)}
			</div>
		);
	}

	public renderModalContent(): React.ReactElement {
		const runway: any = this.state.response;
		const unit = get(this.props.mLength, 'targetDimension', 'm');

		const toda = this.length(get(runway, 'toda'), unit);
		const tora = this.length(get(runway, 'tora'), unit);
		const asda = this.length(get(runway, 'asda'), unit);
		const lda = this.length(get(runway, 'lda'), unit);
		const width = this.length(get(runway, 'width'), unit);

		const thresholdLatitude = get(runway, 'thresholdCoordinates.latitude');
		const thresholdLongitude = get(
			runway,
			'thresholdCoordinates.longitude'
		);
		const thresholdElevation = this.length(
			get(runway, 'thresholdElevation'),
			unit
		);
		const magneticBearing = get(runway, 'magneticBearing');
		const surface = get(runway, 'surface');

		const alc = get(runway, 'approachLightConfiguration');
		const thresholdLights = get(runway, 'thresholdLights');
		const touchdownZoneLights = get(runway, 'touchDownZoneLights');
		const centerLights = get(runway, 'centerLights');
		const centerlineLightsSpacing = get(runway, 'centerLightsSpacing');
		const edgeLights = get(runway, 'edgeLights');
		const endLights = get(runway, 'endLights');

		const vasis = get(runway, 'visualApproachSlopeIndicator');
		const helipad = get(runway, 'helipad');
		const slope = get(runway, 'slope', null);
		const grooved = get(runway, 'grooved');
		const instrumental = get(runway, 'instrumental');
		const precision = get(runway, 'precision');
		const separate = get(runway, 'separate');
		const standardPattern = get(runway, 'standardPattern');

		const breakpointColumnsObj = {
			default: 2,
			575: 1,
		};

		return (
			<div className='w-100 px-4 pb-4 pt-0'>
				<div className='pb-4 display-flex justify-content-end'>
					<HoverableButton
						disabled={false}
						className='border-radius-1'
						colorType='avio-green'
						onClick={() => this.handleContributeButtonClick()}
						titleLeft='Edit'
						icon='rate_review'
					/>
				</div>

				<Masonry
					breakpointCols={breakpointColumnsObj}
					className='my-masonry-grid'
					columnClassName='my-masonry-grid_column'
				>
					<div className={this.masonryContainerClasses}>
						<div className={this.masonryTitleContainerClasses}>
							<h4 className={this.masonryTitleClasses}>
								Declared Distances
							</h4>
						</div>
						{this.renderValue('TORA', tora, 0, unit)}
						{this.renderValue('TODA', toda, 2, unit)}
						{this.renderValue('ASDA', asda, 0, unit)}
						{this.renderValue('LDA', lda, 2, unit)}
						{this.renderValue('Width', width, 0, unit)}
					</div>

					<div className={this.masonryContainerClasses}>
						<div className={this.masonryTitleContainerClasses}>
							<h4 className={this.masonryTitleClasses}>
								Geographical information
							</h4>
						</div>
						{this.renderCooridnates(
							thresholdLatitude,
							thresholdLongitude,
							0
						)}
						{this.renderValue(
							'Threshold elevation',
							thresholdElevation,
							2,
							unit
						)}
						{this.renderValue(
							'Magnetic Bearing',
							magneticBearing,
							0,
							'˚'
						)}
					</div>

					<div className={this.masonryContainerClasses}>
						<div className={this.masonryTitleContainerClasses}>
							<h4 className={this.masonryTitleClasses}>Lights</h4>
						</div>
						{this.renderVasis('VASIS', vasis, 2)}
						{this.renderValue('Approach light system', alc, 0)}
						{this.renderValue(
							'Threshold lights',
							thresholdLights,
							2
						)}
						{this.renderValue(
							'Touch-down zone lights',
							touchdownZoneLights,
							0
						)}
						{this.renderValue('Center lights', centerLights, 2)}
						{centerLights &&
							this.renderValue(
								'Centerline lights spacing',
								centerlineLightsSpacing,
								0,
								unit
							)}
						{this.renderValue(
							'Edge lights',
							edgeLights,
							centerLights ? 2 : 0
						)}
						{this.renderValue(
							'End lights',
							endLights,
							centerLights ? 0 : 2
						)}
					</div>

					<div className={this.masonryContainerClasses}>
						<div className={this.masonryTitleContainerClasses}>
							<h4 className={this.masonryTitleClasses}>
								Additional Information
							</h4>
						</div>
						{this.renderValue('Surface', surface, 0)}
						{this.getPCN(2)}
						{this.renderValue('Helipad', helipad, 0)}
						{this.renderValue(
							'Slope',
							slope !== null
								? (slope * 100) % 1 === 0
									? slope * 100
									: (slope * 100).toFixed(2)
								: null,
							2,
							'%'
						)}
						{this.renderValue('Grooved', grooved, 0)}
						{this.renderValue('Instrumental', instrumental, 2)}
						{this.renderValue('Precision', precision, 0)}
						{this.renderValue('Separate', separate, 2)}
						{this.renderValue(
							'Standard pattern',
							standardPattern,
							0
						)}
					</div>
				</Masonry>
			</div>
		);
	}

	public length(width: number, unit) {
		if (!width) {
			return '-';
		}
		return (
			<Length
				value={width}
				sourceDimension='m'
				targetDimension={unit}
				precision={0}
				ignorePrecision={false}
				ignoreDimension={true}
			/>
		);
	}

	public renderValue(
		title: string,
		value: any,
		bgColor: number,
		mark: any = null
	): any {
		if (
			(!value || value === '-') &&
			typeof value !== 'boolean' &&
			value !== 0
		) {
			return this.renderEmptyProperties(false, title, bgColor);
		}

		let displayable = value;

		if (typeof value === 'boolean') {
			displayable = value ? 'Yes' : 'No';
		}

		if (typeof displayable === 'string') {
			displayable = (displayable || '').replace(/_/gi, ' ');
		}

		return (
			<div className={this.containerClasses(bgColor)}>
				<p className={this.titleClasses}>{title}</p>
				<p className={this.valueClasses}>
					{displayable} {mark}
				</p>
			</div>
		);
	}

	public renderCooridnates(lat, lng, bgColor: number): React.ReactElement {
		if (!lat && !lng) {
			return this.renderEmptyProperties(
				false,
				'Threshold coordinates',
				bgColor
			);
		}

		const isDec = this.props.decimalCoordinates;

		return (
			<div className={this.containerClasses(bgColor)}>
				<p className={this.titleClasses}>Threshold coordinates</p>
				<div className={this.valueClasses}>
					<p className='palette--c-neutral-6 fw-bold'>
						{isDec ? lat : generateDMS(lat)}
					</p>
					<p className='palette--c-neutral-6 fw-bold ml-2'>
						{isDec ? lng : generateDMS(lng, true)}
					</p>
				</div>
			</div>
		);
	}

	public getPCN(color): any {
		const runway = this.state.response;

		const numerical = get(runway, 'pcnNumerical');
		const type = get(runway, 'pcnType');
		const strength = get(runway, 'pcnStrength');
		const tyrePressure = get(runway, 'pcnTirePressure');
		const method = get(runway, 'pcnMethod');

		if (!numerical && !type && !strength && !tyrePressure && !method) {
			return this.renderEmptyProperties(false, 'PCN', color);
		}

		return (
			<div className={this.containerClasses(color)}>
				<p className={this.titleClasses}>PCN</p>
				<p className={this.valueClasses}>
					{numerical || '-'}/{type || '-'}/{strength || '-'}/
					{tyrePressure || '-'}/{method || '-'}
				</p>
			</div>
		);
	}

	public renderVasis(
		title: string,
		value: any,
		bgColor: number,
		mark: any = null
	): any {
		if (!value || value === '-') {
			return this.renderEmptyProperties(false, title, bgColor);
		}

		const displayable = runwayOptions.vasiOpts.find(
			(opt) => opt.value === value
		);

		return (
			<div className={this.containerClasses(bgColor)}>
				<p className={this.titleClasses}>{title}</p>
				<p className={this.valueClasses}>
					{get(displayable, 'title', '-')} {mark}
				</p>
			</div>
		);
	}

	public renderEmptyProperties(
		label: any,
		title,
		bgColor,
		isAre: boolean = false
	): React.ReactElement {
		return (
			<div className={this.containerClasses(bgColor)}>
				<p className={this.titleClasses}>{title}</p>
				<div className={this.valueClasses}>
					<ContributeButton
						openModal={() => this.handleContributeButtonClick()}
						label={label}
						isAre={isAre}
						buttonOnly={!label}
					/>
				</div>
			</div>
		);
	}

	public renderFooter(): React.ReactElement {
		const isAdmin = (this.props?.user?.roles || []).includes('ADMIN');

		return (
			<ModalButtons
				onClose={() => this.handleClose()}
				isDelete={isAdmin}
				type='runway'
				merge={'/runways/merge'}
				delete={`/runways/${this.props?.aid}`}
				changes={(resp) => this.getChanges(resp)}
			/>
		);
	}

	public getChanges(resp) {
		const note = this.state?.response;
		const changes = Object.keys(note).filter(
			(key) =>
				note?.[key] !== null ||
				note?.[key] !== undefined ||
				note?.[key] !== ''
		);

		resp.contribution.changes = changes;
		return resp;
	}

	public handleClose(): void {
		this.detailsModal$?.close();

		if (this.props.setModalDetails) {
			this.props.setModalDetails(null);
		}

		this.props.goToAirports();
	}

	public handleContributeButtonClick(): void {
		this.detailsModal$.close();

		if (this.props.setModalDetails) {
			this.props.setModalDetails({ runway: this.state.response });
		}

		this.props.edit();
	}
}

export const RunwayDetails: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(RunwayDetailsComponent);
