export const runwayInfos = {
    identifier:
        'The number assigned to a runway based on orientation. To be expressed in a 2 or 3-character alphanumeric code E.g. |35|, |35L|, |35C| or |35R|. This can be left empty if helipad is selected |YES|.',
    helipad: 'A landing and take-off area dedicated only to the movement of VTOL aircraft. ',
    trueBearing: 'The angle between True North and the orientation of the runway. ',
    magneticBearing: 'The angle between Magnetic North and the orientation of the runway. ',
    surface:
        '|ASPHALT| A mineral aggregate bound with asphalt, including bituminous asphalt or tarmac. \n|CONCRETE| An aggregate bonded together with a fluid cement. \n|GRAVEL| Loose aggregation of rock fragments. \n|DIRT| A prepared or semi-prepared surface with an earthy surface. \n|GRASS| A prepared or semi-prepared surface with a grass cover. \n|SNOW| A compact or loose snow cover, which is meant for usage with specific landing gears. \n|WATER| A marked or unmarked body of water, or portion thereof, which is meant for floatplanes or amphibious aircraft operations.',
    thresholdCoordinates: 'A set of numbers indicating the beginning of the runway on the centreline.',
    thresholdElevation:
        'A number indicating the elevation of the runway threshold at the location indicated by the Threshold Coordinates. To be expressed with up to 2 decimal places.',
    slope:
        'The difference in elevation from the beginning to the end of the runway. \nTo be expressed as percentage with up to 2 decimal places. Enter positive for uphill slope and negative (-) for downhill slope e.g. 2 for uphill, -2 for downhill.',
    grooved: 'A runway surface with narrow channels meant for improved friction in wet conditions.',
    tora:
        'Take-Off Run Available (the runway length declared available for the ground run of an aeroplane or helicopter on take-off). \nTo be expressed with up to 2 decimal places.',
    toda:
        'Take-Off Distance Available (the length of the TORA, including any remaining runway or clearway beyond the TORA). \nTo be expressed with up to 2 decimal places.',
    asda:
        'Accelerate Stop Distance Available (the length of the TORA, including the stopway). \nTo be expressed with up to 2 decimal places.',
    lda:
        'Landing Distance Available (the runway length declared available for the ground run of an aeroplane or helicopter on landing). \nTo be expressed with up to 2 decimal places.',
    width: 'The distance between the two edges of the runway. \nTo be expressed with up to 2 decimal places.',
    pcnNumerical:
        'The numerical portion of the Pavement Classification Number indicating the load-carrying capacity of the runway.',
    pcnType:
        ' The letter relating to the flexibility of the runway material as stated in the Pavement Classification Number. \n|R| - Rigid, most typically concrete \n|F| - flexible, most typically asphalt',
    pcnStrength:
        'The letter relating to the strength of the material below the runway surface (subgrade) as stated in the Pavement Classification Number. \n|A| High Strength – for example, concrete-stabilised clay \n|B| Medium Strength \n|C| Low Strength \n|D| Ultra-Low Strength – for example, uncompacted soil',
    pcnTirePressure:
        'The letter relating to the maximum tire pressure that the runway can support as stated in the Pavement Classification Number. \n|W| Unlimited – no tyre pressure limit \n|X| High – maximum tyre pressure of 1.75 MPa \n|Y| Medium – maximum tyre pressure of 1.25 MPa \n|Z| Low – maximum tyre pressure of 0.5 MPa',
    pcnMethod:
        'The letter relating to the method through which the value of numerical portion of the Pavement Classification Number was obtained. \n|T| Technical evaluation \nU Usage – a physical testing regime.',
    visualApproachSlopeIndicator:
        'A system of lights located on the left, right or both sides of an airport runway threshold, providing visual descent guidance information during approach.',
    approachLightConfiguration:
        'The lighting system installed prior to the runway providing positive identification of the approach. \n|NONE| No ALS is installed. \n|MALSR| 1,400 foot medium intensity approach lighting system with runway alignment indicator lights. \n|MALSF| 1,400 foot medium intensity approach lighting system with sequenced flashers. \n|SALS| Short approach lighting system \n|SSALS| simplified short approach lighting system. \n|SSALR| Simplified short approach lighting system with runway alignment indicator lights. \n|SSALF| Simplified short approach lighting system with sequenced flashers. \n|ODALS| Omnidirectional approach lighting system. \n|ALSF| Standard 2,400 foot high-intensity approach lighting system with sequenced flashers, category I configuration. \n|ALSF2| Standard 2,400 foot high-intensity approach lighting system with sequenced flashers, category |II| or |III| configuration. \n|ICAO1 HIALS| ICAO-compliant configuration 1, High-Intensity Approach Lighting System. \n|ICAO2 HIALS| ICAO-compliant configuration 2 High-Intensity Approach Lighting System. \n|LDIN| Lead-in lighting. \n|REIL| Runway End Identification Lights. \n|RAIL| Runway Alignment Indicator Lights.',
    thresholdLights:
        'A series of green lights provided in a line along the landing threshold at the touchdown end of a runway.',
    touchDownZoneLights:
        'Two rows of lighting situated either side of the centreline extending from the landing threshold for either 900 metres or to the midpoint of the runway, whichever is the least.',
    centerLights: 'A series of lights indicating the middle line of the runway.',
    centerLightsSpacing:
        'The distance between individual lights along the centerline. \nTo be expressed with up to 2 decimal places.',
    edgeLights: 'A series of high or medium intensity lights located along the edge of the runway.',
    endLights:
        'A series of red lights situated at the end and omitting light towards the runway indicating the end of useable runway.',
    instrumental:
        'The runway has at least one instrumental approach that serves the landing direction being described and allows the aircraft to land using Instrument Flight Rules.',
    precision:
        'The runway has at least one instrumental precision approach that serves the landing direction being described, allowing the aircraft to land using precision lateral and vertical guidance with minima as determined by the category of operation.',
    separate:
        'The runway constitutes a separate landing surface from other runways. This runway may overlay or cross other runways in such a way that if one of the runways is blocked, it will not prevent operations on the other runway. This satisfies the criteria of JAR-OPS-1.192(9) for physical separation.',
    standardPattern: 'A left-hand path followed by aircraft while maintaining visual contact with the airfield.',
};
