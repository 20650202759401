export const fuelInfos = {
	type: 'A string indicating the type of fuel product being described. \nPossible values are: \n|JET A| for JET A fuel \n|JET A1| for JET A-1 fuel \n|JET B| for JET B fuel \n|TS1| for TS-1 fuel \n|AVGAS 100 130| for AvGas 100/130 \n|AVGAS 100LL| for AvGas 100LL \n|AVGAS 91 96UL| for AvGas 91/96UL \n|JP5| for military kerosene JP-5 \n|JP8| for military kerosene JP-8 \n|MO GAS| for regular automotive gasoline \n|DIESEL| for regular automotive diesel',
	limitedAvailability:
		'|YES| indicates this fuel product is available in limited quantity only and operators should consider making prior arrangement. \n|NO| if the stock level allows for uplifts without prior arrangement. \nThis property only indicates stock levels. Any prior arrangements required for payment or other purposes is not taken into consideration.',
	minimumHandlingWaiver:
		'Indicates the minimum amount in units required to waive handling charges.',
	fees: {
		appliesToCommercial:
			'|YES| if this Fee applies to flights operated by an AOC holder and filed as commercial. \n|NO| if commercial flights are exempt.',
		appliesToInternational:
			'|YES| if this Fee applies to flights where the country of destination is the same as the country of origin.',
		excludedCountries:
			'If |Applies to commercial| is |YES|, a list of destination countries for which flights are excluded from paying this Fee.',
		tax: '|YES| if this Fee represents a tax which is levied by or on behalf of a government.',
	},
};
