import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { PreLoader } from '../../../utilities/pre-loader';
import { RequestManager } from '../../../utilities/request';
import { ToastMessages } from '../../notifications/toast-messages';
import { Form } from '../../ui-components/form/form';
import { Checkbox } from '../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { registrationInfos } from '../../../utilities/widget-helpers/registration-infos';

class EmailPreferencesComponent extends React.Component<any, any> {
    public form = new Form({
        contributeEmails: this.props?.response?.contributeEmails || '',
        mergeEmails: this.props?.response?.mergeEmails || '',
        marketingEmails: this.props?.response?.marketingEmails || '',
    });

    public render(): React.ReactElement {
        return (
            <div className="py-4 w-100">
                <h6 className="fw-bold text-uppercase palette--c-neutral-5 mb-4">Email preferences</h6>

                <div className="EmailCheckboxes">
                    <Checkbox
                        field={this.form.getField('contributeEmails')}
                        labelInfos={{
                            label: 'Contribution confirmation',
                            info: registrationInfos.contribution,
                            isSimple: true,
                            classes: 'flex-fill',
                        }}
                    />
                    <Checkbox
                        field={this.form.getField('mergeEmails')}
                        classes="py-2"
                        labelInfos={{
                            label: 'Published contribution confirmation',
                            info: registrationInfos.publish,
                            isSimple: true,
                            classes: 'flex-fill',
                        }}
                    />
                    <Checkbox
                        field={this.form.getField('marketingEmails')}
                        labelInfos={{
                            label: 'Marketing and other updates from aviowiki',
                            isSimple: true,
                            classes: 'flex-fill',
                        }}
                    />
                </div>
            </div>
        );
    }

    public async handleEmailPreferences(aid = false): Promise<any> {
        const form: any = this.form.generateJSON();
        const diffs = this.form.getDifferences();

        if (!diffs.length) {
            return;
        }

        try {
            const response = await RequestManager.post(`/mailPreferences/${aid || get(this.props.user, 'aid')}`, form);

            if (!response) {
                throw new Error('error');
            }

            toast.success('Email preferences successfully saved!');
        } catch (err) {
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
            return err;
        }

        return;
    }
}

export const EmailPreferences = PreLoader(EmailPreferencesComponent);
