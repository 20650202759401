import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../../actions/details.actions';
import { ConfirmationModal } from '../../../components/notifications/confirmation-modal';
import { ToastMessages } from '../../../components/notifications/toast-messages';
import { HoverableButton } from '../../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { StatusMarker } from '../../../components/ui-components/statusmarker/status-marker';
import { ManageWebhook } from '../../../components/user-profile/webhooks/manage-webhook';
import { SecretKey } from '../../../components/user-profile/webhooks/secret-key';
import { ManagesLayout } from '../../../layouts/manages/manages.layout';
import { arrayToClass, debounce } from '../../../utilities/helper-fuctions';
import { RequestManager } from '../../../utilities/request';
import { BasicAdminScreen } from '../basic-admin/basic-admin.screen';
import '../basic-admin/basic-admin.screen.scss';

const mapStateProps = (store: any) => ({
    user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
    toggleMobile: () => dispatch(DetailsActions.toggleOpenMobile()),
});

class WebhooksScreenComponent extends BasicAdminScreen<any, any> {
    public modal$: ManageWebhook;
    public secret$: SecretKey;
    public deleteModal$: ConfirmationModal;

    public state = {
        ...this.state,
        deletableAid: false,
    };

    public getEndpoint(): string {
        return '/webhooks/all';
    }

    public getTitle(): string {
        return 'Manage webhooks';
    }

    public renderContent(): React.ReactElement {
        const webhooks: any = get(this.state, 'response', []) || [];

        return (
            <div className="Contents w-100 border-radius-1">
                {(webhooks || []).map((webhook, index) => {
                    const classes = arrayToClass([
                        'ListItem w-100 p-2',
                        `palette--bgc-neutral-${index % 2 === 1 ? '1' : '2'}`,
                    ]);

                    return (
                        <div key={webhook.aid} className={classes}>
                            <div className="row display-flex align-items-center position-relative">
                                <div className="col-24 col-md-4">
                                    <StatusMarker
                                        tColor="secondary-4"
                                        bgColor="secondary-1"
                                        text={get(webhook, 'aid')}
                                        type="tag"
                                    />
                                </div>
                                <div className="col-24 col-md-6">
                                    {(get(webhook, 'types') || []).map((item) => (
                                        <StatusMarker
                                            key={item}
                                            tColor="secondary-4"
                                            bgColor="secondary-1"
                                            text={item.replace(/_/gi, ' ')}
                                            type="tag"
                                        />
                                    ))}
                                </div>
                                <div className="col-24 col-md-10">
                                    <p className="m-0 palette--c-neutral-6">{get(webhook, 'url')}</p>
                                </div>
                                <div className="position-absolute absolute-top-0 absolute-right-0 h-0 h-md-100 display-flex align-items-center justify-content-center">
                                    <HoverableButton
                                        icon="vpn_key"
                                        colorType="transparent-grey"
                                        className="border-radius-1"
                                        onClick={() => this.showKey(get(webhook, 'secret'))}
                                    />
                                    <HoverableButton
                                        colorType="transparent-green"
                                        className="border-radius-1 mx-1"
                                        onClick={() => this.openModal(webhook)}
                                        icon="edit"
                                    />
                                    <HoverableButton
                                        icon="delete"
                                        colorType="delete"
                                        className="border-radius-1"
                                        onClick={() => this.handleDeleteOnFE(get(webhook, 'aid'))}
                                    />
                                </div>
                            </div>

                            <SecretKey ref={(ref: any) => (this.secret$ = ref)} />
                            {this.state?.selected && this.renderModal()}
                            <ConfirmationModal
                                title="Are you sure you want to delete this webhook?"
                                onClick={() => this.handleDelete()}
                                close={() => this.deleteModal$.close()}
                                ref={(ref: any) => (this.deleteModal$ = ref)}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    public showKey(key) {
        this.secret$.open(key);
    }

    public handleDeleteOnFE(webhook) {
        this.setState({ deletableAid: webhook });
        this.deleteModal$.open();
    }

    public renderModal(): React.ReactElement {
        return <ManageWebhook onClose={(changed) => this.handleClose(changed)} webhook={this.state?.selected} />;
    }

    public handleClose(isChanged) {
        this.setState({ selected: false });
        this.props?.router?.navigate({
            pathname: `/admin/webhooks`,
            search: window.location.search,
        });

        if (isChanged) {
            debounce(() => this.fetch(), 600)();
        }
    }

    public openModal(webhook) {
        this.setState({ selected: webhook });
        this.props?.router?.navigate({
            pathname: `/admin/webhooks/${webhook.aid}`,
            search: window.location.search,
        });
    }

    public async handleDelete(): Promise<any> {
        try {
            await RequestManager.delete(`/webhooks/${this.state.deletableAid}`);

            toast.success('Successfully deleted webhook.');
            this.fetch();
        } catch (err) {
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
        }
    }
}

export const WebhooksScreen: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    ManagesLayout(WebhooksScreenComponent)
);
