import { connect } from 'react-redux';
import { LocalStorage } from '../../utilities/local-storage';
import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { AirportCard } from '../../components/cards/airport/airport.card';
import { ToastMessages } from '../../components/notifications/toast-messages';
import { RequestManager } from '../../utilities/request';

const mapStateProps = (store: any) => ({
	lastSearched: store.userDetails.lastSearched,
});

const mapDispatchProps = (dispatch: any) => ({});

class RecommendedAirportsComponent extends React.Component<any, any> {
	public state = {
		recommendedAirports: [],
		loading: false,
		error: false,
	};

	public componentDidMount() {
		this.getThreeAirports();
	}

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (this.props.lastSearched !== prevProps.lastSearched) {
			this.getThreeAirports();
		}
	}

	public async getThreeAirports() {
		this.setState({ loading: true });
		const lastSearched = this.props.lastSearched || [];

		if (lastSearched.length > 0) {
			await this.updateLastSearch();
		} else {
			await this.updateNearbyAirports();
		}

		if (get(this.state, 'recommendedAirports').length === 0) {
			this.getRandomAirports();
		}
		this.setState({ loading: false });
	}

	public async getRandomAirports() {
		try {
			const response = await RequestManager.get(
				'/airports/search/random',
				{ size: 3 }
			);

			if (!response) {
				throw new Error('missing.airport');
			}

			this.setState({
				recommendedAirports: response.content,
				type: 'Airports',
			});
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}

	public async updateLastSearch() {
		try {
			const lastSearchedAids = LocalStorage.getItem('lastSearched') || [];
			const airports: any[] = [];

			for (const aid of lastSearchedAids) {
				const response = await RequestManager.get(`/airports/${aid}`);

				if (!response) {
					throw new Error('missing.airport');
				}

				airports.push(response);
			}

			this.setState({
				recommendedAirports: airports,
				type: 'last searched',
			});
		} catch (e) {
			// silence is golden
		}
	}

	public async updateNearbyAirports() {
		try {
			const position = await this.getPosition();

			const result = await RequestManager.get(
				'/airports/search?includeArchived=true',
				{
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
					size: 3,
				}
			);

			if (!result || !result.content) {
				throw new Error('something.went.wrong');
			}

			this.setState({
				recommendedAirports: result.content,
				type: 'nearby airports',
			});
		} catch (e) {
			// silence is golden
		}
	}

	public getPosition(): Promise<any> {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(position: any) => resolve(position),
				(err) => reject(err)
			);
		});
	}

	public render(): React.ReactElement {
		return (
			<div className='LastSearchedContainer w-100 mt-8'>
				{this.state.loading}
				<h6 className='text-uppercase palette--c-neutral-1 mb-4'>
					{get(this.state, 'type', 'last searched')}
				</h6>

				{(get(this.state, 'recommendedAirports', false) || []).map(
					(item: any, index: number) => (
						<AirportCard
							key={`${item.aid}-${index}`}
							data={item}
							className='mb-4 elevation-1'
							onClick={() =>
								this.props.onClick &&
								this.props.onClick(item.aid)
							}
							enableIcon={get(this.props.config, 'wide')}
						/>
					)
				)}
			</div>
		);
	}
}

export const RecommendedAirports: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(RecommendedAirportsComponent);
