export const validators = {
	phone: /^([\+{1}|0{2}][0-9\s\(\),]{7,})$/g,
	email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi,
	web: /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi,
	aftn: /^[A-Z]{8}$/,
	sita: /^[A-Z, 0-9]{7}$/,
	address: /([A-Z]|[0-9])/gi,
};

export const validationErrors = {
	phone: 'Please use only plus sign (+) and numbers.',
	fax: 'Please use only plus sign (+) and numbers.',
	email: 'Please enter a valid email address.',
	web: 'Invalid website URL.',
	aftn: 'AFTN should include 8 letters',
	sita: 'SITA should include 7 letters or numbers',
	address: 'Address should include latin letters and numbers.',
};

export const validate = (value, type) => {
	if (!(value || '').match(validators[type])) {
		return false;
	} else {
		return true;
	}
};
