import { get } from 'lodash';
import * as React from 'react';
import { connectNLP } from '../../../../utilities/connect-navigate';
import { arrayToClass, isInQuery, replaceQuery } from '../../../../utilities/helper-fuctions';
import { HoverableButton } from '../../../ui-components/hoverable-buttons/hoverable-buttons';
import { StatusMarker } from '../../../ui-components/statusmarker/status-marker';

class AuthorityCardComponent extends React.Component<any, any> {
    public state = {
        crew: false,
        passengers: false,
        flights: false,
    };

    public render(): React.ReactElement {
        const authority = get(this.props, 'authority');

        const classNames = arrayToClass([
            'AuthorityCard w-100 palette--bc-neutral-4 border-radius-1 mb-2',
            this.props.className ? this.props.className : '',
        ]);

        return (
            <div className={classNames}>
                <div className={`${this.props.isDetails ? 'mb-2' : 'p-2'}`}>
                    {!!this.props.isDetails && (
                        <h3 className="palette--c-neutral-6 flex-fill">{get(authority, 'name')}</h3>
                    )}
                    <div className="display-flex mb-2">
                        <div className="display-flex align-items-center flex-fill">
                            <StatusMarker
                                tColor="secondary-4"
                                bgColor="secondary-1"
                                text={get(authority, 'aid')}
                                type="tag"
                            />
                            {!!this.props.isDetails && (
                                <div className="display-flex">
                                    <StatusMarker
                                        tColor="secondary-4"
                                        bgColor="secondary-1"
                                        text={get(authority, 'type', '').replace(/_/g, ' ')}
                                        type="tag"
                                        classNames="mx-2 py-9"
                                    />
                                    <StatusMarker
                                        tColor="secondary-4"
                                        bgColor="secondary-1"
                                        text={get(authority, 'kind')}
                                        type="tag"
                                    />
                                </div>
                            )}
                        </div>
                        {this.props.isDetails && (
                            <HoverableButton
                                disabled={false}
                                className="border-radius-1 ml-2"
                                colorType="avio-green"
                                titleLeft="Edit"
                                icon="rate_review"
                                onClick={() => this.handleEditOpen()}
                            />
                        )}
                    </div>
                    {!this.props.isDetails && (
                        <div className="w-100 display-flex align-items-center">
                            <h4 className="palette--c-neutral-6 m-0 flex-fill">{get(authority, 'name')}</h4>
                            <StatusMarker
                                tColor="secondary-4"
                                bgColor="secondary-1"
                                text={get(authority, 'type', '').replace(/_/g, ' ')}
                                classNames="mr-2"
                            />
                            <StatusMarker
                                tColor="secondary-4"
                                bgColor="secondary-1"
                                text={get(authority, 'kind')}
                                classNames="mr-2"
                            />
                        </div>
                    )}
                    {this.props.isDetails && get(authority, 'type') === 'CUSTOMS' && this.renderCustoms()}
                </div>

                {!this.props.isDetails && (
                    <div
                        className="FooterButton w-100 display-flex justify-content-center align-items-center p-4 border-top-1 palette--bc-neutral-4 pointer"
                        onClick={() => this.handleClick()}
                    >
                        <p className="m-0 palette--c-primary-4 mr-2">See authority details</p>
                        <p className="fas fa-arrow-right m-0 palette--c-primary-4" />
                    </div>
                )}
            </div>
        );
    }

    public renderCustoms() {
        const services = get(this.props, 'authority.customsDetails.services');
        const visaProcessed = get(this.props, 'authority.customsDetails.visaProcessed');

        return (
            <div className="w-100 mt-4">
                <h5 className="palette--c-neutral-5 text-uppercase my-2">services</h5>
                {!(services || []).length && this.renderEmpty('services')}
                {!!(services || []).length && this.renderLists(services)}
                <h5 className="palette--c-neutral-5 text-uppercase my-2">visa processed</h5>
                {!(visaProcessed || []).length && this.renderEmpty('Visa information')}
                {!!(visaProcessed || []).length && this.renderLists(visaProcessed)}
                {/* {this.renderAllowedValues('Crew', 'crew')}
                {this.renderAllowedValues('Passengers', 'passengers')}
                {this.renderAllowedValues('Flights', 'flights')} */}
            </div>
        );
    }

    public renderAllowedValues(field, stateName) {
        const countryValues = get(this.props.authority.customsDetails, `allow${field}FromCountries`);
        const regionValues = get(this.props.authority.customsDetails, `allow${field}FromRegions`);
        const state = get(this.state, stateName);

        if (!countryValues && !regionValues) {
            return this.renderEmpty(stateName);
        }

        return (
            <div className="w-100">
                <div className="display-flex align-items-center" onClick={() => this.setState({ [stateName]: !state })}>
                    <h5 className="palette--c-neutral-5 text-uppercase my-2">{field} can be cleared from</h5>
                    <span className="palette--c-primary-4 material-icons ml-2">
                        {state ? 'expand_more' : 'chevron_right'}
                    </span>
                </div>

                {!!regionValues && !!state && (
                    <div className="display-inline-block">
                        <div className="border-1 border-radius-1 palette--bc-neutral-5 p-1 mr-2 mb-2">
                            <p className="palette--c-neutral-5 m-0">WorldWide</p>
                        </div>
                    </div>
                )}
                {!regionValues && !!state && (
                    <div className="display-flex flex-wrap align-items-end">
                        {countryValues.map((item) => (
                            <div className="border-1 border-radius-1 palette--bc-neutral-5 p-1 mr-2 mb-2" key={item}>
                                <p className="palette--c-neutral-5 m-0">{get(item, 'name').toUpperCase()}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    public renderEmpty(type) {
        return (
            <div className="w-100">
                <p className="palette--c-neutral-5">No {type} listed.</p>
            </div>
        );
    }

    public renderLists(services) {
        return (
            <div className="display-flex">
                {services.map((item) => {
                    return (
                        <div className="border-1 border-radius-1 palette--bc-neutral-5 p-1 mr-2 mb-2" key={item}>
                            <p className="palette--c-neutral-5 m-0">{item.replace(/_/g, ' ')}</p>
                        </div>
                    );
                })}
            </div>
        );
    }

    public handleClick() {
        if (!this.props.isDetails) {
            const utmSource = isInQuery('utm_source');
            this.props?.router?.navigate({
                pathname: `${window.location.pathname}/${get(this.props.authority, 'aid')}`,
                search: utmSource,
            });
        }

        if (this.props.edit) {
            this.props.edit();
        }
    }

    public handleEditOpen() {
        const newQuery = replaceQuery({
            edit: true,
        });

        this.props?.router?.navigate({ search: newQuery });
    }
}

export const AuthorityCard: any = connectNLP(AuthorityCardComponent);
