import * as React from 'react';
import { debounce } from '../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../utilities/request';
import { Select } from './select';

export class SearchableSelect extends React.Component<any, any> {
    public select$: any;
    public isMounted = false;

    public state = {
        content: [],
        loading: false,
        error: false,

        options: false,
    };

    public componentDidMount() {
        this.isMounted = true;

        if (this.props?.isFirst) {
            this.fetch({ [this.props.param || 'query']: this.getOriginalSearchValue() });
        } else {
            const value = this.props?.field?.newValue;
            const current = this.props?.isMulti ? value : value ? [value] : [];
            this.initializeOptions(current || []);
        }
    }

    public componentWillUnmount() {
        this.isMounted = false;
    }

    public getOriginalSearchValue() {
        const current = this.props?.field?.newValue;

        if (!current) {
            return 'a';
        }

        return this.props?.qeryValue ? current[this.props?.qeryValue] : current;
    }

    public async fetch(search) {
        try {
            if (this.isMounted) this.setState({ loading: true });

            const response = await RequestManager.get(this.props.endpoint, { ...search, size: 20 });

            if (!response) {
                throw new Error();
            }

            const content = response.content || response;
            if (this.isMounted) this.initializeOptions(content);
        } catch (err) {
            if (this.isMounted) this.initializeOptions(null, true);
        }
    }

    public initializeOptions(response, error = false) {
        if (!!error) {
            this.setState({ error: true, loading: false, options: [] });
        }

        const content = response || [];

        const formatOptions = content.map((element) => ({
            value: this.props.value ? element?.[this.props.value] : element,
            title: element?.[this.props.title],
        }));

        if (this.props.notNull) {
            this.setState({ options: formatOptions, error: false, loading: false });
        } else {
            const nullOpt = { value: null, title: 'Not specified' };

            this.setState({ options: [...formatOptions, nullOpt], error: false, loading: false });
        }
    }

    public render() {
        if (!this.state?.options) {
            return null;
        }

        return (
            <Select
                {...this.props}
                onChange={(value) => this.handleChange(value)}
                loading={this.state?.loading}
                error={this.state?.error}
                options={this.state?.options}
                searchable={true}
                fetch={(value) => this.fetch({ [this.props.param || 'query']: value })}
            />
        );
    }

    public handleChange(value) {
        debounce(() => this.fetch({ [this.props.param || 'query']: value || 'a' }))();
    }
}
