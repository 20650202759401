import * as React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Footer } from '../../components/footer/footer';
import { MainHeaderButtons } from '../../components/header/main-header-buttons/main-header-buttons';
import { arrayToClass, setTitleAndMeta } from '../../utilities/helper-fuctions';
import { MobileLinks } from '../../utilities/mobile-links/mobile-links';
import './landing.layout.scss';

export const LandingLayout = (Component) => (props) => {
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const layoutClasses = arrayToClass([
		'LandingLayout',
		'default',
		'palette--bgc-neutral-3',
		'display-flex flex-column',
	]);

	const containerClasses = arrayToClass([
		'Container',
		'flex-fill',
		'p-2 p-md-0',
	]);

	const footerClasses = arrayToClass(['w-100']);

	React.useEffect(() => {
		setTitleAndMeta(
			'aviowiki',
			'FREE aviation data for everybody. Details about airports, runways, authorities and FBOs.'
		);
	}, []);

	const renderLogo = (): any => {
		return (
			<Link to='/' className='flex-fill'>
				<div className='w-100 Logo display-flex justify-content-start'>
					<img
						src='https://aviowiki.com/aviowiki_logo_white/'
						alt='logo'
						className='display-block'
						height={54}
					/>
				</div>
			</Link>
		);
	};

	return (
		<div className={layoutClasses}>
			<MobileLinks />
			<div className='LandingLogoContainer w-100 display-block p-3'>
				<div className='display-flex'>
					{renderLogo()}
					<MainHeaderButtons isAdd={true} hideFormats={true} />
				</div>
			</div>

			<div className={containerClasses}>
				<Component {...props} router={{ navigate, params, location }} />
			</div>

			<div className={footerClasses}>
				<Footer />
			</div>
		</div>
	);
};
