import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { LocalstorageActions } from '../../actions/localstorage.actions';
import { UserDetailsActions } from '../../actions/user-details.actions';
import { dimConvert } from '../../utilities/dim.transform';
import { Dropdown } from '../ui-components/dropdown/dropdown';
import { Form } from '../ui-components/form/form';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';
import { Switch } from '../ui-components/inputs/checkboxes/switch-checkbox/switch-checkbox';
import { Input } from '../ui-components/inputs/inputs/input';
import { formatOptions } from '../ui-components/inputs/select/formatter';
import { Select } from '../ui-components/inputs/select/select';
import './filters.component.scss';

const defaultOptions: any = [{ value: 'km' }, { value: 'nm' }, { value: 'mi' }];

const mapStateProps = (store: any) => ({
	mLength: store.measures.length,
	measure: store.localStorage.nearbyMeasure,
	radius: store.localStorage.radius,
	heliports: store.userDetails.heliports,
	runway: store.userDetails.runway,
});

const mapDispatchProps = (dispatch: any) => ({
	setMeasure: (measure: any) =>
		dispatch(LocalstorageActions.setMeasure(measure)),
	setRadius: (radius: any) => dispatch(LocalstorageActions.setRadius(radius)),
	saveUserDetails: (data: any) =>
		dispatch(UserDetailsActions.saveUserDetails(data)),
});

class NearbyFiltersComponent extends React.Component<any, any> {
	public dropdown$: Dropdown;

	public state = {
		...this.state,
		isActive: false,
	};

	public form = new Form(
		{
			radius: this.props.radius || 100,
			measure: this.props.measure,
			heliports: this.props.heliports,
			runway: this.props.runway,
			isActive: this.props.runway ? true : false,
		},
		() => this.handleFormChange()
	);

	public handleFormChange() {
		const form: any = this.form.generateJSON();
		const radius = form.radius;
		const measure = form.measure.value;
		const runway = form.runway
			? dimConvert(
					parseInt(get(form, 'runway', 1500)),
					get(this.props.mLength, 'targetDimension', 'm'),
					'm',
					0
			  )
			: null;
		const heliports = form.heliports;

		if (this.props.saveUserDetails) {
			return this.props.saveUserDetails({
				radius: radius,
				measure: measure,
				runway: runway,
				heliports: heliports,
			});
		}
	}

	public handleActiveChange() {
		const value = this.form.getValue('isActive');

		this.setState({ isActive: value });
		const defaultValue = dimConvert(
			1500,
			'm',
			get(this.props.mLength, 'targetDimension'),
			0
		);
		this.form.setValue('runway', value ? defaultValue : null);
	}

	public render(): React.ReactElement {
		return (
			<Dropdown
				direction='right'
				ref={(ref: any) => (this.dropdown$ = ref)}
				trigger={() => this.renderFilterButton()}
				dropdown={() => this.renderFilters()}
				backdropClasses='EditBackdrop palette--bgc-neutral-transparent'
				className='FilterDropdown'
			/>
		);
	}

	public renderFilterButton(): React.ReactElement {
		return (
			<HoverableButton
				className='border-radius-1'
				colorType='transparent-grey'
				onClick={() => this.dropdown$.open()}
				icon='filter_list'
			/>
		);
	}

	public renderFilters(): React.ReactElement {
		return (
			<form className='palette--bgc-neutral-1 border-radius-1 elevation-1'>
				{this.props.showRadius && (
					<div className='display-flex align-items-center p-2'>
						<p className='palette--c-secondary-4 m-0 flex-fill'>
							Radius:
						</p>
						<Input
							field={this.form.getField('radius')}
							classes='RadiusInput mr-2'
							inputType='number'
						/>
						<Select
							classes='RadiusInput'
							field={this.form.getField('measure')}
							options={formatOptions(defaultOptions, false)}
						/>
					</div>
				)}

				<div className='display-flex align-items-center p-2'>
					<p className='palette--c-secondary-4 m-0 flex-fill'>
						Show heliports:
					</p>
					<Switch field={this.form.getField('heliports')} />
				</div>

				<div className='display-flex align-items-center p-2'>
					<p className='palette--c-secondary-4 m-0 flex-fill'>
						Minimum runway length:
					</p>
					<Switch field={this.form.getField('isActive')} />
				</div>
				{this.state.isActive && (
					<div className='px-2 pb-2 w-100 display-flex justify-content-end align-items-center'>
						<Input
							inputType='number'
							field={this.form.getField('runway')}
							classes='RadiusInput mr-2'
						/>
						<p className='palette--c-secondary-4 m-0 mx-1'>
							{get(this.props.mLength, 'targetDimension')}
						</p>
					</div>
				)}
			</form>
		);
	}
}

export const NearbyFilters: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(NearbyFiltersComponent);
