import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { arrayToClass, redirectToLogin } from '../../utilities/helper-fuctions';
import { RequestManager } from '../../utilities/request';
import { ErrorPresenter } from '../notifications/error-presenter';
import { ToastMessages } from '../notifications/toast-messages';
import { Spinner } from '../ui-components/spinner/spinner';

export class Widget<P, S> extends React.Component<P & any, S & any> {
	public isMounted = false;
	public state: any = {
		loading: false,
		error: false,
		response: false,
		render: false,
	};

	public getEndpoint(): any {
		return null;
	}

	public getQuery(): any {
		return {};
	}

	public async componentDidMount() {
		this.isMounted = true;
		await this.fetch();
	}

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	): void {
		if (prevProps.aid !== this.props.aid && this.props.aid) {
			this.fetch();
		}

		if (this.props.refresh !== prevProps.refresh && !!this.props.refresh) {
			this.fetch();
			this.props.setSuccess();
		}
	}

	public componentWillUnmount() {
		this.isMounted = false;
	}

	public setStates(states) {
		if (this.isMounted) {
			this.setState(states);
		}
	}

	public async fetch() {
		if (!this.getEndpoint()) {
			return this.setStates({ render: true });
		}

		try {
			this.setStates({ loading: true });
			const response = await RequestManager.get(
				this.getEndpoint(),
				this.getQuery()
			);
			this.formatData(response);
			this.setStates({ response: response, error: null });
		} catch (err) {
			this.setStates({
				error:
					get(err, 'response.data.message', null) || err || 'Error',
			});
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			redirectToLogin(err);
		} finally {
			this.setStates({ loading: false });
		}
	}

	public formatData(response) {
		return;
	}

	public render(): React.ReactElement {
		const classes = arrayToClass(['Widget w-100 h-100']);

		return (
			<div className={classes}>
				{this.state.render && this.renderContent()}
				{this.state.loading && this.renderLoading()}
				{this.state.error &&
					!this.state.response &&
					!this.state.render &&
					this.renderError()}
				{this.state.response &&
					!this.state.loading &&
					!this.state.error &&
					this.renderContent()}
			</div>
		);
	}

	public renderLoading(): React.ReactElement {
		return (
			<div className='w-100 py-6 dispaly-flex justify-content-center align-items-center'>
				<Spinner size='large' />
			</div>
		);
	}

	public renderError(): React.ReactElement {
		return <ErrorPresenter error={this.state.error} />;
	}

	public renderContent(): any {
		return null;
	}
}
