import * as React from 'react';

interface ErrorProps {
    error: any;
}

export class ErrorPresenter extends React.Component<ErrorProps, any> {
    public render(): React.ReactElement {
        return <div className="w-100 display-flex justify-content-center align-items-center py-6">
            <div className="palette--c-neutral-5">
                {/*{this.props.error}*/} error
            </div>
        </div>;
    }
}
