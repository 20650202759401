import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { RequestManager } from '../../../utilities/request';
import { ToastMessages } from '../../notifications/toast-messages';
import { Form } from '../../ui-components/form/form';
import { FormButtons } from '../../ui-components/hoverable-buttons/form-modal-buttons/form-buttons';
import { HoverableButton } from '../../ui-components/hoverable-buttons/hoverable-buttons';
import { Input } from '../../ui-components/inputs/inputs/input';
import { TextInput } from '../../ui-components/inputs/inputs/text-input';
import { Select } from '../../ui-components/inputs/select/select';
import { WidgetModal } from '../../widgets/widget-modal';

export class IssueNewToken extends WidgetModal<any, any> {
	public state = {
		...this.state,
		buttonDisabled: true,
		token: null,
		types: [],
	};

	public form = new Form(
		{
			type: '',
			validity: '',
			comment: '',
		},
		(changed) => this.setState({ buttonDisabled: !changed })
	);

	public componentDidMount() {
		super.componentDidMount();

		this.fetchTokenTypes();
	}

	public async fetchTokenTypes(): Promise<any> {
		try {
			const response = await RequestManager.get(
				'/management/authTokens/types'
			);

			if (!response) {
				throw new Error('There was an error');
			}

			this.setState({
				types: response.map((item) => ({
					value: item,
					title: item.replace(/_/g, ' '),
				})),
			});
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}

	public onClose() {
		if (this.props.onClose) {
			this.props.onClose(!!this.props.token);
		}

		this.modal$.close();
	}

	public getTitle(): any {
		return 'Add new token';
	}

	public renderForm(): React.ReactElement {
		return (
			<div className='p-4'>
				<form>
					<div className='display-flex'>
						<Select
							field={this.form.getField('type')}
							options={this.state.types || []}
							classes='py-2 limit-w'
							labelInfos={{
								label: 'Type',
							}}
						/>

						<Input
							field={this.form.getField('validity')}
							inputType='number'
							labelInfos={{ label: 'Validity' }}
							classes='py-2 limit-w left-m'
						/>
					</div>

					<TextInput
						element='textarea'
						rows={3}
						field={this.form.getField('comment')}
						classes='py-2'
						textType='sentences'
						labelInfos={{ label: 'Comment' }}
					/>
				</form>

				{this.state.token && (
					<div className='w-100 pt-3'>
						<h5 className='palette--c-neutral-5 fw-bold'>
							New token:
						</h5>

						<div className='border-radius-1 palette--bc-neutral-4 border-1 w-100 display-flex overflow-hidden'>
							<div className='flex-fill py-1 px-2'>
								<p
									id='Token'
									className='palette--c-neutral-6 m-0'
								>
									{get(this.state, 'token')}
								</p>
							</div>
							<HoverableButton
								title='Copy!'
								colorType='avio-green'
								onClick={() => this.handleCopy()}
							/>
						</div>
					</div>
				)}
			</div>
		);
	}

	public renderFooter(): React.ReactElement {
		return (
			<FormButtons
				saveDisabled={this.state?.buttonDisabled}
				onClose={() => this.onClose()}
				onSave={() => this.handleSave()}
			/>
		);
	}

	public handleCopy() {
		const range = document.createRange();
		const element: any = document.getElementById('Token');
		range.selectNode(element);
		const selection: any = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);
		document.execCommand('copy');
		selection.removeAllRanges();
		toast.success('Token copied to clipboard!', { theme: 'light' });
	}

	public async handleSave(): Promise<any> {
		try {
			const form: any = this.form.generateJSON();

			const response = await RequestManager.post(
				`/users/${get(this.props, 'aid')}/authTokens`,
				{ comment: form.comment },
				{ type: form.type, validity: form.validity }
			);

			if (!response) {
				throw new Error('There was an error');
			}

			this.setState({ token: response });
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}
}
