import * as React from 'react';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router';
import { ConfigActions } from '../../actions/config.actions';
import { DetailsActions } from '../../actions/details.actions';
import { UserActions } from '../../actions/user.actions';

interface UnauthorizedRouteProps extends RouteProps {
    layout: any;
    config: any;

    [key: string]: any;
}

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
    setConfig: (config: any) => dispatch(ConfigActions.setConfig(config)),
    setCurrentNavigationPath: (path) => dispatch(DetailsActions.setCurrentNavigationPath(path)),
    tryRedirectIfAuthenticated: () => dispatch(UserActions.tryRedirectIfAuthenticated()),
});

class UnauthorizedRouteComponent extends React.Component<UnauthorizedRouteProps, any> {
    public componentDidMount(): void {
        this.props.setConfig(this.props.config);
        this.props.setCurrentNavigationPath(this.props.currentNavigationPath);
        this.props.tryRedirectIfAuthenticated();
    }

    public componentDidUpdate(
        prevProps: Readonly<UnauthorizedRouteProps>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {
        this.props.setConfig(this.props.config);

        if (this.props.path !== prevProps.path) {
            this.props.tryRedirectIfAuthenticated();
        }
    }

    public render(): React.ReactNode {
        return React.createElement(this.props.component, this.props);
    }
}

export const UnauthorizedRoute: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    UnauthorizedRouteComponent
);
