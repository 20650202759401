import * as React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

type PhoneNumberProps = {
	number: string;
	className?: string;
};

export const PhoneNumber = ({
	className = '',
	number,
}: PhoneNumberProps): React.ReactElement => {
	const formattedNumber = formatPhoneNumberIntl(number);

	return <p className={className}>{formattedNumber}</p>;
};
