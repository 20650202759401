import { arrayToClass } from '../../../utilities/helper-fuctions';
import { get } from 'lodash';
import * as React from 'react';
import { RequestManager } from '../../../utilities/request';
import { Spinner } from '../../ui-components/spinner/spinner';

export class TooltipLoader extends React.Component<any, any> {
    public state = {
        loading: false,
        error: false,
        notes: false,
        runways: false,
    };

    public async componentDidMount(): Promise<any> {
        await this.fetch();
    }

    public async fetch(): Promise<any> {
        this.setState({ loading: true });
        await this.fetchNotes();
        await this.fetchRunways();
        this.setState({ loading: false });
    }

    public async fetchNotes(): Promise<any> {
        const aid = get(this.props, 'airport.aid');
        try {
            const response = await RequestManager.get(`/airports/${aid}/airportOperationalNotes/all`);

            if (!response) {
                throw new Error('error');
            }

            this.setState({ notes: response });
        } catch (err) {
            this.setState({ error: err });
        }
    }

    public async fetchRunways(): Promise<any> {
        const aid = get(this.props, 'airport.aid');
        try {
            const response = await RequestManager.get(`/airports/${aid}/runways/all`);

            if (!response) {
                throw new Error('error');
            }

            const runways = response.map((runway) => {
                return {
                    identifier: get(runway, 'identifier'),
                    lda: get(runway, 'lda'),
                };
            });

            this.setState({ runways: runways });
        } catch (err) {
            this.setState({ error: err });
        }
    }

    public render(): React.ReactElement {
        return (
            <div className="Tooltip w-100">
                {this.state.loading &&
                    !this.state.error &&
                    !this.state.notes &&
                    !this.state.runways &&
                    this.renderLoading()}
                {!this.state.loading &&
                    this.state.error &&
                    (!this.state.notes || !this.state.runways) &&
                    this.renderLoading()}
                {!this.state.loading && (this.state.notes || this.state.runways) && this.renderContent()}
            </div>
        );
    }

    public renderLoading(): React.ReactElement {
        return (
            <div className="w-100">
                <Spinner size="medium" isLight={true} />
            </div>
        );
    }

    public renderError(): React.ReactElement {
        return (
            <div className="w-100">
                <p className="palette--c-neutral-6">There is no content.</p>
            </div>
        );
    }

    public renderContent(): React.ReactElement {
        const airport: any = get(this.props, 'airport');
        const runways: any = get(this.state, 'runways', []) || [];
        const notes: any = get(this.state, 'notes', []) || [];

        return (
            <div className="w-100">
                <div
                    className={arrayToClass([
                        runways.length ? 'border-bottom-1 palette--bc-neutral-6' : '',
                        'w-100 p-1',
                    ])}
                >
                    <h5 className="palette--c-neutral-6">{get(airport, 'name')}</h5>
                    <div className="display-flex">
                        {get(airport, 'icao') && (
                            <span className="palette--c-neutral-6 p-0">{get(airport, 'icao')}</span>
                        )}
                        {get(airport, 'icao') && get(airport, 'iata') && (
                            <span className="palette--c-neutral-6 mx-1">/</span>
                        )}
                        {get(airport, 'iata') && (
                            <span className="palette--c-neutral-6 p-0">{get(airport, 'iata')}</span>
                        )}
                    </div>
                </div>
                {!!runways.length && (
                    <div
                        className={arrayToClass([
                            notes.length ? 'border-bottom-1 palette--bc-neutral-6' : '',
                            'w-100 p-1',
                        ])}
                    >
                        {(runways || []).map((runway) => {
                            return (
                                <div key={get(runway, 'identifier')} className="row">
                                    <span className="palette--c-neutral-6 col-12">{runway.identifier}:</span>
                                    <span className="palette--c-neutral-6 col-12">
                                        {runway.lda}&nbsp;{runway.lda ? 'm' : '-'}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                )}
                {!!notes.length && (
                    <div className="w-100 p-1">
                        {(notes || []).map((note, index) => {
                            const classes = arrayToClass(['row', index - 1 === notes.length ? 'mb-0' : 'mb-1']);

                            return (
                                <div key={get(note, 'aid')} className={classes}>
                                    <span className="palette--c-neutral-6 col-24">
                                        {(note.notes || '').substring(0, 40)}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}
