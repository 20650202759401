import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../actions/details.actions';
import { RegionForm } from '../../../components/admin/region-form';
import { MapDrawing } from '../../../components/map/map-drawing/map-drawing';
import { HoverableButton } from '../../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { StatusMarker } from '../../../components/ui-components/statusmarker/status-marker';
import { ManagesLayout } from '../../../layouts/manages/manages.layout';
import { arrayToClass, randomId } from '../../../utilities/helper-fuctions';
import { BasicAdminScreen } from '../basic-admin/basic-admin.screen';
import '../basic-admin/basic-admin.screen.scss';

const mapStateProps = (store: any) => ({
    user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
    toggleMobile: () => dispatch(DetailsActions.toggleOpenMobile()),
});

class RegionsScreenComponent extends BasicAdminScreen<any, any> {
    public modal$;

    public state = {
        ...this.state,
        multipolygon: [],
        isMapOpen: false,
    };

    public getEndpoint(): string {
        return '/regions/search';
    }

    public getTitle(): string {
        return 'Manage regions';
    }

    public renderExtra() {
        return (
            <HoverableButton
                icon="add"
                title="New Region"
                colorType="cancel"
                className="border-radius-1 ml-2 elevation-1"
                onClick={(e) => this.openModal(e, false)}
            />
        );
    }

    public renderContent(): React.ReactElement {
        const regions: any = get(this.state, 'response', []) || [];

        return (
            <div className="Contents w-100 border-radius-1">
                {(regions || []).map((item, index) => {
                    const classes = arrayToClass([
                        'ListItem w-100 p-2 pointer',
                        `palette--bgc-neutral-${index % 2 === 1 ? '1' : '2'}`,
                    ]);

                    return (
                        <div key={item.aid} className={classes} onClick={(e) => this.openModal(e, item)}>
                            <div className="row display-flex align-items-center">
                                <div className="col-24 col-md-4">
                                    <StatusMarker
                                        tColor="secondary-4"
                                        bgColor="secondary-1"
                                        text={get(item, 'aid')}
                                        type="tag"
                                    />
                                </div>

                                <p className="overflow-hidden col-24 col-md-6 m-0 palette--c-neutral-6">{item.name}</p>
                                {this.renderCountries(item.countries)}
                                {this.renderPolygons(item.multipolygon)}
                            </div>
                        </div>
                    );
                })}
                {this.state?.isMapOpen && (
                    <MapDrawing isShowOnly={true} isMulti={true} title="Polygons" polygons={this.state.multipolygon} />
                )}
            </div>
        );
    }

    public renderCountries(countries) {
        if (!(countries || []).length) {
            return <div className="col-24 col-md-10" />;
        }

        return (
            <div className="col-24 col-md-10 display-flex flex-wrap">
                {countries.map((country) => (
                    <StatusMarker
                        key={randomId()}
                        tColor="secondary-4"
                        bgColor="secondary-1"
                        classNames="mx-9 py-0"
                        text={country.name}
                        type="tag"
                    />
                ))}
            </div>
        );
    }

    public renderPolygons(polygons) {
        if (!(polygons || []).length) {
            return <div className="col-24 col-md-4" />;
        }

        return (
            <div className="col-24 col-md-4">
                <HoverableButton
                    title="Check polygons"
                    id="polygonsShower"
                    colorType="transparent-green"
                    type="button"
                    className="border-radius-1"
                    onClick={() => this.openPolygons(polygons)}
                />
            </div>
        );
    }

    public renderModal(): React.ReactElement {
        const aid = this.props?.router?.params?.aid;

        return (
            <RegionForm
                isEdit={aid !== 'new-region' && !!aid}
                endpoint={`/regions/${aid}`}
                data={this.state.selected}
                aid={aid}
                onClose={() => this.handleModalClose()}
            />
        );
    }

    public handleModalClose() {
        this.setState({ selected: false, isModal: false });
        setTimeout(() => this.fetch(), 600);
    }

    public openModal(event, region) {
        if (event.target.id) {
            return this.openPolygons(region.multipolygon);
        }

        this.setState({ selected: region });
        this.props?.router?.navigate({
            pathname: `/admin/regions/${region ? region.aid : 'new-region'}`,
            search: window.location.search,
        });
        this.setState({ isModal: true });
    }

    public openPolygons(polygons) {
        this.setState({ multipolygon: polygons, isMapOpen: true });
    }
}

export const RegionsScreen: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    ManagesLayout(RegionsScreenComponent)
);
