import { get } from 'lodash';
import * as React from 'react';
import { connectNLP } from '../../../../../utilities/connect-navigate';
import {
	arrayToClass,
	randomId,
	replaceQuery,
} from '../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../utilities/request';
import { ContributeButton } from '../../../../other/contribute-button';
import { EmailShower } from '../../../../other/emial-shower';
import { PhoneNumber } from '../../../../other/phone-number';
import { Tooltip } from '../../../../ui-components/popover/popover';
import { StatusMarker } from '../../../../ui-components/statusmarker/status-marker';
import {
	FUEL_SERVICE_LEVEL,
	HANDLING_SERVICE_LEVEL,
} from '../provider-contribution/constants';
import './provider-cards.scss';

class ProviderCardComponent extends React.Component<any, any> {
	public state = {
		hovered: false,
		logo: false,
	};

	public componentDidMount() {
		this.fetchLogo();
	}

	public async fetchLogo() {
		try {
			const logo = get(this.props.content, 'logo');
			const aid = get(logo, 'aid');

			if (!aid) {
				return;
			}

			const response = await RequestManager.get(
				`/free/files/${aid}/info`
			);

			if (!response) {
				return;
			}

			this.setState({ logo: { ...logo, ...response } });
		} catch (err) {
			return;
		}
	}

	public handleQueryChange() {
		const newQuery = replaceQuery({ edit: true });

		this.props?.router?.navigate({ search: newQuery });
	}

	public render(): React.ReactElement {
		const content = this.props.content;
		const isFeatured = typeof content.featuredOrder === 'number';
		const isDetails = this.props.isDetails;

		const logo = get(this.state, 'logo');

		const webLink = content.website
			? content.website.match(/(http)[s]?/g)
				? content.website
				: `http://${content.website}`
			: null;

		const providerCardIsEmpty =
			!content.phone &&
			!content.website &&
			!content.email &&
			!content.aftn &&
			!content.handlingProvider?.serviceLevel &&
			!(content.paymentMethods || []).length;

		const addressIsEmpty =
			content.streetName ||
			content.locality ||
			content.municipality ||
			content.postalCode ||
			content.country;

		const detailsCardIsEmpty =
			!addressIsEmpty &&
			providerCardIsEmpty &&
			!content.vhf &&
			!content.outOfHoursPhone &&
			!get(content, 'handlingProvider.accountsPhone') &&
			!logo;

		if (isDetails && detailsCardIsEmpty) {
			return (
				<ContributeButton
					openModal={() => this.handleQueryChange()}
					label='Provider'
				/>
			);
		}

		let serviceLevel: any = false;

		if (content?.category === 'HANDLING') {
			serviceLevel =
				HANDLING_SERVICE_LEVEL.find(
					(item) =>
						item.value ===
						get(content.handlingProvider, 'serviceLevel', false)
				) || null;
		}

		if (content?.category === 'FUEL') {
			serviceLevel =
				FUEL_SERVICE_LEVEL.find(
					(item) =>
						item.value ===
						get(content.fuelProvider, 'serviceLevel', false)
				) || null;
		}

		const classes = arrayToClass([
			'ProviderCard w-100 row',
			isDetails ? 'py-3' : 'p-4',
			this.props.classes ? this.props.classes : '',
		]);

		const cardClasses = arrayToClass([
			'CardContent col-24 display-flex flex-column',
			(isFeatured || isDetails) && !!logo ? 'col-md-12' : 'col-24',
		]);

		if (!isDetails && providerCardIsEmpty) {
			return (
				<div className={classes}>
					{serviceLevel && !isDetails && (
						<div className='pb-2'>
							<StatusMarker
								tColor='secondary-4'
								bgColor='secondary-1'
								text={get(serviceLevel, 'title')}
								type='tag'
							/>
						</div>
					)}
					<div className='display-flex w-100 p-7 align-items-center justify-content-center'>
						<p className='palette--c-neutral-5 m-0'>
							Awaiting contact information.
						</p>
					</div>
				</div>
			);
		}

		return (
			<div className={classes}>
				{(isFeatured || !!isDetails) &&
					this.renderLogo(
						logo,
						'LogoMobile display-flex display-md-none'
					)}
				<div className={cardClasses}>
					{serviceLevel && !isDetails && (
						<div className='pb-2'>
							<StatusMarker
								tColor='secondary-4'
								bgColor='secondary-1'
								text={get(serviceLevel, 'title')}
								type='tag'
							/>
						</div>
					)}
					{isDetails && addressIsEmpty && (
						<div className='m-0 pb-2 palette--neutral-5 display-flex'>
							{addressIsEmpty &&
								this.renderPopover('fa-map-pin', 'Address')}
							<div className=''>
								{content.streetName && (
									<p className='palette--c-neutral-6 m-0'>
										{content.streetName}
									</p>
								)}
								{content.locality && (
									<p className='palette--c-neutral-6 m-0'>
										{content.locality}
									</p>
								)}
								<div className='display-flex'>
									{content.municipality && (
										<p className='palette--c-neutral-6 m-0 mr-2'>
											{content.municipality}
										</p>
									)}
									{content.postalCode && (
										<p className='palette--c-neutral-6 m-0'>
											{content.postalCode}
										</p>
									)}
								</div>
								{content.country && (
									<p className='palette--c-neutral-6 m-0'>
										{content.country.name}
									</p>
								)}
							</div>
						</div>
					)}

					{content.website && (
						<div className='m-0 pb-2 palette--neutral-5 display-flex align-items-center'>
							{this.renderPopover('fa-globe-europe', 'Website')}
							<a
								href={webLink}
								target='_blank'
								className='Link WebsiteLink palette--c-blue-2 m-0 text-decoration-none display-inline-block'
							>
								{content.website || '-'}
							</a>
						</div>
					)}

					{content.vhf && isDetails && (
						<div className='m-0 pb-2 palette--neutral-5 display-flex align-items-center'>
							{this.renderPopover('fa-broadcast-tower', 'VHF')}
							<p className='palette--c-neutral-6 m-0'>
								{content.vhf % 1 === 0
									? `${content.vhf}.000`
									: content.vhf}
							</p>
						</div>
					)}

					{content.phone && !isDetails && (
						<div className='m-0 pb-2 palette--neutral-5 display-flex align-items-center'>
							{this.renderPopover('fa-phone-alt', 'Phone')}
							<a
								href={`tel:${content.phone}`}
								className='WebsiteLink Link palette--c-blue-2 m-0 text-decoration-none display-inline-block'
							>
								<PhoneNumber number={content.phone || '-'} />
							</a>
						</div>
					)}

					{content.email && (
						<div className='m-0 pb-2 palette--neutral-5 display-flex align-items-center'>
							{this.renderPopover('fa-envelope', 'Email')}
							<EmailShower email={content.email} />
						</div>
					)}

					{content.aftn && (
						<div className='m-0 pb-2 palette--neutral-5 display-flex align-items-center'>
							{this.renderPopover('fa-satellite-dish', 'AFTN')}
							<p className='palette--c-neutral-6 m-0'>
								{content.aftn || '-'}
							</p>
						</div>
					)}

					{!!(content.paymentMethods || []).length && (
						<div className='m-0 pb-2  palette--neutral-5 display-flex align-items-center'>
							{this.renderPopover(
								'fa-receipt',
								'Payment methods'
							)}
							{this.renderPaymentMethods(content.paymentMethods)}
						</div>
					)}
					{isDetails && (
						<div className='display-flex flex-column'>
							{content.phone && (
								<div className='m-0 pb-2 palette--neutral-5 display-flex align-items-center'>
									{this.renderPopover(
										'fa-phone-alt',
										'Phone'
									)}
									<a
										href={`tel:${content.phone}`}
										className='WebsiteLink Link palette--c-neutral-6 m-0 text-decoration-none'
									>
										<PhoneNumber
											number={content.phone || '-'}
										/>
									</a>
								</div>
							)}
							{content.outOfHoursPhone && (
								<div className='m-0 pb-2 palette--neutral-5 display-flex align-items-center'>
									{this.renderPopover(
										'fa-phone-alt',
										'Out of hours phone number'
									)}
									<a
										href={`tel:${content.outOfHoursPhone}`}
										className='WebsiteLink Link palette--c-neutral-6 m-0 text-decoration-none'
									>
										<PhoneNumber
											number={
												content.outOfHoursPhone || '-'
											}
										/>
									</a>
								</div>
							)}
							{get(content, 'handlingProvider.accountsPhone') && (
								<div className='m-0 pb-2 palette--neutral-5 display-flex align-items-center'>
									{this.renderPopover(
										'fa-phone-alt',
										'Accounts phone number'
									)}
									<a
										href={`tel:${get(
											content,
											'handlingProvider.accountsPhone'
										)}`}
										className='WebsiteLink Link palette--c-neutral-6 m-0 text-decoration-none'
									>
										<PhoneNumber
											number={
												get(
													content,
													'handlingProvider.accountsPhone'
												) || '-'
											}
										/>
									</a>
								</div>
							)}
							{get(content, 'handlingProvider.accountsEmail') && (
								<div className='m-0 pb-2 palette--neutral-5 display-flex align-items-center'>
									{this.renderPopover(
										'fa-envelope',
										'Accounts email'
									)}
									<EmailShower
										email={get(
											content,
											'handlingProvider.accountsEmail'
										)}
									/>
								</div>
							)}
						</div>
					)}
				</div>

				{(isFeatured || !!isDetails) &&
					this.renderLogo(logo, 'Logo display-none display-md-flex')}
			</div>
		);
	}

	public renderLogo(logo, sizeClasses): React.ReactNode {
		if (!logo) {
			return null;
		}

		const url = get(logo, 'url');
		const classes = arrayToClass(['col-24 col-md-12', sizeClasses]);

		return (
			<div className={classes}>
				<img
					src={url}
					alt='provider-logo'
					onError={(event: any) => this.handleImageError(event)}
				/>
			</div>
		);
	}

	private handleImageError(event) {
		event.target.style.display = 'none';
	}

	public renderPopover(icon, title, isPayment: boolean = false) {
		return (
			<Tooltip
				key={randomId()}
				tooltip={this.renderPopoverMessage(title)}
				trigger={
					isPayment
						? this.renderPaymentImages(icon, title)
						: this.renderChildren(icon)
				}
			/>
		);
	}

	public renderChildren(icon) {
		const classNames = arrayToClass([
			'palette--c-neutral-5 fas mr-3 text-center',
			icon,
		]);

		return <div className={classNames} />;
	}

	public renderPopoverMessage(description: any): any {
		return (
			<div className='PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1'>
				<span className='palette--c-neutral-1'>{description}</span>
			</div>
		);
	}

	public renderPaymentMethods(methods: any) {
		if (!methods) {
			return null;
		}

		return (
			<div className='display-flex'>
				{methods.map((method: string) => {
					try {
						const src = require(`../../../../../assets/card-types/${method}.png`);
						const title = method.replace(/_/gi, ' ');

						return this.renderPopover(src, title, true);
					} catch (err) {
						return;
					}
				})}
			</div>
		);
	}

	public renderPaymentImages(image, title) {
		return (
			<div>
				<img src={image} alt={title} className='PaymentPictures mr-2' />
			</div>
		);
	}
}

export const ProviderCard: any = connectNLP(ProviderCardComponent);
