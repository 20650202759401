import { get } from 'lodash';

export const defaultOptions = [
    { value: true, title: 'Yes' },
    { value: false, title: 'No' },
    { value: null, title: 'Not specified' },
];

export const formatOptions = (values, isNull = true) => {
    const defaultOptions = values.map((value) => {
        if (!value.title) {
            return {
                ...value,
                title: get(value, 'value', '')
                    .toString()
                    .replace(/_/gi, ' '),
            };
        } else {
            return value;
        }
    });

    if (isNull) {
        return [...defaultOptions, { value: null, title: 'Not specified' }];
    }

    return defaultOptions;
};
