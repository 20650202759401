import { get } from 'lodash';
import * as React from 'react';
import { SubTab } from '../../../../../components/ui-components/tab/sub-tab';
import { connectNLP } from '../../../../../utilities/connect-navigate';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { ArffContributions } from './arff-contributions';
import { AtcContributions } from './atc-contributions';
import { CiqContributions } from './ciq-contributions';
import { MovementContributions } from './movement-contributions';

const tabData = [
	{
		title: 'Possibility to take-off and land',
		type: 'movement',
	},
	{
		title: 'Air Traffic Control',
		type: 'atc',
	},
	{
		title: 'Aerodrome Rescue and Fire Fighting',
		type: 'arff',
	},
	{
		title: 'Custom and Immigration',
		type: 'ciq',
	},
];

class AvailabilityContributionsComponent extends React.Component<any, any> {
	public state = {
		movement: this.props?.total?.movement,
		atc: this.props?.total?.atc,
		arff: this.props?.total?.arff,
		ciq: this.props?.total?.ciq,
	};

	public render(): React.ReactElement {
		const type = get(this.props, 'subType', false);

		const tabs = tabData.map((tab) => {
			return {
				...tab,
				total: get(this.state, tab.type),
			};
		});

		const classes = arrayToClass([
			'w-100',
			this.props.show ? 'SubContainer' : '',
		]);

		return (
			<div className={classes}>
				{this.props.show && (
					<SubTab
						items={tabs}
						onItemClick={(item) => this.handleClick(item)}
						selected={type}
					/>
				)}

				<MovementContributions
					system={this.props.system}
					show={this.props.show && (type === 'movement' || !type)}
					onTotalChange={(total) =>
						this.setState({
							movement: total,
							total: this.calculate(0, total),
						})
					}
					total={this.props?.total?.movementAvailability}
				/>
				<AtcContributions
					system={this.props.system}
					show={this.props.show && type === 'atc'}
					onTotalChange={(total) =>
						this.setState({
							atc: total,
							total: this.calculate(1, total),
						})
					}
					total={this.props?.total?.atcavailability}
				/>
				<ArffContributions
					system={this.props.system}
					show={this.props.show && type === 'arff'}
					onTotalChange={(total) =>
						this.setState({
							arff: total,
							total: this.calculate(2, total),
						})
					}
					total={this.props?.total?.arffavailability}
				/>
				<CiqContributions
					system={this.props.system}
					show={this.props.show && type === 'ciq'}
					onTotalChange={(total) =>
						this.setState({
							ciq: total,
							total: this.calculate(3, total),
						})
					}
					total={this.props?.total?.ciqavailability}
				/>
			</div>
		);
	}

	public handleClick(item): any {
		if (this.props.isAdmin || this.props.system) {
			this.props?.router?.navigate(
				`/admin/${
					this.props.system ? 'system-' : ''
				}contributions/availabilities/${item.type}`
			);
		} else {
			this.props?.router?.navigate(
				`/contributions/availabilities/${item.type}`
			);
		}
	}

	public calculate(type, total) {
		const movement = type !== 'movement' ? this.state.movement : total;
		const atc = type !== 'atc' ? this.state.atc : total;
		const arff = type !== 'arff' ? this.state.arff : total;
		const ciq = type !== 'ciq' ? this.state.ciq : total;

		const newTotal = movement + atc + arff + ciq;

		this.setState({ [type]: total });
		this.props?.onTotalChange(newTotal);
	}
}

export const AvailabilityContributions: any = connectNLP(
	AvailabilityContributionsComponent
);
