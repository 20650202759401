import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { randomId } from '../../../../../../utilities/helper-fuctions';
import { LocalStorage } from '../../../../../../utilities/local-storage';
import { PreLoader } from '../../../../../../utilities/pre-loader';
import { fuelInfos } from '../../../../../../utilities/widget-helpers/fuel-infos';
import { request } from '../../../../../../utilities/widget-helpers/request';
import { ToastMessages } from '../../../../../notifications/toast-messages';
import { Form } from '../../../../../ui-components/form/form';
import { FormButtons } from '../../../../../ui-components/hoverable-buttons/form-modal-buttons/form-buttons';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Checkbox } from '../../../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { Input } from '../../../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../../../ui-components/inputs/inputs/text-input';
import { Label } from '../../../../../ui-components/inputs/label/label';
import { formatOptions } from '../../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../../ui-components/inputs/select/select';
import { SwitchSelect } from '../../../../../ui-components/inputs/select/switch-select';
import { WidgetModal } from '../../../../widget-modal';
import {
	CURRENCIES,
	FUEL_PRODUCT_TYPES,
	FUEL_PRODUCT_UNITS,
} from '../../provider-contribution/constants';

class FuelProductFormComponent extends WidgetModal<any, any> {
	public state: any = {
		countries: [],
		governingDistricts: [],
		percentage: 0,
		buttonDisabled: true,
		minimumHandlingWaiver:
			this.props?.response?.minimumHandlingWaiver === 99999,
	};

	public form: any = new Form(
		{
			description: this.props?.response?.description,
			type: { value: this.props?.response?.type, mandatory: true },
			unit: this.props?.response?.unit,
			currency: this.props?.response?.currency,
			price: this.props?.response?.price,
			limitedAvailability: this.props?.response?.limitedAvailability,
			minimumVolume: this.props?.response?.minimumVolume,
			maximumVolume: this.props?.response?.maximumVolume,
			selfService: this.props?.response?.selfService,
			minimumHandlingWaiver: {
				value: this.props?.response?.minimumHandlingWaiver,
				trigger: () => this.handleDisables(),
			},
		},
		(changed) => this.setState({ buttonDisabled: !changed })
	);

	public helper = new Form({
		minimumHandlingWaiver: {
			value: this.props?.response?.minimumHandlingWaiver === 99999,
			trigger: () => this.handleToggle(),
		},
	});

	public handleDisables() {
		const newValue = this.form.getValue('minimumHandlingWaiver');
		const toNum = parseInt(newValue);

		if (toNum > 99999) {
			this.helper.setValue('minimumHandlingWaiver', 99999);
		}
	}

	public handleToggle() {
		const newValue = !this.state?.minimumHandlingWaiver;
		this.setState({
			minimumHandlingWaiver: newValue,
		});

		if (!!newValue) {
			this.form.setValue('minimumHandlingWaiver', 99999);
		}
	}

	public getTitle() {
		return this.props?.isEdit
			? 'Update fuel product'
			: 'Create fuel product';
	}

	public renderForm(): React.ReactElement {
		const unit = this.form.getValue('unit');

		return (
			<form className='p-4'>
				<Select
					classes='py-2'
					field={this.form.getField('type')}
					options={formatOptions(FUEL_PRODUCT_TYPES, false)}
					labelInfos={{
						label: 'Type',
					}}
				/>

				<TextInput
					field={this.form.getField('description')}
					classes='py-2'
					textType='sentences'
					labelInfos={{ label: 'Description' }}
				/>

				<div className='FormContainer'>
					<Input
						field={this.form.getField('price')}
						inputType='number'
						classes='py-2'
						labelInfos={{ label: 'Price' }}
					/>
					<Select
						field={this.form.getField('currency')}
						options={formatOptions(CURRENCIES, false)}
						classes='py-2 left-m'
						labelInfos={{
							label: 'Currency',
						}}
					/>
					<Select
						field={this.form.getField('unit')}
						classes='py-2 left-m'
						options={formatOptions(FUEL_PRODUCT_UNITS, false)}
						labelInfos={{
							label: 'Unit',
						}}
					/>
				</div>

				<SwitchSelect
					field={this.form.getField('limitedAvailability')}
					labelInfos={{
						info: fuelInfos.limitedAvailability,
						label: 'Limited availability',
					}}
					classes='py-2'
				/>
				<div className='FormContainer'>
					<Input
						field={this.form.getField('minimumVolume')}
						classes='py-2'
						inputType='number'
						labelInfos={{ label: 'Minimum volume' }}
					/>
					<Input
						field={this.form.getField('maximumVolume')}
						classes='py-2 left-m'
						inputType='number'
						labelInfos={{ label: 'Maximum volume' }}
					/>
				</div>

				<SwitchSelect
					field={this.form.getField('selfService')}
					labelInfos={{ label: 'Self service' }}
					classes='py-2 limit-w'
				/>

				<Label
					info={fuelInfos.minimumHandlingWaiver}
					label='Amount to waive handling charges'
				/>
				<div className='display-flex align-items-center py-2'>
					<Input
						disabled={this.state.minimumHandlingWaiver}
						field={this.form.getField('minimumHandlingWaiver')}
						classes='limit-w'
						inputType='number'
						maxLengthLimit={99999}
					/>
					{!!unit && (
						<p className='palette--c-neutral-5 m-0 ml-4'>{unit}</p>
					)}
					<Checkbox
						field={this.helper.getField('minimumHandlingWaiver')}
						classes='ml-4'
						labelInfos={{
							label: 'Not limited',
						}}
					/>
				</div>

				{/* {!this.props?.isEdit && this.renderCosts()} */}
			</form>
		);
	}

	public renderCosts() {
		const costs = LocalStorage.getItem('FuelCosts');

		return (
			<div>
				<h4 className='palette--c-neutral-6'>Fuel Costs: </h4>
				{(costs || []).map((cost) => {
					return (
						<div
							key={randomId()}
							className='w-100 palette--bgc-neutral-2 border-radius-1 my-2 p-2'
						>
							<div className='row'>
								<p className='col-6 palette--c-neutral-6 m-0'>
									{cost.name}
								</p>
								<div className='col-16 display-flex align-items-center'>
									<p className='m-0'>{cost.price}</p>
									<p className='m-0'>{cost.currency}</p>
									<p className='m-0'>
										{cost.currency && cost.unit && '/'}
									</p>
									<p className='m-0'>{cost.unit}</p>
								</div>
								<div className='display-flex'>
									<div
										className='px-1 pointer'
										onClick={() =>
											this.handleOpenCosts(cost)
										}
									>
										<span className='AuthorityIcon palette--c-primary-4 material-icons'>
											edit
										</span>
									</div>
									<div
										className='px-1 pointer'
										onClick={() =>
											this.handleCostDelete(cost.key)
										}
									>
										<span className='AuthorityIcon palette--c-primary-4 material-icons'>
											delete
										</span>
									</div>
								</div>
							</div>
						</div>
					);
				})}
				<HoverableButton
					type='button'
					className='border-radius-1'
					colorType='avio-green'
					onClick={() => this.handleOpenCosts()}
					title='Add contacts'
				/>
			</div>
		);
	}

	public async handleOpenCosts(details = null) {
		// add here a link for costs, /airports/:aid/:providerAid/:fuelAid/{create - fuel-costs/ :fuelCostsAid?edit=true};
	}

	public handleCostDelete(key) {
		const costs = LocalStorage.getItem('FuelCosts');

		const newCosts = costs.filter((cost) => cost.key !== key);

		LocalStorage.setItem('FuelCosts', newCosts);
		this.forceUpdate();
	}

	public renderFooter(): React.ReactElement {
		return (
			<FormButtons
				isDelete={this.props?.isEdit}
				onDelete={() => this.deleteModal$.open()}
				saveDisabled={this.state?.buttonDisabled}
				onSave={() => this.handleSave()}
				onClose={() => this.onClose()}
			/>
		);
	}

	public getType() {
		return 'fuel';
	}

	public onClose(): void {
		this.form.clearForm();
		this.modal$.close();
		this.props.onClose();
		this.setState({ buttonDisabled: false });
	}

	public async onDelete(): Promise<void> {
		const aid = get(this.props, 'aid', null);

		if (!aid) {
			return;
		}

		await request(
			'/fuelproducts/merge',
			'fuel',
			() => this.handleSuccessModal(),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		).deleteF(`/fuelproducts/${aid}`, this.form.getFieldsWhereHasValue());
	}

	public async handleSave() {
		this.setState({ buttonDisabled: true });

		const req = request(
			'/fuelproducts/merge',
			'fuel',
			() => this.handleSuccessModal(),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		);

		const data = this.form.generateJSON();
		const changes = this.form.getDifferences();

		if (this.props?.isEdit) {
			await req.edit(`/fuelproducts/${this.props?.aid}`, data, changes);
		} else {
			await req.create('/fuelproducts', data, changes, {
				parentAid: this.props.aid,
			});
			// here goes the costs save request
		}

		this.setState({ buttonDisabled: false });
	}

	public handleSuccessModal(isDelete = false) {
		if (this.notif$) {
			this.notif$.open(isDelete);
		}

		this.modal$.close();
	}
}

export const FuelProductForm: any = PreLoader(FuelProductFormComponent);
