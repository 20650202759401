export const availabilityInfos = {
           validFrom: 'An |ISO| formatted date-time string that indicates when the block starts',
           validTo: 'An |ISO| formatted date-time string that indicates when the block ends',
           status:
               'An enumeration that describes the current status of the facility. This can be |FULL| to indicate that the facility is fully available as described by the object, |LIMITED| to indicate that the facility is available under certain conditions that cannot be described by the model, and |CLOSED| to indicate that the facility is not available.',
           info: 'An object that contains additional information about the current |Availability Block|.',
           notes:
               'present only in case status is |LIMITED| and is used to describe in plain English the limitation in force',
           atc: {
               afisOnly: 'When |YES| indicates that only |AFIS| (Aerodrome Flight Information Service) is provided.',
               enRtfAvailable:
                   'When |YES| indicates that English Radiotelephony is available. This simply means that the radio operator can speak English.',
           },
           arff: {
               icaoCatAirplane:
                   "Indicates the |ICAO| Rescue and Fire Fighting category for airplanes (not helicopters). Possible values are |NONE|, |I|, |II|, |III|, |IV|, |V|, |VI|, |VII|, |VIII|, |IX|, |X|.",
               faaCatAirplane:
                   "Indicates the |FAA| category. Possible values are |NONE|, |A|, |B|, |C|, |D|, |E|.",
               faa139Certified:
                   'Indicates if the airport is certified according to |14 CFR §139|. If |YES|, it means the basis for the |ARFF| certification are the US rules, which don’t allow for immediate conversion of |FAA cat airplane| to |ICAO cat airplane|. If |YES| it indicates that |ICAO cat airplane| was a value calculated based on the values of |water quantity|, |complementary quantity|, |ARFF vehicles|, and |discharge capacity|.',
               catHelicopters:
                   "Indicates the ARFF value for helicopters. Possible values are |NONE|, |I|, |II|, |III|, |IV|.",
               waterQuantity: 'The total water capacity of all ARFF vehicles available, in litres.',
               complementaryQuantity:
                   "The total capacity of Complimentary Extinguishing Agent available, in kilograms.",
               arffVehicles: "The number of ARFF vehicles available.",
               dischargeCapacity:
                   "The total discharge capacity of all ARFF vehicles available, in litres per minute.",
               extensionAvailable:
                   "Indicates if an upgrade of the ARFF to a higher category can be arranged.",
               extensionNotice:
                   "Amount of hours required to obtain ARFF extension before departure or arrival.",
               extensionUpToIcao:
                   "The maximum ARFF obtainable with extension. This is only available in |ICAO| format. Possible values are as per ICAO cat airplane.",
               firestationRemote:
                   "Indicates if the ARFF service is offered by a remote fire station, not located at the airfield, for example, a village fire station.",
               firestationNotes:
                   'If |Firestation remote| is |YES|, this text field allows notes about the location and performance of the remote fire station, for example, to indicate the response time.',
           },
       };
