import { get } from 'lodash';
import { toast } from 'react-toastify';
import { RequestManager } from '../../utilities/request';
import './search.scss';
import * as React from 'react';

interface SearchableProps {
	size?: number;
}

export class Searchable<P> extends React.Component<SearchableProps & P, any> {
	public token: any;

	public state = {
		response: null,
		error: null,
		loading: false,
	};

	public endpoint() {
		return '/airports/search?includeArchived=true';
	}

	public async search(query: any, isPaginated: boolean = false) {
		try {
			this.setState({
				loading: true,
				error: null,
			});

			const response = await RequestManager.get(this.endpoint(), query);

			if (!response || !response.content) {
				throw new Error('there was no data');
			}

			if (isPaginated) {
				return this.setState({
					loading: false,
					response: {
						content: [
							...get(this.state.response, 'content', []),
							...response.content,
						],
						page: response.content,
					},
					error: null,
				});
			}

			this.setState({
				loading: false,
				response: response,
				error: null,
			});
		} catch (err) {
			this.setState({
				loading: false,
				response: null,
				error:
					get(err, 'response.data.message', null) || err || 'Error',
			});
			toast.error(
				get(err, 'response.data.message', null) || err || 'Error',
				{ theme: 'light' }
			);
		}
	}
}
