import { get } from 'lodash';
import * as React from 'react';
import {
	arrayToClass,
	randomId,
} from '../../../../../../utilities/helper-fuctions';
import { Form } from '../../../../../ui-components/form/form';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Input } from '../../../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../../ui-components/inputs/select/select';
import { Tooltip } from '../../../../../ui-components/popover/popover';
import { statusOpts } from '../../opening-hours-contribution/constants';
import { ArffAdditions } from './arff-additions';
import { AtcAdditions } from './atc-additions';
import { hourFieldValidator } from './hour-validator';
import { MovementAdditionModal } from './movement-open-fors/movement-addition-modal';
import { MovementAdditionText } from './movement-open-fors/movement-addition-text';

const hourValidator = /(?:[01]\d|2[0123])(|:)([0-5]){1}([0-9]){1}/;
const ssValidator = /([s]){1}([s]){1}((([-+]| [+-])[0-9]+|)|)/gi;
const srValidator = /([s]){1}([r]){1}((([-+]| [+-])[0-9]+|)|)/gi;

export class Block extends React.Component<any, any> {
	public modal$: MovementAdditionModal;

	public state = {
		disableNotes: this.props?.block?.status !== 'LIMITED',
		disableAdds: this.props?.block?.status === 'CLOSED',
		isLimited: this.props?.block?.status === 'LIMITED',
		showHelp: false,
		validFromError: false,
		validToError: false,
		open:
			get(this.props, 'block.open', null) !== null
				? get(this.props, 'block.open')
				: true,
		hovered: '',
		openFors:
			(this.props?.block?.openFor, []).map((item) => ({
				key: randomId(),
				data: item,
			})) || [],
		openLimitDetails: false,
	};

	public form = new Form({
		validFrom: this.getFormValues(),
		validTo: this.getFormValues(true),
		validFromSunriseOffset: null,
		validToSunriseOffset: null,
		validFromSunsetOffset: null,
		validToSunsetOffset: null,
		status: {
			value: this.props?.block?.status || statusOpts[0].value,
			trigger: () => this.handleStatusChange(),
		},
		notes: this.props?.block?.notes || '',
	});

	public atc = new Form({
		enRtfAvailable: this.props?.block?.enRtfAvailable,
		afisOnly: this.props?.block?.afisOnly,
	});

	public arff = new Form({
		icaoCatAirplane: this.props?.block?.icaoCatAirplane,
		faaCatAirplane: this.props?.block?.faaCatAirplane,
		faa139Certified: this.props?.block?.faa139Certified,
		catHelicopters: this.props?.block?.catHelicopters,
		waterQuantity: this.props?.block?.waterQuantity,
		complementaryQuantity: this.props?.block?.complementaryQuantity,
		arffVehicles: this.props?.block?.arffVehicles,
		dischargeCapacity: this.props?.block?.dischargeCapacity,
		extensionAvailable: this.props?.block?.extensionAvailable,
		extensionNotice: this.props?.block?.extensionNotice,
		extensionUpToIcao: this.props?.block?.extensionUpToIcao,
		firestationRemote: this.props?.block?.firestationRemote,
		firestationNotes: this.props?.block?.firestationNotes,
	});

	public handleStatusChange() {
		const value = this.form.getValue('status');

		this.setState({
			disableNotes: value !== 'LIMITED',
			disableAdds: value === 'CLOSED',
			isLimited: value === 'LIMITED',
		});
	}

	public getFormValues(isTo = false) {
		const field = isTo ? 'To' : 'From';
		const block = this.props?.block;

		const value =
			block?.[`valid${field}`] || this.checkSSorSR(field) || null;

		return value;
	}

	public checkSSorSR(field) {
		const block = this.props?.block;

		return (
			this.setSsAndSrValues(
				block?.[`valid${field}SunsetOffset`],
				`valid${field}SunsetOffset`,
				true
			) ||
			this.setSsAndSrValues(
				block?.[`valid${field}SunriseOffset`],
				`valid${field}SunriseOffset`
			)
		);
	}

	public setSsAndSrValues(value, field, isSs = false) {
		if (value === 0) {
			return `${isSs ? 'SS' : 'SR'}`;
		} else if (value > 0) {
			return `${isSs ? 'SS' : 'SR'}+${value}`;
		} else if (value < 0) {
			return `${isSs ? 'SS' : 'SR'}${value}`;
		} else {
			return null;
		}
	}

	public render(): React.ReactElement {
		const isNotes =
			this.props.add === 'movement' ? false : !this.state.disableNotes;

		return (
			<div className='w-100'>
				<div className='w-100 p-2 my-1 palette--bgc-neutral-1 border-radius-1 position-relative'>
					<div className='row'>
						<Tooltip
							tooltip={this.renderPopover()}
							trigger={this.renderInput('validFrom')}
							triggerClasses='col-24 col-sm-4 my-2 my-sm-0 display-flex flex-column justify-content-center'
						/>

						<Tooltip
							tooltip={this.renderPopover()}
							trigger={this.renderInput('validTo')}
							triggerClasses='col-24 col-sm-4 my-2 my-sm-0 display-flex flex-column justify-content-center'
						/>

						<div className='col-24 col-sm-4 my-2 my-sm-0 display-flex flex-column justify-content-center'>
							<span className='display-flex display-sm-none palette--c-neutral-5 fw-bold text-uppercase'>
								Status
							</span>
							<Select
								field={this.form.getField('status')}
								classes='w-100'
								options={formatOptions(statusOpts, false)}
							/>
						</div>
						{isNotes && (
							<div className='col-24 col-sm-7 my-2 my-sm-0 display-flex flex-column justify-content-center'>
								<span className='display-flex display-sm-none palette--c-neutral-5 fw-bold text-uppercase'>
									Notes
								</span>
								<TextInput
									placeholder='Notes'
									field={this.form.getField('notes')}
									className='w-100 border-radius-1'
									textType='sentences'
								/>
							</div>
						)}

						{!isNotes && (
							<div className='col-24 col-sm-7 my-2 my-sm-0' />
						)}

						<div className='col-24 col-sm-4 display-flex justify-content-end'>
							{(this.props.add === 'atc' ||
								this.props.add === 'arff') &&
								!this.state.disableAdds && (
									<HoverableButton
										colorType='transparent-grey'
										onClick={() =>
											this.setState({
												open: !this.state.open,
											})
										}
										title={`${
											this.props.open ? 'Close' : ''
										} Additional Info`}
									/>
								)}
							{this.props.add === 'movement' &&
								!!this.state.isLimited && (
									<HoverableButton
										onClick={() => this.modal$.open()}
										colorType='avio-green'
										title='Specify Exceptions'
									/>
								)}
						</div>
					</div>

					<HoverableButton
						className='position-absolute absolute-top-0 absolute-right-0 h-0 h-md-100'
						icon='close'
						colorType='transparent-grey'
						onClick={() =>
							this.props.deleteBlock && this.props.deleteBlock()
						}
					/>
				</div>
				{this.renderAdds()}
				{this.props.add === 'movement' &&
					!!this.state.isLimited &&
					this.renderFlightTypes()}
				{this.props.add === 'movement' && !!this.state.isLimited && (
					<MovementAdditionModal
						block={{
							validFrom: this.form.getValue('validFrom'),
							validTo: this.form.getValue('validTo'),
						}}
						ref={(ref: any) => (this.modal$ = ref)}
						save={(data) => this.handleSave(data)}
					/>
				)}
			</div>
		);
	}

	public renderFlightTypes() {
		const openFors = get(this.state, 'openFors', []) || [];

		if (openFors.length === 0) {
			return null;
		}

		return (
			<div className='w-100 palette--bgc-neutral-1 border-radius-1 mb-2'>
				<div className='w-100 display-flex p-2'>
					<div className='flex-fill'>
						<div className='OpenFors position-relative display-inline-box'>
							<p className='palette--c-neutral-6 m-0 fw-bold'>
								Open for:
							</p>
							<div className='OpenForsCounter palette--bgc-primary-4 border-1 border-radius-50 palette--c-primary-4 display-flex justify-content-center align-items-center position-absolute'>
								<p className='palette--c-neutral-1 m-0'>
									{(get(this.state, 'openFors') || []).length}
								</p>
							</div>
						</div>
					</div>

					<HoverableButton
						onClick={() =>
							this.setState({
								openLimitDetails: !this.state.openLimitDetails,
							})
						}
						icon={
							this.state.openLimitDetails
								? 'expand_more'
								: 'chevron_right'
						}
						colorType='transparent-grey'
					/>
				</div>
				{!!this.state.openLimitDetails &&
					openFors.map((data: any) => {
						return (
							<MovementAdditionText
								key={data.key}
								data={data}
								openEdit={(openForData) =>
									this.modal$.open(openForData)
								}
								delete={(openForData) =>
									this.handleMovementAddDelete(
										get(openForData, 'key')
									)
								}
							/>
						);
					})}
			</div>
		);
	}

	public renderPopover(): any {
		return (
			<div className='PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1'>
				{this.renderTooltip()}
			</div>
		);
	}

	public renderInput(type) {
		const validFromClasses = arrayToClass([
			`palette--bc-${
				this.state?.[`${type}Error`] ? 'red-2' : 'neutral-4'
			}`,
		]);

		return (
			<div className='w-100'>
				<span className='display-flex display-sm-none palette--c-neutral-5 fw-bold text-uppercase'>
					From
				</span>
				<Input
					field={this.form.getField(type)}
					inputClasses={validFromClasses}
					onBlur={() => this.handleOnBlur(type)}
					formatInput={(value) => this.formatInput(value)}
					onFocus={() => this.handleOpen(type)}
				/>
			</div>
		);
	}

	public renderTooltip(): React.ReactElement {
		return (
			<div className='TooltipBox'>
				<h4 className='palette--c-neutral-1'>
					Use one of these formats
				</h4>
				<div className='row mt-2'>
					<h5 className='col-24 palette--c-neutral-1'>
						Based on sunrise
					</h5>
					<p className='col-12 palette--c-neutral-1'>
						Before sunrise:
					</p>
					<p className='col-12 palette--c-neutral-1'>SR-15(min)</p>
					<p className='col-12 palette--c-neutral-1'>
						After sunrise:
					</p>
					<p className='col-12 palette--c-neutral-1'>SR+15(min)</p>
				</div>
				<div className='row my-2'>
					<h5 className='col-24 palette--c-neutral-1'>
						Based on sunset:
					</h5>
					<p className='col-12 palette--c-neutral-1'>
						Before sunset:
					</p>
					<p className='col-12 palette--c-neutral-1'>SS-15(min)</p>
					<p className='col-12 palette--c-neutral-1'>After sunset:</p>
					<p className='col-12 palette--c-neutral-1'>SS+15(min)</p>
				</div>
				<div className='row mb-2'>
					<h5 className='col-24 palette--c-neutral-1'>Exact time:</h5>
					<p className='col-12 palette--c-neutral-1'>Exact time:</p>
					<p className='col-12 palette--c-neutral-1'>0500 or 05:00</p>
				</div>
				<div className='row'>
					<h5 className='col-24 palette--c-neutral-1'>
						All times should be in local.
					</h5>
				</div>
			</div>
		);
	}

	public handleSave(data) {
		let newOpenFors;

		if (get(data, 'key')) {
			newOpenFors = this.state.openFors.map((item: any) => {
				if (item.key === data.key) {
					item.data = data.data;
				}

				return item;
			});
		} else {
			newOpenFors = [
				...this.state.openFors,
				{ key: randomId(), data: data },
			];
		}

		this.setState({ openFors: newOpenFors });
		this.modal$.close();
	}

	public handleMovementAddDelete(key) {
		this.setState({
			openFors: this.state.openFors.filter(
				(item: any) => item.key !== key
			),
		});
	}

	public handleOpen(field) {
		const value = this.form.getValue(field);
		const error = get(this.state, `${field}Error`);

		if (!value || error) {
			this.setState({ hovered: field });
		}
	}

	public formatInput(value): string {
		const validator = hourFieldValidator(value);

		if (!validator) {
			return value;
		}

		const validations = validator.valid;

		if (
			(value || '').match(hourValidator) &&
			!value.includes(':') &&
			!!validations
		) {
			return `${value.slice(0, 2)}:${value.slice(2)}`;
		}

		return value;
	}

	public async handleOnBlur(field): Promise<void> {
		const validator = await hourFieldValidator(this.form.getValue(field));

		if (!validator) {
			return;
		}

		this.setState({
			showHelp: validator.error,
			[`${field}Error`]: !!validator.error,
			hovered: false,
		});
	}

	public renderAdds(): any {
		if (this.props.add === 'atc' && !this.state.disableAdds) {
			return (
				<AtcAdditions
					form={this.atc}
					values={this.props.block}
					open={this.state.open}
				/>
			);
		}

		if (this.props.add === 'arff' && !this.state.disableAdds) {
			return (
				<ArffAdditions
					form={this.arff}
					values={this.props.block}
					open={this.state.open}
				/>
			);
		}

		return null;
	}

	public generateValue(form, type) {
		const field = `valid${type}`;
		const defaultValue = form?.[field] || '';

		if (defaultValue.match(ssValidator)) {
			const parseToNumbersOnly = defaultValue.replace(/[a-z]/gi, '');
			form[`${field}SunsetOffset`] = parseInt(parseToNumbersOnly || 0);
			form[field] = null;
		} else if (defaultValue.match(srValidator)) {
			const parseToNumbersOnly = defaultValue.replace(/[a-z]/gi, '');
			form[`${field}SunriseOffset`] = parseInt(parseToNumbersOnly || 0);
			form[field] = null;
		} else if (
			defaultValue.match(hourValidator) &&
			!defaultValue.includes(':')
		) {
			form[field] = `${defaultValue.slice(0, 2)}:${defaultValue.slice(
				2
			)}`;
		}

		return form;
	}

	public getData(): any {
		const form: any = this.form.generateJSON();

		this.generateValue(form, 'From');
		this.generateValue(form, 'To');

		if (form.status !== 'LIMITED') {
			delete form.notes;
		}

		const adds = this.getAdds();

		return {
			...form,
			...adds,
			open: this.state.open,
		};
	}

	public validateBlock() {
		const block = this.getData();
		const validFrom = !!block.validFrom;
		const srValidFrom = this.ssOrSrTimes(block.validFromSunriseOffset);
		const ssValidFrom = this.ssOrSrTimes(block.validFromSunsetOffset);
		const validTo = !!block.validTo;
		const ssValidTo = this.ssOrSrTimes(block.validToSunsetOffset);
		const srValidTo = this.ssOrSrTimes(block.validToSunriseOffset);

		if (
			(validFrom || srValidFrom || ssValidFrom) &&
			(validTo || srValidTo || ssValidTo)
		) {
			return false;
		} else {
			return true;
		}
	}

	public ssOrSrTimes(time) {
		if (!!time || time === 0) {
			return true;
		}

		return false;
	}

	public getAdds(): any {
		if (this.props.add === 'atc') {
			return this.atc.generateJSON();
		}

		if (this.props.add === 'arff') {
			return this.arff.generateJSON();
		}

		if (this.props.add === 'movement' && !this.state.disableNotes) {
			return {
				openFor: this.state.openFors.map((item: any) => item.data),
			};
		}

		return {};
	}

	public async validateForm(): Promise<any> {
		const from = this.form.getValue('validFrom');
		const to = this.form.getValue('validTo');

		if (!from || !to) {
			this.setState({
				validFromError: true,
				validToError: true,
				showHelp: '0000 00:00 SR+10 SS-10',
			});
			return false;
		}

		const validator = {
			from: hourFieldValidator(from),
			to: hourFieldValidator(to),
		};

		const fromValidations = validator?.from?.valid;
		const toValidations = validator?.to?.valid;

		if (!!fromValidations && !!toValidations) {
			return true;
		}

		if (!fromValidations) {
			this.setState({
				showHelp: get(fromValidations[0], 'error'),
				validFromError: true,
			});
		}

		if (!toValidations) {
			this.setState({
				showHelp: get(toValidations[0], 'error'),
				validToError: true,
			});
		}

		return false;
	}
}
