import { format, isValid } from 'date-fns';
import { get, isEmpty, isEqual, sortBy } from 'lodash';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { generateDMS } from '../../../../utilities/coordinates-convereter';
import { RequestManager } from '../../../../utilities/request';
import { validate } from '../../../../utilities/validators';

export const getFields = (ctx, list, extra: string = ''): any => {
	return list.filter((fieldItem) => {
		const { type } = fieldItem;
		const { original, item } = ctx.props;
		let current = get(original, type);
		let changed = get(item, type);

		if (extra === 'customs') {
			current = original?.customsDetails?.[type];
			changed = item?.customsDetails?.[type];
		}

		if (ctx.state.type === 'CREATE') {
			if (Array.isArray(changed)) {
				return !isEmpty(changed);
			}

			return changed !== null;
		}

		if (ctx.state.type === 'DELETE') {
			if (Array.isArray(current)) {
				return !isEmpty(current);
			}

			return current !== null;
		}

		if (current === null && changed === null) {
			return;
		}

		if (current === null && Array.isArray(changed) && !!isEmpty(changed)) {
			return;
		}

		if (
			Array.isArray(changed) &&
			isEqual(sortBy(current), sortBy(changed))
		) {
			return;
		}

		if (
			typeof changed !== 'number' &&
			isValid(new Date(changed)) &&
			isValid(new Date(current)) &&
			changed !== null
		) {
			return (
				format(new Date(changed), 'yyyy. MM. dd. HH:mm') !==
				format(new Date(current), 'yyyy. MM. dd. HH:mm')
			);
		}

		if (isEqual(current, changed)) {
			return;
		}

		if (typeof changed === 'boolean' && changed !== current) {
			return type;
		}

		if (current !== null && changed === null) {
			return type;
		}

		if (!isNaN(changed) && current !== changed) {
			if (typeof changed === 'string') {
				changed.replace(/,/g, '');
			}

			return type;
		}

		if (changed !== null && current === null) {
			return type;
		}

		return changed !== current && changed;
	});
};

export const getData = (
	field: string,
	value: any,
	isForm: boolean = false,
	bools: any = false
): any => {
	if (
		((field || '').includes('country') ||
			(field || '').includes('referenceWeatherStation')) &&
		value
	) {
		return value.name || value || '';
	}

	if (
		((field || '').includes('latitude') ||
			(field || '').includes('longitude')) &&
		value
	) {
		return generateDMS(
			value,
			field === 'coordinates.longitude' ||
				field === 'thresholdCoordinates.longitude'
		);
	}

	if (typeof value === 'boolean') {
		if (isForm) {
			const bool = value;
			return bool === true
				? 'Yes'
				: bool === false
				? 'No'
				: 'Not specified';
		}

		return value === true
			? 'Yes'
			: value === false
			? 'No'
			: 'Not specified';
	}

	if (value === null || value === '') {
		return null;
	}

	if (Array.isArray(value)) {
		if (
			field === 'fboBuildingPolygon' ||
			field === 'aircraftParkingPolygon'
		) {
			return null;
		}

		return (value || [])
			.map((i: any) =>
				(i?.title || i?.name || i || '').replace(/_/gi, ' ')
			)
			.join(', ');
	}

	if (field.includes('valid') && value !== null) {
		return format(new Date(value), 'yyyy. MM. dd. HH:mm');
	}

	if (validate((value || '').toString(), 'phone')) {
		return formatPhoneNumberIntl(value);
	}

	if (typeof field === 'number' && value !== null) {
		return value;
	}

	if (typeof value === 'object' && value !== null) {
		if (field === 'paxMeetingPolygon') {
			return null;
		}

		const { title, name } = value;

		return title || name || null;
	}

	if (typeof value === 'string') {
		return value.replace(/_/gi, ' ');
	}

	return value;
};

export const checkData = (list, obj, form, specialFields: any = []): any => {
	return list.map((fieldItem: any) => {
		const { type } = fieldItem;
		const value = form?.[type];

		if (specialFields.includes(type)) {
			obj[type] = form?.[type];
			return type;
		}

		if (type === 'slope' && !!obj?.[type]) {
			const data = parseFloat(value) / 100;
			obj[type] = parseFloat(data.toFixed(4));
			return type;
		}

		if (
			type === 'aircraftParkingPolygon' ||
			type === 'fboBuildingPolygon' ||
			type === 'paxMeetingCoordinates'
		) {
			obj[type] = value;
			return type;
		}

		if ((type || '').includes('coordinates')) {
			if (type.includes('latitude')) {
				obj.coordinates.latitude = parseFloat(value);
			} else if (type.includes('longitude')) {
				obj.coordinates.longitude = parseFloat(value);
			}

			return 'coordinates';
		}

		if ((type || '').includes('thresholdC')) {
			if (type.includes('latitude') && !!obj.thresholdCoordinates) {
				obj.thresholdCoordinates.latitude = parseFloat(value);
			} else if (
				type.includes('longitude') &&
				!!obj.thresholdCoordinates
			) {
				obj.thresholdCoordinates.longitude = parseFloat(value);
			}

			return 'thresholdCoordinates';
		}

		if (type.includes('valid')) {
			obj[type] = new Date(value);
			return type;
		}

		if (Array.isArray(value)) {
			obj[type] = (value || []).map((i: any) => i?.value || i);
			return type;
		}

		if (typeof value === 'object' && value !== null) {
			if (type.includes('country')) {
				obj[type] = value;
				return type;
			}

			if (type.includes('referenceWeatherStation')) {
				obj[type] = value?.aid;
				return type;
			}

			obj[type] = value?.value || value;
			return type;
		}

		if (typeof obj[type] === 'string' && !isNaN(obj[type])) {
			let data = value;

			if ((type || '').includes('hone')) {
				obj[type] = data;
				return type;
			}

			if (typeof value === 'string') {
				data = value.replace(/,/g, '');
			}

			obj[type] = parseFloat(data);
			return type;
		}

		if (value !== obj[type]) {
			obj[type] = value;
			return type;
		}

		return type;
	});
};

export const getLinks = async (type, item) => {
	const parent = item?.parentEntity;
	const aid = item?.entityAid;

	if (!parent && type !== 'airports') {
		const t = type || '';
		const fuelP = t.includes('fuel') ? 'fuel product' : false;
		const productP = t.includes('product') ? 'provider' : false;
		const conatctP = t.includes('Contacts') ? 'authority' : false;
		const parentType = fuelP || productP || conatctP || 'airport';

		return {
			link: false,
			name: false,
			aid: aid,
			parent: parentType,
		};
	}

	if ((type || '').includes('country')) {
		if ((type || '').includes('Contacts')) {
			const country = await RequestManager.get(
				`/countryauthorities/${parent}`
			);

			return {
				link: `/countries/${country?.parent?.iso3}/${parent}`,
				name: country?.name,
				aid: null,
				parent: parent,
			};
		}

		return {
			link: `/countries/${parent?.iso3}`,
			parent: item?.iso3,
			name: item?.name,
			aid: aid,
		};
	}

	if ((type || '').includes('authorityContacts')) {
		const authority = await RequestManager.get(
			`/airportauthorities/${parent}`
		);
		const parentAirport = await RequestManager.get(
			`/airports/${authority?.parent}`
		);

		return {
			link: `/airports/${parentAirport?.aid}/${authority?.aid}`,
			name: parentAirport?.name,
			aid: null,
			parent: parent,
		};
	}

	if (type === 'providers') {
		return {
			link: `/airports/${parent}`,
			name: item?.name,
			aid: aid,
			parent: parent,
		};
	}

	if ((type || '').includes('products')) {
		if ((type || '').includes('fuel')) {
			const fuel = await RequestManager.get(`/fuelproducts/${parent}`);
			const fuelParent = await RequestManager.get(
				`/providers/${fuel?.parent}`
			);

			return {
				link: `/airports/${fuelParent?.parent}/${fuel?.parent}/${parent}`,
				name: item?.name,
				aid: aid,
				parent: parent,
			};
		}

		const provider = await RequestManager.get(`/providers/${parent}`);

		return {
			link: `/airports/${provider?.parent}/${parent}`,
			name: item?.name,
			aid: type === 'genericproducts' ? null : aid,
			parent: parent,
		};
	}

	let airport: any = null;

	if (parent) {
		airport = await RequestManager.get(`/airports/${parent}`);
	}

	if ((type || '').includes('note')) {
		return {
			link: `/airports/${parent}`,
			parent: parent,
			aid: null,
			name: item?.name || airport?.name,
		};
	}

	return {
		link: `/airports/${parent || aid}`,
		parent: parent,
		aid: aid,
		name: item?.name || airport?.name,
	};
};
