import { format, isSameDay } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { replaceQuery } from '../../../../utilities/helper-fuctions';
import { PreLoader } from '../../../../utilities/pre-loader';
import { TextRenderer } from '../../../other/text-renderer';
import { ModalButtons } from '../../../ui-components/hoverable-buttons/form-modal-buttons/modal-button';
import { HoverableButton } from '../../../ui-components/hoverable-buttons/hoverable-buttons';
import { StatusMarker } from '../../../ui-components/statusmarker/status-marker';
import { WidgetModal } from '../../widget-modal';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class OperationalNotesDetailsComponent extends WidgetModal<any, any> {
	public componentDidMount(): void {
		super.componentDidMount();

		if (this.props?.error) {
			this.props?.onClose();
		}
	}

	public getTitle() {
		return 'Note';
	}

	public renderForm() {
		const note = this.props?.response;
		const date = this.props?.date || 'yyyy. MM. dd. HH:mm';
		const to = isSameDay(new Date(note?.validTo), new Date('31.12.2099'))
			? 'Permanent'
			: format(new Date(note.validTo), date);

		return (
			<div className='px-4'>
				<div className='display-flex'>
					<div className='flex-fill'>
						{this.renderCategory()}

						<div className='display-flex'>
							{this.renderMarkers(note?.aid)}
							{this.renderMarkers(
								(note?.category || '').replace(/_/gi, ' ')
							)}
						</div>
					</div>

					<div className='inline-flex'>
						<HoverableButton
							className='border-radius-1'
							colorType='avio-green'
							titleRight='Edit'
							icon='rate_review'
							onClick={() => this.onEdit()}
						/>
					</div>
				</div>

				<div className='display-flex py-2'>
					<h5 className='palette--c-neutral-5 m-0'>
						{format(new Date(note?.validFrom), date)}
					</h5>
					<h5 className='palette--c-neutral-5 m-0 px-2'>-</h5>
					<h5 className='palette--c-neutral-5 m-0'>{to}</h5>
				</div>

				<TextRenderer text={note?.notes} classNames='py-2' />

				<h5 className='palette--c-neutral-5 m-0 pt-2'>
					{note?.source}
				</h5>
			</div>
		);
	}

	public renderCategory() {
		const criticality = this.props?.response?.criticality || '';
		const color =
			criticality === 'OPERATIONALLY_CRITICAL'
				? 'red-2'
				: criticality === 'OPERATIONALLY_RELEVANT'
				? 'green-2'
				: 'blue-2';
		const type = criticality
			.replace('_', ' ')
			.toLowerCase()
			.replace(/^\w/, (l) => l.toUpperCase());

		return (
			<StatusMarker
				tColor='neutral-1'
				bgColor={color}
				text={type}
				type='tag'
				classNames='mb-2'
			/>
		);
	}

	public renderMarkers(text) {
		return (
			<StatusMarker
				tColor='neutral-5'
				bgColor='neutral-1'
				text={text}
				type='badge'
				classNames='border-1 palette--bc-neutral-5 mr-4 my-1'
			/>
		);
	}

	public renderFooter() {
		const isAdmin = (this.props?.user?.roles || []).includes('ADMIN');

		return (
			<ModalButtons
				onClose={() => this.onClose()}
				isDelete={isAdmin}
				type='operational-note'
				merge={`/${
					this.props.isCountry ? 'country' : 'airport'
				}operationalnotes/merge`}
				delete={`/${
					this.props.isCountry ? 'country' : 'airport'
				}operationalnotes/${this.props?.aid}`}
				changes={(resp) => this.getChanges(resp)}
			/>
		);
	}

	public getChanges(resp) {
		const note = this.props?.response;
		const changes = Object.keys(note).filter(
			(key) =>
				note?.[key] !== null ||
				note?.[key] !== undefined ||
				note?.[key] !== ''
		);

		resp.contribution.changes = changes;
		return resp;
	}

	public onClose(): void {
		this.props?.onClose();
	}

	public onEdit() {
		const newQuery = replaceQuery({ edit: true });
		this.props?.router?.navigate({ search: newQuery });
	}
}

export const OperationalNotesDetails: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(PreLoader(OperationalNotesDetailsComponent));
