import * as React from 'react';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';

export class StepFive extends React.Component<any, any> {
    public render(): React.ReactElement {
        return (
            <div className="w-100">
                <div className="w-100 px-4 py-6 display-flex align-items-center">
                    <h2 className="palette--c-primary-4 material-icons m-0 mr-4">check_circle</h2>
                    <h3 className="palette--c-neutral-6 m-0">Thank you for your contribution</h3>
                </div>

                {this.renderFooter()}
            </div>
        );
    }

    public renderFooter(): React.ReactElement {
        return (
            <div className="w-100 display-flex justify-content-end p-4">
                <HoverableButton
                    className="border-radius-1 mr-2"
                    colorType="cancel"
                    onClick={() => this.props.close && this.props.close()}
                    title="Close"
                />
            </div>
        );
    }
}
