import { get, isEqual } from 'lodash';
import * as React from 'react';
import { randomId } from '../../../../../../utilities/helper-fuctions';
import { Form } from '../../../../../ui-components/form/form';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Checkbox } from '../../../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { Select } from '../../../../../ui-components/inputs/select/select';
import { DAYS } from '../../opening-hours-contribution/constants';
import { ArffAdditions } from './arff-additions';
import { AtcAdditions } from './atc-additions';
import { Block } from './block';

const weekdays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];
const weekends = ['SATURDAY', 'SUNDAY'];

export class Blocks extends React.Component<any, any> {
	public block: { [key: string]: Block } = {};
	public atc: AtcAdditions;
	public arff: ArffAdditions;

	public state = {
		available: this.props?.block?.availablePerDay || false,
		blocks:
			this.props?.block?.availablePerDay === 'custom'
				? get(this.props.block, 'blocks') || ['default']
				: ['default'],
		adds: !!this.props.add ? (this.props?.block?.blocks || [])[0] : false,
	};

	public form = new Form({
		scheduleUnits: {
			value: this.props?.block?.scheduleUnits || [],
			trigger: () => this.handleChange(),
		},
	});

	public availabilityForm = new Form({
		available: {
			value: this.props?.block?.availablePerDay === 'available' || false,
			trigger: () => this.handleUnchecks('available'),
		},
		unavailable: {
			value:
				this.props?.block?.availablePerDay === 'unavailable' || false,
			trigger: () => this.handleUnchecks('unavailable'),
		},
		custom: {
			value: this.props?.block?.availablePerDay === 'custom' || false,
			trigger: () => this.handleUnchecks('custom'),
		},
	});

	public handleChange() {
		const blockValues = this.props?.scheduleUnits;
		const values = this.form.getValue('scheduleUnits');

		if (blockValues !== values) {
			this.updateDays(values);
		}
	}

	public handleUnchecks(field): void {
		const value = this.availabilityForm.getValue(field);

		if (value) {
			this.setState({ available: field }, () =>
				this.props?.changeButton()
			);

			const fields = ['available', 'unavailable', 'custom'];
			const needToUncheck = fields.filter((f) => f !== field);

			(needToUncheck || []).map((f) =>
				this.availabilityForm.setValue(f, false)
			);
		}
	}

	public checkBlocks() {
		return !this.state.available;
	}

	public getOptions(): any {
		const disabled = this.props.disabledDays || [];
		const ownSelected = this.form.getValue('scheduleUnits') || [];
		const minusOwn =
			disabled.filter((day) => !ownSelected.includes(day)) || [];
		const availables = this.props?.days?.filter(
			(day: any) => !minusOwn.includes(day.value)
		);

		if (!availables.length && this.props.deleteBlock) {
			this.props.deleteBlock();
		}

		return availables;
	}

	public updateDays(v): any {
		// values which are not disabled for other components yet.
		const newValues = v.filter(
			(day) =>
				!(this.props.disabledDays || []).find((d) => isEqual(d, day))
		);
		// create new disabled
		const currentDisabled = [
			...newValues,
			...(this.props.disabledDays || []),
		];
		// filter which options are no longer disabled by this block.
		const needToPutBack = (
			get(this.props.block, 'scheduleUnits') || []
		).filter((day) => !v.find((d) => isEqual(d, day)));
		// remove the filtered options.
		const deletedFromDisabled = currentDisabled.filter(
			(day) => !needToPutBack.find((d) => isEqual(d, day))
		);

		if (!newValues.length && !needToPutBack.length) {
			return;
		}

		this.props.updateDays(deletedFromDisabled);
	}

	public render(): React.ReactElement {
		const isWeekButtonValid =
			(this.props?.disabledDays || []).filter((day) =>
				weekdays.includes(day)
			).length === 0;
		const isWeekendButtonValid =
			(this.props?.disabledDays || []).filter((day) =>
				weekends.includes(day)
			).length === 0;
		const isAllButtonValid = (this.props?.disabledDays || []).length === 0;

		return (
			<div className='w-100 palette--bgc-neutral-3 palette--bgc-neutral-2 p-3 border-radius-1 my-2 position-relative'>
				{this.props.enableDeleteButton && (
					<HoverableButton
						colorType='transparent-grey'
						icon='close'
						onClick={() =>
							this.props.deleteBlock &&
							this.props.deleteBlock(
								this.form.getValue('scheduleUnits') || []
							)
						}
						className='palette--c-neutral-6 position-absolute absolute-right-2 absolute-top-2'
					/>
				)}
				<div className='w-100'>
					<Select
						labelInfos={{
							label: 'Which days in the schedule below are valid?',
							info: false,
						}}
						field={this.form.getField('scheduleUnits')}
						options={this.getOptions()}
						classes='w-100'
						isMulti={true}
					/>
				</div>
				<div className='w-100 display-flex'>
					{isWeekButtonValid && (
						<HoverableButton
							className='border-radius-1 my-2 mr-2'
							colorType='avio-green'
							onClick={() => this.handleClick('week')}
							title='Select weekdays'
						/>
					)}
					{isWeekendButtonValid && (
						<HoverableButton
							className='border-radius-1 my-2 mr-2'
							colorType='avio-green'
							onClick={() => this.handleClick('weekend')}
							title='Select weekends'
						/>
					)}
					{isAllButtonValid && (
						<HoverableButton
							className='border-radius-1 my-2 mr-2'
							colorType='avio-green'
							onClick={() => this.handleClick()}
							title='Select every day'
						/>
					)}
					<HoverableButton
						className='border-radius-1 my-2'
						colorType='cancel'
						onClick={() => this.handleClick('remove')}
						title='Remove days'
					/>
				</div>

				{!!(this.form.getValue('scheduleUnits') || []).length && (
					<div className='w-100'>
						<div className='Availability w-100 FormContainer my-3'>
							<Checkbox
								field={this.availabilityForm.getField(
									'available'
								)}
								labelInfos={{
									label: 'Available 24h every day',
								}}
								disabled={this.state?.available === 'available'}
								labelOnLeft={true}
							/>
							<Checkbox
								field={this.availabilityForm.getField(
									'unavailable'
								)}
								labelInfos={{ label: 'Always unavailable' }}
								classes='px-0 px-md-2'
								disabled={
									this.state?.available === 'unavailable'
								}
								labelOnLeft={true}
							/>
							<Checkbox
								field={this.availabilityForm.getField('custom')}
								labelInfos={{ label: 'Custom' }}
								disabled={this.state?.available === 'custom'}
								labelOnLeft={true}
							/>
						</div>

						{this.props.add === 'atc' &&
							this.state.available === 'available' && (
								<AtcAdditions
									ref={(ref: any) => (this.atc = ref)}
									values={this.state.adds}
									open={true}
								/>
							)}
						{this.props.add === 'arff' &&
							this.state.available === 'available' && (
								<ArffAdditions
									ref={(ref: any) => (this.arff = ref)}
									values={this.state.adds}
									open={true}
								/>
							)}

						{this.state.available === 'custom' && (
							<div className='w-100'>
								{this.renderBlockHeader()}
								{this.renderBlocks()}

								<HoverableButton
									title='Add Schedule'
									colorType='avio-green'
									className='border-radius-1 mt-2'
									onClick={() => this.handleAddBlock()}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}

	public handleClick(type: any = null) {
		if (type === 'week') {
			const choosedOptions = [
				...DAYS.filter(
					(day) =>
						!!(this.form.getValue('scheduleUnits') || []).includes(
							day.value
						)
				),
				...DAYS.filter((day: any) => weekdays.includes(day.value)),
			];
			const newValues = choosedOptions.map((opt) => opt?.value);

			this.form.setValue('scheduleUnits', newValues);
		} else if (type === 'weekend') {
			const choosedOptions = [
				...DAYS.filter(
					(day) =>
						!!(this.form.getValue('scheduleUnits') || []).includes(
							day.value
						)
				),
				...DAYS.filter((day: any) => weekends.includes(day.value)),
			];
			const newValues = choosedOptions.map((opt) => opt?.value);

			this.form.setValue('scheduleUnits', newValues);
		} else if (type === 'remove') {
			this.form.setValue('scheduleUnits', []);
		} else {
			const newValues = DAYS.map((opt) => opt?.value);

			this.form.setValue('scheduleUnits', newValues);
		}
	}

	public handleAddBlock(): any {
		this.setState({ blocks: [...this.state.blocks, randomId()] });
	}

	public renderBlockHeader(): React.ReactElement {
		return (
			<div className='w-100 p-2'>
				<div className='w-100'>
					<span className='palette--c-neutral-5 m-0'>
						All times should be in local.
					</span>
				</div>
				<div className='row display-none display-sm-flex'>
					<span className='col-4 palette--c-neutral-5 fw-bold text-uppercase m-0'>
						From
					</span>
					<span className='col-4 palette--c-neutral-5 fw-bold text-uppercase m-0'>
						To
					</span>
					<span className='col-4 palette--c-neutral-5 fw-bold text-uppercase m-0'>
						Availability
					</span>
				</div>
			</div>
		);
	}

	public renderBlocks(): any {
		return (this.state.blocks || []).map((block: any) => {
			const key = typeof block === 'string' ? block : randomId();

			return (
				<Block
					key={key}
					ref={(ref: any) => (this.block[key] = ref)}
					block={block || false}
					add={this.props.add || false}
					deleteBlock={() => this.deleteBlock(block)}
				/>
			);
		});
	}

	public deleteBlock(key): void {
		this.setState({
			blocks: (this.state.blocks || []).filter(
				(block) => !isEqual(key, block)
			),
		});
	}

	public handleSubmit(): any {
		const form: any = this.form.generateJSON();
		const days = form.scheduleUnits || [];
		const selected = this.state.available;
		const adds = this.getAdds();

		if (selected === 'available' || selected === 'unavailable') {
			return {
				available: 'custom',
				availablePerDay: selected,
				scheduleUnits: days,
				blocks: [
					{
						...adds,
						validFrom: '00:00',
						validTo: '23:59',
						status:
							this.state.available === 'available'
								? 'FULL'
								: 'CLOSED',
					},
				],
			};
		}

		const blocks = Object.keys(this.block)
			.map((key) => this.block[key] && this.block[key].getData())
			.filter((block) => !!block);

		return {
			available: 'custom',
			availablePerDay: selected,
			scheduleUnits: days,
			blocks: blocks,
		};
	}

	public getAdds(): any {
		if (
			this.props.add === 'atc' &&
			this.state.available === 'available' &&
			this.atc
		) {
			return this.atc.getForm();
		}

		if (
			this.props.add === 'arff' &&
			this.state.available === 'available' &&
			this.arff
		) {
			return this.arff.getForm();
		}

		return {};
	}

	public async validateBlocks(): Promise<any> {
		if (this.state.available === 'custom') {
			const blockKeys = Object.keys(this.block);
			let error = false;

			for (const key of blockKeys) {
				if (this.block[key]) {
					const validation = await this.block[key].validateForm();

					if (!validation) {
						error = true;
					}
				}
			}

			return error;
		}

		return false;
	}
}
