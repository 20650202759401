import * as React from 'react';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router';
import { ConfigActions } from '../../actions/config.actions';
import { DetailsActions } from '../../actions/details.actions';
import { UserDetailsActions } from '../../actions/user-details.actions';
import { UserActions } from '../../actions/user.actions';
import { timer } from '../helper-fuctions';

interface AuthorizedRouteProps extends RouteProps {
    layout: any;
    config: any;
    identifier: any;

    [key: string]: any;
}

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
    setConfig: (config: any) => dispatch(ConfigActions.setConfig(config)),
    setCurrentNavigationPath: (path) => dispatch(DetailsActions.setCurrentNavigationPath(path)),
    tryRedirectIfNotAuthenticated: () => dispatch(UserActions.tryRedirectIfNotAuthenticated()),
    checkAuthentication: () => dispatch(UserActions.checkAuthentication()),
    setUserDetails: () => dispatch(UserDetailsActions.setUserDetails()),
});

class AuthorizedRouteComponent extends React.Component<AuthorizedRouteProps, any> {
    public async componentDidMount(): Promise<void> {
        this.props.setConfig(this.props.config);
        this.props.setCurrentNavigationPath(this.props.currentNavigationPath);

        timer(this.props.checkAuthentication(), 5000);
    }

    public async componentDidUpdate(
        prevProps: Readonly<AuthorizedRouteProps>,
        prevState: Readonly<any>,
        snapshot?: any
    ): Promise<void> {
        this.props.setConfig(this.props.config);

        if (this.props.identifier !== prevProps.identifier) {
            await this.props.tryRedirectIfNotAuthenticated();
        }

        if (this.props.setUserDetails) {
            this.props.setUserDetails();
        }
    }

    public render(): any {
        return React.createElement(this.props.component, this.props);
    }
}

export const AuthorizedRoute: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    AuthorizedRouteComponent
);
