import { Modal } from '../ui-components/modal/modal';
import * as React from 'react';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';

export class ConfirmationModal extends React.Component<any, any> {
	public modal$: Modal;

	public state = {
		disableButton: false,
	};

	public open(): void {
		this.modal$.open();
	}

	public closeModal() {
		if (this.props.close) {
			this.props?.close();
		} else {
			this.modal$.close();
		}
	}

	public close(): void {
		this.modal$.close();
	}

	public render(): React.ReactElement {
		return (
			<Modal
				ref={(ref: any) => (this.modal$ = ref)}
				title={() => this.renderHeader()}
				content={() => this.renderContent()}
				footer={() => this.renderFooter()}
				onDismissRequest={() => this.closeModal()}
			/>
		);
	}

	public renderHeader(): React.ReactElement {
		return (
			<div className='w-100 p-4'>
				<h3 className='palette--c-neutral-6 m-0'>
					{this.props.title || 'Are you sure you want to delete?'}
				</h3>
			</div>
		);
	}

	public renderContent(): React.ReactElement {
		if (this.props.renderCustomContent) {
			return this.props.renderCustomContent();
		}

		return (
			<div className='w-100 p-4'>
				<p className='palette--c-neutral-6 m-0 my-4'>
					{this.props.content ||
						"If you delete this item, you won't be able to see it anymore."}
				</p>
			</div>
		);
	}

	public renderFooter(): React.ReactElement {
		return (
			<div className='w-100 p-4 display-flex w-100 justify-content-end'>
				<HoverableButton
					className='border-radius-1'
					colorType='cancel'
					title='Cancel'
					onClick={() => this.closeModal()}
				/>
				<HoverableButton
					disabled={this.props.disableButton || false}
					className='border-radius-1 ml-2'
					colorType={
						this.props.disableButton
							? 'disabled'
							: this.props.color
							? this.props.color
							: 'delete'
					}
					onClick={() => this.handleOnClick()}
					title={
						this.props.buttonTitle
							? this.props.buttonTitle
							: 'Delete'
					}
				/>
			</div>
		);
	}

	public async handleOnClick() {
		this.setState({ disableButton: true });

		if (this.props.onClick) {
			await this.props.onClick();
		}
	}
}
