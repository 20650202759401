import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
	checkData,
	getData,
	getFields,
} from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import {
	arrayToClass,
	redirectToLogin,
} from '../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../utilities/request';
import { ToastMessages } from '../../../../notifications/toast-messages';
import { Form } from '../../../../ui-components/form/form';
import { ContributionFooter } from '../../../shared/contributions/contribution-footer';
import { WidgetContributionPresenter } from '../../../shared/contributions/widget-contribution-presenter/widget-contribution-presenter';
import { providerFields } from './constants';
import { FuelContribution } from './fuel-provider-contribution';
import { HandlingContribution } from './handling-provider-contribution';
import { ImagesContribution } from './image-contribution';

interface ProviderContributionProps {
	item: any;
	original: any;
	onSuccess: () => void;
	close: () => void;
	user?: any;
}

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class ProviderContributionComponent extends React.Component<
	ProviderContributionProps,
	any
> {
	public images$: { [key: string]: ImagesContribution } = {};
	public handling$: any;
	public fuel$: any;

	public state: any = {
		editable: [],
		rejected: [],
		isAdmin: get(this.props, 'user.roles', []).includes('ADMIN'),
		type: get(this.props.item, 'contribution.action'),
		buttonDisabled: false,
		editAll: false,

		isHandling: get(this.props.item, 'category', null) === 'HANDLING',
		isFuel: get(this.props.item, 'category', null) === 'FUEL',
	};

	public form: any = new Form({
		name: this.props?.item?.name,
		category: {
			value: this.props?.item?.category,
			trigger: () => this.handleCategoryChange(),
		},
		email: this.props?.item?.email,
		phone: this.props?.item?.phone,
		outOfHoursPhone: this.props?.item?.outOfHoursPhone,
		vhf: !this.props?.item?.vhf
			? null
			: this.props?.item?.vhf % 1 === 0
			? `${this.props?.item?.vhf}.000`
			: this.props?.item?.vhf,
		website: this.props?.item?.website,
		sita: this.props?.item?.sita,
		aftn: this.props?.item?.aftn,
		streetName: this.props?.item?.streetName,
		locality: this.props?.item?.locality,
		municipality: this.props?.item?.municipality,
		/* governingDistrict:  this.props?.item?.name, */
		postalCode: this.props?.item?.postalCode,
		country: this.props?.item?.country,
		featuredOrder: this.props?.item?.featuredOrder,
		paymentMethods: this.props?.item?.paymentMethods,
		archived: this.props?.item?.archived,
	});

	public handleCategoryChange() {
		const category = this.form.getValue('category');
		this.setState({
			isHandling: category === 'HANDLING',
			isFuel: category === 'FUEL',
		});
	}

	public renderEmpty(): React.ReactElement {
		return (
			<div className='display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2'>
				<p className='palette--c-neutral-5 m-0'>Nothing changed.</p>
			</div>
		);
	}

	public render(): React.ReactElement {
		const providerList: any = getFields(this, providerFields(this));

		return (
			<div className='Content w-100'>
				<div className='p-2'>{this.renderContent(providerList)}</div>
				<ContributionFooter
					buttonDisabled={this.state.buttonDisabled}
					isAdmin={this.state.isAdmin}
					handleButtonClick={(isReject: boolean = false) =>
						this.handleButtonClick(isReject)
					}
					close={() => this.close()}
					handleEdit={() => this.handleEdit()}
				/>
			</div>
		);
	}

	public renderContent(list): React.ReactElement {
		return (
			<div className='w-100'>
				{(!list || !list.length) && this.renderEmpty()}
				{list && !!(list || []).length && (
					<div>
						<p className='m-0 fw-bold palette--c-neutral-6'>
							Changes in Provider widget
						</p>
						<div className='p-2 display-none display-md-flex'>
							<h6 className='Property palette--c-neutral-5 fw-bold m-0'>
								Property
							</h6>
							<h6 className='CurrentData palette--c-neutral-5 fw-bold m-0'>
								Current data
							</h6>
							<span className='ArrowIcons material-icons palette--c-neutral-1 fw-bold'>
								arrow_forward
							</span>
							<h6 className='NewData palette--c-neutral-5 fw-bold m-0'>
								New data
							</h6>
						</div>

						{list.map((item: any, index: number) => {
							return this.renderFields(item, index);
						})}
					</div>
				)}
				{this.state.isHandling && this.renderHandling()}
				{this.state.isFuel && this.renderFuel()}
				{this.renderImages()}
				{this.renderLogo()}
			</div>
		);
	}

	public renderHandling() {
		const original = get(this.props.original, 'handlingProvider');
		const item = get(this.props.item, 'handlingProvider');

		return (
			<div>
				<p className='pt-2 pl-4 m-0 fw-bold palette--c-neutral-6'>
					Handling Provider:{' '}
				</p>
				<HandlingContribution
					ref={(ref: any) => (this.handling$ = ref)}
					original={original}
					isAdmin={this.state.isAdmin}
					item={item}
				/>
			</div>
		);
	}

	public renderFuel() {
		const original = get(this.props.original, 'fuelProvider');
		const item = get(this.props.item, 'fuelProvider');

		return (
			<div>
				<p className='pt-2 pl-4 m-0 fw-bold palette--c-neutral-6'>
					Fuel Provider:{' '}
				</p>
				<FuelContribution
					ref={(ref: any) => (this.fuel$ = ref)}
					original={original}
					isAdmin={this.state.isAdmin}
					item={item}
				/>
			</div>
		);
	}

	public renderImages() {
		const isPictures = !!(get(this.props.item, 'pictures', []) || [])
			.length;
		return (
			<div>
				<p className='pt-2 pl-4 m-0 fw-bold palette--c-neutral-6'>
					Images:{' '}
				</p>
				{!isPictures && this.renderEmpty()}
				{isPictures &&
					get(this.props.item, 'pictures', []).map((item, index) => {
						const original = (
							get(this.props.original, 'pictures') || []
						).find((i) => get(i, 'aid') === get(item, 'entityAid'));

						return (
							<ImagesContribution
								ref={(ref: any) =>
									(this.images$[`image-${index}`] = ref)
								}
								key={`image-${index}`}
								original={original}
								item={item}
								isAdmin={this.state.isAdmin}
								editAll={this.state.editAll}
							/>
						);
					})}
			</div>
		);
	}

	public renderLogo() {
		return (
			<div>
				<p className='py-2 pl-4 m-0 fw-bold palette--c-neutral-6'>
					Logo:{' '}
				</p>
				{!get(this.props.item, 'logo') && this.renderEmpty()}
				{!!get(this.props.item, 'logo') && (
					<ImagesContribution
						ref={(ref: any) => (this.images$['logo'] = ref)}
						key='logo'
						original={get(this.props.original, 'logo')}
						item={get(this.props.item, 'logo')}
						isAdmin={this.state.isAdmin}
						editAll={this.state.editAll}
					/>
				)}
			</div>
		);
	}

	private renderFields(field, index): any {
		const wrapperClassName = arrayToClass([
			this.state.rejected.includes(field.type)
				? 'palette--bgc-neutral-3'
				: `palette--bgc-${
						this.state.type === 'UPDATE'
							? 'yellow-1'
							: this.state.type === 'CREATE'
							? 'green-1'
							: 'red-1'
				  }`,
			'border-radius-1 my-2 p-2',
			'display-flex align-items-center',
		]);

		const element = {
			type: field.type,
			content: this.getData(field.type),
			component: field.component,
		};

		return (
			<WidgetContributionPresenter
				isAdmin={this.state.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(field.type)}
				toValue={element}
				isEditable={this.state.editable.includes(field.type)}
				isRejected={this.state.rejected.includes(field.type)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				className={wrapperClassName}
			/>
		);
	}

	private getOriginalData(field: any) {
		const form = this.props.original?.[field];
		const value = form;

		if (this.state.type === 'CREATE') {
			return null;
		}

		return getData(field, value, false);
	}

	private getData(field: any): any {
		const form = this.form.generateJSON();
		const value = form?.[field];

		if (this.state.type === 'DELETE') {
			return null;
		}

		return getData(field, value, true);
	}

	private async handleEdit(type: string = 'editAll'): Promise<void> {
		if (type === 'editAll') {
			const providerArray =
				providerFields(this).map((item: any) => item.type) || [];
			await this.setState({ editable: providerArray, editAll: true });
		}

		if (!this.state.editable.includes(type)) {
			await this.setState({ editable: [...this.state.editable, type] });
		}
	}

	private async handleMissEdit(type: string): Promise<void> {
		if (this.state.editable.includes(type)) {
			const editableArray = this.state.editable.filter(
				(item) => item !== type
			);
			await this.setState({ editable: editableArray });
		}
	}

	private handleReject(type: string): void {
		if (!this.state.rejected.includes(type)) {
			this.setState({ rejected: [...this.state.rejected, type] });
		}
	}

	private handleMissReject(type: string) {
		if (this.state.rejected.includes(type)) {
			const rejectedArray = this.state.rejected.filter(
				(item) => item !== type
			);
			this.setState({ rejected: rejectedArray });
		}
	}

	private clearForm(): void {
		this.form.clearForm();
	}

	private checkModifications() {
		const obj = get(this.props, 'item');
		const formList = getFields(this, providerFields(this)) || [];
		const form = this.form.generateJSON();
		const changes = checkData(formList, obj, form, ['postalCode']);

		obj.contribution.rejectedChanges = this.state.rejected;
		obj.contribution.changes = this.generateChanges(changes);

		return obj;
	}

	private generateChanges(changes) {
		const category = this.props?.original?.category;
		if (category === 'HANDLING') {
			return [...changes, 'handlingProvider'];
		} else if (category === 'FUEL') {
			return [...changes, 'fuelProvider'];
		} else {
			return changes;
		}
	}

	private getChanges(provider) {
		const keys = Object.keys(this.images$);

		let changedImageList: any = [];
		let changedLogo = null;

		for (let key of keys) {
			const newData = this.images$[key].getContribution();

			if (key === 'logo') {
				changedLogo = newData;
			} else {
				changedImageList = [...changedImageList, newData];
			}
		}

		if (this.state.isHandling && this.handling$) {
			const handlingProvider = this.handling$.getContribution();
			provider.handlingProvider = handlingProvider;

			if (!!(handlingProvider?.changes || []).length) {
				provider.contribution.changes = [
					...provider.contribution.changes,
					'handlingProvider',
				];
			}
		}

		if (this.state.isFuel && this.fuel$) {
			const fuelProvider = this.fuel$.getContribution();
			provider.fuelProvider = fuelProvider;

			if (!!(fuelProvider?.changes || []).length) {
				provider.contribution.changes = [
					...provider.contribution.changes,
					'fuelProvider',
				];
			}
		}

		provider.pictures = changedImageList;
		provider.logo = changedLogo;
		return provider;
	}

	private async handleButtonClick(isReject = false): Promise<void> {
		this.setState({ buttonDisabled: true });
		let data = get(this.props, 'item');

		if (isReject) {
			data.contribution.status = 'REJECTED';
		} else {
			data = this.checkModifications();
		}

		const withChildrens = this.getChanges(data);

		try {
			const response = await RequestManager.post(
				'/providers/merge/withChildren',
				withChildrens
			);

			if (!response) {
				throw new Error('There was an error');
			}

			this.onSuccess(
				`Contribution is successfully ${
					isReject ? 'rejected' : 'accepted'
				}`
			);
		} catch (err) {
			this.onError(err);
		} finally {
			this.setState({ buttonDisabled: false });
		}
	}

	private onSuccess(message: string): void {
		this.clearForm();
		toast.success(message, { theme: 'light' });
		this.props.onSuccess();
	}

	private onError(err): void {
		toast.error(<ToastMessages error={err} />, { theme: 'light' });
		redirectToLogin(err);
	}

	private close(): void {
		this.clearForm();
		this.props.close();
	}
}

export const ProviderContribution: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(ProviderContributionComponent);
