import * as React from 'react';
import { connect } from 'react-redux';
import { connectNLP } from '../../../../utilities/connect-navigate';
import { isInQuery } from '../../../../utilities/helper-fuctions';
import { Map } from '../../../map/map';
import './map.scss';

const mapStateProps = (store: any) => ({
	radius: store.localStorage.radius,
	mLength: store.measures.length,
	open: !store.details.open,
});

const mapDispatchProps = (dispatch: any) => ({});

class MapWidgetComponent extends React.Component<any, any> {
	public modal$;

	public state: any = {
		width: null,
		response: null,
		viewport: this.getCoords(),
	};

	public getCoords() {
		const lon = this.props?.airport?.coordinates?.longitude;
		const lat = this.props?.airport?.coordinates?.latitude;

		if (!lon && !lat) {
			this.setState({ error: true });
		}

		const viewport = {
			viewport: {
				center: [lat, lon],
				zoom: 14,
			},
		};

		return viewport;
	}

	public isMobile() {
		if (
			navigator.userAgent.indexOf('IEMobile') !== -1 ||
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			return true;
		}

		return false;
	}

	public render(): React.ReactElement {
		if (this.state.error) {
			return (
				<div className='w-100 display-flex justify-content-center py-6'>
					<p className='palette--c-neutral-5'>Map is unavailable</p>
				</div>
			);
		}

		const mapProps = {
			dragging: !this.isMobile(),
			clickEnabled: this.isMobile(),
			withoutZIndex: true,
		};

		return (
			<Map
				airport={this.props?.airport}
				mapProps={mapProps}
				handleGoTo={() => this.handleGoTo()}
				scrollLayer={!this.isMobile()}
				isScroll={false}
				useMarkers={true}
			/>
		);
	}

	public handleGoTo() {
		if (this.isMobile()) {
			const utmSource = isInQuery('utm_source') || '';
			this.props?.router?.navigate({
				pathname: `/airports/${this.props?.airport?.aid}/map`,
				search: utmSource,
			});
		}
	}
}

export const MapWidget: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(connectNLP(MapWidgetComponent));
