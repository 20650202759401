import * as React from 'react';
import { getQuery } from '../../utilities/helper-fuctions';
import { RequestManager } from '../../utilities/request';
import { Form } from '../ui-components/form/form';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';
import { Select } from '../ui-components/inputs/select/select';

export class ContributionFilter extends React.Component<any, any> {
	public state = {
		error: false,
		options: [],
	};

	public form = new Form({
		systems: getQuery()?.systemUser || true,
		actions: this.getActions(),
	});

	public actionOptions = [
		{ value: 'UPDATE', title: 'Update' },
		{ value: 'CREATE', title: 'Create' },
		{ value: 'DELETE', title: 'Delete' },
	];

	public getActions() {
		const actions: any = getQuery()?.actions || '';

		return actions.split(',');
	}

	public componentDidMount() {
		this.fetchOptions();
	}

	public async fetchOptions() {
		this.setState({ loading: false });

		try {
			const response = await RequestManager.get('/users/system');

			if (!response) {
				throw new Error('Error in fetching');
			}

			this.formatResponse(response);
		} catch (err) {
			this.setState({ error: err });
		}
	}

	public formatResponse(list) {
		const originalList = list.map((item) => ({
			value: item.aid,
			title: (item.name || '').replace(/_/gi, ' '),
		}));
		const allOption = { value: true, title: 'SHOW ALL' };

		const options = [...originalList, allOption];

		this.setState({ options: options });
	}

	public render() {
		return (
			<div className='display-flex flex-column flex-sm-row justify-content-start justify-content-sm-end align-items-start align-items-sm-center palette--bgc-neutral-1 border-radius-1 p-2'>
				<p className='m-0 palette--c-neutral-5'>Filters: </p>

				<Select
					isMulti={true}
					field={this.form.getField('actions')}
					classes='limit-w mx-0 mx-sm-2 py-2 py-sm-0'
					options={this.actionOptions || []}
				/>
				<Select
					field={this.form.getField('systems')}
					classes='limit-w mx-0 mx-sm-2 py-2 py-sm-0'
					options={this.state?.options || []}
				/>

				<HoverableButton
					className='w-100 w-sm-unset'
					colorType='transparent-grey'
					onClick={() => this.handleFilter()}
					icon='search'
				/>
			</div>
		);
	}

	public handleFilter() {
		if (this.props.handleFilter) {
			const system = this.form.getValue('systems');
			const actions = this.form.getValue('actions');

			this.props?.handleFilter({
				systemUser: system,
				actions: actions.join(','),
			});
		}
	}
}
