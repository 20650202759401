import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Footer } from '../../components/footer/footer';
import { arrayToClass, setTitleAndMeta } from '../../utilities/helper-fuctions';
import './login.layout.scss';

const logoWhite = require('../../assets/logo-white.png');

export const LoginLayout = (Component) => (props) => {
    const navigate = useNavigate();
    const layoutClasses = arrayToClass([
        'LoginLayout default',
        'position-relative',
        'palette--bgc-neutral-3',
        'display-flex flex-column',
    ]);

    const containerClasses = arrayToClass(['Container', 'flex-fill', 'p-2 p-md-0']);

    const footerClasses = arrayToClass(['w-100']);

    React.useEffect(
        () =>
            setTitleAndMeta(
                'aviowiki',
                'FREE aviation data for everybody. Details about airports, runways, authorities and FBOs.'
            ),
        []
    );

    const renderLogo = (): React.ReactElement => {
        return (
            <Link to="/">
                <div className="w-100 Logo display-flex justify-content-center">
                    <img src={logoWhite} alt="logo" className="display-block" />
                </div>
            </Link>
        );
    };

    return (
        <div className={layoutClasses}>
            {renderLogo()}

            <div className={containerClasses}>
                <Component {...props} router={{ navigate }} />
            </div>

            <div className={footerClasses}>
                <Footer />
            </div>
        </div>
    );
};
