import * as React from 'react';
import { toast } from 'react-toastify';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';
import { Tooltip } from '../ui-components/popover/popover';
import { arrayToClass } from '../../utilities/helper-fuctions';

export class EmailShower extends React.Component<any, any> {
    public state = {
        hovered: false,
    };

    public render() {
        const classNames = arrayToClass(['display-flex justify-content-center', this.props.classNames]);
        return (
            <div className={classNames}>
                <Tooltip
                    tooltip={this.renderPopover()}
                    trigger={
                        <HoverableButton
                            disabled={false}
                            className="border-radius-1 mr-1"
                            colorType="transparent-green"
                            onClick={() => this.handleMailTo()}
                            icon="mail"
                        />
                    }
                />
                <HoverableButton
                    disabled={false}
                    className="border-radius-1"
                    colorType="transparent-green"
                    icon="content_copy"
                    onClick={() => this.handleCopy()}
                />
            </div>
        );
    }

    public renderPopover() {
        return (
            <div className="PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1">
                <span className="palette--c-neutral-1">{this.props.email}</span>
            </div>
        );
    }

    public handleMailTo() {
        window.location.href = `mailto:${this.props.email}`;
    }

    public handleCopy() {
        navigator.clipboard
            .writeText(this.props.email)
            .then(() => {
                toast.success('Email address is copied to clipboard!', { theme: 'light' });
            })
            .catch((err) => err);
    }
}
