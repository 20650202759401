import * as React from 'react';
import { HoverableButton } from '../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Checkbox } from '../../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { formatOptions } from '../../../../ui-components/inputs/select/formatter';
import { SearchableSelect } from '../../../../ui-components/inputs/select/searchable-select';
import { Select } from '../../../../ui-components/inputs/select/select';
import { TextInput } from '../../../../ui-components/inputs/inputs/text-input';

export const authoritiesFields = (elmnt) => [
    {
        type: 'name',
        title: 'Name',
        component: <TextInput field={elmnt.form.getField('name')} classes="NewData" textType="camel" />,
    },
    {
        type: 'type',
        title: 'Type',
        component: <Select field={elmnt.form.getField('type')} options={formatOptions(types)} classes="NewData" />,
    },
    {
        type: 'landsideArea',
        title: 'Landside area',
        component: (
            <HoverableButton
                colorType="avio-green"
                className="border-radius-1"
                title="Add landside area polygon"
                onClick={() => elmnt.onClick('Landside area', 'landsideArea', 'form')}
            />
        ),
    },
    {
        type: 'airsideArea',
        title: 'Airside area',
        component: (
            <HoverableButton
                colorType="avio-green"
                className="border-radius-1"
                title="Add airside area polygon"
                onClick={() => elmnt.onClick('Airside area', 'airsideArea', 'form')}
            />
        ),
    },
];

export const customsDetailsFields = (elmnt) => [
    {
        type: 'allowCrewFromRegions',
        title: 'Crew allowed from regions',
        component: (
            <Checkbox field={elmnt.helper.getField('allowCrewFromRegions')} labelInfos={{ label: 'Worldwide' }} />
        ),
    },
    {
        type: 'allowPassengersFromRegions',
        title: 'Passengers allowed from regions',
        component: (
            <Checkbox field={elmnt.helper.getField('allowPassengersFromRegions')} labelInfos={{ label: 'Worldwide' }} />
        ),
    },
    {
        type: 'allowFlightsFromRegions',
        title: 'Flights allowed from areas',
        component: (
            <Checkbox field={elmnt.helper.getField('allowFlightsFromRegions')} labelInfos={{ label: 'Worldwide' }} />
        ),
    },
    {
        type: 'allowCrewFromCountries',
        title: 'Crew allowed from countries',
        component: (
            <SearchableSelect
                isMulti={true}
                value="name"
                title="name"
                classes="NewData"
                endpoint={'/free/countries/search'}
                field={elmnt.form.getField('allowCrewFromCountries')}
            />
        ),
    },
    {
        type: 'allowPassengersFromCountries',
        title: 'Passengers allowed from countries',
        component: (
            <SearchableSelect
                isMulti={true}
                value="name"
                title="name"
                classes="NewData"
                endpoint={'/free/countries/search'}
                field={elmnt.form.getField('allowPassengersFromCountries')}
            />
        ),
    },
    {
        type: 'allowFlightsFromCountries',
        title: 'Flights allowed from countries',
        component: (
            <SearchableSelect
                isMulti={true}
                value="name"
                title="name"
                classes="NewData"
                endpoint={'/free/countries/search'}
                field={elmnt.form.getField('allowFlightsFromCountries')}
            />
        ),
    },
    {
        type: 'services',
        title: 'Services',
        component: (
            <Select
                field={elmnt.form.getField('services')}
                classes="NewData"
                options={formatOptions(services, false)}
                isMulti={true}
            />
        ),
    },
    {
        type: 'visaProcessed',
        title: 'Visa processed',
        component: (
            <Select
                field={elmnt.form.getField('visaProcessed')}
                classes="NewData"
                options={formatOptions(visaProcessed, false)}
                isMulti={true}
            />
        ),
    },
];

export const types = [
    { value: 'AIR_TRAFFIC', title: 'Air traffic' },
    { value: 'BRIEFING', title: 'Briefing' },
    { value: 'COORDINATOR', title: 'Coordinator' },
    { value: 'CUSTOMS', title: 'Customs' },
    { value: 'FIRE', title: 'Fire' },
    { value: 'HEALTH', title: 'Health' },
    { value: 'OPERATOR', title: 'Operator' },
    { value: 'OWNER', title: 'Owner' },
    { value: 'REPORTING', title: 'Reporting' },
    { value: 'SECURITY', title: 'Security' },
    { value: 'WEATHER', title: 'Weather' },
];

export const countryTypes = [
    { title: 'Air traffic', value: 'AIR_TRAFFIC' },
    { title: 'Civil aviation', value: 'CIVIL_AVIATION' },
    { title: 'Customs', value: 'CUSTOMS' },
    { title: 'Immigration', value: 'IMMIGRATION' },
    { title: 'Landing rights', value: 'LANDING_RIGHTS' },
    { title: 'Licensing', value: 'LICENSING' },
    { title: 'Health', value: 'HEALTH' },
    { title: 'Military aviation', value: 'MILITARY_AVIATION' },
];

export const services = [
    { value: 'AGRICULTURE_CHECK', title: 'Agricultural check' },
    { value: 'CREW_VISA_ON_ARRIVAL', title: 'Crew visa on arrival' },
    { value: 'INTERNATIONAL_RUBBISH', title: 'International rubbish' },
    { value: 'PAX_VISA_ON_ARRIVAL', title: 'Pax visa on arrival' },
    { value: 'PAYMENT_OF_FEES', title: 'Payment of fees' },
];

export const visaProcessed = [
    { value: 'CREW', title: 'Crew' },
    { value: 'DIPLOMATIC', title: ' Diplomatic' },
    { value: 'ELECTRONIC', title: 'Electronic' },
    { value: 'IMMIGRANT', title: 'Immigrant' },
    { value: 'NON_IMMIGRANT_WORK', title: 'Non immigrant work' },
    { value: 'TOURISM', title: 'Tourism' },
];
