import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { RequestManager } from '../../../utilities/request';
import { ToastMessages } from '../../notifications/toast-messages';
import { Form } from '../../ui-components/form/form';
import { FormButtons } from '../../ui-components/hoverable-buttons/form-modal-buttons/form-buttons';
import { Input } from '../../ui-components/inputs/inputs/input';
import { formatOptions } from '../../ui-components/inputs/select/formatter';
import { Select } from '../../ui-components/inputs/select/select';
import { WidgetModal } from '../../widgets/widget-modal';

const types = [
    { value: 'AIRPORT' },
    { value: 'AIRPORT_AUTHORITY' },
    { value: 'AIRPORT_AUTHORITY_CONTACT' },
    { value: 'AIRPORT_AVAILABILITY' },
    { value: 'AIRPORT_NOTES' },
    { value: 'COUNTRY_AUTHORITY' },
    { value: 'COUNTRY_AUTHORITY_CONTACT' },
    { value: 'COUNTRY_NOTES' },
    { value: 'FLIGHT' },
    { value: 'FUEL_COST' },
    { value: 'FUEL_PRODUCT' },
    { value: 'PROVIDER' },
    { value: 'PROVIDER_AVAILABILITY' },
    { value: 'PROVIDER_PRODUCT' },
    { value: 'REGION' },
    { value: 'RUNWAY' },
];

export class ManageWebhook extends WidgetModal<any, any> {
    public state = {
        webhook: false,
        buttonDisabled: true,
    };

    public form = new Form(
        {
            url: this.props?.webhook?.url || '',
            types: this.props?.webhook?.types || '',
        },
        (changed) => this.setState({ buttonDisabled: !changed })
    );

    public onClose(isCahnged = false) {
        if (this.props.onClose) {
            this.props.onClose(isCahnged);
        }

        this.modal$.close();
    }

    public getTitle() {
        return this.props?.webhook ? 'Edit webhook' : 'Add new webhook';
    }

    public renderForm(): React.ReactElement {
        return (
            <form className="w-100 p-4">
                <Input field={this.form.getField('url')} classes="py-2" labelInfos={{ label: 'URL' }} />
                <Select
                    isMulti={true}
                    classes="py-2"
                    field={this.form.getField('types')}
                    options={formatOptions(types, false)}
                    labelInfos={{ label: 'Types' }}
                />
            </form>
        );
    }

    public renderFooter() {
        return (
            <FormButtons
                onClose={() => this.onClose()}
                saveDisabled={this.state?.buttonDisabled}
                onSave={() => this.onSave()}
            />
        );
    }

    public async onSave(): Promise<any> {
        try {
            const form = this.form.generateJSON();
            let response = false;

            if (this.state.webhook) {
                response = await RequestManager.put(`/webhooks/${get(this.state, 'webhook.aid')}`, form);
            } else {
                response = await RequestManager.post('/webhooks', form);
            }

            if (!response) {
                throw new Error('Error');
            }

            this.onClose(true);
        } catch (err) {
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
        }
    }
}
