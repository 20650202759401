import * as React from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { arrayToClass } from '../../../utilities/helper-fuctions';
import './popover.scss';

export const Tooltip = (props) => {
	const { tooltip, trigger, isOpen, triggerOnly, triggerClasses } = props;
	const [open, setOpen] = React.useState(false);
	const useOwnOpen = typeof isOpen !== 'boolean';

	React.useEffect(() => {
		if (typeof isOpen === 'boolean') {
			setOpen(isOpen);
		}
	});

	const renderPopover = (args) => {
		return (
			<ArrowContainer
				position={args.position}
				childRect={args.childRect}
				popoverRect={args.popoverRect}
				arrowColor={props.arrowColor || '#23262e'}
				arrowSize={5}
			>
				{tooltip}
			</ArrowContainer>
		);
	};

	const renderChildren = (): any => {
		const classes = arrayToClass([
			'display-inline-flex',
			triggerClasses ? triggerClasses : '',
		]);

		return (
			<div
				onMouseEnter={() => handleToggle(true)}
				onMouseLeave={() => handleToggle(false)}
				className={classes}
			>
				{trigger}
			</div>
		);
	};

	const handleToggle = (isOpen) => {
		if (useOwnOpen) {
			setOpen(isOpen);
		}
	};

	return (
		<Popover
			containerStyle={{ zIndex: '4001' }}
			containerClassName='PopoverContainer'
			isOpen={open}
			reposition={true}
			content={(args) => renderPopover(args)}
		>
			{triggerOnly ? trigger : renderChildren()}
		</Popover>
	);
};
