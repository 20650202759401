import * as React from 'react';
import { randomId } from '../../utilities/helper-fuctions';
import { RequestManager } from '../../utilities/request';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';
import { Runway } from './runway';

export class RunwayWrapper extends React.Component<any, any> {
	public runways$: { [key: string]: any } = {};

	public state = {
		runways: false,
		error: false,
		loading: false,
		newRunways: false,

		isSpacing: false,
		isDMS: true,

		runwayChanges: {},
	};

	public componentDidMount() {
		if (this.props.aid) {
			this.fetchRunways();
		}
	}

	public async fetchRunways() {
		try {
			this.setState({ loading: true });

			const response = await RequestManager.get(
				`/airports/${this.props.aid}/runways/all`
			);

			if (!response) {
				throw new Error('Error');
			}

			const runways = response.map((runway) => ({
				key: randomId(),
				runway: runway,
			}));

			this.setState({ runways: runways });
		} catch (err) {
			this.setState({ error: err });
		} finally {
			this.setState({ loading: false });
		}
	}

	public render() {
		if (this.state.loading) {
			return null;
		}

		const list: any = this.state.runways || [];
		const newList: any = this.state.newRunways || [];

		return (
			<div className='w-100'>
				<div className='display-flex justify-content-end p-3'>
					<div className='display-inline-block'>
						<HoverableButton
							type='button'
							className='border-radius-1'
							colorType='avio-green'
							title='Add new runway'
							onClick={() => this.handleAddNewRunway()}
						/>
					</div>
				</div>
				<div className='w-100 display-flex'>
					{(!!(list || []).length || !!(newList || []).length) && (
						<Runway
							isTitle={true}
							isSpacing={this.state.isSpacing}
							isDMS={this.state.isDMS}
						/>
					)}
					<div className='RunwaysWrapper position-relative display-flex'>
						{list.map((runway) => (
							<Runway
								aid={this.props.aid}
								key={runway.key}
								ref={(ref: any) =>
									(this.runways$[runway.key] = ref)
								}
								data={runway.runway}
								setSpacing={(value: any) =>
									this.setState({ isSpacing: value })
								}
								setDMS={(value: any) =>
									this.setState({ isDMS: value })
								}
								isSpacing={this.state.isSpacing}
								isDMS={this.state.isDMS}
								checkButtonState={(status) =>
									this.handleButtonStates(runway.key, status)
								}
							/>
						))}
						{newList.map((runway) => (
							<Runway
								aid={this.props.aid}
								key={runway.key}
								ref={(ref: any) =>
									(this.runways$[runway.key] = ref)
								}
								data={false}
								setSpacing={(value: any) =>
									this.setState({ isSpacing: value })
								}
								setDMS={(value: any) =>
									this.setState({ isDMS: value })
								}
								isSpacing={this.state.isSpacing}
								isDMS={this.state.isDMS}
								removeRunway={() =>
									this.removeRunway(runway.key)
								}
								checkButtonState={(status) =>
									this.handleButtonStates(runway.key, status)
								}
							/>
						))}
					</div>
				</div>
			</div>
		);
	}

	public handleButtonStates(key, status) {
		const runwayChanges: any = this.state?.runwayChanges;
		this.setState(
			{ runwayChanges: { ...runwayChanges, [key]: status } },
			() => this.handleButtonStatesChange()
		);
	}

	public handleButtonStatesChange() {
		const runwayChanges: any = Object.keys(this.state?.runwayChanges);
		let counter = 0;
		let enable = false;

		while (!enable && runwayChanges.length > counter) {
			const key = runwayChanges[counter];
			if (!!this.state?.runwayChanges?.[key]) {
				enable = true;
			} else {
				counter++;
			}
		}

		this.props?.disableButton(!enable);
	}

	public handleAddNewRunway() {
		let runways: any = this.state.newRunways || [];
		runways = [...runways, { key: randomId(), runway: false }];

		this.setState({ newRunways: runways });
	}

	public removeRunway(key) {
		let runways: any = this.state.newRunways || [];
		runways = runways.filter((runway) => runway.key !== key);

		this.setState({ newRunways: runways });
	}

	public async saveRunways(aid) {
		const keys = Object.keys(this.runways$);
		let counter = 0;
		let error: any = false;

		while (!error && keys.length > counter) {
			const key = keys[counter];
			let resp;

			if (this.runways$[key]) {
				resp = await this.runways$[key].saveRunway(aid);
			}

			if (!!resp) {
				error = true;
			} else {
				counter++;
			}
		}

		if (!!error) {
			return true;
		} else {
			return false;
		}
	}
}
