import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { connectNLP } from '../../../../utilities/connect-navigate';
import { ContributeButton } from '../../../other/contribute-button';
import { dimConvert } from '../../../../utilities/dim.transform';
import { TextRenderer } from '../../../other/text-renderer';
import { Widget } from '../../widget';
import './operational-data.scss';
import { isInQuery } from '../../../../utilities/helper-fuctions';
import { StatusMarker } from '../../../ui-components/statusmarker/status-marker';

const fuelsAvailableColors = {
	JET: 'neutral-6',
	AVGAS: 'blue-avio-blue',
	MOGAS: 'primary-4',
	SAF: 'neutral-1',
};

const mapStateProps = (store: any) => ({
	mass: store.measures.mass,
	length: store.measures.length,
});

const mapDispatchProps = (dispatch: any) => ({});

class OperationDataComponent extends Widget<any, any> {
	public renderContent(): React.ReactElement {
		return <div className='w-100'>{this.isEverythingNull()}</div>;
	}

	public isEverythingNull(): React.ReactElement {
		const airport = get(this.props, 'airport');

		const type = get(airport, 'type');
		const operator = get(airport, 'operator');
		const accessible = get(airport, 'accessibleFor');
		const rules = get(airport, 'ifr', null);
		const fuelsAvailable = get(airport, 'fuelsAvailable', null);
		const handling = get(airport, 'mandatoryHandling');
		const paxLimit = get(airport, 'paxLimit');
		const weightLimit = get(airport, 'weightLimit');
		const wingspan = get(airport, 'wingspanLimit');

		return (
			<div className='w-100 default'>
				{this.getData('Type', type)}
				{this.getData('Operator', operator)}
				{this.renderAcceptedTraffic(accessible)}
				{this.renderFlightRules(rules)}
				{this.renderFuelsAvailable(fuelsAvailable)}

				<h4 className='w-100 palette--c-neutral-6 mt-3'>
					Requirements
				</h4>
				{this.getData('Handling', this.getMandatory(handling))}
				{this.renderQualifications()}
				{this.renderNonScheduledPermission()}

				<h4 className='w-100 palette--c-neutral-6 mt-3'>Limitations</h4>
				{this.getData('Passengers', this.isPaxLimitLimited(paxLimit))}
				{this.getData('Weight', this.getWeight(weightLimit))}
				{this.getData('Wingspan', this.getWings(wingspan))}
			</div>
		);
	}

	public renderEmptyProperties(
		label: any,
		isAre: boolean = false
	): React.ReactElement {
		const aid = get(this.props, 'airport.aid');

		return (
			<div className='w-100 p-2'>
				<ContributeButton
					openModal={() =>
						this.props?.router?.navigate({
							pathname: `/airports/${aid}/operations`,
							search: isInQuery('urm_source') || '',
						})
					}
					label={label}
					isAre={isAre}
					buttonOnly={!label}
				/>
			</div>
		);
	}

	public getData(title: string, data: any) {
		if (data === null || !data) {
			return (
				<div className='row'>
					<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
						{title}
					</p>
					<div className='col-14'>
						{this.renderEmptyProperties(false)}
					</div>
				</div>
			);
		}

		let dt = data;

		if (data.includes('_')) {
			dt = data.replace('_', ' ');
		}

		return (
			<div className='row'>
				<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
					{title}
				</p>
				<p className='col-14 palette--c-neutral-6 p-2 m-0 fw-bold'>
					{dt}
				</p>
			</div>
		);
	}

	public renderAcceptedTraffic(trafficList: any): React.ReactElement {
		if (!trafficList || !trafficList.length) {
			return (
				<div className='row'>
					<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
						Accepted traffic
					</p>
					<div className='col-14'>
						{this.renderEmptyProperties(false)}
					</div>
				</div>
			);
		}

		return (
			<div className='row'>
				<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
					Accepted traffic
				</p>
				<div className='col-14 p-2'>
					{trafficList.map((traffic, index) => {
						let traf = traffic;

						if ((traffic || '').includes('_')) {
							traf = traffic.replace('_', ' ');
						}

						return (
							<div
								key={index}
								className='AidContainer palette--bgc-secondary-1 display-inline-block p-1 px-2 mr-2 mb-2'
							>
								<p className='m-0 palette--c-secondary-4 text-uppercase'>
									{traf}
								</p>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	public renderFlightRules(ifr: any): React.ReactElement {
		if (ifr === null) {
			return (
				<div className='row'>
					<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
						Flight Rules
					</p>
					<div className='col-14'>
						{this.renderEmptyProperties(false)}
					</div>
				</div>
			);
		}

		return (
			<div className='row'>
				<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
					Flight Rules
				</p>
				<div className='col-14 p-2 display-flex'>
					<p className='text-uppercase palette--c-secondary-6 fw-bold m-0'>
						VFR
					</p>
					{!ifr && (
						<p className='palette--c-secondary-6 fw-bold m-0'>
							&nbsp;only
						</p>
					)}
					{ifr && (
						<p className='palette--c-secondary-6 fw-bold m-0'>
							&nbsp;and&nbsp;
						</p>
					)}
					{ifr && (
						<p className='text-uppercase mr-2 palette--c-secondary-6 fw-bold m-0'>
							IFR
						</p>
					)}
				</div>
			</div>
		);
	}

	public renderFuelsAvailable(fuels): React.ReactElement {
		if (!fuels || !fuels.length) {
			return (
				<div className='row'>
					<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
						Fuels Available
					</p>
					<div className='col-14'>
						{this.renderEmptyProperties(false)}
					</div>
				</div>
			);
		}

		return (
			<div className='row'>
				<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
					Fuels Available
				</p>
				<div className='col-14 p-2'>
					{fuels.map((fuel) => {
						return (
							<StatusMarker
								key={fuel}
								tColor={
									fuel === 'SAF' ? 'primary-4' : 'neutral-1'
								}
								bgColor={fuelsAvailableColors[fuel]}
								classNames={`mr-2 border-1 palette--bc-${
									fuel === 'SAF'
										? 'primary-4'
										: fuelsAvailableColors[fuel]
								}`}
								text={fuel}
								type='tag'
							/>
						);
					})}
				</div>
			</div>
		);
	}

	public getMandatory(data: any) {
		if (data === null) {
			return null;
		}

		if (data) {
			return 'Mandatory';
		}

		return 'Not mandatory';
	}

	public renderQualifications(): React.ReactElement {
		const airport = get(this.props, 'airport');
		const mandatory = get(airport, 'mandatoryQualification');
		const links = get(airport, 'qualificationLink');
		const notes = get(airport, 'qualificationNotes');

		if (mandatory === null && links === null && notes === null) {
			return (
				<div className='row'>
					<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
						Qualification
					</p>
					<div className='col-14'>
						{this.renderEmptyProperties(false)}
					</div>
				</div>
			);
		}

		return (
			<div className='row'>
				<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
					Qualification
				</p>
				<div className='col-14 p-2'>
					<p className='palette--c-neutral-6 m-0 pb-2 fw-bold'>
						{this.getMandatory(mandatory)}
					</p>
					{links && (
						<p>
							<a
								href={links}
								className='WordBreak Link text-justify palette--c-neutral-5 m-0 text-decoration-none py-2'
							>
								{links}
							</a>
						</p>
					)}
					{notes && (
						<p className='WordBreak palette--c-neutral-6 py-2 m-0 fw-bold'>
							Notes:&nbsp;{notes}
						</p>
					)}
				</div>
			</div>
		);
	}

	public getPermitData(data: any) {
		if (data === null) {
			return null;
		}

		if (data === 'NONE') {
			return 'None required';
		}

		if (data === 'PPR') {
			return 'PPR';
		}

		if (data === 'SLOT') {
			return 'Slot';
		}

		if (data === 'UNAVAILABLE') {
			return 'Airport not available to non-scheduled flights';
		}

		return;
	}

	public renderNonScheduledPermission(): React.ReactElement {
		const nonScheduled = this.getPermitData(
			get(this.props, 'airport.nonScheduledPermission')
		);
		const notes = get(this.props, 'airport.nonScheduledPermissionNotes');

		if (!nonScheduled) {
			return (
				<div className='row'>
					<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
						Non-scheduled permission
					</p>
					<div className='col-14'>
						{this.renderEmptyProperties(false)}
					</div>
				</div>
			);
		}

		return (
			<div className='row'>
				<p className='col-10 palette--c-neutral-5 p-2 pl-4 m-0'>
					Non-scheduled permission
				</p>
				<div className='col-14'>
					<p className='palette--c-neutral-6 py-2 m-0 fw-bold'>
						{nonScheduled}
					</p>
					{notes && (
						<div className='py-2 m-0'>
							<p className='palette--c-neutral-6 fw-bold'>
								Notes:&nbsp;
							</p>
							<TextRenderer text={notes} />
						</div>
					)}
				</div>
			</div>
		);
	}

	public isPaxLimitLimited(value) {
		if (!value) {
			return null;
		}

		if (value === 999) {
			return 'Not limited';
		}

		return `Maximum ${value}`;
	}

	public getWeight(value: any) {
		if (!value) {
			return null;
		}

		if (value === 9999999) {
			return 'Not limited';
		}

		const target = get(this.props.mass, 'targetDimension', 'kg');

		return `Maximum ${dimConvert(value, 'kg', target, 0)} ${target}`;
	}

	public getWings(value: any) {
		if (!value) {
			return null;
		}

		if (value === 999) {
			return 'Not limited';
		}

		const target = get(this.props.length, 'targetDimension', 'm');

		return `Maximum ${dimConvert(value, 'm', target, 0)} ${target}`;
	}
}

export const OperationData: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(connectNLP(OperationDataComponent));
