import L from 'leaflet';
import { get } from 'lodash';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, Polygon, Tooltip } from 'react-leaflet';
import { arrayToClass } from '../../../utilities/helper-fuctions';
import { Map } from '../map';

const link = 'https://www.googlemaps.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=';

export class ProviderMap extends React.Component<any, any> {
    public render(): any {
        const provider = get(this.props.content, 'handlingProvider');
        const pax = get(provider, 'paxMeetingCoordinates');
        const fbo = get(provider, 'fboBuildingPolygon');
        const aircraft = get(provider, 'aircraftParkingPolygon');

        if (!pax && !fbo && !aircraft) {
            return null;
        }

        const bounds: any = { bounds: this.generateBoundaries([...(fbo || []), ...(aircraft || []), pax]) };

        if (!(bounds?.bounds || []).length) {
            return null;
        }

        const icon = L.divIcon({
            html: ReactDOMServer.renderToString(<span className="material-icons Dropoff">hail</span>),
            className: 'leaflet-div-maps-marker',
            iconAnchor: [15, 10],
        });

        const logo = get(this.props.content, 'logo');

        const className = arrayToClass(['map__reactleaflet', logo ? 'Margin' : '']);

        const fboPolygon = this.getPolygon(fbo);
        const aircraftPolygon = this.getPolygon(aircraft);

        const mapProps: any = {
            className: className,
            scrollWheelZoom: false,
            dragging: true,
            attributionControl: false,
        };

        return (
            <Map mapProps={mapProps} viewport={bounds} className="MapOnProvider" buttonPosition="bottom">
                {pax && (
                    <Marker
                        icon={icon}
                        position={[get(pax, 'latitude'), get(pax, 'longitude')]}
                        riseOnHover={true}
                        eventHandlers={{
                            click: () => this.handleOpenGoogleMaps([[get(pax, 'latitude'), get(pax, 'longitude')]]),
                        }}
                    >
                        {this.renderPopover('Pick-up and Drop-off')}
                    </Marker>
                )}
                {!!fboPolygon.length && (
                    <Polygon
                        positions={fboPolygon}
                        color="#800360"
                        weight={2}
                        eventHandlers={{
                            click: () => !pax && this.handleOpenGoogleMaps(fboPolygon),
                        }}
                    >
                        {this.renderPopover('Building')}
                    </Polygon>
                )}
                {!!aircraftPolygon.length && (
                    <Polygon positions={aircraftPolygon} color="#800360" weight={2}>
                        {this.renderPopover('Aircraft Parking')}
                    </Polygon>
                )}
            </Map>
        );
    }

    public renderPopover(text) {
        return (
            <Tooltip direction="top">
                <span className="palette--c-neutral-1 p-2">
                    {get(this.props.content, 'name')}&nbsp;
                    {text}
                </span>
            </Tooltip>
        );
    }

    public getCenter(coordinates) {
        const lats = coordinates.reduce((acc, q) => {
            return acc + q[0];
        }, 0);
        const lngs = coordinates.reduce((acc, q) => {
            return acc + q[1];
        }, 0);
        const devider = (coordinates || []).length;

        return {
            lat: lats / devider,
            lng: lngs / devider,
        };
    }

    public handleOpenGoogleMaps(coordinates) {
        const center: any = this.getCenter(coordinates);
        window.open(`${link}${center.lat},${center.lng}`, '_blank');
    }

    private getPolygon(list): any {
        if (!list) {
            return [];
        }

        return list.map((item: any) => [item.latitude, item.longitude]);
    }

    private generateBoundaries(list) {
        const minMaxCoords = list.reduce(
            (acc, arr) => {
                if (acc.minLat > get(arr, 'latitude') || !acc.minLat) {
                    acc.minLat = get(arr, 'latitude');
                }

                if (acc.maxLat < get(arr, 'latitude') || !acc.maxLat) {
                    acc.maxLat = get(arr, 'latitude');
                }

                if (acc.minLng > get(arr, 'longitude') || !acc.minLng) {
                    acc.minLng = get(arr, 'longitude');
                }

                if (acc.maxLng < get(arr, 'longitude') || !acc.maxLng) {
                    acc.maxLng = get(arr, 'longitude');
                }

                return acc;
            },
            { minLat: null, minLng: null, maxLat: null, maxLng: null }
        );

        return [
            [minMaxCoords.minLat - 0.01, minMaxCoords.minLng - 0.01],
            [minMaxCoords.minLat - 0.01, minMaxCoords.maxLng + 0.01],
            [minMaxCoords.maxLat + 0.01, minMaxCoords.minLng - 0.01],
            [minMaxCoords.maxLat + 0.01, minMaxCoords.maxLng + 0.01],
        ];
    }
}
