import * as React from 'react';
import { arrayToClass } from '../../../utilities/helper-fuctions';
import { Form } from '../form/form';
import { Switch } from './checkboxes/switch-checkbox/switch-checkbox';
import { Input } from './inputs/input';
import { Label } from './label/label';

export class VariationInput extends React.Component<any, any> {
	public form = new Form({
		value: {
			value: this.initializeValues(),
			trigger: () => this.handleValueChange(),
		},
		measure: {
			value: this.initializeValues(true),
			trigger: () => this.handleMeasureChange(),
		},
	});

	public handleValueChange() {
		const newValue = this.form.getValue('value');

		let valueToString = newValue.toString();

		if (!valueToString.match(/[0-9|.]/gi)) {
			valueToString = valueToString.replace(/\D/g, '');
		}

		if (valueToString.includes('.')) {
			const numberArray = valueToString.split('.');
			const whole = numberArray[0];
			const decimals = numberArray[1];

			if (decimals.length > 2) {
				const twoOnly = decimals.substring(0, 2);

				valueToString = `${whole}.${twoOnly}`;
			}
		}

		if (newValue !== valueToString) {
			this.form.setValue('value', valueToString);
		}

		if (this.props?.field?.newValue !== valueToString) {
			this.handleMeasureChange();
		}
	}

	public handleMeasureChange() {
		const value = this.form.getValue('value');
		const measure = this.form.getValue('measure');

		if (!!value) {
			const newValue = parseFloat(
				`${!!measure ? '+' : '-'}${Math.abs(value)}`
			);
			this.props.field.setValue(newValue);
		}
	}

	public initializeValues(isMeasure = false) {
		const originalValue = this.props.field.value;

		if (
			originalValue !== '' &&
			originalValue !== null &&
			originalValue !== undefined
		) {
			const value = isMeasure
				? originalValue > 0
				: Math.abs(originalValue);

			return value;
		}

		return isMeasure ? false : '';
	}

	public render() {
		const wrapperClasses = arrayToClass([
			this.props.classes ? this.props.classes : '',
		]);

		return (
			<div className={wrapperClasses}>
				{this.renderLabel()}
				<div className='display-flex mt-2'>
					<p className='m-0 palette--c-neutral-6'>West</p>
					<Switch
						field={this.form.getField('measure')}
						classNames='mx-2'
						disabled={this.props?.disabled || false}
					/>
					<p className='m-0 palette--c-neutral-6'>East</p>
				</div>
				<div className='MeasureWrapper display-flex align-items-end'>
					<Input
						inputType='number'
						field={this.form.getField('value')}
						classes='pt-2 limit-w'
						disabled={this.props?.disabled || false}
					/>
					<p className='ml-2 mb-1 palette--c-neutral-4'>º</p>
				</div>
			</div>
		);
	}

	public renderLabel() {
		const label = this.props?.labelInfos;
		const labelClasses = arrayToClass([
			'mb-2',
			label?.classes ? label?.classes : '',
			this.props?.field?.mandatory ? 'asterix' : '',
		]);

		if (!label) {
			return null;
		}

		return (
			<Label
				info={label.info || false}
				label={label.label || false}
				className={labelClasses}
				ignoreTopMargin={label.ignoreTopMargin || false}
				isSimple={label.isSimple || false}
			/>
		);
	}
}
