import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../../../actions/details.actions';
import {
	arrayToClass,
	isInQuery,
} from '../../../../../utilities/helper-fuctions';
import { PreLoader } from '../../../../../utilities/pre-loader';
import { RequestManager } from '../../../../../utilities/request';
import { PicturePresenter } from '../../../../image-management/picture-presenter/picture-presenter';
import { ProviderMap } from '../../../../map/widget-maps/provider-map';
import { ContributeButton } from '../../../../other/contribute-button';
import { ModalButtons } from '../../../../ui-components/hoverable-buttons/form-modal-buttons/modal-button';
import { HoverableButton } from '../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Modal } from '../../../../ui-components/modal/modal';
import { Spinner } from '../../../../ui-components/spinner/spinner';
import { StatusMarker } from '../../../../ui-components/statusmarker/status-marker';
import { ProviderCard } from '../provider-cards/provider-card';
import {
	AIRCRAFT_SERVICE_OPTIONS,
	FUEL_SERVICE_LEVEL,
	HANDLING_SERVICE_LEVEL,
	TERMINAL_SERVICE_OPTIONS,
} from '../provider-contribution/constants';
import { FuelProduct } from '../provider-products/fuel-products/fuel-product';
import { GenericProduct } from '../provider-products/generic-product/generic-product';
import { ProvidersAvailability } from '../provider-products/provider-availability/providers-availability';
import './provider-details.scss';

const mapStateProps = (store: any) => ({
	user: store.user.user,
	widgetSuccess: store.details.widgetSuccess,
});

const mapDispatchProps = (dispatch: any) => ({
	setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

class ProviderDetailsComponent extends React.Component<any, any> {
	public modal$: Modal;
	public fuelProductCreateModal$: any;
	public providerProductCreateModal$: any;
	public availabilityCreateModal$: any;
	public fuelCosts$: any;

	public state: any = {
		imageLoading:
			this.props?.response?.pictures || this.props?.response?.logo,
		pictures: [],
	};

	public async componentDidMount(): Promise<void> {
		await this.fetchImages();

		if (this.props?.error) {
			this.props?.onClose();
		} else {
			this.modal$.open();
		}
	}

	public componentDidUpdate(
		prevProps: any,
		prevState: any,
		snapshot?: any
	): void {
		if (
			this.props.widgetSuccess !== prevProps.widgetSuccess &&
			(this.props.widgetSuccess === 'products' ||
				this.props.widgetSuccess === 'availability')
		) {
			this.props.setSuccess();
		}
	}

	public async fetchImages() {
		const picutresList = this.props?.response?.pictures;

		let pictures: any = [];

		for (let picture of picutresList) {
			const file = await this.fetchFile(picture);

			if (!!file) {
				pictures = [...pictures, file];
			}
		}

		this.setState({ pictures: pictures, imageLoading: false });
	}

	public async fetchFile(picture) {
		try {
			const aid = get(picture, 'aid');
			const response = await RequestManager.get(
				`/free/files/${aid}/info`
			);

			if (!response) {
				return;
			}

			return { ...picture, ...response };
		} catch (err) {
			return;
		}
	}

	private hasExtras(onlyHandling = false): boolean {
		const category = this.props?.response?.category;

		if (!!onlyHandling) {
			return category === 'HANDLING';
		}

		return category === 'HANDLING' || category === 'FUEL';
	}

	public render(): React.ReactElement {
		return (
			<div className='ProviderDetailsContainer'>
				<Modal
					renderButtonOnly={true}
					ref={(ref: any) => (this.modal$ = ref)}
					content={() => this.renderContent()}
					onDismissRequest={() => this.handleClose()}
				/>
			</div>
		);
	}

	private renderContent(): React.ReactElement {
		const provider = this.props?.response;
		const pictures = this.props?.response?.pictures || [];
		const handling = provider?.handlingProvider;
		const fuel = provider?.fuelProvider;
		let serviceLevel: any = false;

		if (provider?.category === 'HANDLING') {
			serviceLevel =
				HANDLING_SERVICE_LEVEL.find(
					(item) =>
						item.value === get(handling, 'serviceLevel', false)
				) || null;
		}

		if (provider?.category === 'FUEL') {
			serviceLevel =
				FUEL_SERVICE_LEVEL.find(
					(item) => item.value === get(fuel, 'serviceLevel', false)
				) || null;
		}

		const statusMarkerClasses = arrayToClass(['display-flex flex-fill']);

		return (
			<div className='ProviderDetails w-100 border-radius-1 display-flex flex-wrap overflow-hidden'>
				{this.renderPicture()}

				<div className='ContentContainer p-4'>
					<h3 className='ProviderName palette--c-neutral-6 fw-bold m-0'>
						{get(provider, 'name')}
					</h3>
					<div className='display-flex'>
						<div className={statusMarkerClasses}>
							<div>
								<StatusMarker
									tColor='secondary-4'
									bgColor='secondary-1'
									text={get(provider, 'aid')}
									type='tag'
								/>
							</div>
							{serviceLevel && (
								<div className='ml-3'>
									<StatusMarker
										tColor='secondary-4'
										bgColor='secondary-1'
										text={get(serviceLevel, 'title')}
										type='tag'
									/>
								</div>
							)}
						</div>
						<div>{this.renderRecommendationButton()}</div>
					</div>
					{provider?.archived && (
						<p className='palette--c-neutral-5'>
							Permanently closed
						</p>
					)}

					<div className='py-2'>
						<ProviderCard
							content={{ ...provider, ...this.props.provider }}
							isDetails={true}
						/>
					</div>

					{!!(pictures || []).length && this.renderMap()}

					<div className='border-bottom-1 palette--bc-neutral-4 my-3' />

					<ProvidersAvailability
						aid={this.props?.response?.parent}
						providerAid={get(this.props, 'aid')}
						handleOpen={() =>
							this.handleModalOpen('provider-availability')
						}
						refresh={this.props.widgetSuccess === 'availability'}
					/>

					{this.renderPricing()}

					{this.hasExtras(true) && this.renderServices()}
				</div>
				{this.renderFooter()}
			</div>
		);
	}

	private renderEmptyProperties(
		text,
		open: () => void,
		isButtonOnly = false
	) {
		const props = {
			openModal: () => open(),
			isAre: true,
			buttonOnly: isButtonOnly,
			...text,
		};

		return <ContributeButton {...props} />;
	}

	private renderRecommendationButton() {
		return (
			<HoverableButton
				disabled={false}
				className='border-radius-1 ml-2'
				onClick={() => this.handleEditOpen()}
				titleLeft='Edit'
				icon='rate_review'
				colorType='avio-green'
			/>
		);
	}

	private renderPicture(): React.ReactElement {
		const originalPictures = this.props?.response?.pictures || [];

		if (!originalPictures.length || !originalPictures) {
			return this.renderMap();
		}

		const fetchedPictures = this.state?.pictures;

		return (
			<div className='PictureContainer position-relative'>
				{!!this.state.imageLoading && <Spinner size='medium' />}
				{!this.state?.imageLoading && (
					<PicturePresenter pictures={fetchedPictures} />
				)}
			</div>
		);
	}

	private renderMap(): React.ReactElement {
		return (
			<div className='PictureContainer position-relative'>
				<ProviderMap content={this.props?.response} />
			</div>
		);
	}

	private renderPricing(): React.ReactElement {
		const aid = this.props?.aid;

		return (
			<div className='w-100'>
				<div className='display-flex w-100 align-items-center'>
					<h3 className='palette--c-neutral-6 fw-bold m-0 flex-fill'>
						Pricing
					</h3>
					{this.createProductButtons()}
				</div>
				<div className='w-100 py-4 border-bottom-1 palette--bc-neutral-4 mb-4'>
					<div className='mb-4'>
						<h5 className='palette--c-neutral-5 text-uppercase'>
							Products and Services:
						</h5>
						<GenericProduct
							endpoint={`/providers/${aid}/providerProducts`}
							aid={aid}
							isEdit={true}
							emptyRenderer={() =>
								this.renderEmptyProperties(
									{
										text: 'No Generic Products listed for this Provider',
									},
									() =>
										this.handleModalOpen('generic-product')
								)
							}
						/>
					</div>
					<h5 className='palette--c-neutral-5 text-uppercase'>
						Fuels:
					</h5>
					<FuelProduct
						endpoint={`/providers/${aid}/fuelProducts/`}
						isEdit={true}
						aid={get(this.props, 'aid')}
						emptyRenderer={() =>
							this.renderEmptyProperties(
								{
									text: 'No Fuel Products listed for this Provider',
								},
								() => this.handleModalOpen('fuel-product')
							)
						}
					/>
				</div>
			</div>
		);
	}

	private createProductButtons() {
		return (
			<div className='display-flex mt-2 mb-2'>
				<HoverableButton
					className='border-radius-1 mr-2'
					colorType='avio-green'
					title='Add fuel product'
					onClick={() => this.handleModalOpen('fuel-product')}
				/>
				<HoverableButton
					className='border-radius-1'
					colorType='avio-green'
					title='Add generic product'
					onClick={() => this.handleModalOpen('generic-product')}
				/>
			</div>
		);
	}

	private renderServices(): React.ReactElement {
		return (
			<div className='w-100'>
				<h3 className='palette--c-neutral-6 fw-bold'>Services</h3>
				{this.renderService('Crew & Passengers', 'terminalServices')}
				{this.renderService('Aircraft', 'aircraftServices')}
			</div>
		);
	}

	private renderService(title, type) {
		const services = get(this.props?.response.handlingProvider, type, []);
		const list =
			type === 'terminalServices'
				? TERMINAL_SERVICE_OPTIONS
				: AIRCRAFT_SERVICE_OPTIONS;

		return (
			<div className='w-100 pt-3'>
				<h5 className='palette--c-neutral-5 text-uppercase'>{title}</h5>
				<div className='w-100'>
					<div className='w-100 mt-2'>
						{(!services || !services[0]) &&
							this.renderEmptyProperties(
								{ label: 'Services' },
								() => this.handleEditOpen()
							)}
					</div>
					<div className='display-flex flex-wrap mt-2'>
						{services &&
							!!services.length &&
							(list || []).map((item: any, index: number) =>
								this.renderServiceValues(services, item, index)
							)}
					</div>
				</div>
			</div>
		);
	}

	private renderServiceValues(
		services: any,
		service: any,
		index: number
	): React.ReactElement {
		if (services.includes(service.value)) {
			return (
				<div
					className='ServiceValues border-1 border-radius-1 palette--bc-neutral-5 p-1 mr-2 mb-2'
					key={index}
				>
					<p className='palette--c-neutral-5 m-0'>
						{service.value.replace(/_/g, ' ')}
					</p>
				</div>
			);
		}

		return (
			<div
				className='border-1 border-radius-1 palette--bc-neutral-4 palette--bgc-neutral-2 p-1 mr-2 mb-2'
				key={index}
			>
				<p className='NonExistedService palette--c-neutral-4 m-0'>
					{service.value.replace(/_/g, ' ')}
				</p>
			</div>
		);
	}

	private renderFooter(): React.ReactElement {
		const isAdmin = (this.props?.user?.roles || []).includes('ADMIN');

		return (
			<ModalButtons
				onClose={() => this.handleClose()}
				isDelete={isAdmin}
				type='provider'
				merge={'/providers/merge'}
				delete={`/providers/${this.props?.aid}`}
				changes={(resp) => this.getChanges(resp)}
			/>
		);
	}

	public getChanges(resp) {
		let mainChanges = Object.keys(resp).filter(
			(key) =>
				resp?.[key] !== null ||
				resp?.[key] !== undefined ||
				resp?.[key] !== ''
		);

		if (resp?.handlingProvider) {
			resp.handlingProvider.changes = Object.keys(
				resp?.handlingProvider
			).filter(
				(key) =>
					resp?.[key] !== null ||
					resp?.[key] !== undefined ||
					resp?.[key] !== ''
			);

			mainChanges = [...mainChanges, 'handlingProvider'];
		} else if (resp?.fuelProvider) {
			resp.fuelProvider.changes = Object.keys(resp?.fuelProvider).filter(
				(key) =>
					resp?.[key] !== null ||
					resp?.[key] !== undefined ||
					resp?.[key] !== ''
			);

			mainChanges = [...mainChanges, 'fuelProvider'];
		}

		resp.contribution.changes = mainChanges;

		return resp;
	}

	private handleClose(): void {
		this.props.goToAirports();

		this.modal$.close();
	}

	private handleEditOpen(): void {
		this.props.edit();
	}

	private handleModalOpen(type): void {
		const utmSource = isInQuery('utm_source');
		this.props?.router?.navigate({
			pathname: `${window.location.pathname}/${type}`,
			search: utmSource,
		});
	}
}

export const ProviderDetails: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(PreLoader(ProviderDetailsComponent));
