import { format } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../actions/details.actions';
import { TokenModal } from '../../../components/admin/token-modals/token.modal';
import { StatusMarker } from '../../../components/ui-components/statusmarker/status-marker';
import { ManagesLayout } from '../../../layouts/manages/manages.layout';
import { arrayToClass, randomId } from '../../../utilities/helper-fuctions';
import { BasicAdminScreen } from '../basic-admin/basic-admin.screen';

const mapStateProps = (store: any) => ({
	user: store.user.user,
	format: store.userDetails.date,
});

const mapDispatchProps = (dispatch: any) => ({
	toggleMobile: () => dispatch(DetailsActions.toggleOpenMobile()),
});

class TokenManagesScreenComponent extends BasicAdminScreen<any, any> {
	public getEndpoint() {
		return '/management/authTokens?API_TEST,API';
	}

	public getTitle(): string {
		return 'Manage tokens';
	}

	public renderContent(): React.ReactElement {
		const { response = [] } = this.state;
		const formatString = this.props.format || 'yyyy. MM. dd. HH:mm';

		return (
			<div className='Contents w-100 border-radius-1'>
				{(response as any[]).map((item, index) => {
					const { type, userAid, userName, expirationDate } = item;

					const classes = arrayToClass([
						'ListItem w-100 p-2 pointer',
						`palette--bgc-neutral-${index % 2 === 1 ? '1' : '2'}`,
					]);

					return (
						<div
							key={randomId()}
							className={classes}
							onClick={() => this.openModal(item)}
						>
							<div className='row display-flex align-items-center'>
								<div className='col-24 col-md-4'>
									<StatusMarker
										tColor='secondary-4'
										bgColor='secondary-1'
										text={(type || '').replace('_', ' ')}
										type='tag'
									/>
								</div>
								<div className='col-24 col-md-4'>
									<StatusMarker
										tColor='secondary-4'
										bgColor='secondary-1'
										text={userAid}
										type='tag'
									/>
								</div>
								<p className='overflow-hidden col-24 col-md-6 m-0 palette--c-neutral-6'>
									{userName}
								</p>
								<p className='overflow-hidden col-24 col-md-6 m-0 palette--c-neutral-6'>
									{format(
										new Date(expirationDate),
										formatString
									)}
								</p>
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	public renderUser(item) {
		const { name } = item?.state?.response;

		return (
			<p className='overflow-hidden m-0 palette--c-neutral-6'>{name}</p>
		);
	}

	public renderModal(): React.ReactElement {
		const { aid } = this.props?.router?.params;

		return (
			<TokenModal
				isEdit={true}
				endpoint={`/users/${aid}`}
				aid={aid}
				onClose={(isChanged) => this.handleClose(isChanged)}
			/>
		);
	}

	public handleClose(isChanged) {
		this.setState({ selected: false, isModal: false });

		if (isChanged) this.fetch();
	}

	public openModal(item) {
		this.props?.router?.navigate({
			pathname: `/admin/tokens/${item.userAid}`,
			search: window.location.search,
		});

		this.setState({ isModal: true });
	}
}

export const TokenManagesScreen: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(ManagesLayout(TokenManagesScreenComponent));
