import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../../../../actions/details.actions';
import { arrayToClass, replaceQuery } from '../../../../../../utilities/helper-fuctions';
import { PreLoader } from '../../../../../../utilities/pre-loader';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';

const mapStateProps = (store: any) => ({
    widgetSuccess: store.details.widgetSuccess,
});

const mapDispatchProps = (dispatch: any) => ({
    setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

class FuelProductComponent extends React.Component<any, any> {
    public componentDidUpdate(prevProps: any, prevState: any, snapshot?: any) {
        if (this.props.widgetSuccess !== prevProps.widgetSuccess && this.props.widgetSuccess === 'fuel') {
            this.props?.refresh();
            this.props?.setSuccess();
        }
    }

    public render(): React.ReactElement {
        const fuelProducts = this.props?.response?.content;

        if (!fuelProducts || !(fuelProducts || []).length) {
            if (this.props.emptyRenderer) {
                return this.props.emptyRenderer();
            }

            return <></>;
        }

        return (
            <div className="w-100 border-radius-1 border-radius-1 border-1 palette--bc-neutral-3 overflow-hidden">
                {fuelProducts.map((product: any, index: number) => {
                    const classNames = arrayToClass([
                        'w-100',
                        `palette--bgc-${index % 2 === 0 ? 'neutral-2' : 'neutral-1'}`,
                    ]);

                    return (
                        <div className={classNames} key={index}>
                            <div className="display-flex p-2 row">
                                <p className="palette--c-neutral-6 m-0 col-10">
                                    {product.type && product.type.replace(/_/g, ' ')}
                                </p>
                                <div className="display-flex col-12 justify-content-end">
                                    <p className="palette--c-neutral-6 m-0 mr-2">{product.price}</p>
                                    <p className="palette--c-neutral-6 m-0">{product.currency}</p>
                                    {product.unit && <p className="palette--c-neutral-6 m-0 mr-2">/{product.unit}</p>}
                                </div>
                                <div className="col-2 display-flex justify-content-end">
                                    <HoverableButton
                                        disabled={false}
                                        className="border-radius-1"
                                        colorType="transparent-green"
                                        icon="rate_review"
                                        onClick={() => this.handleEdit(product?.aid)}
                                    />
                                </div>
                            </div>

                            <div className="p-2">
                                {product.description && <p>{product.description}</p>}
                                {product.minimumVolume >= 0 && product.maximumVolume && (
                                    <div className="display-flex justify-content-start align-items-center">
                                        <p className="palette--c-neutral-5">Valid between</p>
                                        <p className="palette--c-neutral-6 mx-2">{product.minimumVolume}</p>
                                        <p className="palette--c-neutral-5">and</p>
                                        <p className="palette--c-neutral-6 mx-2">{product.maximumVolume}</p>
                                        {product.unit && <p className="palette--c-neutral-6">{product.unit}</p>}
                                    </div>
                                )}
                                {!!product.selfService && <p className="palette--c-neutral-5">Self service only</p>}
                                {typeof product.minimumHandlingWaiver == 'number' &&
                                    product.minimumHandlingWaiver >= 0 && (
                                        <div className="display-flex justify-content-start align-items-center">
                                            <p className="palette--c-neutral-5 mr-2">Minimum amount of</p>
                                            <p className="palette--c-neutral-6">{product.minimumHandlingWaiver}</p>
                                            {product.unit && (
                                                <p className="palette--c-neutral-6 mx-2">{product.unit}</p>
                                            )}
                                            <p className="palette--c-neutral-5">to waive handling charges.</p>
                                        </div>
                                    )}
                                {!!product.limitedAvailability && (
                                    <div className="display-flex justify-content-start align-items-center">
                                        <p className="palette--c-yellow-2 material-icons">warning</p>
                                        <p className="palette--c-neutral-5 ml-2">this product is in short supply!</p>
                                    </div>
                                )}
                            </div>

                            {/* <div className="p-2">
                                <FuelCosts
                                    aid={get(product, 'aid')}
                                    refresh={this.props.widgetSuccess === 'fuel-products'}
                                />
                            </div> */}
                        </div>
                    );
                })}
            </div>
        );
    }

    public handleEdit(aid) {
        const newQueries = replaceQuery({ edit: true });
        this.props?.router?.navigate({ pathname: `${window.location.pathname}/${aid}`, search: newQueries });
    }
}

export const FuelProduct: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    PreLoader(FuelProductComponent)
);
