import * as React from 'react';
import { HoverableButton } from '../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { LoginLayout } from '../../layouts/login/login.layout';
import './forgot-password.screen.scss';

export const ForgotPasswordFeedbackScreenComponent = (props) => {
    return (
        <div className="ForgotPasswordScreen w-100 display-flex justify-content-center">
            <div className="ForgotPasswordContainer w-100 palette--bgc-neutral-1 border-radius-1 p-6 elevation-1">
                <h2 className="palette--c-neutral-6 mb-4">Check your inbox!</h2>

                <div className="display-flex flex-column flex-sm-row mb-6">
                    <p className="palette--c-neutral-4">
                        We sent an email to the address provided, with instructions on how to change your password.
                    </p>
                </div>

                <div className="w-100 display-flex justify-content-end">
                    <HoverableButton
                        colorType="transparent-grey"
                        icon="arrow_back"
                        titleRight="Go back to login"
                        onClick={() => props?.router?.navigate('/login')}
                        isSave={true}
                        isClose={true}
                        type="button"
                    />
                </div>
            </div>
        </div>
    );
};

export const ForgotPasswordFeedbackScreen: any = LoginLayout(ForgotPasswordFeedbackScreenComponent);
