import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { UserActions } from '../../actions/user.actions';
import { ToastMessages } from '../../components/notifications/toast-messages';
import { WhyAvioModal } from '../../components/notifications/why-avio-modal';
import { Form } from '../../components/ui-components/form/form';
import { HoverableButton } from '../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { Checkbox } from '../../components/ui-components/inputs/checkboxes/checkbox/checkbox';
import { Input } from '../../components/ui-components/inputs/inputs/input';
import { Password } from '../../components/ui-components/inputs/inputs/password';
import { TextInput } from '../../components/ui-components/inputs/inputs/text-input';
import { EmailPreferences } from '../../components/user-profile/user-settings/email-preferences';
import { LoginLayout } from '../../layouts/login/login.layout';
import { isInQuery } from '../../utilities/helper-fuctions';
import './registration.scss';

const mapStateProps = (store: any) => ({
    registrationSuccess: store.user.registrationSuccess,
    registrationError: store.user.registrationError,
});

const mapDispatchProps = (dispatch: (action: any) => void) => ({
    register: (data: any) => dispatch(UserActions.registration(data)),
});

class RegistrationScreenComponent extends React.Component<any, any> {
    public email$;
    public whyAvio$: WhyAvioModal;

    public form = new Form({
        fullName: '',
        email: '',
        password: '',
        passwordAgain: '',
        cookies: false,
    });

    public render(): React.ReactElement {
        return (
            <div className="RegistrationScreen w-100 display-flex justify-content-center">
                <div className="RegistrationContainer w-100 palette--bgc-neutral-1 border-radius-1 p-6 elevation-1">
                    <h2 className="palette--c-neutral-6 mb-4">Create new account</h2>

                    <div className="display-flex flex-column flex-sm-row mb-6">
                        <p className="palette--c-neutral-5">Already have an account?</p>
                        <a href="/login" className="text-decoration-none">
                            <p className="RegistrationLink fw-bold">&nbsp;Sign in</p>
                        </a>
                    </div>

                    <form>
                        <TextInput
                            type="text"
                            field={this.form.getField('fullName')}
                            classes="mb-6"
                            textType="camel"
                            labelInfos={{ label: 'Full name' }}
                        />
                        <Input
                            type="text"
                            field={this.form.getField('email')}
                            classes="mb-6"
                            labelInfos={{ label: 'Email' }}
                        />

                        <div className="display-flex flex-column flex-sm-row mb-6">
                            <p className="palette--c-neutral-5">Why do I need to provide this </p>
                            <div onClick={() => this.whyAvio$.open()}>
                                <p className="RegistrationLink pointer">&nbsp;information?</p>
                            </div>

                            <WhyAvioModal ref={(ref: any) => (this.whyAvio$ = ref)} />
                        </div>

                        <Password
                            field={this.form.getField('password')}
                            classes="mb-6"
                            labelInfos={{ label: 'Password' }}
                        />

                        <Password
                            field={this.form.getField('passwordAgain')}
                            classes="mb-6"
                            labelInfos={{ label: 'Repeat password' }}
                        />

                        <EmailPreferences innerRef={(ref: any) => (this.email$ = ref)} />

                        <Checkbox
                            field={this.form.getField('cookies')}
                            classes="py-2"
                            labelInfos={{
                                label: (
                                    <div className="CheckboxLabel display-flex flex-wrap">
                                        <p className="palette--c-neutral-5 m-0">I accept the&nbsp;</p>
                                        <div
                                            onClick={() =>
                                                window.open('https://aviowiki.com/privacy-policy/', '_blank')
                                            }
                                            className="ml-0 text-decoration-none"
                                        >
                                            <p className="RegistrationLink m-0">Privacy Policy</p>
                                        </div>
                                        <p className="palette--c-neutral-5 m-0">&nbsp;and&nbsp;</p>
                                        <div
                                            onClick={() => window.open('https://aviowiki.com/terms/', '_blank')}
                                            className="ml-0 text-decoration-none"
                                        >
                                            <p className="RegistrationLink m-0">Terms and Conditions</p>
                                        </div>
                                    </div>
                                ),
                            }}
                        />

                        <div className="w-100 display-flex justify-content-end">
                            <HoverableButton
                                colorType="avio-green"
                                titleLeft="Create account"
                                icon="arrow_forward"
                                className="border-radius-1 elevation-1"
                                onClick={() => this.handleRegistration(this.form.generateJSON())}
                                isSave={true}
                            />
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    public async handleRegistration(form): Promise<void> {
        if (!form.fullName || !form.password || !form.passwordAgain || !form.email) {
            const err = 'All stared fields are mandatory, but some of them are missing.';
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
            return;
        }

        if (form.password !== form.passwordAgain) {
            const err = 'The password and the confirmation password do not match.';
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
            return;
        }

        if (!form.cookies) {
            const err = 'You need to agree to our Privacy Policy and Terms before registering';
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
            return;
        }

        if (form.password === form.passwordAgain && form.cookies) {
            const credentials = {
                login: form.email,
                name: form.fullName,
                password: form.password,
            };

            const emailPrefs = this.email$?.form?.generateJSON();

            await this.props.register({
                user: credentials,
                mailPreferences: emailPrefs,
            });
        }

        if (this.props.registrationError) {
            const err =
                get(this.props.registrationError, 'response.data.message') || this.props.registrationError || 'Error';
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
            return;
        }

        if (this.props.registrationSuccess) {
            const utmSource = isInQuery('utm_source');
            this.props?.router?.navigate({ pathname: '/login', search: utmSource });
            return;
        }
    }
}

export const RegistrationScreen: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    LoginLayout(RegistrationScreenComponent)
);
