import { format } from 'date-fns';
import { isEqual } from 'lodash';
import * as React from 'react';
import { randomId } from '../../../../../utilities/helper-fuctions';
import { HoverableButton } from '../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { formatOptions } from '../../../../ui-components/inputs/select/formatter';
import { SubTab } from '../../../../ui-components/tab/sub-tab';
import { WidgetModal } from '../../../widget-modal';
import {
	AIRFRAME,
	DIRECTION,
	FILING,
	PURPOSE,
	RULES,
	STATUSES,
} from '../opening-hours-contribution/constants';
import './limited-info-details-modal.scss';

export class LimitedInfoDetailsModal extends WidgetModal<any, any> {
	public state = {
		...this.state,
		current: {
			title: this.title(this.props?.current),
			other: this.props?.current,
			color: this.getColor(this.props?.current),
		},
	};

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (!isEqual(this.props?.current, prevProps?.current)) {
			this.setState(
				{
					current: {
						title: this.title(this.props?.current),
						other: this.props?.current,
						color: this.getColor(this.props?.current),
					},
				},
				() => this.modal$.open()
			);
		}
	}

	public onClose() {
		this.modal$.close();
		this.props?.onClose();
	}

	public getTitle() {
		return 'Availability details';
	}

	public renderForm() {
		const current = this.state?.current || this.props.current;
		const all = this.props?.data || [];

		const titles = (all || []).map((item: any) => ({
			title: this.title(item),
			other: item,
			color: this.getColor(item),
		}));

		const { openFor = [] } = current?.other?.info || [];

		return (
			<div className='w-100'>
				<SubTab
					items={titles}
					onItemClick={(data) => this.changeView(data)}
					selected={current}
				/>

				{!openFor.length && (
					<div className='p-4 py-2'>{this.renderOther(current)}</div>
				)}

				{!!openFor.length && (
					<div className='p-4 py-2'>
						{openFor.map((item) => this.renderOpenFor(item))}
					</div>
				)}
			</div>
		);
	}

	public renderOther(current) {
		let { notes, status } = current;

		if (!notes) {
			notes = 'No notes added';
		}

		if (status === 'CLOSED') {
			notes = 'Take-off and landing not permitted';
		}

		if (status === 'FULL') {
			notes = 'Take-off and landing permitted';
		}

		return (
			<div className='w-100 py-6 display-flex justify-content-center'>
				<p className='palette--c-neutral-5'>{notes}</p>
			</div>
		);
	}

	public renderOpenFor(item) {
		const { priorNoticeRequired, costsInvolved, other } = item;
		const flightTypes = this.sortFlightTypes(item.flightTypes);

		const pnrText = priorNoticeRequired
			? `${priorNoticeRequired}h notice required`
			: 'Prior notice not required';
		const costsText = costsInvolved
			? 'and additional costs'
			: costsInvolved === false
			? 'and no additional costs'
			: '';

		return (
			<ul className='ListForFlightTypes' key={randomId()}>
				<li className='palette--c-neutral-6'>
					{pnrText} {costsText}. {!!other ? other : ''}
				</li>
				{(flightTypes || []).map((flightType, index) => {
					const length = (flightTypes || []).length;
					const {
						direction,
						airframe,
						rules,
						filing,
						purpose,
						status,
					} = flightType;

					return (
						<div key={randomId()}>
							<ul className='my-2'>
								{this.renderType(
									direction,
									DIRECTION,
									'DIRECTION'
								)}
								{this.renderType(
									airframe,
									AIRFRAME,
									'AIRFRAME'
								)}
								{this.renderType(rules, RULES, 'RULES')}
								{this.renderType(filing, FILING, 'FILING')}
								{this.renderType(purpose, PURPOSE, 'PURPOSE')}
								{this.renderType(status, STATUSES, 'STATUS')}
							</ul>
							{index < length - 1 && (
								<p className='Separator palette--c-neutral-6'>
									or
								</p>
							)}
						</div>
					);
				})}
			</ul>
		);
	}

	public renderType(data, options, title) {
		if (!(data || []).length) {
			return null;
		}

		const listForTitles = formatOptions(options, false).filter((option) =>
			data.includes(option?.value)
		);
		const listText = listForTitles.map((item) => item?.title).join(', ');

		return (
			<li key={randomId()}>
				<span className='palette--c-neutral-5'>{title}:</span>
				<span className='palette--c-neutral-6 ml-2'>{listText}</span>
			</li>
		);
	}

	public renderFooter() {
		return (
			<div className='w-100 display-flex align-items-center justify-content-end p-4'>
				<HoverableButton
					className='border-radius-1 mr-2'
					colorType='cancel'
					title='Cancel'
					onClick={() => this.onClose()}
				/>
			</div>
		);
	}

	public title(item = false) {
		const formatString = 'HH:mm';
		const formatableData = item || this.state?.current?.other;

		const formatedValidFrom = format(
			new Date(formatableData?.validFrom),
			formatString
		);
		const formatedValidTo = format(
			new Date(formatableData?.validTo),
			formatString
		);

		return `${formatedValidFrom} ${item ? '-' : 'and'} ${formatedValidTo}`;
	}

	public getColor(item) {
		const { status } = item;

		if (status === 'LIMITED') {
			return 'yellow-2';
		} else if (status === 'FULL') {
			return 'primary-4';
		} else if (status === 'CLOSED') {
			return 'red-2';
		}

		return 'neutral-4';
	}

	public changeView(data) {
		this.setState({ current: data });
	}

	public sortFlightTypes(flightTypes) {
		const flightTypesWithDir = flightTypes.filter(
			(item) => !!(item?.direction || []).length
		);
		const flightTypesWithoutDir = flightTypes.filter(
			(item) => !(item?.direction || []).length
		);

		const sortedFlightTypes = [
			...flightTypesWithDir.sort((first, second) => {
				const rank = this.sortByDataValue(first, second);
				return rank;
			}),
			...flightTypesWithoutDir.sort((first, second) => {
				const rank = this.sortByDataValue(first, second);
				return rank;
			}),
		];

		return sortedFlightTypes;
	}

	public sortByDataValue(first, second) {
		const firstKeys = Object.keys(first);
		const secondKeys = Object.keys(second);

		const firstDefinedKeys = firstKeys.filter(
			(key) => !!(first?.[key] || []).length
		);
		const secondDefinedKeys = secondKeys.filter(
			(key) => !!(second?.[key] || []).length
		);

		const firstLength = (firstDefinedKeys || []).length;
		const secondLength = (secondDefinedKeys || []).length;

		if (firstLength < secondLength) {
			return -1;
		}

		if (firstLength > secondLength) {
			return 1;
		}

		return 0;
	}
}
