import * as React from 'react';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router';
import { ConfigActions } from '../../actions/config.actions';
import { DetailsActions } from '../../actions/details.actions';
import { UserDetailsActions } from '../../actions/user-details.actions';
import { UserActions } from '../../actions/user.actions';
import { timer } from '../helper-fuctions';

interface PublicRouteProps extends RouteProps {
    layout: any;
    config: any;

    [key: string]: any;
}

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
    setConfig: (config: any) => dispatch(ConfigActions.setConfig(config)),
    setCurrentNavigationPath: (path) => dispatch(DetailsActions.setCurrentNavigationPath(path)),
    checkAuthentication: () => dispatch(UserActions.checkAuthentication()),
    setUserDetails: () => dispatch(UserDetailsActions.setUserDetails()),
});

class PublicRouteComponent extends React.Component<PublicRouteProps, any> {
    public componentDidMount(): void {
        if (this.props.setConfig) {
            this.props.setConfig(this.props.config);
        }

        if (this.props.setCurrentNavigationPath) {
            this.props.setCurrentNavigationPath(this.props.currentNavigationPath);
        }

        if (this.props.setUserDetails) {
            this.props.setUserDetails();
        }

        timer(this.props.checkAuthentication(), 1000);
    }

    public render(): any {
        return React.createElement(this.props.component, this.props);
    }
}

export const PublicRoute: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    PublicRouteComponent
);
