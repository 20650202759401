export const airportInfos = {
    icao:
        'The 4-letter code issued by International Civil Aviation Organisation used to identify an airport or heliport.',
    iata:
        'The 3-letter code issued by International Air Transport Association used to identify an airport or heliport.',
    localIdentifier: 'An alphanumeric code which is local to the country and used to identify an airport.',
    elevation: 'The elevation of the Aerodrome Reference Point.',
    variation:
        'The difference between True North and Magnetic North at an airport or heliport. To be expressed in degrees with 2 decimal places.',
    name: '	The name of the airport. This should be the official name of the airport written in Latin characters.',
    coordinates:
        'A set of numbers which represent the Aerodrome Reference Point (the designated geographical location of an airport or heliport).',
    servedCity:
        'A string containing the name of the city served by the airport. In some countries, like the USA, this includes the state. For example: Los Angeles, CA.',
    type:
        '|AIRPORT| A defined area, often certified by a local authority, with infrastructure to support commercial operations, and at least one runway in service. \n|HELIPORT| A location uniquely designated to the operation of helicopters or other VTOL aircraft. \n|AIRSTRIP| An area prepared for the operation of aircraft. This differs from |AIRPORT| in that the quality of the landing surface and surrounding infrastructure may not be guaranteed, surveyed or certified by a suitable authority. \n|WATER| A site used by aircraft on floats. This may be defined by buoys or other markings, or not.',
    operator:
        'The type of organisation responsible for the direction and management of the airport. \n|PUBLIC| A governmental body or a private or public company on behalf of a governmental body, in the public interest (i.e. London Heathrow). \n|PRIVATE| An individual or a company, for revenue or not, where there is no involvement of public bodies in the decision making. \n|MILITARY| A military, or paramilitary entities, such as Air Force, Army, Coast Guard, State Police, etc. Access to an airport operated in such a way may be granted under rules set by the operating entity. \n|JOINT| Part operated by a military or paramilitary body. The aerodrome may have a dedicated military side, or military interests in other forms, like training grounds.',
    accessibleFor:
        'The type of traffic which has access to the airport. \n|SCHEDULED| Civil flights of a scheduled nature. Usually notified to Air Traffic Control with ICAO flightplan of type S \n|NON SCHEDULED| Civil flights of non-scheduled commercial nature. Usually notified to Air Traffic Control with an ICAO flightplan of type N. \n|GENERAL AVIATION| Civil flights of a private nature. Usually notified to Air Traffic Control with an ICAO flightplan of type G. \n|TRAINING| Civil flights performed for training. \n|BUSINESS AVIATION| Civil flights of a private or commercial nature. These are usually operated with aircraft of any type or size, but generally in configurations of less than 19 passengers. \n|MILITARY| Any type of military traffic. \n|HELICOPTER| Any type of flight operated by a VTOL aircraft.',
    ifr:
        'The airport is capable and authorised to accept aircraft operating in instrument meteorological conditions (IFR) or visual meteorological conditions (VFR).',
    mandatoryHandling: 'The use of a ground handling company is mandatory due to local regulations.',
    mandatoryQualification:
        'A pilot qualification is required before operating at the airport due to local regulations or airport characteristics e.g. mountainous terrain or obstructions.',
    qualificationLink:
        'If available, a URL which links to the process for pilots to obtain the required qualification.',
    nonScheduledPermission:
        'The permission required for non-scheduled flights (of either a private or commercial nature) to operate at the airport. \n|NONE| No permission is required. \n|UNAVAILABLE| Non scheduled traffic is not accepted at the airport. \n|PPR| A permission which is obtained prior to operating at the airport and once obtained does not restrict the aircraft to operate at a certain time during the day. \n|SLOT| A permission obtained prior to operating at an airport where demand exceeds capacity and therefore restricts the aircraft to operate at the time for which the permission was issued.',
    paxLimit: 'The maximum number of passengers allowed for each aircraft operating at the airport.',
    weightLimit: 'The highest Maximum Take-off Mass or Weight allowed for an aircraft operating at the airport.',
    wingspanLimit: 'The largest wingspan allowed for an aircraft operating at the airport.',
    timeZone: 'Should be in IANA format.'
};
