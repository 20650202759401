import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../../../../../actions/details.actions';
import {
	arrayToClass,
	redirectToLogin,
} from '../../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../../utilities/request';
import { ConfirmationModal } from '../../../../../notifications/confirmation-modal';
import { ToastMessages } from '../../../../../notifications/toast-messages';
import { Form } from '../../../../../ui-components/form/form';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { TextInput } from '../../../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../../ui-components/inputs/select/select';
import { ValidationInput } from '../../../../../ui-components/inputs/validation-input/validation-input';
import {
	placeholders,
	typeOpts,
} from '../contributions/authority-contacts.helper';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
	mergeContribution: (data, endpoint, widgetId, callback) =>
		dispatch(
			DetailsActions.mergeContribution(data, endpoint, widgetId, callback)
		),
});

class AuthorityContactsEditComponent extends React.Component<any, any> {
	public delete$: ConfirmationModal;

	public state: any = {
		validityError: !this.props?.originalValues,
		isEdit: !this.props?.originalValues,
		buttonDisabled: !!this.props?.originalValues,
		deleted: false,
	};

	public form: any = new Form(
		{
			name: this.props?.values?.name,
			type: { value: this.props?.values?.type, mandatory: true },
			details: { value: this.props?.values?.details, mandatory: true },
		},
		() => this.handleFormChange()
	);

	public setToDefault() {
		const formKeys = Object.keys(this.form.generateJSON());
		formKeys.map((key) =>
			this.form.setValue(key, this.props?.values?.[key])
		);
		this.handleFormChange();
	}

	public handleFormChange() {
		const changed = this.form.hasChanged();

		if (!!changed && !this.state.validityError) {
			this.setState({ buttonDisabled: false }, () =>
				this.props?.checkChange()
			);
		} else {
			this.setState({ buttonDisabled: true }, () =>
				this.props?.checkChange()
			);
		}
	}

	public onClose(): void {
		this.form.clearForm();
		this.forceUpdate();
	}

	public render(): React.ReactElement {
		const classes = arrayToClass([
			'AuthorityContacts w-100',
			this.props.className ? this.props.className : '',
		]);

		return (
			<div className={classes}>
				{!this.state.isEdit && this.renderLine()}
				{this.state.isEdit && this.renderEdit()}

				<ConfirmationModal
					title='Are you sure you want to delete this authority contact?'
					onClick={() => this.handleDelete()}
					close={() => this.delete$.close()}
					ref={(ref: any) => (this.delete$ = ref)}
				/>
			</div>
		);
	}

	public renderLine() {
		const form: any = this.form.generateJSON();
		const type = formatOptions(typeOpts, false).find(
			(option) => option.value === form.type
		);

		return (
			<div className='EditOrCreateGeneralData'>
				<div className='p-3 palette--bgc-neutral-2 border-radius-1 my-2'>
					<div className='row display-flex align-items-center'>
						<p className='col-24 col-sm-7 m-0'>{form.name}</p>
						<p className='col-24 col-sm-7 m-0'>
							{type?.title || ''}
						</p>
						<p className='col-24 col-sm-7 m-0 ContactString'>
							{form.details}
						</p>
						<div className='col-24 col-sm-3'>
							{this.renderButtons()}
						</div>
					</div>
				</div>
			</div>
		);
	}

	public renderEdit(): React.ReactElement {
		return (
			<form className='p-3'>
				<div className='row display-flex align-items-center'>
					<TextInput
						field={this.form.getField('name')}
						classes='col-24 col-sm-7 py-2'
						textType='camel'
						labelInfos={{
							label: 'Name',
							classes: 'display-sm-flex display-md-none',
						}}
					/>

					<Select
						field={this.form.getField('type')}
						options={formatOptions(typeOpts, false)}
						classes='py-2 col-24 col-sm-7'
						labelInfos={{
							label: 'Type',
							classes: 'display-sm-flex display-md-none',
						}}
					/>

					{this.renderInput()}

					<div className='col-24 col-sm-3 display-flex align-items-end justify-content-end'>
						{this.renderButtons()}
					</div>
				</div>
			</form>
		);
	}

	public renderInput() {
		const typeFromSelect = this.form.getValue('type') || '';
		const type = (typeFromSelect || '').toLowerCase();

		return (
			<ValidationInput
				placeholder={placeholders[type]}
				classes='col-24 col-sm-7 py-2'
				type={type}
				field={this.form.getField('details')}
				onError={() => this.onErrorToggle(true)}
				onValid={() => this.onErrorToggle(false)}
				labelInfos={{
					label: 'Details',
					classes: 'display-sm-flex display-md-none',
				}}
			/>
		);
	}

	public onErrorToggle(isError) {
		this.setState({ validityError: isError }, () =>
			this.handleFormChange()
		);
	}

	public renderButtons() {
		return (
			<div className='display-flex justify-content-end'>
				<HoverableButton
					type='button'
					disabled={this.state.deleted as boolean}
					colorType={
						this.state.isEdit
							? 'transparent-grey'
							: this.state.deleted
							? 'transparent-grey'
							: 'transparent-green'
					}
					onClick={() => this.handleCloseOrEdit()}
					icon={this.state.isEdit ? 'close' : 'edit'}
				/>
				<HoverableButton
					icon={this.state.isEdit ? 'check' : 'delete'}
					type='button'
					colorType={
						!this.state.isEdit
							? 'delete'
							: this.state.buttonDisabled ||
							  !!this.state.validityError
							? 'transparent-grey'
							: 'transparent-green'
					}
					disable={
						(!!this.state.isEdit &&
							!!this.state.buttonDisabled &&
							!this.state.validityError) as boolean
					}
					className='ml-1'
					onClick={() =>
						this.state.isEdit
							? this.handleSaveChanges()
							: this.delete$.open()
					}
				/>
			</div>
		);
	}

	public handleCloseOrEdit() {
		const { buttonDisabled, isEdit } = this.state;
		const { originalValues } = this.props;
		const { getValue } = this.form;
		const isCreateEmpty = !getValue('type') || !getValue('details');

		if (!isEdit) {
			this.setState({ isEdit: true });
		} else if (
			(!buttonDisabled && !originalValues) ||
			(isCreateEmpty && !originalValues)
		) {
			this.props?.onEmptyClose();
		} else {
			this.setToDefault();
			this.setState({ isEdit: false });
		}
	}

	public handleSaveChanges() {
		if (
			this.props.handleSaveChanges &&
			!this.state.validityError &&
			!this.state.buttonDisabled
		) {
			this.props.handleSaveChanges({
				...this.props?.values,
				...this.form.generateJSON(),
			});
			this.setState({ isEdit: false });
		}
	}

	public async handleSave(aid = false, isMergedParent) {
		if (!!aid) {
			await this.handleCreateSave(aid, isMergedParent);
		} else {
			await this.handleEditSave();
		}
	}

	public async handleCreateSave(aid, isMergedParent) {
		try {
			const contact = this.form.generateJSON();

			const query = isMergedParent
				? { parentAid: aid }
				: { parentContributionId: aid };

			const response = await RequestManager.post(
				`/${
					this.props.isCountry ? 'country' : 'airport'
				}authoritycontacts`,
				contact,
				query
			);

			if (!response) {
				throw new Error('Something went wrong');
			}

			if (!!isMergedParent) {
				response.parentEntity = aid;
			}

			this.onSuccess(response);
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			redirectToLogin(err);
		} finally {
			this.setState({ buttonDisabled: false });
		}
	}

	public async handleEditSave(): Promise<void> {
		this.setState({ buttonDisabled: true });

		try {
			const contacts = this.form.generateJSON();

			const data = {
				...this.state?.response,
				...contacts,
			};

			const response = await RequestManager.put(
				`/${
					this.props.isCountry ? 'country' : 'airport'
				}authoritycontacts/${get(this.props.originalValues, 'aid')}`,
				data
			);

			if (!response) {
				throw new Error('Something went wrong');
			}

			this.onSuccess(response);
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			redirectToLogin(err);
		} finally {
			this.setState({ buttonDisabled: false });
		}
	}

	public async handleDelete() {
		this.setState({ buttonDisabled: true });

		if (!this.props?.originalValues) {
			return this.props?.onEmptyClose();
		}

		try {
			const response = await RequestManager.delete(
				`/${
					this.props.isCountry ? 'country' : 'airport'
				}authoritycontacts/${get(this.props.originalValues, 'aid')}`
			);

			if (!response) {
				throw new Error('Error in delete.');
			}

			this.props.handleDelete(get(this.props.originalValues, 'aid'));
			this.onSuccess(response, true);
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			redirectToLogin(err);
		} finally {
			this.setState({ buttonDisabled: false });
		}
	}

	public async onSuccess(response, isDelete = false) {
		if (
			(get(this.props.user, 'roles') || []).includes(
				'AUTO_MERGE_CONTRIBUTIONS'
			)
		) {
			response.contribution.changes = isDelete
				? this.form.getFieldsWhereHasValue()
				: this.form.getDifferences();

			await this.props.mergeContribution(
				response,
				`/${
					this.props.isCountry ? 'country' : 'airport'
				}authoritycontacts/merge`,
				'contact'
			);
		}
	}
}

export const AuthorityContactsEdit: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(AuthorityContactsEditComponent);
