import { get, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { getQuery, isInQuery } from '../utilities/helper-fuctions';
import { LocalStorage } from '../utilities/local-storage';
import { RequestManager } from '../utilities/request';

export abstract class UserActions {
	public static LOGOUT = 'UserActions[LOGOUT]';
	public static LOGIN_IN_PROGRESS = 'UserActions[LOGIN_IN_PROGRESS]';
	public static LOGIN_ERROR = 'UserActions[LOGIN_ERROR]';
	public static LOGIN_SUCCESS = 'UserActions[LOGIN_SUCCESS]';
	public static SET_ACCESS_TOKEN = 'UserActions[SET_ACCESS_TOKEN]';
	public static SET_ACCESS_TOKEN_TYPE = 'UserActions[SET_ACCESS_TOKEN_TYPE]';
	public static SET_REFRESH_TOKEN = 'UserActions[SET_REFRESH_TOKEN]';
	public static REGISTRATION_IN_PROGRESS =
		'UserActions[REGISTRATION_IN_PROGRESS]';
	public static REGISTRATION_ERROR = 'UserActions[REGISTRATION_ERROR]';
	public static REGISTRATION_SUCCESS = 'UserActions[REGISTRATION_SUCCESS]';
	public static SET_USER = 'UserActions[SET_USER]';

	// REGISTRATION

	public static registration(credentialsAndPreferences: any) {
		return async (dispatch: (action: any) => any) => {
			dispatch(this.setRegistrationIsInProgress(true));

			try {
				const user = await RequestManager.post(
					'/users',
					credentialsAndPreferences
				);
				dispatch(this.setRegistrationSuccess(user));
			} catch (err: any) {
				dispatch(this.setRegistrationError(err));

				if (err.response.status === 403) {
					toast.error('Wrong email or password!', { theme: 'light' });
				} else {
					return err;
				}
			} finally {
				dispatch(this.setRegistrationIsInProgress(false));
			}
		};
	}

	public static setRegistrationIsInProgress(value: boolean) {
		return (dispatch: any): any => {
			if (value) {
				dispatch(this.setRegistrationError(null));
				dispatch(this.setRegistrationSuccess(false));
			}

			dispatch({
				type: this.REGISTRATION_IN_PROGRESS,
				payload: value,
			});
		};
	}

	public static setRegistrationError(error: string | null) {
		return (dispatch: any): any => {
			if (error) {
				dispatch(this.setRegistrationSuccess(false));
				dispatch(this.setRegistrationIsInProgress(false));
			}

			dispatch({
				type: this.REGISTRATION_ERROR,
				payload: error,
			});
		};
	}

	public static setRegistrationSuccess(value: boolean) {
		return (dispatch: any): any => {
			if (value) {
				dispatch(this.setRegistrationError(null));
			}

			dispatch({
				type: this.REGISTRATION_SUCCESS,
				payload: value,
			});
		};
	}

	// LOGIN

	public static login(credentials: any) {
		return async (dispatch: (action: any) => void) => {
			dispatch(this.setLoginIsInProgress(true));

			try {
				const response = await RequestManager.post(
					'/auth/login',
					credentials,
					{},
					{ noHeader: true }
				);

				if (!response) {
					throw new Error('UnexpectedAccessToken');
				}

				dispatch(this.setAccessToken(response));
				dispatch(this.setAccessTokenType('Bearer'));
				dispatch(this.setRefreshToken(null));
				dispatch(this.setLoginSuccess(true));
			} catch (err: any) {
				dispatch(this.setLoginError(err));
				if (
					get(err, 'response.status') &&
					get(err, 'response.status', null) === 403
				) {
					toast.error(
						get(err, 'response.data.message', null) ||
							err ||
							'Error',
						{ theme: 'light' }
					);
				} else {
					toast.error(
						get(err, 'response.data.message', null) ||
							err ||
							'Error',
						{ theme: 'light' }
					);
				}
			} finally {
				dispatch(this.setLoginIsInProgress(false));
			}
		};
	}

	public static setLoginIsInProgress(value: boolean) {
		return (dispatch: any): any => {
			if (value) {
				dispatch(this.setLoginError(null));
				dispatch(this.setLoginSuccess(false));
			}

			dispatch({
				type: this.LOGIN_IN_PROGRESS,
				payload: value,
			});
		};
	}

	public static setLoginError(error: string | null) {
		return (dispatch: any): any => {
			if (error) {
				dispatch(this.setLoginSuccess(false));
			}

			dispatch({
				type: this.LOGIN_ERROR,
				payload: error,
			});
		};
	}

	public static setLoginSuccess(value: boolean) {
		return (dispatch: any): any => {
			if (value) {
				dispatch(this.setLoginError(null));
			}

			dispatch({
				type: this.LOGIN_SUCCESS,
				payload: value,
			});
		};
	}

	public static setAccessToken(value: string | null): AnyAction {
		LocalStorage.setItem('AccessToken', value);

		return {
			type: this.SET_ACCESS_TOKEN,
			payload: value,
		};
	}

	public static setAccessTokenType(value: string | null): AnyAction {
		LocalStorage.setItem('AccessTokenType', value);

		return {
			type: this.SET_ACCESS_TOKEN_TYPE,
			payload: value,
		};
	}

	public static setRefreshToken(value: string | null): AnyAction {
		LocalStorage.setItem('RefreshToken', value);

		return {
			type: this.SET_REFRESH_TOKEN,
			payload: value,
		};
	}

	// LOGOUT

	public static logout() {
		return async (dispatch: (action: any) => void) => {
			await RequestManager.post(
				'/auth/logout',
				{},
				{},
				{ noHeader: true }
			);

			dispatch(this.setAccessToken(null));
			dispatch(this.setAccessTokenType(null));
			dispatch(this.setRefreshToken(null));
			dispatch(this.setUser(null));
			LocalStorage.removeItem('fingerprint');

			return {
				type: this.LOGOUT,
				payload: Math.random(),
			};
		};
	}

	// OTHER

	public static setUser(value: string | null) {
		LocalStorage.setItem('User', value);

		return {
			type: this.SET_USER,
			payload: value,
		};
	}

	public static checkAuthentication() {
		return async (dispatch) => {
			try {
				const whoami: any = await RequestManager.get('/auth/current');

				if (!whoami) {
					throw new Error('Unauthorized');
				}

				if (!isEqual(LocalStorage.getItem('User'), whoami)) {
					dispatch(this.setUser(whoami));
				}
			} catch (e) {
				if (!LocalStorage.getItem('User')) {
					LocalStorage.removeItem('AccessToken');
					LocalStorage.removeItem('AccessTokenType');
					LocalStorage.removeItem('RefreshToken');
					return;
				}

				if (
					get(e, 'response.data.message') === 'token expired' &&
					get(e, 'response.status') === 403
				) {
					toast.warn('Your session has ended, please login again');
					LocalStorage.removeItem('AccessToken');
					LocalStorage.removeItem('AccessTokenType');
					LocalStorage.removeItem('RefreshToken');
					LocalStorage.removeItem('User');
					dispatch(this.logout());
				}
			}
		};
	}

	public static tryRedirectIfAuthenticated() {
		return async (dispatch: any) => {
			try {
				const whoami: any = await RequestManager.get('/auth/current');

				if (!whoami) {
					throw new Error('Unauthorized');
				}

				dispatch(UserActions.setUser(whoami));

				let redirect: any = getQuery()?.redirect || '';

				if (
					redirect.includes('login') ||
					redirect.includes('registration') ||
					redirect === ''
				) {
					redirect = '/';
				}

				window.location.href = redirect;
			} catch (e) {
				dispatch(UserActions.setUser(null));
				dispatch(UserActions.setAccessToken(null));
				dispatch(UserActions.setAccessTokenType(null));
			}
		};
	}

	public static tryRedirectIfNotAuthenticated() {
		return async (dispatch: any) => {
			try {
				const whoami = await RequestManager.get('/auth/current');

				if (!whoami) {
					throw new Error('Unauthorized');
				}

				dispatch(UserActions.setUser(whoami));
			} catch (e) {
				dispatch(UserActions.setUser(null));
				dispatch(UserActions.setAccessToken(null));
				dispatch(UserActions.setAccessTokenType(null));

				let redirectLink = `${window.location.pathname}${window.location.search}`;

				if (
					redirectLink.includes('login') ||
					redirectLink.includes('registration')
				) {
					redirectLink = '/';
				}

				const utmSource = isInQuery('utm_source');
				window.location.href = `/login?redirect=${redirectLink}${
					utmSource ? `&${utmSource}` : ''
				}`;
			}
		};
	}
}
