import { LocalStorage } from '../utilities/local-storage';
import { AnyAction } from 'redux';
import { ConfigActions } from '../actions/config.actions';

const initialiState = {
    routeConfig: {},
    detailsOpen: !!LocalStorage.getItem('DetailsOpen'),
    detailsOpenMobile: !!LocalStorage.getItem('DetailsOpenMobile'),
};

export const configReducer = (state: any = initialiState, action: AnyAction) => {
    if (action.type === ConfigActions.SET_CONFIG) {
        state = { ...state, routeConfig: action.payload };
    }

    return state;
};
