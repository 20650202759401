import { get } from 'lodash';
import * as React from 'react';
import { CountryCard } from '../cards/country/country-card';
import { VisitComponent } from '../sidebars/visit-component';
import { Spinner } from '../ui-components/spinner/spinner';

export class CountryListRenderer extends React.Component<any, any> {
	public render() {
		const items = get(this.props, 'items');

		return (
			<div className='CountryList position-relative'>
				{items.map((item: any, index: number) =>
					this.renderCountryCard(item, index)
				)}
				{!this.props.isMax && (
					<VisitComponent
						parentClass='CountryList'
						fetch={() => this.handleFetch()}
						itemLength={(items || []).length}
					/>
				)}
				{this.props.loading && <Spinner size='medium' />}
			</div>
		);
	}

	public renderCountryCard(item, index) {
		return (
			<div
				key={index}
				className='w-100 border-bottom-1 palette--bc-neutral-3 pointer'
			>
				<CountryCard
					country={item}
					onClick={() =>
						this.props.onClick && this.props.onClick(item.iso3)
					}
				/>
			</div>
		);
	}

	public handleFetch() {
		if (this.props.fetch) {
			this.props.fetch();
		}
	}
}
