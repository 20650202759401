import { get, isEqual } from 'lodash';
import * as React from 'react';
import { arrayToClass } from '../../../utilities/helper-fuctions';
import { RequestManager } from '../../../utilities/request';
import { Spinner } from '../../ui-components/spinner/spinner';
import '../map.scss';

export class MapDataLoader<P, S> extends React.Component<P & any, S & any> {
	public isMounted = false;

	public state = {
		airports: false,
		error: false,
		loading: false,
		viewport: this.props?.viewport || null,
		distance: 840,
	};

	public async componentDidMount(): Promise<any> {
		this.isMounted = true;

		if (this.props.airport || this.props.isRelief) {
			return await this.fetchNearestAirports();
		}
	}

	public componentWillUnmount() {
		this.isMounted = false;
	}

	public async fetchNearestAirports() {
		if (this.props.airport) {
			await this.fetchWithAirport();
		} else {
			const viewport = {
				center: [19, -73],
				zoom: 7,
			};

			const distance = 840;

			this.stateChange({ viewport: viewport });
			await this.fetch(viewport, distance);
		}
	}

	public async componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (
			!isEqual(this.props.airport, prevProps.airport) &&
			this.props.airport
		) {
			await this.fetchWithAirport();
		}
	}

	public async fetchWithAirport() {
		const lat = this.props?.airport?.coordinates?.latitude;
		const lng = this.props?.airport?.coordinates?.longitude;
		const isHeliport = this.props?.airport?.type === 'HELIPORT';
		const distance = this.state.distance || 100;
		let viewport;

		if (!lat && !lng) {
			viewport = {
				center: [19, -73],
				zoom: 7,
			};
		} else {
			viewport = {
				zoom: isHeliport ? 15 : 13,
				center: [lat, lng],
			};
		}

		this.setState({ viewport: viewport, distance: distance });
		await this.fetch(viewport, distance);
	}

	public async fetch(
		viewport,
		distance,
		ignoreLoading = false
	): Promise<any> {
		if (!ignoreLoading) {
			this.stateChange({ loading: true });
		}

		try {
			const lat = get(viewport, 'center[0]');
			const lng = get(viewport, 'center[1]');

			const response = await RequestManager.get(
				'/airports/search?includeArchived=true',
				{
					latitude: lat,
					longitude: lng,
					distance: distance,
					size: 500,
				}
			);

			if (!response) {
				throw new Error('Error');
			}

			const content = response?.content;
			const alreadyLoaded: any = this.state.airports || [];
			const airports: any[] = alreadyLoaded;

			for (const airport of content) {
				if (
					(alreadyLoaded || []).find(
						(a: any) => a.aid === airport.aid
					)
				) {
					return;
				}

				let color = 'green';
				const operationalStatus = get(airport, 'operationalStatus');

				if (operationalStatus === 'OPERATIONALLY_RELEVANT') {
					color = 'yellow';
				}

				if (operationalStatus === 'OPERATIONALLY_CRITICAL') {
					color = 'red';
				}

				const data = {
					coordinates: get(airport, 'coordinates'),
					aid: get(airport, 'aid'),
					name: get(airport, 'name'),
					iata: get(airport, 'iata'),
					icao: get(airport, 'icao'),
					icon: get(airport, 'icon'),
					color: color,
				};

				airports.push(data);
			}

			this.stateChange({ airports: airports });
		} catch (err) {
			this.stateChange({ error: err });
		} finally {
			if (!ignoreLoading) {
				this.stateChange({ loading: false });
			}
		}
	}

	public stateChange(states) {
		if (this.isMounted) {
			this.setState(states);
		}
	}

	public render(): React.ReactElement {
		const classNames = arrayToClass([
			'position-relative border-radius-1 overflow-hidden w-100 h-100',
		]);

		return (
			<div className={classNames}>
				{this.state.loading &&
					!this.state.error &&
					this.renderLoading()}
				{!this.state.loading &&
					this.state.error &&
					!this.state.airports &&
					this.renderError()}
				{this.renderContent()}
			</div>
		);
	}

	public renderLoading(): React.ReactElement {
		return (
			<div className='LayoutBox w-100 h-100 display-flex align-items-center justify-content-center position-absolute'>
				<Spinner isLight={true} size='large' />
			</div>
		);
	}

	public renderError(): React.ReactElement {
		return (
			<div className='LayoutBox w-100 h-100 display-flex justify-content-center align-items-center position-absolute'>
				<h2 className='palette--c-neutral-1'>
					There was an error while we tried to load your search.
				</h2>
			</div>
		);
	}

	public renderContent(): any {
		return null;
	}
}
