import { arrayToClass } from '../../../utilities/helper-fuctions';
import jquery from 'jquery';
import { get } from 'lodash';
import * as React from 'react';
import './tab.scss';

interface TabProps {
    items: any;
    onItemClick: (ctx, data) => any;
    selected: any;
    classNames?: any;
    headerTitleClasses?: any;
}

export class Tab extends React.Component<TabProps, any> {
    public state: any = {
        isLeft: false,
        isRight: false,
        pickedProvider: null,
    };

    public componentDidMount(): void {
        this.isArrowsShown();
        window.addEventListener('resize', () => {
            this.isArrowsShown();
        });
    }

    public isArrowsShown() {
        const parentWidth: any = jquery('.HeaderInner').width();
        const providerWidth: any = jquery('.HeaderTitleBox').outerWidth();
        const scrollWidth = Math.round(providerWidth * get(this.props, 'items', []).length);

        if (scrollWidth <= parentWidth) {
            this.setState({ isLeft: false, isRight: false });
            return;
        }

        this.setState({ isLeft: false, isRight: true });
    }

    public render(): React.ReactElement {
        const className = arrayToClass(['HeaderInner display-flex overflow-hidden']);

        const classNames = arrayToClass([
            'Tab display-flex position-relative',
            this.props.classNames ? this.props.classNames : '',
        ]);

        return (
            <div className={classNames}>
                {this.state.isLeft && this.renderArrow('chevron-left')}
                <div className={className}>{this.renderHeaderTitles()}</div>
                {this.state.isRight && this.renderArrow('chevron-right')}
            </div>
        );
    }

    public renderArrow(icon) {
        const direction = icon.split('-')[1];
        const containerClasses = arrayToClass([
            'ScrollArrows palette--bgc-neutral-3 position-absolute absolute-top-0 absolute-bottom-0 display-flex justify-content-center align-items-center',
            `absolute-${direction}-0`,
        ]);

        const iconClasses = arrayToClass(['palette--c-neutral-6 fas', `fa-${icon}`]);

        return (
            <div className={containerClasses} onClick={() => this.handleScroll(direction)}>
                <span className={iconClasses} />
            </div>
        );
    }

    public handleScroll(direction: string) {
        const parentLeft: any = jquery('.HeaderInner').scrollLeft();
        const parentWidth: any = jquery('.HeaderInner').width();
        const providerWidth: any = jquery('.HeaderTitleBox').outerWidth();
        const scrollWidth = providerWidth * get(this.props, 'items', []).length - parentWidth;

        if (direction === 'right') {
            const newRightWidth = parentLeft + providerWidth * 2;
            jquery('.HeaderInner').animate({ scrollLeft: newRightWidth }, 200);
            this.setState({ isLeft: true });

            if (newRightWidth >= scrollWidth) {
                this.setState({ isLeft: true, isRight: false });
                return;
            }
            return;
        }

        const newLeftWidth = parentLeft - providerWidth * 2;
        jquery('.HeaderInner').animate({ scrollLeft: newLeftWidth }, 200);
        this.setState({ isRight: true });

        if (newLeftWidth <= 0) {
            this.setState({ isLeft: false, isRight: true });
            return;
        }
        return;
    }

    public renderHeaderTitles() {
        const items = get(this.props, 'items', []);
        return items.map((header: any, index: number) => {
            const selected = get(this.props, 'selected', null);
            const item = selected ? selected.title : items[0].title;
            const color = item === header.title;

            const borderClasses = arrayToClass([
                'HeaderTitleBox text-center p-1',
                color ? 'border-bottom-2 palette--bc-primary-4' : 'border-bottom-1 palette--bc-neutral-3',
                this.props.headerTitleClasses ? this.props.headerTitleClasses : '',
            ]);

            const iconClasses = arrayToClass([
                'ItemTitleIcons',
                header.material ? header.material : `fas fa-${header.icon}`,
                color ? 'palette--c-primary-4' : 'palette--c-neutral-5',
            ]);

            const titleClasses = arrayToClass(['m-0', color ? 'palette--c-primary-4' : 'palette--c-neutral-5']);

            return (
                <div className={borderClasses} key={header.title} onClick={() => this.handleOnClick(header)}>
                    {header.icon && <span className={iconClasses}>{header.material && header.icon}</span>}
                    <div className="ItemTitleContainer w-100">
                        <span className={titleClasses}>{header.title}</span>
                    </div>
                </div>
            );
        });
    }

    public handleOnClick(title: any) {
        this.props.onItemClick(this, title);
    }
}
