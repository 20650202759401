import { arrayToClass } from '../../../utilities/helper-fuctions';
import jquery from 'jquery';
import { get, isEqual } from 'lodash';
import * as React from 'react';
import './tab.scss';

interface TabProps {
	items: any;
	onItemClick: (item) => any;
	selected: any;
	classNames?: any;
	headerTitleClasses?: any;
	color?: string;
}

export class SubTab extends React.Component<TabProps, any> {
	public state: any = {
		prevIndex:
			get(this.props, 'items', []).indexOf(
				get(this.props, 'items', {}).find(
					(item) =>
						item.type === get(this.props, 'selected', 'movement')
				)
			) || 0,
		pickedProvider: null,
		showScroll: false,

		isRight: false,
		isLeft: false,
	};

	public componentDidMount(): void {
		this.isArrowsShown();
		window.addEventListener('resize', () => {
			this.isArrowsShown();
		});
	}

	public isArrowsShown() {
		const parentWidth: any = jquery('.TabItemsContainer').width();
		const providerWidth: any = jquery('.SubHeader').innerWidth();
		const providerRealWidth: any = providerWidth + 8;
		const scrollWidth = Math.round(
			providerRealWidth * Object.keys(get(this.props, 'items', {})).length
		);

		if (scrollWidth <= parentWidth) {
			this.setState({ isLeft: false, isRight: false });
			return;
		}

		this.setState({ isLeft: false, isRight: true });
	}

	public render(): React.ReactElement {
		return (
			<div className='Tab w-100 position-relative'>
				{this.state.isLeft && this.renderArrow('chevron-left')}
				{this.renderTab()}
				{this.state.isRight && this.renderArrow('chevron-right')}
			</div>
		);
	}

	public renderArrow(icon) {
		const direction = icon.split('-')[1];
		const containerClasses = arrayToClass([
			'ScrollArrows palette--bgc-neutral-3 position-absolute absolute-top-0 absolute-bottom-0 display-flex justify-content-center align-items-center',
			`absolute-${direction}-0`,
		]);

		const iconClasses = arrayToClass([
			'palette--c-neutral-6 fas',
			`fa-${icon}`,
		]);

		return (
			<div
				className={containerClasses}
				onClick={() => this.handleScroll(direction)}
			>
				<span className={iconClasses} />
			</div>
		);
	}

	public renderTab(): React.ReactElement {
		const className = arrayToClass([
			'TabItemsContainer w-100 display-flex',
		]);

		const classNames = arrayToClass([
			'Tab display-flex position-relative palette--bgc-neutral-2',
			this.props.classNames ? this.props.classNames : '',
		]);

		const items = get(this.props, 'items', {});

		return (
			<div className={classNames}>
				<div className={className}>
					{items.map((item: any, index: any) => {
						const selectedItem = get(this.props, 'selected');
						const selected = selectedItem
							? selectedItem === get(item, 'type') ||
							  isEqual(selectedItem, item)
							: get(items[0], 'type') === get(item, 'type') ||
							  isEqual(items[0], selectedItem);

						return this.renderHeader(item, selected, index);
					})}
				</div>
			</div>
		);
	}

	public renderHeader(header, selected, index): React.ReactElement {
		const boxClasses = arrayToClass([
			'SubHeader',
			selected ? 'border-radius-1' : '',
			selected
				? `palette--bgc-${header.color ? header.color : 'primary-4'}`
				: '',
			'display-flex align-items-center justify-content-center flex-fill py-2 pointer m-1 w-100 position-relative',
		]);

		const titleClasses = arrayToClass([
			selected ? 'palette--c-neutral-1' : 'palette--c-neutral-5',
			'SubHeaderTitle m-0',
		]);

		return (
			<div
				className={boxClasses}
				key={header.title}
				onClick={() => this.handleOnClick(header, index)}
			>
				{header.total > 0 && (
					<div className='ContributionMarker palette--bgc-red-2 display-flex justify-content-center align-items-center border-radius-50 position-absolute absolute-right-1 absolute-top-1'>
						<span className='palette--c-neutral-1'>
							{header.total}
						</span>
					</div>
				)}
				<div className='ItemTitleContainer'>
					<span className={titleClasses}>{header.title}</span>
				</div>
			</div>
		);
	}

	public handleScroll(direction: string) {
		const parentLeft: any = jquery('.TabItemsContainer').scrollLeft();
		const parentWidth: any = jquery('.TabItemsContainer').width();
		const providerWidth: any = jquery('.SubHeader').outerWidth();
		const providerRealWidth: any = providerWidth + 8;
		const scrollWidth =
			providerRealWidth *
				Object.keys(get(this.props, 'items', {})).length -
			parentWidth;

		if (direction === 'right') {
			const newRightWidth = parentLeft + providerRealWidth * 2;
			jquery('.TabItemsContainer').animate(
				{ scrollLeft: newRightWidth },
				200
			);
			this.setState({ isLeft: true });

			if (newRightWidth >= scrollWidth) {
				this.setState({ isLeft: true, isRight: false });
				return;
			}
			return;
		}

		const newLeftWidth = parentLeft - providerRealWidth * 2;
		jquery('.TabItemsContainer').animate({ scrollLeft: newLeftWidth }, 200);
		this.setState({ isRight: true });

		if (newLeftWidth <= 0) {
			this.setState({ isLeft: false, isRight: true });
			return;
		}
		return;
	}

	public handleOnClick(item: string, index: number) {
		const parentWidth: any = jquery('.TabItemsContainer').width();
		const providerWidth: any = jquery('.SubHeader').outerWidth();
		const providerRealWidth: any = providerWidth + 8;
		const scrollWidth = parentWidth - providerRealWidth * (index + 1);

		if (scrollWidth < 0) {
			jquery('.TabItemsContainer').animate(
				{ scrollRight: Math.abs(scrollWidth) },
				200
			);
		}

		if (this.props.onItemClick) {
			this.props.onItemClick(item);
		}
	}
}
