export abstract class ConfigActions {
    public static readonly SET_CONFIG = 'ConfigActions[SET_CONFIG]';
    public static readonly SET_CONTENT_SCROLL_Y = 'ConfigActions[SET_CONTENT_SCROLL_Y]';

    public static setConfig(config: any) {
        return {
            type: ConfigActions.SET_CONFIG,
            payload: config
        };
    }

    public static setContentScrollY(value: number) {
        return {
            type: ConfigActions.SET_CONTENT_SCROLL_Y,
            payload: value
        };
    }
}
