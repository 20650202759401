const dimensions = [
	{
		class: 'length',
		name: 'meter',
		symbol: ['m'],
		toSI: (v: number) => v,
		fromSI: (v: number) => v,
	},
	{
		class: 'length',
		name: 'kilometer',
		symbol: ['km'],
		toSI: (v: number) => v * 1000,
		fromSI: (v: number) => v / 1000,
	},
	{
		class: 'length',
		name: 'feet',
		symbol: ['ft', 'feet'],
		toSI: (v: number) => v / 3.280839895,
		fromSI: (v: number) => v * 3.280839895,
	},
	{
		class: 'length',
		name: 'Nautical Miles',
		symbol: ['NM', 'nm'],
		toSI: (v: number) => v * 1852,
		fromSI: (v: number) => v / 1852,
	},
	{
		class: 'length',
		name: 'Statue Miles',
		symbol: ['mi'],
		toSI: (v: number) => v * 1609.344,
		fromSI: (v: number) => v / 1609.344,
	},
	{
		class: 'mass',
		name: 'gramm',
		symbol: ['g'],
		toSI: (v: number) => v / 1000,
		fromSI: (v: number) => v * 1000,
	},
	{
		class: 'mass',
		name: 'kilogram',
		symbol: ['kg'],
		toSI: (v: number) => v,
		fromSI: (v: number) => v,
	},
	{
		class: 'mass',
		name: 'pounds',
		symbol: ['lb', 'pounds'],
		toSI: (v: number) => v / 2.2046226218,
		fromSI: (v: number) => v * 2.2046226218,
	},
	{
		class: 'temperature',
		name: 'kelvin',
		symbol: ['K'],
		toSI: (v: number) => v,
		fromSI: (v: number) => v,
	},
	{
		class: 'temperature',
		name: 'celsius',
		symbol: ['°C', 'C', 'celsius', '°c'],
		toSI: (v: number) => v - 273.15,
		fromSI: (v: number) => v + 273.15,
	},
	{
		class: 'temperature',
		name: 'fahrenheit',
		symbol: ['°F', 'F', 'fahrenheit', '°f'],
		toSI: (v: number) => ((v - 32) / 9) * 5 - 273.15,
		fromSI: (v: number) => ((v + 273.15) / 5) * 9 + 32,
	},
];

export const dimConvert = (
	value: number,
	sourceDimension: string,
	targetDimension: string,
	precision: number,
	ignorePrecision = false,
	ignoreDimension = false
): number | string | any => {
	const sourceDim = dimensions.find(
		(dim: any) => dim.symbol.indexOf(sourceDimension) > -1
	);
	const targetDim = dimensions.find(
		(dim: any) => dim.symbol.indexOf(targetDimension) > -1
	);

	if (!sourceDim || !targetDim) {
		return;
	}

	if (sourceDim.class !== targetDim.class) {
		return;
	}

	const si = sourceDim.toSI(value);
	const target = targetDim.fromSI(si);

	if (!ignorePrecision && value) {
		const prec = value % 1 !== 0 ? precision : 0;

		return target.toFixed(prec);
	}

	return target;
};
