import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
	checkData,
	getData,
	getFields,
} from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import {
	arrayToClass,
	redirectToLogin,
} from '../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../utilities/request';
import { ToastMessages } from '../../../../notifications/toast-messages';
import { Form } from '../../../../ui-components/form/form';
import { ContributionFooter } from '../../../shared/contributions/contribution-footer';
import { WidgetContributionPresenter } from '../../../shared/contributions/widget-contribution-presenter/widget-contribution-presenter';
import { runwayFields } from './runway-contribution-helpers';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class RunwayContributionComponent extends React.Component<any, any> {
	public state: any = {
		isAdmin: get(this.props, 'user.roles', []).includes('ADMIN'),
		type: get(this.props.item, 'contribution.action'),
		editable: [],
		rejected: [],
		buttonDisabled: false,
	};

	public form: any = new Form({
		identifier: this.props?.item?.identifier,
		magneticBearing: this.props?.item?.magneticBearing,
		trueBearing: this.props?.item?.trueBearing,
		helipad: this.props?.item?.helipad,
		surface: this.props?.item?.surface,
		'thresholdCoordinates.latitude':
			this.props?.item?.thresholdCoordinates?.latitude,
		'thresholdCoordinates.longitude':
			this.props?.item?.thresholdCoordinates?.longitude,
		thresholdElevation: this.props?.item?.thresholdElevation,
		slope:
			typeof this.props?.item?.slope === 'number'
				? this.props?.item?.slope * 100
				: null,
		grooved: this.props?.item?.grooved,
		tora: this.props?.item?.tora,
		toda: this.props?.item?.toda,
		asda: this.props?.item?.asda,
		lda: this.props?.item?.lda,
		width: this.props?.item?.width,
		visualApproachSlopeIndicator:
			this.props?.item?.visualApproachSlopeIndicator,
		pcnNumerical: this.props?.item?.pcnNumerical,
		pcnType: this.props?.item?.pcnType,
		pcnStrength: this.props?.item?.pcnStrength,
		pcnTirePressure: this.props?.item?.pcnTirePressure,
		pcnMethod: this.props?.item?.pcnMethod,
		approachLightConfiguration:
			this.props?.item?.approachLightConfiguration,
		thresholdLights: this.props?.item?.thresholdLights,
		touchDownZoneLights: this.props?.item?.touchDownZoneLights,
		centerLights: this.props?.item?.centerLights,
		centerLightsSpacing: this.props?.item?.centerLightsSpacing,
		edgeLights: this.props?.item?.edgeLights,
		endLights: this.props?.item?.endLights,
		instrumental: this.props?.item?.instrumental,
		precision: this.props?.item?.precision,
		separate: this.props?.item?.separate,
		standardPattern: this.props?.item?.standardPattern,
		archived: this.props?.item?.archived,
	});

	public render(): React.ReactElement {
		const runwayList = getFields(this, runwayFields(this));

		return (
			<div className='Content w-100'>
				<div className='p-2'>
					{(!runwayList || !runwayList.length) && this.renderEmpty()}
					{!!runwayList.length && this.renderContent(runwayList)}
				</div>
				<ContributionFooter
					buttonDisabled={this.state.buttonDisabled}
					isAdmin={this.state.isAdmin}
					handleButtonClick={(isReject: boolean = false) =>
						this.handleButtonClick(isReject)
					}
					close={() => this.props.close()}
					handleEdit={() => this.handleEdit()}
				/>
			</div>
		);
	}

	public renderContent(list): React.ReactElement {
		return (
			<div className='w-100'>
				<p className='m-0 fw-bold palette--c-neutral-6'>
					Changes in runway widget
				</p>
				<div className='p-2 display-none display-md-flex'>
					<h6 className='Property palette--c-neutral-5 fw-bold m-0'>
						Property
					</h6>
					<h6 className='CurrentData palette--c-neutral-5 fw-bold m-0'>
						Current data
					</h6>
					<span className='ArrowIcons material-icons palette--c-neutral-1 fw-bold'>
						arrow_forward
					</span>
					<h6 className='NewData palette--c-neutral-5 fw-bold m-0'>
						New data
					</h6>
				</div>
				{list.map((item: any, index: number) => {
					return this.checkData(item, index);
				})}
			</div>
		);
	}

	public renderEmpty(): React.ReactElement {
		return (
			<div className='display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2'>
				<p className='palette--c-neutral-5 m-0'>Nothing changed.</p>
			</div>
		);
	}

	public checkData(field, index): any {
		const wrapperClassName = arrayToClass([
			this.state.rejected.includes(field.type)
				? 'palette--bgc-neutral-3'
				: `palette--bgc-${
						this.state.type === 'UPDATE'
							? 'yellow-1'
							: this.state.type === 'CREATE'
							? 'green-1'
							: 'red-1'
				  }`,
			'border-radius-1 my-2 p-2',
			'display-flex align-items-center',
		]);

		const element = {
			type: field.type,
			content: this.getData(field.type),
			component: field.component,
			unit: get(field, 'unit', ''),
			...field,
		};

		return (
			<WidgetContributionPresenter
				isAdmin={this.state.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(field)}
				toValue={element}
				isEditable={this.state.editable.includes(field.type)}
				isRejected={this.state.rejected.includes(field.type)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				className={wrapperClassName}
			/>
		);
	}

	public async handleEdit(type: string = 'editAll'): Promise<void> {
		if (type === 'editAll') {
			const editableArray = runwayFields(this).map(
				(item: any) => item.type
			);
			await this.setState({ editable: editableArray });
		}

		if (!this.state.editable.includes(type)) {
			await this.setState({ editable: [...this.state.editable, type] });
		}
	}

	private getOriginalData(field: string): any {
		const type = get(field, 'type');
		const value = get(this.props.original, type, null);

		if (this.state.type === 'CREATE') {
			return null;
		}

		if (type === 'slope' && !isNaN(value)) {
			const slope = (value * 10000000) / 100000;
			return slope % 1 === 0 ? slope : slope.toFixed(2);
		}

		return getData(type, value, false);
	}

	private getData(field: any): any {
		const form = this.form.generateJSON();
		const value = get(form, field, null);

		if (this.state.type === 'DELETE') {
			return '';
		}

		return getData(field, value, true);
	}

	private async handleMissEdit(type: string): Promise<void> {
		if (this.state.editable.includes(type)) {
			const editableArray = this.state.editable.filter(
				(item) => item !== type
			);
			await this.setState({ editable: editableArray });
		}
	}

	private handleReject(type: string): void {
		if (!this.state.rejected.includes(type)) {
			this.setState({ rejected: [...this.state.rejected, type] });
		}
	}

	private handleMissReject(type: string) {
		if (this.state.rejected.includes(type)) {
			const rejectedArray = this.state.rejected.filter(
				(item) => item !== type
			);
			this.setState({ rejected: rejectedArray });
		}
	}

	private checkModifications(): any {
		const obj = get(this.props, 'item');
		const list = getFields(this, runwayFields(this)) || [];
		const form = this.form.generateJSON();

		obj.contribution.rejectedChanges = this.state.rejected;
		obj.contribution.changes = checkData(list, obj, form, ['identifier']);

		return obj;
	}

	private onSuccess(message: string): void {
		toast.success(message, { theme: 'light' });
		this.props.onSuccess();
	}

	private onError(err): void {
		toast.error(<ToastMessages error={err} />, { theme: 'light' });
		redirectToLogin(err);
	}

	private async handleButtonClick(isReject = false): Promise<void> {
		this.setState({ buttonDisabled: true });
		let data = get(this.props, 'item');

		if (isReject) {
			data.contribution.status = 'REJECTED';
		} else {
			data = this.checkModifications();
		}

		try {
			const response = await RequestManager.post('/runways/merge', data);

			if (!response) {
				throw new Error('There was an error');
			}

			this.onSuccess(
				`Contribution is successfully ${
					isReject ? 'rejected' : 'accepted'
				}.`
			);
		} catch (err) {
			this.onError(err);
		} finally {
			this.setState({ buttonDisabled: false });
		}
	}
}

export const RunwayContribution: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(RunwayContributionComponent);
