import { arrayToClass } from '../../utilities/helper-fuctions';
import * as React from 'react';
import './footer.scss';
import { format } from 'date-fns';

const insta = require('../../assets/icon-instagram.svg');
const linkedin = require('../../assets/icon-linkedin.svg');
const twitter = require('../../assets/icon-twitter.svg');

export class Footer extends React.Component<any, any> {
	public render(): React.ReactElement {
		const className = arrayToClass([
			'FooterLink mt-3 ml-md-3',
			'text-decoration-none',
			'flex-fill',
		]);

		const containerClasses = arrayToClass([
			'Footer w-100 display-flex flex-column align-items-center justify-content-center px-3 py-3',
			'flex-md-row-reverse px-3 px-md-5',
		]);

		const linkClasses = arrayToClass([
			'display-flex flex-column align-items-center justify-content-center',
			'flex-column flex-md-row',
		]);

		return (
			<div className={containerClasses}>
				<div className={linkClasses}>
					<a
						href='https://aviowiki.com/privacy-policy/'
						target='_blank'
						className='ml-0 ml-md-3 text-decoration-none'
					>
						<p className='FooterLink palette--c-neutral-5 mb-md-0'>
							Privacy policy
						</p>
					</a>
					<a
						href='https://www.aviowiki.com/terms/'
						target='_blank'
						className='ml-0 ml-md-3 text-decoration-none'
					>
						<p className='FooterLink palette--c-neutral-5 ml-md-3 mb-md-0'>
							Terms and Conditions
						</p>
					</a>
					<div className='display-flex'>
						<a
							href='https://instagram.com/aviowiki'
							target='_blank'
							className='ml-0 ml-md-3 text-decoration-none'
						>
							<img
								src={insta}
								alt='Instagram'
								className='SocialMediaIcon'
							/>
						</a>
						<a
							href='https://linkedin.com/company/aviowiki'
							target='_blank'
							className='ml-3 text-decoration-none'
						>
							<img
								src={linkedin}
								alt='LinkedIn'
								className='SocialMediaIcon'
							/>
						</a>
						<a
							href='https://twitter.com/aviowiki'
							target='_blank'
							className='ml-3 text-decoration-none'
						>
							<img
								src={twitter}
								alt='Twitter'
								className='SocialMediaIcon'
							/>
						</a>
					</div>
				</div>
				<a
					href='https://www.aviowiki.com'
					target='_blank'
					className={className}
				>
					<p className='palette--c-neutral-5'>
						aviowiki © {format(new Date(), 'yyyy')}
					</p>
				</a>
			</div>
		);
	}
}
