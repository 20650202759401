import * as React from 'react';
import { LocalStorage } from '../../../../utilities/local-storage';
import { PreLoader } from '../../../../utilities/pre-loader';
import { Map } from '../../../map/map';
import { HoverableButton } from '../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Modal } from '../../../ui-components/modal/modal';

export class MapModalComponent extends React.Component<any, any> {
    public modal$: Modal;

    public componentDidMount() {
        this.modal$.open();
        LocalStorage.setItem('reliefAirport', this.props?.response);
    }

    public render() {
        return (
            <Modal
                hideClose={true}
                ref={(ref: any) => (this.modal$ = ref)}
                content={() => this.renderMap()}
                wrapperClasses="MapModal"
            />
        );
    }

    public renderMap(): any {
        return (
            <div className="">
                <Map
                    airport={this.props?.response}
                    dragging={true}
                    isScroll={true}
                    useMarkers={true}
                    buttonPosition="bottom"
                    className="MapOnModal"
                />

                <HoverableButton
                    type="button"
                    className="MapModalClose border-radius-1 position-absolute absolute-top-10 absolute-right-10"
                    colorType="contrast"
                    icon="close"
                    onClick={() => this.handleModalClose()}
                />
            </div>
        );
    }

    public handleModalClose() {
        LocalStorage.removeItem('reliefDetails');
        this.modal$.close();

        if (this.props.onClose) {
            this.props.onClose();
            this.forceUpdate();
        }
    }
}

export const MapModal = PreLoader(MapModalComponent);
