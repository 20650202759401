import { get } from 'lodash';
import * as React from 'react';
import { Form } from '../../../../../../ui-components/form/form';
import { HoverableButton } from '../../../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Checkbox } from '../../../../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { formatOptions } from '../../../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../../../ui-components/inputs/select/select';
import { AIRFRAME, DIRECTION, FILING, PURPOSE, RULES, STATUSES } from '../../../opening-hours-contribution/constants';
import './limited-info.scss';

export class FlightType extends React.Component<any, any> {
    public state = {
        rulesDisabled: this.setValue('rules', RULES, true),
        filingDisabled: this.setValue('filing', FILING, true),
        airframeDisabled: this.setValue('airframe', AIRFRAME, true),
        purposeDisabled: this.setValue('purpose', PURPOSE, true),
        directionDisabled: this.setValue('direction', DIRECTION, true),
    };

    public form = new Form({
        rules: { value: this.setValue('rules', RULES) || [], trigger: () => this.setNotRequired('rules', RULES) },
        filing: { value: this.setValue('filing', FILING) || [], trigger: () => this.setNotRequired('filing', FILING) },
        airframe: {
            value: this.setValue('airframe', AIRFRAME) || [],
            trigger: () => this.setNotRequired('airframe', AIRFRAME),
        },
        status: this.props?.data?.status || [],
        purpose: {
            value: this.setValue('purpose', PURPOSE) || [],
            trigger: () => this.setNotRequired('purpose', PURPOSE),
        },
        direction: {
            value: this.setValue('direction', DIRECTION) || [],
            trigger: () => this.setNotRequired('direction', DIRECTION),
        },
    });

    public helperCheckboxes = new Form({
        purpose: { value: this.setValue('purpose', PURPOSE, true), trigger: () => this.toggleDisabled('purpose') },
        direction: {
            value: this.setValue('direction', DIRECTION, true),
            trigger: () => this.toggleDisabled('direction'),
        },
        filing: { value: this.setValue('filing', FILING, true), trigger: () => this.toggleDisabled('filing') },
        rules: { value: this.setValue('rules', RULES, true), trigger: () => this.toggleDisabled('rules') },
        airframe: {
            value: this.setValue('airframe', AIRFRAME, true),
            trigger: () => this.toggleDisabled('airframe'),
        },
    });

    public setValue(field, opts, isCheckbox = false) {
        if (!this.props?.data) {
            return isCheckbox ? true : '';
        }

        const value = this.props?.data?.[field] || '';
        const isNotRequired = (value || []).length === opts.length;

        if (isNotRequired) {
            return isCheckbox ? true : '';
        } else {
            return isCheckbox ? false : value;
        }
    }

    public toggleDisabled(type) {
        const value = this.helperCheckboxes.getValue(type);
        this.setState({ [`${type}Disabled`]: value });

        if (!!value) {
            this.form.setValue(type, []);
        }
    }

    public setNotRequired(field, options) {
        const value = this.form.getValue(field);

        if ((value || []).length === (options || []).length) {
            this.helperCheckboxes.setValue(field, true);
        }
    }

    public render(): React.ReactElement {
        return (
            <div className="FlightTypes palette--bgc-neutral-3 border-1 border-radius-1 my-2 p-2 palette--bc-neutral-3 position-relative">
                <HoverableButton
                    className="position-absolute absolute-top-2 absolute-right-2"
                    colorType="transparent-grey"
                    icon="close"
                    onClick={() => this.deleteRow()}
                />

                <div className="display-flex flex-column flex-md-row align-items-start">
                    <div className="w-50 display-flex align-items-end">
                        <Select
                            disabled={this.state.directionDisabled}
                            autoFocus={true}
                            isMulti={true}
                            classes="py-2"
                            field={this.form.getField('direction')}
                            options={formatOptions(DIRECTION, false)}
                            labelInfos={{
                                label: 'Direction',
                            }}
                        />
                        <Checkbox
                            classes="py-1"
                            labelOnLeft={true}
                            field={this.helperCheckboxes.getField('direction')}
                            labelInfos={{ label: 'Not required' }}
                        />
                    </div>

                    <div className="left-m w-50 display-flex align-items-end">
                        <Select
                            disabled={this.state.airframeDisabled}
                            field={this.form.getField('airframe')}
                            classes="py-2"
                            isMulti={true}
                            options={formatOptions(AIRFRAME, false)}
                            labelInfos={{
                                label: 'Airframe',
                            }}
                        />
                        <Checkbox
                            classes="py-1"
                            labelOnLeft={true}
                            field={this.helperCheckboxes.getField('airframe')}
                            labelInfos={{ label: 'Not required' }}
                        />
                    </div>
                </div>

                <div className="display-flex flex-column flex-md-row align-items-start">
                    <div className="w-50 display-flex align-items-end">
                        <Select
                            disabled={this.state.rulesDisabled}
                            autoFocus={true}
                            isMulti={true}
                            classes="py-2"
                            field={this.form.getField('rules')}
                            options={formatOptions(RULES, false)}
                            labelInfos={{
                                label: 'Rules',
                            }}
                        />
                        <Checkbox
                            classes="py-1"
                            labelOnLeft={true}
                            field={this.helperCheckboxes.getField('rules')}
                            labelInfos={{ label: 'Not required' }}
                        />
                    </div>

                    <div className="left-m w-50 display-flex align-items-end">
                        <Select
                            disabled={this.state.filingDisabled}
                            field={this.form.getField('filing')}
                            classes="py-2"
                            isMulti={true}
                            options={formatOptions(FILING, false)}
                            labelInfos={{
                                label: 'Filing',
                            }}
                        />
                        <Checkbox
                            classes="py-1"
                            labelOnLeft={true}
                            field={this.helperCheckboxes.getField('filing')}
                            labelInfos={{ label: 'Not required' }}
                        />
                    </div>
                </div>

                <div className="display-flex flex-column flex-md-row align-items-start">
                    <div className="w-50 display-flex align-items-end">
                        <Select
                            disabled={this.state.purposeDisabled}
                            field={this.form.getField('purpose')}
                            classes="py-2"
                            isMulti={true}
                            options={formatOptions(PURPOSE, false)}
                            labelInfos={{
                                label: 'Purpose',
                            }}
                        />
                        <Checkbox
                            classes="py-1"
                            labelOnLeft={true}
                            field={this.helperCheckboxes.getField('purpose')}
                            labelInfos={{ label: 'Not required' }}
                        />
                    </div>

                    <div className="left-m w-50 ">
                        <Select
                            classes="py-2"
                            field={this.form.getField('status')}
                            isMulti={true}
                            options={formatOptions(STATUSES, false)}
                            labelInfos={{
                                label: 'Status',
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    public deleteRow() {
        if (this.props.delete) {
            this.props.delete();
        }
    }

    public getForm() {
        const form = this.form.generateJSON();

        Object.keys(form).map((field) => {
            if (!!get(this.state, `${field}Disabled`)) {
                form[field] = [];
                return;
            }
        });

        return form;
    }
}
