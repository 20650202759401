import { arrayToClass } from '../../../utilities/helper-fuctions';
import { get } from 'lodash';
import * as React from 'react';
import './status-marker.scss';

interface MarkerProps {
    tColor: string;
    bgColor: string;
    text: string;
    type?: string;
    classNames?: string;
    id?: string;
    onClick?: () => void;
}

export class StatusMarker extends React.Component<MarkerProps, any> {
    public render(): React.ReactElement {
        const classes = arrayToClass([
            this.props.id ? 'StatusMarker' : '',
            'StatusMarkerBox',
            `palette--c-${this.props.tColor}`,
            `palette--bgc-${this.props.bgColor}`,
            `border-radius-${this.props.type === 'badge' ? 1 : 50}`,
            get(this.props, 'type', null) === 'badge' ? 'px-1' : 'px-10',
            !this.props.classNames ? 'py-9' : this.props.classNames
        ]);

        return <span onClick={() => this.props.onClick && this.props.onClick()} id={this.props.id && this.props.id} className={classes}>{get(this.props, 'text')}</span>;
    }
}
