import { format } from 'date-fns';
import { clone } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
	checkData,
	getFields,
} from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import { connectNLP } from '../../../../../utilities/connect-navigate';
import {
	arrayToClass,
	redirectToLogin,
} from '../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../utilities/request';
import { ToastMessages } from '../../../../notifications/toast-messages';
import { Form } from '../../../../ui-components/form/form';
import { ContributionFooter } from '../../../shared/contributions/contribution-footer';
import { WidgetContributionPresenter } from '../../../shared/contributions/widget-contribution-presenter/widget-contribution-presenter';
import { availabilityFields } from './availability-contribution-helpers';
import { BlocksContribution } from './blocks-contribution';

interface OpeningHoursContributionsProps {
	item: any;
	original: any;
	onSuccess: () => void;
	close: () => void;
	user?: any;
	availability: string;
	date?: string;
}

const mapStateProps = (store: any) => ({
	user: store.user.user,
	date: store.userDetails.date
		? (store.userDetails.date || '').replace(/(hh:mm)( a|)/gi, '')
		: 'yyyy. MM. dd. hh:mm',
});

const mapDispatchProps = (dispatch: any) => ({});

class OpeningHoursContributionsComponent extends React.Component<
	OpeningHoursContributionsProps,
	any
> {
	public blocks$: { [key: string]: BlocksContribution } = {};

	public state = {
		isAdmin: (this.props?.user?.roles || []).includes('ADMIN'),
		type: this.props?.item?.contribution?.action,
		rejected: [],
		editable: [],
		buttonDisabled: false,
	};

	public form: any = new Form({
		regularity: this.props?.item?.exception || '',
		validFrom: this.props?.item?.validFrom || '',
		validTo: this.props?.item?.validTo || '',
		scheduleUnits: this.props?.item?.scheduleUnits || '',
	});

	public renderEmpty(): React.ReactElement {
		return (
			<div className='display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2'>
				<p className='palette--c-neutral-5 m-0'>Nothing changed.</p>
			</div>
		);
	}

	public render(): React.ReactElement {
		const availabilityList = getFields(this, availabilityFields(this));

		if (!availabilityList || !availabilityList.length) {
			return this.renderEmpty();
		}

		return (
			<div className='Content w-100'>
				<div className='p-2'>
					{(!availabilityList || !availabilityList.length) &&
						this.renderEmpty()}
					{!!availabilityList.length &&
						this.renderContent(availabilityList)}
				</div>
				<ContributionFooter
					buttonDisabled={this.state.buttonDisabled}
					isAdmin={this.state.isAdmin}
					handleButtonClick={(isReject: boolean = false) => {
						this.handleButtonClick(isReject);
					}}
					close={() => this.props.close()}
					handleEdit={() => this.handleEdit()}
				/>
			</div>
		);
	}

	public renderContent(list): React.ReactElement {
		return (
			<div className='w-100'>
				<p className='m-0 fw-bold palette--c-neutral-6'>
					Changes in availability widget
				</p>
				<div className='p-2 display-none display-md-flex'>
					<h6 className='Property palette--c-neutral-5 fw-bold m-0'>
						Property
					</h6>
					<h6 className='CurrentData palette--c-neutral-5 fw-bold m-0'>
						Current data
					</h6>
					<span className='ArrowIcons material-icons palette--c-neutral-1 fw-bold'>
						arrow_forward
					</span>
					<h6 className='NewData palette--c-neutral-5 fw-bold m-0'>
						New data
					</h6>
				</div>
				{list.map((item: any, index: number) => {
					return this.renderFields(item, index);
				})}
				{this.renderBlocks()}
			</div>
		);
	}

	public renderBlocks(): React.ReactElement {
		const {
			item: { blocks = [] },
			availability,
		} = this.props;
		const add = availability === 'ciq' ? false : availability;

		return blocks.map((item, index) => {
			return (
				<BlocksContribution
					ref={(ref: any) => (this.blocks$[`block-${index}`] = ref)}
					key={`block-${index}`}
					original={blocks[index]}
					item={item}
					adds={add}
					isAdmin={this.state.isAdmin}
				/>
			);
		});
	}

	public renderFields(field: any, index): React.ReactElement {
		const { rejected = [] } = this.state;
		const { editable = [] } = this.state;
		const { type, component, title } = field;

		const wrapperClassName = arrayToClass([
			rejected.includes(type as never)
				? 'palette--bgc-neutral-3'
				: `palette--bgc-${
						this.state.type === 'UPDATE'
							? 'yellow-1'
							: this.state.type === 'CREATE'
							? 'green-1'
							: 'red-1'
				  }`,
			'border-radius-1 my-2 p-2',
			'display-flex align-items-center',
		]);

		const element = {
			type: type,
			content: this.getData(type),
			component: component,
		};

		return (
			<WidgetContributionPresenter
				isAdmin={this.state.isAdmin}
				key={index}
				title={title}
				fromValue={this.getOriginalData(type)}
				toValue={element}
				isEditable={editable.includes(type as never)}
				isRejected={rejected.includes(type as never)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				className={wrapperClassName}
			/>
		);
	}

	public getData(field): any {
		const form = this.form.generateJSON();
		const formData = form?.[field];

		if (this.state.type === 'DELETE') {
			return null;
		}

		return this.typeRenderer(formData);
	}

	public getOriginalData(field): any {
		const data = this.props?.original?.[field];

		if (this.state.type === 'CREATE') {
			return null;
		}

		return this.typeRenderer(data);
	}

	public typeRenderer(data): any {
		if (Array.isArray(data)) {
			return data.map((d) => d.title || d).join(', ');
		}

		const formatString: string = this.props?.date || 'yyyy. MM. dd.';

		return format(new Date(data), formatString);
	}

	private async handleEdit(type: string = 'editAll'): Promise<void> {
		const { editable = [] } = this.state;

		if (type === 'editAll') {
			const editableArray = availabilityFields(this).map(
				(item: any) => item.type
			);
			await this.setState({ editable: editableArray });

			Object.keys(this.blocks$).map((key: string) =>
				this.blocks$[key].handleEdit('editAll')
			);
		}

		if (!editable.includes(type as never)) {
			await this.setState({ editable: [...editable, type] });
		}
	}

	private async handleMissEdit(type: string): Promise<void> {
		const { editable = [] } = this.state;

		if (editable.includes(type as never)) {
			const editableArray = editable.filter((item) => item !== type);
			await this.setState({ editable: editableArray });
		}
	}

	private handleReject(type: string): void {
		const { rejected = [] } = this.state;

		if (!rejected.includes(type as never)) {
			this.setState({ rejected: [...rejected, type] });
		}
	}

	private handleMissReject(type: string) {
		const { rejected = [] } = this.state;

		if (rejected.includes(type as never)) {
			const rejectedArray = rejected.filter((item) => item !== type);
			this.setState({ rejected: rejectedArray });
		}
	}

	private clearForm(): void {
		this.form.clearForm();
	}

	private onSuccess(message: string): void {
		this.clearForm();
		toast.success(message, { theme: 'light' });
		this.props.onSuccess();
		Object.keys(this.blocks$).map(
			(key: any) => this.blocks$[key] && this.blocks$[key].clearForm()
		);
	}

	private onError(err): void {
		toast.error(<ToastMessages error={err} />, { theme: 'light' });
		redirectToLogin(err);
	}

	private async handleButtonClick(isReject: boolean = false): Promise<void> {
		this.setState({ buttonDisabled: true });
		let { item } = this.props;

		if (isReject) {
			item.contribution.status = 'REJECTED';
		} else {
			item = this.checkModifications();
		}

		if (!(item?.blocks || []).length) {
			const err = "You can't merge empty block";
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}

		try {
			const response = await RequestManager.post(
				`/${this.props.availability}availabilities/merge`,
				item
			);

			if (!response) {
				throw new Error('There was an error');
			}

			this.onSuccess(
				`Contribution is successfully ${
					isReject ? 'rejected' : 'accepted'
				}`
			);
		} catch (err) {
			this.onError(err);
		} finally {
			this.setState({ buttonDisabled: false });
		}
	}

	private checkModifications(): any {
		const { item } = this.props;
		const obj = clone(item);
		const list = getFields(this, availabilityFields(this)) || [];
		const form = this.form.generateJSON();

		obj.contribution.rejectedChanges = this.state.rejected;
		obj.contribution.changes = [...checkData(list, obj, form), 'blocks'];
		obj.blocks = this.getBlocksData();

		return obj;
	}

	private getBlocksData() {
		const blocks: any = [];
		let error: boolean = false;

		for (const key of Object.keys(this.blocks$)) {
			if (this.blocks$[key]) {
				const data = this.blocks$[key].getBlock();
				const clonedData = clone(data);
				this.convertOpeningTimes(clonedData);

				if (
					!clonedData.validFrom &&
					!clonedData.validFromSunsetOffset &&
					clonedData.validFromSunsetOffset !== 0 &&
					!clonedData.validFromSunriseOffset &&
					clonedData.validFromSunriseOffset !== 0
				) {
					error = true;
					return;
				}

				if (
					!clonedData.validTo &&
					!clonedData.validToSunsetOffset &&
					clonedData.validToSunsetOffset !== 0 &&
					!clonedData.validToSunriseOffset &&
					clonedData.validToSunriseOffset !== 0
				) {
					error = true;
					return;
				}

				blocks.push(clonedData);
			}
		}

		if (!!error) {
			const err =
				'There are some blocks what are invalid, we will ignore them and save the other blocks.';
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}

		return blocks;
	}

	private convertOpeningTimes(data): any {
		let {
			validFromSunsetOffset,
			validToSunsetOffset,
			validFromSunriseOffset,
			validToSunriseOffset,
			validFrom,
			validTo,
		} = data;
		if (!validFromSunriseOffset && !validFromSunsetOffset) {
			if (typeof validFrom === 'string') {
				if (validFrom.includes('SR') || validFrom.includes('SS')) {
					validFrom = null;
					return;
				}
			}

			validFrom = validFrom;
		}

		if (!validToSunriseOffset && !validToSunsetOffset) {
			if (typeof data.validTo === 'string') {
				if (validTo.includes('SR') || validTo.includes('SS')) {
					validTo = null;
					return;
				}
			}

			validTo = validTo;
		}

		if (validFromSunriseOffset) {
			const number = data.validFromSunriseOffset.replace('SR', '');
			validFromSunriseOffset = parseFloat(number);
		}

		if (validToSunriseOffset) {
			const number = data.validToSunriseOffset.replace('SR', '');
			validToSunriseOffset = parseFloat(number);
		}

		if (validToSunsetOffset) {
			const number = data.validToSunsetOffset.replace('SS', '');
			validToSunsetOffset = parseFloat(number);
		}

		if (validFromSunsetOffset) {
			const number = data.validFromSunsetOffset.replace('SS', '');
			validFromSunsetOffset = parseFloat(number);
		}

		return data;
	}
}

export const OpeningHoursContributions: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(connectNLP(OpeningHoursContributionsComponent));
