import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import {
	checkData,
	getData,
	getFields,
} from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import {
	arrayToClass,
	randomId,
} from '../../../../../utilities/helper-fuctions';
import { MapDrawing } from '../../../../map/map-drawing/map-drawing';
import { Form } from '../../../../ui-components/form/form';
import { HoverableButton } from '../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { WidgetContributionPresenter } from '../../../shared/contributions/widget-contribution-presenter/widget-contribution-presenter';
import { fuelFields } from './constants';
import { ImagesContribution } from './image-contribution';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class FuelContributionComponent extends React.Component<any, any> {
	public images$: { [key: string]: ImagesContribution } = {};

	public state: any = {
		editable: [],
		rejected: [],
		isAdmin: get(this.props, 'user.roles', []).includes('ADMIN'),
		type: get(this.props.item, 'contribution.action'),
		buttonDisabled: false,
		editAll: false,
		title: null,
		field: null,
		isMapOpen: false,
	};

	public fuel = new Form({
		serviceLevel: this.props?.item?.serviceLevel,
	});

	public renderEmpty(): React.ReactNode {
		return (
			<div className='display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2'>
				<p className='palette--c-neutral-5 m-0'>Nothing changed.</p>
			</div>
		);
	}

	public render(): React.ReactNode {
		const fuelList: any = getFields(this, fuelFields(this));

		if (!fuelList || !fuelList.length) {
			return this.renderEmpty();
		}

		return (
			<div className='ProviderContent w-100 pl-4 pr-0'>
				{fuelList.map((item: any, index: number) => {
					return this.renderFields(item, index);
				})}
			</div>
		);
	}

	public renderFields(field, index): any {
		const wrapperClassName = arrayToClass([
			this.state.rejected.includes(field.type)
				? 'palette--bgc-neutral-3'
				: `palette--bgc-${
						this.state.type === 'UPDATE'
							? 'yellow-1'
							: this.state.type === 'CREATE'
							? 'green-1'
							: 'red-1'
				  }`,
			'border-radius-1 my-2 p-2',
			'display-flex align-items-center',
		]);

		if (
			field.type === 'fboBuildingPolygon' ||
			field.type === 'aircraftParkingPolygon' ||
			field.type === 'paxMeetingCoordinates'
		) {
			const wrapperClasses = arrayToClass([
				'WidgetContributionPresenter',
				'flex-wrap',
				wrapperClassName,
			]);

			return (
				<div key={randomId()} className={wrapperClasses}>
					<h6 className='Property palette--c-neutral-5 fw-bold m-0 display-block display-md-none'>
						Property
					</h6>
					<p className='Property Bottom fw-bold m-0 palette--c-neutral-6'>
						{field.title}:
					</p>
					<div className='flex-fill display-flex justify-content-center'>
						{field.component}
					</div>
					<div className='ButtonContainer display-flex align-items justify-content justify-content-end'>
						{!this.state.rejected.includes(field.type) && (
							<HoverableButton
								colorType='transparent-grey'
								icon='close'
								onClick={() => this.handleReject(field.type)}
							/>
						)}
						{this.state.rejected.includes(field.type) && (
							<HoverableButton
								icon='check'
								colorType='transparent-grey'
								onClick={() =>
									this.handleMissReject(field.type)
								}
							/>
						)}
					</div>
					{this.state?.isMapOpen && (
						<MapDrawing
							title={this.state.title}
							field={this.getField()}
							polygon={this.getField(true)}
							marker={
								this.state.field === 'paxMeetingCoordinates'
									? this.getField(true)
									: false
							}
							original={this.props?.original?.[this.state?.field]}
							onClose={() => this.setState({ isMapOpen: false })}
						/>
					)}
				</div>
			);
		}

		const element = {
			type: field.type,
			content: this.getData(field.type),
			component: field.component,
		};

		return (
			<WidgetContributionPresenter
				isAdmin={this.state.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(field.type)}
				toValue={element}
				isEditable={this.state.editable.includes(field.type)}
				isRejected={this.state.rejected.includes(field.type)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				className={wrapperClassName}
			/>
		);
	}

	public getField(isValue = false) {
		if (!!isValue) {
			return this.fuel.getValue(this.state?.field);
		}

		return this.fuel.getField(this.state.field);
	}

	public async onClick(title, field) {
		await this.setState({ title: title, field: field, isMapOpen: true });
	}

	public getOriginalData(field: any) {
		const fuel = get(this.props.original, field);
		const value = fuel || null;

		if (this.state.type === 'CREATE') {
			return null;
		}

		const bools: any[] = [];
		return getData(field, value, false, bools);
	}

	public getData(field: any): any {
		const fuel = this.fuel.generateJSON();
		const value = get(fuel, field);

		if (this.state.type === 'DELETE') {
			return null;
		}

		const bools: any[] = [];
		return getData(field, value, true, bools);
	}

	public async handleEdit(type: string = 'editAll'): Promise<void> {
		if (type === 'editAll') {
			const fuelArray =
				fuelFields(this).map((item: any) => item.type) || [];
			await this.setState({ editable: fuelArray, editAll: true });
		}

		if (!this.state.editable.includes(type)) {
			await this.setState({ editable: [...this.state.editable, type] });
		}
	}

	public async handleMissEdit(type: string): Promise<void> {
		if (this.state.editable.includes(type)) {
			const editableArray = this.state.editable.filter(
				(item) => item !== type
			);
			await this.setState({ editable: editableArray });
		}
	}

	public handleReject(type: string): void {
		if (!this.state.rejected.includes(type)) {
			this.setState({ rejected: [...this.state.rejected, type] });
		}
	}

	public handleMissReject(type: string) {
		if (this.state.rejected.includes(type)) {
			const rejectedArray = this.state.rejected.filter(
				(item) => item !== type
			);
			this.setState({ rejected: rejectedArray });
		}
	}

	public clearForm(): void {
		this.fuel.clearForm();
	}

	public getContribution() {
		const obj = get(this.props, 'item');
		const fuelList = getFields(this, fuelFields(this)) || [];
		const fuel = this.fuel.generateJSON();
		const changes = checkData(fuelList, obj, fuel);

		obj.contribution.rejectedChanges = this.state.rejected;
		obj.contribution.changes = changes;

		return obj || null;
	}
}

export const FuelContribution: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(FuelContributionComponent);
