export class LocalStorage {
	static provider = window.localStorage;

	static getItem = (key) => {
		try {
			const data = this.provider.getItem(key);
			return JSON.parse(data || '');
		} catch (err) {
			return undefined;
		}
	};

	static setItem = (key, value) => {
		try {
			const data = JSON.stringify(value);
			this.provider.setItem(key, data);
		} catch (err: any) {
			throw new Error(err.message);
		}
	};

	static removeItem = (key) => {
		this.provider.removeItem(key);
	};

	static clear = () => {
		this.provider.clear();
	};
}
