import { AnyAction } from 'redux';
import { LocalstorageActions } from '../actions/localstorage.actions';
import { isInQuery } from '../utilities/helper-fuctions';
import { LocalStorage } from '../utilities/local-storage';

const initialState = {
    modalIdentifier: LocalStorage.getItem('modal_identifier'),
    modalDetails: LocalStorage.getItem('modal_details'),
    nearbyMeasure: LocalStorage.getItem('nearby_measure'),
    radius: LocalStorage.getItem('radius'),
    search: LocalStorage.getItem('search'),
    sunsetAndSunrise: false,
};

export const localstorageReducer = (state: any = initialState, action: AnyAction) => {
    if (action.type === LocalstorageActions.SET_MODAL_IDENTIFIER) {
        state = { ...state, modalIdentifier: action.payload };
        LocalStorage.setItem('modal_identifier', action.payload);
    }

    if (action.type === LocalstorageActions.SET_MODAL_DETAILS) {
        state = { ...state, modalDetails: action.payload };
        LocalStorage.setItem('modal_details', action.payload);
    }

    if (action.type === LocalstorageActions.SET_MEASURE) {
        LocalStorage.setItem('nearby_measure', action.payload);

        state = { ...state, nearbyMeasure: action.payload };
    }

    if (action.type === LocalstorageActions.SET_RADIUS) {
        LocalStorage.setItem('radius', action.payload);

        state = { ...state, radius: action.payload };
    }

    if (action.type === LocalstorageActions.SET_SEARCH) {
        LocalStorage.setItem('search', action.payload);

        state = { ...state, search: action.payload };
    }

    if (action.type === LocalstorageActions.CHECK_USER) {
        const user = LocalStorage.getItem('User');

        if (!user) {
            const utmSource = isInQuery('utm_source');
            window.location.href = `/login${utmSource ? `?${utmSource}` : ''}`;
        }
    }

    if (action.type === LocalstorageActions.SUNSET_AND_SUNRISE) {
        state = { ...state, sunsetAndSunrise: action.payload };
    }

    return state;
};
