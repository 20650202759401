import { addSeconds, subSeconds } from 'date-fns';
import { get } from 'lodash';
import { parseUrl, stringify } from 'query-string';
import { LocalStorage } from './local-storage';

export const arrayToClass = (list) => list.join(' ');

export const randomId = () => {
	return Math.random().toString(36).substring(2, 9);
};

let debounceTimer;

export const debounce = (func, timeout = 1000) => {
	return (...args) => {
		if (!!timer) {
			clearTimeout(debounceTimer);
		}

		debounceTimer = setTimeout(() => {
			return func.apply(this, args);
		}, timeout);
	};
};

export const timer = (func, timeInterval = 1000) => {
	const timerTimer = setInterval(func, timeInterval);
	clearInterval(timerTimer);
};

export const removeQuery = (q: any) => {
	const currentPath = window.location.href;
	const currentQuery = parseUrl(currentPath).query;

	if (currentQuery?.[q]) {
		delete currentQuery?.[q];
	}

	return stringify(currentQuery);
};

export const replaceQuery = (q: any) => {
	const currentPath = window.location.href;
	const currentQuery = parseUrl(currentPath).query;

	let newQuery = {
		...currentQuery,
		...q,
	};

	if (!Object.keys(q).length) {
		newQuery = {};
	}

	return stringify(newQuery);
};

export const convertBoolsInParsedData = (parsedData) => {
	const keys = Object.keys(parsedData);

	for (let key of keys) {
		if (parsedData?.[key] === 'true') parsedData[key] = true;
		if (parsedData?.[key] === 'false') parsedData[key] = false;
	}

	return parsedData;
};

export const getQuery = () => {
	const currentPath = window.location.href;
	const currentQuery = parseUrl(currentPath).query;

	return convertBoolsInParsedData(currentQuery);
};

export const isInQuery = (key) => {
	const queries = getQuery();

	if (queries?.[key]) {
		return `${key}=${queries?.[key]}`;
	} else {
		return false;
	}
};

export const redirectToLogin = (err) => {
	if (get(err, 'response.status') === 403) {
		const utmSource = isInQuery('utm_source');
		window.location.href = `/login${utmSource ? `?${utmSource}` : ''}`;
		LocalStorage.removeItem('AccessToken');
		LocalStorage.removeItem('AccessTokenType');
		LocalStorage.removeItem('RefreshToken');
		LocalStorage.removeItem('User');
	}

	return;
};

export const setTitleAndMeta = (title, meta) => {
	document.title = title;
	const metaTag: any = document.getElementsByTagName('meta');
	metaTag.description.content = meta;
};

export const handleCoordinates = (obj, fieldName, isChanges = false) => {
	if (!isChanges) {
		obj[fieldName] =
			obj.latitude && obj.longitude
				? {
						latitude: parseFloat(obj.latitude),
						longitude: parseFloat(obj.longitude),
				  }
				: null;
		delete obj.latitude;
		delete obj.longitude;

		return obj;
	} else {
		const newObj = obj.filter(
			(item) => item !== 'longitude' && item !== 'latitude'
		);

		if (newObj.length < obj.length) {
			return [...newObj, fieldName];
		} else {
			return newObj;
		}
	}
};

export const toUtc = (localDate: string | Date): Date => {
	const offset = new Date(localDate).getTimezoneOffset() * 60;

	const date = new Date(localDate);

	return offset < 0 ? subSeconds(date, offset) : addSeconds(date, offset);
};

export const round = (num, dec) => {
	const trunc = Math.trunc(num);
	const decimals = (num % trunc).toString();

	if (decimals.length > dec) {
		const factor = Math.pow(10, dec);
		return Math.round(num * factor) / factor;
	} else {
		return num;
	}
};
