
export const hourFieldValidator = (value) => {
    const ssOrSrValidator = /([s]){1}([sr]){1}((([-+]| [+-]|)[0-9]+|[])|)/gi;
    const hourValidator = /(?:[01]\d|2[0123])(|:)([0-5]){1}([0-9])/;

    if (!ssOrSrValidator.test(value) && !hourValidator.test(value)) {
        return {
            error: 'Bad format, use one of these: 0000 00:00 SR+10 SS-10',
            valid: false,
        };
    } else {
        return {
            error: false,
            valid: true,
        };
    }
};
