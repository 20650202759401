import { LocalStorage } from '../utilities/local-storage';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { RequestManager } from '../utilities/request';
import { LocalstorageActions } from './localstorage.actions';
import { SettingsActions } from './settings.actions';

export abstract class UserDetailsActions {
    public static setUserDetails() {
        return async (dispatch: (action: any) => void) => {
            const user = this.getUser();

            if (!user) {
                return;
            }

            try {
                const data = await this.getUserAllDetails(user);

                if (!data) {
                    throw new Error('There was an error');
                }

                dispatch(this.setMeasures(data));
            } catch (err) {
                // err
            }
        };
    }

    public static async getUserAllDetails(user) {
        try {
            const data: any = await RequestManager.get(`/userPreferences/${user}`);

            if (!data) {
                throw new Error('There was an error');
            }

            return JSON.parse(data.preferences);
        } catch (err) {
            return false;
        }
    }

    public static setMeasures(data) {
        return async (dispatch: (action: any) => void) => {
            dispatch(SettingsActions.setMeasures(data.metric));
            dispatch(LocalstorageActions.setMeasure(data.measure));
            dispatch(LocalstorageActions.setRadius(data.radius));
            dispatch(SettingsActions.setMapNewTab(data.mapNewTab));
            dispatch(SettingsActions.setNearbyAirportFilters(data));
            dispatch(SettingsActions.setFormat(data));
            dispatch(SettingsActions.saveWidgetSettings(data.widgetOrderAndSize));
            dispatch(SettingsActions.setLastSearched(data));
        };
    }

    public static getUser() {
        const user = LocalStorage.getItem('User');
        if (user) {
            return get(user, 'aid');
        }

        return null;
    }

    public static saveUserDetails(newData, isToast: boolean = false) {
        return async (dispatch: (action: any) => void) => {
            const data = this.getUserDetails(newData);

            const user = this.getUser();

            if (!user) {
                dispatch(this.setMeasures(data));

                if (isToast) {
                    toast.success('Successfully saved', { theme: 'light' });
                }
                return;
            }

            const details = await this.getUserAllDetails(user);

            const mergedData = {
                ...details,
                ...data,
            };

            try {
                await RequestManager.put(`/userPreferences/${user}`, mergedData);

                if (isToast) {
                    toast.success('Successfully saved', { theme: 'light' });
                }
            } catch (err) {
                if (isToast) {
                    toast.error("Something happened, sorry, we couldn't save your settings.", { theme: 'light' });
                }
            } finally {
                dispatch(this.setMeasures(data));
            }
        };
    }

    public static dispatchUserDetails() {
        return (dispatch: (action: any) => void) => {
            return dispatch(() => this.getUserDetails({}));
        };
    }

    public static getUserDetails(changedData) {
        return {
            metric: LocalStorage.getItem('mu-metric'),
            startOfTheWeek: LocalStorage.getItem('mu-startOfTheWeek'),
            dateFormat: LocalStorage.getItem('mu-date'),
            hourFormat: (LocalStorage.getItem('mu-date') || '').includes('HH:mm') ? false : true,
            decimalCoordinates: LocalStorage.getItem('decimal_coordinates'),
            radius: LocalStorage.getItem('radius'),
            measure: LocalStorage.getItem('nearby_measure'),
            heliports: LocalStorage.getItem('heliports'),
            runway: LocalStorage.getItem('runway'),
            h24: LocalStorage.getItem('h24'),
            widgetOrderAndSize: LocalStorage.getItem('widgetOrderAndSize'),
            airports: LocalStorage.getItem('lastSearched'),
            countries: LocalStorage.getItem('lastSearchedCountries'),
            mapNewTab: LocalStorage.getItem('mapNewTab') || false,
            ...changedData,
        };
    }
}
