import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../actions/details.actions';
import { UserManagerForm } from '../../../components/admin/user-manager-form';
import { StatusMarker } from '../../../components/ui-components/statusmarker/status-marker';
import { ManagesLayout } from '../../../layouts/manages/manages.layout';
import { arrayToClass, randomId } from '../../../utilities/helper-fuctions';
import { BasicAdminScreen } from '../basic-admin/basic-admin.screen';

const mapStateProps = (store: any) => ({
    user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
    toggleMobile: () => dispatch(DetailsActions.toggleOpenMobile()),
});

class UserManagesScreenComponent extends BasicAdminScreen<any, any> {
    public getEndpoint() {
        return '/users/search';
    }

    public getTitle(): string {
        return 'Manage users';
    }

    public renderContent(): React.ReactElement {
        const users: any = get(this.state, 'response', []) || [];

        return (
            <div className="Contents w-100 border-radius-1">
                {users.map((item, index) => {
                    const classes = arrayToClass([
                        'ListItem w-100 p-2 pointer',
                        `palette--bgc-neutral-${index % 2 === 1 ? '1' : '2'}`,
                    ]);

                    return (
                        <div key={item.aid} className={classes} onClick={() => this.openModal(item)}>
                            <div className="row display-flex align-items-center">
                                <div className="col-24 col-md-4">
                                    <StatusMarker
                                        tColor="secondary-4"
                                        bgColor="secondary-1"
                                        text={get(item, 'aid')}
                                        type="tag"
                                    />
                                </div>
                                <p className="overflow-hidden col-24 col-md-6 m-0 palette--c-neutral-6">{item.name}</p>
                                <p className="overflow-hidden col-24 col-md-6 m-0 palette--c-neutral-6">{item.login}</p>
                                {this.renderRoles(item.roles)}
                                <p className="col-24 col-md-2 m-0 palette--c-neutral-6">{item.reward}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    public renderModal(): React.ReactElement {
        const aid = this.props?.router?.params?.aid;

        return (
            <UserManagerForm
                isEdit={true}
                endpoint={`/users/${aid}`}
                data={this.state.selected}
                aid={aid}
                onClose={() => this.handleModalClose()}
            />
        );
    }

    public openModal(user) {
        this.setState({ selected: user, isModal: true });

        this.props?.router?.navigate({
            pathname: `/admin/users/${user.aid}`,
            search: window.location.search,
        });
    }

    public handleModalClose() {
        this.setState({ selected: false, isModal: false });
        setTimeout(() => this.fetch(), 600);
    }

    public renderRoles(roles) {
        if (!(roles || []).length) {
            return <div className="col-24 col-md-6" />;
        }

        return (
            <div className="col-24 col-md-6 display-flex flex-wrap">
                {roles.map((role) => (
                    <StatusMarker
                        key={randomId()}
                        tColor="secondary-4"
                        bgColor="secondary-1"
                        text={role.replace(/_/gi, ' ')}
                        type="tag"
                    />
                ))}
            </div>
        );
    }
}

export const UserManagesScreen: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    ManagesLayout(UserManagesScreenComponent)
);
