import * as React from 'react';
import { arrayToClass } from '../../../../../../utilities/helper-fuctions';
import { Form } from '../../../../../ui-components/form/form';
import { Input } from '../../../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../../ui-components/inputs/select/select';
import { SwitchSelect } from '../../../../../ui-components/inputs/select/switch-select';
import { availabilityInfos } from '../../../../../../utilities/widget-helpers/availability-infos';
import {
	catHelis,
	eUpToI,
	faaCatAirplane,
	icaoCatAirplane,
} from '../../opening-hours-contribution/constants';
import './additions.scss';

export class ArffAdditions extends React.Component<any, any> {
	public state = {
		remote: false,
	};

	public form = new Form({
		icaoCatAirplane: this.props?.values?.icaoCatAirplane || '',
		faaCatAirplane: this.props?.values?.faaCatAirplane || '',
		faa139Certified: this.props?.values?.faa139Certified || '',
		catHelicopters: this.props?.values?.catHelicopters || '',
		waterQuantity: this.props?.values?.waterQuantity || '',
		complementaryQuantity: this.props?.values?.complementaryQuantity || '',
		arffVehicles: this.props?.values?.arffVehicles || '',
		dischargeCapacity: this.props?.values?.dischargeCapacity || '',
		extensionAvailable: this.props?.values?.extensionAvailable || '',
		extensionNotice: this.props?.values?.extensionNotice || '',
		extensionUpToIcao: this.props?.values?.extensionUpToIcao || '',
		firestationRemote: {
			value: this.props?.values?.firestationRemote || '',
			trigger: () =>
				this.setState({
					remote: this.form.getValue('firestationRemote'),
				}),
		},
		firestationNotes: this.props?.values?.firestationNotes || '',
	});

	public render(): React.ReactElement {
		const form = this.props.form ? this.props.form : this.form;

		const className = arrayToClass([
			'Additions palette--bc-neutral-3 border-1 border-radius-1 palette--bgc-neutral-1',
			!this.props.open ? 'closedAdds' : '',
		]);

		return (
			<div
				className={className}
				style={{ maxHeight: !this.props.open ? '0px' : '1000px' }}
			>
				<div className='p-4'>
					<div className='FormContainer'>
						<Select
							classes='py-2'
							field={form.getField('icaoCatAirplane')}
							options={formatOptions(icaoCatAirplane)}
							labelInfos={{
								label: 'ICAO ARFF category',
								info: availabilityInfos.arff.icaoCatAirplane,
							}}
						/>
						<Select
							classes='py-2 left-m'
							field={form.getField('faaCatAirplane')}
							options={formatOptions(faaCatAirplane)}
							labelInfos={{
								label: 'FAA ARFF category',
								info: availabilityInfos.arff.faaCatAirplane,
							}}
						/>
						<SwitchSelect
							field={form.getField('faa139Certified')}
							classes='py-2 left-m'
							labelInfos={{
								label: '14 CFR §139',
								info: availabilityInfos.arff.faa139Certified,
							}}
						/>
					</div>
					<div className='FormContainer'>
						<Select
							field={form.getField('catHelicopters')}
							classes='py-2'
							options={formatOptions(catHelis)}
							labelInfos={{
								label: 'ARFF helicopters',
								info: availabilityInfos.arff.catHelicopters,
							}}
						/>
						<Input
							field={form.getField('arffVehicles')}
							inputType='number'
							classes='py-2 left-m'
							labelInfos={{
								label: 'ARFF vehicles',
								info: availabilityInfos.arff.arffVehicles,
							}}
						/>
					</div>

					<Input
						classes='py-2'
						field={form.getField('complementaryQuantity')}
						inputType='number'
						labelInfos={{
							label: 'Complimentary Extinguishing Agent available capacity',
							info: availabilityInfos.arff.complementaryQuantity,
						}}
					/>

					<div className='FormContainer'>
						<Input
							field={form.getField('waterQuantity')}
							inputType='number'
							classes='py-2'
							labelInfos={{
								label: 'Water capacity',
								info: availabilityInfos.arff.waterQuantity,
							}}
						/>

						<Input
							field={form.getField('dischargeCapacity')}
							inputType='number'
							classes='py-2 left-m'
							labelInfos={{
								label: 'All ARFF vehicles capacity',
								info: availabilityInfos.arff.dischargeCapacity,
							}}
						/>
					</div>

					<div className='FormContainer'>
						<SwitchSelect
							field={form.getField('extensionAvailable')}
							classes='py-2'
							labelInfos={{
								info: availabilityInfos.arff.extensionAvailable,
								label: 'Upgrade availability',
							}}
						/>
						<Input
							field={form.getField('extensionNotice')}
							inputType='number'
							classes='py-2 left-m'
							labelInfos={{
								label: 'Notice required (hours)',
								info: availabilityInfos.arff.extensionNotice,
							}}
						/>
						<Select
							labelInfos={{
								label: 'Maximum ARFF',
								info: availabilityInfos.arff.extensionUpToIcao,
							}}
							classes='py-2 left-m'
							field={form.getField('extensionUpToIcao')}
							options={formatOptions(eUpToI)}
						/>
					</div>

					<div className='FormContainer'>
						<SwitchSelect
							classes='py-2'
							field={form.getField('firestationRemote')}
							labelInfos={{
								label: 'Remote ARFF service',
								info: availabilityInfos.arff.firestationRemote,
							}}
						/>

						{this.state.remote && (
							<TextInput
								field={form.getField('firestationNotes')}
								classes='py-2 left-m'
								textType='sentences'
								labelInfos={{
									label: 'Remote ARFF service notes',
									info: availabilityInfos.arff
										.firestationNotes,
								}}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}

	public getForm(): any {
		return this.form.generateJSON();
	}
}
