import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../../../actions/details.actions';
import { UserActions } from '../../../../actions/user.actions';
import { handleCoordinates } from '../../../../utilities/helper-fuctions';
import { PreLoader } from '../../../../utilities/pre-loader';
import { request } from '../../../../utilities/widget-helpers/request';
import { runwayInfos } from '../../../../utilities/widget-helpers/runway-infos';
import { ToastMessages } from '../../../notifications/toast-messages';
import { Form } from '../../../ui-components/form/form';
import { Checkbox } from '../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { CoordinatesInput } from '../../../ui-components/inputs/coordinates-input';
import { Input } from '../../../ui-components/inputs/inputs/input';
import { Label } from '../../../ui-components/inputs/label/label';
import { MeasurementInput } from '../../../ui-components/inputs/measure-input/measurement-input';
import { formatOptions } from '../../../ui-components/inputs/select/formatter';
import { Select } from '../../../ui-components/inputs/select/select';
import { SwitchSelect } from '../../../ui-components/inputs/select/switch-select';
import { WidgetModal } from '../../widget-modal';
import { runwayOptions } from './runways-contribution/runway-contribution-helpers';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
	tryRedirectIfNotAuthenticated: () =>
		dispatch(UserActions.tryRedirectIfNotAuthenticated()),
	mergeContribution: (data, endpoint, widgetId, callback) =>
		dispatch(
			DetailsActions.mergeContribution(data, endpoint, widgetId, callback)
		),
});

class RunwayFormComponent extends WidgetModal<any, any> {
	public state: any = {
		...this.state,
		isSpacing: this.props?.response?.centerLights,
		disableForm: this.props?.response?.archived,
	};

	public form = new Form(
		{
			identifier: this.props?.response?.identifier,
			magneticBearing: this.props?.response?.magneticBearing,
			trueBearing: this.props?.response?.trueBearing,
			helipad: this.props?.response?.helipad,
			surface: this.props?.response?.surface,
			latitude: this.props?.response?.thresholdCoordinates?.latitude,
			longitude: this.props?.response?.thresholdCoordinates?.longitude,
			thresholdElevation: this.props?.response?.thresholdElevation,
			slope:
				typeof this.props?.response?.slope === 'number'
					? this.props?.response?.slope * 100
					: null,
			grooved: this.props?.response?.grooved,
			tora: this.props?.response?.tora,
			toda: this.props?.response?.toda,
			asda: this.props?.response?.asda,
			lda: this.props?.response?.lda,
			width: this.props?.response?.width,
			visualApproachSlopeIndicator:
				this.props?.response?.visualApproachSlopeIndicator,
			pcnNumerical: this.props?.response?.pcnNumerical,
			pcnType: this.props?.response?.pcnType,
			pcnStrength: this.props?.response?.pcnStrength,
			pcnTirePressure: this.props?.response?.pcnTirePressure,
			pcnMethod: this.props?.response?.pcnMethod,
			approachLightConfiguration:
				this.props?.response?.approachLightConfiguration,
			thresholdLights: this.props?.response?.thresholdLights,
			touchDownZoneLights: this.props?.response?.touchDownZoneLights,
			centerLights: {
				value: this.props?.response?.centerLights,
				trigger: () =>
					this.setState({
						isSpacing: this.form.getValue('centerLights'),
					}),
			},
			centerLightsSpacing: this.props?.response?.centerLightsSpacing,
			edgeLights: this.props?.response?.edgeLights,
			endLights: this.props?.response?.endLights,
			instrumental: this.props?.response?.instrumental,
			precision: this.props?.response?.precision,
			separate: this.props?.response?.separate,
			standardPattern: this.props?.response?.standardPattern,
			archived: {
				value: this.props?.response?.archived || false,
				trigger: () =>
					this.setState({
						disableForm: this.form.getValue('archived'),
					}),
			},
		},
		(changed) => this.setState({ buttonDisabled: !changed })
	);

	public handleIdentifierCheck() {
		const value = this.form.getValue('identifier');

		if (/[a-z]/.test(value)) {
			this.form.setValue(
				'identifier',
				value.replace(/[a-z]/gi, (replacable) =>
					replacable.toUpperCase()
				)
			);
		}

		if (/[^a-zA-Z0-9]/.test(value)) {
			this.form.setValue('identifier', value.replace(/[^A-Z0-9]/gi, ''));
		}

		if (value.length > 3) {
			this.form.setValue('identifier', value.substring(0, 3));
		}
	}

	public renderModalHeader(): React.ReactElement {
		return (
			<div className='w-100 px-4 py-2'>
				<h4 className='palette--c-neutral-6 m-0 fw-bold'>
					Add new runway
				</h4>
			</div>
		);
	}

	public getTitle() {
		return this.props?.isEdit ? 'Edit runway' : 'Add new runway';
	}

	public renderForm(): React.ReactElement {
		return (
			<form>
				<div className='border-bottom-1 palette--bc-neutral-3 p-4'>
					<h6 className='palette--c-neutral-5 fw-bold text-uppercase'>
						general properties
					</h6>

					<Input
						field={this.form.getField('identifier')}
						classes='py-2 limit-w'
						labelInfos={{
							label: 'Runway ID',
							info: runwayInfos.identifier,
						}}
						disabled={this.state?.disableForm}
					/>

					<div className='FormContainer'>
						<div className='display-flex align-items-end'>
							<Input
								field={this.form.getField('magneticBearing')}
								inputType='number'
								classes='py-2'
								labelInfos={{
									label: 'Magnetic bearing',
									info: runwayInfos.magneticBearing,
								}}
								disabled={this.state?.disableForm}
							/>
							<p className='palette--c-neutral-4 mb-5 px-2'>°</p>
						</div>
						<div className='display-flex left-m  align-items-end'>
							<Input
								field={this.form.getField('trueBearing')}
								inputType='number'
								classes='py-2'
								labelInfos={{
									label: 'True bearing',
									info: runwayInfos.trueBearing,
								}}
								disabled={this.state?.disableForm}
							/>
							<p className='palette--c-neutral-4 mb-5 px-2'>°</p>
						</div>
					</div>

					<SwitchSelect
						classes='py-2 limit-w'
						field={this.form.getField('helipad')}
						labelInfos={{
							label: 'Helipad',
							classes: 'mb-2',
							info: runwayInfos.helipad,
						}}
						disabled={this.state?.disableForm}
					/>
				</div>

				<div className='border-bottom-1 palette--bc-neutral-3 p-4'>
					<h6 className='palette--c-neutral-5 fw-bold text-uppercase'>
						surface options
					</h6>
					<Select
						classes='py-2 limit-w'
						field={this.form.getField('surface')}
						options={formatOptions(runwayOptions.surfaceOpts)}
						labelInfos={{
							label: 'Surface',
							info: runwayInfos.surface,
						}}
						disabled={this.state?.disableForm}
					/>

					<CoordinatesInput
						lat={this.form.getField('latitude')}
						lng={this.form.getField('longitude')}
						classes='py-4'
						labelInfos={{
							label: 'Threshold coordinates',
							info: runwayInfos.thresholdCoordinates,
						}}
						disabled={this.state?.disableForm}
					/>

					<MeasurementInput
						type='length'
						classes='py-2 limit-w'
						field={this.form.getField('thresholdElevation')}
						labelInfos={{
							label: 'Threshold elevation',
							info: runwayInfos.thresholdElevation,
						}}
						disabled={this.state?.disableForm}
					/>

					<Label info={runwayInfos.slope} label='Slope' />
					<div className='display-flex align-items-center limit-w'>
						<Input
							field={this.form.getField('slope')}
							inputType='number'
							classes='py-2'
							disabled={this.state?.disableForm}
						/>
						<p className='palette--c-neutral-4 m-0 p-2'>%</p>
					</div>

					<SwitchSelect
						classes='py-2 limit-w'
						field={this.form.getField('grooved')}
						labelInfos={{
							label: 'Grooved',
							info: runwayInfos.grooved,
						}}
						disabled={this.state?.disableForm}
					/>
				</div>

				<div className='border-bottom-1 palette--bc-neutral-3 p-4'>
					<h6 className='palette--c-neutral-5 fw-bold text-uppercase'>
						declared distance
					</h6>

					<div className='FormContainer'>
						<MeasurementInput
							classes='py-2'
							type='length'
							field={this.form.getField('tora')}
							labelInfos={{
								label: 'TORA',
								info: runwayInfos.tora,
							}}
							disabled={this.state?.disableForm}
						/>
						<MeasurementInput
							classes='py-2 left-m'
							type='length'
							field={this.form.getField('toda')}
							labelInfos={{
								label: 'TODA',
								info: runwayInfos.toda,
							}}
							disabled={this.state?.disableForm}
						/>
					</div>

					<div className='FormContainer'>
						<MeasurementInput
							type='length'
							field={this.form.getField('asda')}
							classes='py-2'
							labelInfos={{
								label: 'ASDA',
								info: runwayInfos.asda,
							}}
							disabled={this.state?.disableForm}
						/>
						<MeasurementInput
							type='length'
							field={this.form.getField('lda')}
							classes='py-2 left-m'
							labelInfos={{ label: 'LDA', info: runwayInfos.lda }}
							disabled={this.state?.disableForm}
						/>
					</div>

					<MeasurementInput
						classes='py-2 limit-w'
						type='length'
						field={this.form.getField('width')}
						labelInfos={{ label: 'Width', info: runwayInfos.width }}
						disabled={this.state?.disableForm}
					/>

					<Select
						classes='py-2'
						field={this.form.getField(
							'visualApproachSlopeIndicator'
						)}
						options={formatOptions(runwayOptions.vasiOpts, true)}
						labelInfos={{
							label: 'Visual approach slope indicator',
							info: runwayInfos.visualApproachSlopeIndicator,
						}}
						disabled={this.state?.disableForm}
					/>
				</div>

				<div className='border-bottom-1 palette--bc-neutral-3 p-4'>
					<h6 className='palette--c-neutral-5 fw-bold text-uppercase'>
						pavement classification number
					</h6>

					<Input
						field={this.form.getField('pcnNumerical')}
						inputType='number'
						classes='py-2 limit-w'
						labelInfos={{
							label: 'Numerical',
							info: runwayInfos.pcnNumerical,
						}}
						disabled={this.state?.disableForm}
					/>

					<div className='FormContainer'>
						<Select
							classes='py-2'
							options={formatOptions(runwayOptions.typeOpts)}
							field={this.form.getField('pcnType')}
							labelInfos={{
								label: 'Type',
								info: runwayInfos.pcnType,
							}}
							disabled={this.state?.disableForm}
						/>
						<Select
							labelInfos={{
								label: 'Strength',
								info: runwayInfos.pcnStrength,
							}}
							options={formatOptions(runwayOptions.strengthOpts)}
							classes='py-2 left-m'
							field={this.form.getField('pcnStrength')}
							disabled={this.state?.disableForm}
						/>
					</div>

					<div className='FormContainer'>
						<Select
							classes='py-2'
							field={this.form.getField('pcnTirePressure')}
							options={formatOptions(
								runwayOptions.tirePressureOpts
							)}
							labelInfos={{
								label: 'Tire pressure',
								info: runwayInfos.pcnTirePressure,
							}}
							disabled={this.state?.disableForm}
						/>
						<Select
							classes='py-2 left-m'
							field={this.form.getField('pcnMethod')}
							options={formatOptions(runwayOptions.methodOpts)}
							labelInfos={{
								label: 'Method',
								info: runwayInfos.pcnMethod,
							}}
							disabled={this.state?.disableForm}
						/>
					</div>
				</div>

				<div className='border-bottom-1 palette--bc-neutral-3 p-4'>
					<h6 className='palette--c-neutral-5 fw-bold text-uppercase'>
						lights
					</h6>

					<Select
						classes='py-2 limit-w'
						field={this.form.getField('approachLightConfiguration')}
						options={formatOptions(runwayOptions.alcOpts)}
						labelInfos={{
							info: runwayInfos.approachLightConfiguration,
							label: 'Approach light configuration',
						}}
						disabled={this.state?.disableForm}
					/>
					<SwitchSelect
						classes='py-2 limit-w'
						field={this.form.getField('thresholdLights')}
						labelInfos={{
							info: runwayInfos.thresholdLights,
							label: 'Threshold lights',
						}}
						disabled={this.state?.disableForm}
					/>
					<SwitchSelect
						field={this.form.getField('touchDownZoneLights')}
						classes='py-2 limit-w'
						labelInfos={{
							info: runwayInfos.touchDownZoneLights,
							label: 'Touch-down zone lights',
						}}
						disabled={this.state?.disableForm}
					/>

					<SwitchSelect
						field={this.form.getField('centerLights')}
						classes='py-2 limit-w'
						labelInfos={{
							info: runwayInfos.centerLights,
							label: 'Center lights',
						}}
						disabled={this.state?.disableForm}
					/>

					{this.state.isSpacing && (
						<MeasurementInput
							type='length'
							field={this.form.getField('centerLightsSpacing')}
							classes='limit-w py-2'
							labelInfos={{
								label: 'Center lights spacing',
								info: runwayInfos.centerLightsSpacing,
							}}
							disabled={this.state?.disableForm}
						/>
					)}

					<SwitchSelect
						field={this.form.getField('edgeLights')}
						classes='py-2 limit-w'
						labelInfos={{
							info: runwayInfos.edgeLights,
							label: 'Edge lights',
						}}
						disabled={this.state?.disableForm}
					/>

					<SwitchSelect
						field={this.form.getField('endLights')}
						classes='py-2 limit-w'
						labelInfos={{
							info: runwayInfos.endLights,
							label: 'End lights',
						}}
						disabled={this.state?.disableForm}
					/>
				</div>

				<div className='p-4'>
					<h6 className='palette--c-neutral-5 fw-bold text-uppercase'>
						other options
					</h6>

					<SwitchSelect
						field={this.form.getField('instrumental')}
						classes='py-2 limit-w'
						labelInfos={{
							info: runwayInfos.instrumental,
							label: 'Instrumental',
						}}
						disabled={this.state?.disableForm}
					/>
					<SwitchSelect
						field={this.form.getField('precision')}
						classes='py-2 limit-w'
						labelInfos={{
							info: runwayInfos.precision,
							label: 'Precision',
						}}
						disabled={this.state?.disableForm}
					/>
					<SwitchSelect
						field={this.form.getField('separate')}
						classes='py-2 limit-w'
						labelInfos={{
							info: runwayInfos.separate,
							label: 'Separate',
						}}
						disabled={this.state?.disableForm}
					/>
					<SwitchSelect
						field={this.form.getField('standardPattern')}
						classes='py-2 limit-w'
						labelInfos={{
							info: runwayInfos.standardPattern,
							label: 'Standard pattern',
						}}
						disabled={this.state?.disableForm}
					/>

					<Checkbox
						field={this.form.getField('archived')}
						labelInfos={{
							label: 'Permanently closed',
						}}
						classes='py-2'
					/>
				</div>
			</form>
		);
	}

	public getType() {
		return 'runway';
	}

	public onClose(isDelete): any {
		this.form?.clearForm();
		this.modal$?.close();
		this.props?.onClose();
	}

	public async onSave() {
		this.setState({ saveLoading: true, buttonDisabled: true });

		const data = await this.getResponse();
		const changes = handleCoordinates(
			this.form?.getDifferences(),
			'thresholdCoordinates',
			true
		);

		const req = request(
			'/runways/merge',
			'runways',
			() => this.handleSuccessModal(),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		);

		if (this.props?.isEdit) {
			await req.edit(`/runways/${this.props?.aid}`, data, changes);
		} else {
			await req.create('/runways', data, changes, {
				parentAid: this.props?.aid,
			});
		}

		this.setState({ saveLoading: false, buttonDisabled: false });
	}

	public async onDelete(): Promise<void> {
		this.setState({ saveLoading: true, buttonDisabled: true });
		const aid = this.props?.aid;

		await request(
			'/runways/merge',
			'runways-delete',
			() => this.handleSuccessModal(),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		).deleteF(
			`/runways/${aid}`,
			handleCoordinates(
				this.form?.getFieldsWhereHasValue(),
				'thresholdCoordinates',
				true
			)
		);

		this.setState({ saveLoading: false, buttonDisabled: false });
	}

	public async getResponse(): Promise<any> {
		const values: any = this.form.generateJSON();
		handleCoordinates(values, 'thresholdCoordinates');

		values.slope = values.slope
			? parseFloat((parseFloat(values.slope) / 100).toFixed(7))
			: values.slope === 0
			? 0
			: null;

		return values;
	}

	public handleSuccessModal() {
		if (this.notif$) {
			this.notif$.open();
		}

		this.modal$.close();
	}
}

export const RunwayForm: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(PreLoader(RunwayFormComponent));
