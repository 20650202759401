import * as React from 'react';
import { toast } from 'react-toastify';
import { ToastMessages } from '../../components/notifications/toast-messages';
import { Form } from '../../components/ui-components/form/form';
import { HoverableButton } from '../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { Password } from '../../components/ui-components/inputs/inputs/password';
import { LoginLayout } from '../../layouts/login/login.layout';
import { getQuery, isInQuery } from '../../utilities/helper-fuctions';
import { RequestManager } from '../../utilities/request';
import './reset-password.screen.scss';

class ResetPasswordScreenComponent extends React.Component<any, any> {
    public form: any = new Form({
        password: '',
        passwordAgain: '',
    });

    public render(): React.ReactElement {
        return (
            <div className="ResetPasswordScreen w-100 display-flex justify-content-center">
                <div className="ResetPasswordContainer w-100 palette--bgc-neutral-1 border-radius-1 p-6 elevation-1">
                    <h2 className="palette--c-neutral-6 mb-4">Add new password</h2>

                    <form>
                        <Password
                            field={this.form.getField('password')}
                            classes="mb-6"
                            labelInfos={{ label: 'New password' }}
                        />

                        <Password
                            field={this.form.getField('passwordAgain')}
                            classes="mb-6"
                            labelInfos={{ label: 'New password again' }}
                        />

                        <div className="w-100 display-flex justify-content-end">
                            <HoverableButton
                                colorType="avio-green"
                                titleLeft="Save and login"
                                icon="check_circle"
                                className="border-radius-1 elevation-1"
                                isSave={true}
                                onClick={() => this.handleNewPassword()}
                            />
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    public async handleNewPassword(): Promise<void> {
        try {
            const token = getQuery().token;

            if (!token) {
                throw new Error('Invalid token');
            }

            const form = this.form.generateJSON();

            if (!form.password || !form.passwordAgain) {
                throw new Error('The password or the confirmation password is empty.');
            }

            if (form.password !== form.passwordAgain) {
                throw new Error('The password and the confirmation password do not match.');
            }

            const data = {
                token: token,
                password: form.password,
            };

            const response = await RequestManager.post('/auth/resetPassword', data, {}, { noHeader: true });

            if (!response) {
                throw new Error('Something happened! Can you try again, please');
            }

            const utmSource = isInQuery('utm_source');
            this.props?.router?.navigate({ pathname: '/login', search: utmSource });
        } catch (err) {
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
        }
    }
}

export const ResetPasswordScreen: any = LoginLayout(ResetPasswordScreenComponent);
