import * as React from 'react';
import { randomId } from '../../../../../../utilities/helper-fuctions';
import { PreLoader } from '../../../../../../utilities/pre-loader';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { AuthorityContactsEdit } from './authority-contacts-form';

class ListContactsComponent extends React.Component<any, any> {
	public contacts$: { [key: string]: any } = {};

	public state = {
		contacts: this.props?.response || [],
		changedContacts: [],
		createContacts: [],
	};

	public componentDidMount() {
		this.checkContacts(this.props?.response);
	}

	public checkContacts(list) {
		if (!(list || []).length) {
			this.props.disableDelete(false);
		}
	}

	public getContacts() {
		let contacts: any = [];

		const obj = this.state?.createContacts;
		contacts = Object.keys(obj).map((key) => obj[key]) || [];

		if (this.props?.isEdit) {
			contacts = [...this.state?.contacts, ...contacts];
		}

		return contacts;
	}

	public render() {
		const contacts = this.getContacts();

		return (
			<div className='row p-4'>
				<label className='col-24'>Contacts:</label>
				{!!contacts.length && (
					<div className='px-3 w-100 display-none display-sm-block'>
						<div className='row'>
							<span className='col-24 col-sm-7 fw-bold palette--c-neutral-5'>
								Name
							</span>
							<span className='col-24 col-sm-7 fw-bold palette--c-neutral-5'>
								Type
							</span>
							<span className='col-24 col-sm-7 fw-bold palette--c-neutral-5'>
								Details
							</span>
						</div>
					</div>
				)}
				{contacts.map((item, index) =>
					this.renderContacts(item, index)
				)}

				<div className='col-24'>
					<HoverableButton
						type='button'
						className='border-radius-1'
						colorType='avio-green'
						onClick={() => this.addNewContact()}
						title='Add contacts'
					/>
				</div>
			</div>
		);
	}

	public renderContacts(item, index) {
		const key = item?.aid || item?.key;
		const contact = this.state?.changedContacts?.[key] || item;

		return (
			<AuthorityContactsEdit
				key={key}
				ref={(ref: any) => (this.contacts$[key] = ref)}
				values={contact}
				originalValues={item?.aid ? item : false}
				handleSaveChanges={(newItem: any) =>
					this.handleChange(key, newItem, index)
				}
				handleDelete={(deletable: any) =>
					this.deleteEditContact(deletable)
				}
				onEmptyClose={() => this.deleteCreateContact(key)}
				checkChange={() => this.checkChange()}
			/>
		);
	}

	public addNewContact() {
		const id = randomId();
		this.setState({
			createContacts: {
				...this.state?.createContacts,
				[id]: { key: id },
			},
		});
	}

	public deleteCreateContact(key) {
		const contacts = this.state?.createContacts;
		delete this.state?.createContacts[key];
		this.setState({ createContacts: contacts });
	}

	public deleteEditContact(item) {
		const changedContacts = (this.state?.contacts || []).filter(
			(contact) => contact.aid !== item
		);
		const changedChangedContacts = this.state.changedContacts;

		delete changedChangedContacts[item];

		this.setState({
			contacts: changedContacts,
			changedContacts: changedChangedContacts,
		});

		this.checkContacts(changedContacts);
	}

	public checkChange() {
		const keys = Object.keys(this.contacts$);
		let changed = false;
		let counter = 0;

		while (!changed && counter < (keys || []).length) {
			const key = keys[counter];
			if (!this.contacts$[key]?.state?.buttonDisabled) {
				changed = true;
			} else {
				counter++;
			}
		}

		this.props?.setChange(changed);
	}

	public handleChange(key, item, index) {
		if (!item?.aid) {
			this.handleCreateChange(key, item);
		} else {
			this.handleEditChange(item, index);
		}
	}

	public handleCreateChange(key, item) {
		this.setState({
			createContacts: {
				...this.state?.createContacts,
				[key]: { ...item, key },
			},
		});
	}

	public handleEditChange(item, index) {
		const contacts = this.props?.response;

		this.setState({
			changedContacts: {
				...this.state.changedContacts,
				[contacts[index].aid]: item,
			},
		});
	}

	public async handleSaveContacts(aid, isMergedParent = false) {
		const eKeys = Object.keys(this.state.changedContacts);
		for (let key of eKeys) {
			if (!!this.contacts$[key]) {
				await this.contacts$[key].handleSave();
			}
		}

		const cKeys = Object.keys(this.state.createContacts);
		for (let key of cKeys) {
			if (!!this.contacts$[key]) {
				await this.contacts$[key].handleSave(aid, isMergedParent);
			}
		}

		this.props?.handleSuccessModal();
	}
}

export const ListContacts = PreLoader(ListContactsComponent);
