import $ from 'jquery';
import { get, uniq } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { connectNLP } from '../../../utilities/connect-navigate';
import { arrayToClass, isInQuery, redirectToLogin } from '../../../utilities/helper-fuctions';
import { LocalStorage } from '../../../utilities/local-storage';
import { RequestManager } from '../../../utilities/request';
import { AirportCard } from '../../cards/airport/airport.card';
import { NearbyFilters } from '../../filters/nearby-filters';
import { ToastMessages } from '../../notifications/toast-messages';
import { Spinner } from '../../ui-components/spinner/spinner';
import '../nearby-airports/nearby-airports.scss';
import { VisitComponent } from '../visit-component';

const mapStateProps = (store: any) => ({
    config: store.config.routeConfig,
    runway: store.userDetails.runway,
    heliports: store.userDetails.heliports,
    h24: store.userDetails.h24,
});

const mapDispatchProps = (dispatch: any) => ({});

class CountryAirportsComponent extends React.Component<any, any> {
    public parentRef$ = React.createRef();

    public state: any = {
        ...this.state,
        items: [],
        currentItems: [],
        counter: 0,
        weathers: false,
    };

    public async componentDidMount(): Promise<void> {
        await this.fetch(false);
    }

    public async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): Promise<void> {
        if (this.state.currentItems !== prevState.currentItems) {
            await this.fetchWeather();
        }

        if (this.props.aid !== prevProps.aid) {
            await this.fetch(false);
        }
    }

    public async fetch(enableAppend = true) {
        try {
            if (!this.props.aid) {
                return;
            }

            const page = enableAppend ? get(this.state, 'counter') : 0;

            this.setState({ loading: true, error: null, counter: page });

            const airports = await RequestManager.get(
				'/airports/search?includeArchived=true',
				{ country: this.props.aid, page: page }
			);

            if (enableAppend) {
                this.setState({
                    response: airports,
                    loading: false,
                    error: null,
                    items: [...this.state.items, ...get(airports, 'content', [])],
                    currentItems: get(airports, 'content', []),
                });
            } else {
                this.setState({
                    response: airports,
                    loading: false,
                    error: null,
                    items: get(airports, 'content', []),
                    currentItems: get(airports, 'content', []),
                });
            }
        } catch (err) {
            this.setState({
                loading: false,
                response: null,
                error: get(err, 'response.data.message', null) || err || 'Error',
            });
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
            redirectToLogin(err);
        }
    }

    public async fetchWeather(): Promise<void> {
        const items = get(this.state, 'currentItems', []);

        if (!items.length) {
            return;
        }

        try {
            const ids = items.map((item: any) => `${item.aid}`).join(',');

            const response = await RequestManager.get('/weather', { aid: ids });

            if (!response) {
                throw new Error('There is no weather');
            }

            if (!this.state.weathers) {
                this.setState({ weathers: response });
            } else {
                this.setState({ weathers: { ...this.state.weathers, ...response } });
            }
        } catch (err) {
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
            redirectToLogin(err);
        }
    }

    public render(): React.ReactElement {
        const classes = arrayToClass(['NearbyAirports', 'h-100', 'palette--bgc-neutral-2 position-relative', 'mt-5']);

        return (
            <div className={classes}>
                <div className="ListTitle px-3 pt-3 pb-2 display-flex align-items-center">
                    <h6 className="m-0 text-uppercase palette--c-neutral-5 flex-fill">Airports</h6>
                    <NearbyFilters />
                </div>

                <div
                    className="List w-100 h-100 px-3 display-flex flex-column position-relative"
                    ref={(ref: any) => (this.parentRef$ = ref)}
                >
                    {this.state.error && this.renderNotFound()}
                    {this.state.loading && !!(get(this.state, 'items', false) || []).length && this.renderLoading()}
                    {this.state.items && this.state.currentItems && this.renderCards()}
                </div>
            </div>
        );
    }

    public renderNotFound(): React.ReactElement {
        return (
            <div className="w-100 h-100 p-3 display-flex flex-column justify-content-center align-items-center">
                <img src="/assets/no-nearby-airport.svg" alt="no-nearby" className="NoNearbyAirportLogo" />
                <p className="palette--neutral-5 mt-3">We don't have any airport for this country in our database.</p>
            </div>
        );
    }

    public renderLoading(): React.ReactElement {
        return <Spinner size="large" />;
    }

    public hasMore() {
        const totalPages = get(this.state.response, 'page.totalPages', 0);
        return this.state.counter !== totalPages && this.state.counter < totalPages;
    }

    public renderCards(): React.ReactElement {
        let allCountries = get(this.state, 'items', false) || [];

        if (!allCountries || !allCountries.length) {
            return this.renderNotFound();
        }

        if (!this.props.heliports) {
            allCountries = allCountries.filter((airport) => airport.icon !== 'HELIPORT');
        }

        const opened = ($('.List').width() || 0) > 0;

        return (
            <div className="w-100">
                {allCountries.map((airport: any, index: number) => (
                    <AirportCard
                        key={index}
                        data={airport}
                        className="my-2 elevation-1"
                        onClick={() => this.changeAirport(airport.aid)}
                        enableIcon={get(this.props.config, 'wide')}
                        weather={get(this.state.weathers, airport.aid)}
                        hasOwnWeather={true}
                    />
                ))}
                {opened && (
                    <VisitComponent
                        parentClass="List"
                        fetch={() => this.handleFetch()}
                        itemLength={(get(this.state, 'items', false) || []).length}
                    />
                )}
                {this.state.loading && !!(allCountries || []).length && this.renderLoading()}
                {!this.hasMore() && this.renderNoMore()}
            </div>
        );
    }

    public changeAirport(aid: any) {
        this.setState({ counter: 0 });
        const prevSearched = LocalStorage.getItem('lastSearched') || [];
        prevSearched.unshift(aid);

        const sliced = prevSearched.slice(0, 3);

        const individualized = uniq(sliced);

        LocalStorage.setItem('lastSearched', individualized);

        const utmSource = isInQuery('utm_source') || '';
        this.props?.router?.navigate({ pathname: `/airports/${aid}`, search: utmSource });
    }

    public async handleFetch() {
        if (this.hasMore()) {
            await this.setState({ counter: this.state.counter + 1 });
            await this.fetch();
        }
    }

    public renderNoMore(): React.ReactElement {
        return (
            <div className="display-flex justify-content-center p-2 mb-2">
                <p className="palette--c-neutral-5 m-0">There is no other airport in this country.</p>
            </div>
        );
    }
}

export const CountryAirports: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    connectNLP(CountryAirportsComponent)
);
