import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../../actions/details.actions';
import { connectNLP } from '../../../../utilities/connect-navigate';
import { arrayToClass, isInQuery } from '../../../../utilities/helper-fuctions';
import { ContributeButton } from '../../../other/contribute-button';
import { Tab } from '../../../ui-components/tab/tab';
import { Widget } from '../../widget';
import { ProviderCard } from './provider-cards/provider-card';

const providers = [
	{
		title: 'FBO & Handling',
		icon: 'pen',
		types: ['HANDLING'],
	},
	{
		title: 'Fuel',
		icon: 'gas-pump',
		types: ['FUEL'],
	},
	{
		title: 'Transport',
		icon: 'car',
		types: ['TAXI', 'PRIVATE_TRANSFER', 'CAR_RENTAL'],
	},
	{
		title: 'Catering',
		icon: 'utensils',
		types: ['CATERING'],
	},
	{
		title: 'Aircraft Services',
		icon: 'build',
		types: [
			'MAINTENANCE',
			'AIRCRAFT_SERVICING',
			'DEICING',
			'AIRCRAFT_CLEANING',
			`AIRCRAFT_SUPPLIES`,
		],
		material: 'material-icons-round',
	},
	{
		title: 'Amenities',
		icon: 'add_circle',
		types: [
			'RESTAURANT',
			'CAFETERIA',
			'LAUNDRY',
			'DISH_WASHING',
			'TAX_REFUND',
			'SUPERMARKET',
		],
		material: 'material-icons-round',
	},
	{
		title: 'Hotel',
		icon: 'hotel',
		types: ['HOTEL'],
		material: 'material-icons-round',
	},
];

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
	setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

class ProvidersComponent extends Widget<any, any> {
	public state: any = {
		pickedProvider: null,
		modalContent: null,
		providerTabs: providers,
	};

	public formatData(response: any): void {
		const addProvidersToTabs = providers.map((provider: any) => {
			provider.providers = response.filter((item) =>
				provider?.types?.includes(item?.category)
			);
			return provider;
		});

		const showableTabs = addProvidersToTabs.filter(
			(tab: any) => !!(tab.providers || []).length
		);

		this.setState({ providerTabs: showableTabs });
	}

	public getEndpoint(): string {
		return `/airports/${this.props?.airport?.aid}/providers/all?includeArchived=true`;
	}

	public renderContent(): React.ReactElement {
		return (
			<div className='ProviderWidget'>
				<div>
					<Tab
						items={this.state?.providerTabs}
						onItemClick={(ctx, data) => this.changeView(data)}
						selected={this.state.pickedProvider}
					/>
				</div>
				{this.renderProviderCard()}
			</div>
		);
	}

	public changeView(title: string) {
		this.setState({ pickedProvider: title });
	}

	public renderProviderCard(): React.ReactElement {
		const providersList =
			this.state?.pickedProvider?.providers ||
			this.state?.providerTabs[0]?.providers;

		if ((providersList || []).length > 0) {
			return providersList.map((content: any, index: number) =>
				this.renderCard(content, index)
			);
		}

		return (
			<div className='w-100 display-flex justify-content-center py-6'>
				<ContributeButton
					openModal={() =>
						this.props?.router?.navigate(
							`/airports/${this.props?.airport?.aid}/providers`
						)
					}
					buttonOnly={true}
				/>
			</div>
		);
	}

	public renderCard(content: any, index: number): React.ReactElement {
		const isFeatured = content.featuredOrder && content.featuredOrder < 0;
		const archived = content?.archived;

		const backgroundColor = `palette--bgc-${
			archived ? 'neutral-2' : isFeatured ? 'primary-4' : 'neutral-1'
		}`;
		const textColor = `palette--c-${
			archived ? 'neutral-5' : isFeatured ? 'neutral-1' : 'neutral-6'
		}`;
		const borderColor = `palette--bc-${
			archived ? 'neutral-3' : isFeatured ? 'primary-4' : 'neutral-4'
		}`;

		const containerClasses = arrayToClass([
			'overflow-hidden border-radius-1 m-2 my-3',
			isFeatured ? 'elevation-1' : '',
			archived || !isFeatured ? 'border-1' : '',
			borderColor,
		]);

		const headerClasses = arrayToClass([
			'py-2 px-4 display-flex border-bottom-1',
			borderColor,
			backgroundColor,
		]);

		const textClasses = arrayToClass(['m-0 flex-fill', textColor]);

		const flagClasses = arrayToClass(['m-0 text-uppercase', textColor]);

		return (
			<div key={`${index}-${content.name}`} className={containerClasses}>
				<div className={headerClasses}>
					<h4 className={textClasses}>{get(content, 'name', '')}</h4>
					{(archived || isFeatured) && (
						<h6 className={flagClasses}>
							{archived ? 'Permanently closed' : 'featured'}
						</h6>
					)}
				</div>
				<ProviderCard content={content} />
				{this.renderFooter(
					content,
					`palette--bc-neutral-${archived ? '3' : '4'}`
				)}
			</div>
		);
	}

	public renderFooter(content: any, borderColor): React.ReactElement {
		const footerContainerClasses = arrayToClass([
			'FooterButton w-100 display-flex justify-content-center align-items-center p-4 border-top-1 pointer',
			borderColor,
		]);
		return (
			<div
				className={footerContainerClasses}
				onClick={() => this.open(content)}
			>
				<p className='PointerCursor m-0 palette--c-primary-4 mr-2'>
					See provider details
				</p>
				<p className='PointerCursor fas fa-arrow-right m-0 palette--c-primary-4' />
			</div>
		);
	}

	public open(content: any) {
		const utmSource = isInQuery('utm_source');
		this.props?.router?.navigate({
			pathname: `/airports/${this.props?.airport?.aid}/${content?.aid}`,
			search: utmSource,
		});
	}
}

export const Providers: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(connectNLP(ProvidersComponent));
