import { get } from 'lodash';
import * as React from 'react';
import { arrayToClass, round } from '../../../utilities/helper-fuctions';
import { Form } from '../form/form';
import { Switch } from './checkboxes/switch-checkbox/switch-checkbox';
import { Input } from './inputs/input';
import { Label } from './label/label';

export class CoordinatesInput extends React.Component<any, any> {
	public state = {
		isDms: this.props?.dms || false,
		originalLatInDms: this.props?.lat
			? this.generateOriginalDms(true)
			: false,
		originalLngInDms: this.props?.lng ? this.generateOriginalDms() : false,
	};

	public form = new Form(
		{
			dms: {
				value: this.props?.dms || false,
				trigger: () => this.handleDMSChange(),
			},
			NorS: {
				value: this.props?.lat?.value < 0 || false,
				trigger: () => this.handleDirectionChange('NorS','lat'),
			},
			EorW: {
				value: this.props?.lng?.value < 0 || false,
				trigger: () => this.handleDirectionChange('EorW','lng'),
			},
			latDegs: {
				value: this.getFieldValue(true, 'deg'),
				trigger: () => this.handleNumberChanges('latDegs', 0, 90),
                mandatory: true,
			},
			latMins: {
				value: this.getFieldValue(true, 'min'),
				trigger: () => this.handleNumberChanges('latMins', 0, 59),
                mandatory: true,
			},
			latSecs: {
				value: this.getFieldValue(true),
				trigger: () => this.handleNumberChanges('latSecs', 0, 59),
                mandatory: true,
			},
			lngDegs: {
				value: this.getFieldValue(false, 'deg'),
				trigger: () => this.handleNumberChanges('lngDegs', 0, 180),
                mandatory: true,
			},
			lngMins: {
				value: this.getFieldValue(false, 'min'),
				trigger: () => this.handleNumberChanges('lngMins', 0, 59),
                mandatory: true,
			},
			lngSecs: {
				value: this.getFieldValue(false),
				trigger: () => this.handleNumberChanges('lngSecs', 0, 59),
                mandatory: true,
			},
		},
		() => this.handleFormChange()
	);

	public handleDMSChange() {
		const value = this.form.getValue('dms');
		this.setState({ isDms: value });

		if (this.props.setDMS && this.props.dms !== value) {
			this.props.setDMS(value);
		}

		if (!value) {
			this.toDecimal();
			this.toDecimal(true);
		} else {
			this.toDms();
			this.toDms(true);
		}
	}

	public handleDirectionChange(type, field) {
		const latOrLngValue = this.props?.[field]?.newValue;
		const value = this.form.getValue(type);
             console.log(latOrLngValue,"xxaa1");


              if(latOrLngValue !== undefined){
            const  newValue  = value
			? Math.abs(latOrLngValue)
			: -Math.abs(latOrLngValue);
		    this.props?.[field].setValue(newValue);
         }
	}

	public handleNumberChanges(field, min, max) {
		if (this.state.isDms) {
			const value = this.form.getValue(field);



			if (value > max) {
				this.form.setValue(field, max);
			} else if (value < min) {
				this.form.setValue(field, min);
			}
		}
	}

	public handleFormChange() {
		if (this.state.isDms) {
			const values = this.form.generateJSON();

			const isChanged =
				!!this.changeChecker(values) ||
				!!this.changeChecker(values, true);

			if (!!isChanged) {
				this.toDecimal();
				this.toDecimal(true);
			}
		}
	}

	public componentDidUpdate(prevProps) {
		if (this.props.dms !== prevProps.dms) {
			this.setState({ isDms: this.props.dms });
			this.form.setValue('dms', this.props.dms);
		}
	}

	public getFieldValue(isLat, field: any = false) {
		const value = this.props?.[isLat ? 'lat' : 'lng']?.value;

		if (!value) {
			return '';
		}

		const deg = Math.abs(value);
		if (field === 'deg') {
			return Math.trunc(deg);
		}

		const min = (deg % 1) * 60;
		if (field === 'min') {
			return Math.trunc(min);
		}

		const sec = Math.abs(min % 1) * 60;
		return Math.trunc(sec);
	}

	public generateOriginalDms(isLat: boolean = false) {
		const type = isLat ? 'lat' : 'lng';
		const num = this.props?.[type]?.newValue;

		if (num === null || typeof num === 'undefined') {
			return {
				type: type,
				latOrLng: isLat ? 'NorS' : 'EorW',
				latOrLngValue: false,
				deg: null,
				min: null,
				sec: null,
			};
		}

		const deg = Math.abs(num);
		const min = (deg % 1) * 60;
		const sec = (min % 1) * 60;

		return {
			type: type,
			latOrLng: isLat ? 'NorS' : 'EorW',
			latOrLngValue: !(num > 0),
			deg: Math.trunc(deg),
			min: Math.trunc(min),
			sec: Math.round(sec * 100) / 100,
		};
	}

	public toDms(isLat = false) {
		const type = isLat ? 'lat' : 'lng';

		if (!get(this.props, type)) {
			return;
		}

		const valueInDms: any = round(this.generateOriginalDms(isLat), 7);

		this.form.setValue(valueInDms.latOrLng, valueInDms.latOrLngValue);
		this.form.setValue(`${valueInDms.type}Degs`, valueInDms.deg);
		this.form.setValue(`${valueInDms.type}Mins`, valueInDms.min);
		this.form.setValue(`${valueInDms.type}Secs`, valueInDms.sec);
	}

	public toDecimal(isLat = false) {
		const type = isLat ? 'lat' : 'lng';
		const latOrLng = isLat ? 'NorS' : 'EorW';

		const degs = this.form.getValue(`${type}Degs`);
		const mins = this.form.getValue(`${type}Mins`);
		const secs = this.form.getValue(`${type}Secs`);
        console.log(degs,mins,secs,"xxaa1");

		if (!degs || !mins || !secs) {
            console.log("calling xxaa1 ");

            this.props.lat.setValue()
            this.props.lng.setValue()
			return;
		}

		const absNumber =
			(degs ? parseFloat(degs) : 0) +
			(mins ? parseFloat(mins) : 0) / 60 +
			(secs ? parseFloat(secs) : 0) / 3600;
		const plusOrMinus = this.form.getValue(latOrLng);
		const number = round(plusOrMinus ? -absNumber : absNumber, 7);

		if (isLat) {
			this.props.lat.setValue(number);
		} else {
			this.props.lng.setValue(number);
		}
	}

	public changeChecker(newValues, isLat: boolean = false) {
		if (!!this.props.isContribute) {
			return true;
		}

		const originalValues: any = get(
			this.state,
			`original${isLat ? 'Lat' : 'Lng'}InDms`
		);

		if (
			newValues[isLat ? 'NorS' : 'EorW'] !== originalValues.latOrLngValue
		) {
			return true;
		}

		if (newValues[`${originalValues.type}Degs`] !== originalValues.deg) {
			return true;
		}

		if (newValues[`${originalValues.type}Mins`] !== originalValues.min) {
			return true;
		}

		if (newValues[`${originalValues.type}Secs`] !== originalValues.sec) {
			return true;
		}

		return false;
	}

	public render(): React.ReactElement {
		const wrapperClasses = arrayToClass([
			this.props.classes ? this.props.classes : 'w-100',
		]);

		const dmsClasses = arrayToClass([
			'display-flex align-items-end',
			this.props?.breakLines ? 'flex-column' : 'flex-column flex-md-row',
		]);

		const lngClasses = arrayToClass([
			'limit-w',
			this.props?.lat ? 'left-m' : '',
		]);

		const decimalClasses = arrayToClass([
			'display-flex align-items-end',
			this.props?.breakLines ? 'flex-column' : 'flex-column flex-md-row',
		]);

		const disabled = this.props?.disabled || false;

		return (
			<div className={wrapperClasses}>
				{this.renderLabel()}

				<div className='display-flex align-items-center my-2'>
					<p className='m-0 palette--c-neutral-6'>Decimal</p>
					<Switch
						field={this.form.getField('dms')}
						classNames='mx-2'
						disabled={disabled}
					/>
					<p className='m-0 palette--c-neutral-6'>DMS</p>
				</div>

				{this.state.isDms && (
					<div className='w-100'>
						{!!this.props.lat && (
							<div className='my-2'>
								<label className='palette--c-neutral-6'>
									Latitude
								</label>
								<div className='display-flex my-2'>
									<p className='m-0 palette--c-neutral-6'>
										North
									</p>
									<Switch
										field={this.form.getField('NorS')}
										classNames='mx-2'
										disabled={disabled}
									/>
									<p className='m-0 palette--c-neutral-6'>
										South
									</p>
								</div>
								<div className={dmsClasses}>
									<Input
										inputType='number'
										field={this.form.getField('latDegs')}
										labelInfos={{ label: 'Degrees' }}
										disabled={disabled}
									/>
									<Input
										inputType='number'
										field={this.form.getField('latMins')}
										classes={
											this.props?.breakLines
												? ''
												: 'left-m'
										}
										labelInfos={{ label: 'Minutes' }}
										disabled={disabled}
									/>
									<Input
										inputType='number'
										field={this.form.getField('latSecs')}
										classes={
											this.props?.breakLines
												? ''
												: 'left-m'
										}
										labelInfos={{ label: 'Seconds' }}
										disabled={disabled}
									/>
								</div>
							</div>
						)}
						{!!this.props.lng && (
							<div className='my-2'>
								<label className='palette--c-neutral-6'>
									Longitude
								</label>
								<div className='display-flex my-2'>
									<p className='m-0 palette--c-neutral-6'>
										East
									</p>
									<Switch
										field={this.form.getField('EorW')}
										classNames='mx-2'
										disabled={disabled}
									/>
									<p className='m-0 palette--c-neutral-6'>
										West
									</p>
								</div>
								<div className={dmsClasses}>
									<Input
										inputType='number'
										field={this.form.getField('lngDegs')}
										labelInfos={{ label: 'Degrees' }}
										disabled={disabled}
									/>
									<Input
										inputType='number'
										field={this.form.getField('lngMins')}
										classes={
											this.props?.breakLines
												? ''
												: 'left-m'
										}
										labelInfos={{ label: 'Minutes' }}
										disabled={disabled}
									/>
									<Input
										inputType='number'
										field={this.form.getField('lngSecs')}
										classes={
											this.props?.breakLines
												? ''
												: 'left-m'
										}
										labelInfos={{ label: 'Seconds' }}
										disabled={disabled}
									/>
								</div>
							</div>
						)}
					</div>
				)}

				{!this.state.isDms && (
					<div className={decimalClasses}>
						{!!this.props.lat && (
							<Input
								inputType='number'
								field={this.props.lat}
								classes='limit-w'
								labelInfos={{ label: 'Latitude' }}
								disabled={disabled}
								formatInput={(value) => round(value, 7)}
							/>
						)}
						{!!this.props.lng && (
							<Input
								inputType='number'
								field={this.props.lng}
								classes={lngClasses}
								labelInfos={{ label: 'Longitude' }}
								disabled={disabled}
								formatInput={(value) => round(value, 7)}
							/>
						)}
					</div>
				)}
			</div>
		);
	}

	public renderLabel() {
		const label = this.props?.labelInfos;
		const labelClasses = arrayToClass([
			'mb-2',
			label?.classes ? label?.classes : '',
			this.props?.field?.mandatory ? 'asterix' : '',
		]);

		if (!label) {
			return null;
		}

		return (
			<Label
				info={label.info || false}
				label={label.label || false}
				className={labelClasses}
				ignoreTopMargin={label.ignoreTopMargin || false}
				isSimple={label.isSimple || false}
			/>
		);
	}
}
