import { get } from 'lodash';
import * as React from 'react';
import { randomId } from '../../../../../../../utilities/helper-fuctions';
import { Form } from '../../../../../../ui-components/form/form';
import { HoverableButton } from '../../../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Input } from '../../../../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../../../../ui-components/inputs/inputs/text-input';
import { SwitchSelect } from '../../../../../../ui-components/inputs/select/switch-select';
import { Modal } from '../../../../../../ui-components/modal/modal';
import { FlightType } from './flight-types';
import { MovementAdditionText } from './movement-addition-text';

export class MovementAdditionModal extends React.Component<any, any> {
	public $flightTypes: { [key: string]: FlightType } = {};
	public modal$: Modal;

	public state = {
		flightTypes: ['default'],
		buttonDisabled: false,
		original: null,
		isPreview: false,
		data: {},
	};

	public form = new Form({
		priorNoticeRequired: '',
		costsInvolved: '',
		other: '',
	});

	public updateForm(data: any = false): void {
		this.form.setValue(
			'priorNoticeRequired',
			data ? data?.priorNoticeRequired : ''
		);
		this.form.setValue('costsInvolved', data ? data?.costsInvolved : '');
		this.form.setValue('other', data ? data?.other : '');

		this.setState({
			flightTypes: data?.flightTypes
				? data?.flightTypes.map((item) => ({
						key: randomId(),
						data: item,
				  }))
				: ['default'],
		});
	}

	public close() {
		this.modal$.close();
	}

	public async open(data = false) {
		if (!!data) {
			this.setState({ original: data, isPreview: false });
			await this.updateForm(get(data, 'data'));
		} else {
			this.setState({ isPreview: false });
			await this.updateForm();
		}

		this.modal$.open();
	}

	public render(): React.ReactElement {
		return (
			<Modal
				onDismissRequest={() => this.close()}
				ref={(ref: any) => (this.modal$ = ref)}
				title={() => this.renderHeader()}
				content={() => this.renderBody()}
				footer={() => this.renderFooter()}
			/>
		);
	}

	public renderHeader() {
		return (
			<div className='p-4 py-2'>
				<h4 className='palette--c-neutral-6 fw-bold m-0'>
					Add exception:
				</h4>
			</div>
		);
	}

	public renderBody() {
		if (this.state.isPreview) {
			return this.renderPreview();
		} else {
			return this.renderForm();
		}
	}

	public renderPreview() {
		const data: any = { data: this.state.data, key: randomId() };
		return <MovementAdditionText key={data.key} data={data} />;
	}

	public renderForm() {
		const validFrom = get(this.props, 'block.validFrom');
		const validTo = get(this.props, 'block.validTo');
		let sentence = `Between ${validFrom} - ${validTo}`;

		if (!validFrom || !validTo) {
			sentence = 'You have to add a block with defined from and to times';
		}

		return (
			<div className='p-4'>
				<h5 className='palette--c-neutral-5 fw-bold flex-fill'>
					{sentence}
				</h5>

				<h5 className='palette--c-neutral-6 fw-bold flex-fill'>
					Flight Types:
				</h5>
				{this.renderFlightTypes()}
				<HoverableButton
					onClick={() => this.handleAddNew()}
					className='border-radius-1 mt-2'
					colorType='avio-green'
					title='Add new flight type'
				/>
				<div className='row display-flex align-items-end mt-4'>
					<div className='col-24 col-sm-7 display-flex align-items-end'>
						<Input
							inputType='number'
							field={this.form.getField('priorNoticeRequired')}
							classes='py-2'
							numeralPositiveOnly={true}
							labelInfos={{ label: 'Prior notice required' }}
						/>
						<p className='palette--c-neutral-4 ml-2'>hours</p>
					</div>

					<SwitchSelect
						classes='col-24 col-sm-7 py-2'
						field={this.form.getField('costsInvolved')}
						labelInfos={{ label: 'Costs involved' }}
					/>

					<TextInput
						field={this.form.getField('other')}
						classes='col-24 col-sm-7 py-2'
						type='textarea'
						rows={3}
						textType='sentences'
						labelInfos={{ label: 'Other' }}
					/>
				</div>
			</div>
		);
	}

	public renderFlightTypes() {
		return get(this.state, 'flightTypes').map((flightType: any) => {
			const key = flightType.key || flightType;

			return (
				<FlightType
					key={key}
					ref={(ref: any) => (this.$flightTypes[key] = ref)}
					data={flightType.data || null}
					delete={() => this.deleteFlightType(key)}
				/>
			);
		});
	}

	public renderFooter(): React.ReactElement {
		return (
			<div className='w-100'>
				<div className='display-flex align-items-center justify-content-end p-4 py-2'>
					<HoverableButton
						id={
							get(this.state, 'buttonDisabled')
								? 'MovementAddsLast'
								: ''
						}
						className='border-radius-1 mr-2'
						colorType='cancel'
						title='Cancel'
						onClick={() => this.close()}
					/>

					{this.state.isPreview && (
						<HoverableButton
							className='border-radius-1 mr-2'
							colorType='cancel'
							title='Back'
							onClick={() => this.handleBack()}
						/>
					)}

					<HoverableButton
						id='MovementAddsLast'
						disabled={get(this.state, 'buttonDisabled')}
						className='border-radius-1'
						colorType={
							get(this.state, 'buttonDisabled')
								? 'disabled'
								: 'avio-green'
						}
						title={this.state.isPreview ? 'Save' : 'Preview'}
						onClick={() => this.onButtonClick()}
					/>
				</div>
			</div>
		);
	}

	public deleteFlightType(key) {
		delete this.$flightTypes[key];
		this.setState({
			flightTypes: this.state.flightTypes.filter((id) => id !== key),
		});
	}

	public handleAddNew() {
		this.setState({ flightTypes: [...this.state.flightTypes, randomId()] });
	}

	public handleBack() {
		const data = this.state.data;
		this.updateForm(data);
		this.setState({ isPreview: false, data: {} });
	}

	public getFlightTypes() {
		return Object.keys(this.$flightTypes)
			.map(
				(key) =>
					this.$flightTypes[key] && this.$flightTypes[key].getForm()
			)
			.filter((item) => !!item);
	}

	public getForm(): any {
		const form: any = this.form.generateJSON();
		const flightTypes = this.getFlightTypes();

		form.flightTypes = flightTypes;
		form.priorNoticeRequired = form.priorNoticeRequired
			? parseInt(form.priorNoticeRequired, 10)
			: null;

		return form;
	}

	public onButtonClick() {
		if (this.state.isPreview) {
			this.handleSave();
		} else {
			this.setState({ isPreview: true, data: this.getForm() });
		}
	}

	public handleSave() {
		const form = this.state.data;

		if (this.props.save) {
			const otherInfos = this.state.original
				? { data: form, key: get(this.state, 'original.key') }
				: form;

			this.props.save(otherInfos);
		}
	}

	public clearForm() {
		this.setState({
			flightTypes: ['default'],
			original: null,
			isPreview: false,
			data: null,
		});
		this.$flightTypes = {};
		this.form.clearForm();
	}
}
