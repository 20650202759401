import { differenceInHours, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { clone } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../../actions/details.actions';
import { LocalstorageActions } from '../../../../actions/localstorage.actions';
import { connectNLP } from '../../../../utilities/connect-navigate';
import {
	arrayToClass,
	debounce,
	isInQuery,
	toUtc,
} from '../../../../utilities/helper-fuctions';
import { AvailabilityLines } from '../../../other/availability-lines';
import { ContributeButton } from '../../../other/contribute-button';
import { Form } from '../../../ui-components/form/form';
import { DateTimePicker } from '../../../ui-components/inputs/date-picker/date-time-picker';
import { Tooltip } from '../../../ui-components/popover/popover';
import { Widget } from '../../widget';
import { Background } from './background';
import './opening-hours.scss';

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
	fetchNewSun: (aid, date) =>
		dispatch(LocalstorageActions.fetchNewSun(aid, date)),
	setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

class OpeningHoursComponent extends Widget<any, any> {
	public state: any = {
		...this.state,
		isPopover: false,
		date: new Date(),
		isMovement: false,
	};

	public form = new Form(
		{
			date: new Date(),
		},
		() => debounce(() => this.handleFormChanges())()
	);

	public options: any = [
		{
			title: 'Movements',
			state: 'movement',
		},
		{
			title: 'customs',
			state: 'ciq',
		},
		{
			title: 'firefighting',
			state: 'arff',
		},
		{
			title: 'air traffic control',
			state: 'atc',
		},
	];

	public handleFormChanges() {
		const diffs = this.form.getDifferences();

		if (diffs.includes('date')) {
			this.fetch();
		}

		if (this.props.fetchNewSun) {
			this.props.fetchNewSun(
				this.props?.airport?.aid,
				this.form.getValue('date')
			);
		}
	}

	public getEndpoint(): any {
		return `/airports/${this.props?.airport?.aid}/availability`;
	}

	public getQuery(): any {
		const localDate = startOfDay(this.form.getValue('date'));
		const utcDate = toUtc(localDate);

		return {
			dateTime: utcDate,
			local: true,
		};
	}

	public renderContent(): any {
		const { response } = this.state;

		if (!response) {
			return this.renderEmptyProperty('Availability');
		}

		return (
			<div
				className='OpeningHoursWidget position-relative'
				onMouseOver={() => this.renderMarker()}
			>
				<Background
					classNames='Gradient'
					date={this.form.getValue('date')}
					aid={this.props?.airport?.aid}
				/>
				<div className='Content position-relative p-4'>
					<div className='display-flex align-items-center'>
						{this.renderDatePicker()}
						<div className='display-flex justify-content-center align-items-center ml-2'>
							<p className='palette--c-neutral-2 mb-0'>
								All times are local
							</p>
						</div>
					</div>
					{this.renderTimeline()}
				</div>
			</div>
		);
	}

	public renderDatePicker(): React.ReactElement {
		return (
			<form>
				<DateTimePicker
					field={this.form.getField('date')}
					inputClassName='palette--c-neutral-5 p-0'
				/>
			</form>
		);
	}

	public renderTimeline(): React.ReactElement {
		return (
			<div className='Timeline w-100 position-relative'>
				{this.renderLines()}
				{this.renderFooterLine()}
				{this.renderMarker()}
			</div>
		);
	}

	public renderLines(): React.ReactElement {
		return (
			<div className='py-2'>
				{this.options.map((option: any, index: number) => {
					return (
						<div className='my-2' key={index}>
							<Tooltip
								isOpen={
									this.state.isMovement &&
									option.state === 'movement'
								}
								tooltip={this.renderPopover()}
								arrowColor='#fefeff'
								trigger={
									<h6
										onMouseEnter={() =>
											this.setState({ isMovement: true })
										}
										onMouseLeave={() =>
											this.setState({ isMovement: false })
										}
										className='AvailabilityTitle palette--c-neutral-2 text-uppercase m-0 display-inline'
									>
										{option.title}
									</h6>
								}
							/>
							<div className='display-flex'>
								{this.colorizeLineBoxes(option)}
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	public renderPopover(): any {
		return (
			<div className='PopoverContent border-radius-1 px-2 py-1 palette--bgc-neutral-1'>
				<span className='palette--c-neutral-5'>
					Indicates the availability for take-off and landing without
					any further guarantee of service.
				</span>
			</div>
		);
	}

	public colorizeLineBoxes(option: any): any {
		const state = this.state?.response?.[option.state];

		if (!state || !state[0]) {
			return this.renderEmptyProperty(`${option.title} availability`);
		}
       console.log({state});
       

		return <AvailabilityLines states={clone(state)} type={option.state} />;
	}

	public renderEmptyProperty(label): React.ReactElement {
		return <ContributeButton openModal={() => this.open()} label={label} />;
	}

	public open(): void {
		const utmSource = isInQuery('utm_source') || '';
		this.props?.router?.navigate({
			pathname: `/airports/${this.props?.aid}/availability`,
			search: utmSource,
		});
	}

	public renderFooterLine(): React.ReactElement {
		return (
			<div className='FooterLine w-100 display-flex mb-5'>
				<div className='MainBox border-top-1 border-left-1 border-right-1 palette--bc-neutral-2 display-flex'>
					{this.renderBoxes()}
				</div>
				{['04:00', '08:00', '12:00', '16:00', '20:00'].map(
					(item: string) => {
						return (
							<div
								key={item}
								className='MainBox border-top-1 border-right-1 palette--bc-neutral-2 display-flex position-relative'
							>
								<span className='HourIndex palette--c-neutral-4'>
									{item}
								</span>
								{this.renderBoxes()}
							</div>
						);
					}
				)}
			</div>
		);
	}

	public renderBoxes(): React.ReactElement {
		return this.options.map((opt: any, index: number) => (
			<div
				key={index + opt}
				className='Box border-right-1 palette--bc-neutral-2'
			/>
		));
	}

	public renderMarker(): React.ReactElement {
		const timeMarkerClasses = arrayToClass([
			'MarkerLine display-flex flex-column align-items-center position-absolute',
		]);

		const timeZone = this.props?.airport?.timeZone || 'UTC';
		const t = utcToZonedTime(new Date(), timeZone);
		const tInHour = differenceInHours(t, startOfDay(t), {
			roundingMethod: 'round',
		});
		const time = tInHour * (100 / 24);

		return (
			<div className={timeMarkerClasses} style={{ left: `${time}%` }}>
				<div className='Marker palette--bgc-neutral-1 h-100 m-0' />
				<span className='Arrow material-icons palette--c-neutral-1'>
					arrow_drop_up
				</span>
			</div>
		);
	}
}

export const OpeningHours: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(connectNLP(OpeningHoursComponent));
