import { get } from 'lodash';
import * as React from 'react';
import { SubTab } from '../../../../../components/ui-components/tab/sub-tab';
import { connectNLP } from '../../../../../utilities/connect-navigate';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { AuthorityContributions } from './authority.contributions';

const tabData = [
	{
		title: 'Airport authorities',
		type: 'airportauthorities',
		types: ['airportAuthority', 'airportAuthorityContact'],
	},
	{
		title: 'Country authorities',
		type: 'countryauthorities',
		types: ['countryAuthority', 'countryAuthorityContact'],
	},
];

class AuthoritiesContributionsComponent extends React.Component<any, any> {
	public state = {
		airportauthorities: this.getCounts('airport'),
		countryauthorities: this.getCounts('country'),
	};

	public getCounts(type) {
		const keys = Object.keys(this.props.total).filter((key) =>
			key.includes(type)
		);
		let counter = 0;

		for (let key of keys) {
			counter = this.props?.total?.[key] + counter;
		}

		return counter;
	}

	public render(): React.ReactElement {
		const type = get(this.props, 'subTypes');
		const mainType = get(type, 'type', false);
		const subType = get(type, 'authorities', false);

		const tabs = tabData.map((tab) => {
			return {
				...tab,
				total: get(this.state, tab.type),
			};
		});

		const classes = arrayToClass([
			'w-100',
			this.props.show ? 'SubContainer' : '',
		]);

		return (
			<div className={classes}>
				{this.props.show && (
					<SubTab
						items={tabs}
						onItemClick={(item) => this.handleClick(item)}
						selected={mainType}
					/>
				)}

				<AuthorityContributions
					isAdmin={this.props.isAdmin}
					system={this.props.system}
					isAirport={true}
					show={
						this.props.show &&
						(mainType === 'airportauthorities' || !mainType)
					}
					subType={subType}
					onTotalChange={(total) =>
						this.handleChange('airport', total)
					}
					total={{
						authority: this.props?.total?.airportAuthority,
						contact: this.props?.total?.airportAuthorityContact,
					}}
				/>
				<AuthorityContributions
					isAdmin={this.props.isAdmin}
					system={this.props.system}
					show={this.props.show && mainType === 'countryauthorities'}
					subType={subType}
					onTotalChange={(total) =>
						this.handleChange('country', total)
					}
					total={{
						authority: this.props?.total?.countryAuthority,
						contact: this.props?.total?.countryAuthorityContact,
					}}
				/>
			</div>
		);
	}

	public handleClick(item): any {
		if (this.props.isAdmin || this.props.system) {
			this.props?.router?.navigate(
				`/admin/${
					this.props.system ? 'system-' : ''
				}contributions/authorities/${item.type}`
			);
		} else {
			this.props?.router?.navigate(
				`/contributions/authorities/${item.type}`
			);
		}
	}

	public handleChange(type, total) {
		const airportauthorities =
			type === 'airport' ? total : this.state.airportauthorities;
		const countryauthorities =
			type === 'country' ? total : this.state.countryauthorities;

		const newTotal = airportauthorities + countryauthorities;

		this.setState({
			[`${type}authorities`]: total,
		});

		this.props?.onTotalChange(newTotal);
	}
}

export const AuthoritiesContributions: any = connectNLP(
	AuthoritiesContributionsComponent
);
