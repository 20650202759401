export abstract class SettingsActions {
    public static readonly SET_LENGTH = 'SettingsActions[SET_LENGTH]';
    public static readonly SET_MASS = 'SettingsActions[SET_MASS]';
    public static readonly SET_TEMPERATURE = 'SettingsActions[SET_TEMPERATURE]';
    public static readonly SET_WIDGET_SETTINGS = 'SettingsActions[SET_WIDGET_SETTINGS]';
    public static readonly SET_FORMAT = 'SettingsActions[SET_FORMAT]';
    public static readonly SET_NEARBY_AIRPORT_FILTERS = 'SettingsActions[SET_NEARBY_AIRPORT_FILTERS]';
    public static readonly SET_LAST_SEARCHED = 'SettingsActions[SET_LAST_SEARCHED]';
    public static readonly SET_MAP_NEW_TAB = 'SettingsActions[SET_MAP_NEW_TAB]';

    public static setMeasures(metric: boolean) {
        return async (dispatch: (action: any) => void) => {
            dispatch(SettingsActions.setLength({ targetDimension: metric ? 'm' : 'ft' }));
            dispatch(SettingsActions.setMass({ targetDimension: metric ? 'kg' : 'lb' }));
            dispatch(SettingsActions.setTemperature({ targetDimension: metric ? 'C' : 'F' }));
        };
    }

    public static setLength(measures: any) {
        return {
            type: SettingsActions.SET_LENGTH,
            payload: measures,
        };
    }

    public static setMapNewTab(newTab: any) {
        return {
            type: SettingsActions.SET_MAP_NEW_TAB,
            payload: newTab,
        };
    }

    public static setMass(measures: any) {
        return {
            type: SettingsActions.SET_MASS,
            payload: measures,
        };
    }

    public static setTemperature(measures: any) {
        return {
            type: SettingsActions.SET_TEMPERATURE,
            payload: measures,
        };
    }

    public static setNearbyAirportFilters(measures: any) {
        return {
            type: SettingsActions.SET_NEARBY_AIRPORT_FILTERS,
            payload: measures,
        };
    }

    public static setFormat(measures: any) {
        return {
            type: SettingsActions.SET_FORMAT,
            payload: measures,
        };
    }

    public static setLastSearched(aids: any) {
        return {
            type: SettingsActions.SET_LAST_SEARCHED,
            payload: aids,
        };
    }

    public static saveWidgetSettings(settings: any) {
        return {
            type: SettingsActions.SET_WIDGET_SETTINGS,
            payload: settings,
        };
    }
}
