import { get } from 'lodash';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { arrayToClass } from '../../../utilities/helper-fuctions';
import './admin-menu.scss';

export const AdminMenu = (props) => {
    const navigate = useNavigate();

    const renderOptions = (title) => {
        const id = get(props, 'id');

        const classes = arrayToClass([
            'Option text-uppercase pointer m-0 px-3 py-1',
            `palette--c-neutral-${id === title ? '6' : '5'}`,
        ]);

        return (
            <h5 key={title} className={classes} onClick={() => handleClick(title)}>
                {title.replace('-', ' ')}
            </h5>
        );
    };

    const handleClick = (title) => {
        navigate(`/admin/${title}`);
    };

    return (
        <div className="AdminSidebar w-100 my-2">
            {['contributions', 'system-contributions', 'users', 'tokens', 'regions', 'webhooks', 'plausible'].map(
                (item: string) => renderOptions(item)
            )}
        </div>
    );
};
