import * as React from 'react';
import { formatOptions } from '../../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../../ui-components/inputs/select/select';
import { TextInput } from '../../../../../ui-components/inputs/inputs/text-input';
import { ValidationInput } from '../../../../../ui-components/inputs/validation-input/validation-input';

export const typeOpts = [
    { title: 'Address', value: 'ADDRESS' },
    { title: 'Aftn', value: 'AFTN' },
    { title: 'Email', value: 'EMAIL' },
    { title: 'Fax', value: 'FAX' },
    { title: 'Phone', value: 'PHONE' },
    { title: 'Sita', value: 'SITA' },
    { title: 'Website', value: 'WEBSITE' },
];

export const placeholders: any = {
    phone: '+1 234 567 8900',
    fax: '+1 234 567 8900',
    aftn: 'EUCHZMTA',
    sita: 'BRUEA7X',
    email: 'user@example.com',
    address: 'address',
    website: 'https://example.com',
};

export const authorityContactsFields = (elmnt) => [
    {
        type: 'name',
        title: 'Name',
        component: <TextInput field={elmnt.form.getField('name')} classes="NewData" textType="camel" />,
    },
    {
        type: 'type',
        title: 'Type',
        component: (
            <Select field={elmnt.form.getField('type')} classes="NewData" options={formatOptions(typeOpts, false)} />
        ),
    },
    {
        type: 'details',
        title: 'Details',
        component: (
            <ValidationInput classes="NewData py-2" type={elmnt.state.type} field={elmnt.form.getField('details')} />
        ),
    },
];
