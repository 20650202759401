import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { ToastMessages } from '../../../../components/notifications/toast-messages';
import { WidgetModal } from '../../../../components/widgets/widget-modal';
import { redirectToLogin } from '../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../utilities/request';

export class ContributionModal extends WidgetModal<any, any> {
	public state = {
		...this.state,
		error: false,
		response: false,
		loading: false,
	};

	public async componentDidMount(): Promise<void> {
		super.componentDidMount();

		if (
			this.props?.item?.contribution?.action !== 'CREATE' &&
			!!this.props?.item
		) {
			await this.fetchOriginal();
		}
	}

	public componentDidUpdate(prevProps) {
		if (this.props.item !== prevProps.item) {
			this.forceUpdate();
		}
	}

	public async fetchOriginal() {
		try {
			this.setState({ loading: true });

			const response = await RequestManager.get(this.props.endpoint);

			if (!response) {
				throw new Error('There was an error');
			}

			this.setState({ loading: false, response: response });
		} catch (err) {
			this.setState({
				loading: false,
				error:
					get(err, 'response.data.message', null) || err || 'Error',
			});
			toast.error(<ToastMessages error={err} />, { theme: 'light' });

			redirectToLogin(err);
		}
	}

	public onClose() {
		this.props?.onClose();
	}

	public getTitle(): any {
		return true;
	}

	public renderHeader() {
		return this.props?.renderHeader();
	}

	public renderContent() {
		const isCreate =
			get(this.props.item, 'contribution.action') === 'CREATE';
		const isLoading =
			!this.state.response &&
			!this.state.error &&
			!isCreate &&
			!this.state.response;

		if (this.state.loading || isLoading) {
			return this.renderLoading();
		}

		if (this.state.error) {
			return this.renderError();
		}

		return (
			<div className='WidgetModal w-100'>
				{this.props?.renderBody(this)}
			</div>
		);
	}

	public renderFooter() {
		return;
	}

	public renderEmpty(): React.ReactElement {
		return (
			<div className='display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2'>
				<p className='palette--c-neutral-5 m-0'>Nothing changed.</p>
			</div>
		);
	}
}
