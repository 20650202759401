import $ from 'jquery';
import { isEqual } from 'lodash';
import * as React from 'react';
import {
	arrayToClass,
	debounce,
	randomId,
} from '../../../../utilities/helper-fuctions';
import { Dropdown } from '../../dropdown/dropdown';
import { Spinner } from '../../spinner/spinner';
import { Label } from '../label/label';
import './select.scss';

export class Select extends React.Component<any, any> {
	public dropdown$;
	public input$;
	public mainId = randomId();
	public inputId = randomId();
	public optionId = randomId();

	public state = {
		value: this.getValues() || '',
		values: this.getValues(true),
		options: this.props.options || [],
		currentIndex: 0,
		isOpen: false,

		loading: false,
		error: false,
	};

	public getValues(isValues = false) {
		const original = this.props?.field?.newValue;
		const options = this.props?.options;

		if (isValues) {
			if (this.props.isMulti) {
				const newValues = options.filter((option) =>
					(original || []).find(
						(value) => !!isEqual(value, option?.value)
					)
				);

				return newValues || '';
			} else {
				return '';
			}
		} else {
			if (this.props.isMulti) {
				return [];
			} else {
				const newValue = options.find((option) =>
					isEqual(option?.value, original)
				);

				return newValue;
			}
		}
	}

	public componentDidUpdate(prevProps, prevState) {
		if (!isEqual(this.props.options, prevProps.options)) {
			if (this.props?.searchable) {
				this.setState({ options: this.props.options });
			} else {
				this.setState({ options: this.props.options });
			}
		}

		if (
			!isEqual(
				this.getValues(this.props?.isMulti),
				this.props?.isMulti ? this.state?.values : this.state?.value
			) &&
			!this.state?.isOpen
		) {
			this.setState({
				options: this.props.options,
				value: this.getValues(),
				values: this.getValues(true),
			});
		}

		if (this.props.loading !== prevProps.loading) {
			this.setState({ loading: this.props.loading });
		}

		if (this.props.error !== prevProps.error) {
			this.setState({ error: this.props.error });
		}

		if (prevState.isOpen !== this.state.isOpen) {
			if (!!this.state.isOpen) {
				$(window).on('keydown', (event) => this.handleKeys(event));
				$(window).on('mousedown', (event) =>
					this.handleOutsideClick(event)
				);
			} else {
				$(window).off('keydown');
				$(window).off('mousedown');
			}
		}
	}

	public handleKeys(event) {
		if (document.activeElement?.id === this.inputId) {
			const current = this.state.currentIndex;
			const scrollTop: any = $(`#${this.optionId}`).scrollTop();
			const elementHeight: any = $(
				`#Option-${this.optionId}-${current}`
			).outerHeight(true);
			const optionsLength = (this.state?.options || []).length - 1;

			if (event.keyCode === 27) {
				this.handleTrigger(false);
			} else if (event.keyCode === 13) {
				this.handleSelect(current);
			} else if (event.keyCode === 38 && current > 0) {
				this.setState({ currentIndex: current - 1 });

				if (current < optionsLength - 5) {
					const newScrollTop = scrollTop - elementHeight;
					$(`#${this.optionId}`).animate(
						{ scrollTop: newScrollTop },
						100
					);
				}
			} else if (event.keyCode === 40 && current < optionsLength) {
				this.setState({ currentIndex: current + 1 });

				if (current > 5) {
					const newScrollTop = scrollTop + elementHeight;
					$(`#${this.optionId}`).animate(
						{ scrollTop: newScrollTop },
						100
					);
				}
			} else if (event.keyCode === 9) {
				this.handleTrigger(false);
			}
		}
	}

	public handleOutsideClick(event) {
		const id = event.target?.id;

		if (id === this.mainId) {
			return;
		} else {
			const parents = $(event.target).parents();
			let isChild = false;
			let index = 0;

			while (index <= parents.length - 1 && !isChild) {
				if (
					parents[index]?.id === this.mainId ||
					parents[index]?.id.includes(this.optionId)
				) {
					isChild = true;
				}

				index++;
			}

			if (!isChild) {
				this.handleTrigger(false);
			}
		}
	}

	public render() {
		const wrapperClasses = arrayToClass([
			'SelectWrapper w-100',
			this.props.classes ? this.props.classes : '',
		]);

		return (
			<div className={wrapperClasses}>
				{this.renderLabel()}
				<Dropdown
					id={this.mainId}
					ref={(ref) => (this.dropdown$ = ref)}
					ignoreMinWidth={true}
					ignoreMaxWidth={true}
					ignoreBackdrop={true}
					ignoreTriggerClick={true}
					className='w-100'
					trigger={() => this.renderTriggerComponent()}
					dropdown={() => this.renderOptions()}
				/>
			</div>
		);
	}

	public renderLabel() {
		const label = this.props?.labelInfos;
		const labelClasses = arrayToClass([
			'mb-2',
			label?.classes ? label?.classes : '',
			this.props?.field?.mandatory ? 'asterix' : '',
		]);

		if (!label) {
			return null;
		}

		return (
			<Label
				info={label.info || false}
				label={label.label || false}
				className={labelClasses}
				ignoreTopMargin={label.ignoreTopMargin || false}
				isSimple={label.isSimple || false}
			/>
		);
	}

	public renderTriggerComponent() {
		const isOpen = this.state?.isOpen;
		const isDisabled = this.props?.disabled;
		const isValues = (this.state?.values || []).length;

		const value = this.state.value?.['title'] || this.state?.value || '';

		const wrapper = arrayToClass([
			'SelectTriggerWrapper',
			'display-flex border-1 border-radius-1 w-100',
			isDisabled
				? 'palette--bc-neutral-2 palette--bgc-neutral-2'
				: 'palette--bc-neutral-4 palette--bgc-neutral-1',
		]);
		const inputClasses = arrayToClass([
			isOpen || !this.props.isMulti || !isValues
				? 'inputOpen'
				: 'inputClosed',
			'w-100',
		]);

		return (
			<div className={wrapper}>
				<div className='display-flex flex-wrap overflow-auto flex-fill'>
					{this.props.isMulti && this.renderValues()}
					<input
						disabled={isDisabled || false}
						id={this.inputId}
						ref={(ref) => (this.input$ = ref)}
						value={value}
						onFocus={() => this.handleTrigger(true)}
						onChange={(event) => this.handleChange(event)}
						className={inputClasses}
					/>
				</div>
				<div
					className='Arrow display-flex align-items-center'
					onClick={() => !isDisabled && this.handleTrigger(!isOpen)}
				>
					<span className='material-icons palette--c-neutral-5'>
						{isOpen
							? 'keyboard_arrow_down'
							: 'keyboard_arrow_right'}
					</span>
				</div>
			</div>
		);
	}

	public renderValues() {
		const values = this.state?.values || [];
		const isOpen = this.state?.isOpen;
		const isDisabled = this.props?.disabled;

		const closeClasse = arrayToClass([
			`material-icons palette--c-neutral-1`,
			this.props?.disabled ? '' : 'pointer',
		]);

		return (
			<div
				className='display-flex flex-wrap flex-fill'
				onClick={() => !isDisabled && this.handleTrigger(!isOpen)}
			>
				{values.map((option, index) => (
					<div
						key={randomId()}
						className='SelectedMultiOptions display-flex align-items-center palette--bgc-neutral-5'
					>
						<span className='palette--c-neutral-1 m-0 mr-1'>
							{option?.title}
						</span>
						<span
							className={closeClasse}
							onMouseDown={() =>
								!this.props?.disabled &&
								this.handleDisselect(index)
							}
						>
							close
						</span>
					</div>
				))}
			</div>
		);
	}

	public renderOptions() {
		const values = this.props?.isMulti
			? this.state?.values
			: this.state?.value;
		const options = this.state?.options || [];
		const isOptions =
			!this.state.loading && !this.state.error && !!options.length;
		const isError = !this.state.loading && !!this.state.error && !isOptions;
		const isLoading =
			!!this.state.loading && !this.state.error && !isOptions;
		const currentIndex = this.state?.currentIndex;

		return (
			<div
				className='SelectOptions palette--bgc-neutral-1 border-radius-1 border-1 palette--bc-neutral-4 elevation-1 overflow-auto'
				id={this.optionId}
			>
				{!!isLoading && (
					<div className='display-flex align-items-center justify-content-center py-6'>
						<Spinner size='small' />
					</div>
				)}
				{(!isOptions || !!isError) && (
					<div className='display-flex align-items-center justify-content-center py-6'>
						<p className='palette--c-neutral-5 m-0'>No results</p>
					</div>
				)}
				{isOptions &&
					options.map((option, index) => {
						const isSelected = Array.isArray(values)
							? values.find(
									(value) =>
										isEqual(option?.value, value) ||
										isEqual(value?.value, option.value)
							  )
							: isEqual(values?.value, option?.value) ||
							  isEqual(values, option?.value);

						const optionClasses = arrayToClass([
							isSelected
								? 'selected'
								: currentIndex === index
								? 'hovered'
								: 'normal',
							'p-2',
						]);

						return (
							<div
								key={randomId()}
								id={`Option-${this.optionId}-${index}`}
								onClick={() => this.handleSelect(index)}
								onMouseEnter={() =>
									this.setState({ currentIndex: index })
								}
								className={optionClasses}
							>
								<p className='palette--c-neutral-5 m-0 mr-1'>
									{option.title}
								</p>
							</div>
						);
					})}
			</div>
		);
	}

	public handleTrigger(isOpen) {
		if (!isOpen && !!this.state.isOpen) {
			debounce(() => {
				this.dropdown$.close();
			}, 100)();
		} else if (!!isOpen && !this.state.isOpen) {
			this.dropdown$.open();
			this.input$.focus();
		}

		this.setState({ isOpen: isOpen });
	}

	public handleChange(event) {
		if (this.props.searchable) {
			this.props.onChange(event.target.value);
			this.setState({ value: event.target.value });
		} else {
			const options = this.props?.options || [];
			const newValue = event.target?.value;

			const newOptions = options.filter((option) => {
				const title = option?.title?.toLowerCase();
				const valueToLower = (newValue || '').toLowerCase();

				return title.startsWith(valueToLower);
			});

			this.setState({
				value: event.target.value,
				options: newOptions,
				currentIndex: 0,
			});
		}
	}

	public handleDisselect(index) {
		const currentValues = this.state?.values || [];
		currentValues.splice(index, 1);

		this.setState({ values: currentValues, options: this.props?.options });
		this.saveToOriginal();
	}

	public handleSelect(index) {
		const option = (this.state?.options || [])[index];

		if (!!this.props.isMulti) {
			const selectedValue = this.state?.values.find(
				(value) =>
					isEqual(value, option) || isEqual(value, option?.value)
			);

			if (!selectedValue) {
				this.setState(
					{
						values: [...this.state.values, option],
						value: '',
						options: this.props?.options,
					},
					() => this.saveToOriginal()
				);
			} else {
				const indexOf = this.state?.values.indexOf(selectedValue);
				this.handleDisselect(indexOf);
			}
		} else {
			this.setState({ value: option, options: this.props?.options }, () =>
				this.saveToOriginal()
			);
		}
	}

	public saveToOriginal() {
		const value = this.props.isMulti
			? (this.state?.values || []).map((element) => element?.value)
			: this.state?.value?.value;

		const original = this.props?.field?.newValue;

		if (isEqual(original, value)) {
			return;
		} else if (value === undefined) {
			this.setState({ value: '', options: this.props?.options });
		} else {
			this.props?.field?.setValue(value);
		}

		if (!this.props?.isMulti) {
			this.handleTrigger(false);
		}
	}
}
