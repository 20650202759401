import { Modal } from '../ui-components/modal/modal';
import * as React from 'react';
import { logoLink } from '../../utilities/variables';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';

export class WhyAvioModal extends React.Component<any, any> {
    public modal$: Modal;

    public open() {
        this.modal$.open();
    }

    public close() {
        this.modal$.close();
    }

    public render() {
        return (
            <Modal
                ref={(ref: any) => (this.modal$ = ref)}
                title={() => this.renderHeader()}
                content={() => this.renderContent()}
                footer={() => this.renderFooter()}
                onDismissRequest={() => this.close()}
            />
        );
    }

    public renderHeader() {
        return (
            <div className="NotificationModalHeader w-100 p-4 display-flex">
                <img src={logoLink} alt="aviowiki" className="AvioLogo display-lg-flex" />
            </div>
        );
    }

    public renderContent() {
        return (
            <div className="w-100 px-4 p-6 display-flex flex-column justify-content-center">
                <div className="display-flex align-items-center justify-content-center row">
                    <div className="WhyAvioImage">
                        <img src={require('../../assets/account.png')} alt="account" />
                    </div>
                    <div className="col-24 col-sm-18 col-md-14">
                        <h4 className="palette--c-neutral-6">Why do I need an account?</h4>
                        <p className="palette--c-neutral-5">
                            This helps us verify our data and data sources, and ensures we are always providing
                            accurate, high quality information.
                        </p>
                    </div>
                </div>

                <div className="display-flex align-items-center justify-content-center row py-6">
                    <div className="WhyAvioImage">
                        <img src={require('../../assets/contribute.png')} alt="contribution" />
                    </div>
                    <div className="col-24 col-sm-18 col-md-14">
                        <h4 className="palette--c-neutral-6">What happens when I contribute?</h4>
                        <p className="palette--c-neutral-5">
                            Contributions aren't published straight away. All information will be sent to our friendly
                            data team for verification and we may even contact you for further details.
                        </p>
                    </div>
                </div>

                <div className="display-flex align-items-center justify-content-center row">
                    <h4 className="palette--c-neutral-6">So don't be shy, share your info with us today!</h4>
                </div>
            </div>
        );
    }

    public renderFooter() {
        return (
            <div className="w-100 display-flex justify-content-end p-4">
                <HoverableButton colorType="cancel" className="mr-2" onClick={() => this.close()} title="Close" />
            </div>
        );
    }
}
