import L from 'leaflet';
import { get } from 'lodash';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { FeatureGroup, Marker, Polygon } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { arrayToClass, randomId } from '../../../utilities/helper-fuctions';
import { PreLoader } from '../../../utilities/pre-loader';
import { FormButtons } from '../../ui-components/hoverable-buttons/form-modal-buttons/form-buttons';
import { WidgetModal } from '../../widgets/widget-modal';
import { Map } from '../map';
import './map-drawing.scss';

const markerOptions = {
    shapeOptions: {
        color: '#800360',
        weight: 5,
        opacity: 1,
        fillOpacity: 0.3,
    },
};

const iconOptions = (marker) => ({
    icon: marker,
});

export class MapDrawingComponent extends WidgetModal<any, any> {
    public map$;
    public editableFG;

    public state = {
        disableToolButton: this.getDisableValue(),
        disableSaveButton: true,
        latlngs: this.props.isMarker ? this.props?.marker : this.props?.polygon || false,
        polygons: false,
        defaultCoordinates: this.props?.response?.coordinates,
        changed: false,
    };

    public getDisableValue() {
        if (!!this.props.isShowOnly) {
            return true;
        }

        if (this.props.isMulti) {
            return false;
        } else {
            return !!this.props?.field?.newValue;
        }
    }

    public getTitle() {
        return this.props.title || 'MAP';
    }

    public getIcon(isOriginal = false): any {
        const classes = arrayToClass(['material-icons', `Dropoff${isOriginal ? '-original' : ''}`]);

        return L.divIcon({
            html: ReactDOMServer.renderToString(<span className={classes}>hail</span>),
            className: 'leaflet-div-maps-marker nearby-helpers',
            iconAnchor: [20, 25],
        });
    }

    public getViewport(): any {
        if (!!this.props.isMulti) {
            if (!!this.props.polygons) {
                const coords = this.props?.polygons;
                const allCoordinates = coords.reduce((acc, arr) => (acc = [...acc, ...arr]), []);
                return { bounds: this.generateBoundaries(allCoordinates) };
            } else {
                return { center: [0, 0], zoom: 2 };
            }
        } else {
            if (!!this.props.isMarker) {
                const coords: any = this.state?.latlngs || this.props?.marker;

                if (!!coords) {
                    const center = [coords?.latitude, coords?.longitude];
                    return { center: center, zoom: 14 };
                } else {
                    return this.generateDefaultCoords();
                }
            } else {
                const coords: any = this.state?.latlngs || this.props?.polygon;

                if (!!coords) {
                    return { bounds: this.generateBoundaries(coords) };
                } else {
                    return this.generateDefaultCoords();
                }
            }
        }
    }

    public generateDefaultCoords() {
        let viewport = { center: [0, 0], zoom: 2 };

        if (this.state?.defaultCoordinates) {
            const coords = this.state?.defaultCoordinates;
            const center = [coords?.latitude, coords?.longitude];

            viewport = { center: center, zoom: 14 };
        }

        return viewport;
    }

    public generateBoundaries(list) {
        const minMaxCoords = list.reduce(
            (acc, arr) => {
                if (acc.minLat > get(arr, 'latitude') || !acc.minLat) {
                    acc.minLat = get(arr, 'latitude');
                }

                if (acc.maxLat < get(arr, 'latitude') || !acc.maxLat) {
                    acc.maxLat = get(arr, 'latitude');
                }

                if (acc.minLng > get(arr, 'longitude') || !acc.minLng) {
                    acc.minLng = get(arr, 'longitude');
                }

                if (acc.maxLng < get(arr, 'longitude') || !acc.maxLng) {
                    acc.maxLng = get(arr, 'longitude');
                }

                return acc;
            },
            { minLat: null, maxLat: null, minLng: null, maxLng: null }
        );

        return [
            [minMaxCoords.minLat - 0.2, minMaxCoords.minLng - 0.2],
            [minMaxCoords.minLat - 0.2, minMaxCoords.maxLng + 0.2],
            [minMaxCoords.maxLat + 0.2, minMaxCoords.minLng - 0.2],
            [minMaxCoords.maxLat + 0.2, minMaxCoords.maxLng + 0.2],
        ];
    }

    public renderContent(): any {
        const viewport: any = this.getViewport();
        const icon: any = this.getIcon();
        const isMarker = this.props.isMarker || false;

        const editConfig = {
            polygon: isMarker || !!this.state.disableToolButton ? false : markerOptions,
            polyline: false,
            rectangle: false,
            circle: false,
            circlemarker: false,
            marker: !isMarker || !!this.state.disableToolButton ? false : iconOptions(icon),
        };

        const coordinates = this.state?.latlngs;
        const isMultiPolygon = this.state?.polygons || this.props?.polygons;
        const original = this.props?.original || [];

        const originalIcon: any = isMarker && this.getIcon(true);

        const mapProps = {
            dragging: true,
        };

        return (
            <Map
                innerRef={(ref) => (this.map$ = ref)}
                viewport={viewport}
                isScroll={false}
                className="Map"
                mapProps={mapProps}
            >
                <FeatureGroup ref={(reactFGref) => this.onFeatureGroupReady(reactFGref)}>
                    {!this.props.isShowOnly && (
                        <EditControl
                            position="topleft"
                            onCreated={(e) => this.onChange(e)}
                            onDeleted={() =>
                                this.setState({ disableSaveButton: false, disableToolButton: false, latlngs: null })
                            }
                            onEdited={(e) => this.onEdit(e)}
                            draw={editConfig}
                        />
                    )}
                    {!!this.props.polygon && !isMarker && !!coordinates && (
                        <Polygon
                            key="POLYGON"
                            positions={(coordinates || []).map((cord) => [cord.latitude, cord.longitude])}
                            color="#800360"
                        />
                    )}
                    {!!this.props.marker && isMarker && !!coordinates && (
                        <Marker
                            icon={icon}
                            position={[coordinates.latitude, coordinates.longitude]}
                            zIndexOffset={1000}
                        />
                    )}
                    {!!this.props.isMulti &&
                        !isMarker &&
                        coordinates &&
                        (isMultiPolygon || []).map((item: any) => (
                            <Polygon
                                key={randomId()}
                                positions={item.map((cords) => [cords.latitude, cords.longitude])}
                                color="#800360"
                            />
                        ))}
                </FeatureGroup>
                {this.props.original && !isMarker && (
                    <Polygon
                        positions={(original || []).map((cord) => [cord.latitude, cord.longitude])}
                        color="yellow"
                    />
                )}
                {this.props.original && isMarker && (
                    <Marker
                        icon={originalIcon}
                        position={[original.latitude, original.longitude]}
                        zIndexOffset={1000}
                    />
                )}
                {this.props.original &&
                    !!this.props.isMulti &&
                    !isMarker &&
                    (original || []).map((item) => (
                        <Polygon
                            key={randomId()}
                            positions={item.map((cords) => [cords.latitude, cords.longitude])}
                            color="yellow"
                        />
                    ))}
            </Map>
        );
    }

    public onFeatureGroupReady(e) {
        this.editableFG = e;
    }

    public onEdit(e) {
        const layer = get(this.editableFG, '_layers');

        if (this.props.isMarker) {
            const markerKey = Object.keys(layer)[0];
            const newPos = get(layer[markerKey], '_latlng');

            const latitude = get(newPos, 'lat').toFixed(7);
            const longitude = get(newPos, 'lng').toFixed(7);
            this.setState({
                disableSaveButton: false,
                disableToolButton: true,
                latlngs: { latitude: parseFloat(latitude), longitude: parseFloat(longitude) },
                changed: true,
            });
            return;
        }

        if (this.props.isMulti) {
            const keys = Object.keys(layer);
            const latlngsArray = keys.map((k) => {
                const coordinates = get(layer[k], '_latlngs[0]');

                return (coordinates || []).map((ll) => {
                    const ltd = get(ll, 'lat').toFixed(7);
                    const lng = get(ll, 'lng').toFixed(7);
                    return { latitude: parseFloat(ltd), longitude: parseFloat(lng) };
                });
            });

            this.setState({ disableSaveButton: false, polygons: latlngsArray, changed: true });
            return;
        }

        const key = Object.keys(layer)[0];
        const latlng = get(layer[key], '_latlngs[0]');

        const saveableArray = (latlng || []).map((ll) => {
            const lat = get(ll, 'lat').toFixed(7);
            const long = get(ll, 'lng').toFixed(7);
            return { latitude: parseFloat(lat), longitude: parseFloat(long) };
        });

        this.setState({ disableSaveButton: false, disableToolButton: true, latlngs: saveableArray, changed: true });
        return;
    }

    public onChange(e) {
        if (this.props.isMarker) {
            const latitude = get(e.layer, '_latlng.lat').toFixed(7);
            const longitude = get(e.layer, '_latlng.lng').toFixed(7);

            this.setState({
                disableToolButton: true,
                latlngs: { latitude: latitude, longitude: longitude },
                disableSaveButton: false,
                changed: true,
            });

            return;
        }

        const latlngs = get(e.layer, '_latlngs', [])[0] || [];
        const newArrayFromLatLngs = latlngs.map((latlng) => {
            const lat = get(latlng, 'lat').toFixed(7);
            const long = get(latlng, 'lng').toFixed(7);
            return { latitude: parseFloat(lat), longitude: parseFloat(long) };
        });

        if (this.props.isMulti) {
            const currentPolys: any = this.state?.polygons || [];
            const newStatePolygons = [...currentPolys, newArrayFromLatLngs];
            this.setState({ disableSaveButton: false, polygons: newStatePolygons, changed: true });
            return;
        }

        this.setState({
            disableSaveButton: false,
            disableToolButton: true,
            latlngs: newArrayFromLatLngs,
            changed: true,
        });
    }

    public renderFooter(): React.ReactElement {
        return (
            <FormButtons
                onSave={() => this.handleSave()}
                saveDisabled={this.state.disableSaveButton}
                onClose={() => this.onClose()}
            />
        );
    }

    public onClose() {
        if (this.props?.onClose) {
            this.props.onClose();
        }
    }

    public handleSave(): void {
        const latlongs = this.state.latlngs;
        let polygons: any = this.state.polygons || [];

        if (!this.props.isMarker && (!!polygons.length || !!latlongs)) {
            if (this.props.isMulti) {
                polygons = polygons.map((item: any) => {
                    const lastItem = item[0];
                    item.push(lastItem);
                    return item;
                });
            } else {
                const lastPoint = latlongs[0];
                latlongs.push(lastPoint);
            }
        }

        const newValue = this.props.isMulti ? polygons : latlongs;

        this.props.field.setValue(newValue);
        this.setState({ disableSaveButton: true, disableToolButton: false, latlngs: false, polygons: [] });
        this.onClose();
    }
}

export const MapDrawing = PreLoader(MapDrawingComponent);
