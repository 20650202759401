import { randomId } from '../../utilities/helper-fuctions';
import { get } from 'lodash';
import * as React from 'react';

export class ToastMessages extends React.Component<any, any> {
	public generateError() {
		const errorProps = get(this.props, 'error');

		if (
			get(errorProps, 'response.data.message') === 'token expired' &&
			get(errorProps, 'response.status') === 403
		) {
			return 'Your session has ended, please login again';
		}

		if (get(errorProps, 'response.data.message', null)) {
			return get(errorProps, 'response.data.message', null);
		}

		if (get(errorProps, 'message')) {
			return get(errorProps, 'message');
		}

		if (errorProps) {
			if (typeof errorProps === 'string') {
				return errorProps;
			}

			return 'Error';
		}

		return 'Error';
	}

	public render() {
		const error = this.generateError();

		const errorList = typeof error === 'string' ? error.split('\n') : null;

		return (
			<div>
				{!(errorList || []).length && (
					<p className='ToastMessageContent palette--c-neutral-6 m-1'>
						{error}
					</p>
				)}
				{!!(errorList || []).length &&
					(errorList || []).map((err) => {
						if (err === ': ') {
							return null;
						}

						return (
							<p
								key={randomId()}
								className='ToastMessageContent m-1'
							>
								{err}
							</p>
						);
					})}
			</div>
		);
	}
}
