import {
	compareAsc,
	differenceInHours,
	endOfDay,
	format,
	isAfter,
	isBefore,
	startOfDay,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as React from 'react';
import { arrayToClass } from '../../utilities/helper-fuctions';
import { RequestManager } from '../../utilities/request';
import { Tooltip } from '../ui-components/popover/popover';
import { LimitedInfoDetailsModal } from '../widgets/airport/opening-hours/limited-info-modal/limited-info-details-modal';

const statusMessages = {
	FULL: 'Full availability',
	LIMITED: 'Limited availability',
	CLOSED: 'Not available',
	UNKNOWN: '',
};

const romanToArabic = {
	NONE: 'none',
	I: 1,
	II: 2,
	III: 3,
	IV: 4,
	V: 5,
	VI: 6,
	VII: 7,
	VIII: 8,
	IX: 9,
	X: 10,
};

export class AvailabilityLines extends React.Component<any, any> {
	public detailsModal$;

	public state: any = {
		popover: false,
		airport: null,
		loading: false,
		error: false,
		currentState: false,
	};

	public async componentDidMount(): Promise<void> {
		if (this.props.isMarker) {
			await this.fetchAirport();
		}
	}

	public async fetchAirport(): Promise<any> {

		try {
			this.setState({ loading: true });
			const response = await RequestManager.get(
				`/airports/${this.props?.airportId}`
			);

			if (!response) {
				return null;
			}

			this.setState({ airport: response });
		} catch (err) {
			this.setState({ error: err });
			return;
		} finally {
			this.setState({ loading: false });
		}
	}

	public render(): React.ReactElement {
		return (
			<div className='w-100'>
				<div className='Timeline w-100 position-relative'>
					<div className='w-100 display-flex'>
						{this.renderColors()}
					</div>
					{this.props.isFooter && this.renderFooterLine()}
					{this.props.isMarker && this.renderMarker()}
				</div>
				{this.state?.currentState && (
					<LimitedInfoDetailsModal
						onClose={() => this.setState({ currentState: false })}
						current={this.state?.currentState}
						data={this.getCorrectState()}
					/>
				)}
			</div>
		);
	}

	private renderColors(): React.ReactElement {
		const times = this.getCorrectState();


		let sum = 0;
		times?.map((me) => {
			const diff = this.getDiffs(me);
			sum = sum + diff;
		});

		return times.map((state: any, index: number) => {
			const popoverText = this.generatePopoverText(state);
			const popover = this.state?.popover;

			return (
				<Tooltip
					key={index}
					arrowColor='#fefeff'
					isOpen={popover === `${this.props.type} ${index}`}
					tooltip={this.renderPopoverContent(popoverText)}
					trigger={this.renderChildren(state, index, sum)}
					triggerOnly={true}
				/>
			);
		});
	}

	private renderChildren(state: any, index: number, sum: number): any {
		console.log('1', state, index);

		const diff = this.getDiffs(state);

		const percentage = (diff / sum) * 100;

		console.log({ percentage });

		const status = state?.status;

		let color = 'palette--bgc-neutral-4';

		if (status === 'FULL') {
			color = 'palette--bgc-green-2';
		}

		if (status === 'LIMITED') {
			color = 'palette--bgc-yellow-2';
		}

		if (status === 'CLOSED') {
			color = 'palette--bgc-red-2';
		}

		const className = arrayToClass([
			'Timelinebox py-2',
			this.props.type === 'movement' ? 'pointer' : '',
		]);

		return (
			<div
				style={{ width: `${percentage}%`, height: '25px' }}
				className={className}
				onMouseEnter={() =>
					this.setState({ popover: `${this.props?.type} ${index}` })
				}
				onMouseLeave={() => this.setState({ popover: false })}
				onClick={() => this.openForMore(state)}
			>
				<div
					className={arrayToClass([color])}
					style={{ width: '100%', height: '7px' }}
				/>
			</div>
		);
	}

	private renderPopoverContent(notes): any {
		return (
			<div className='PopoverContent border-radius-1 px-2 py-1 palette--bgc-neutral-1'>
				{notes}
			</div>
		);
	}

	public openForMore(state) {
		if (this.props?.type === 'movement') {
			this.setState({ currentState: state });
		}

		return;
	}

	private generatePopoverText(state): any {
        console.log(state,"sss");

		const { status } = state;
		const color = 'palette--c-neutral-5';

		if (status === 'UNKNOWN') {
			return (
				<div className='display-flex flex-column'>
					<span className={color}>00:00 - 23:59</span>
				</div>
			);
		}

		const { type } = this.props;

		if (!type) {
			return null;
		}

		if (!state?.validFrom || !state.validTo) {
			return null;
		}

		const { validFrom, validTo, notes, info } = state;

		const from = format(new Date(validFrom), 'HH:mm');
		const to = format(new Date(validTo), 'HH:mm');
		const isLimited = status === 'LIMITED';

		if (type === 'ciq' || type === 'provider') {
			return (
				<div className='display-flex flex-column'>
					<span className={color}>
						{from} - {to} {statusMessages?.[status]}
						&nbsp;
					</span>
					{isLimited && <span className={color}>{notes}</span>}
				</div>
			);
		}

		if (type === 'movement') {
			const { openFors } = info||{};

            console.log({ openFors });


			return (
				<div className='display-flex flex-column'>
					<span className={color}>
						{from} - {to} {statusMessages?.[status]}
						&nbsp;
					</span>
					{isLimited && !!openFors && (
						<div className='w-100 pt-1'>
							<span className={color}>
								Click for more details
							</span>
						</div>
					)}
				</div>
			);
		}

		if (type === 'arff') {
			const {
				icaoCatAirplane,
				extensionAvailable,
				extensionUpToIcao,
				extensionNotice,
				firestationRemote,
				firestationNotes,
			} = info||{};

            console.log({ icaoCatAirplane });

			const icao = romanToArabic?.[icaoCatAirplane];
			const upToIcao = romanToArabic?.[extensionUpToIcao];

			return (
				<div className='display-flex flex-column'>
					<span className={color}>
						{from} - {to} {statusMessages?.[status]}
						&nbsp;
					</span>
					{isLimited && <span className={color}>{notes}</span>}
					{icao && <span className={color}>ICAO CAT: {icao}</span>}
					{extensionAvailable && !!extensionUpToIcao && (
						<span className={color}>
							Upgrade available up to ICAO Cat. {upToIcao}{' '}
							{!!extensionNotice &&
								`with prior notice of ${extensionNotice} hours`}
						</span>
					)}
					{!!firestationRemote && (
						<span className={color}>Remote ARFF service only</span>
					)}
					{!!firestationRemote && firestationNotes && (
						<span className={color}>{firestationNotes}</span>
					)}
				</div>
			);
		}

		if (type === 'atc') {
            console.log(info,"xxxx");

			const { afisOnly, enRtfAvailable } = info||{};
            console.log({afisOnly})

			const afis =
				afisOnly === false
					? 'Controlled Tower'
					: afisOnly === true
					? 'AFIS only'
					: false;
			const englishOperator =
				enRtfAvailable === false
					? 'No english operator'
					: enRtfAvailable === true
					? 'English operator'
					: false;

			return (
				<div className='display-flex flex-column'>
					<span className={color}>
						{from} - {to} {statusMessages?.[status]}
						&nbsp;
					</span>
					{isLimited && <span className={color}>{notes}</span>}
					{afis && <span className={color}>{afis}</span>}
					{englishOperator && (
						<span className={color}>{englishOperator}</span>
					)}
				</div>
			);
		}

		if (!info) {
			return null;
		}

		return null;
	}

	private getCorrectState(): any {
		const { states } = this.props;

		const hasTwoDays = states.filter(
			(time: any) =>
				isBefore(new Date(time?.validFrom), endOfDay(new Date())) &&
				isAfter(new Date(time?.validTo), endOfDay(new Date()))
		);

		if (!!hasTwoDays.length && states.length > 1) {
			hasTwoDays.map(
				(time: any) => (time.validTo = endOfDay(new Date()))
			);
		}

		const preTimes = states.map((s: any) => ({
			...s,
			...{
				validFrom: new Date(s?.validFrom),
				validTo: new Date(s?.validTo),
			},
		}));

		const blocks = preTimes.sort((a, b) =>
			compareAsc(a?.validFrom, b?.validFrom)
		);

		// I think this code snippet got depricated as BE always sends us the correct times from midnight to 23:59, but I don't want to remove it right away, let's wait for a bit for errors or something.

		// const h = startOfDay(new Date());

		// const scheduleFromMidnight = blocks.find((s: any) =>
		// 	isSameHour(new Date(s?.validFrom), h);
		// );

		// if (scheduleFromMidnight) {
		// 	return blocks;
		// }

		// const filtered = blocks.filter((s) => {
		// 	const time = new Date(s?.validTo);
		// 	const hour = new Date(h);
		// 	return isAfter(time, hour);
		// });

		// const earliestDate = min(
		// 	filtered.map((state: any) => new Date(state?.validTo))
		// );

		// const filter = blocks.filter((state: any) =>
		// 	isSameHour(new Date(state?.validTo), new Date(earliestDate))
		// )[0];
		// const index = blocks.indexOf(filter);

		// blocks[index] = { ...filter, ...{ validTo: h }, lastForTheDay: true };
		// blocks.unshift({ ...filter, ...{ validFrom: h } });

		return blocks;
	}

	private getDiffs(state: any): any {
		const from = new Date(state?.validFrom);

		console.log({ from });
		const to = new Date(state?.validTo);
		console.log({ to });

		const diff = differenceInHours(from, to, { roundingMethod: 'round' });

		const myDiff = (to - from) / (1000 * 60 * 60);

		console.log('diff', Math.abs(diff), myDiff);

		return myDiff;
	}

	private renderFooterLine(): React.ReactElement {
		return (
			<div className='FooterLine w-100 display-flex'>
				<div className='MainBox border-top-1 border-left-1 border-right-1 palette--bc-neutral-2 display-flex'>
					{this.renderBoxes()}
				</div>
				{['04:00', '08:00', '12:00', '16:00', '20:00'].map(
					(item: string) => {
						return (
							<div
								key={item}
								className='MainBox border-top-1 border-right-1 palette--bc-neutral-2 display-flex position-relative'
							>
								<span className='HourIndex palette--c-neutral-4'>
									{item}
								</span>
								{this.renderBoxes()}
							</div>
						);
					}
				)}
			</div>
		);
	}

	private renderBoxes(): any {
		return [1, 2, 3, 4].map((opt: any, index: number) => (
			<div
				key={index + opt}
				className='Box border-right-1 palette--bc-neutral-2'
			/>
		));
	}

	private renderMarker(): React.ReactElement {
		const timeMarkerClasses = arrayToClass([
			'MarkerLine display-flex flex-column align-items-center position-absolute h-100',
		]);

		const timeZone = this.props?.airport?.timeZone || 'UTC';
		const t = utcToZonedTime(new Date(), timeZone);
		const tInHour = differenceInHours(t, startOfDay(t), {
			roundingMethod: 'round',
		});
		const time = tInHour * (100 / 24);

		return (
			<div className={timeMarkerClasses} style={{ left: `${time}%` }}>
				<div className='Marker palette--bgc-neutral-2 h-100 m-0' />
				<span className='Arrow material-icons palette--c-neutral-2'>
					arrow_drop_up
				</span>
			</div>
		);
	}
}
