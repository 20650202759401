import { arrayToClass, redirectToLogin } from '../../utilities/helper-fuctions';
import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { RequestManager } from '../../utilities/request';
import { Spinner } from '../ui-components/spinner/spinner';
import { ToastMessages } from '../notifications/toast-messages';

export class ResourceLoader extends React.Component<any, any> {
    public isMounted = false;

    public state: any = {
        loading: false,
        error: false,
        response: false,
    };

    public async componentDidMount(): Promise<void> {
        this.isMounted = true;
        await this.fetch();
    }

    public componentWillUnmount() {
        this.isMounted = false;
    }

    public async fetch(): Promise<void> {
        try {
            this.setState({ loading: true });

            const response = await RequestManager.get(this.props.endpoint, this.props.query);

            if (!response) {
                throw new Error('There was an error');
            }

            if (!!this.isMounted) {
                this.setState({ response: response, loading: false });
            }
        } catch (err) {
            this.setState({ error: get(err, 'response.data.message', null) || err || 'Error', loading: false });
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
            redirectToLogin(err);
        }
    }

    public render(): React.ReactElement {
        const classNames = arrayToClass([this.props.classNames ? this.props.classNames : '']);

        return (
            <div className={classNames}>
                {this.state.loading && <Spinner size="medium" />}
                {!this.state.loading && !this.state.error && this.props.renderContent(this)}
                {!this.state.loading && this.state.error && this.renderError()}
            </div>
        );
    }

    private renderError(): any {
        if (this.props?.dontShowError) {
            return null;
        }

        if (this.props.renderError) {
            return this.props.renderError(this);
        }

        return <p className="m-0 palette--c-red-2">{this.state.error}</p>;
    }
}
