import $ from 'jquery';
import { get } from 'lodash';
import * as React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { connectNLP } from '../../utilities/connect-navigate';
import {
	arrayToClass,
	debounce,
	isInQuery,
} from '../../utilities/helper-fuctions';
import {
	accessToken,
	defaultMap,
	googleApiKey,
} from '../../utilities/variables';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';
import { MapDataLoader } from './map-helpers/data-loader';
import { HoverableMarker } from './map-helpers/hoverable-marker';
import { MapConsumer } from './map-helpers/map-component';
import { NearbyMarker } from './map-helpers/nearby-marker';
import './map.scss';

require('react-leaflet-markercluster/dist/styles.min.css');

class MapProviderComponent extends MapDataLoader<any, any> {
	public map$: any;

	public state = {
		...this.state,
		isGoogle: false,
		isScrolling: false,
		mouseOnIt: false,
		isCtrl: false,
	};

	public isScrolling;

	public async componentDidMount(): Promise<any> {
		await super.componentDidMount();

		if (this.props.scrollLayer) {
			this.scrollEventHandler();
			this.keyEventHandler();
		}
	}

	public componentWillUnmount() {
		$(window).off('scroll');
		$(window).off('keyup');
		$(window).off('keydown');
	}

	public scrollEventHandler() {
		const el: any = document.getElementById('Scrollable');
		if (el) {
			el.addEventListener(
				'scroll',
				(ev) => {
					if (!!this.state.mouseOnIt) {
						if (!this.state.isScrolling) {
							this.setState({ isScrolling: true });
						}

						this.isScrolling = debounce(() => {
							if (!!this.state?.isScrolling) {
								this.setState({ isScrolling: false });
							}
						}, 200)();
					}
				},
				false
			);
		}
	}

	public keyEventHandler() {
		window.addEventListener('keydown', (event) => {
			if (event.key === 'Control' || event.key === 'Meta') {
				this.setState({ isCtrl: true });
			}
		});

		window.addEventListener('keyup', (event) => {
			if (event.key === 'Control' || event.key === 'Meta') {
				this.setState({ isCtrl: false });
			}
		});

		$('.Resize-maps').on('click', (e) => {
			this.resize();
		});
	}

	public resize() {
		if (this.map$) {
			debounce(() => this.map$.invalidateSize(), 500)();
		}
	}

	public renderContent(): React.ReactElement {
		const viewport: any = this.state?.viewport;

		if (!viewport) {
			return (
				<div className='w-100 display-flex justify-content-center py-5'>
					<p className='palette--c-neutral-5'>Map is unavailable</p>
				</div>
			);
		}

		const isMarkers =
			this.state.airports && !this.state.loading && !this.state.error;

		const buttonClasses = arrayToClass([
			'position-absolute absolute-right-10 elevation-1',
			`absolute-${this.props.buttonPosition || 'top'}-10`,
			this.props.withoutZIndex ? '' : 'ButtonOnMap',
		]);

		let mapProps = {
			...this.props?.mapProps,
		};

		mapProps = { ...mapProps, ...this.state?.viewport };

		const classNames = arrayToClass([
			'Map position-relative',
			this.props.className || '',
		]);

		return (
			<div
				className={classNames}
				onMouseEnter={() => this.setState({ mouseOnIt: true })}
				onMouseLeave={() => this.setState({ mouseOnIt: false })}
			>
				<MapContainer
					id='Map'
					className='map__reactleaflet elevation-1 h-100'
					maxZoom={18}
					minZoom={2}
					attributionControl={false}
					whenCreated={(ref: any) => (this.map$ = ref)}
					scrollWheelZoom={
						!!this.props.isScroll || !!this.state.isCtrl
					}
					preferCanvas={this.props?.isRelief}
					{...mapProps}
				>
					<MapConsumer
						scrollWheelZoom={
							!!this.props.isScroll || !!this.state.isCtrl
						}
						zoom={mapProps?.zoom || this.state?.viewport?.zoom}
						center={
							mapProps?.center || this.state?.viewport?.center
						}
						bounds={mapProps?.bounds || false}
						handleZoom={(event) => this.handleZoom(event)}
						handleDrag={(event) => this.handleDrag(event)}
						onClick={() =>
							this.props.clickEnabled
								? this.props.handleGoTo()
								: () => {}
						}
						isAirport={this.props?.airport || this.props?.isRelief}
					/>
					{!this.state?.isGoogle && (
						<TileLayer
							url={`${defaultMap}?access_token=${accessToken}`}
						/>
					)}
					{this.state?.isGoogle && (
						<ReactLeafletGoogleLayer
							apiKey={googleApiKey}
							type={'satellite'}
						/>
					)}
					{this.props.useMarkers &&
						isMarkers &&
						this.renderLeafletMarkers()}
					{this.props.children}
				</MapContainer>

				<div className={buttonClasses}>
					<HoverableButton
						title={this.state.isGoogle ? 'Roadmap' : 'Satellite'}
						className='border-radius-1'
						colorType='avio-green'
						onClick={() =>
							this.setState({ isGoogle: !this.state.isGoogle })
						}
					/>
				</div>
				{this.state.isScrolling && (
					<div className='NotiScreenOnMap position-absolute absolute-top-0 absolute-right-0 absolute-bottom-0 absolute-left-0 display-flex justify-content-center align-items-center'>
						<h3 className='palette--c-neutral-1'>
							Press control to use wheel zoom on this map
						</h3>
					</div>
				)}
				{this.props.clickEnabled && (
					<p className='Message m-0 position-absolute absolute-bottom-2 absolute-left-2 palette--c-neutral-1 px-2 py-9'>
						Tap on the map to move around.
					</p>
				)}
			</div>
		);
	}

	public renderLeafletMarkers() {
		const airports: any = get(this.state, 'airports', []) || [];

		return (
			<MarkerClusterGroup
				spiderfyDistanceMultiplier={1}
				showCoverageOnHover={false}
			>
				{this.props?.isRelief &&
					airports.map((airport) => {
						return (
							<HoverableMarker
								key={get(airport, 'aid')}
								airport={airport}
							/>
						);
					})}
				{!this.props?.isRelief &&
					airports.map((airport) => {
						const isMain =
							get(this.props.airport, 'aid') ===
							get(airport, 'aid');
						return (
							<NearbyMarker
								key={get(airport, 'aid')}
								airport={airport}
								clickable={!isMain}
								isMain={isMain}
							/>
						);
					})}
			</MarkerClusterGroup>
		);
	}

	public handleDrag(event): void {
		const newCenter = event.getCenter();
		const zoom = this.props?.mapProps?.zoom || 6;

		const viewport = {
			center: [newCenter.lat, newCenter.lng],
			zoom: zoom,
		};

		if (this.props.airport || this.props.isRelief) {
			debounce(() => this.fetch(viewport, 1000, true))();
		}
	}

	public handleZoom(event): void {
		const newCenter = event.getCenter();
		const newZoom = event.getZoom();
		const northEastPoint = event.getBounds().getNorthEast();
		const distance = newCenter.distanceTo(northEastPoint) / 1000;

		const viewport = {
			center: [newCenter.lat, newCenter.lng],
			zoom: newZoom,
		};

		if (this.props.airport || this.props.isRelief) {
			debounce(() => this.fetch(viewport, distance, true))();
		} else {
			this.setState({ viewport });
		}
	}

	public onClick() {
		const utmSource = isInQuery('utm_source') || '';
		this.props?.router?.navigate({
			pathname: `/airports/${this.props?.airport?.aid}`,
			search: utmSource,
		});
	}
}

export const Map: any = connectNLP(MapProviderComponent);
