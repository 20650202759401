import axios from 'axios';

export class Env {
    static DEFAULT_ENV_PATH = `${window?.location?.origin}/env.json`;
    static envs;

    public static download = () => {
        return axios.get(this.DEFAULT_ENV_PATH).then((response) => (this.envs = response.data));
    };

    public static get = (key) => {
        const file = this.envs;
        const value = file?.[key];

        return value;
    };
}
