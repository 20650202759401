import { get } from 'lodash';
import * as React from 'react';
import { LocalStorage } from '../../../../../../utilities/local-storage';
import { Dropdown } from '../../../../../ui-components/dropdown/dropdown';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';

const copyOptions = [
	{ value: 1, title: 'Movements' },
	{ value: 2, title: 'Air Traffic Control service' },
	{ value: 3, title: 'Aerodrome Rescue and Fire Fighting service' },
	{ value: 4, title: 'Customs and Immigration services' },
];

export class CopyDropdown extends React.Component<any, any> {
	public dropdown$: Dropdown;

	public state = {
		options: copyOptions,
	};

	public componentDidMount() {
		this.getOptions();
	}

	public getOptions() {
		const availableOptions = copyOptions.filter(
			(option: any) =>
				LocalStorage.getItem(`step-${option.value}`) &&
				`step-${option.value}` !== this.props.step
		);

		this.setState({ options: availableOptions });
	}

	public render(): any {
		if (!(this.state.options || []).length) {
			return null;
		}

		return (
			<Dropdown
				direction='right'
				ref={(ref: any) => (this.dropdown$ = ref)}
				trigger={() => this.renderButton()}
				dropdown={() => this.renderOptions()}
				backdropClasses='EditBackdrop palette--bgc-neutral-transparent'
				className='DropdownComponent'
			/>
		);
	}

	public renderButton(): React.ReactElement {
		return (
			<HoverableButton
				colorType='transparent-green'
				title='Copy availability from...'
				onClick={() => this.dropdown$.open()}
			/>
		);
	}

	public renderOptions(): React.ReactElement {
		return (
			<div className='w-100 elevation-1 border-radius-1 display-flex flex-column'>
				{(this.state.options || []).map((option: any) => (
					<p
						key={option.title}
						className='SpecialListItem p-2 m-0 palette--c-neutral-5 pointer'
						onClick={() => this.selectOptions(option.value)}
					>
						{option.title}
					</p>
				))}
			</div>
		);
	}

	public selectOptions(id): void {
		if (id) {
			const stepData = LocalStorage.getItem(`step-${id}`);

			const dataWithoutAdds = (stepData?.data || []).map((d: any) => {
				(get(d.data, 'blocks') || []).map((block) => {
					delete block.afisOnly;
					delete block.enRtfAvailable;
					delete block.icaoCatAirplane;
					delete block.faaCatAirplane;
					delete block.faa139Certified;
					delete block.catHelicopters;
					delete block.waterQuantity;
					delete block.complementaryQuantity;
					delete block.arffVehicles;
					delete block.dischargeCapacity;
					delete block.extensionAvailable;
					delete block.extensionNotice;
					delete block.extensionUpToIcao;
					delete block.firestationRemote;
					delete block.firestationNotes;
				});

				return d;
			});

			if (stepData) {
				LocalStorage.setItem(this.props.step, {
					...stepData,
					data: dataWithoutAdds,
				});
			}
		}

		if (this.props.onChange) {
			this.props.onChange();
		}

		this.dropdown$.close();
	}
}
