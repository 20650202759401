import * as React from 'react';
import { useMap, useMapEvents } from 'react-leaflet';

export const MapConsumer = (props) => {
	const {
		center,
		zoom,
		scrollWheelZoom,
		bounds,
		handleDrag,
		handleZoom,
		onClick,
		isAirport,
	} = props;
	const map = useMap();

	React.useEffect(() => {
		if (!!center && !isAirport) {
			map.invalidateSize();
			map.setView(center);
			map.setZoom(zoom);
		}

		if (!!bounds && !isAirport) {
			map.invalidateSize();
			map.fitBounds(bounds);
		}

		if (!scrollWheelZoom) {
			map.scrollWheelZoom.disable();
		} else {
			map.scrollWheelZoom.enable();
		}
	}, [props]);

	const mapEvents = useMapEvents({
		zoomend: () => {
			handleZoom(mapEvents);
		},
		click: () => {
			onClick();
		},
		dragend: () => {
			handleDrag(mapEvents);
		},
	});

	return null;
};
