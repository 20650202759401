import { isEqual } from 'lodash';

export const Form = class {
	constructor(fields, toggle?: any) {
		Object.keys(fields).forEach(
			(key) =>
				(this.form = {
					...this.form,
					[key]: {
						...(fields[key]?.trigger || !!fields[key]?.mandatory
							? fields[key]
							: { value: fields[key] }),
						newValue:
							fields[key]?.trigger || !!fields[key]?.mandatory
								? fields[key].value
								: fields[key],
						setValue: (value) => this.setValue(key, value),
					},
				})
		);
		this.toggle = toggle ? (hasChanged) => toggle(hasChanged) : false;
	}

	form = {};
	toggle;

	validateMandatory = () => {
		const mandatoryFields = Object.keys(this.form).filter(
			(key) => !!this.form?.[key].mandatory
		);
		let valid = true;
		let counter = 0;

		while (!!valid && mandatoryFields.length > counter) {
			const fieldName: any = mandatoryFields[counter];
			const field = this.form?.[fieldName];

			if (
				field?.newValue === null ||
				field?.newValue === undefined ||
				field?.newValue === ''
			) {
				valid = false;
			} else {
				counter++;
			}
		}

		return valid;
	};

	hasChanged = () => {
		const isValid = this.validateMandatory();

		if (!isValid) {
			return false;
		}

		const formArray = Object.keys(this.form);
		let hasChanged = false;
		let counter = 0;

		while (!hasChanged && formArray.length > counter) {
			const fieldName: any = formArray[counter];
			const value = this.form?.[fieldName].newValue;
			const originalValue = this.form?.[fieldName].value;

			if (value !== originalValue) {
				hasChanged = true;
			} else {
				counter++;
			}
		}

		return hasChanged;
	};

	setValue = (field, value) => {
		const formField = this.getField(field);

		this.form[field].newValue = value;

		if (formField.trigger) {
			formField.trigger();
		}

		if (this.toggle) {
			const hasFormChanged = this.hasChanged();

			if (hasFormChanged) {
				this.toggle(true);
			} else {
				this.toggle(false);
			}
		}
	};

	getValue = (key, isOriginal = false) =>
		this.form?.[key]?.[isOriginal ? 'value' : 'newValue'];

	getField = (key) => this.form?.[key];

	getFieldsWhereHasValue = () =>
		Object.keys(this.form).filter(
			(key) =>
				this.getValue(key, true) !== null &&
				this.getValue(key, true) !== ''
		);

	generateJSON = () => {
		let obj = {};
		Object.keys(this.form).forEach((key) => {
			const value = this.getValue(key);
			const nullValue = value === undefined || value === '';

			obj = {
				...obj,
				[key]: nullValue ? null : value,
			};
		});

		return obj;
	};

	getDifferences = () =>
		Object.keys(this.form).filter((key) => {
			const json = this.generateJSON();
			const value = json?.[key];
			const v = value !== null && value !== undefined ? value : '';
			const originalValue = this.getValue(key, true);
			const oVAlue =
				originalValue !== null && originalValue !== undefined
					? originalValue
					: '';

			return !isEqual(v, oVAlue);
		});

	clearForm = () => (this.form = {});
};
