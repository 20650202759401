import * as React from 'react';
import { connect } from 'react-redux';
import { LocalstorageActions } from '../../actions/localstorage.actions';
import { UserActions } from '../../actions/user.actions';
import { GeneralDataForm } from '../../components/widgets/airport/general-data/general-data-form';
import { MapModal } from '../../components/widgets/airport/map/map-modal';
import { OpeningHoursCreate } from '../../components/widgets/airport/opening-hours/opening-hours-create/opening-hours-create';
import { OperationalDataForm } from '../../components/widgets/airport/operational-data/operational-data-form';
import { ProviderDetails } from '../../components/widgets/airport/providers/provider-details/provider-details';
import { ProviderForm } from '../../components/widgets/airport/providers/provider-form';
import { FuelProductForm } from '../../components/widgets/airport/providers/provider-products/fuel-products/fuel-product-form';
import { GenericProductForm } from '../../components/widgets/airport/providers/provider-products/generic-product/generic-product-form';
import { ProviderAvailabilityEdit } from '../../components/widgets/airport/providers/provider-products/provider-availability/provider-availability-create/availability-create';
import { RunwayDetails } from '../../components/widgets/airport/runways/runway-details/runway-details';
import { RunwayForm } from '../../components/widgets/airport/runways/runway-form';
import { AuthorityContactsCreate } from '../../components/widgets/shared/authority/authority-contacts/authority-contact-create/authority-contacts.create';
import { AuthorityDetails } from '../../components/widgets/shared/authority/authority-details';
import { AuthorityForm } from '../../components/widgets/shared/authority/authority-form';
import { OperationalNotesForm } from '../../components/widgets/shared/notes/notes-form';
import { OperationalNotesDetails } from '../../components/widgets/shared/notes/operational-notes-details';
import { connectNLP } from '../../utilities/connect-navigate';
import {
	getQuery,
	isInQuery,
	removeQuery,
	replaceQuery,
} from '../../utilities/helper-fuctions';
import { aidNamespaces } from '../../utilities/variables';
import './modals.scss';

const mapStateProps = (store: any) => ({
	widgetSuccess: store.details.widgetSuccess,
});

const mapDispatchProps = (dispatch: any) => ({
	setModalDetails: (data) =>
		dispatch(LocalstorageActions.setModalDetails(data)),
	checkUser: () => dispatch(UserActions.tryRedirectIfNotAuthenticated()),
});

const endOfRegex = '{1}([A-Z]|[0-9]){4}-([A-Z]|[0-9]){4}';

class ModalsComponent extends React.Component<any, any> {
	public componentDidMount() {
		this.checkIfRedirectIsNecessary();
	}

	public checkIfRedirectIsNecessary(): any {
		this.isDetailsModal();
	}

	public isDetailsModal() {
		const params = this.props?.router?.params?.chldid;

		if (!params || params === 'map') {
			return;
		}

		const query = getQuery().edit;
		const subParams = this.props?.router?.params?.subId;

		if (!!query || !!subParams) {
			this.props.checkUser();
			return;
		}

		const nameSpaces = [
			aidNamespaces.airportAuthority,
			aidNamespaces.provider,
			aidNamespaces.airportNote,
			aidNamespaces.runway,
			aidNamespaces.countryNote,
			aidNamespaces.countryAuthority,
		];

		let isReturn = false;
		let counter = 0;

		while (!isReturn && nameSpaces.length > counter) {
			const regex = new RegExp(
				`${nameSpaces[counter]}-${endOfRegex}`,
				'g'
			);
			if (params.match(regex) && !query) {
				isReturn = true;
			} else {
				counter++;
			}
		}

		if (!!isReturn) {
			return;
		}

		this.props.checkUser();
	}

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		const location = this.props?.router?.location;
		const prevLocation = prevProps?.router?.location;

		if (
			location?.pathname !== prevLocation?.pathname ||
			location?.search !== prevLocation?.search
		) {
			this.checkIfRedirectIsNecessary();
		}
	}

	public render(): React.ReactElement {
		return (
			<div className='w-100 h-100'>
				<div className='Modals'>{this.renderModal()}</div>
			</div>
		);
	}

	public renderModal(): any {
		const identifierBlock = this.getIdentifier();

		if (!identifierBlock) {
			return null;
		}

		const { identifier } = identifierBlock;
		const query = getQuery().edit;
		const cOrAPrefix = identifierBlock?.isCountry ? 'country' : 'airport';

		if (identifier === 'map') {
			return (
				<MapModal
					aid={identifierBlock.aid}
					onClose={() => this.onClose()}
					isEdit={true}
					endpoint={`/airports/${identifierBlock.aid}`}
				/>
			);
		}

		if (identifier === 'RWY' || identifier === 'runways') {
			if (!!query || identifier === 'runways') {
				const isEdit = identifier === 'RWY';
				return (
					<RunwayForm
						aid={identifierBlock.aid}
						onClose={(isDelete) => this.onClose(isDelete)}
						endpoint={`/runways/${identifierBlock.aid}`}
						isEdit={isEdit}
					/>
				);
			}

			return (
				<RunwayDetails
					aid={identifierBlock.aid}
					goToAirports={() => this.onClose()}
					edit={() => this.edit()}
				/>
			);
		}

		if (identifier === 'PRV' || identifier === 'providers') {
			if (!!query || identifier === 'providers') {
				return (
					<ProviderForm
						aid={identifierBlock.aid}
						endpoint={`/providers/${identifierBlock.aid}`}
						isEdit={query}
						onClose={(isDelete) => this.onClose(isDelete)}
					/>
				);
			}

			return (
				<ProviderDetails
					aid={identifierBlock.aid}
					goToAirports={() => this.onClose()}
					edit={() => this.edit()}
					endpoint={`/providers/${identifierBlock.aid}`}
					isEdit={true}
				/>
			);
		}

		if (
			identifier === 'AAU' ||
			identifier === 'CAU' ||
			identifier === 'authorities'
		) {
			if (!!query || identifier === 'authorities') {
				return (
					<AuthorityForm
						endpoint={`/${cOrAPrefix}authorities/${identifierBlock.aid}`}
						aid={identifierBlock.aid}
						onClose={(isDelete) => this.onClose(isDelete)}
						isEdit={identifier !== 'authorities'}
						isCountry={identifierBlock?.isCountry}
					/>
				);
			}
			return (
				<AuthorityDetails
					aid={identifierBlock.aid}
					onClose={() => this.onClose()}
					edit={() => this.edit()}
					isCountry={identifierBlock?.isCountry}
				/>
			);
		}

		if (
			identifier === 'ONA' ||
			identifier === 'ONC' ||
			identifier === 'operational-notes'
		) {
			if (!!query || identifier === 'operational-notes') {
				return (
					<OperationalNotesForm
						endpoint={`/${cOrAPrefix}operationalnotes/${identifierBlock.aid}`}
						aid={identifierBlock.aid}
						isEdit={identifier !== 'operational-notes'}
						onClose={(isDelete) => this.onClose(isDelete)}
						isCountry={identifierBlock?.isCountry}
					/>
				);
			}

			return (
				<OperationalNotesDetails
					endpoint={`/${cOrAPrefix}operationalnotes/${identifierBlock.aid}`}
					aid={identifierBlock.aid}
					isCountry={identifierBlock?.isCountry}
					onClose={() => this.onClose()}
					isEdit={true}
				/>
			);
		}

		if (
			(identifier === 'FPR' && !!query) ||
			identifier === 'fuel-product'
		) {
			const isEdit = identifier === 'FPR';
			return (
				<FuelProductForm
					aid={
						isEdit ? identifierBlock.aid : identifierBlock.parentAid
					}
					onClose={() => this.onSubClose(identifierBlock.parentAid)}
					endpoint={
						isEdit ? `/fuelproducts/${identifierBlock.aid}` : ''
					}
					isEdit={isEdit}
				/>
			);
		}

		if (
			(identifier === 'PVP' && !!query) ||
			identifier === 'generic-product'
		) {
			const isEdit = identifier === 'PVP';
			return (
				<GenericProductForm
					aid={
						isEdit ? identifierBlock.aid : identifierBlock.parentAid
					}
					isEdit={isEdit}
					endpoint={`/providerproducts/${identifierBlock.aid}`}
					onClose={() => this.onSubClose(identifierBlock.parentAid)}
				/>
			);
		}

		if (identifier === 'provider-availability') {
			return (
				<ProviderAvailabilityEdit
					aid={identifierBlock.parentAid}
					onClose={() => this.onSubClose(identifierBlock.parentAid)}
				/>
			);
		}

		if (identifier === 'contact') {
			return (
				<AuthorityContactsCreate
					isCountry={identifierBlock?.isCountry}
					aid={identifierBlock.parentAid}
					onClose={() => this.onSubClose(identifierBlock.parentAid)}
				/>
			);
		}

		if (identifier === 'availability') {
			return (
				<OpeningHoursCreate
					onClose={() => this.onClose()}
					parentAid={identifierBlock.aid}
				/>
			);
		}

		if (identifier === 'general-data') {
			return (
				<GeneralDataForm
					aid={identifierBlock.aid}
					onClose={() => this.onClose()}
					isEdit={true}
					endpoint={`/airports/${identifierBlock.aid}`}
				/>
			);
		}

		if (identifier === 'operations') {
			return (
				<OperationalDataForm
					aid={identifierBlock.aid}
					onClose={() => this.onClose()}
					isEdit={true}
					endpoint={`/airports/${identifierBlock.aid}`}
				/>
			);
		}

		return null;
	}

	public onSubClose(parentAid) {
		const aid = this.props?.router?.params?.aid;
		const utmSource = isInQuery('utm_source');

		this.props?.router?.navigate({
			pathname: `/airports/${aid}/${parentAid}`,
			search: utmSource,
		});
	}

	public onClose(goTo: boolean = false): void {
		const isCountry = this.props?.identifier === 'countries';
		const params = this.props?.router?.params;
		const id = params?.[isCountry ? 'iso' : 'aid'];
		const query = getQuery();

		if (!!query.edit && !goTo) {
			const newQuery = removeQuery('edit');
			this.props?.router?.navigate({ search: newQuery });
		} else {
			const utmSource = isInQuery('utm_source');
			this.props?.router?.navigate({
				pathname: `/${isCountry ? 'countries' : 'airports'}/${id}`,
				search: utmSource,
			});
		}
	}

	public edit(): void {
		const newQueries = replaceQuery({ edit: true });
		this.props?.router?.navigate({ search: newQueries });
	}

	public getIdentifiersPrefix(param) {
		const paramsNamespace: any = Object.keys(aidNamespaces).find(
			(namespace) => {
				const regex = new RegExp(
					`${aidNamespaces?.[namespace]}-${endOfRegex}`,
					'g'
				);

				return !!param.match(regex);
			}
		);

		return aidNamespaces?.[paramsNamespace] || false;
	}

	public getIdentifier(): any {
		const subQuery = this.props?.router?.params?.subId;
		const query = this.props?.router?.params?.chldid;
		const isCountry = this.props?.identifier === 'countries';

		if (!query) {
			return null;
		}

		if (!!subQuery) {
			const identifier = this.getIdentifiersPrefix(subQuery);

			if (!!identifier) {
				return {
					identifier,
					aid: subQuery,
					parentAid: query,
					isCountry,
				};
			} else {
				return { identifier: subQuery, parentAid: query, isCountry };
			}
		} else {
			const identifier = this.getIdentifiersPrefix(query);

			if (!!identifier) {
				return { identifier, aid: query, isCountry };
			} else {
				return {
					identifier: query,
					aid: isCountry
						? this.props?.router?.params?.iso
						: this.props?.router?.params?.aid,
					isCountry,
				};
			}
		}
	}
}

export const Modals: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(connectNLP(ModalsComponent));
