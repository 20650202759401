import * as React from 'react';
import './mobile-links.scss';

export class MobileLinks extends React.Component<any, any> {
    public state = {
        openLink: true,
        isHover: false,
    };

    public render() {
        const isMobile = this.isMobile();
        const isOpen = this.state.openLink;

        if (!isOpen || !isMobile) {
            return null;
        }

        const isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);

        const icon = require(`../../assets/${isIos ? 'app' : 'play'}-store${this.state.isHover ? '' : '-white'}.png`);

        return (
            <div className="FloatingNotifier p-3 w-100 elevation-1 display-flex justify-content-end">
                <div
                    className="display-inline-block pointer"
                    onClick={() => this.handleNavigate(isIos)}
                    onMouseEnter={() => this.setState({ isHover: true })}
                    onMouseLeave={() => this.setState({ isHover: false })}
                >
                    <div className="Button display-flex align-items-center border-1 border-radius-1 px-2 py-1">
                        <p className="palette--c-neutral-1 mr-3 mb-0">Download our app</p>
                        <img src={icon} className="palette--c-primary-4" width="20px" height="auto" alt="logo" />
                    </div>
                </div>
                <div className="display-inline-block ml-2 pointer" onClick={() => this.setState({ openLink: false })}>
                    <div className="CloseButton display-flex align-items-center border-1 border-radius-1 px-2 py-1 h-100">
                        <span className="material-icons">close</span>
                    </div>
                </div>
            </div>
        );
    }

    public isMobile() {
        if (
            navigator.userAgent.indexOf('IEMobile') !== -1 ||
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        ) {
            return true;
        }

        return false;
    }

    public handleNavigate(isIos) {
        window.open(
            isIos
                ? 'https://apps.apple.com/us/app/aviowiki/id1517452674'
                : 'https://play.google.com/store/apps/details?id=com.aviowiki'
        );
        this.setState({ openLink: false });
    }
}
