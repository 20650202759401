import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { DetailsActions } from '../../actions/details.actions';
import { AdminMenu } from '../../components/sidebars/admin-menu/admin-menu';
import { arrayToClass, setTitleAndMeta } from '../../utilities/helper-fuctions';
import { logoLink } from '../../utilities/variables';
import './manages.layout.scss';

export const ManagesLayout = (Component) => (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const user = useSelector((state: any) => state.user.user);
    const open = useSelector((state: any) => state.details.open);
    const openMobile = useSelector((state: any) => state.details.openMobile);
    const dispatch = useDispatch();

    const classes = arrayToClass([
        'ManagesLayout default',
        open ? 'open' : 'closed',
        openMobile ? 'open-mobile' : 'closed-mobile',
    ]);

    const isAdmin = !!(user?.roles || []).includes('ADMIN');

    const className = arrayToClass([isAdmin ? 'ManagesContent' : 'ContributionsContent', 'palette--bgc-neutral-3']);

    React.useEffect(() => setTitleAndMeta('aviowiki - admin', 'Admin panel for aviowiki'), []);

    const handleToMain = () => {
        dispatch(DetailsActions.setOpen(false));
        dispatch(DetailsActions.setOpenMobile(false));
        navigate('/');
    };

    const renderToggle = () => {
        const classes = arrayToClass([
            'Resize-maps NearbyAirportsToggle elevation-2 palette--bc-neutral-4 border-1',
            'display-none display-lg-block',
            open ? 'Opened' : 'Closed',
            openMobile ? 'OpenedMobile' : 'ClosedMobile',
        ]);

        return (
            <div className={classes} onClick={() => dispatch(DetailsActions.toggleOpen())}>
                <div className="ToggleWrapper">
                    <span className="ToggleIcon material-icons">chevron_left</span>
                    <span className="ToggleTitle">Menu</span>
                </div>
            </div>
        );
    };

    return (
        <div className={classes}>
            {isAdmin && (
                <div className="Sidebar palette--bgc-neutral-2 border-right-1 palette--bc-neutral-4">
                    <div className="display-flex w-100">
                        <div className="LinkToHome flex-fill p-3" onClick={() => handleToMain()}>
                            <img src={logoLink} alt="aviowiki" className="AvioLogo" />
                        </div>
                        <div>
                            <div className="display-flex display-lg-none justify-content-end m-3">
                                <span
                                    className="CloseSidebarButton material-icons"
                                    onClick={() => dispatch(DetailsActions.toggleOpenMobile())}
                                >
                                    close
                                </span>
                            </div>
                        </div>
                    </div>

                    <AdminMenu id={props.identifier} />
                </div>
            )}

            <div className={className}>
                {isAdmin && renderToggle()}
                <Component {...props} router={{ navigate, location, params }} />
            </div>
        </div>
    );
};
