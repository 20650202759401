import { get } from 'lodash';
import * as React from 'react';
import { arrayToClass, randomId } from '../../../utilities/helper-fuctions';
import { types } from './button-color-types';
import './hoverable-button.scss';

export class HoverableButton extends React.Component<any, any> {
    public state = {
        hovered: false,
    };

    public buttonId: any = randomId();

    public componentDidMount() {
        if (this.props.isSave || this.props.isClose) {
            document.addEventListener('keyup', this.handleKey);
        }
    }

    public componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKey);
    }

    public handleKey = (event) => {
        const isDisabled = get(this.props, 'disabled');
        const key = event.key;

        if (this.props.onClick) {
            if (key === 'Enter' && !isDisabled && this.props.isSave) {
                this.props.onClick();
            }

            if (key === 'Escape' && this.props.isClose) {
                this.props.onClick();
            }
        }
    };

    public render() {
        const hovered = this.state.hovered === this.buttonId;
        const colors = types[this.props.colorType];
        const classes = arrayToClass([
            this.props.className ? this.props.className : '',
            'HoverableButton border-radius-1',
            `palette--bgc-${hovered ? colors.hbgc : colors.bgc}`,
            `palette--bc-${hovered ? colors.hbc : colors.bc}`,
            `palette--c-${hovered ? colors.hc : colors.c}`,
        ]);

        const childColors = `palette--c-${hovered ? colors.hc : colors.c}`;

        const props: any = {
            onClick: this.props.onClick,
            id: this.props.id,
            autoFocus: this.props.autoFocus || false,
            disabled: this.props.disabled || false,
        };

        if (!this.props.ignoreType) {
            props.type = this.props.type ? this.props.type : 'button';
        }

        return (
            <button
                {...props}
                className={classes}
                onMouseEnter={() => this.handleEnter()}
                onMouseLeave={() => this.handleLeave()}
            >
                {this.renderChildren(childColors)}
            </button>
        );
    }

    public handleEnter() {
        this.setState({ hovered: this.buttonId });

        if (this.props.onEnter) {
            this.props.onEnter();
        }
    }

    public handleLeave() {
        this.setState({ hovered: false });

        if (this.props.onLeave) {
            this.props.onLeave();
        }
    }

    public renderChildren(colors): any {
        if (this.props.renderChildren) {
            return this.props.renderChildren(colors);
        }

        const classes = (other, isTitle = false) =>
            arrayToClass([
                colors,
                other,
                isTitle && this.props.titleClasses
                    ? this.props.titleClasses
                    : this.props.iconClasses
                    ? this.props.iconClasses
                    : '',
            ]);

        if (!this.props.icon) {
            return <span className={classes('pointer', true)}>{this.props.title}</span>;
        }

        return (
            <div className="display-flex align-items-center pointer">
                {this.props.titleLeft && <span className={classes('mr-2 pointer', true)}>{this.props.titleLeft}</span>}
                <span className={classes('material-icons HoverableButtonIcons pointer')}>{this.props.icon}</span>
                {this.props.titleRight && (
                    <span className={classes('ml-2 pointer', true)}>{this.props.titleRight}</span>
                )}
            </div>
        );
    }
}
