import { format } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
	checkData,
	getData,
	getFields,
} from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import {
	arrayToClass,
	randomId,
	redirectToLogin,
} from '../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../utilities/request';
import { MapDrawing } from '../../../../map/map-drawing/map-drawing';
import { ToastMessages } from '../../../../notifications/toast-messages';
import { Form } from '../../../../ui-components/form/form';
import { HoverableButton } from '../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { ContributionFooter } from '../../contributions/contribution-footer';
import { WidgetContributionPresenter } from '../../contributions/widget-contribution-presenter/widget-contribution-presenter';
import { authoritiesFields } from './authority-helper';
import { CustomsDetailsContribution } from './customs-details-contribution';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class AuthoritiesContributionComponent extends React.Component<any, any> {
	public customs$: any;

	public state: any = {
		isAdmin: (this.props?.user?.roles || []).includes('ADMIN'),
		type: this.props?.item?.contribution?.action,
		editable: [],
		rejected: [],
		buttonDisabled: false,
		title: null,
		field: null,
		formType: null,
		multi: false,
		isMapOpen: false,
	};

	public form: any = new Form({
		name: this.props?.item?.name,
		type: this.props?.item?.type,
		landsideArea: this.props?.item?.landsideArea,
		airsideArea: this.props?.item?.airsideArea,
	});

	public render(): React.ReactElement {
		const authoritiesList: any = getFields(this, authoritiesFields(this));
		const original = this.props.original?.customsDetails;
		const item = this.props.item?.customsDetails;

		return (
			<div className='Content w-100'>
				<div className='p-2'>
					{(!authoritiesList || !authoritiesList.length) &&
						this.renderEmpty()}
					{!!authoritiesList.length &&
						this.renderContent(authoritiesList)}
				</div>
				{item && (
					<div>
						<p className='pt-2 pl-4 m-0 fw-bold palette--c-neutral-6'>
							Customs Details:{' '}
						</p>
						<CustomsDetailsContribution
							ref={(ref: any) => (this.customs$ = ref)}
							original={original}
							item={item}
							isAdmin={this.state.isAdmin}
						/>
					</div>
				)}
				<ContributionFooter
					buttonDisabled={this.state.buttonDisabled}
					isAdmin={this.state.isAdmin}
					handleButtonClick={(isReject: boolean = false) =>
						this.handleButtonClick(isReject)
					}
					close={() => this.close()}
					handleEdit={() => this.handleEdit()}
				/>
			</div>
		);
	}

	public renderContent(list): React.ReactElement {
		return (
			<div className='w-100'>
				<p className='m-0 fw-bold palette--c-neutral-6'>
					Changes in operational notes widget
				</p>
				<div className='p-2 display-none display-md-flex'>
					<h6 className='Property palette--c-neutral-5 fw-bold m-0'>
						Property
					</h6>
					<h6 className='CurrentData palette--c-neutral-5 fw-bold m-0'>
						Current data
					</h6>
					<span className='ArrowIcons material-icons palette--c-neutral-1 fw-bold'>
						arrow_forward
					</span>
					<h6 className='NewData palette--c-neutral-5 fw-bold m-0'>
						New data
					</h6>
				</div>
				{list.map((item: any, index: number) => {
					return this.renderFields(item, index);
				})}
			</div>
		);
	}

	public renderEmpty(): any {
		return (
			<div className='display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2'>
				<p className='palette--c-neutral-5 m-0'>Nothing changed.</p>
			</div>
		);
	}

	public getField() {
		const form = this[this.state.formType];

		if (!form) {
			return false;
		}

		return form.field(this.state.field);
	}

	public async onClick(title, field, formType, multi: boolean = true) {
		await this.setState({
			title: title,
			field: field,
			formType: formType,
			multi: multi,
			isMapOpen: true,
		});
	}

	private renderFields(field, index): any {
		const wrapperClassName = arrayToClass([
			this.state.rejected.includes(field.type)
				? 'palette--bgc-neutral-3'
				: `palette--bgc-${
						this.state.type === 'UPDATE'
							? 'yellow-1'
							: this.state.type === 'CREATE'
							? 'green-1'
							: 'red-1'
				  }`,
			'border-radius-1 my-2 p-2',
			'display-flex align-items-center',
		]);

		if (field.type === 'landsideArea' || field.type === 'airsideArea') {
			const wrapperClasses = arrayToClass([
				'WidgetContributionPresenter',
				'flex-wrap',
				wrapperClassName,
			]);

			return (
				<div key={randomId()} className={wrapperClasses}>
					<h6 className='Property palette--c-neutral-5 fw-bold m-0 display-block display-md-none'>
						Property
					</h6>
					<p className='Property Bottom fw-bold m-0 palette--c-neutral-6'>
						{field.title}:
					</p>
					<div className='flex-fill display-flex justify-content-center'>
						{field.component}
					</div>
					<div className='ButtonContainer display-flex align-items justify-content justify-content-end'>
						{!this.state.rejected.includes(field.type) && (
							<HoverableButton
								colorType='transparent-grey'
								icon='close'
								onClick={() => this.handleReject(field.type)}
							/>
						)}
						{this.state.rejected.includes(field.type) && (
							<HoverableButton
								colorType='transparent-grey'
								icon='check'
								onClick={() =>
									this.handleMissReject(field.type)
								}
							/>
						)}
					</div>
					{this.state?.isMapOpen && (
						<MapDrawing
							isMulti={this.state.multi}
							title={this.state.title}
							field={this.getField()}
							polygon={this.getField().newValue}
							endpoint={`/airports/${this.state?.response?.parent}`}
							isEdit={true}
							onClose={() => this.setState({ isMapOpen: false })}
						/>
					)}
				</div>
			);
		}

		const element = {
			type: field.type,
			content: this.getData(field.type),
			component: field.component,
		};

		return (
			<WidgetContributionPresenter
				isAdmin={this.state.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(field.type)}
				toValue={element}
				isEditable={this.state.editable.includes(field.type)}
				isRejected={this.state.rejected.includes(field.type)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				className={wrapperClassName}
			/>
		);
	}

	private getOriginalData(field: string): any {
		const value = this.props.original?.[field];

		if (this.state.type === 'CREATE') {
			return null;
		}

		return getData(field, value, false);
	}

	private getData(field: any): any {
		const form = this.form.generateJSON();
		const value = form?.[field];

		if (this.state.type === 'DELETE') {
			return '';
		}

		if (field === 'validFrom' || field === 'validTo') {
			return format(new Date(form?.[field]), 'yyyy-MM-d, HH:mm');
		}

		return getData(field, value, false);
	}

	private async handleEdit(type: string = 'editAll'): Promise<void> {
		if (type === 'editAll') {
			const authorities =
				authoritiesFields(this).map((item: any) => item.type) || [];
			this.customs$.handleEdit('editAll');

			await this.setState({
				editable: authorities,
			});
		}

		if (!this.state.editable.includes(type)) {
			await this.setState({ editable: [...this.state.editable, type] });
		}
	}

	private async handleMissEdit(type: string): Promise<void> {
		if (this.state.editable.includes(type)) {
			const editableArray = this.state.editable.filter(
				(item) => item !== type
			);
			await this.setState({ editable: editableArray });
		}
	}

	private handleReject(type: string): void {
		if (!this.state.rejected.includes(type)) {
			this.setState({ rejected: [...this.state.rejected, type] });
		}
	}

	private handleMissReject(type: string) {
		if (this.state.rejected.includes(type)) {
			const rejectedArray = this.state.rejected.filter(
				(item) => item !== type
			);
			this.setState({ rejected: rejectedArray });
		}
	}

	private clearForm(): void {
		this.form.clearForm();
		this.customs$?.clearForm();
	}

	private checkModifications() {
		const { item } = this.props;
		const formList = getFields(this, authoritiesFields(this)) || [];
		const form = this.form.generateJSON();

		if (item?.customsDetails) {
			item.customsDetails = this.customs$?.getContribution();

			item.contribution.changes = [
				...checkData(formList, item, form),
				'customsDetails',
			];
		} else {
			item.contribution.changes = checkData(formList, item, form);
		}

		item.contribution.rejectedChanges = this.state.rejected;

		return item;
	}

	private async handleButtonClick(isReject = false): Promise<void> {
		this.setState({ buttonDisabled: true });
		let { item } = this.props;

		if (isReject) {
			item.contribution.status = 'REJECTED';
		} else {
			item = this.checkModifications();
		}

		try {
			const response = await RequestManager.post(
				`/${
					this.props.isCountry
						? 'countryauthorities'
						: 'airportauthorities'
				}/merge`,
				item
			);

			if (!response) {
				throw new Error('There was an error');
			}

			this.onSuccess(
				`Contribution is successfully ${
					isReject ? 'rejected' : 'accepted'
				}.`
			);
		} catch (err) {
			this.onError(err);
		}
	}

	private onSuccess(message: string): void {
		this.clearForm();
		toast.success(message, { theme: 'light' });
		this.props.onSuccess();
		this.setState({ buttonDisabled: false });
	}

	private onError(err): void {
		toast.error(<ToastMessages error={err} />, { theme: 'light' });
		this.setState({ buttonDisabled: false });
		redirectToLogin(err);
	}

	private close(): void {
		this.clearForm();
		this.props.close();
		this.setState({ buttonDisabled: false });
	}
}

export const AuthoritiesContribution: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(AuthoritiesContributionComponent);
