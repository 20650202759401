import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { RequestManager } from '../../../utilities/request';
import { ToastMessages } from '../../notifications/toast-messages';
import { Form } from '../../ui-components/form/form';
import { Password } from '../../ui-components/inputs/inputs/password';

export class GeneralUserData extends React.Component<any, any> {
    public form: any = new Form({
        oldPassword: '',
        newPassword: '',
        newPasswordAgain: '',
    });

    public render(): React.ReactElement {
        return (
            <form>
                <div className="py-4 w-100">
                    <div className="display-flex align-items-center mb-2">
                        <h4 className="palette--c-neutral-6 flex-fill m-0">{get(this.props.user, 'name')}</h4>

                        <div className="display-flex align-items-center">
                            <span className="Reward material-icons palette--c-primary-4">emoji_events</span>
                            <p className="m-0 ml-2">{get(this.props.user, 'reward')}</p>
                        </div>
                    </div>

                    <p className="palette--c-neutral-5">{get(this.props.user, 'login')}</p>
                </div>
                <div className="py-4 w-100">
                    <h6 className="fw-bold text-uppercase palette--c-neutral-5 mb-4">Change password</h6>

                    <Password
                        field={this.form.getField('oldPassword')}
                        classes="limit-w  mb-4"
                        labelInfos={{ label: 'Old password' }}
                    />
                    <Password
                        field={this.form.getField('newPassword')}
                        classes="limit-w mb-4"
                        labelInfos={{ label: 'New password' }}
                    />
                    <Password
                        field={this.form.getField('newPasswordAgain')}
                        classes="limit-w"
                        labelInfos={{ label: 'New password again' }}
                    />
                </div>
            </form>
        );
    }

    public async handlePassword(): Promise<any> {
        const form = this.form.generateJSON();

        if (!form.newPassword && !form.newPasswordAgain) {
            return;
        }

        if (!form.oldPassword) {
            throw new Error('You have to give your old password');
        }

        if (form.newPassword === form.newPasswordAgain) {
            try {
                const response = await RequestManager.put(`/users/${get(this.props.user, 'aid')}/password`, {
                    oldPassword: form.oldPassword,
                    newPassword: form.newPassword,
                });

                if (!response) {
                    throw new Error('There was an error');
                }

                toast.success('New password is successfully saved!', { theme: 'light' });
            } catch (err) {
                toast.error(<ToastMessages error={err} />, { theme: 'light' });
                return err;
            }
        } else {
            const err = 'The new password and the check new password is not the same.';
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
        }
    }
}
