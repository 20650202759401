import * as React from 'react';
import { arrayToClass } from '../../../../utilities/helper-fuctions';
import { Label } from '../label/label';

export class InputWrapper<S, P> extends React.Component<S & any, P & any> {
	public state = {
		value: this.generateValue(),
	};

	public generateValue() {
		const value = this.props?.field?.newValue;
		return value === null || value === undefined ? '' : value;
	}

	public componentDidUpdate() {
		const newValue = this.generateValue();

		if (this.state?.value !== newValue) {
			this.setState({ value: newValue });
		}
	}

	public render() {
		const wrapperClassName = arrayToClass([
			'w-100',
			this.props.classes ? this.props.classes : '',
		]);

		return (
			<div className={wrapperClassName}>
				{this.renderLabel()}
				{this.renderInput()}
			</div>
		);
	}

	public renderLabel() {
		const label = this.props?.labelInfos;
		const labelClasses = arrayToClass([
			'mb-2',
			label?.classes ? label?.classes : '',
			this.props?.field?.mandatory ? 'asterix' : '',
		]);

		if (!label) {
			return null;
		}

		return (
			<Label
				info={label.info || false}
				label={label.label || false}
				className={labelClasses}
				ignoreTopMargin={label.ignoreTopMargin || false}
				isSimple={label.isSimple || false}
			/>
		);
	}

	public renderInput(): any {
		return null;
	}

	public handleChange(event) {
		let newValue = event.target.value;

		if (this.props.numeralPositiveOnly && newValue < 0) {
			event.target.value = Math.abs(newValue);
		}

		if (this.props.inputType === 'number') {
			if (
				!!this.props.maxLengthLimit &&
				(newValue || '').length > !!this.props.maxLengthLimit
			) {
				event.target.value = newValue.slice(
					0,
					this.props.maxLengthLimit
				);
			}

			if ((newValue || '').includes(',')) {
				newValue = newValue.replaceAll(',', '.');
			}

			if (!/^[0-9.-]+$/.test(newValue)) {
				newValue = newValue.replace(/[^0-9.-]/g, '');
			}
		}

		if (!!this.props?.formatInput) {
			newValue = this.props?.formatInput(newValue);
		}

		this.props.field.setValue(newValue);
		this.setState({ value: newValue });
	}
}
