import { get } from 'lodash';
import * as React from 'react';
import { getData } from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../utilities/request';
import { Form } from '../../../../ui-components/form/form';
import { WidgetContributionPresenter } from '../../../shared/contributions/widget-contribution-presenter/widget-contribution-presenter';
import { imageFields } from './constants';

export class ImagesContribution extends React.Component<any, any> {
    public state = {
        rejected: [],
        editable: [],
        type: get(this.props.item, 'contribution.action'),
        limited: false,

        image: false,
        originalImage: false,
    };

    public form: any = new Form({
        description: this.props?.item?.description,
    });

    public clearForm(): void {
        this.form.clearForm();
    }

    public async componentDidMount(): Promise<void> {
        await this.fetchImage();
    }

    public componentDidUpdate(prevProps) {
        if (this.props.editAll !== prevProps.editAll) {
            this.handleEdit();
        }
    }

    public async fetchImage() {
        try {
            const action = get(this.props.item, 'contribution.action');
            let originalImage = false;
            let image = false;

            if (action !== 'CREATE') {
                originalImage = await RequestManager.get(`/free/files/${get(this.props.item, 'entityAid')}/info`);
            }

            if (action !== 'DELETE') {
                image = await RequestManager.get(`/free/files/contributions/${get(this.props.item, 'id')}/info`);
            }

            this.setState({ image: image, originalImage: originalImage });
        } catch (err) {
            //
        }
    }

    public renderEmpty(): React.ReactElement {
        return (
            <div className="display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2">
                <p className="palette--c-neutral-5 m-0">Nothing changed.</p>
            </div>
        );
    }

    public render(): React.ReactElement {
        const imageList = imageFields(this);

        const action = get(this.props.item, 'contribution.action');

        if (!action) {
            return this.renderEmpty();
        }

        return (
            <div className="ProviderContent w-100 pl-4 pr-0">
                {imageList.map((item: any, index: number) => {
                    return this.renderFields(item, index);
                })}
            </div>
        );
    }

    public renderFields(field: any, index): React.ReactElement {
        const rejected: any = get(this.state, 'rejected', []);
        const editable: any = get(this.state, 'editable', []);

        const id = get(this.props.item, 'id');

        const wrapperClassName = arrayToClass([
            rejected.includes(id)
                ? 'palette--bgc-neutral-3'
                : `palette--bgc-${
                      get(this.props.item, 'contribution.action') === 'UPDATE'
                          ? 'yellow-1'
                          : get(this.props.item, 'contribution.action') === 'CREATE'
                          ? 'green-1'
                          : 'red-1'
                  }`,
            'border-radius-1 my-1 p-2',
            'display-flex align-items-center',
        ]);

        const element = {
            type: field.type,
            content: this.getData(field.type),
            component: field.component,
        };

        return (
			<WidgetContributionPresenter
				isAdmin={this.props.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(field.type)}
				toValue={element}
				renderValue={(value, isNew) => this.renderFile(value, isNew)}
				isEditable={editable.includes(id)}
				isRejected={rejected.includes(id)}
				handleEdit={() => this.handleEdit(id)}
				missEdit={() => this.handleMissEdit(id)}
				handleReject={() => this.handleReject(id)}
				missReject={() => this.handleMissReject(id)}
				className={wrapperClassName}
			/>
		);
    }

    public renderFile(value, isNew = false) {
        const image = get(this.state.image, 'thumbnail');
        const originalImage = get(this.state.originalImage, 'thumbnail');
        const action = get(this.props.item, 'contribution.action');
        const isShow = isNew ? action !== 'DELETE' : action !== 'CREATE';

        if (!isShow) {
            return <div className="CurrentData Bottom" />;
        }

        return (
            <div className="CurrentData display-flex flex-column align-items-center Bottom">
                {!!(image || originalImage) && (
                    <div className="Thumbnail">
                        <img src={isNew ? image : originalImage} alt="provider-file"/>
                    </div>
                )}
                <p className="w-100 Bottom palette--c-neutral-6 m-0 ImageDescription">{value}</p>
            </div>
        );
    }

    public getData(field): any {
        const form = this.form.generateJSON();
        const value = get(form, field, null);

        if (this.state.type === 'DELETE') {
            return null;
        }

        if (field.includes('valid')) {
            return get(form, field);
        }

        return getData(field, value, true, []);
    }

    public getOriginalData(field): any {
        const value = get(this.props.original, field, null);

        if (get(this.props.item, 'contribution.action') === 'CREATE') {
            return null;
        }

        if (field.includes('valid')) {
            return value;
        }

        return getData(field, value, false, []);
    }

    public async handleEdit(type: string = 'editAll'): Promise<void> {
        const editable: any = get(this.state, 'editable', []);

        if (type === 'editAll') {
            const block = imageFields(this).map((item: any) => item.type);
            await this.setState({ editable: [...block] });
        }

        if (!editable.includes(type)) {
            await this.setState({ editable: [...this.state.editable, type] });
        }
    }

    public async handleMissEdit(type: string): Promise<void> {
        const editable: any = get(this.state, 'editable', []);

        if (editable.includes(type)) {
            const editableArray = this.state.editable.filter((item) => item !== type);
            await this.setState({ editable: editableArray });
        }
    }

    public handleReject(type: string): void {
        const rejected: any = get(this.state, 'rejected', []);

        if (!rejected.includes(type)) {
            this.setState({ rejected: [...this.state.rejected, type] });
        }
    }

    public handleMissReject(type: string) {
        const rejected: any = get(this.state, 'rejected', []);

        if (rejected.includes(type)) {
            const rejectedArray = this.state.rejected.filter((item) => item !== type);
            this.setState({ rejected: rejectedArray });
        }
    }

    public getContribution() {
        const original = get(this.props, 'item');
        const form = this.form.generateJSON();

        const isRejected = this.state.rejected.find((item: any) => item === original.id);
        const isChanged = get(original, 'description') !== form.description;

        if (!!isRejected) {
            original.contribution.status = 'REJECTED';
        }

        if (!!isChanged) {
            original.description = form.description;
        }

        return original || null;
    }
}
