import { get } from 'lodash';
import * as React from 'react';
import { arrayToClass } from '../../../../utilities/helper-fuctions';
import { Tooltip } from '../../../ui-components/popover/popover';
import { Widget } from '../../widget';

export class CountryDetails extends Widget<any, any> {
    public state = {
        ...this.state,
        hovered: false,
    };

    public renderContent(): React.ReactElement {
        const country = get(this.props, 'country');

        return (
            <div className="CountryDetails w-100 p-2">
                <div className="display-flex flex-column flex-md-row">
                    <h2 className="palette--c-neutral-6 fw-bold mb-2 flex-fill">{get(country, 'name', '')}</h2>
                    {this.renderCity()}
                </div>
                {this.renderCodes()}
            </div>
        );
    }

    public renderCodes() {
        const iso2 = get(this.props, 'country.iso2', false);
        const iso3 = get(this.props, 'country.iso3', false);
        const isoNumeric = get(this.props, 'country.isoNumeric', false);
        const localIdentifier = get(this.props, 'country.localIdentifierName', false);

        if (!iso2 && !iso3 && !isoNumeric && !localIdentifier) {
            return null;
        }

        return (
            <div className="Codes display-flex mb-2">
                {iso2 && <Tooltip tooltip={this.renderPopover('iso2')} trigger={this.renderPopoverChildren(iso2)} />}
                {iso2 && (iso3 || isoNumeric) && <h4 className="palette--c-neutral-5 px-1 m-0 fw-bold">|</h4>}
                {iso3 && <Tooltip tooltip={this.renderPopover('iso3')} trigger={this.renderPopoverChildren(iso3)} />}
                {iso3 && (isoNumeric || localIdentifier) && (
                    <h4 className="palette--c-neutral-5 px-1 m-0 fw-bold">|</h4>
                )}
                {isoNumeric && (
                    <Tooltip
                        tooltip={this.renderPopover('iso numeric')}
                        trigger={this.renderPopoverChildren(isoNumeric)}
                    />
                )}
                {isoNumeric && localIdentifier && <h4 className="palette--c-neutral-5 px-1 m-0 fw-bold">|</h4>}
                {localIdentifier && (
                    <Tooltip
                        tooltip={this.renderPopover('Local Identifier Name')}
                        trigger={this.renderPopoverChildren(localIdentifier)}
                    />
                )}
            </div>
        );
    }

    public renderPopover(description: any): any {
        return (
            <div className="PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1">
                <span className="palette--c-neutral-1">{description}</span>
            </div>
        );
    }

    public renderPopoverChildren(code: string) {
        return (
            <div>
                <h4 className="palette--c-neutral-5 m-0 fw-bold">{code}</h4>
            </div>
        );
    }

    public renderCity(): any {
        const country = get(this.props, 'country');
        const name = get(country, 'name');
        const isoCode = get(country, 'iso2');

        if (!isoCode) {
            return null;
        }

        const flagClasses = arrayToClass([
            'flag-map-marker',
            isoCode ? `fi fi-${isoCode.toLowerCase()}` : 'display-none',
            'mr-2',
        ]);

        return (
            <div className="display-flex align-items-center pb-4 pb-sm-0">
                <span className={flagClasses} />
                {name && <p className="palette--c-neutral-5 m-0">{name}</p>}
            </div>
        );
    }

    public createCodes(code) {
        return code ? code : '';
    }
}
