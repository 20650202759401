import * as React from 'react';
import { arrayToClass } from '../../../../../../utilities/helper-fuctions';
import { Form } from '../../../../../ui-components/form/form';
import { formatOptions } from '../../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../../ui-components/inputs/select/select';
import { SwitchSelect } from '../../../../../ui-components/inputs/select/switch-select';
import { availabilityInfos } from '../../../../../../utilities/widget-helpers/availability-infos';
import './additions.scss';
import { afis } from '../../opening-hours-contribution/constants';

export class AtcAdditions extends React.Component<any, any> {
    public form = new Form({
        enRtfAvailable: this.props?.values?.enRtfAvailable,
        afisOnly: this.props?.values?.afisOnly,
    });

    public render(): React.ReactElement {
        const form = this.props.form ? this.props.form : this.form;

        const className = arrayToClass([
            'Additions palette--bc-neutral-3 border-1 border-radius-1 palette--bgc-neutral-1',
            !this.props.open ? 'closedAdds' : '',
        ]);

        return (
            <div className={className} style={{ maxHeight: !this.props.open ? '0px' : '300px' }}>
                <div className="p-4 FormContainer">
                    <SwitchSelect
                        classes="py-2"
                        field={form.getField('enRtfAvailable')}
                        labelInfos={{
                            label: 'English-speaking operators are available',
                            info: availabilityInfos.atc.enRtfAvailable,
                            classes: 'pb-2',
                        }}
                    />
                    <Select
                        classes="py-2 left-m"
                        field={form.getField('afisOnly')}
                        options={formatOptions(afis)}
                        labelInfos={{
                            label: 'What level of service is provided?',
                            info: availabilityInfos.atc.afisOnly,
                        }}
                    />
                </div>
            </div>
        );
    }

    public getForm(): any {
        return this.form.generateJSON();
    }
}
