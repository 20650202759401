import { get } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { DetailsActions } from '../../actions/details.actions';
import { CountryAirports } from '../../components/sidebars/country-airports/country-airports';
import { NearbyAirports } from '../../components/sidebars/nearby-airports/nearby-airports';
import { arrayToClass } from '../../utilities/helper-fuctions';
import { logoLink } from '../../utilities/variables';
import './details.layout.scss';

export const DetailsLayout = (Component) => (props) => {
    const params = useParams();
    const navigate = useNavigate();

    const open = useSelector((state: any) => state.details.open);
    const openMobile = useSelector((state: any) => state.details.openMobile);
    const dispatch = useDispatch();
    const isCountry = get(props, 'identifier') === 'countries';

    const classes = arrayToClass([
        'DetailsLayout default',
        open ? 'open' : 'closed',
        openMobile ? 'open-mobile' : 'closed-mobile',
    ]);

    const detailsClasses = arrayToClass(['DetailsContentContainer palette--bgc-neutral-3']);

    const handleToMain = () => {
        dispatch(DetailsActions.setOpen(false));
        dispatch(DetailsActions.setOpenMobile(false));
        navigate('/');
    };

    const renderToggle = () => {
        const isCountry = get(props, 'identifier') === 'countries';

        const classes = arrayToClass([
            'Resize-maps NearbyAirportsToggle elevation-2 palette--bc-neutral-4 border-1',
            'display-none display-lg-block',
            open ? 'Opened' : 'Closed',
            openMobile ? 'OpenedMobile' : 'ClosedMobile',
        ]);

        return (
            <div className={classes} onClick={() => dispatch(DetailsActions.toggleOpen())}>
                <div className="ToggleWrapper">
                    <span className="ToggleIcon material-icons">chevron_left</span>
                    <span className="ToggleTitle">{isCountry ? 'Airport list' : 'Nearby airports'}</span>
                </div>
            </div>
        );
    };

    return (
        <div className={classes}>
            <div className="Sidebar palette--bgc-neutral-2 border-right-1 palette--bc-neutral-4">
                <div className="display-flex w-100">
                    <div className="LinkToHome flex-fill p-3" onClick={() => handleToMain()}>
                        <img src={logoLink} alt="aviowiki" className="AvioLogo" />
                    </div>

                    <div>
                        <div className="display-flex display-lg-none justify-content-end m-3">
                            <span
                                className="CloseSidebarButton material-icons"
                                onClick={() => dispatch(DetailsActions.toggleOpenMobile())}
                            >
                                close
                            </span>
                        </div>
                    </div>
                </div>

                {!isCountry && <NearbyAirports aid={params.aid} />}
                {!!isCountry && <CountryAirports aid={params.iso} />}
            </div>

            <div className={detailsClasses}>
                {renderToggle()}
                <Component {...props} router={{ navigate, params }} />
            </div>
        </div>
    );
};
