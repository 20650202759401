import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { RequestManager } from '../utilities/request';

export abstract class LocalstorageActions {
	public static readonly SET_MODAL_IDENTIFIER =
		'LocalstorageActions[SET_MODAL_IDENTIFIER]';
	public static readonly SET_MODAL_DETAILS =
		'LocalstorageActions[SET_MODAL_DETAILS]';
	public static readonly SET_MEASURE = 'LocalstorageActions[SET_MEASURE]';
	public static readonly SET_RADIUS = 'LocalstorageActions[SET_RADIUS]';
	public static readonly SET_SEARCH = 'LocalstorageActions[SET_SEARCH]';
	public static readonly CHECK_USER = 'LocalstorageActions[CHECK_USER]';
	public static readonly SUNSET_AND_SUNRISE =
		'LocalstorageActions[SUNSET_AND_SUNRISE]';

	public static setModalIdentifier(identifier) {
		return {
			type: LocalstorageActions.SET_MODAL_IDENTIFIER,
			payload: identifier,
		};
	}

	public static setModalDetails(details) {
		return {
			type: LocalstorageActions.SET_MODAL_DETAILS,
			payload: details,
		};
	}

	public static setMeasure(measure) {
		return {
			type: LocalstorageActions.SET_MEASURE,
			payload: measure,
		};
	}

	public static setRadius(radius) {
		return {
			type: LocalstorageActions.SET_RADIUS,
			payload: radius,
		};
	}

	public static setSearch(search) {
		return {
			type: LocalstorageActions.SET_SEARCH,
			payload: search,
		};
	}

	public static setSunsetAndSunrise(response) {
		return {
			type: LocalstorageActions.SUNSET_AND_SUNRISE,
			payload: response,
		};
	}

	public static fetchNewSun(aid, date) {
		return async (dispatch: (action: any) => void) => {
			try {
				const response = await RequestManager.get(
					`/airports/${aid}/sun`,
					{
						date: format(new Date(date), 'yyyy-MM-dd'),
						local: true,
					}
				);

				if (!response) {
					return;
				}

				dispatch(this.setSunsetAndSunrise(response));
			} catch (err: any) {
				toast.error(
					err?.response?.data?.message ||
						err.message ||
						err ||
						'Error',
					{
						theme: 'light',
					}
				);
			}
		};
	}
}
