import { Modal } from '../../ui-components/modal/modal';
import { randomId } from '../../../utilities/helper-fuctions';
import { get } from 'lodash';
import * as React from 'react';
import { HoverableButton } from '../../ui-components/hoverable-buttons/hoverable-buttons';

export class ImagesWarningModal extends React.Component<any, any> {
    public modal$: Modal;

    public componentDidMount() {
        if (!!this.props.isOpen) {
            this.open();
        } else {
            this.close();
        }
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            if (this.props.isOpen) {
                this.open();
            } else {
                this.close();
            }
        }
    }

    public open() {
        this.modal$.open();
    }

    public close() {
        this.modal$.close();

        if (this.props.close) {
            this.props.close();
        }
    }

    public render() {
        return (
            <Modal
                onDismissRequest={() => this.close()}
                ref={(ref: any) => (this.modal$ = ref)}
                title={() => this.renderHeader()}
                content={() => this.renderContent()}
                footer={() => this.renderFooter()}
            />
        );
    }

    public renderHeader() {
        return (
            <div className="w-100 p-4">
                <div className="display-flex flex-wrap">
                    <span className="palette--c-yellow-2 material-icons mr-2">warning</span>
                    <h4 className="palette--c-neutral-6 m-0">Image Uploader</h4>
                </div>
            </div>
        );
    }

    public renderContent() {
        const images = get(this.props, 'images') || [];

        return (
            <div className="w-100 p-4">
                {this.props.message && <p className="palette--c-neutral-5 py-3">{this.props.message}</p>}
                {this.props.renderMessage && this.props.renderMessage()}
                <div className="ImagePreview display-flex flex-wrap">
                    {(images || []).map((image) => this.renderImage(image))}
                </div>
            </div>
        );
    }

    public renderImage(image) {
        return (
            <div key={randomId()} className={'Thumbnail m-1 border-radius-1 overflow-hidden position-relative'}>
                <img src={get(image, 'path')} alt="provider-image" />
            </div>
        );
    }

    public renderFooter(): React.ReactElement {
        return (
            <div className="w-100">
                <div className="display-flex align-items-center justify-content-end p-4">
                    <HoverableButton
                        className="border-radius-1 mr-2"
                        colorType="cancel"
                        title="Cancel"
                        onClick={() => this.close()}
                    />
                </div>
            </div>
        );
    }
}
