import { get } from 'lodash';
import * as React from 'react';
import { HoverableButton } from '../../../ui-components/hoverable-buttons/hoverable-buttons';

export class ContributionFooter extends React.Component<any, any> {
    public render(): React.ReactElement {
        if (this.props.isAdmin) {
            return (
                <div className="Footer w-100 p-4 display-flex justify-content-end">
                    <HoverableButton
                        className="border-radius-1 mr-2"
                        colorType="cancel"
                        onClick={() => this.props.close && this.props.close()}
                        title="Cancel"
                    />
                    <HoverableButton
                        className="border-radius-1 mr-2"
                        colorType="cancel"
                        onClick={() => this.props.handleEdit && this.props.handleEdit()}
                        titleRight="Edit"
                        icon="edit"
                    />
                    <HoverableButton
                        disabled={get(this.props, 'buttonDisabled', false) || false}
                        className="border-radius-1 mr-2"
                        colorType="cancel"
                        onClick={() => this.props.handleButtonClick && this.props.handleButtonClick(true)}
                        icon="block"
                        titleRight="Reject"
                    />
                    <HoverableButton
                        disabled={get(this.props, 'buttonDisabled', false) || false}
                        className="border-radius-1"
                        colorType="avio-green"
                        titleRight="Accept"
                        icon="check"
                        onClick={() => this.props.handleButtonClick && this.props.handleButtonClick()}
                    />
                </div>
            );
        }

        return (
            <div className="Footer w-100 p-4 border-top-1 palette--bc-neutral-3 display-flex justify-content-end">
                <HoverableButton
                    className="border-radius-1 mr-2"
                    colorType="cancel"
                    title="Cancel"
                    onClick={() => this.props.close && this.props.close()}
                />
            </div>
        );
    }
}
