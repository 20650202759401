export const types: any = {
    neutral: {
        bgc: 'secondary-1',
        hbgc: 'secondary-4',
        bc: 'secondary-1',
        hbc: 'secondary-1',
        c: 'secondary-4',
        hc: 'secondary-1',
        oc: 'secondary-4',
        cursor: 'pointer',
    },
    'avio-blue': {
        bgc: 'blue-avio-blue',
        hbgc: 'neutral-1',
        bc: 'blue-avio-blue',
        hbc: 'blue-avio-blue',
        c: 'neutral-1',
        hc: 'blue-avio-blue',
        oc: 'transparent',
        cursor: 'pointer',
    },
    cancel: {
        bgc: 'secondary-1',
        hbgc: 'secondary-4',
        bc: 'secondary-1',
        hbc: 'secondary-1',
        c: 'secondary-4',
        hc: 'secondary-1',
        oc: 'transparent',
        cursor: 'pointer',
    },
    contrast: {
        bgc: 'neutral-1',
        hbgc: 'neutral-6',
        bc: 'transparent',
        hbc: 'transparent',
        c: 'neutral-6',
        hc: 'neutral-1',
        oc: 'transparent',
        cursor: 'pointer',
    },
    'avio-green': {
        bgc: 'primary-4',
        hbgc: 'neutral-1',
        bc: 'primary-4',
        hbc: 'primary-4',
        c: 'neutral-1',
        hc: 'primary-4',
        oc: 'transparent',
        cursor: 'pointer',
    },
    disabled: {
        bgc: 'neutral-1',
        hbgc: 'neutral-1',
        bc: 'neutral-2',
        hbc: 'neutral-2',
        c: 'neutral-4',
        hc: 'neutral-4',
        oc: 'neutral-2',
        cursor: 'pointer',
    },
    'transparent-grey': {
        bgc: 'transparent',
        hbgc: 'transparent',
        bc: 'transparent',
        hbc: 'secondary-4',
        c: 'secondary-4',
        hc: 'secondary-4',
        oc: 'transparent',
        cursor: 'pointer',
    },
    'transparent-green': {
        bgc: 'transparent',
        hbgc: 'transparent',
        bc: 'transparent',
        hbc: 'primary-4',
        c: 'primary-4',
        hc: 'primary-4',
        oc: 'transparent',
        cursor: 'pointer',
    },
    delete: {
        bgc: 'transparent',
        hbgc: 'transparent',
        bc: 'transparent',
        hbc: 'red-2',
        c: 'red-2',
        hc: 'red-2',
        oc: 'transparent',
        cursor: 'pointer',
    },
    'transparent-white': {
        bgc: 'transparent',
        hbgc: 'neutral-1',
        bc: 'neutral-1',
        hbc: 'primary-4',
        c: 'neutral-1',
        hc: 'primary-4',
        oc: 'transparent',
        cursor: 'pointer',
    },
};
