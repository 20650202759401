import * as React from 'react';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { Label } from '../../label/label';
import './checkbox.scss';

export class Checkbox extends React.Component<any, any> {
	public state = {
		value: this.getValue(),
	};

	public getValue() {
		const value = this.props?.field?.newValue;

		if (value === null || value === undefined) {
			this.props?.field.setValue(false);
		}

		return value || false;
	}

	public render() {
		const wrapperClassName = arrayToClass([
			'CheckboxWrapper display-flex align-items-center',
			this.props.classes ? this.props.classes : '',
		]);

		const inputClassNames = arrayToClass([
			this.props.inputClassNames ? this.props.inputClassNames : '',
			'Checkbox pointer',
			this.props?.field?.newValue ? 'checked' : '',
		]);

		return (
			<div className={wrapperClassName}>
				{!this.props.labelOnLeft && this.renderLabel()}
				<div className='CircleWrapper'>
					<label className={inputClassNames}>
						<input
							autoFocus={this.props.autoFocus || false}
							id={this.props.id || ''}
							type='checkbox'
							value={this.state?.value || false}
							onChange={() => this.handleChange()}
						/>
					</label>
				</div>
				{!!this.props.labelOnLeft && this.renderLabel()}
			</div>
		);
	}

	public renderLabel() {
		const label = this.props?.labelInfos;
		const labelClasses = arrayToClass([
			'mb-2',
			label?.classes ? label?.classes : '',
			this.props?.field?.mandatory ? 'asterix' : '',
		]);

		if (!label) {
			return null;
		}

		return (
			<Label
				info={label.info || false}
				label={label.label || false}
				className={labelClasses}
				ignoreTopMargin={label.ignoreTopMargin || false}
				isSimple={label.isSimple || false}
			/>
		);
	}

	public handleChange() {
		if (!this.props.disabled) {
			const newValue = !this.props?.field?.newValue;
			this.setState({ value: newValue });
			this.props?.field.setValue(!this.props?.field?.newValue);
		}
	}
}
