import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { arrayToClass, setTitleAndMeta } from '../../utilities/helper-fuctions';
import './search.layout.scss';

export const SearchLayout = (Component) => (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const layoutClasses = arrayToClass(['SearchLayout overflow-hidden', 'palette--bgc-neutral-3 default']);

    React.useEffect(
        () =>
            setTitleAndMeta(
                'aviowiki',
                'FREE aviation data for everybody. Details about airports, runways, authorities and FBOs.'
            ),
        []
    );

    return (
        <div className={layoutClasses}>
            <Component {...props} router={{ navigate, params, location }} />
        </div>
    );
};
