import * as React from 'react';
import { Link } from 'react-router-dom';
import { arrayToClass, setTitleAndMeta } from '../../utilities/helper-fuctions';
import './relief-wing-app.scss';

const logoWhite = require('../../assets/logo-white.png');
const reliefWing = require('../../assets/relief-wing.png');

export const ReliefWingLayout = (Component) => (props) => {
    const layoutClasses = arrayToClass([
        'ReliefWingLayout',
        'position-relative',
        'palette--bgc-neutral-3',
        'display-flex flex-column default',
    ]);

    const containerClasses = arrayToClass(['Container display-flex flex-column', 'flex-fill', 'p-2 p-md-0']);

    React.useEffect(
        () =>
            setTitleAndMeta(
                'aviowiki',
                'FREE aviation data for everybody. Details about airports, runways, authorities and FBOs.'
            ),
        []
    );

    const renderLogo = (): React.ReactElement => {
        return (
            <Link
                to="/"
                className="ReliefWingLogoContainer display-flex justify-content-start w-100 display-block p-3 pb-2"
            >
                <img src={logoWhite} alt="logo" className="display-block" />
                <div className="flex-fill" />
                <img src={reliefWing} alt="logo" className="ReliefLogo display-block" />
            </Link>
        );
    };

    return (
        <div className={layoutClasses}>
            {renderLogo()}

            <div className={containerClasses}>
                <Component {...props} />
            </div>
        </div>
    );
};
