import $ from 'jquery';
import { get } from 'lodash';
import * as React from 'react';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { Label } from '../../label/label';
import './switch-checkbox.scss';

export class Switch extends React.Component<any, any> {
	public state = {
		focused: false,
		value: this.props.field.newValue || false,
	};

	public componentDidMount() {
		document.addEventListener(
			'focusin',
			(value) => {
				const classes = get(value.target, 'className');

				if (classes.includes('SwitchInput')) {
					this.setState({ focused: true });
				} else if (!!this.state.focused) {
					this.setState({ focused: false });
				}
			},
			true
		);
	}

	public componentWillUnmount() {
		$(document).off('focusin');
	}

	public render() {
		const wrapperClassName = arrayToClass([
			'SwitchCheckbox',
			this.props.classNames ? this.props.classNames : '',
		]);

		const inputClassNames = arrayToClass([
			this.props.inputClassNames ? this.props.inputClassNames : '',
			`border-1 palette--bc-${
				this.state.focused ? 'blue-avio-blue' : 'neutral-4'
			}`,
			'Switch',
			this.props?.disabled ? 'Disabled' : '',
		]);

		return (
			<div className={wrapperClassName}>
				{this.renderLabel()}
				<label className='ToggleSwitch'>
					<input
						type='checkbox'
						checked={this.state.value}
						onChange={() => this.handleChange()}
						className='SwitchInput'
						disabled={this.props?.disabled || false}
					/>
					<span className={inputClassNames} />
				</label>
			</div>
		);
	}

	public renderLabel() {
		const label = this.props?.labelInfos;
		const labelClasses = arrayToClass([
			'mb-2',
			label?.classes ? label?.classes : '',
			this.props?.field?.mandatory ? 'asterix' : '',
		]);

		if (!label) {
			return null;
		}

		return (
			<Label
				info={label.info || false}
				label={label.label || false}
				className={labelClasses}
				ignoreTopMargin={label.ignoreTopMargin || false}
				isSimple={label.isSimple || false}
			/>
		);
	}

	public handleChange() {
		const value = this.state.value;
		this.setState({ value: !value });
		this.props.field.setValue(!value);
	}
}
