import * as React from 'react';
import { toast } from 'react-toastify';
import { PreLoader } from '../../../../../../utilities/pre-loader';
import { productInfos } from '../../../../../../utilities/widget-helpers/product-infos';
import { request } from '../../../../../../utilities/widget-helpers/request';
import { ToastMessages } from '../../../../../notifications/toast-messages';
import { Form } from '../../../../../ui-components/form/form';
import { FormButtons } from '../../../../../ui-components/hoverable-buttons/form-modal-buttons/form-buttons';
import { Input } from '../../../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../../ui-components/inputs/select/select';
import { SwitchSelect } from '../../../../../ui-components/inputs/select/switch-select';
import { WidgetModal } from '../../../../widget-modal';
import {
	CURRENCIES,
	PROVIDER_PRODUCT_UNITS,
} from '../../provider-contribution/constants';

class GenericProductFormComponent extends WidgetModal<any, any> {
	public state: any = {
		countries: [],
		governingDistricts: [],
		percentage: 0,
		buttonDisabled: true,
	};

	public form: any = new Form(
		{
			name: { value: this.props?.response?.name || '', mandatory: true },
			description: this.props?.response?.description || '',
			price: {
				value: this.props?.response?.price || '',
				mandatory: true,
			},
			currency: {
				value: this.props?.response?.currency || '',
				mandatory: true,
			},
			unit: { value: this.props?.response?.unit || '', mandatory: true },
			quantity: {
				value: this.props?.response?.quantity || '',
				mandatory: true,
			},
			taxInclusive: {
				value: this.props?.response?.taxInclusive || '',
				mandatory: true,
			},
		},
		(changed) => this.setState({ buttonDisabled: !changed })
	);

	public getTitle() {
		return this.props?.isEdit
			? 'Edit generic product'
			: 'Create new generic product';
	}

	public renderForm(): React.ReactElement {
		return (
			<form className='p-4'>
				<TextInput
					autoFocus={true}
					id='genericProductFirst'
					field={this.form.getField('name')}
					classes='py-2'
					textType='camel'
					labelInfos={{ label: 'Name' }}
				/>

				<TextInput
					field={this.form.getField('description')}
					classes='py-2'
					textType='sentences'
					labelInfos={{ label: 'Description' }}
				/>

				<div className='FormContainer'>
					<Input
						inputType='number'
						field={this.form.getField('price')}
						classes='py-2'
						labelInfos={{
							label: 'Price',
							info: productInfos.price,
						}}
					/>
					<Select
						field={this.form.getField('currency')}
						options={formatOptions(CURRENCIES, false)}
						classes='left-m py-2'
						labelInfos={{
							label: 'Currency',
						}}
					/>
				</div>

				<div className='FormContainer'>
					<Select
						labelInfos={{
							label: 'Unit',
						}}
						classes='py-2'
						field={this.form.getField('unit')}
						options={formatOptions(PROVIDER_PRODUCT_UNITS, false)}
					/>
					<SwitchSelect
						field={this.form.getField('taxInclusive')}
						classes='py-2 left-m'
						labelInfos={{
							classes: 'mb-2',
							info: productInfos.taxInclusive,
							label: 'Tax',
						}}
					/>
				</div>

				<Input
					field={this.form.getField('quantity')}
					inputType='number'
					classes='py-2 w-50'
					labelInfos={{
						label: 'Quantity',
						info: productInfos.quantity,
					}}
				/>
			</form>
		);
	}

	public renderFooter() {
		return (
			<FormButtons
				isDelete={this.props?.isEdit}
				onDelete={() => this.deleteModal$.open()}
				saveDisabled={this.state?.buttonDisabled}
				onSave={() => this.handleSave()}
				onClose={() => this.onClose()}
			/>
		);
	}

	public getType() {
		return 'product';
	}

	public onClose(): void {
		this.form.clearForm();

		this.props.onClose();
		this.modal$.close();
	}

	public async onDelete(): Promise<void> {
		this.deleteModal$.close();
		const aid = this.props?.aid;

		if (!aid) {
			return;
		}

		this.setState({ buttonDisabled: true });

		await request(
			'/providerproducts/merge',
			'products',
			() => this.handleSuccessModal(),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		).deleteF(
			`/providerproducts/${aid}`,
			this.form?.getFieldsWhereHasValue()
		);

		this.setState({ buttonDisabled: false });
	}

	public async handleSave(): Promise<void> {
		this.setState({ buttonDisabled: true });
		const aid = this.props?.aid;
		const generalData = this.form.generateJSON();

		const req = request(
			'/providerproducts/merge',
			'products',
			() => this.handleSuccessModal(),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		);

		const changes = this.form?.getDifferences();

		if (this.props?.isEdit) {
			await req.edit(`/providerproducts/${aid}`, generalData, changes);
		} else {
			await req.create('/providerproducts', generalData, changes, {
				parentAid: aid,
			});
		}

		this.setState({ buttonDisabled: false });
	}

	public handleSuccessModal() {
		if (this.notif$) {
			this.notif$.open();
		}

		this.modal$.close();
	}
}

export const GenericProductForm: any = PreLoader(GenericProductFormComponent);
