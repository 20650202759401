import * as React from 'react';
import { arrayToClass, randomId } from '../../utilities/helper-fuctions';

const regexpWeb =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/gi;
const regexEmail =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;

export class TextRenderer extends React.Component<any, any> {
    public render() {
        const originalText = this.props?.text || '';
        const saveEnters = originalText.replace(/\n/gi, ' \n ').replace(/,/gi, ' ,');
        const text = saveEnters.split(' ');

        const classes = arrayToClass(['text-justify', this.props?.classNames || '']);

        return (
            <div className={classes}>
                <p className="Text text-justify palette--c-neutral-6 m-0 mr-2">
                    {text.map((line) => {
                        if (line === '\n') {
                            return <br key={randomId()}></br>;
                        }

                        if (line.match(regexpWeb) || line.match(regexEmail)) {
                            return this.renderLinks(line, line.match(regexEmail));
                        }

                        return `${line} `;
                    })}
                </p>
            </div>
        );
    }

    public renderLinks(original, isEmail) {
        const lineWithLinks = original.replace(isEmail ? regexEmail : regexpWeb, '/link$&/link');

        return lineWithLinks.split('/link').map((line: any) => {
            if (line.length === 0) {
                return;
            }

            if (line.match(regexpWeb) || line.match(regexEmail)) {
                let link = line;

                if (line.charAt(line.length - 1) === '.') {
                    link = line.slice(0, line.length - 1);
                }

                if (!link.match(/(http)[s]?/g) && !isEmail) {
                    link = `https://${link}`;
                }

                if (isEmail) {
                    link = `mailto:${link}`;
                }

                return (
                    <a
                        href={link}
                        target="_blank"
                        key={randomId()}
                        className="Text Link WordBreak text-justify palette--c-neutral-5 m-0 text-decoration-none mr-2"
                    >
                        {line}
                    </a>
                );
            }

            return line;
        });
    }
}
