import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { UserDetailsActions } from '../../actions/user-details.actions';
import { UserActions } from '../../actions/user.actions';
import { HoverableButton } from '../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { MainTab } from '../../components/ui-components/tab/main-tab';
// import { MainSubscription } from '../../components/user-profile/subscriptions/main-subscription';
import { ApiToken } from '../../components/user-profile/tokens/api-token';
import { UserDetailsSettings } from '../../components/user-profile/user-details-settings';
import { UserSettings } from '../../components/user-profile/user-settings/user-settings';
import { WebhooksSubscriptions } from '../../components/user-profile/webhooks/webhooks';
import { LandingLayout } from '../../layouts/landing/landing.layout';
import { isInQuery } from '../../utilities/helper-fuctions';
import { LocalStorage } from '../../utilities/local-storage';
import './user-profile.scss';

const tabs = [
	{
		title: 'General',
		type: 'general',
	},
	{
		title: 'Settings',
		type: 'settings',
	},
	{
		title: 'API tokens',
		type: 'tokens',
	},
	// {
	// 	title: 'Subscriptions',
	// 	type: 'subscriptions',
	// },
];

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
	checkAuthentication: () => dispatch(UserActions.checkAuthentication()),
	saveUserDetails: (data: any) =>
		dispatch(UserDetailsActions.saveUserDetails(data)),
});

class UserProfileComponent extends React.Component<any, any> {
	public componentDidMount() {
		if (!this.props.user) {
			this.navigate('/user/settings');
		}
	}

	public render(): React.ReactElement {
		const type = this.props?.router?.params?.userType || false;
		const user = this.props.user;
		const hasWHRole = (get(user, 'roles') || []).includes('WEBHOOK');

		const useableTabs = hasWHRole
			? [...tabs, { title: 'Webhooks', type: 'webhooks' }]
			: tabs;

		return (
			<div className='EditUserForm w-100 display-flex justify-content-center'>
				<div className='EditUserContainer w-100 palette--bgc-neutral-1 border-radius-1 elevation-1'>
					<div className='p-4 border-bottom-1 palette--bc-neutral-3 display-flex align-items-center'>
						<h2 className='palette--c-neutral-6 m-0 flex-fill'>
							{user ? 'User Profile' : 'Settings'}
						</h2>
						<HoverableButton
							title='Back'
							colorType='cancel'
							type='button'
							onClick={() => this.handleGoBack()}
						/>
					</div>

					{!!user && (
						<MainTab
							items={useableTabs}
							onItemClick={(item) =>
								this.navigate(`/user/${item.type}`)
							}
							selected={type}
						/>
					)}

					<div className='p-4'>
						{(type === 'general' || !type) && !!user && (
							<UserSettings
								user={this.props.user}
								close={() => this.navigate('/')}
							/>
						)}
						{(type === 'settings' || !user) && (
							<UserDetailsSettings
								onClose={() => this.navigate('/')}
								endpoint={`/userPreferences/${user?.aid}`}
								isEdit={user ? true : false}
							/>
						)}
						{type === 'tokens' && (
							<ApiToken
								user={this.props.user}
								close={() => this.navigate('/')}
							/>
						)}
						{type === 'webhooks' && (
							<WebhooksSubscriptions
								user={this.props.user}
								close={() => this.navigate('/')}
							/>
						)}
						{/* {type === 'subscriptions' && (
							<MainSubscription
								endpoint={`/pricing`}
								isEdit={true}
								user={this.props.user}
								close={() => this.navigate('/')}
							/>
						)} */}
					</div>
				</div>
			</div>
		);
	}

	public handleGoBack() {
		const link = LocalStorage.getItem('redirectLink');

		if (!!link) {
			this.navigate(link, true);
		} else {
			this.navigate('/');
		}
	}

	public navigate(path, utmNull = false) {
		const utmSource = isInQuery('utm_source') || '';
		this.props?.router?.navigate({
			pathname: path,
			search: utmNull ? '' : utmSource,
		});
	}
}

export const UserProfile: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(LandingLayout(UserProfileComponent));
