import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../../actions/details.actions';
import { connectNLP } from '../../../../utilities/connect-navigate';
import { AuthorityCard } from '../../shared/authority/authority-card';
import { ContributeButton } from '../../../other/contribute-button';
import { Widget } from '../../widget';
import { isInQuery } from '../../../../utilities/helper-fuctions';

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
    setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

class AuthoritiesComponent extends Widget<any, any> {
    public getEndpoint(): any {
        return `/airports/${get(this.props.airport, 'aid')}/airportAuthorities/all`;
    }

    public renderContent(): React.ReactElement {
        const authoritiesList = get(this.state, 'response', null);

        if (!authoritiesList || !(authoritiesList || []).length) {
            return (
                <div className="w-100 display-flex justify-content-center py-6">
                    <ContributeButton
                        openModal={() => this.handleNavigate(this.props?.airport?.aid)}
                        buttonOnly={true}
                    />
                </div>
            );
        }

        return (
            <div className="GeneralDataWidget w-100 p-2">
                {authoritiesList.map((authority, index) => (
                    <AuthorityCard
                        key={`${get(authority, 'name')} - ${index}`}
                        authority={authority}
                        className="border-1"
                    />
                ))}
            </div>
        );
    }

    public handleNavigate(aid) {
        const utmSource = isInQuery('utm_source') || '';
        this.props?.router?.navigate({ pathname: `/airports/${aid}/authorities`, search: utmSource });
    }
}

export const Authorities: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    connectNLP(AuthoritiesComponent)
);
