import * as React from 'react';
import { connect } from 'react-redux';
import { SettingsActions } from '../../actions/settings.actions';
import { UserDetailsActions } from '../../actions/user-details.actions';
import { PreLoader } from '../../utilities/pre-loader';
import { Form } from '../ui-components/form/form';
import { FormButtons } from '../ui-components/hoverable-buttons/form-modal-buttons/form-buttons';
import { formatOptions } from '../ui-components/inputs/select/formatter';
import { Select } from '../ui-components/inputs/select/select';
import { Switch } from '../ui-components/inputs/checkboxes/switch-checkbox/switch-checkbox';

const dateFormats = [
	{ title: 'yyyy. MM. DD.', value: 'yyyy. MM. dd.' },
	{ title: 'yyyy. MMM. DD.', value: 'yyyy. MMM. dd.' },
	{ title: 'DD. MM. yyyy.', value: 'dd. MM. yyyy.' },
	{ title: 'DD. MMM. yyyy.', value: 'dd. MMM. yyyy.' },
];

const days = [{ value: 'Monday' }, { value: 'Sunday' }];

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
	setFormat: (data: any) => dispatch(SettingsActions.setFormat(data)),
	setMeasures: (data: any) => dispatch(SettingsActions.setMeasures(data)),
	saveUserDetails: (data: any) =>
		dispatch(UserDetailsActions.saveUserDetails(data, true)),
	getUserDetails: () => dispatch(UserDetailsActions.dispatchUserDetails()),
});

class UserDetailsSettingsComponent extends React.Component<any, any> {
	public state = {
		data: this.getUserDetails(),
	};

	public form = new Form({
		dateFormat: this.getDate(this.state?.data?.dateFormat),
		hourFormat: this.getHour(this.state?.data?.dateFormat),
		startOfTheWeek: this.state?.data?.startOfTheWeek || days[0].value,
		imperial: !this.state?.data?.metric || false,
		decimalCoordinates: this.state?.data?.decimalCoordinates || false,
		mapNewTab: this.state?.data?.mapNewTab || false,
	});

	public getUserDetails() {
		if (this.props?.response?.preferences) {
			return JSON.parse(this.props?.response?.preferences);
		} else {
			const details = this.props?.getUserDetails();
			return details;
		}
	}

	public getHour(dateFormat) {
		if (dateFormat) {
			const hour = (dateFormat || '').match(/(hh:mm)( a|)/gi)[0];

			return hour !== 'HH:mm';
		}

		return false;
	}

	public getDate(dateFormat) {
		if (dateFormat) {
			const date = (dateFormat || '')
				.replace(/(hh:mm)( a|)/gi, '')
				.trim();

			return date;
		}

		return dateFormats[0].value;
	}

	public getData() {
		const form: any = this.form.generateJSON();

		return {
			dateFormat: `${form.dateFormat || dateFormats[0]} ${
				form.hourFormat ? 'hh:mm a' : 'HH:mm'
			}`,
			hourFormat: form.hourFormat ? 'hh:mm a' : 'HH:mm',
			startOfTheWeek: form.startOfTheWeek,
			metric: !form?.imperial,
			decimalCoordinates: form?.decimalCoordinates,
			mapNewTab: form?.mapNewTab,
		};
	}

	public render(): React.ReactElement {
		return (
			<div className='w-100'>
				<div className='py-4 w-100'>
					<h6 className='fw-bold text-uppercase palette--c-neutral-5 mb-4'>
						Custom settings
					</h6>

					{this.renderForm()}
				</div>
				<FormButtons
					onSave={() => this.handleSave()}
					onClose={() => this.handleClose()}
				/>
			</div>
		);
	}

	public renderForm() {
		return (
			<div>
				<div className='row display-flex align-items-center mb-4'>
					<p className='col-10 m-0 palette--c-secondary-4 m-0 fw-bold'>
						Display imperial units
					</p>

					<Switch
						field={this.form.getField('imperial')}
						classNames='col-14'
					/>
				</div>
				<div className='w-100'>
					<div className='row display-flex align-items-center mb-4'>
						<p className='col-10 palette--c-secondary-4 m-0 fw-bold'>
							Date Format
						</p>

						<div className='col-14'>
							<Select
								field={this.form.getField('dateFormat')}
								options={dateFormats}
							/>
						</div>
					</div>
					<div className='row display-flex align-items-center mb-4'>
						<p className='col-10 palette--c-secondary-4 m-0 fw-bold'>
							12h format
						</p>

						<Switch
							field={this.form.getField('hourFormat')}
							classNames='col-14'
						/>
					</div>
					<div className='row display-flex align-items-center mb-4'>
						<p className='col-10 palette--c-secondary-4 m-0 fw-bold'>
							First Day Of the Week
						</p>

						<div className='col-14'>
							<Select
								field={this.form.getField('startOfTheWeek')}
								options={formatOptions(days, false)}
							/>
						</div>
					</div>
					<div className='row display-flex align-items-center mb-4'>
						<p className='col-10 palette--c-secondary-4 m-0 fw-bold'>
							Use decimal coordinates
						</p>

						<Switch
							field={this.form.getField('decimalCoordinates')}
							classNames='col-14'
						/>
					</div>
					<div className='row display-flex align-items-center mb-4'>
						<p className='col-10 palette--c-secondary-4 m-0 fw-bold'>
							Open map marker on new tab
						</p>

						<Switch
							field={this.form.getField('mapNewTab')}
							classNames='col-14'
						/>
					</div>
				</div>
			</div>
		);
	}

	public handleClose() {
		if (this.props.onClose) this.props.onClose();
	}

	public async handleSave() {
		if (this.props.saveUserDetails) {
			const data = this.getData();

			this.props.saveUserDetails(data);
		}
	}
}

export const UserDetailsSettings: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(PreLoader(UserDetailsSettingsComponent));
