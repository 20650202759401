import * as React from 'react';
import { DateTimePicker } from '../../../../../../ui-components/inputs/date-picker/date-time-picker';
import { Input } from '../../../../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../../../ui-components/inputs/select/select';
import { BlocksContribution } from '../../../../opening-hours/opening-hours-contribution/blocks-contribution';
import { ProviderAvailabilityContribution } from './provider-availability-contribution';

export const providersAvailabilityFields = (elmnt: ProviderAvailabilityContribution) => [
    {
        type: 'validFrom',
        title: 'Valid from',
        component: (
            <DateTimePicker
                field={elmnt.form.getField('validFrom')}
                inputClassName="palette--c-neutral-6"
                classes="NewData"
            />
        ),
    },
    {
        type: 'validTo',
        title: 'Valid to',
        component: (
            <DateTimePicker
                field={elmnt.form.getField('validTo')}
                inputClassName="palette--c-neutral-6"
                classes="NewData"
            />
        ),
    },
    {
        title: 'ScheduleUnits',
        type: 'scheduleUnits',
        component: (
            <Select classes="NewData" field={elmnt.form.getField('scheduleUnits')} options={formatOptions(days, false)} />
        ),
    },
];

export const blockFields = (elmnt: BlocksContribution) => [
    {
        title: 'Valid from',
        type: 'validFrom',
        component: <Input field={elmnt.form.getField('validFrom')} classes="NewData" />,
    },
    {
        title: 'Valid from',
        type: 'validFromSunriseOffset',
        component: <Input field={elmnt.form.getField('validFromSunriseOffset')} classes="NewData" />,
    },
    {
        title: 'Valid from',
        type: 'validFromSunsetOffset',
        component: <Input field={elmnt.form.getField('validFromSunsetOffset')} classes="NewData" />,
    },
    {
        title: 'Valid to',
        type: 'validToSunriseOffset',
        component: <Input field={elmnt.form.getField('validToSunriseOffset')} classes="NewData" />,
    },
    {
        title: 'Valid to',
        type: 'validToSunsetOffset',
        component: <Input field={elmnt.form.getField('validToSunsetOffset')} classes="NewData" />,
    },
    {
        title: 'Valid to',
        type: 'validTo',
        component: <Input field={elmnt.form.getField('validTo')} classes="NewData" />,
    },
    {
        title: 'Status',
        type: 'status',
        component: (
            <Select
                classes="NewData"
                field={elmnt.form.getField('status')}
                options={formatOptions(statusOpts, false)}
            />
        ),
    },
    {
        title: 'Notes',
        type: 'notes',
        component: <TextInput textType="sentences" classes="NewData" field={elmnt.form.getField('notes')} />,
    },
];

export const statusOpts = [
    { value: 'FULL', title: 'Full' },
    { value: 'LIMITED', title: 'Limited' },
    { value: 'CLOSED', title: 'Closed' },
];

export const days = [
    { title: 'Monday', value: 'MONDAY' },
    { title: 'Tuesday', value: 'TUESDAY' },
    { title: 'Wednesday', value: 'WEDNESDAY' },
    { title: 'Thursday', value: 'THURSDAY' },
    { title: 'Friday', value: 'FRIDAY' },
    { title: 'Saturday', value: 'SATURDAY' },
    { title: 'Sunday', value: 'SUNDAY' },
    { title: 'Holiday', value: 'HOLIDAY' },
];
