import * as React from 'react';
import { HoverableButton } from '../../ui-components/hoverable-buttons/hoverable-buttons';
import { EmailPreferences } from './email-preferences';
import { GeneralUserData } from './general-user-data';

export class UserSettings extends React.Component<any, any> {
    public general$;
    public email$;

    public render(): React.ReactElement {
        return (
            <div className="w-100">
                <GeneralUserData ref={(ref: any) => (this.general$ = ref)} user={this.props.user} />
                <EmailPreferences
                    isEdit={true}
                    innerRef={(ref: any) => (this.email$ = ref)}
                    endpoint={`/mailPreferences/${this.props?.user?.aid}`}
                    user={this.props.user}
                />

                <div className="display-flex w-100 justify-content-end pt-4">
                    <HoverableButton
                        title="Back"
                        colorType="cancel"
                        type="button"
                        onClick={() => this.props.close && this.props.close()}
                    />
                    <HoverableButton
                        id="editUserLast"
                        className="border-radius-1 ml-2 elevation-1"
                        colorType="avio-green"
                        title="Save"
                        onClick={(ev: any) => this.handleSave()}
                    />
                </div>
            </div>
        );
    }

    public async handleSave(): Promise<any> {
        await this.general$.handlePassword();
        await this.email$.handleEmailPreferences();
    }
}
