import { get } from 'lodash';
import * as React from 'react';
import { MainTab } from '../../../../../components/ui-components/tab/main-tab';
import { connectNLP } from '../../../../../utilities/connect-navigate';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { AuthorityContactsContributions } from './authority-contacts.contributions';
import { SubAuthoritiesContributions } from './sub-authorities.contributions';

const tabData = [
	{
		title: 'Authorities',
		type: 'authorities',
	},
	{
		title: 'Contacts',
		type: 'contacts',
	},
];

class AuthorityContributionsComponent extends React.Component<any, any> {
	public state = {
		authorities: this.props?.total?.authority,
		contacts: this.props?.total?.contact,
	};

	public render(): React.ReactElement {
		const type = get(this.props, 'subType', false);

		const tabs = tabData.map((tab) => {
			return {
				...tab,
				total: this.state?.[tab.type],
			};
		});

		const classes = arrayToClass([
			'w-100',
			this.props.show ? 'SubContainer' : '',
		]);

		return (
			<div className={classes}>
				{this.props.show && (
					<MainTab
						items={tabs}
						onItemClick={(item) => this.handleClick(item)}
						selected={type}
					/>
				)}

				<SubAuthoritiesContributions
					isAirport={this.props.isAirport}
					system={this.props.system}
					show={this.props.show && (type === 'authorities' || !type)}
					onTotalChange={(total) =>
						this.handleChange('authority', total)
					}
					total={this.props?.total?.authority}
				/>
				<AuthorityContactsContributions
					isAirport={this.props.isAirport}
					system={this.props.system}
					show={this.props.show && type === 'contacts'}
					onTotalChange={(total) =>
						this.handleChange('contacts', total)
					}
					total={this.props?.total?.contact}
				/>
			</div>
		);
	}

	public handleClick(item): any {
		if (this.props.isAdmin || this.props.system) {
			this.props?.router?.navigate(
				`/admin/${
					this.props.system ? 'system-' : ''
				}contributions/authorities/${
					this.props.isAirport ? 'airport' : 'country'
				}authorities/${item.type}`
			);
		} else {
			this.props?.router?.navigate(
				`/contributions/authorities/${
					this.props.isAirport ? 'airport' : 'country'
				}authorities/${item.type}`
			);
		}
	}

	public handleChange(type, total) {
		const authorities =
			type !== 'authority' ? this.state.authorities : total;
		const contacts = type !== 'contacts' ? this.state.contacts : total;

		const newTotal = authorities + contacts;

		this.setState({ [type]: total });

		this.props?.onTotalChange(newTotal);
	}
}

export const AuthorityContributions: any = connectNLP(
	AuthorityContributionsComponent
);
