import { get, isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../../actions/details.actions';
import { connectNLP } from '../../../../utilities/connect-navigate';
import {
	arrayToClass,
	isInQuery,
	replaceQuery,
} from '../../../../utilities/helper-fuctions';
import { Length } from '../../../measurements/length';
import { ContributeButton } from '../../../other/contribute-button';
import { Tooltip } from '../../../ui-components/popover/popover';
import { Widget } from '../../widget';
import './runways.scss';

const mapStateProps = (store: any) => ({
	mLength: store.measures.length,
});

const mapDispatchProps = (dispatch: any) => ({
	setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

class RunwaysComponent extends Widget<any, any> {
	public getEndpoint(): string {
		return `/airports/${this.props?.airport?.aid}/runways/all?includeArchived=true`;
	}

	public componentDidUpdate(
		prevProps: any,
		prevState: any,
		snapshot?: any
	): void {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		if (
			!isEqual(get(this.state, 'response'), get(prevState, 'response')) &&
			this.props.setModalDetails
		) {
			const currentRunway = get(this.props.modalDetails, 'runway');

			if (!currentRunway) {
				return;
			}

			const updatedRunway = (get(this.state, 'response') || []).find(
				(item: any) => item.aid === get(currentRunway, 'aid')
			);

			if (!isEqual(currentRunway, updatedRunway)) {
				this.props.setModalDetails({ runway: updatedRunway });
			}
		}
	}

	public renderContent(): React.ReactElement {
		const runways = get(this.state, 'response', []);

		return (
			<div className='RunwaysWidget w-100'>
				<div className='border-bottom-2 palette--bc-primary-4'>
					<div className='row display-flex align-items-center py-2 px-4'>
						<span className='col-3 palette--c-primary-4 m-0 text-center'>
							ID
						</span>
						<span className='col-7 palette--c-primary-4 m-0 text-center'>
							Length
						</span>
						<span className='col-7 palette--c-primary-4 m-0 text-center'>
							Width
						</span>
						<span className='col-7 palette--c-primary-4 m-0 text-center'>
							Surface
						</span>
					</div>
				</div>

				{!!runways.length && (
					<div className='w-100'>
						{runways.map((runway, index) =>
							this.renderRunway(runway, index)
						)}
					</div>
				)}

				{!runways.length && (
					<div className='w-100 display-flex justify-content-center py-6'>
						<ContributeButton
							openModal={(ev) => this.chooseRunway(ev, 'runways')}
							buttonOnly={true}
						/>
					</div>
				)}
			</div>
		);
	}

	public dataParser(data: any, mark: any) {
		if (data === null) {
			return '-';
		}

		return `${data} ${mark}`;
	}

	public length(width: number, unit) {
		if (!width) {
			return '-';
		}

		return (
			<Length
				value={width}
				sourceDimension='m'
				targetDimension={unit}
				precision={0}
				ignorePrecision={false}
				ignoreDimension={true}
			/>
		);
	}

	public renderRunway(runway: any, index: number): React.ReactElement {
		const rwyId = get(runway, 'aid');
		const identifier = this.dataParser(get(runway, 'identifier'), '');

		const unit = get(this.props.mLength, 'targetDimension', 'm');
		const longestLength = this.getLongestData(runway);
		const length = this.length(longestLength.value, unit);
		const width = this.length(get(runway, 'width'), unit);

		const surface = this.dataParser(get(runway, 'surface'), '');

		const containerClasses = arrayToClass([
			'Runway display-flex justify-content-center align-items-center border-bottom-1 palette--bc-neutral-3 p-2',
			runway?.archived ? 'palette--bgc-neutral-2' : '',
		]);

		return (
			<div
				className={containerClasses}
				key={index}
				onClick={(ev) => this.chooseRunway(ev, runway?.aid)}
			>
				<div className='Data row display-flex w-100'>
					{this.renderData(rwyId, identifier, 3, 'ID')}
					{this.renderData(
						rwyId,
						length,
						7,
						longestLength.title,
						unit,
						index + 1
					)}
					{this.renderData(rwyId, width, 7, 'Width', unit)}
					{this.renderData(rwyId, surface, 7, 'Surface')}
				</div>
			</div>
		);
	}

	public getLongestData(runway) {
		const tora = { title: 'TORA', value: get(runway, 'tora') };
		const toda = { title: 'TODA', value: get(runway, 'toda') };
		const asda = { title: 'ASDA', value: get(runway, 'asda') };
		const lda = { title: 'LDA', value: get(runway, 'lda') };

		const list = [tora, toda, asda, lda];
		let biggestNumOfThem = toda;

		list.forEach((item) => {
			if (item.value > biggestNumOfThem.value) {
				biggestNumOfThem = item;
			}
		});

		return biggestNumOfThem;
	}

	public renderData(
		aid,
		value,
		number,
		title,
		unit: any = false,
		lengthId: any = false
	): React.ReactElement {
		if (value && value !== '-') {
			const classNames = arrayToClass([`col-${number} m-0`]);

			const u = unit ? ` ${unit}` : '';

			return (
				<Tooltip
					triggerClasses={classNames}
					tooltip={this.renderPopover(title)}
					trigger={
						<span className='palette--c-neutral-5 display-flex justify-content-center align-items-center w-100'>
							{value}
							{u}
						</span>
					}
				/>
			);
		}

		const classes = arrayToClass([
			`col-${number} display-flex justify-content-center align-items-center`,
		]);

		return (
			<div className={classes} onClick={(e) => this.chooseRunway(e, aid)}>
				<span className='palette--c-primary-4' id='contribute'>
					Contribute!
				</span>
			</div>
		);
	}

	public renderPopover(title: any): any {
		return (
			<div className='PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1'>
				<span className='palette--c-neutral-1'>{title}</span>
			</div>
		);
	}

	public chooseRunway(ev, aid) {
		const utmSource = isInQuery('utm_source');
		this.props?.router?.navigate({
			pathname: `/airports/${this.props?.airport?.aid}/${aid}`,
			search: utmSource,
		});

		if (ev.target.id === 'contribute') {
			const newQuery = replaceQuery({ edit: true });
			this.props?.router?.navigate({
				pathname: `/airports/${this.props?.airport?.aid}/${aid}`,
				search: newQuery,
			});
		}
	}
}

export const Runways: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(connectNLP(RunwaysComponent));
