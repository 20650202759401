import { format, isBefore } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { arrayToClass, randomId } from '../../../utilities/helper-fuctions';
import { RequestManager } from '../../../utilities/request';
import { ConfirmationModal } from '../../notifications/confirmation-modal';
import { ToastMessages } from '../../notifications/toast-messages';
import { Form } from '../../ui-components/form/form';
import { HoverableButton } from '../../ui-components/hoverable-buttons/hoverable-buttons';
import { TextInput } from '../../ui-components/inputs/inputs/text-input';
import { Spinner } from '../../ui-components/spinner/spinner';
import { StatusMarker } from '../../ui-components/statusmarker/status-marker';
import { SelfCreatedTokenModal } from './self-created-token-modal';

const mapStateProps = (store: any) => ({
	dateFormat: store.userDetails.date,
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class ApiTokenComponent extends React.Component<any, any> {
	public selfCreatedTokenModal$;
	public confirm$: ConfirmationModal;
	public tokenModal$;

	public form = new Form({
		comment: '',
	});

	public state = {
		tokens: [],
		loading: false,
		isRevealed: false,
		hovered: false,
		token: false,
	};

	public componentDidMount() {
		this.fetchTokens();
	}

	public async fetchTokens(): Promise<any> {
		this.setState({ loading: true });

		try {
			const response = await RequestManager.get(
				`/users/${this.props?.user?.aid}/authTokens`
			);

			if (!response) {
				throw new Error('There was an error');
			}

			const tokens = response.filter(
				(token) => token.type === 'API' || token.type === 'API_FREE'
			);

			this.setState({ tokens: tokens, loading: false });
		} catch (err) {
			this.setState({ loading: false });
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}

	public render(): React.ReactElement {
		if (this.state.loading) {
			return <Spinner size='large' />;
		}

		// TODO: create new component for adding new token, it can be reorganized, move SelfCreatedTokenModal to the new one as well.

		return (
			<div className='w-100'>
				<div className='py-4 w-100'>
					<div className='display-flex align-items-center mb-4'>
						<h6 className='fw-bold text-uppercase palette--c-neutral-5 flex-fill'>
							API tokens
						</h6>
						<HoverableButton
							title='Issue free token'
							colorType='avio-green'
							className='border-radius-1 ml-2 elevation-1'
							onClick={() => this.tokenModal$.open()}
						/>

						<ConfirmationModal
							ref={(ref: any) => (this.tokenModal$ = ref)}
							title='Adding token'
							renderCustomContent={() =>
								this.renderTokenContent()
							}
							color='avio-green'
							buttonTitle='Add token'
							onClick={() => this.addNewToken()}
						/>
					</div>

					{this.renderTokens()}
					<SelfCreatedTokenModal
						ref={(ref: any) => (this.selfCreatedTokenModal$ = ref)}
						token={this.state?.token}
						onClose={() => this.fetchTokens()}
					/>
				</div>

				<div className='display-flex w-100 justify-content-end pt-4'>
					<HoverableButton
						title='Back'
						colorType='cancel'
						type='button'
						onClick={() => this.props.close && this.props.close()}
					/>
				</div>
			</div>
		);
	}

	public renderTokenContent() {
		return (
			<div className='p-4'>
				<div className='TokenDescription mb-4'>
					<span className='palette--c-neutral-5'>
						Tokens generated in this section only allow access
						to&nbsp;
						<a
							href='https://docs.aviowiki.com/free-endpoints'
							target='_blank'
							className='text-decoration-none LinkOnUser palette--c-blue-2'
						>
							Free Endpoints&nbsp;
						</a>
						for 90 days. To access paid endpoints, please send us an
						email at&nbsp;
						<a
							href='mailto:support@aviowiki.com'
							className='text-decoration-none LinkOnUser palette--c-blue-2'
						>
							support@aviowiki.com
						</a>
						.
					</span>
				</div>

				<TextInput
					textType='sentences'
					element='textarea'
					rows={5}
					field={this.form.getField('comment')}
					classes='w-100 py-2'
				/>
			</div>
		);
	}

	public renderTokens() {
		const { roles } = this.props?.user;
		const isAdmin = (roles || []).includes('ADMIN');
		const { tokens } = this.state;
		const validTokens = (tokens || []).filter((token: any) =>
			isBefore(new Date(), new Date(token?.expirationDate))
		);

		return (
			<div className='w-100'>
				{!(validTokens || []).length && (
					<div className='w-100 display-flex justify-content-center palette--bc-neutral-4 border-1 border-radius-1'>
						<p className='m-0 py-5 palette--c-neutral-5'>
							Your tokens will be listed here.
						</p>
					</div>
				)}
				{!!(validTokens || []).length && (
					<div className='TokensListContainer border-1 palette--bc-neutral-4 border-radius-1 mb-4'>
						{(validTokens || []).map((tokenObject: any, index) => {
							const { expirationDate, type, token } = tokenObject;
							const { isRevealed } = this.state;

							const classes = arrayToClass([
								'w-100 px-2',
								`palette--bgc-neutral-${
									index % 2 === 0 ? '0' : '2'
								}`,
							]);

							const bgColor = arrayToClass([
								`${
									index % 2 === 0
										? 'secondary-1'
										: 'neutral-1'
								}`,
							]);

							return (
								<div key={randomId()} className={classes}>
									<div className='display-flex align-items-center'>
										<div className='flex-fill'>
											<div className='display-flex py-2 px-1 align-items-center'>
												<div className='mr-3 display-flex align-items-center'>
													<StatusMarker
														tColor='secondary-4'
														bgColor={bgColor}
														text={(
															type || ''
														).replace(/_/gi, ' ')}
													/>
												</div>
												<div className='display-flex align-items-center'>
													<p className='flex-fill m-0 palette--c-neutral-5'>
														Expiry:&nbsp;
													</p>
													<p className='flex-fill m-0'>
														{format(
															new Date(
																expirationDate
															),
															this.props
																.dateFormat ||
																'yyyy. MM. dd. HH:mm'
														)}
													</p>
												</div>
											</div>
											<div className='p-1'>
												{isRevealed !== token && (
													<p className='flex-fill m-0'>
														{(token || '').slice(
															0,
															6
														)}
														&bull;&bull;&bull;
													</p>
												)}
												{isRevealed === token && (
													<p className='flex-fill m-0'>
														{token || ''}
													</p>
												)}
											</div>
										</div>
										<div className='display-flex flex-column flex-sm-row p-1'>
											<div>
												<HoverableButton
													className='border-radius-1 w-100'
													colorType='cancel'
													title={
														isRevealed === token
															? 'Hide'
															: 'Reveal'
													}
													onClick={() =>
														this.setState({
															isRevealed:
																isRevealed ===
																token
																	? false
																	: token,
														})
													}
												/>
											</div>
											{isAdmin && (
												<div className='ml-0 ml-sm-1'>
													<HoverableButton
														className='border-radius-1'
														colorType='avio-green'
														title='Revoke'
														onClick={() =>
															this.confirm$.open()
														}
													/>
												</div>
											)}
										</div>
										<ConfirmationModal
											ref={(ref: any) =>
												(this.confirm$ = ref)
											}
											color='avio-green'
											buttonTitle='Revoke'
											title='Are you sure you want to revoke this token?'
											content={token}
											onClick={() => this.revoke(token)}
										/>
									</div>

									{/*<div className="py-1">*/}
									{/*    {this.renderComment(token)}*/}
									{/*</div>*/}
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}

	public renderComment(token) {
		const line = token?.comment;

		if (line.length === 0) {
			return <p key={randomId()} className='palette--c-neutral-6 p-1' />;
		}

		return (
			<p
				key={randomId()}
				className='Text text-justify palette--c-neutral-6 m-0'
			>
				{line}
			</p>
		);
	}

	public async revoke(token) {
		try {
			await RequestManager.put(`/management/authTokens/${token}/revoke`);

			toast.success('Token successfully revoked.', { theme: 'light' });
			this.confirm$.close();
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}

	public async addNewToken(): Promise<any> {
		try {
			this.tokenModal$.close();

			const form: any = this.form.generateJSON();
			const token = await RequestManager.post(
				`/users/${this.props?.user?.aid}/authTokens/apiTokenFree`,
				{
					comment: form?.comment,
				}
			);

			if (!token) {
				throw new Error('There was an error');
			}

			this.setState({ token });
			this.selfCreatedTokenModal$.open();
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}
}

export const ApiToken: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(ApiTokenComponent);
