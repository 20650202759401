import * as React from 'react';
import { arrayToClass } from '../../../../utilities/helper-fuctions';
import { Spinner } from '../../spinner/spinner';
import { HoverableButton } from '../hoverable-buttons';

export class FormModalButtons<S, P> extends React.Component<S & any, P & any> {
	public render() {
		return (
			<div className='w-100 display-flex p-4'>
				{this.props?.isDelete && (
					<HoverableButton
						disabled={
							this.props?.disableDelete ||
							this.props?.loading ||
							false
						}
						className='mr-2'
						colorType='delete'
						title='Delete'
						onClick={() => this.onDelete()}
					/>
				)}
				<div className='display-flex align-items-center justify-content-end flex-fill'>
					<HoverableButton
						className='border-radius-1 mr-2'
						colorType={this.props?.loading ? 'disabled' : 'cancel'}
						disabled={this.props?.loading || false}
						title='Cancel'
						onClick={() => this.onClose()}
					/>
					{this.renderExtra()}
				</div>
			</div>
		);
	}

	public renderExtra() {
		return null;
	}

	public renderButtonChildren(colors) {
		if (this.props?.loading) {
			return <Spinner size='small' className='position-absolute' />;
		} else {
			const classes = arrayToClass(['mr-1 mb-0', colors]);
			return (
				<div className='w-100 display-flex align-items-center'>
					<span className={classes}>Send</span>
				</div>
			);
		}
	}

	public onClose() {
		this.props?.onClose();
	}

	public onSave() {
		this.props?.onSave();
	}

	public onDelete() {
		this.props?.onDelete();
	}
}
