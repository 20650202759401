import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { PreLoader } from '../../utilities/pre-loader';
import { RequestManager } from '../../utilities/request';
import { ToastMessages } from '../notifications/toast-messages';
import { Form } from '../ui-components/form/form';
import { FormButtons } from '../ui-components/hoverable-buttons/form-modal-buttons/form-buttons';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';
import { Input } from '../ui-components/inputs/inputs/input';
import { TextInput } from '../ui-components/inputs/inputs/text-input';
import { Select } from '../ui-components/inputs/select/select';
import { StatusMarker } from '../ui-components/statusmarker/status-marker';
import { WidgetModal } from '../widgets/widget-modal';

class UserManagerFormComponent extends WidgetModal<any, any> {
	public state = {
		...this.state,
		buttonDisabled: this.props?.isEdit,
		roles: [],
	};

	public form = new Form(
		{
			name: this.props?.response?.name || this.props?.data?.name || '',
			login: this.props?.response?.login || this.props?.data?.login || '',
			password:
				this.props?.response?.password ||
				this.props?.data?.password ||
				'',
			roles: this.props?.response?.roles || this.props?.data?.roles || '',
		},
		(changed) => this.setState({ buttonDisabled: !changed })
	);

	public componentDidMount() {
		super.componentDidMount();

		this.fetchRoles();
	}

	public async fetchRoles(): Promise<any> {
		try {
			const response = await RequestManager.get('/users/roles');

			if (!response) {
				throw new Error('There was an error');
			}

			this.setState({
				roles: response.map((item) => ({
					value: item,
					title: item.replace(/_/gi, ' '),
				})),
			});
		} catch (err) {
			// here we can handle roles error;
		}
	}

	public onClose() {
		this.props?.router?.navigate({
			pathname: '/admin/users',
			search: window.location.search,
		});

		if (this.props.onClose) {
			this.props.onClose();
		}

		this.form.clearForm();
	}

	public getTitle() {
		return 'Edit user';
	}

	public renderForm(): React.ReactElement {
		return (
			<div className='w-100 p-4'>
				<div className='display-flex'>
					<div>
						<StatusMarker
							tColor='secondary-4'
							bgColor='secondary-1'
							text={get(this.props, 'aid')}
							type='tag'
						/>
					</div>

					<div className='flex-fill' />

					<HoverableButton
						className='mr-2'
						colorType='transparent-green'
						onClick={() => this.redirect()}
						title='Check tokens'
					/>
				</div>

				<form>
					<TextInput
						textType='camel'
						field={this.form.getField('name')}
						classes='py-2'
						labelInfos={{
							label: 'Name',
						}}
					/>
					<Input
						field={this.form.getField('login')}
						classes='py-2'
						labelInfos={{ label: 'Email' }}
					/>
					<Input
						field={this.form.getField('password')}
						classes='py-2'
						labelInfos={{ label: 'Password' }}
					/>
					<Select
						isMulti={true}
						field={this.form.getField('roles')}
						options={this.state.roles || []}
						labelInfos={{
							label: 'Roles',
						}}
					/>
				</form>
			</div>
		);
	}

	public renderFooter(): React.ReactElement {
		return (
			<FormButtons
				saveDisabled={this.state?.buttonDisabled}
				isDelete={true}
				onClose={() => this.onClose()}
				onSave={() => this.handleSave()}
				onDelete={() => this.deleteModal$.open()}
			/>
		);
	}

	public getType() {
		return 'user';
	}

	public async handleSave() {
		const form: any = this.form.generateJSON();

		if (!form.password || form.password === '') {
			delete form.password;
		}

		try {
			const response = await RequestManager.put(
				`/users/${get(this.props, 'aid')}`,
				form
			);

			if (!response) {
				throw new Error('there was an error');
			}

			toast.success('User is successfully updated!');
			this.onClose();
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}

	public async onDelete() {
		try {
			await RequestManager.delete(`/users/${get(this.props, 'aid')}`);

			this.deleteModal$.close();
			this.onClose();
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}

	public redirect() {
		if (this.props?.router?.navigate) {
			this.props?.router?.navigate(
				`/admin/tokens/${get(this.props, 'aid')}`
			);
		}
	}
}

export const UserManagerForm: any = PreLoader(UserManagerFormComponent);
