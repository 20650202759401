import { differenceInDays, getISODay } from 'date-fns';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../../../../actions/details.actions';
import { UserActions } from '../../../../../actions/user.actions';
import { toUtc } from '../../../../../utilities/helper-fuctions';
import { LocalStorage } from '../../../../../utilities/local-storage';
import { RequestManager } from '../../../../../utilities/request';
import { ErrorPresenter } from '../../../../notifications/error-presenter';
import { ToastMessages } from '../../../../notifications/toast-messages';
import { HoverableButton } from '../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Modal } from '../../../../ui-components/modal/modal';
import { WidgetModal } from '../../../widget-modal';
import { DAYS } from '../opening-hours-contribution/constants';
import { StepFive } from './steps/step-five';
import { StepFour } from './steps/step-four';
import { StepOne } from './steps/step-one';
import { StepThree } from './steps/step-three';
import { StepTwo } from './steps/step-two';
import { StepZero } from './steps/step-zero';
import './steps/step.scss';

const titles = [
	{
		title: 'Possibility to take-off and land',
		endpoint: '/movementavailabilities',
	},
	{
		title: 'Air Traffic Control service',
		endpoint: '/atcavailabilities',
	},
	{
		title: 'Aerodrome Rescue and Fire Fighting service',
		endpoint: '/arffavailabilities',
	},
	{
		title: 'Customs and Immigration service',
		endpoint: '/ciqavailabilities',
	},
];

const mapStateProps = (store: any) => ({
	widgetSuccess: store.details.widgetSuccess,
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({
	tryRedirectIfNotAuthenticated: () =>
		dispatch(UserActions.tryRedirectIfNotAuthenticated()),
	setSuccess: () => dispatch(DetailsActions.setSuccess('availabilities')),
	mergeContribution: (data, endpoint, widgetId, callback) =>
		dispatch(
			DetailsActions.mergeContribution(data, endpoint, widgetId, callback)
		),
});

class OpeningHoursCreateComponent extends WidgetModal<any, any> {
	public availability: Modal;
	public stepOne: StepOne;
	public stepTwo: StepTwo;
	public stepThree: StepThree;
	public stepFour: StepFour;

	public state = {
		...this.state,
		step: LocalStorage.getItem('step') || 0,
		error: false,
		move: false,
		atc: false,
		arff: false,
		ciq: false,
		airport: false,
		loading: false,
	};

	public componentDidMount() {
		super.componentDidMount();

		this.fetchAirportForTimezone();

		if (this.availability) {
			this.availability.open();
		}

		const step = LocalStorage.getItem('step');

		if (!step) {
			LocalStorage.setItem('step', 0);
		} else if (step > 0 && !LocalStorage.getItem('step-0')) {
			this.setState({ step: 0 });
			LocalStorage.setItem('step', 0);
		}
	}

	public async fetchAirportForTimezone() {
		try {
			this.setState({ loading: true });
			const response = await RequestManager.get(
				`/airports/${this.props.parentAid}`
			);

			if (!response) {
				throw new Error('Error');
			}

			this.setState({ airport: response });
		} catch (err) {
			this.setState({ error: err });
		} finally {
			this.setState({ loading: false });
		}
	}

	public render(): React.ReactElement {
		return (
			<Modal
				ref={(ref: any) => (this.availability = ref)}
				hideClose={true}
				title={() => this.renderHeader()}
				content={() => this.renderContent()}
				wrapperClasses='AvailabilityModal'
			/>
		);
	}

	public renderHeader(): React.ReactElement {
		const progress = (((this.state.step || 0) + 1) / 6) * 100;

		return (
			<div className='position-relative'>
				<div className='w-100 p-4'>
					<div className='OpeningHoursHeader display-flex'>
						<h3 className='palette--c-neutral-6 fw-bold m-0 flex-fill'>
							Contribute opening hours
						</h3>
						<HoverableButton
							type='button'
							className='border-radius-1 position-absolute absolute-top-10 absolute-right-10'
							colorType='contrast'
							icon='close'
							onClick={() => this.handleClose()}
						/>
					</div>
					{this.renderAirport()}
				</div>
				<div className='w-100 palette--bgc-neutral-3'>
					<div
						className='py-9 palette--bgc-primary-4'
						style={{ width: `${progress}%` }}
					/>
				</div>
			</div>
		);
	}

	public renderAirport(): any {
		const { name } = this.state?.airport;

		if (!name) {
			return null;
		}

		return <h5 className='palette--c-neutral-5 m-0'>{name}</h5>;
	}

	public renderError(): React.ReactElement {
		return <ErrorPresenter error={this.state.error} />;
	}

	public renderContent(): React.ReactElement {
		const { timeZone } = this.state?.airport;
		const { step, saveLoading } = this.state;

		return (
			<div className='w-100'>
				{step === 0 && (
					<StepZero
						goForward={() => this.stepForward()}
						timezone={timeZone}
					/>
				)}
				{step === 1 && (
					<StepOne
						ref={(ref: any) => (this.stepOne = ref)}
						goForward={() => this.save()}
						goBack={() => this.stepBack()}
						defaultDays={this.getDays()}
						loading={saveLoading}
					/>
				)}
				{step === 2 && (
					<StepTwo
						ref={(ref: any) => (this.stepTwo = ref)}
						goForward={() => this.save()}
						goBack={() => this.stepBack()}
						defaultDays={this.getDays()}
						loading={saveLoading}
					/>
				)}
				{step === 3 && (
					<StepThree
						ref={(ref: any) => (this.stepThree = ref)}
						goForward={() => this.save()}
						goBack={() => this.stepBack()}
						defaultDays={this.getDays()}
						loading={saveLoading}
					/>
				)}
				{step === 4 && (
					<StepFour
						ref={(ref: any) => (this.stepFour = ref)}
						save={() => this.save()}
						goBack={() => this.stepBack()}
						defaultDays={this.getDays()}
						loading={saveLoading}
					/>
				)}
				{step === 5 && <StepFive close={() => this.handleClose()} />}
			</div>
		);
	}

	public stepForward(): any {
		const { step } = this.state;

		if (step < 5) {
			this.setState({ step: step + 1 });
			LocalStorage.setItem('step', step + 1);
		}
	}

	public stepBack(): any {
		const { step } = this.state;

		if (step > 0) {
			this.setState({ step: step - 1 });
			LocalStorage.setItem('step', step - 1);
		}
	}

	public handleClose(): void {
		LocalStorage.removeItem('step');
		LocalStorage.removeItem('step-0');
		LocalStorage.removeItem('step-1');
		LocalStorage.removeItem('step-2');
		LocalStorage.removeItem('step-3');
		LocalStorage.removeItem('step-4');

		if (this.props.widgetSuccess === 'availability') {
			this.props.setSuccess();
		}

		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	public getDays(): any {
		const temporarity = LocalStorage.getItem('step-0');
		const { validFrom, validTo } = temporarity;
		const from = new Date(validFrom);
		const to = new Date(validTo);

		const diff = differenceInDays(to, from);

		if (Math.ceil(diff) >= 7) {
			return DAYS;
		}

		const firstDay = getISODay(from);
		const lastDay = getISODay(to);

		if (lastDay < firstDay) {
			return DAYS.filter(
				(day, index) =>
					firstDay <= index + 1 ||
					lastDay >= index + 1 ||
					day.value === 'HOLIDAY'
			);
		}

		return DAYS.filter(
			(day, index) =>
				(firstDay <= index + 1 && lastDay >= index + 1) ||
				day.value === 'HOLIDAY'
		);
	}

	public async save(): Promise<void> {
		const { step } = this.state;
		this.setState({ saveLoading: true });

		try {
			const endpoint = titles[step - 1].endpoint;
			const err = await this.saveStep(step, endpoint);

			if (err) {
				throw new Error(err);
			}

			this.stepForward();
		} catch (err) {
			//
		} finally {
			this.setState({ saveLoading: false });
		}
	}

	public async saveStep(step, endpoint): Promise<any> {
		const data = LocalStorage.getItem(`step-${step}`);

		if (data?.skipped) {
			return;
		}

		const blocks = this.getBlocks(data);

		let error: any = false;
		const title = titles[step - 1].title;

		const convertStepZero = this.convertDateToUTC();

		for (const block of blocks) {
			const body = {
				...convertStepZero,
				...block,
			};

			try {
				const response = await RequestManager.post(
					`${endpoint}`,
					body,
					{ parentAid: this.props.parentAid }
				);

				if (!response) {
					throw new Error('Error');
				}

				await this.onSuccess(response, body, `${endpoint}/merge`);
			} catch (err: any) {
				toast.error(
					<ToastMessages
						error={`Error in ${title}: ${
							err?.response?.data?.message ||
							'some blocks are not valid.'
						}`}
					/>,
					{ theme: 'light' }
				);
				error = err;
			}
		}

		return error;
	}

	public async onSuccess(response, sentData, endpoint) {
		if (
			(this.props?.user?.roles || []).includes('AUTO_MERGE_CONTRIBUTIONS')
		) {
			response.contribution.changes = Object.keys(sentData).filter(
				(key) =>
					sentData[key] !== null ||
					sentData[key] !== '' ||
					!isEmpty(sentData)
			);

			await this.props.mergeContribution(
				response,
				endpoint,
				'availability'
			);
		}
	}

	public getBlocks(localStorageItem) {
		const { data = [] } = localStorageItem;

		return data.map((block) => {
			const { blocks = [] } = block?.data || [];
			block.data.blocks = blocks.map((b) => {
				if (b.open) {
					delete b.open;
				}

				return b;
			});

			delete block?.data?.available;
			delete block?.data?.availablePerDay;
			return block?.data;
		});
	}

	public convertDateToUTC() {
		const dates = LocalStorage.getItem('step-0');
		const { validFrom, validTo } = dates;

		return {
			...dates,
			validFrom: toUtc(validFrom),
			validTo: toUtc(validTo),
		};
	}
}

export const OpeningHoursCreate: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(OpeningHoursCreateComponent);
