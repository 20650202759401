import * as React from 'react';
import { toast } from 'react-toastify';
import { ToastMessages } from '../../components/notifications/toast-messages';
import { Form } from '../../components/ui-components/form/form';
import { HoverableButton } from '../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { Input } from '../../components/ui-components/inputs/inputs/input';
import { LoginLayout } from '../../layouts/login/login.layout';
import { RequestManager } from '../../utilities/request';
import './forgot-password.screen.scss';

class ForgotPasswordScreenComponent extends React.Component<any, any> {
    public form: any = new Form({
        email: '',
    });

    public render(): React.ReactElement {
        return (
            <div className="ForgotPasswordScreen w-100 display-flex justify-content-center">
                <div className="ForgotPasswordContainer w-100 palette--bgc-neutral-1 border-radius-1 p-6 elevation-1">
                    <h2 className="palette--c-neutral-6 mb-4">Forgot Password</h2>

                    <div className="display-flex flex-column flex-sm-row mb-6">
                        <p className="palette--c-neutral-4">
                            To request a password change please enter your email address below
                        </p>
                    </div>

                    <form>
                        <Input
                            type="text"
                            field={this.form.getField('email')}
                            classes="mb-6"
                            labelInfos={{ label: 'Email' }}
                        />

                        <div className="w-100 display-flex justify-content-end">
                            <HoverableButton
                                colorType="transparent-grey"
                                titleRight="Go back"
                                icon="arrow_back"
                                className="mr-2"
                                type="button"
                                isClose={true}
                                onClick={() => this.props?.router?.navigate('/login')}
                            />
                            <HoverableButton
                                colorType="avio-green"
                                titleLeft="Send"
                                type="button"
                                onClick={() => this.sendEmail()}
                                isSave={true}
                                icon="send"
                                className="border-radius-1 elevation-1"
                            />
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    public async sendEmail(): Promise<void> {
        const form = this.form.generateJSON();

        try {
            await RequestManager.post('/auth/forgotPassword', {}, { login: form.email }, { noHeader: true });

            this.props?.router?.navigate('/forgot-password/feedback');
        } catch (err) {
            toast.error(<ToastMessages error={err} />, { theme: 'light' });
        }
    }
}

export const ForgotPasswordScreen: any = LoginLayout(ForgotPasswordScreenComponent);
