import { get } from 'lodash';
import * as React from 'react';
import { getData, getFields } from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { Form } from '../../../../ui-components/form/form';
import { WidgetContributionPresenter } from '../../../shared/contributions/widget-contribution-presenter/widget-contribution-presenter';
import { flightTypesFields } from './availability-contribution-helpers';

export class FlightContribution extends React.Component<any, any> {
    public state = {
        rejected: [],
        editable: [],
        limited: false,
        type: get(this.props, 'type'),
    };

    public form = new Form({
        rules: this.props?.item?.rules,
        filing: this.props?.item?.filing,
        airframe: this.props?.item?.airframe,
        status: this.props?.item?.status,
        purpose: this.props?.item?.purpose,
        direction: this.props?.item?.direction,
    });

    public getSsAndSrValue(value, type) {
        if (!isNaN(value) && value !== null) {
            return `${type}${value === 0 ? ' 0' : value > 0 ? `+${value}` : value}`;
        }

        return null;
    }

    public getBlock(): any {
        const form = this.form.generateJSON();

        const data = {
            ...this.props.item,
            ...form,
        };

        data.contribution.changes = getFields(this, flightTypesFields(this)).map((item) => item.type);

        return data;
    }

    public clearForm(): void {
        this.form.clearForm();
    }

    public renderEmpty(): React.ReactElement {
        return (
            <div className="display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2">
                <p className="palette--c-neutral-5 m-0">Nothing changed.</p>
            </div>
        );
    }

    public render(): React.ReactElement {
        const flightList = getFields(this, flightTypesFields(this));

        if (!flightList || !flightList.length) {
            return this.renderEmpty();
        }

        return (
            <div className="ProviderContent w-100 py-2 pl-4 pr-0">
                <p className="m-0 fw-bold palette--c-neutral-6">Flight Types: </p>
                {flightList.map((item: any, index: number) => {
                    return this.renderFields(item, index);
                })}
            </div>
        );
    }

    public renderFields(field: any, index): React.ReactElement {
        const rejected: any = get(this.state, 'rejected', []);
        const editable: any = get(this.state, 'editable', []);

        const wrapperClassName = arrayToClass([
            rejected.includes(field.type)
                ? 'palette--bgc-neutral-3'
                : `palette--bgc-${
                      this.state.type === 'UPDATE' ? 'yellow-1' : this.state.type === 'CREATE' ? 'green-1' : 'red-1'
                  }`,
            'border-radius-1 my-2 p-2',
            'display-flex align-items-center',
        ]);

        const element = {
            type: field.type,
            content: this.getData(field.type),
            component: field.component,
        };

        return (
			<WidgetContributionPresenter
				isAdmin={this.props.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(field.type)}
				toValue={element}
				isEditable={editable.includes(field.type)}
				isRejected={rejected.includes(field.type)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				className={wrapperClassName}
			/>
		);
    }

    public getData(field): any {
        const form = this.form.generateJSON();
        const value = get(form, field, null);

        if (this.state.type === 'DELETE') {
            return null;
        }

        return getData(field, value, true, []);
    }

    public getOriginalData(field): any {
        const value = get(this.props.original, field, null);

        if (this.state.type === 'CREATE') {
            return null;
        }

        return getData(field, value, false, []);
    }

    public async handleEdit(type: string = 'editAll'): Promise<void> {
        const editable: any = get(this.state, 'editable', []);

        if (type === 'editAll') {
            const block = flightTypesFields(this).map((item: any) => item.type);
            await this.setState({ editable: [...block] });
        }

        if (!editable.includes(type)) {
            await this.setState({ editable: [...this.state.editable, type] });
        }
    }

    private async handleMissEdit(type: string): Promise<void> {
        const editable: any = get(this.state, 'editable', []);

        if (editable.includes(type)) {
            const editableArray = this.state.editable.filter((item) => item !== type);
            await this.setState({ editable: editableArray });
        }
    }

    private handleReject(type: string): void {
        const rejected: any = get(this.state, 'rejected', []);

        if (!rejected.includes(type)) {
            this.setState({ rejected: [...this.state.rejected, type] });
        }
    }

    private handleMissReject(type: string) {
        const rejected: any = get(this.state, 'rejected', []);

        if (rejected.includes(type)) {
            const rejectedArray = this.state.rejected.filter((item) => item !== type);
            this.setState({ rejected: rejectedArray });
        }
    }
}
