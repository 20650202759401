import { addDays, endOfDay, format, startOfDay } from 'date-fns';
import * as React from 'react';
import { toast } from 'react-toastify';
import { RequestManager } from '../../../utilities/request';
import { ToastMessages } from '../../notifications/toast-messages';
import { Form } from '../../ui-components/form/form';
import { HoverableButton } from '../../ui-components/hoverable-buttons/hoverable-buttons';
import { DateTimePicker } from '../../ui-components/inputs/date-picker/date-time-picker';
import { TextInput } from '../../ui-components/inputs/inputs/text-input';
import { StatusMarker } from '../../ui-components/statusmarker/status-marker';
import { WidgetModal } from '../../widgets/widget-modal';

export class TokenEditModal extends WidgetModal<any, any> {
	public state = {
		...this.state,
		buttonDisabled: true,
		isEdit: false,
		isRevealed: false,
		response: false,
	};

	public form = new Form(
		{
			comment: this.props?.data?.comment,
			expirationDate: this.props?.data?.expirationDate || new Date(),
			start: {
				value: new Date(addDays(new Date(), -30)),
				trigger: () => this.fetch(),
			},
			end: { value: new Date(), trigger: () => this.fetch() },
		},
		(changed) => this.setState({ buttonDisabled: !changed })
	);

	public componentDidMount(): void {
		super.componentDidMount();

		this.fetch();
	}

	public onClose() {
		this.setState({ isEdit: false });

		if (this.props.onClose) {
			this.props.onClose();
		}
		this.modal$.close();
	}

	public async fetch(): Promise<any> {
		this.setState({ loading: true });
		try {
			const form: any = this.form.generateJSON();
			const start = startOfDay(new Date(form.start));
			const end = endOfDay(new Date(form.end));

			const response = await RequestManager.get(
				`/management/usage/token/${this.props?.token?.token}`,
				{
					start: start,
					end: end,
				}
			);

			if (!response && response !== 0) {
				throw new Error('There was an error');
			}

			this.setState({ response: response });
		} catch (err: any) {
			this.setState({
				error: err?.response?.data?.message || err || 'Error',
			});
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		} finally {
			this.setState({ loading: false });
		}
	}

	public getTitle() {
		return this.state.isEdit ? 'Edit token' : 'Token details';
	}

	public renderForm(): React.ReactElement {
		return (
			<div className='w-100 p-4'>
				{this.renderTokenContent()}
				{this.renderTokenUsages()}
			</div>
		);
	}

	public renderTokenContent(): React.ReactElement {
		const token: any = this.props.token;

		return (
			<form>
				<div className='display-flex align-items-center'>
					<StatusMarker
						tColor='secondary-4'
						bgColor='secondary-1'
						text={token?.userAid}
						type='tag'
					/>
					<p className='palette--c-neutral-6 mb-0 ml-2'>
						- {token?.userId}
					</p>
					{!this.state?.isEdit && (
						<div className='flex-fill display-flex justify-content-end'>
							<HoverableButton
								className='border-radius-1'
								colorType='avio-green'
								onClick={() => this.setState({ isEdit: true })}
								title='Edit'
							/>
						</div>
					)}
				</div>

				<div className='my-2 FormContainer align-items-start align-items-md-center'>
					<div className='inline-flex flex-fill'>
						<StatusMarker
							tColor='secondary-4'
							bgColor='secondary-1'
							text={(token?.type || '').replace('_', ' ')}
							type='tag'
						/>
					</div>

					{!this.state.isEdit && (
						<p className='m-0'>
							{format(
								new Date(this.form.getValue('expirationDate')),
								'yyyy-MM-dd'
							)}
						</p>
					)}
					{this.state.isEdit && (
						<DateTimePicker
							field={this.form.getField('expirationDate')}
							dateFormat='yyyy-MM-dd'
							inputClassName='palette--c-neutral-6'
							classes='limit-w py-2'
							labelInfos={{ label: 'Expiration date' }}
						/>
					)}
				</div>

				<div className='w-100 border-1 palette--bc-neutral-4 border-radius-1 my-4 display-flex align-items-center overflow-hidden'>
					{!this.state?.isRevealed && (
						<p className='flex-fill m-0 pl-2'>
							{(token.token || '').slice(0, 6)} &bull;&bull;&bull;
						</p>
					)}
					{!!this.state?.isRevealed && (
						<p className='flex-fill m-0 pl-2'>{token.token}</p>
					)}
					<HoverableButton
						title={this.state.isRevealed ? 'Hide' : 'Reveal'}
						className='border-radius-0'
						colorType='cancel'
						onClick={() =>
							this.setState({
								isRevealed: !this.state.isRevealed,
							})
						}
					/>
					<HoverableButton
						title='Revoke'
						className='border-radius-0'
						colorType='avio-green'
						onClick={() => this.revoke()}
					/>
				</div>

				{!this.state.isEdit && (
					<p className='palette--c-neutral-6 m-0 border-radius-1 palette--bgc-neutral-2 p-2'>
						{this.form.getValue('comment')
							? this.form.getValue('comment')
							: 'There is no comment'}
					</p>
				)}
				{this.state.isEdit && (
					<TextInput
						element='textarea'
						rows={3}
						field={this.form.getField('comment')}
						classes='py-2'
						textType='sentences'
						labelInfos={{ label: 'Comment' }}
					/>
				)}
			</form>
		);
	}

	public renderTokenUsages() {
		const resp: any = this.state.response;
		const isResp = !!resp || resp === 0;

		return (
			<div className='pt-4'>
				<h5 className='palette--c-neutral-6'>Token usage range: </h5>
				<div className='FormContainer'>
					<DateTimePicker
						field={this.form.getField('start')}
						inputClassName='palette--c-neutral-6'
						classes='limit-w mb-2 mb-md-0'
						dateFormat='yyyy-MM-dd'
					/>
					<DateTimePicker
						field={this.form.getField('end')}
						inputClassName='palette--c-neutral-6'
						classes='limit-w left-m'
						dateFormat='yyyy-MM-dd'
					/>
				</div>
				{isResp && (
					<div className='w-100 display-flex align-items-center pt-2'>
						<h5 className='palette--c-neutral-5 m-0 mr-2'>
							Token usage:
						</h5>
						<p className='palette--c-neutral-6 m-0 mr-2'>
							{this.state.response}
						</p>
					</div>
				)}
			</div>
		);
	}

	public renderFooter(): React.ReactElement {
		return (
			<div className='w-100 display-flex align-items-center justify-content-end p-4'>
				<HoverableButton
					className='border-radius-1 mr-2'
					colorType='cancel'
					title='Cancel'
					onClick={() => this.closeButtonEventHandlers()}
				/>
				{this.state.isEdit && (
					<HoverableButton
						disabled={this.state?.buttonDisabled}
						colorType={
							this.state?.buttonDisabled
								? 'disabled'
								: 'avio-green'
						}
						className='border-radius-1'
						title='Send'
						onClick={() => this.handleSave()}
					/>
				)}
			</div>
		);
	}

	public closeButtonEventHandlers() {
		if (this.state.isEdit) {
			this.setState({ isEdit: false });
		} else {
			this.onClose();
		}
	}

	public async handleSave(): Promise<any> {
		try {
			const form: any = this.form.generateJSON();

			const data = {
				expirationDate: new Date(form.expirationDate),
				comment: form.comment,
			};

			const response = await RequestManager.put(
				`/management/authTokens/${this.props?.token?.token}`,
				data
			);

			if (!response) {
				throw new Error('There was an error');
			}

			this.setState({ isEdit: false });
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}

	public async revoke(): Promise<any> {
		try {
			const response = await RequestManager.put(
				`/management/authTokens/${this.props?.token?.token}/revoke`
			);

			if (!response) {
				throw new Error('There was an error');
			}

			toast.success('Successfully revoked!', { theme: 'light' });
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}
}
