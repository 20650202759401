import { endOfDay, startOfDay } from 'date-fns';
import { addDays } from 'date-fns/esm';
import * as React from 'react';
import { LocalStorage } from '../../../../../../utilities/local-storage';
import { availabilityInfos } from '../../../../../../utilities/widget-helpers/availability-infos';
import { Form } from '../../../../../ui-components/form/form';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Checkbox } from '../../../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { DateTimePicker } from '../../../../../ui-components/inputs/date-picker/date-time-picker';

export class StepZero extends React.Component<any, any> {
	public state = {
		disableTo: false,
		error: { from: false, to: false },
	};

	public form = new Form({
		validFrom: {
			value: this.getDateValues(),
			trigger: () => this.handleTrigger('validFrom', 'from'),
		},
		validTo: {
			value: this.getDateValues(7),
			trigger: () => this.handleTrigger('validTo', 'to'),
		},
		exception: {
			value:
				LocalStorage.getItem(this.props.stepName || 'step-0')
					?.exception || false,
			trigger: () => this.handleException(),
		},
	});

	public getDateValues(days = 0) {
		const step = LocalStorage.getItem(this.props.stepName || 'step-0');
		const type = days ? 'validTo' : 'validFrom';

		return step?.[type] || addDays(new Date(), days);
	}

	public handleException() {
		const value = this.form.getValue('exception');

		if (!!value) {
			this.form.setValue('validTo', new Date('2099-12-31'));

			if (!this.form.getValue('validFrom')) {
				this.form.setValue('validFrom', new Date());

				const fromEl = document.getElementById('availabilityFirst');
				if (fromEl) {
					fromEl.focus();
				}
			}

			this.setState({ disableTo: true });
		} else {
			this.setState({ disableTo: false });
		}
	}

	public handleTrigger(field, errorField) {
		const value = this.form.getValue(field);

		if (!!value) {
			this.setState({
				error: { ...this.state.error, [errorField]: false },
			});
		}
	}

	public render(): React.ReactElement {
		return (
			<form className='w-100'>
				<div className='p-4'>
					<h3 className='w-100 palette--c-neutral-6 fw-bold'>
						Add the validity period
					</h3>
					<p className='w-100 palette--c-neutral-6'>
						What is the period of opening hours that can be
						specified in the next steps?
					</p>

					<div className='display-flex flex-column flex-md-row align-items-end mt-4'>
						<DateTimePicker
							classes='py-2 limit-w'
							error={this.state?.error?.from}
							field={this.form.getField('validFrom')}
							autoFocus={true}
							inputClassName='palette--c-neutral-5'
							id='availabilityFirst'
							labelInfos={{
								label: 'Valid from',
								info: availabilityInfos.validFrom,
							}}
						/>
						<DateTimePicker
							classes='py-2 limit-w left-m'
							error={this.state?.error?.to}
							field={this.form.getField('validTo')}
							disabled={this.state.disableTo}
							inputClassName='palette--c-neutral-5'
							labelInfos={{
								label: 'Valid to',
								info: availabilityInfos.validTo,
							}}
						/>
						<Checkbox
							field={this.form.getField('exception')}
							classes='py-2 left-m'
							labelInfos={{ label: 'Permanent change' }}
						/>
					</div>
				</div>

				{this.renderFooter()}
			</form>
		);
	}

	public renderFooter(): React.ReactElement {
		return (
			<div className='w-100 display-flex justify-content-end p-4'>
				<HoverableButton
					colorType='avio-green'
					className='border-radius-1'
					id='availabilityLast'
					icon='arrow_forward'
					titleLeft='Next'
					onClick={() => this.handleNext()}
				/>
			</div>
		);
	}

	public handleNext(): any {
		const from = this.form.getValue('validFrom');
		const to = this.form.getValue('validTo');

		if (!from || !to) {
			return this.setState({ error: { from: !!from, to: !!to } });
		}

		LocalStorage.setItem(this.props.stepName || 'step-0', {
			validFrom: startOfDay(from),
			validTo: endOfDay(to),
			exception: this.form.getValue('exception'),
		});

		if (this.props.goForward) {
			this.props.goForward();
		}
	}
}
