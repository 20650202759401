import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../../../../actions/details.actions';
import { arrayToClass } from '../../../../../../utilities/helper-fuctions';
import { PreLoader } from '../../../../../../utilities/pre-loader';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';

const mapStateProps = (store: any) => ({
    widgetSuccess: store.details.widgetSuccess,
});

const mapDispatchProps = (dispatch: any) => ({
    setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

class GenericProductComponent extends React.Component<any, any> {
    public componentDidUpdate(prevProps: any, prevState: any, snapshot?: any) {
        if (this.props.widgetSuccess !== prevProps.widgetSuccess && this.props.widgetSuccess === 'fuel') {
            this.props.refresh();
            this.props.setSuccess();
        }
    }

    public render(): React.ReactElement {
        const generic = this.props?.response?.content;

        if (!generic || !(generic || []).length) {
            if (this.props.emptyRenderer) {
                return this.props.emptyRenderer();
            }

            return <></>;
        }

        return (
            <div className="w-100 border-radius-1 border-radius-1 border-1 palette--bc-neutral-3 overflow-hidden">
                {!!(generic || []).length && (
                    <div className="border-radius-1 border-radius-1 border-1 palette--bc-neutral-3 overflow-hidden">
                        {generic.map((item: any, index: number) => {
                            const classNames = arrayToClass([
                                'w-100 p-2',
                                `palette--bgc-${index % 2 === 0 ? 'neutral-2' : 'neutral-1'}`,
                            ]);

                            return (
                                <div className={classNames} key={index}>
                                    <div className="row">
                                        <p className="palette--c-neutral-6 m-0 col-12">{item.name}</p>

                                        <div className="display-flex col-10 justify-content-end">
                                            <p className="palette--c-neutral-6 m-0 mr-2">{item.currency}</p>
                                            <p className="palette--c-neutral-6 m-0">{item.price}</p>
                                            {item.unit && <p className="palette--c-neutral-6 m-0 mr-2">/{item.unit}</p>}
                                            {item.unit && (
                                                <p className="palette--c-neutral-6 m-0">
                                                    {item.taxInclusive ? '[tax]' : '[No taxes]'}
                                                </p>
                                            )}
                                        </div>

                                        <div className="col-2 display-flex justify-content-end">
                                            <HoverableButton
                                                disabled={false}
                                                className="border-radius-1"
                                                colorType="transparent-green"
                                                icon="rate_review"
                                                onClick={() =>
                                                    this.props?.router?.navigate(
                                                        `${window.location.pathname}/${item.aid}?edit=true`
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

export const GenericProduct: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    PreLoader(GenericProductComponent)
);
