import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

export const connectNLP = (Component) => (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    return <Component {...props} ref={props.innerRef} router={{ params, navigate, location }} />;
};
