import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { arrayToClass } from '../../../../utilities/helper-fuctions';
import { differenceInMinutes, startOfDay } from 'date-fns';
import * as React from 'react';

const mapStateProps = (store: any) => ({
	sunsetAndSunrise: store.localStorage.sunsetAndSunrise,
});
const mapDispatchProps = (dispatch: any) => ({});

class BackgroundComponent extends React.Component<any, any> {
	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (!isEqual(this.props.sunsetAndSunrise, prevProps.sunsetAndSunrise)) {
			this.forceUpdate();
		}
	}

	public render() {
		const { alwaysDown, alwaysUp, sunset, sunrise } =
			this.props?.sunsetAndSunrise;

		const classNames = arrayToClass([
			this.props.classNames,
			'åw-100 h-100 position-absolute absolute-top-0 absolute-left-0 absolute-bottom-0 absolute-right-0 display-flex overflow-hidden',
		]);

		if (!!alwaysDown || !!alwaysUp || (!sunset && !sunrise)) {
			const color = !!alwaysDown
				? '#070b34'
				: !!alwaysUp
				? '#85a6d4'
				: '#778295';

			return (
				<div
					className={classNames}
					style={{ backgroundColor: color }}
				/>
			);
		}

		const colors: any = this.calculateGradient();

		return (
			<div className={classNames}>
				<div
					style={{
						width: 16,
						height: '100%',
						backgroundColor: colors.color,
					}}
				/>
				<div
					className='w-100 h-100'
					style={{ background: colors.gradient }}
				/>
				<div
					style={{
						width: 16,
						height: '100%',
						backgroundColor: colors.color,
					}}
				/>
			</div>
		);
	}

	public calculateGradient() {
		const { sunset, sunrise } = this.props?.sunsetAndSunrise;

		const sunsetDate = new Date(sunset);
		const current = startOfDay(new Date(sunsetDate));
		const sunsetTime = differenceInMinutes(sunsetDate, current) / 60;

		const sunriseDate = new Date(sunrise);
		const sunriseTime = differenceInMinutes(sunriseDate, current) / 60;

		const sunriseFrom = ((sunriseTime - 0.5) / 24) * 100;
		const sunriseTo = (sunriseTime / 24) * 100;
		const sunsetFrom = (sunsetTime / 24) * 100;
		const sunsetTo = ((sunsetTime + 0.5) / 24) * 100;

		if (sunsetFrom < sunriseTo) {
			return {
				gradient: `linear-gradient(to right, rgba(133,166,212,1) ${sunsetFrom}%, rgba(7,11,52,1) ${sunsetTo}%, rgba(7,11,52,1) ${sunriseFrom}%, rgba(133,166,212,1) ${sunriseTo}%)`,
				color: 'rgba(133,166,212,1)',
			};
		}

		return {
			gradient: `linear-gradient(to right, rgba(7,11,52,1) ${sunriseFrom}%, rgba(133,166,212,1) ${sunriseTo}%, rgba(133,166,212,1) ${sunsetFrom}%, rgba(7,11,52,1) ${sunsetTo}%)`,
			color: 'rgba(7,11,52,1)',
		};
	}
}

export const Background: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(BackgroundComponent);
