import { format, isBefore } from 'date-fns';
import * as React from 'react';
import { toast } from 'react-toastify';
import { arrayToClass, randomId } from '../../../utilities/helper-fuctions';
import { RequestManager } from '../../../utilities/request';
import { ToastMessages } from '../../notifications/toast-messages';
import { HoverableButton } from '../../ui-components/hoverable-buttons/hoverable-buttons';
import { Spinner } from '../../ui-components/spinner/spinner';
import { StatusMarker } from '../../ui-components/statusmarker/status-marker';
import { IssueNewToken } from './add-token.modal';
import { TokenEditModal } from './token-details-form';

export class TokenList extends React.Component<any, any> {
	public addModal$: IssueNewToken;
	public editModal$: TokenEditModal;

	public state = {
		loading: false,
		error: false,
		response: false,
		token: false,
		isNew: false,
		isEdit: false,
	};

	public componentDidMount() {
		this.fetch();
	}

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (this.props.aid !== prevProps.aid && this.props.aid) {
			this.fetch();
		}

		if (this.props.showAllTokens !== prevProps.showAllTokens) {
			this.fetch();
		}

		if (this.props.showExpiredTokens !== prevProps.showExpiredTokens) {
			this.forceUpdate();
		}
	}

	public async fetch(): Promise<any> {
		this.setState({ loading: true, isModal: false });

		try {
			const types = await this.fetchTokenTypes();
			const selectedTypes = this.props.showAllTokens
				? 'API, API_FREE, MOBILE, WEBAPP, PASSWORD_RESET'
				: types.join(', ') || '';

			const response = await RequestManager.get(
				`/management/authTokens/${this.props?.aid}`,
				{
					type: selectedTypes,
				}
			);

			if (!response) {
				throw new Error('There is an error');
			}

			this.setState({ response: response });
		} catch (err: any) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			this.setState({
				error: err?.response?.data?.message || err || 'Error',
			});
		} finally {
			this.setState({ loading: false });
		}
	}

	public async fetchTokenTypes(): Promise<any> {
		try {
			const response = await RequestManager.get(
				'/management/authTokens/types'
			);

			if (!response) {
				throw new Error('There was an error');
			}

			return response;
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		}
	}

	public render(): React.ReactElement {
		return (
			<div className='w-100'>
				{this.state.loading &&
					!this.state.error &&
					!this.state.response &&
					this.renderLoading()}
				{!this.state.loading &&
					this.state.error &&
					!this.state.response &&
					this.renderError()}
				{!this.state.loading &&
					!this.state.error &&
					this.state.response &&
					this.renderContent()}

				{this.state?.isNew && (
					<IssueNewToken
						aid={this.props?.aid}
						onClose={(isChanged) => this.handleClose(isChanged)}
					/>
				)}
				{this.state?.isEdit && (
					<TokenEditModal
						token={this.state.token}
						onClose={(isChanged) => this.handleClose(isChanged)}
					/>
				)}
			</div>
		);
	}

	public renderLoading(): React.ReactElement {
		return (
			<div className='w-100 display-flex align-items-center justify-content-center'>
				<Spinner size='large' />
			</div>
		);
	}

	public renderError(): React.ReactElement {
		return (
			<div className='w-100 display-flex align-items-center justify-content-center'>
				<p className='m-0 palette--c-neutral-5'>{this.state.error}</p>
			</div>
		);
	}

	public renderContent(): React.ReactElement {
		const response: any = this.state?.response || [];
		const tokens = this.props.showExpiredTokens
			? response
			: (response || []).filter((item) => {
					const expirationDate = new Date(item?.expirationDate);
					const today = new Date();
					return isBefore(expirationDate, today);
			  });

		return (
			<div className='w-100'>
				{!!(tokens || []).length && (
					<div className='border-1 palette--bc-neutral-3 border-radius-1 overflow-hidden'>
						{(tokens || []).map((token, index) => {
							const classNames = arrayToClass([
								`palette--bgc-neutral-${
									index % 2 === 1 ? '' : '2'
								}`,
								'p-2 pointer',
							]);

							return (
								<div
									key={randomId()}
									className={classNames}
									onClick={() => this.openEditModal(token)}
								>
									<div className='pb-2'>
										<StatusMarker
											tColor='secondary-4'
											bgColor='secondary-1'
											text={token?.type}
											type='tag'
										/>
									</div>
									<div className='row mb-2'>
										<p className='col-24 col-sm-18 m-0'>
											{(token.token || '').slice(0, 6)}{' '}
											&bull;&bull;&bull;
										</p>
										<div className='col-24 col-sm-6 display-flex justify-content-end'>
											<p className='m-0'>
												{token.expirationDate
													? format(
															new Date(
																token.expirationDate
															),
															'yyyy-MM-dd'
													  )
													: '-'}
											</p>
										</div>
									</div>
									<p className='m-0'>{token.comment}</p>
								</div>
							);
						})}
					</div>
				)}
				<HoverableButton
					className='border-radius-1 mt-2'
					onClick={() => this.addNewToken()}
					titleRight='Add new token'
					icon='add'
					colorType='avio-green'
				/>
			</div>
		);
	}

	public async openEditModal(token) {
		this.setState({ token: token, isEdit: true });
	}

	public handleClose(isChanged) {
		this.setState({ isNew: false, isEdit: false });

		if (!!isChanged) {
			this.fetch();
		}
	}

	public addNewToken() {
		this.setState({ isNew: true });
	}
}
