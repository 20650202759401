import { Modal } from '../ui-components/modal/modal';
import * as React from 'react';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';

export class NotificationModal extends React.Component<any, any> {
    public modal$: Modal;

    public state = {
        title: '',
        isDelete: false,
    };

    public open(isDelete = false) {
        this.setState({ isDelete: isDelete });
        this.modal$.open();
    }

    public close() {
        if (this.props.onClose) {
            this.props.onClose(this.state.isDelete);
        }

        this.modal$.close();
    }

    public render() {
        return (
            <Modal
                onDismissRequest={() => this.close()}
                ref={(ref: any) => (this.modal$ = ref)}
                title={() => this.renderHeader()}
                content={() => this.renderContent()}
                footer={() => this.renderFooter()}
            />
        );
    }

    public renderHeader() {
        return (
            <div className="NotificationModalHeader w-100 p-4 display-flex">
                <h3 className="palette--c-neutral-6 fw-bold m-0 flex-fill">Contribute {this.state.title}</h3>
            </div>
        );
    }

    public renderContent() {
        // ha a user meg nem kapcsolta ki az email ertesitot, akkor az jon ide;

        return (
            <div className="w-100 px-4 py-6 display-flex align-items-center">
                <h2 className="palette--c-primary-4 material-icons m-0 mr-4">check_circle</h2>
                <h3 className="palette--c-neutral-6 m-0">Thank you for your contribution</h3>
            </div>
        );
    }

    public renderFooter() {
        return (
            <div className="w-100 display-flex justify-content-end p-4">
                <HoverableButton colorType="cancel" className="mr-2" onClick={() => this.close()} title="Close" />
            </div>
        );
    }
}
