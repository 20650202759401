import * as React from 'react';
import { Map } from '../../components/map/map';
import { ReliefWingLayout } from '../../layouts/relief-wing-app/relief-wing-layout';
import { LocalStorage } from '../../utilities/local-storage';
import { Search } from './search/search';

export class ReliefWingScreenComponent extends React.Component<any, any> {
    public state = {
        airport: LocalStorage.getItem('reliefAirport') || false,
    };

    public render(): React.ReactElement {
        return (
            <div className="w-100 flex-fill display-flex flex-column">
                <div className="w-100 p-2">{this.renderSearch()}</div>
                <div className="flex-fill mx-2 display-flex">
                    <Map airport={this.state.airport} isFull={true} useMarkers={true} isRelief={true} />
                </div>
            </div>
        );
    }

    public renderSearch() {
        return <Search onClick={(item) => this.handleClick(item)} className="SearchInput" />;
    }

    public handleClick(item) {
        LocalStorage.setItem('reliefAirport', item);
        this.setState({ airport: item });
    }
}

export const ReliefWingScreen = ReliefWingLayout(ReliefWingScreenComponent);
