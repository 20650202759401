import * as React from 'react';
import { toast } from 'react-toastify';
import { request } from '../../../../../../utilities/widget-helpers/request';
import { ToastMessages } from '../../../../../notifications/toast-messages';
import { Form } from '../../../../../ui-components/form/form';
import { TextInput } from '../../../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../../ui-components/inputs/select/select';
import { ValidationInput } from '../../../../../ui-components/inputs/validation-input/validation-input';
import { WidgetModal } from '../../../../widget-modal';
import {
	placeholders,
	typeOpts,
} from '../contributions/authority-contacts.helper';

export class AuthorityContactsCreate extends WidgetModal<any, any> {
	public state: any = {
		validityError: false,
	};

	public form: any = new Form(
		{
			name: this.props?.data?.name || '',
			type: { value: this.props?.data?.type || '', mandatory: true },
			details: {
				value: this.props?.data?.details || '',
				mandatory: true,
			},
		},
		(changed) => this.handleFormChange(changed)
	);

	public handleFormChange(changed) {
		this.setState({ buttonDisabled: !changed });
	}

	public getTitle() {
		return this.props?.data ? 'Edit contact' : 'Add new contact';
	}

	public renderForm(): React.ReactElement {
		const error = this.state.validityError;

		return (
			<form className='p-4'>
				<div className='display-flex flex-column flex-md-row'>
					<TextInput
						autoFocus={true}
						id='contactFirst'
						field={this.form.getField('name')}
						classes='py-2'
						textType='camel'
						labelInfos={{ label: 'Name' }}
					/>

					<Select
						field={this.form.getField('type')}
						options={formatOptions(typeOpts, false)}
						classes='py-2 left-m'
						labelInfos={{
							label: 'Type',
						}}
					/>
				</div>

				{this.renderInput()}

				{error && (
					<span className='col-24 palette--c-red-2 pb-2'>
						{error}
					</span>
				)}
			</form>
		);
	}

	public renderInput() {
		const typeFromSelect = this.form.getValue('type');
		const type = (typeFromSelect || '').toLowerCase();

		return (
			<ValidationInput
				placeholder={placeholders[type]}
				classes='py-2'
				type={type}
				field={this.form.getField('details')}
				onError={() => this.onErrorToggle(true)}
				onSuccess={() => this.onErrorToggle(false)}
				labelInfos={{ label: 'Details' }}
			/>
		);
	}

	public onErrorToggle(isError) {
		this.setState({ validityError: isError });
	}

	public onClose(): void {
		this.form.clearForm();
		this.props?.onClose();
	}

	public async handleSave(): Promise<void> {
		this.setState({ buttonDisabled: true, saveLoading: true });

		const data = this.form.generateJSON();

		await request(
			`/${
				this.props.isCountry ? 'country' : 'airport'
			}authoritycontacts/merge`,
			'contact',
			() => this.handleSuccessModal(),
			(err) =>
				toast.error(<ToastMessages error={err} />, { theme: 'light' })
		).create(
			`/${this.props.isCountry ? 'country' : 'airport'}authoritycontacts`,
			data,
			this.form?.getDifferences(),
			{
				[`parent${
					this.props.isAuthorityCreate ? 'ContributionId' : 'Aid'
				}`]: this.props?.aid,
			}
		);

		this.setState({ buttonDisabled: false, saveLoading: false });
	}

	public handleSuccessModal() {
		if (this.notif$) {
			this.notif$.open();
		}

		this.modal$.close();
	}
}
