import * as React from 'react';
import { arrayToClass } from '../../utilities/helper-fuctions';
import { ConfirmationModal } from '../notifications/confirmation-modal';
import { ErrorPresenter } from '../notifications/error-presenter';
import { NotificationModal } from '../notifications/notification-modal';
import { FormButtons } from '../ui-components/hoverable-buttons/form-modal-buttons/form-buttons';
import { Modal } from '../ui-components/modal/modal';
import { Spinner } from '../ui-components/spinner/spinner';

export class WidgetModal<P, S> extends React.Component<P & any, S & any> {
	public notif$: NotificationModal;
	public modal$: Modal;
	public deleteModal$: ConfirmationModal;

	public state = {
		saveLoading: false,
		buttonDisabled: true,
	};

	public componentDidMount() {
		if (this.modal$) {
			this.open();
		}
	}

	public open() {
		this.modal$.open();
	}

	public render(): React.ReactElement {
		const classes = arrayToClass([
			'WidgetModal',
			this.props.modalClasses ? this.props.modalClasses : '',
		]);

		return (
			<div className={classes}>
				<Modal
					ref={(ref: any) => (this.modal$ = ref)}
					title={() =>
						this.getTitle() ? this.renderHeader() : false
					}
					content={() => this.renderContent()}
					footer={() => this.renderFooter()}
					onDismissRequest={() => this.onClose()}
				/>

				<ConfirmationModal
					title={`Are you sure you want to remove this ${this.getType()}?`}
					onClick={() => this.onDelete()}
					close={() => this.deleteModal$.close()}
					ref={(ref: any) => (this.deleteModal$ = ref)}
				/>

				<NotificationModal
					ref={(ref: any) => (this.notif$ = ref)}
					title={this.getType()}
					onClose={(isDelete) => this.onClose(isDelete)}
				/>
			</div>
		);
	}

	public renderHeader() {
		return (
			<div className='display-flex align-items-center p-4'>
				<h4 className='palette--c-neutral-6'>{this.getTitle()}</h4>
			</div>
		);
	}

	public getTitle(): any {
		return false;
	}

	public renderFooter(): any {
		const type = this.getType();

		return (
			<FormButtons
				isDelete={
					type !== 'airport' &&
					type !== 'operations' &&
					this.props?.isEdit
				}
				onClose={() => this.onClose()}
				onSave={() => this.onSave()}
				onDelete={() => this.deleteModal$.open()}
				saveDisabled={this.state?.buttonDisabled}
				loading={this.state?.saveLoading}
				type={type}
			/>
		);
	}

	public renderContent(): any {
		return (
			<div className='w-100'>
				{!this.props?.error && this.renderForm()}
				{!!this.props?.error && this.renderError()}
			</div>
		);
	}

	public renderLoading() {
		return (
			<div className='display-flex align-imtes-center p-6'>
				<Spinner size='large' />
			</div>
		);
	}

	public renderError() {
		return (
			<div className='display-flex align-imtes-center p-6'>
				<ErrorPresenter error={this.props?.error} />;
			</div>
		);
	}

	public getType() {
		return 'item';
	}

	public renderForm(): any {
		return null;
	}

	public onClose(isDelete = false) {
		//
	}

	public onDelete() {
		//
	}

	public onSave() {
		//
	}
}
