export const providerInfos = {
        vhf:
            'The radio frequency for voice communication between the aircraft and the Provider on ground expressed in Mhz. This is in the range of 118.000 and 136.975.',
        sita:
            'The 7-letter address used to communicate with this provider at this location, or the most relevant location.',
        aftn:
            'The 8-letter address used to communicate with this provider at this location, or the most relevant location.',
        governingDiscrict:
            'Depending on the country this may be a province, state, county, region or any other subdivision which is used in addressing mail.',
        handlingProvider: {
            paxMeetingCoordinates:
                'A public area, accessible to private vehicles, where passengers can meet with a representative of this Handling Provider.',
            aircraftParkingPolygon:
                'The airside location where aircraft served by this Handling Provider are usually parked.',
            fboBuildingPolygon:
                'The area within the main building from which this Handling Provider performs its core services.',
        },
        fuelProvider: {
            serviceLevel: '|RESELLER| An agent that can arrange fuel services and is not located on the airfield. \n|LOCAL| A company located on the airfield with a fuel bowser, directly supplying fuel to aircraft.'
        }
    };
