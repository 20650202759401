import * as React from 'react';
import { arrayToClass } from '../../../../utilities/helper-fuctions';
import { Spinner } from '../../spinner/spinner';
import { HoverableButton } from '../hoverable-buttons';
import { FormModalButtons } from './form-modal-button';

export class FormButtons extends FormModalButtons<any, any> {
	public renderExtra(): any {
		const isDisabled = this.props?.saveDisabled;

		return (
			<HoverableButton
				disabled={this.props.saveDisabled}
				className='border-radius-1'
				colorType={isDisabled ? 'disabled' : 'avio-green'}
				onClick={() => this.onSave()}
				renderChildren={(colors) => this.renderButtonChildren(colors)}
			/>
		);
	}

	public renderButtonChildren(colors) {
		if (this.props?.loading) {
			return <Spinner size='small' className='position-absolute' />;
		} else {
			const classes = arrayToClass(['mr-1 mb-0', colors]);
			return (
				<div className='w-100 display-flex align-items-center'>
					<span className={classes}>Send</span>
				</div>
			);
		}
	}
}
