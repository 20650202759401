import { get } from 'lodash';
import * as React from 'react';
import { PreLoader } from '../../../utilities/pre-loader';
import { Form } from '../../ui-components/form/form';
import { HoverableButton } from '../../ui-components/hoverable-buttons/hoverable-buttons';
import { Checkbox } from '../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { StatusMarker } from '../../ui-components/statusmarker/status-marker';
import { WidgetModal } from '../../widgets/widget-modal';
import { TokenList } from './token-list';

class TokenModalComponent extends WidgetModal<any, any> {
    public state = {
        showExpiredTokens: false,
        showAllTokens: false,
    };

    public form = new Form({
        expired: { value: false, trigger: () => this.setState({ showExpiredTokens: this.form.getValue('expired') }) },
        all: { value: false, trigger: () => this.setState({ showAllTokens: this.form.getValue('all') }) },
    });

    public onClose() {
        this.setState({ response: false });
        this.props?.router?.navigate({
            pathname: '/admin/tokens',
            search: window.location.search,
        });
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.modal$.close();
    }

    public getTitle() {
        return 'User details - tokens';
    }

    public renderContent(): React.ReactElement {
        const data = this.props?.response;

        return (
            <div className="w-100 p-4">
                <div className="display-flex mb-2">
                    <div>
                        <StatusMarker tColor="secondary-4" bgColor="secondary-1" text={get(data, 'aid')} type="tag" />
                    </div>

                    <div className="flex-fill display-flex justify-content-end">
                        <HoverableButton
                            className="border-radius-1 mr-2"
                            onClick={() => this.redirect()}
                            title="Check user"
                            colorType="transparent-green"
                        />
                    </div>
                </div>

                <h4 className="palette--c-neutral-6">{get(data, 'name')}</h4>
                <p className="palette--c-neutral-6">{get(data, 'login')}</p>

                <div className="w-100 display-flex flex-column align-items-end">
                    <Checkbox
                        field={this.form.getField('expired')}
                        labelOnLeft={true}
                        labelInfos={{
                            label: 'Show active tokens',
                        }}
                    />
                    <Checkbox
                        field={this.form.getField('all')}
                        labelOnLeft={true}
                        classes="pb-2"
                        labelInfos={{
                            label: 'Show all token types',
                        }}
                    />
                    <TokenList
                        aid={get(this.props, 'aid')}
                        showAllTokens={this.state.showAllTokens}
                        showExpiredTokens={this.state.showExpiredTokens}
                    />
                </div>
            </div>
        );
    }

    public renderFooter(): React.ReactElement {
        return (
            <div className="w-100 display-flex justify-content-end p-4">
                <HoverableButton
                    className="border-radius-1 mr-2"
                    colorType="cancel"
                    title="Cancel"
                    onClick={() => this.onClose()}
                />
            </div>
        );
    }

    public redirect() {
        if (this.props?.router?.navigate) {
            this.props?.router?.navigate(`/admin/users/${get(this.props, 'aid')}`);
        }
    }
}

export const TokenModal: any = PreLoader(TokenModalComponent);
