import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
	arrayToClass,
	handleCoordinates,
	redirectToLogin,
} from '../../../../utilities/helper-fuctions';
import { PreLoader } from '../../../../utilities/pre-loader';
import { RequestManager } from '../../../../utilities/request';
import { airportInfos } from '../../../../utilities/widget-helpers/airport-infos';
import { request } from '../../../../utilities/widget-helpers/request';
import { ToastMessages } from '../../../notifications/toast-messages';
import { Form } from '../../../ui-components/form/form';
import { Checkbox } from '../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { CoordinatesInput } from '../../../ui-components/inputs/coordinates-input';
import { Input } from '../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../ui-components/inputs/inputs/text-input';
import { MeasurementInput } from '../../../ui-components/inputs/measure-input/measurement-input';
import { SearchableSelect } from '../../../ui-components/inputs/select/searchable-select';
import { VariationInput } from '../../../ui-components/inputs/variation-input';
import { Tooltip } from '../../../ui-components/popover/popover';
import { StatusMarker } from '../../../ui-components/statusmarker/status-marker';
import { WidgetModal } from '../../widget-modal';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class GeneralDataFormComponent extends WidgetModal<any, any> {
	public state: any = {
		...this.state,
		weather: [],
		label: 'Local Identifier',
		icao: false,
		iata: false,
		localIdentifier: false,
		disableForm: this.props?.response?.archived,
	};

	public form = new Form(
		{
			name: {
				value: this.props.updateName || this.props?.response?.name,
				mandatory: true,
			},
			icao: {
				value: this.props?.response?.icao,
				trigger: () => this.checkCodeUnicity('icao'),
			},
			iata: {
				value: this.props?.response?.iata,
				trigger: () => this.checkCodeUnicity('iata'),
			},
			localIdentifier: {
				value: this.props?.response?.localIdentifier || '',
				trigger: () => this.checkCodeUnicity('localIdentifier'),
			},
			elevation: this.props?.response?.elevation,
			variation: this.props?.response?.variation,
			latitude: {
				value: this.props?.response?.coordinates?.latitude,
				trigger: () => this.getTimezone(),
				mandatory: true,
			},
			longitude: {
				value: this.props?.response?.coordinates?.longitude,
				trigger: () => this.getTimezone(),
				mandatory: true,
			},
			country: {
				value: this.props?.response?.country,
				trigger: () => this.changeLocalIdentifier(),
				mandatory: true,
			},
			servedCity: this.props?.response?.servedCity,
			timeZone: this.props?.response?.timeZone,
			referenceWeatherStation:
				this.props?.response?.referenceWeatherStation,
			archived: {
				value: this.props?.response?.archived || false,
				trigger: () =>
					this.setState({
						disableForm: this.form.getValue('archived'),
					}),
			},
		},
		(changed) => this.handleButtonChange(changed)
	);

	public handleButtonChange(changed) {
		let isErrorFalse;
		if (this.state?.icao?.error == true) {
			isErrorFalse = true;
		} else if (this.state?.iata?.error == true) {
			isErrorFalse = true;
		} else if (this.state?.localIdentifier?.error == true) {
			isErrorFalse = true;
		} else {
			isErrorFalse = false;
		}

		let buttondis;
		if (changed == false) {
			buttondis = true;
		} else if (isErrorFalse == true) {
			buttondis = true;
		} else {
			buttondis = false;
		}

		this.setState({ buttonDisabled: buttondis });
	}
	public async fetchData(): Promise<void> {
		return RequestManager.get(`/airports/${get(this.props, 'aid', '')}`);
	}

	public onClose(): any {
		this.form.clearForm();

		if (this.props.onClose) {
			this.props.onClose();
		}

		if (this.modal$) {
			this.modal$.close();
		}
	}

	public async getTimezone(): Promise<void> {
		const lat = parseFloat(this.form.getValue('latitude'));
		const lon = parseFloat(this.form.getValue('longitude'));

		if (!lat || !lon) {
			return;
		}

		try {
			const response = await RequestManager.get(
				'/free/timezone/coordinates',
				{ lat: lat, lon: lon },
				{ noHeader: true }
			);

			if (!response) {
				throw new Error('there was something which caused an error.');
			}

			this.form.setValue('timeZone', response);
		} catch (err) {
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
			redirectToLogin(err);
		}
	}

	public changeLocalIdentifier() {
		const value = this.form.getValue('country');
		this.setState({
			label: value?.['localIdentifierName'] || 'Local Identifier',
		});
	}

	public async checkCodeUnicity(type): Promise<any> {
		try {
			const value = this.form.getValue(type);

			if (!value) {
				return;
			}

			const response = await RequestManager.get(
				`/free/airports/${type}/${value}`,
				{},
				{ noHeader: true }
			);

			const ogAid = get(this.state.response, 'aid');

			if (ogAid === get(response, 'aid')) {
				this.setState({ [type]: false });
				return;
			}

			this.setState({ [type]: { response: response, error: true } });
		} catch (err) {
			this.setState({ [type]: false });
		}
	}

	public getTitle() {
		return this.props.isEdit
			? 'Suggest General data'
			: 'Create new airport';
	}

	public getType() {
		return 'airport';
	}

	public renderForm(): any {
		const isAdmin = (this.props?.user?.roles || []).includes('ADMIN');

		return (
			<form className='p-4'>
				<TextInput
					field={this.form.getField('name')}
					classes='py-2'
					textType='camel'
					labelInfos={{
						label: 'Name',
					}}
					disabled={this.state?.disableForm}
				/>

				<div className='FormContainer'>
					{this.renderCode('icao', 4, 4, 'ICAO')}
					{this.renderCode('iata', 3, 3, 'IATA', true)}
					{this.renderCode(
						'localIdentifier',
						2,
						64,
						this.state.label,
						true
					)}
				</div>

				<MeasurementInput
					type='length'
					field={this.form.getField('elevation')}
					classes='py-2'
					labelInfos={{
						info: airportInfos.elevation,
						label: 'Elevation',
					}}
					disabled={this.state?.disableForm}
				/>

				<VariationInput
					classes='py-2'
					field={this.form.getField('variation')}
					labelInfos={{
						info: airportInfos.variation,
						label: 'Variation',
					}}
					disabled={this.state?.disableForm}
				/>

				<CoordinatesInput
					classes='py-4'
					lat={this.form.getField('latitude')}
					lng={this.form.getField('longitude')}
					labelInfos={{
						info: airportInfos.coordinates,
						label: 'Coordinates',
					}}
					disabled={this.state?.disableForm}
				/>

				<div className='display-flex flex-column flex-md-row'>
					<SearchableSelect
						qeryValue='name'
						title='name'
						classes='py-2'
						field={this.form.getField('country')}
						endpoint={'/free/countries/search'}
						labelInfos={{
							label: 'Country',
						}}
						disabled={this.state?.disableForm}
					/>
					<Input
						field={this.form.getField('servedCity')}
						classes='py-2 left-m'
						labelInfos={{
							label: 'Served city',
						}}
						disabled={this.state?.disableForm}
					/>
					<Input
						field={this.form.getField('timeZone')}
						classes='py-2 left-m'
						disabled={!isAdmin || this.state?.disableForm}
						labelInfos={{
							label: 'Timezone',
							info: airportInfos.timeZone,
						}}
					/>
				</div>

				<SearchableSelect
					isFirst={true}
					value='aid'
					title='name'
					classes='py-2'
					field={this.form.getField('referenceWeatherStation')}
					endpoint={'/airports/search?includeArchived=true'}
					labelInfos={{
						label: 'Reference weather station',
					}}
					disabled={this.state?.disableForm}
				/>

				<Checkbox
					field={this.form.getField('archived')}
					labelInfos={{
						label: 'Permanently Closed',
					}}
					classes='py-2'
				/>
			</form>
		);
	}

	public renderCode(type, charMin, charMax, label, isLeft = false) {
		const classes = arrayToClass(['py-2', isLeft ? 'left-m' : '']);
		const inputClasses = arrayToClass([
			`palette--bc-${
				!!get(this.state, `${type}.error`) ? 'red-2' : 'neutral-4'
			}`,
		]);

		return (
			<Tooltip
				triggerClasses='w-100'
				isOpen={!!get(this.state, `${type}.response`)}
				tooltip={this.renderPopover(type)}
				trigger={
					<Input
						field={this.form.getField(type)}
						id={type}
						classes={classes}
						inputClassName={inputClasses}
						minLength={charMin}
						maxLength={charMax}
						onFocus={() => this.handleOnFocus(type)}
						labelInfos={{
							label: label,
							info: airportInfos[type],
						}}
						disabled={this.state?.disableForm}
					/>
				}
			/>
		);
	}

	public renderPopover(type: any): any {
		const airport = get(this.state, `${type}.response`);

		return (
			<div className='PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1 display-flex'>
				<div>
					<p className='palette--c-neutral-1 m-0'>
						An airport with code {get(airport, type)} already exists
						in our database.
					</p>
					<div className='display-flex align-items-center flex-wrap'>
						<p className='palette--c-neutral-1 m-0'>
							{' '}
							To contribute data to it, please visit
						</p>
						<div className='display-block'>
							<StatusMarker
								tColor='neutral-6'
								bgColor='neutral-1'
								text={get(airport, 'name', '')}
								type='aid'
								classNames='ml-2 pointer'
								onClick={() =>
									this.handleClick(get(airport, 'aid'))
								}
							/>
						</div>
						<p className='palette--c-neutral-1 m-0'>.</p>
					</div>
				</div>
				<div className='display-block'>
					<div
						className='pointer ml-2'
						onClick={() => this.setState({ [type]: false })}
					>
						<p className='material-icons palette--c-neutral-1'>
							cancel
						</p>
					</div>
				</div>
			</div>
		);
	}

	public handleOnFocus(type) {
		if (get(this.state.icao, 'response') && type !== 'icao') {
			this.setState({ icao: { response: false, error: true } });
		}

		if (get(this.state.iata, 'response') && type !== 'iata') {
			this.setState({ iata: { response: false, error: true } });
		}

		if (
			get(this.state.localIdentifier, 'response') &&
			type !== 'localIdentifier'
		) {
			this.setState({
				localIdentifier: { response: false, error: true },
			});
		}
	}

	public handleClick(aid) {
		window.open(
			`${window.location.origin}/airports/${aid}/general-data`,
			'_blank'
		);
	}

	public createBody(): any {
		const obj: any = this.form.generateJSON();

		if (obj.longitude || obj.latitude) {
			obj.coordinates = {
				latitude: obj.latitude,
				longitude: obj.longitude,
			};
			delete obj.latitude;
			delete obj.longitude;
		}

		return obj;
	}

	public async onSave(): Promise<void> {
		this.setState({ saveLoading: true, buttonDisabled: true });

		const airport = await this.createBody();
		const req = request(
			'/airports/merge',
			'general-data',
			() => this.handleSuccessModal(),
			(err) => this.handleError(err)
		);
		const changes = handleCoordinates(
			this.form?.getDifferences(),
			'coordinates',
			true
		);

		if (this.props.isEdit) {
			const data = { ...this.props?.response, ...airport };
			await req.edit(`/airports/${this.props?.aid}`, data, changes);
		} else {
			await req.create('/airports', airport, changes);
		}

		this.setState({ saveLoading: false, buttonDisabled: false });
	}

	public handleError(err) {
		toast.error(<ToastMessages error={err} />), { theme: 'light' };
	}

	public handleSuccessModal() {
		if (this.notif$) {
			this.notif$.open();
		}
		this.modal$.close();
		this.form.clearForm();
	}
}

export const GeneralDataForm: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(PreLoader(GeneralDataFormComponent));
