import { get } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { FormButtons } from '../ui-components/hoverable-buttons/form-modal-buttons/form-buttons';
import { WidgetModal } from '../widgets/widget-modal';
import './aip-modal.scss';
import { Airport } from './airport';
import { RunwayWrapper } from './runway-wrapper';

export class AIPModal extends WidgetModal<any, any> {
    public airport$;
    public runways$: RunwayWrapper;

    public airport;
    public runways;

    public state = {
        buttonDisabled: true,
    };

    public getTitle() {
        return 'AIP data entry';
    }

    public renderForm() {
        const aid = get(this.props, 'data.aid') || false;

        return (
            <div className="p-3">
                <Airport
                    ref={(ref: any) => (this.airport$ = ref)}
                    aid={aid}
                    next={(aid) => this.next(aid)}
                    data={this.props.data}
                    disableButton={(value) => this.setState({ buttonDisabled: value })}
                />
                <RunwayWrapper
                    ref={(ref: any) => (this.runways$ = ref)}
                    aid={aid}
                    disableButton={(value) => this.setState({ buttonDisabled: value })}
                />
            </div>
        );
    }

    public renderFooter() {
        return (
            <FormButtons
                onSave={() => this.handleSave()}
                saveDisabled={this.state?.buttonDisabled}
                onClose={() => this.onClose()}
                isDelete={false}
            />
        );
    }

    public onClose(isDelete?: boolean): void {
        this.modal$.close();
        this.props?.onClose();
    }

    public async handleSave() {
        await this.airport$.saveAirport();
    }

    public next(aid) {
        this.saveRunways(aid);
    }

    public async saveRunways(aid) {
        const runways: any = await this.runways$.saveRunways(aid);

        if (!runways) {
            toast.success('Everything is successfully saved!', { theme: 'light' });
            this.modal$.close();
        }
    }
}
