import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { OpeningHoursContributions } from '../../../../../components/widgets/airport/opening-hours/opening-hours-contribution/opening-hours-contributions';
import { connectNLP } from '../../../../../utilities/connect-navigate';
import { Contributions } from '../contributions';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class CiqContributionsComponent extends Contributions<any, any> {
	public endpoint(): any {
		const user = get(this.props, 'user');
		const userRoles = get(user, 'roles');
		const isAdmin = userRoles.includes('ADMIN');
		const system = get(this.props, 'system');
		const systemInfo = system === true ? '/system' : `/users/${system}`;
		const otherInfo = isAdmin ? '' : `/users/${get(user, 'aid')}`;

		const systemAdminOrGeneral = isAdmin && system ? systemInfo : otherInfo;

		return `/ciqavailabilities/contributions${systemAdminOrGeneral}`;
	}

	public getModalEndpoint() {
		return `/ciqavailabilities/${get(this.state.item, 'entityAid')}`;
	}

	public renderModalContent(ctx): React.ReactElement {
		return (
			<OpeningHoursContributions
				close={() => this.onModalClose()}
				availability='ciq'
				item={this.state.item}
				onSuccess={() => this.handleModalSuccess()}
				original={get(ctx.state, 'response')}
			/>
		);
	}
}

export const CiqContributions: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(connectNLP(CiqContributionsComponent));
