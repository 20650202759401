import { get, uniq } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { LocalstorageActions } from '../../actions/localstorage.actions';
import { SettingsActions } from '../../actions/settings.actions';
import { connectNLP } from '../../utilities/connect-navigate';
import {
	arrayToClass,
	debounce,
	replaceQuery,
} from '../../utilities/helper-fuctions';
import { LocalStorage } from '../../utilities/local-storage';
import { AirportCard } from '../cards/airport/airport.card';
import { Dropdown } from '../ui-components/dropdown/dropdown';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';
import { Spinner } from '../ui-components/spinner/spinner';
import { GeneralDataForm } from '../widgets/airport/general-data/general-data-form';
import { CountryListRenderer } from './country-list-renderer';
import './search.scss';
import { Searchable } from './searchable';

const mapStateProps = (store: any) => ({
	user: store.user.user,
	search: store.localStorage.search,
	modalIdentifier: store.localStorage.modalIdentifier,
});

const mapDispatchProps = (dispatch: any) => ({
	setSearch: (search: any) => dispatch(LocalstorageActions.setSearch(search)),
	setIdentifier: (identifier) =>
		dispatch(LocalstorageActions.setModalIdentifier(identifier)),
	setLastSearched: (data) => dispatch(SettingsActions.setLastSearched(data)),
});

class SearchComponent extends Searchable<any> {
	public dd$: Dropdown;

	public state = {
		...this.state,
		isRedirect: false,
		query: '',
	};

	public page = 0;

	public handleQueryChange() {
		const value = this.state?.query;

		if (this.props.setSearch) {
			this.props.setSearch(value);

			if (value) {
				this.search({
					query: this.props?.search || '',
					size: this.getSize(),
				});
			}
		}
	}

	public endpoint() {
		return this.props.isCountry
			? '/free/countries/search'
			: '/airports/search?includeArchived=true';
	}

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	): void {
		if (this.props.size !== prevProps.size) {
			this.search({
				query: this.props?.search || '',
				size: this.getSize(),
			});
		}
	}

	public getSize(): number {
		return this.props.size || 3;
	}

	public render(): React.ReactElement {
		const classes = arrayToClass([
			this.props.className,
			'AirportSearch w-100',
		]);

		return (
			<div className={classes}>
				<Dropdown
					className='w-100'
					backdropClasses='palette--bgc-neutral-backdrop'
					ignoreMaxWidth={true}
					ignoreMinWidth={true}
					direction='left'
					trigger={() => this.renderSearch()}
					dropdown={() => this.renderDropdown()}
					ref={(ref: Dropdown) => (this.dd$ = ref)}
					onBackdropClick={() => this.handleClose()}
				/>
				{this.props.modalIdentifier === 'create_new_airport' && (
					<GeneralDataForm
						isEdit={false}
						updateName={this.state?.query}
						onClose={() => this.handleModalClose()}
					/>
				)}
			</div>
		);
	}

	public handleModalClose() {
		if (this.props.setIdentifier) {
			this.props.setIdentifier(null);
		}
	}

	public renderSearch() {
		return (
			<input
				value={this.state?.query}
				className='SearchInput w-100'
				onChange={(event) => {
					this.setState({ query: event?.target?.value });
					debounce(() => this.handleQueryChange())();
				}}
				placeholder={`Start typing ${
					!!this.props.isCountry
						? ' a country name'
						: ' Location, ICAO or IATA'
				}`}
			/>
		);
	}

	public renderNotFound(): React.ReactElement {
		const form = this.state?.query;

		return (
			<div className='w-100 display-flex flex-column justify-content-center align-items-center palette--bgc-neutral-1 p-6'>
				<h4 className='m-0 palette--c-neutral-6 mb-6'>
					{this.props.isCountry
						? 'There is no any country with this search: '
						: 'Are we missing something?'}{' '}
					{form}
				</h4>
				<HoverableButton
					className='border-radius-1'
					colorType='avio-green'
					onClick={() => this.handleClick()}
					titleLeft={
						this.props.isCountry
							? 'Search in airport list'
							: 'Help us and create airport'
					}
					icon='arrow_forward'
				/>
			</div>
		);
	}

	public handleRedirect() {
		this.setState({ isRedirect: true });

		if (this.props.isDetails) {
			this.navigate(
				'/search',
				replaceQuery({ query: this.state?.query })
			);
		} else {
			this.navigate('/');
		}
	}

	public handleClick(): void {
		if (this.props.isCountry) {
			this.handleRedirect();
		} else {
			if (this.props.setIdentifier) {
				this.props.setIdentifier('create_new_airport');
			}
		}
	}

	public renderMoreResult(): any {
		const allResults = get(this.state.response, 'page.totalResults', 0);
		const moreResults = allResults - this.getSize();

		if (allResults <= 3 && allResults > 0) {
			return (
				<div className='w-100 MoreResult palette--c-neutral-4 display-flex justify-content-center align-items-center pointer my-4'>
					<p className='palette--c-secondary-4 m-0'>
						No more results
					</p>
				</div>
			);
		}

		if (moreResults <= 0) {
			return null;
		}

		return (
			<div
				className='w-100 MoreResult palette--c-neutral-4 display-flex justify-content-center align-items-center pointer'
				onClick={() =>
					!this.props.isCountry && this.handleShowMoreClick()
				}
			>
				<div className='display-flex justify-content-center align-items-center pointer my-4'>
					<p className='palette--c-secondary-4 m-0'>
						Show all{' '}
						{allResults
							.toString()
							.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{' '}
						match
					</p>
					<span className='palette--c-secondary-4 material-icons ml-2 fs-large'>
						arrow_forward
					</span>
				</div>
			</div>
		);
	}

	public renderDropdown() {
		const items = get(this.state.response, 'content', []);
		const query = this.props.search;

		return (
			<div className='AirportSearchDropdown mb-4 w-100 palette--bgc-neutral-1 border-radius-1 elevation-3'>
				{!this.props.isCountry &&
					!this.state.loading &&
					!!items &&
					!!items.length &&
					!!query &&
					items.map((item: any, index: number) =>
						this.renderAirportCard(item, index)
					)}
				{this.props.isCountry && !!items.length && !!query && (
					<CountryListRenderer
						items={items}
						fetch={() => this.handleFetch()}
						isLoading={this.state.loading}
						isMax={
							get(this.state.response, 'page.totalPages') ===
							this.page
						}
						onClick={(item) => this.handleCountryCardClick(item)}
					/>
				)}
				{this.state.loading && !(items || []).length && (
					<Spinner size='large' />
				)}
				{!this.state.loading &&
					(!items || !items.length) &&
					!!query &&
					this.renderNotFound()}
				{!this.state.loading && !query && this.renderBeforeType()}
				{!this.state.loading &&
					!!query &&
					!this.props.isCountry &&
					this.renderMoreResult()}
			</div>
		);
	}

	public handleFetch() {
		const newPage = this.page + 1;
		this.search(
			{
				query: this.props?.search || '',
				size: this.getSize(),
				page: newPage,
			},
			true
		);
		this.page = newPage;
	}

	public renderBeforeType(): React.ReactElement {
		return (
			<div className='w-100 display-flex flex-column justify-content-center align-items-center palette--bgc-neutral-1 p-6'>
				<h4 className='m-0 palette--c-neutral-6 mb-6'>
					Start typing{' '}
					{this.props.isCountry
						? 'a country name'
						: 'Location, ICAO or IATA'}{' '}
					and search results will appear here.
				</h4>
				{!this.props.isCountry && (
					<div className='display-flex flex-column align-items-center'>
						<p className='m-0 palette--c-neutral-5 mb-6'>or</p>
						<HoverableButton
							className='border-radius-1'
							onClick={() => this.handleShowMoreClick()}
							titleLeft='Go to airport list'
							icon='arrow_forward'
							colorType='avio-green'
						/>
					</div>
				)}
			</div>
		);
	}

	public renderAirportCard(item: any, index: number): React.ReactElement {
		return (
			<div
				key={index}
				className='w-100 border-bottom-1 palette--bc-neutral-3 pointer'
			>
				<AirportCard
					data={item}
					className='elevation-0'
					onClick={() => this.handleAirportCardClick(item.aid)}
				/>
			</div>
		);
	}

	public handleClose(): void {
		this.setState({ query: '' });
		if (this.props.setSearch) {
			this.props.setSearch('');
		}
		this.dd$.close();
	}

	public handleAirportCardClick(aid: any) {
		this.setState({ query: '' });
		const prevSearched = LocalStorage.getItem('lastSearched') || [];

		prevSearched.unshift(aid);
		const individualized = uniq(prevSearched);
		const sliced =
			(individualized || []).length > 3
				? individualized.slice(0, 3)
				: individualized;

		if (this.props.setLastSearched) {
			this.props.setLastSearched({
				airports: sliced,
				countries: LocalStorage.getItem('lastSearchedCountries'),
			});
		}

		this.handleClose();

		this.navigate(`/airports/${aid}`);
	}

	public handleCountryCardClick(iso: any) {
		this.setState({ query: '' });
		const prevSearched =
			LocalStorage.getItem('lastSearchedCountries') || [];
		prevSearched.unshift(iso);

		const individualized = uniq(prevSearched);
		const sliced = individualized.slice(0, 3);

		if (this.props.setLastSearched) {
			this.props.setLastSearched({
				airports: LocalStorage.getItem('lastSearched'),
				countries: sliced,
			});
		}

		this.handleClose();

		this.navigate(`/countries/${iso}`);
	}

	public handleShowMoreClick() {
		if (this.props.onShowAllClick) {
			this.props.onShowAllClick();
		}

		this.dd$.close();
		this.navigate('/search', replaceQuery({ query: this.props?.search }));
	}

	public navigate(path, extraParams = '') {
		this.props?.router?.navigate({ pathname: path, search: extraParams });
	}
}

export const Search: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(connectNLP(SearchComponent));
