export const logoLink =
	'https://aviowiki.com/wp-content/uploads/2022/06/aviowiki-logo-color.png';

export const defaultMap =
	'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}';
export const accessToken =
	'pk.eyJ1IjoiYXZpb3dpa2kiLCJhIjoiY2szcWNhbXFmMDA2eTNxczU0ZTZ6aWlkcyJ9.GCK53KHn79GE5-NF2YfNtQ';
export const googleApiKey = 'AIzaSyAyAiKu-JbSPteD0X_GdhoGNCcH26yvmVg';

export const abbreviation = {
	RWY: 'runway',
	CLSD: 'closed',
	TWY: 'taxiway',
	AVBL: 'available',
	BTN: 'between',
	THR: 'threshold',
	APCH: 'approach',
	DEP: 'departure',
	ARR: 'arrival',
	PROC: 'procedure',
	RTE: 'route',
	AUTH: 'authorized',
	WIP: 'work-in-progress',
	CONST: 'construction',
	'U/S': 'unserviceable',
	ACFR: 'aircraft',
	BFR: 'before',
	AD: 'aerodrome',
	SKED: 'scheduled',
	FLT: 'flights',
	MET: 'meteorological',
	MON: 'Monday',
	TUE: 'Tuesday',
	WED: 'Wednesday',
	THU: 'Thursday',
	FRI: 'Friday',
	SAT: 'Saturday',
	SUN: 'Sunday',
	avgas: 'AVGAS',
	'jet a1': 'JET A1',
	OPS: 'operation',
	atis: 'ATIS',
	BCST: 'broadcast',
	AFT: 'after',
	SFC: 'surface',
	tora: 'TORA',
	toda: 'TODA',
	asda: 'ASDA',
	lda: 'LDA',
	NR: 'number',
	EXC: 'except',
	ATZ: 'aerodrome traffic zone',
	TGL: 'touch-and-go landing',
	HOSP: 'hospital',
	MAINT: 'maintenance',
	JAN: 'January',
	FEB: 'Feburary',
	APR: 'April',
	MAY: 'May',
	JUN: 'June',
	JUL: 'July',
	AUG: 'August',
	SEPT: 'September',
	OCT: 'October',
	NOV: 'November',
	DEC: 'December',
	ACFT: 'aircraft',
	RMK: 'Remark',
	PN: 'prior notice',
	NGT: 'night',
	FLW: 'following',
	XNG: 'crossing',
	DLY: 'daily',
	OPN: 'open',
	TKOF: 'take-off',
	HR: 'hours',
	LDG: 'landing',
	OBST: 'obstacle',
	DIST: 'distance',
	EMERG: 'emergency',
	FM: 'from',
	TFC: 'traffic',
	SUBJ: 'subject to',
	DLA: 'delay',
};

export const aidNamespaces = {
	user: 'USR',
	airport: 'APT',
	runway: 'RWY',
	aircraft: 'ACF',
	provider: 'PRV',
	region: 'RGN',
	airportNote: 'ONA',
	countryNote: 'ONC',
	regionNote: 'ONR',
	enericProduct: 'PVP',
	fuelProduct: 'FPR',
	fuelCost: 'FCO',
	fileReference: 'FIL',
	regionAuthority: 'RAU',
	regionAuthorityContact: 'RAC',
	airportAuthority: 'AAU',
	airportAuthorityContact: 'AAC',
	countryAuthority: 'CAU',
	countryAuthorityContact: 'CAC',
};
