import { format, isSameDay } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { connectNLP } from '../../../../utilities/connect-navigate';
import { isInQuery } from '../../../../utilities/helper-fuctions';
import { ContributeButton } from '../../../other/contribute-button';
import { TextRenderer } from '../../../other/text-renderer';
import { StatusMarker } from '../../../ui-components/statusmarker/status-marker';

const mapStateProps = (store: any) => ({
	date: store.userDetails.date,
});

const mapDispatchProps = (dispatch: any) => ({});

class OperationalNotesCardComponent extends React.Component<any, any> {
	public getDate(from: any, to: any): any {
		return {
			from: new Date(from),
			to: new Date(to),
			current: new Date(),
		};
	}

	public getNotesByCategory(data, type) {
		return data.filter((item: any) => {
			// TODO: Do not remove this line, it will be needed for a feature - add date input for users (like on the weather widget) and keep current flow for admins

			// const { validFrom, validTo } = item;
			// const dates = this.getDate(validFrom, validTo);
			// const { from, to, current } = dates;
			// const today = new Date();

			// const isBetweenFromAndTo =
			// 	isBefore(new Date(from), today) && isAfter(new Date(to), today);

			// const isFromToday = isSameDay(current, from);
			// const isToToday = isSameDay(current, to);
			// const isTodaySame = isSameDay(today, from) || isSameDay(today, to);

			// const isStillValid =
			// 	isBetweenFromAndTo || isFromToday || isToToday || isTodaySame;

			return item.criticality === type;
		});
	}

	public render(): any {
		const { items = false } = this.props;

		if (!items) {
			return null;
		}

		const critical = this.getNotesByCategory(
			items,
			'OPERATIONALLY_CRITICAL'
		);
		const relevant = this.getNotesByCategory(
			items,
			'OPERATIONALLY_RELEVANT'
		);
		const infos = this.getNotesByCategory(items, 'INFORMATION_ONLY');

		return (
			<div className='w-100'>
				{!critical.length &&
					!relevant.length &&
					!infos.length &&
					this.renderEmpty()}
				{!!critical.length &&
					this.renderNotes(
						critical,
						'red-2',
						'Operationally critical'
					)}
				{!!relevant.length &&
					this.renderNotes(
						relevant,
						'green-2',
						'Operationally relevant'
					)}
				{!!infos.length &&
					this.renderNotes(infos, 'blue-2', 'Information only')}
			</div>
		);
	}

	public renderEmpty(): React.ReactElement {
		return (
			<div className='w-100 display-flex justify-content-center py-6'>
				<ContributeButton
					openModal={() => this.handleOpenCreate()}
					buttonOnly={true}
				/>
			</div>
		);
	}

	public renderNotes(items, color, type) {
		const date = this.props.date || 'yyyy. MM. dd. HH:mm';

		return (
			<div className='w-100 mb-4'>
				{this.renderColor(color, type)}
				{items.map((info: any, index: any) => {
					const {
						validTo,
						aid,
						category,
						validFrom,
						notes = '',
						source,
					} = info;

					const to = isSameDay(
						new Date(validTo),
						new Date('31.12.2099')
					)
						? 'Permanent'
						: format(new Date(validTo), date);

					return (
						<div
							onClick={(ev) => this.handleOpenNote(ev, info)}
							key={index}
							className='w-100 palette--bgc-secondary-1 display-inline-block p-2 mb-2 border-radius-1 pointer'
						>
							<div className='display-flex align-items-center flex-wrap'>
								{this.renderAid(aid)}
								{this.renderAid(
									(category || '').replace(/_/gi, ' ')
								)}
								<p className='m-0 palette--c-neutral-5 py-1'>
									{format(new Date(validFrom), date)}
								</p>
								<p className='m-0 palette--c-neutral-5 py-1'>
									&nbsp;-&nbsp;
								</p>
								<p className='m-0 palette--c-neutral-5 py-1'>
									{to}
								</p>
							</div>

							<TextRenderer text={notes} classNames='p-2' />

							<p className='m-0 palette--c-neutral-5'>{source}</p>
						</div>
					);
				})}
			</div>
		);
	}

	public renderColor(color: string, type: string): React.ReactElement {
		return (
			<StatusMarker
				tColor='neutral-1'
				bgColor={color}
				text={type}
				type='tag'
				classNames='mb-2'
			/>
		);
	}

	public renderAid(aid): React.ReactElement {
		return (
			<StatusMarker
				tColor='neutral-5'
				bgColor='neutral-1'
				text={aid}
				type='badge'
				classNames='border-1 palette--bc-neutral-5 mr-4 my-1'
			/>
		);
	}

	public handleOpenNote(ev, note) {
		if (ev?.target?.localName === 'a') {
			return;
		}

		const kind = note?.kind;
		const isAirport = kind === 'AIRPORT';
		const location = isAirport ? 'airports' : 'countries';
		const parentAid = isAirport ? note?.parent : note?.parent?.iso3;
		const aid = note?.aid;

		const route = `/${location}/${parentAid}/${aid}`;
		const utmSource = isInQuery('utm_source');

		this.props?.router?.navigate({ pathname: route, search: utmSource });
	}

	public handleOpenCreate() {
		this.props?.router?.navigate(
			`${window?.location?.pathname}/operational-notes`
		);
	}
}

export const OperationalNotesCard: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(connectNLP(OperationalNotesCardComponent));
