import { arrayToClass } from '../../utilities/helper-fuctions';
import * as React from 'react';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';

export class ContributeButton extends React.Component<any, any> {
    public render(): React.ReactElement {
        const preWord = this.props.isAre ? 'are' : 'is';
        const text = `${this.props.label} ${preWord} unknown.`;
        const twoLine = this.props.isTwoLine;

        return (
            <div className={arrayToClass(['display-flex align-items-center', twoLine ? 'flex-column' : ''])}>
                {!this.props.buttonOnly && !this.props.text && <p className="palette--c-neutral-5 m-0">{text}</p>}
                {!this.props.buttonOnly && !!this.props.text && (
                    <p className="palette--c-neutral-5 m-0">{this.props.text}</p>
                )}
                <HoverableButton
                    colorType="transparent-green"
                    onClick={() => this.props.openModal()}
                    title={this.props.buttonTitle || 'Contribute now!'}
                />
            </div>
        );
    }
}
