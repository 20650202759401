import { arrayToClass } from '../../../utilities/helper-fuctions';
import { get } from 'lodash';
import * as React from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import { Map } from '../map';

export class AuthorityMap extends React.Component<any, any> {
    public render(): any {
        const authority = get(this.props, 'content');
        const landside = get(authority, 'landsideArea');
        const airside = get(authority, 'airsideArea');

        if (!landside && !airside) {
            return null;
        }

        const bounds: any = { bounds: this.generateBoundaries([...(landside || []), ...(airside || [])]) };

        if (!(bounds?.bounds || []).length) {
            return null;
        }

        const className = arrayToClass(['map__reactleaflet']);

        const landsidePolygon = this.getPolygon(landside);
        const airsidePolygon = this.getPolygon(airside);

        const mapProps: any = {
            className: className,
            scrollWheelZoom: false,
            dragging: true,
            attributionControl: false,
        };

        return (
            <Map mapProps={mapProps} viewport={bounds} className="MapOnAuthority" buttonPosition="bottom">
                {!!landsidePolygon.length && (
                    <Polygon positions={landsidePolygon} color="#800360" weight={2}>
                        {this.renderPopover('Landside location')}
                    </Polygon>
                )}
                {!!airsidePolygon.length && (
                    <Polygon positions={airsidePolygon} color="#800360" weight={2}>
                        {this.renderPopover('Airside location')}
                    </Polygon>
                )}
            </Map>
        );
    }

    public renderPopover(text) {
        return (
            <Tooltip direction="top">
                <span className="palette--c-neutral-1 p-2">
                    {get(this.props.content, 'name')}&nbsp;
                    {text}
                </span>
            </Tooltip>
        );
    }

    private getPolygon(list): any {
        if (!list) {
            return [];
        }

        return list.map((item: any) => [item.latitude, item.longitude]);
    }

    private generateBoundaries(list) {
        const minMaxCoords = list.reduce(
            (acc, arr) => {
                if (acc.minLat > get(arr, 'latitude') || !acc.minLat) {
                    acc.minLat = get(arr, 'latitude');
                }

                if (acc.maxLat < get(arr, 'latitude') || !acc.maxLat) {
                    acc.maxLat = get(arr, 'latitude');
                }

                if (acc.minLng > get(arr, 'longitude') || !acc.minLng) {
                    acc.minLng = get(arr, 'longitude');
                }

                if (acc.maxLng < get(arr, 'longitude') || !acc.maxLng) {
                    acc.maxLng = get(arr, 'longitude');
                }

                return acc;
            },
            { minLat: null, minLng: null, maxLat: null, maxLng: null }
        );

        return [
            [minMaxCoords.minLat - 0.01, minMaxCoords.minLng - 0.01],
            [minMaxCoords.minLat - 0.01, minMaxCoords.maxLng + 0.01],
            [minMaxCoords.maxLat + 0.01, minMaxCoords.minLng - 0.01],
            [minMaxCoords.maxLat + 0.01, minMaxCoords.maxLng + 0.01],
        ];
    }
}
