import { differenceInHours, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as React from 'react';
import { connect } from 'react-redux';
import { dimConvert } from '../../../utilities/dim.transform';
import { arrayToClass } from '../../../utilities/helper-fuctions';
import { RequestManager } from '../../../utilities/request';
import { Tooltip } from '../../ui-components/popover/popover';
import { Spinner } from '../../ui-components/spinner/spinner';
import { StatusMarker } from '../../ui-components/statusmarker/status-marker';
import './airport.card.scss';

interface AirportCardProps {
	data: any;
	onClick?: () => void;
	className?: any;
	alterKey?: string | number;
	enableIcon?: boolean;
	weather?: any;
	hasOwnWeather?: boolean;
	length?: any;
	date?: string;
	metric?: boolean;
	startOfTheWeek?: string;
	decimalCoordinates?: boolean;
	hasWeatherError?: boolean;
}

const mapStateProps = (store: any) => ({
	length: store.measures.length,
	date: store.userDetails.date,
	metric: store.userDetails.metric,
	startOfTheWeek: store.userDetails.startOfTheWeek,
	decimalCoordinates: store.userDetails.decimalCoordinates,
});

const mapDispatchProps = (dispatch: any) => ({});

class AirportCardComponent extends React.Component<AirportCardProps, any> {
	public isMounted = false;

	public state: any = {
		weather: null,
		hovered: false,
	};

	public componentDidMount(): void {
		this.isMounted = true;
		if (!this.props.hasOwnWeather) {
			this.weatherFetch();
		}
	}

	public componentDidUpdate(
		prevProps: Readonly<AirportCardProps>,
		prevState: Readonly<any>,
		snapshot?: any
	): void {
		const date = this.props.date !== prevProps.date;
		const metric = this.props.metric !== prevProps.metric;
		const startOfTheWeek =
			this.props.startOfTheWeek !== prevProps.startOfTheWeek;
		const coordinates =
			this.props.decimalCoordinates !== prevProps.decimalCoordinates;
		const weatherChange =
			!this.props.hasOwnWeather &&
			this.state.weather !== prevState.weather;
		const ownWeatherChange =
			this.props.hasOwnWeather &&
			this.props.weather !== prevProps.weather;

		if (
			weatherChange ||
			ownWeatherChange ||
			date ||
			metric ||
			startOfTheWeek ||
			coordinates
		) {
			this.forceUpdate();
		}
	}

	public componentWillUnmount() {
		this.isMounted = false;
	}

	public setStates(states) {
		if (this.isMounted) {
			this.setState({ states });
		}
	}

	public async weatherFetch() {
		try {
			const aid = this.props?.data?.aid;

			if (!aid) {
				return;
			}

			const result = await RequestManager.get(
				`/airports/${aid}/weather`,
				{ fallback: 'NONE' }
			);

			if (!result) {
				return new Error();
			}

			return this.setStates({ weather: result });
		} catch (err) {
			// silence is golden
		}
	}

	public render(): any {
		const { data, alterKey } = this.props;
		if (!data?.aid) {
			return null;
		}

		const classes = arrayToClass([
			'AirportCard',
			'w-100',
			'palette--bgc-neutral-1',
			'border-radius-1',
			'display-flex',
			'flex-column flex-sm-row',
			'overflow-hidden',
			this.props.className,
			`palette--bgc-neutral-${data?.archived ? '2' : '1'}`,
		]);

		return (
			<div
				className={classes}
				key={alterKey}
				onClick={() => this.handleClick()}
			>
				<div className='w-100 px-4 py-2 display-flex flex-column'>
					<div className='display-flex mb-2'>
						<div className='flex-fill'>{this.renderAid()}</div>

						{this.renderStates()}
					</div>

					<h4 className='pointer palette--c-neutral-6 fw-bold'>
						{data?.name}
					</h4>

					<div className='w-100 flex-fill display-flex align-items-end'>
						<div className='flex-fill'>
							{this.renderCodes()}
							{this.renderCity()}
						</div>

						<div className='display-flex flex-column align-items-end'>
							{this.renderLongestRunway()}
							{this.renderTime()}
						</div>
					</div>
				</div>
			</div>
		);
	}

	public renderAid(): React.ReactElement {
		const { aid, icon } = this.props?.data;
		let type = icon || 'CIVIL';

		if (type === 'WATER') {
			type = 'seaplane';
		}

		return (
			<Tooltip
				tooltip={this.renderPopover(
					`The AID is the Aviowiki ID of this ${type.toLowerCase()}. It uniquely identifies any airport in the world, even if they don't have any other code.`
				)}
				trigger={this.renderAidBadge(aid)}
			/>
		);
	}

	public renderAidBadge(data: any) {
		return (
			<div className='mb-1 cursor-pointer'>
				<StatusMarker
					tColor='secondary-4'
					bgColor='secondary-1'
					text={data}
					type='tag'
					classNames='pointer'
				/>
			</div>
		);
	}

	public renderCodes(): React.ReactElement {
		const { iata, icao, localIdentifier, country } = this.props?.data;

		const localIdentifierName =
			country?.localIdentifierName || 'Local Identifier';

		return (
			<div className='Codes display-flex mb-2'>
				{localIdentifier && (
					<Tooltip
						tooltip={this.renderPopover(localIdentifierName)}
						trigger={this.renderPopoverChildren(localIdentifier)}
					/>
				)}
				{localIdentifier && (iata || icao) && (
					<h4 className='pointer palette--c-neutral-5 px-1 m-0 fw-bold'>
						|
					</h4>
				)}
				{iata && (
					<Tooltip
						tooltip={this.renderPopover('IATA')}
						trigger={this.renderPopoverChildren(iata)}
					/>
				)}
				{iata && icao && (
					<h4 className='pointer palette--c-neutral-5 px-1 m-0 fw-bold'>
						|
					</h4>
				)}
				{icao && (
					<Tooltip
						tooltip={this.renderPopover('ICAO')}
						trigger={this.renderPopoverChildren(icao)}
					/>
				)}
			</div>
		);
	}

	public renderPopover(description: any): any {
		return (
			<div className='PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1'>
				<span className='palette--c-neutral-1'>{description}</span>
			</div>
		);
	}

	public renderPopoverChildren(code: string) {
		return (
			<h4 className='pointer palette--c-neutral-5 m-0 fw-bold'>{code}</h4>
		);
	}

	public renderCity(): React.ReactElement {
		const { country, servedCity, servedCityGoverningDistrict } =
			this.props?.data;
		const iso2 = country?.iso2;
		const name = country?.name;
		const content = `${servedCity ? servedCity : name}${
			iso2 === 'US' && servedCityGoverningDistrict
				? `, ${(servedCityGoverningDistrict.code || '').slice(-2)}`
				: ''
		}`;

		const flagClasses = arrayToClass([
			'flag-map-marker',
			iso2 ? `fi fi-${iso2.toLowerCase()}` : 'display-none',
			'mr-2 pointer',
		]);

		return (
			<div className='display-flex align-items-center'>
				<Tooltip
					tooltip={this.renderPopover(name)}
					trigger={<span className={flagClasses} />}
				/>
				<p className='palette--c-neutral-5 m-0'>{content}</p>
			</div>
		);
	}

	public renderStates(): React.ReactElement {
		const { hasWeatherError, enableIcon } = this.props;
		return (
			<div className='display-flex align-items-center'>
				{!hasWeatherError && this.renderWeather()}
				{this.renderOpening()}
				{enableIcon && this.renderType()}
			</div>
		);
	}

	public renderWeather(): React.ReactElement {
		const { hasOwnWeather, weather } = this.props;
		if (!weather && hasOwnWeather) {
			return <Spinner size='small' />;
		}

		let report = this.state?.weather?.report;

		if (weather) {
			report = weather?.report;
		}

		if (!report) {
			return <></>;
		}

		let tColor = 'red-2';
		let bgColor = 'red-1';

		if (report === 'VFR') {
			tColor = 'green-2';
			bgColor = 'green-1';
		}

		if (report === 'IFR') {
			tColor = 'yellow-3';
			bgColor = 'yellow-1';
		}

		return (
			<StatusMarker
				tColor={tColor}
				bgColor={bgColor}
				text={report}
				type='badge'
				classNames='pointer text-uppercase'
			/>
		);
	}

	public renderOpening(): any {
		const { openingIndicator } = this.props?.data;
		let bgColor = 'red-2';

		if (!openingIndicator) {
			return null;
		}

		if (openingIndicator === 'UNKNOWN') {
			bgColor = 'neutral-4';
		}

		if (openingIndicator === 'OPEN') {
			bgColor = 'green-2';
		}

		if (openingIndicator === 'SEE_TIMES') {
			bgColor = 'yellow-2';
		}

		return (
			<StatusMarker
				tColor='neutral-1'
				bgColor={bgColor}
				text={openingIndicator.replace('_', ' ')}
				type='badge'
				classNames='ml-2 text-uppercase pointer'
			/>
		);
	}

	public renderType(): React.ReactElement {
		const { aid, icon } = this.props?.data;
		const type = icon || 'CIVIL';

		const src = `../../../assets/pins/searched/${type}.svg`;

		const text = type.replace('_', ' ');

		return (
			<Tooltip
				tooltip={this.renderPopover(text)}
				trigger={
					<img
						alt={type}
						src={src}
						className='pl-1'
						onMouseEnter={() =>
							this.setState({ hovered: `${aid} + ${type}` })
						}
						onMouseLeave={() => this.setState({ hovered: false })}
					/>
				}
			/>
		);
	}

	public renderLongestRunway() {
		const runway = dimConvert(
			this.props?.data?.longestRunwayLength,
			'm',
			this.props?.length?.targetDimension || 'm',
			0
		);

		if (!runway) {
			return null;
		}

		return (
			<Tooltip
				tooltip={this.renderPopover('Longest runway on airprot')}
				trigger={this.renderRunway(runway)}
			/>
		);
	}

	public renderRunway(runway) {
		const { targetDimension = 'm' } = this.props?.length;
		return (
			<div>
				<p className='palette--c-neutral-6 m-0'>
					{runway} {targetDimension}
				</p>
			</div>
		);
	}

	public renderTime(): any {
		const { data, date } = this.props;
		const rawTz = data?.timeZone;
		const localTime = utcToZonedTime(new Date(), rawTz);
		const diffInHours = differenceInHours(localTime, new Date());
		const minutesLeft = diffInHours % 1;
		const diffPrefix = diffInHours >= 0 ? '+' : '';

		const hours = Math.floor(diffInHours);
		const minutes = minutesLeft * 60;

		if (!rawTz) {
			return null;
		}

		const formatString = (date || '').match(/(hh:mm)( a|)/gi);
		const rLocalTime = format(
			new Date(localTime),
			formatString ? formatString[0] : 'HH:mm'
		);

		const time =
			hours === 0 && minutes === 0
				? 'Same as you'
				: `${diffPrefix} ${hours}h ${
						minutes ? `${minutes}min` : ''
				  } from you`;

		return (
			<div className='display-flex flex-column align-items-end justify-content-end flex-fill'>
				<p className='palette--c-neutral-6 m-0'>{rLocalTime} LT</p>
				<span className='palette--c-neutral-5 ml-2'>{time}</span>
			</div>
		);
	}

	private handleClick() {
		if (this.props.onClick) {
			this.props.onClick();
		}
	}
}

export const AirportCard: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(AirportCardComponent);
