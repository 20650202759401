import { differenceInHours } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import * as React from 'react';
import { connect } from 'react-redux';
import { connectNLP } from '../../../../utilities/connect-navigate';
import { generateDMS } from '../../../../utilities/coordinates-convereter';
import { dimConvert } from '../../../../utilities/dim.transform';
import { arrayToClass, isInQuery } from '../../../../utilities/helper-fuctions';
import { ContributeButton } from '../../../other/contribute-button';
import { Tooltip } from '../../../ui-components/popover/popover';
import { StatusMarker } from '../../../ui-components/statusmarker/status-marker';
import { Widget } from '../../widget';
import './airport-general-data.scss';

const mapStateProps = (store: any) => ({
	mLength: store.measures.length,
	decimalCoordinates: store.userDetails.decimalCoordinates,
	date: store.userDetails.date,
});

const mapDispatchProps = (dispatch: any) => ({});

class AirportGeneralDataComponent extends Widget<any, any> {
	public state: any = {
		hovered: false,
	};

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		if (this.props.decimalCoordinates !== prevProps.decimalCoordinates) {
			this.forceUpdate();
		}
	}

	public renderContent(): React.ReactElement {
		const { name } = this.props.airport;

		return (
			<div className='GeneralDataWidget w-100 p-2'>
				<div className='display-block display-md-flex'>
					<div className='w-100'>
						{this.renderAid()}
						<h2 className='palette--c-neutral-6 fw-bold mb-2'>
							{name}
						</h2>
						{this.renderCodes()}
						{this.renderCity()}
					</div>
					<div className='w-100 display-flex flex-column align-items-start align-items-md-end mt-lg-0'>
						{this.renderTime()}
						<div className='display-flex mb-4'>
							{this.renderElevation()}
						</div>
						{this.renderCoordinates()}
						{this.renderVariation()}
					</div>
				</div>
			</div>
		);
	}

	public renderAid(): React.ReactElement {
		const { aid } = this.props?.airport;

		return (
			<Tooltip
				tooltip={this.renderPopover(
					'AID The code used by aviowiki to identify this location'
				)}
				trigger={this.renderAidBadge(aid)}
			/>
		);
	}

	public renderAidBadge(data: any) {
		return (
			<div
				className='display-inline-flex mb-1 cursor-pointer'
				onMouseEnter={() => this.setState({ hovered: data })}
				onMouseLeave={() => this.setState({ hovered: false })}
			>
				<StatusMarker
					tColor='secondary-4'
					bgColor='secondary-1'
					text={data}
					type='tag'
				/>
			</div>
		);
	}

	public renderCodes(): any {
		const { localIdentifier, iata, icao, country } = this.props?.airport;

		if (!localIdentifier && !iata && !icao) {
			return this.renderEmptyProperty(
				'IATA, ICAO and Local Identifier',
				true
			);
		}

		return (
			<div className='Codes display-flex mb-2'>
				{localIdentifier && (
					<Tooltip
						tooltip={this.renderPopover(
							country?.localIdentifierName || 'Local Identifier'
						)}
						trigger={this.renderPopoverChildren(localIdentifier)}
					/>
				)}
				{localIdentifier && (iata || icao) && (
					<h4 className='palette--c-neutral-5 px-1 m-0 fw-bold'>|</h4>
				)}
				{iata && (
					<Tooltip
						tooltip={this.renderPopover('IATA')}
						trigger={this.renderPopoverChildren(iata)}
					/>
				)}
				{iata && icao && (
					<h4 className='palette--c-neutral-5 px-1 m-0 fw-bold'>|</h4>
				)}
				{icao && (
					<Tooltip
						tooltip={this.renderPopover('ICAO')}
						trigger={this.renderPopoverChildren(icao)}
					/>
				)}
			</div>
		);
	}

	public renderPopover(description: any): any {
		return (
			<div className='PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1'>
				<span className='palette--c-neutral-1'>{description}</span>
			</div>
		);
	}

	public renderPopoverChildren(code: string) {
		return <h4 className='palette--c-neutral-5 m-0 fw-bold'>{code}</h4>;
	}

	public renderVariation(): any {
		const { variation } = this.props?.airport;

		if (variation === null) {
			return this.renderEmptyProperty('Variation');
		}

		return (
			<div className='display-flex'>
				{this.renderTooltip('far fa-compass', 'Variation', true)}
				<p className='palette--c-neutral-6 m-0'>
					{Math.abs(variation)}
				</p>
				<p className='palette--c-neutral-6 m-0'>
					&nbsp;{variation > 0 ? 'E' : 'W'}
				</p>
			</div>
		);
	}

	public renderCity(): React.ReactElement {
		const {
			country: { name, iso2, iso3 },
			servedCity,
			servedCityGoverningDistrict,
		} = this.props?.airport;
		const content = `${servedCity ? servedCity : name}${
			iso2 === 'US' && servedCityGoverningDistrict
				? `, ${(servedCityGoverningDistrict.code || '').slice(-2)}`
				: ''
		}`;

		if (!iso2 && !content) {
			return this.renderEmptyProperty('Country');
		}

		const flagClasses = arrayToClass([
			'flag-map-marker',
			iso2 ? `fi fi-${iso2.toLowerCase()}` : 'display-none',
			'mr-2 pointer',
		]);

		return (
			<div className='display-flex align-items-center pb-4 pb-sm-0'>
				<Tooltip
					tooltip={this.renderPopover(name)}
					trigger={
						<span
							className={flagClasses}
							onClick={() => this.navigate(`/countries/${iso3}`)}
						/>
					}
				/>
				<p className='palette--c-neutral-5 m-0'>{content}</p>
			</div>
		);
	}

	public renderTime(): any {
		const { timeZone } = this.props?.airport;
		const localTime = utcToZonedTime(new Date(), timeZone);
		const diffInHours = differenceInHours(localTime, new Date(), {
			roundingMethod: 'round',
		});
		const minutesLeft = diffInHours % 1;
		const diffPrefix = diffInHours >= 0 ? '+' : '';

		const hours = Math.floor(diffInHours);
		const minutes = minutesLeft * 60;

		if (!timeZone) {
			return this.renderEmptyProperty('Timezone');
		}

		const formatString = (this.props?.date || '').match(/(hh:mm)( a|)/gi);
		const rLocalTime = format(
			new Date(localTime),
			formatString ? formatString[0] : 'HH:mm'
		);

		const time =
			hours === 0 && minutes === 0
				? 'Same as you'
				: `${diffPrefix} ${hours}h ${
						minutes ? `${minutes}min` : ''
				  } from you`;

		return (
			<div className='display-flex align-items-center mb-4'>
				{this.renderTooltip('schedule', 'Local Time')}
				<p className='palette--c-neutral-6 m-0'>{rLocalTime} LT</p>
				<span className='palette--c-neutral-5 ml-2'>{time}</span>
			</div>
		);
	}

	public renderTooltip(icon, tooltipText, useClass = false) {
		const triggerClasses = arrayToClass([
			'palette--c-neutral-5 mr-2 mb-0',
			useClass ? 'far fa-compass' : 'material-icons',
		]);

		return (
			<Tooltip
				trigger={
					<p className={triggerClasses}>{useClass ? '' : icon}</p>
				}
				tooltip={this.renderPopover(tooltipText)}
			/>
		);
	}

	public renderElevation(): any {
		const { elevation } = this.props?.airport;

		if (!elevation && elevation !== 0) {
			return this.renderEmptyProperty('Elevation');
		}

		const unit = this.props?.mLength?.targetDimension || 'm';

		const el = dimConvert(elevation, 'm', unit, 0);

		return (
			<div className='w-100 display-flex'>
				{this.renderTooltip('landscape', 'Elevation')}
				<p className='palette--c-neutral-6 m-0'>
					{el}&nbsp;{unit}
				</p>
			</div>
		);
	}

	public renderCoordinates(): any {
		let { latitude, longitude } = this.props?.airport.coordinates;

		if (!latitude && !longitude) {
			return this.renderEmptyProperty('Coordinates', true);
		}

		if (!this.props.decimalCoordinates) {
			latitude = generateDMS(latitude);
			longitude = generateDMS(longitude, true);
		}

		return (
			<div className='display-flex mb-4'>
				{this.renderTooltip('language', 'Coordinates')}
				<p className='palette--c-neutral-6 m-0'>{latitude}</p>
				<p className='palette--c-neutral-6 m-0'>&nbsp;{longitude}</p>
			</div>
		);
	}

	private renderEmptyProperty(label, isAre = false): React.ReactElement {
		return (
			<ContributeButton
				openModal={() =>
					this.navigate(
						`/airports/${this.props?.airport?.aid}/general-data`
					)
				}
				label={label}
				isAre={isAre}
			/>
		);
	}

	private navigate(pathname) {
		const utmSource = isInQuery('utm_source') || '';
		this.props?.router?.navigate({ pathname: pathname, search: utmSource });
	}
}

export const AirportGeneralData: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(connectNLP(AirportGeneralDataComponent));
