import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { connectNLP } from '../../utilities/connect-navigate';
import { arrayToClass, isInQuery } from '../../utilities/helper-fuctions';
import { HoverableButton } from '../ui-components/hoverable-buttons/hoverable-buttons';
import { Modal } from '../ui-components/modal/modal';
import { Tooltip } from '../ui-components/popover/popover';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class GridItemComponent extends React.Component<any, any> {
	public modal$: Modal;

	public state: any = {
		hovered: false,
	};

	public render(): React.ReactElement {
		const classes = arrayToClass([
			'GridItem p-2',
			'col-24',
			this.props.className,
		]);

		const wrapperClasses = arrayToClass([
			`Resize-${this.props.identifier}`,
			'h-100',
			'palette--bgc-neutral-1',
			'border-radius-1',
			this.props.wrapperClassName,
		]);

		return (
			<div className={classes}>
				<div className={wrapperClasses}>
					{this.renderHeader()}
					{this.renderContent()}
				</div>
			</div>
		);
	}

	private renderHeader() {
		const classes = arrayToClass([
			'GridItemHeader display-flex align-items-center',
			'border-bottom-1 palette--bc-neutral-3 p-2',
		]);

		return (
			<div className={classes}>
				<div className='flex-fill display-flex align-items-center'>
					{this.props.title && (
						<span className='text-uppercase palette--c-neutral-6 m-0 fw-bold'>
							{this.props.title}
						</span>
					)}
				</div>
				<div className='display-flex align-items-center'>
					{this.renderButtons()}
				</div>
			</div>
		);
	}

	private renderButtons(): any {
		const isEditable = get(this.props, 'isEditable');
		const contributables = [
			'general-data',
			'authorities',
			'availability',
			'operational-notes',
			'runways',
			'operations',
			'providers',
		];

		if (isEditable) {
			return this.renderEditable();
		}

		if (contributables.includes(this.props.identifier)) {
			return (
				<Tooltip
					tooltip={this.renderPopover()}
					trigger={this.renderPopoverChildren()}
				/>
			);
		}

		return null;
	}

	private renderEditable(): React.ReactElement {
		const upClasses = arrayToClass([
			'GridIcon fas fa-arrow-up',
			'palette--c-neutral-4',
		]);

		const downClasses = arrayToClass([
			'GridIcon fas fa-arrow-down',
			'palette--c-neutral-4',
		]);

		const toggleClasses = arrayToClass([
			'GridIcon fas fa-columns display-none display-md-block',
			this.props.isToggled
				? 'palette--c-green-2'
				: 'palette--c-neutral-4',
		]);

		return (
			<div className='display-flex'>
				{!get(this.props, 'isFirst') && (
					<div
						className='pointer mr-4 display-flex align-items-center'
						onClick={() => this.reorder(-1)}
					>
						<span className={upClasses} />
					</div>
				)}
				{!get(this.props, 'isLast') && (
					<div
						className='pointer mr-4 display-flex align-items-center'
						onClick={() => this.reorder(1)}
					>
						<span className={downClasses} />
					</div>
				)}
				<div className='pointer display-flex align-items-center'>
					<span
						id={this.props.identifier}
						className={toggleClasses}
						onClick={() => this.handleToggleSizeClick()}
					/>
				</div>
			</div>
		);
	}

	private renderPopoverChildren() {
		const id = this.props.identifier;

		return (
			<HoverableButton
				className='border-radius-1'
				colorType='transparent-green'
				icon='rate_review'
				onEnter={() => this.setState({ hovered: id })}
				onLeave={() => this.setState({ hovered: false })}
				onClick={() => this.navigate(id)}
			/>
		);
	}

	private navigate(id): any {
		const utmSource = isInQuery('utm_source') || '';
		const path = `${window.location.pathname}/${id}`;
		this.props?.router?.navigate({ pathname: path, search: utmSource });
	}

	private renderPopover(): any {
		return (
			<div className='PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1'>
				<span className='palette--c-neutral-1'>Contribute</span>
			</div>
		);
	}

	private renderContent() {
		const classes = arrayToClass([this.props.contentClassName]);

		return <div className={classes}>{this.props.children}</div>;
	}

	private reorder(direction: number): any {
		if (!this.props.onReorder) {
			return null;
		}

		this.props.onReorder(direction);
	}

	private handleToggleSizeClick(): any {
		if (!this.props.onToggleSize) {
			return null;
		}

		this.props.onToggleSize();
	}
}

export const GridItem: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(connectNLP(GridItemComponent));
