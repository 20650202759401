import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DetailsActions } from '../../actions/details.actions';
import { UserDetailsActions } from '../../actions/user-details.actions';
import { Footer } from '../../components/footer/footer';
import { GridContainer } from '../../components/grid/grid.container';
import { GridItem } from '../../components/grid/grid.item';
import { MainHeaderButtons } from '../../components/header/main-header-buttons/main-header-buttons';
import { ToastMessages } from '../../components/notifications/toast-messages';
import { Search } from '../../components/search/search';
import { HoverableButton } from '../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { Spinner } from '../../components/ui-components/spinner/spinner';
import { CountryAuthorities } from '../../components/widgets/country/authority/country-authorities';
import { CountryDetails } from '../../components/widgets/country/country-details/country-details';
import { CountryOperationalNotes } from '../../components/widgets/country/operational-notes/operational-notes';
import { DetailsLayout } from '../../layouts/details/details.layout';
import { arrayToClass, setTitleAndMeta } from '../../utilities/helper-fuctions';
import { RequestManager } from '../../utilities/request';
import { logoLink } from '../../utilities/variables';
import '../airport-details/details.screen.scss';
import { Modals } from '../modals/modals';

const mapStateProps = (store: any) => ({
	details: store.details,
	query: store.query,
	user: store.user.user,
	openMobile: store.details.openMobile,
	widgetSuccess: store.details.widgetSuccess,
});

const mapDispatchProps = (dispatch: any) => ({
	toggleMobile: () => dispatch(DetailsActions.toggleOpenMobile()),
	saveUserDetails: () => dispatch(UserDetailsActions.saveUserDetails({})),
	setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
	setOpen: (bool: boolean) => dispatch(DetailsActions.setOpen(bool)),
	setOpenMobile: (bool: boolean) =>
		dispatch(DetailsActions.setOpenMobile(bool)),
});

class CountryDetailsScreenComponent extends React.Component<any, any> {
	public state: any = {
		...this.state,
		isEdit: false,
		isContribute: false,
		country: null,
		isScrolled: false,
	};

	public async componentDidMount(): Promise<any> {
		await this.fetchCountry();

		const doc: any = document?.getElementById('Scrollable');
		doc?.addEventListener('scroll', (e) => {
			const scrollTop = e.target.scrollTop;

			if (scrollTop < 110 && this.state.isScrolled) {
				this.setState({ isScrolled: false });
			} else if (!this.state.isScrolled && scrollTop > 110) {
				this.setState({ isScrolled: true });
			}
		});
	}

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (
			this.props.widgetSuccess !== prevProps.widgetSuccess &&
			this.props.widgetSuccess === 'country'
		) {
			this.fetchCountry();
			this.props.setSuccess();
		}
		if (
			this.props?.router?.params?.iso !== prevProps?.router?.params?.iso
		) {
			this.fetchCountry();
		}
	}

	public async fetchCountry(): Promise<any> {
		this.setState({ loading: true });

		try {
			const response = await RequestManager.get(
				`/countries/iso3/${this.props?.router?.params?.iso}`
			);

			if (!response) {
				throw new Error('Empty response');
			}

			this.setState({ country: response }, () =>
				this.handleTitleChange()
			);
		} catch (err) {
			this.setState({ error: err });
			toast.error(<ToastMessages error={err} />, { theme: 'light' });
		} finally {
			this.setState({ loading: false });
		}
	}

	public handleTitleChange() {
		const country = this.state?.country;
		const name = country?.name;

		const titleString = `${name}`;
		const metaString = `FREE information on ${name}, Detailed information on notes and authorities at ${name}.`;
		setTitleAndMeta(titleString, metaString);
	}

	public render(): React.ReactElement {
		const classes = arrayToClass([
			'DetailsScreen h-100 overflow-hidden',
			this.props.details.open ? 'open' : 'closed',
		]);

		const marginClasses = arrayToClass([
			this.state.isEdit || this.state.isContribute
				? 'SmallerAirportDetails'
				: 'NormalAirportDetails',
		]);

		const searchClasses = arrayToClass([
			'Search display-flex align-items-center Paddings position-absolute w-100',
			this.state.isScrolled ? 'White elevation-1' : 'Grey',
			marginClasses,
		]);

		const scrollableContainer = arrayToClass([
			'w-100 Paddings display-flex flex-column',
			this.state.isEdit || this.state.isContribute
				? 'ScrollableDetailsContainer'
				: 'ScrollableDetailsContainerBigger',
		]);

		return (
			<div className={classes}>
				<div className={searchClasses}>{this.renderSearch()}</div>
				<div className={marginClasses} />
				<div className={scrollableContainer} id='Scrollable'>
					{this.renderWidgets()}
					{this.renderFooter()}
				</div>
			</div>
		);
	}

	private renderSearch(): React.ReactElement {
		return (
			<div className='w-100'>
				<div className='w-100 py-3 display-flex align-items-center'>
					<div
						className='ImageContainer display-flex align-items-center pointer'
						onClick={() => this.handleToMain()}
					>
						<img
							src={logoLink}
							alt='aviowiki'
							className='AvioLogo mr-2'
						/>
					</div>

					<div className='display-flex flex-fill justify-content-end align-items-center'>
						<MainHeaderButtons
							isDetails={true}
							changeToEditMode={() => this.changeToEditMode()}
						/>
					</div>
				</div>
				<Search className='SearchbarOnDetails w-100' isCountry={true} />
				<div className='w-100 py-3 display-flex align-items-end'>
					{this.renderButtons()}
				</div>
				{(this.state.isEdit || this.state.isContribute) &&
					this.renderSaveEditButton()}
			</div>
		);
	}

	public renderButtons() {
		return (
			<div className='display-lg-none w-100 display-flex justify-content-start'>
				<HoverableButton
					colorType='contrast'
					type='button'
					className='border-radius-1'
					title='Nearby airports'
					onClick={() => this.props.toggleMobile()}
				/>
			</div>
		);
	}

	public handleToMain() {
		this.props.setOpen(false);
		this.props.setOpenMobile(false);
		this.props?.router?.navigate('/');
	}

	private renderSaveEditButton(): React.ReactElement {
		const text = this.state.isEdit
			? 'Now, you can edit the widget position and size'
			: "Now, you can edit the widgets' content";

		return (
			<div className='LayoutSettingsSaveContainer w-100 border-1 palette--bc-primary-4 border-radius-1 palette--bgc-green-1 py-2 Paddings display-flex my-4'>
				<div className='flex-fill display-flex align-items-center'>
					<span className='SaveEditLayoutIcon palette--c-green-2 material-icons mr-2'>
						check_circle
					</span>
					<p className='m-0 palette--c-neutral-6'>{text}</p>
				</div>
				<HoverableButton
					titleLeft='Save Layout'
					icon='check'
					colorType='avio-green'
					className='SaveButton mx-2'
					onClick={() => this.handleSave()}
				/>
			</div>
		);
	}

	private handleSave(): void {
		this.setState({ isEdit: false, isContribute: false });

		if (this.props.saveUserDetails) {
			this.props.saveUserDetails();
		}
	}

	private renderWidgets(): React.ReactElement {
		const classes = arrayToClass([
			'AirportDetails',
			'w-100',
			CountryDetailsScreen.PADDING_CLASSES,
		]);

		return (
			<div className={classes}>
				{this.state.loading &&
					!this.state.error &&
					!this.state.country &&
					this.renderLoading()}
				{!this.state.loading &&
					this.state.error &&
					!this.state.country &&
					this.renderError()}
				{!this.state.loading &&
					!this.state.error &&
					this.state.country &&
					this.renderData()}
			</div>
		);
	}

	private renderLoading(): React.ReactElement {
		return <Spinner size='large' />;
	}

	private renderError(): React.ReactElement {
		return <p>We can't load this country right now, sorry.</p>;
	}

	private renderData(): React.ReactElement {
		const success = this.props?.widgetSuccess;
		const country = this.state?.country;

		return (
			<div className='GridContainerWrapper'>
				<GridContainer
					aid={this.props?.router?.params?.iso}
					editable={get(this.state, 'isEdit')}
					type='countries'
				>
					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-2'
						identifier='country'
						title='Country details'
					>
						<CountryDetails country={country} />
					</GridItem>

					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-2'
						identifier='operational-notes'
						title='Operational notes'
					>
						<CountryOperationalNotes
							country={country}
							refresh={
								success === 'operational-note' ||
								success === 'operational-note-delete'
							}
						/>
					</GridItem>

					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-2'
						identifier='authorities'
						title='Authorities'
					>
						<CountryAuthorities
							country={country}
							refresh={
								success === 'authority' ||
								success === 'authority-delete'
							}
						/>
					</GridItem>
				</GridContainer>
				<Modals {...this.props} />
			</div>
		);
	}

	private renderFooter(): React.ReactElement {
		const classes = arrayToClass([
			this.state.loading ? 'position-absolute absolute-bottom-0' : '',
			'w-100',
		]);

		return (
			<div className={classes}>
				<Footer />
			</div>
		);
	}

	private changeToEditMode() {
		this.setState({ isEdit: true });
	}
}

export const CountryDetailsScreen: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(DetailsLayout(CountryDetailsScreenComponent));
