import { isAfter, isBefore } from 'date-fns';
import * as React from 'react';
import {
	getData,
	getFields,
} from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { Form } from '../../../../ui-components/form/form';
import { WidgetContributionPresenter } from '../../../shared/contributions/widget-contribution-presenter/widget-contribution-presenter';
import { blockFields } from '../../providers/provider-products/provider-availability/provider-availability-contribution/providers-availability-fields';
import {
	arffBlockFields,
	atcBlockFields,
} from './availability-contribution-helpers';
import { OpenForsContribution } from './open-for-contriution';

const ssOrSrValidator = /([s]){1}([sr]){1}((([-+]| [+-]|)[0-9]+|[])|)/gi;
const hourValidator = /(?:[01]\d|2[0123])(|:)([0-5]){1}([0-9]){1}/g;

export class BlocksContribution extends React.Component<any, any> {
	public openFors$: { [key: string]: any } = {};

	public state = {
		rejected: [],
		editable: [],
		type: this.props?.item?.contribution?.action,
		limited: false,
	};

	public form: any = new Form({
		validFrom: {
			value: this.props?.item?.validFrom,
			trigger: () => this.handleChange('validFrom', 'fromError'),
		},
		validTo: {
			value: this.props?.item?.validTo,
			trigger: () => this.handleChange('validTo', 'toError'),
		},
		validFromSunriseOffset: this.getSsAndSrValue(
			this.props?.item?.validFromSunriseOffset,
			'SR'
		),
		validToSunriseOffset: this.getSsAndSrValue(
			this.props?.item?.validToSunriseOffset,
			'SR'
		),
		validToSunsetOffset: this.getSsAndSrValue(
			this.props?.item?.validToSunsetOffset,
			'SS'
		),
		validFromSunsetOffset: this.getSsAndSrValue(
			this.props?.item?.validFromSunsetOffset,
			'SS'
		),
		status: {
			value: this.props?.item?.status,
			trigger: () =>
				this.setState({
					limited: this.form.getValue('status') === 'LIMITED',
				}),
		},
		notes: this.props?.item?.notes,
	});

	public atc = new Form({
		enRtfAvailable: this.props?.item?.enRtfAvailable,
		afisOnly: this.props?.item?.afisOnly,
	});

	public arff = new Form({
		icaoCatAirplane: this.props?.item?.icaoCatAirplane,
		faaCatAirplane: this.props?.item?.faaCatAirplane,
		faa139Certified: this.props?.item?.faa139Certified,
		catHelicopters: this.props?.item?.catHelicopters,
		waterQuantity: this.props?.item?.waterQuantity,
		complementaryQuantity: this.props?.item?.complementaryQuantity,
		arffVehicles: this.props?.item?.arffVehicles,
		dischargeCapacity: this.props?.item?.dischargeCapacity,
		extensionAvailable: this.props?.item?.extensionAvailable,
		extensionNotice: this.props?.item?.extensionNotice,
		extensionUpToIcao: this.props?.item?.extensionUpToIcao,
		firestationRemote: this.props?.item?.firestationRemote,
		firestationNotes: this.props?.item?.firestationNotes,
	});

	public getSsAndSrValue(value, type) {
		if (!isNaN(value) && value !== null) {
			return `${type}${
				value === 0 ? ' 0' : value > 0 ? `+${value}` : value
			}`;
		}

		return null;
	}

	public getBlock(): any {
		const form = this.form.generateJSON();
		const atc = this.props.adds === 'atc' ? this.atc.generateJSON() : {};
		const arff = this.props.adds === 'arff' ? this.arff.generateJSON() : {};

		const adds = {
			...atc,
			...arff,
		};

		const data = {
			...this.props.item,
			...adds,
			...form,
			status: form.status,
		};

		const changes = [
			...getFields(this, blockFields(this)),
			...getFields(this, atcBlockFields(this)),
			...getFields(this, arffBlockFields(this)),
		];

		data.contribution.changes = changes.map((change) => change.type);

		return data;
	}

	public clearForm(): void {
		this.form.clearForm();
		this.atc.clearForm();
		this.arff.clearForm();
	}

	public handleChange(field, key): void {
		const value = this.form.getValue(field);

		if (!value.match(ssOrSrValidator) && !value.match(hourValidator)) {
			this.setState({
				showHelp: 'Eg.: SS +10, SR -10, 0000, 00:00',
				[key]: true,
			});
		} else if (value.match(ssOrSrValidator) || value.match(hourValidator)) {
			if (key === 'fromError') {
				const to = new Date(this.form.getValue('validTo'));

				if (isBefore(to, value)) {
					return this.setState({
						showHelp: 'FROM time have to be before TO time.',
						[key]: true,
					});
				}
			} else if (key === 'toError') {
				const from = new Date(this.form.getValue('validFrom'));

				if (isAfter(from, value)) {
					return this.setState({
						showHelp:
							'TO time have to be between FROM time and midnight.',
						[key]: true,
					});
				}
			}

			this.setState({ showHelp: false, [key]: false });
		}
	}

	public renderEmpty(): React.ReactElement {
		return (
			<div className='display-flex justify-content-center align-items-center p-5 palette--bgc-neutral-2 border-radius-1 m-2'>
				<p className='palette--c-neutral-5 m-0'>Nothing changed.</p>
			</div>
		);
	}

	public render(): any {
		const availabilityList = this.getAvailability();

		if (!availabilityList || !availabilityList.length) {
			return this.renderEmpty();
		}

		return (
			<div className='ProviderContent w-100 py-2 pl-4 pr-0'>
				<p className='m-0 fw-bold palette--c-neutral-6'>Blocks: </p>
				{availabilityList.map((item: any, index: number) => {
					return this.renderFields(item, index);
				})}
				{this.props.adds === 'movement' && this.renderOpenFors()}
			</div>
		);
	}

	public renderOpenFors() {
		const { openFor = [] } = this.props?.item;
		return openFor.map((item, index) => {
			return (
				<OpenForsContribution
					ref={(ref: any) =>
						(this.openFors$[`openFor-${index}`] = ref)
					}
					key={`openFor-${index}`}
					original={openFor[index]}
					item={item}
					type={this.state.type}
				/>
			);
		});
	}

	public getAvailability(): any {
		let block = getFields(this, blockFields(this));
		const { status, notes } = this.props?.original?.status;

		if (!this.state.limited && status === 'LIMITED') {
			block = block.filter((item: any) => item.type !== 'notes');
		}

		if (this.state.limited && status !== 'LIMITED') {
			const blockNotes: any = blockFields(this).find(
				(item: any) => item.type === 'notes'
			);

			if (notes === null) {
				this.handleEdit('notes');
			}

			block.push(blockNotes);
		}

		if (this.props.adds === 'atc') {
			const atc = getFields(this, atcBlockFields(this));

			return [...block, ...atc];
		}

		if (this.props.adds === 'arff') {
			const arff = getFields(this, arffBlockFields(this));

			return [...block, ...arff];
		}

		return block;
	}

	public renderFields(field: any, index: number): React.ReactElement {
		const { rejected = [], editable = [] } = this.state;
		const { action } = this.props?.item?.contribution;
		const { type, component } = field;

		const wrapperClassName = arrayToClass([
			rejected.includes(type as never)
				? 'palette--bgc-neutral-3'
				: `palette--bgc-${
						action === 'UPDATE'
							? 'yellow-1'
							: action === 'CREATE'
							? 'green-1'
							: 'red-1'
				  }`,
			'border-radius-1 my-2 p-2',
			'display-flex align-items-center',
		]);

		const element = {
			type: type,
			content: this.getData(type),
			component: component,
		};

		return (
			<WidgetContributionPresenter
				isAdmin={this.props.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(type)}
				toValue={element}
				isEditable={editable.includes(type as never)}
				isRejected={rejected.includes(type as never)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				className={wrapperClassName}
			/>
		);
	}

	public getData(field): any {
		const form = this.form.generateJSON();
		const atc = this.atc.generateJSON();
		const arff = this.arff.generateJSON();
		const value = form?.[field] || atc?.[field] || arff?.[field];

		if (this.state.type === 'DELETE') {
			return null;
		}

		if (field.includes('valid')) {
			return form?.[field];
		}

		return getData(field, value, true, []);
	}

	public getOriginalData(field): any {
		const value = this.props?.original?.field;

		if (this.props?.item?.contribution?.action === 'CREATE') {
			return null;
		}

		if (field.includes('valid')) {
			return value;
		}

		return getData(field, value, false, []);
	}

	public async handleEdit(type: string = 'editAll'): Promise<void> {
		const { editable = [] } = this.state;

		if (type === 'editAll') {
			const block = blockFields(this).map((item: any) => item.type);
			const atc =
				atcBlockFields(this).map((item: any) => item.type) || [];
			const arff =
				arffBlockFields(this).map((item: any) => item.type) || [];
			await this.setState({ editable: [...block, ...atc, ...arff] });

			Object.keys(this.openFors$).map((key) =>
				this.openFors$[key].handleEdit('editAll')
			);
		}

		if (!editable.includes(type as never)) {
			await this.setState({ editable: [...editable, type] });
		}
	}

	private async handleMissEdit(type: string): Promise<void> {
		const { editable = [] } = this.state;

		if (editable.includes(type as never)) {
			const editableArray = editable.filter((item) => item !== type);
			await this.setState({ editable: editableArray });
		}
	}

	private handleReject(type: string): void {
		const { rejected = [] } = this.state;

		if (!rejected.includes(type as never)) {
			this.setState({ rejected: [...rejected, type] });
		}
	}

	private handleMissReject(type: string) {
		const { rejected = [] } = this.state;

		if (rejected.includes(type as never)) {
			const rejectedArray = rejected.filter((item) => item !== type);
			this.setState({ rejected: rejectedArray });
		}
	}
}
