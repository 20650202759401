export const DAYS = [
    { value: 'MONDAY', title: 'Monday' },
    { value: 'TUESDAY', title: 'Tuesday' },
    { value: 'WEDNESDAY', title: 'Wednesday' },
    { value: 'THURSDAY', title: 'Thursday' },
    { value: 'FRIDAY', title: 'Friday' },
    { value: 'SATURDAY', title: 'Saturday' },
    { value: 'SUNDAY', title: 'Sunday' },
    { value: 'HOLIDAY', title: 'Public holidays' },
];

export const statusOpts = [{ value: 'FULL' }, { value: 'CLOSED' }, { value: 'LIMITED' }];

export const afis = [{ value: true, title: 'AFIS Only' }, { value: false, title: 'Controlled Tower' }];

export const icaoCatAirplane = [
    { value: 'NONE' },
    { value: 'I' },
    { value: 'II' },
    { value: 'III' },
    { value: 'IV' },
    { value: 'V' },
    { value: 'VI' },
    { value: 'VII' },
    { value: 'VIII' },
    { value: 'IX' },
    { value: 'X' },
];

export const faaCatAirplane = [
    { value: 'NONE', title: 'NONE' },
    { value: 'A', title: 'A' },
    { value: 'B', title: 'B' },
    { value: 'C', title: 'C' },
    { value: 'D', title: 'D' },
    { value: 'E', title: 'E' },
];

export const catHelis = [{ value: 'NONE' }, { value: 'I' }, { value: 'II' }, { value: 'III' }, { value: 'IV' }];

export const eUpToI = [
    { value: 'NONE' },
    { value: 'I' },
    { value: 'II' },
    { value: 'III' },
    { value: 'IV' },
    { value: 'V' },
    { value: 'VI' },
    { value: 'VII' },
    { value: 'VIII' },
    { value: 'IX' },
    { value: 'X' },
];

export const RULES = [{ value: 'IFR' }, { value: 'IFR_TO_VFR' }, { value: 'VFR' }, { value: 'VFR_TO_IFR' }];

export const FILING = [
    { value: 'SCHEDULED', title: 'Scheduled (S)' },
    { value: 'NON_SCHEDULED', title: 'Non scheduled (N)' },
    { value: 'GENERAL_AVIATION', title: 'General aviation (G)' },
    { value: 'MILITARY', title: 'Military (M)' },
    { value: 'OTHER', title: 'Other (X)' },
];

export const AIRFRAME = [{ value: 'AEROPLANE' }, { value: 'VTOL' }];

export const STATUSES = [
    { value: 'HUMANITARIAN' },
    { value: 'FIREFIGHTING' },
    { value: 'HEAD_OF_STATE' },
    { value: 'STATE' },
    { value: 'HOSPITAL' },
    { value: 'MEDEVAC' },
    { value: 'SAR' },
];

export const PURPOSE = [
    { value: 'TRAINING' },
    { value: 'BUSINESS' },
    { value: 'LEISURE' },
    { value: 'CARGO' },
    { value: 'AIRLINE' },
    { value: 'AERIAL_WORK' },
    { value: 'FERRY' },
];

export const DIRECTION = [{ value: 'DEPARTURE' }, { value: 'ARRIVAL' }];
