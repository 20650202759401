import * as React from 'react';
import { Input } from '../../../../../../ui-components/inputs/inputs/input';
import { Select } from '../../../../../../ui-components/inputs/select/select';
import { SwitchSelect } from '../../../../../../ui-components/inputs/select/switch-select';
import { TextInput } from '../../../../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../../../../ui-components/inputs/select/formatter';
import {
	CURRENCIES,
	FUEL_PRODUCT_TYPES,
	FUEL_PRODUCT_UNITS,
} from '../../../provider-contribution/constants';

export const fuelFields = (elmnt) => [
	{
		type: 'description',
		title: 'Additional informations',
		component: (
			<TextInput
				field={elmnt.form.getField('description')}
				textType='sentences'
				classes='NewData'
			/>
		),
	},
	{
		type: 'type',
		title: 'Type',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('type')}
				options={formatOptions(FUEL_PRODUCT_TYPES, false)}
			/>
		),
	},
	{
		type: 'unit',
		title: 'Unit',
		component: (
			<Select
				classes='NewData'
				field={elmnt.form.getField('unit')}
				options={formatOptions(FUEL_PRODUCT_UNITS, false)}
			/>
		),
	},
	{
		type: 'currency',
		title: 'Currency',
		component: (
			<Select
				field={elmnt.form.getField('currency')}
				classes='NewData'
				options={formatOptions(CURRENCIES, false)}
			/>
		),
	},
	{
		type: 'price',
		title: 'Price',
		component: (
			<Input
				inputType='number'
				field={elmnt.form.getField('price')}
				classes='NewData'
			/>
		),
	},
	{
		type: 'limitedAvailability',
		title: 'Limited availability',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('limitedAvailability')}
			/>
		),
	},
	{
		type: 'minimumVolume',
		title: 'Minimum volume',
		component: (
			<Input
				field={elmnt.form.getField('minimumVolume')}
				classes='NewData py-2'
				maxLengthLimit={6}
			/>
		),
	},
	{
		type: 'maximumVolume',
		title: 'Maximum volume',
		component: (
			<Input
				field={elmnt.form.getField('maximumVolume')}
				classes='NewData py-2'
				maxLengthLimit={6}
			/>
		),
	},
	{
		type: 'selfService',
		title: 'Self-service only',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.form.getField('selfService')}
			/>
		),
	},
	{
		type: 'minimumHandlingWaiver',
		title: 'Amount to waive handling charges',
		component: (
			<Input
				field={elmnt.form.getField('minimumHandlingWaiver')}
				classes='NewData'
				maxLengthLimit={6}
			/>
		),
	},
];
