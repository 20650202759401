import * as React from 'react';
import { connect } from 'react-redux';
import { arrayToClass } from '../../../../utilities/helper-fuctions';
import { abbreviation } from '../../../../utilities/variables';
import { HoverableButton } from '../../hoverable-buttons/hoverable-buttons';
import { Label } from '../label/label';
import { Input } from './input';
import { Textarea } from './textarea';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

export class ChangableInputComponent extends React.Component<any, any> {
	public ref$: any = React.createRef();

	public render() {
		const extraProps: any = {};

		if (!!this.props.id) {
			extraProps.id = this.props.id;
		}

		if (!!this.props.placeholder) {
			extraProps.placeholder = this.props?.placeholder;
		}

		const inputClasses = arrayToClass([
			this.props.inputClasses ? this.props.inputClasses : '',
		]);

		const properties = {
			maxLengthLimit: this.props.maxLengthLimit || false,
			numeralPositiveOnly: this.props.numeralPositiveOnly || false,
			disabled: this.props.disabled || false,
			inputClasses: inputClasses,
			field: this.props.field,
			onFocus: () =>
				this.props?.handleOnFocus && this.props?.handleOnFocus(),
			onBlur: () => this.props?.hanleOnBlur && this.props?.hanleOnBlur(),
			extras: extraProps,
			error: this.props?.error,
		};

		const classes = arrayToClass([
			this.props.classes ? this.props.classes : '',
			'w-100',
		]);

		const isAdmin = (this.props?.user?.roles || []).includes('ADMIN');

		return (
			<div className={classes}>
				{this.renderLabel()}
				<div className='w-100 display-flex align-items-center'>
					{!!this.props.element && this.renderTextarea(properties)}
					{!this.props.element && this.renderInput(properties)}

					{isAdmin && (
						<HoverableButton
							type='button'
							className='border-radius-1 ml-2'
							colorType='cancel'
							icon='find_replace'
							onClick={() => this.fixAbbreviations()}
						/>
					)}
				</div>
			</div>
		);
	}

	public renderLabel() {
		const label = this.props?.labelInfos;
		const labelClasses = arrayToClass([
			'mb-2',
			label?.classes ? label?.classes : '',
			this.props?.field?.mandatory ? 'asterix' : '',
		]);

		if (!label) {
			return null;
		}

		return (
			<Label
				info={label.info || false}
				label={label.label || false}
				className={labelClasses}
				ignoreTopMargin={label.ignoreTopMargin || false}
				isSimple={label.isSimple || false}
			/>
		);
	}

	public renderTextarea(properties): any {
		return (
			<Textarea
				ref={(ref: any) => (this.ref$ = ref)}
				{...properties}
				rows={this.props?.rows ? this.props?.rows : 3}
			/>
		);
	}

	public renderInput(properties): any {
		return <Input ref={(ref: any) => (this.ref$ = ref)} {...properties} />;
	}

	public fixAbbreviations() {
		let text = this.props?.field?.newValue;
		const abbreviationKeys = Object.keys(abbreviation);

		for (let key of abbreviationKeys) {
			const regexString = new RegExp(`(\\b|\^)${key}\\b`, 'g');
			text = text.replace(regexString, abbreviation[key]);
		}

		const formattedText = this.generateFormattedText(text);
		this.props?.field?.setValue(formattedText);
		this.ref$.setState({ value: formattedText });
	}

	public generateFormattedText(text) {
		if (!this.props.textType) {
			return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
		}

		switch (this.props.textType) {
			case 'camel':
				return text
					.toLowerCase()
					.replace(/\b[a-z]/gi, (letter) => letter.toUpperCase());
			case 'sentences':
				return (
					text.charAt(0).toUpperCase() +
					text
						.slice(1)
						.toLowerCase()
						.replace(/[.] [a-z]/gi, (letter) =>
							letter.toUpperCase()
						)
				);
			case 'special':
				return this.props.format(text);
			default:
				return text;
		}
	}
}

export const TextInput: any = connect(mapStateProps, mapDispatchProps, null, {
	forwardRef: true,
})(ChangableInputComponent);
