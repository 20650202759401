import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { AuthorityContactsContribution } from '../../../../../components/widgets/shared/authority/authority-contacts/contributions/authority-contacts.contribution';
import { connectNLP } from '../../../../../utilities/connect-navigate';
import { Contributions } from '../contributions';

const mapStateProps = (store: any) => ({
	user: store.user.user,
});

const mapDispatchProps = (dispatch: any) => ({});

class AuthorityContactsContributionsComponent extends Contributions<any, any> {
	public endpoint(): any {
		const user = get(this.props, 'user');
		const userRoles = get(user, 'roles');
		const isAdmin = userRoles.includes('ADMIN');
		const system = get(this.props, 'system');
		const systemInfo = system === true ? '/system' : `/users/${system}`;
		const otherInfo = isAdmin ? '' : `/users/${get(user, 'aid')}`;

		const systemAdminOrGeneral = isAdmin && system ? systemInfo : otherInfo;
		const airportOrCountry = `${
			this.props.isAirport ? 'airport' : 'country'
		}authoritycontacts`;

		return `/${airportOrCountry}/contributions${systemAdminOrGeneral}`;
	}

	public getType(): string {
		return this.props.isAirport ? 'authorityContacts' : 'countryContacts';
	}

	public getModalEndpoint() {
		return `/${
			this.props.isAirport ? 'airport' : 'country'
		}authoritycontacts/${get(this.state.item, 'entityAid')}`;
	}

	public renderModalContent(ctx): React.ReactElement {
		return (
			<AuthorityContactsContribution
				close={() => this.onModalClose()}
				isCountry={!this.props.isAirport}
				item={this.state.item}
				onSuccess={() => this.handleModalSuccess()}
				original={get(ctx.state, 'response')}
			/>
		);
	}
}

export const AuthorityContactsContributions: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(connectNLP(AuthorityContactsContributionsComponent));
