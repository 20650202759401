import { get } from 'lodash';
import * as React from 'react';
import { SubTab } from '../../../../../components/ui-components/tab/sub-tab';
import { connectNLP } from '../../../../../utilities/connect-navigate';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { FuelContributions } from './fuel-contributions/fuel-contributions';
import { GenericProductContributions } from './generic-product-contributions';
import { ProviderAvailabilityContributions } from './provider-availability-contributions';
import { SubProviderContributions } from './sub-provider-contributions';

const tabData = [
	{
		title: 'Providers',
		type: 'providers',
	},
	{
		title: 'Fuel products',
		type: 'fuels',
	},
	{
		title: 'Generic products',
		type: 'products',
	},
	{
		title: 'Availabilities',
		type: 'availabilities',
	},
];

class ProviderContributionsComponent extends React.Component<any, any> {
	public state = {
		providers: this.props?.total?.provider,
		fuels: this.getCounts(),
		products: this.props?.total?.products,
		availabilities: this.props?.total?.availabilities,
	};

	public getCounts() {
		const keys = Object.keys(this.props?.total?.fuels);
		let counter = 0;

		for (let key of keys) {
			counter = this.props?.total?.fuels?.[key] + counter;
		}

		return counter;
	}

	public render(): React.ReactElement {
		const type = get(this.props, 'subTypes');
		const providerType = get(type, 'provider', false);
		const fuelType = get(type, 'fuel', false);

		const tabs = tabData.map((tab) => {
			return {
				...tab,
				total: get(this.state, tab.type),
			};
		});

		const classes = arrayToClass([
			'w-100',
			this.props.show ? 'SubContainer' : '',
		]);

		return (
			<div className={classes}>
				{this.props.show && (
					<SubTab
						items={tabs}
						onItemClick={(item) => this.handleClick(item)}
						selected={providerType}
					/>
				)}

				<SubProviderContributions
					system={this.props.system}
					show={
						this.props.show &&
						(providerType === 'providers' || !providerType)
					}
					onTotalChange={(total) =>
						this.handleChanges('providers', total)
					}
					total={this.props?.total?.provider}
				/>
				<FuelContributions
					system={this.props.system}
					show={this.props.show && providerType === 'fuels'}
					fuelType={fuelType}
					onTotalChange={(total) =>
						this.handleChanges('fuels', total)
					}
					isAdmin={this.props.isAdmin}
					total={this.props?.total?.fuels}
				/>
				<GenericProductContributions
					system={this.props.system}
					show={this.props.show && providerType === 'products'}
					onTotalChange={(total) =>
						this.handleChanges('products', total)
					}
					total={this.props?.total?.products}
				/>
				<ProviderAvailabilityContributions
					system={this.props.system}
					show={this.props.show && providerType === 'availabilities'}
					onTotalChange={(total) =>
						this.handleChanges('availabilities', total)
					}
					total={this.props?.total?.availabilities}
				/>
			</div>
		);
	}

	public handleClick(item): any {
		if (this.props.isAdmin || this.props.system) {
			this.props?.router?.navigate(
				`/admin/${
					this.props.system ? 'system-' : ''
				}contributions/providers/${item.type}`
			);
		} else {
			this.props?.router?.navigate(
				`/contributions/providers/${item.type}`
			);
		}
	}

	public handleChanges(type, total) {
		const providers = type !== 'providers' ? this.state.providers : total;
		const fuels = type !== 'fuels' ? this.state.fuels : total;
		const products = type !== 'products' ? this.state.products : total;
		const availabilities =
			type !== 'availabilities' ? this.state.availabilities : total;

		const newTotal = providers + fuels + products + availabilities;
		this.setState({ [type]: total });
		this.props?.onTotalChange(newTotal);
	}
}

export const ProviderContributions: any = connectNLP(
	ProviderContributionsComponent
);
