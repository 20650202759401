import { format } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
	checkData,
	getData,
	getFields,
} from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import {
	arrayToClass,
	redirectToLogin,
} from '../../../../../utilities/helper-fuctions';
import { RequestManager } from '../../../../../utilities/request';
import { ToastMessages } from '../../../../notifications/toast-messages';
import { TextRenderer } from '../../../../other/text-renderer';
import { Form } from '../../../../ui-components/form/form';
import { ContributionFooter } from '../../contributions/contribution-footer';
import { WidgetContributionPresenter } from '../../contributions/widget-contribution-presenter/widget-contribution-presenter';
import { operationalNotesFields } from './operational-notes-contribution-helpers';

const mapStateProps = (store: any) => ({
	user: store.user.user,
	date: store.userDetails.date || 'yyyy. MM. dd. hh:mm',
});

const mapDispatchProps = (dispatch: any) => ({});

class OperationalNotesContributionComponent extends React.Component<any, any> {
	public state: any = {
		isAdmin: (this.props?.user?.roles || []).includes('ADMIN'),
		type: this.props.item?.contribution?.action,
		editable: [],
		rejected: [],
		buttonDisabled: false,
	};

	public form: any = new Form({
		validFrom: this.props?.item?.validFrom,
		validTo: this.props?.item?.validTo,
		criticality: this.props?.item?.criticality,
		category: this.props?.item?.category,
		source: this.props?.item?.source,
		notes: this.props?.item?.notes,
	});

	public render(): React.ReactElement {
		const operationalNotesList = getFields(
			this,
			operationalNotesFields(this)
		);

		return (
			<div className='Content w-100'>
				<div className='p-2'>
					{(!operationalNotesList || !operationalNotesList.length) &&
						this.renderEmpty()}
					{!!operationalNotesList.length &&
						this.renderContent(operationalNotesList)}
				</div>
				<ContributionFooter
					buttonDisabled={this.state.buttonDisabled}
					isAdmin={this.state.isAdmin}
					handleButtonClick={(isReject: boolean = false) =>
						this.handleButtonClick(isReject)
					}
					close={() => this.props.close()}
					handleEdit={() => this.handleEdit()}
				/>
			</div>
		);
	}

	public renderContent(list): React.ReactElement {
		return (
			<div className='w-100'>
				<p className='m-0 fw-bold palette--c-neutral-6'>
					Changes in operational notes widget
				</p>
				<div className='p-2 display-none display-md-flex'>
					<h6 className='Property palette--c-neutral-5 fw-bold m-0'>
						Property
					</h6>
					<h6 className='CurrentData palette--c-neutral-5 fw-bold m-0'>
						Current data
					</h6>
					<span className='ArrowIcons material-icons palette--c-neutral-1 fw-bold'>
						arrow_forward
					</span>
					<h6 className='NewData palette--c-neutral-5 fw-bold m-0'>
						New data
					</h6>
				</div>
				{list.map((item: any, index: number) => {
					return this.checkData(item, index);
				})}
			</div>
		);
	}

	public renderEmpty(): any {
		return null;
	}

	private checkData(field, index): any {
		const wrapperClassName = arrayToClass([
			this.state.rejected.includes(field.type)
				? 'palette--bgc-neutral-3'
				: `palette--bgc-${
						this.state.type === 'UPDATE'
							? 'yellow-1'
							: this.state.type === 'CREATE'
							? 'green-1'
							: 'red-1'
				  }`,
			'border-radius-1 my-2 p-2',
			'display-flex align-items-center',
		]);

		const element = {
			type: field.type,
			content: this.getData(field.type),
			component: field.component,
		};

		return (
			<WidgetContributionPresenter
				isAdmin={this.state.isAdmin}
				key={index}
				title={field.title}
				fromValue={this.getOriginalData(field.type)}
				toValue={element}
				isEditable={this.state.editable.includes(field.type)}
				isRejected={this.state.rejected.includes(field.type)}
				handleEdit={(type) => this.handleEdit(type)}
				missEdit={(type) => this.handleMissEdit(type)}
				handleReject={(type) => this.handleReject(type)}
				missReject={(type) => this.handleMissReject(type)}
				renderValue={
					element.type === 'notes'
						? (data) => this.renderNotes(data)
						: false
				}
				className={wrapperClassName}
			/>
		);
	}

	private renderNotes(notes): React.ReactElement {
		return <TextRenderer text={notes} classNames='NewData' />;
	}

	private getOriginalData(field: string): any {
		const value = this.props.original?.[field];

		if (this.state.type === 'CREATE') {
			return '';
		}

		if (field === 'validFrom' || field === 'validTo') {
			return format(new Date(value), this.props.date);
		}

		const bools: any[] = [];
		return getData(field, value, false, bools);
	}

	private getData(field: any): any {
		const form = this.form.generateJSON();
		const value = form?.[field];

		if (this.state.type === 'DELETE') {
			return '';
		}

		if (field === 'validFrom' || field === 'validTo') {
			return format(new Date(form?.[field]), this.props.date);
		}

		const bools: any[] = [];
		return getData(field, value, false, bools);
	}

	private async handleMissEdit(type: string): Promise<void> {
		if (this.state.editable.includes(type)) {
			const editableArray = this.state.editable.filter(
				(item) => item !== type
			);
			await this.setState({ editable: editableArray });
		}
	}

	private handleReject(type: string): void {
		const { rejected } = this.state;

		if (!rejected.includes(type)) {
			this.setState({ rejected: [...rejected, type] });
		}
	}

	private handleMissReject(type: string) {
		const { rejected } = this.state;

		if (rejected.includes(type)) {
			const rejectedArray = rejected.filter((item) => item !== type);
			this.setState({ rejected: rejectedArray });
		}
	}

	private async handleEdit(type: string = 'editAll'): Promise<void> {
		const { editable } = this.state;

		if (type === 'editAll') {
			const editableArray = operationalNotesFields(this).map(
				(item: any) => item.type
			);
			await this.setState({ editable: editableArray });
		}

		if (!editable.includes(type)) {
			await this.setState({ editable: [...editable, type] });
		}
	}

	private checkModifications(): any {
		const { item } = this.props;
		const list = getFields(this, operationalNotesFields(this)) || [];
		const form = this.form.generateJSON();

		item.contribution.rejectedChanges = this.state.rejected;
		item.contribution.changes = checkData(list, item, form);

		return item;
	}

	private onSuccess(message: string): void {
		toast.success(message, { theme: 'light' });
		this.setState({ buttonDisabled: false });
		this.props.onSuccess();
	}

	private onError(err): void {
		toast.error(<ToastMessages error={err} />, { theme: 'light' });
		this.setState({ buttonDisabled: false });
		redirectToLogin(err);
	}

	private async handleButtonClick(isReject: boolean = false): Promise<void> {
		this.setState({ buttonDisabled: true });
		let { item } = this.props;

		if (isReject) {
			item.contribution.status = 'REJECTED';
		} else {
			item = this.checkModifications();
		}

		try {
			const response = await RequestManager.post(
				this.props.endpoint,
				item
			);

			if (!response) {
				throw new Error('There was an error');
			}

			this.onSuccess(
				`Contribution is successfully ${
					isReject ? 'rejected' : 'accepted'
				}.`
			);
		} catch (err) {
			this.onError(err);
		}
	}
}

export const OperationalNotesContribution: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(OperationalNotesContributionComponent);
