import * as React from 'react';
import { DateTimePicker } from '../../../../ui-components/inputs/date-picker/date-time-picker';
import { Input } from '../../../../ui-components/inputs/inputs/input';
import { formatOptions } from '../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../ui-components/inputs/select/select';
import { TextInput } from '../../../../ui-components/inputs/inputs/text-input';

export const operationalNotesFields = (elmnt) => [
    {
        type: 'validFrom',
        title: 'Valid from',
        component: (
            <DateTimePicker
                field={elmnt.form.getField('validFrom')}
                inputClassName="palette--c-neutral-6"
                classes="NewData"
                showTimeInput={true}
                useHour={true}
            />
        ),
    },
    {
        type: 'validTo',
        title: 'Valid to',
        component: (
            <DateTimePicker
                field={elmnt.form.getField('validTo')}
                inputClassName="palette--c-neutral-6"
                classes="NewData"
                showTimeInput={true}
                useHour={true}
            />
        ),
    },
    {
        type: 'criticality',
        title: 'Criticality',
        component: (
            <Select
                classes="NewData"
                field={elmnt.form.getField('criticality')}
                options={formatOptions(criticalityOpts, false)}
            />
        ),
    },
    {
        type: 'category',
        title: 'Category',
        component: (
            <Select
                classes="NewData"
                field={elmnt.form.getField('category')}
                options={formatOptions(elmnt.props.isCountry ? countryCategory : category, false)}
            />
        ),
    },
    {
        type: 'source',
        title: 'Source',
        component: <Input field={elmnt.form.getField('source')} classes="NewData" />,
    },
    {
        type: 'notes',
        title: 'Notes',
        component: (
            <TextInput
                element="textarea"
                rows={5}
                field={elmnt.form.getField('notes')}
                classes="NewData"
                textType="sentences"
            />
        ),
    },
];

export const criticalityOpts = [
    { value: 'OPERATIONALLY_CRITICAL', title: 'Operationally critical' },
    { value: 'OPERATIONALLY_RELEVANT', title: 'Operationally relevant' },
    { value: 'INFORMATION_ONLY', title: 'Information only' },
];

export const category = [
    { value: 'RUNWAY', title: 'Runway' },
    { value: 'AIRLINES_TERMINAL', title: 'Airlines terminal' },
    { value: 'GENERAL_AVIATION_TERMINAL', title: 'General aviation terminal' },
    { value: 'FUEL', title: 'Fuel' },
    { value: 'PARKING', title: 'Parking' },
    { value: 'TAXIWAYS', title: 'Taxiways' },
    { value: 'WEATHER', title: 'Weather' },
    { value: 'PROCEDURES', title: 'Procedures' },
    { value: 'EVENTS', title: 'Events' },
    { value: 'COSTS', title: 'Costs' },
    { value: 'STRIKES', title: 'Strikes' },
    { value: 'IMMIGRATION', title: 'Immigration' },
    { value: 'DELAYS', title: 'Delays' },
    { value: 'RISK', title: 'Risk' },
    { value: 'RESTRICTIONS', title: 'Restrictions' },
];

export const countryCategory = [
    { value: 'WEATHER', title: 'Weather' },
    { value: 'PROCEDURES', title: 'Procedures' },
    { value: 'EVENTS', title: 'Events' },
    { value: 'COSTS', title: 'Costs' },
    { value: 'STRIKES', title: 'Strikes' },
    { value: 'IMMIGRATION', title: 'Immigration' },
    { value: 'DELAYS', title: 'Delays' },
    { value: 'RISK', title: 'Risk' },
    { value: 'RESTRICTIONS', title: 'Restrictions' },
];
