import * as React from 'react';
import { connect } from 'react-redux';
import { Search } from '../../components/search/search';
import { HoverableButton } from '../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { LandingLayout } from '../../layouts/landing/landing.layout';
import { isInQuery } from '../../utilities/helper-fuctions';
import './landing.screen.scss';
import { RecommendedAirports } from './recommended-airports';
import { RecommendedCountries } from './recommended-countries';

const mapStateProps = (store: any) => ({
	config: store.config.routeConfig,
});

const mapDispatchProps = (dispatch: any) => ({});

class LandingScreenComponent extends React.Component<any, any> {
	public state = {
		isCountry: this.props?.router?.location?.pathname === '/countries',
	};

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (
			this.props?.router?.location?.pathname !==
			prevProps?.router?.location?.pathname
		) {
			this.setState({
				isCountry:
					this.props?.router?.location?.pathname === '/countries',
			});
		}
	}

	public render(): React.ReactElement {
		return (
			<div className='LandingScreen w-100 display-flex flex-column align-items-center'>
				{this.renderSlogan()}
				{this.renderSearch()}
				{this.renderChooseButton()}
				{!this.state.isCountry && (
					<RecommendedAirports
						onClick={(aid: any) =>
							this.handleGoTo('/airports', aid)
						}
					/>
				)}
				{!!this.state.isCountry && (
					<RecommendedCountries
						onClick={(iso: any) =>
							this.handleGoTo('/countries', iso)
						}
					/>
				)}
			</div>
		);
	}

	public handleGoTo(type, aid = false) {
		const utmSource = isInQuery('utm_source') || '';
		this.props?.router?.navigate({
			pathname: `${type}${aid ? `/${aid}` : ''}`,
			search: utmSource,
		});
	}

	public renderSlogan(): React.ReactElement {
		return (
			<h1 className='Slogan palette--c-neutral-1 mb-6'>
				Aviation data for everybody
			</h1>
		);
	}

	public renderSearch(): React.ReactElement {
		return (
			<Search
				isCountry={this.state.isCountry}
				className='AirportSearchLandingScreen'
			/>
		);
	}

	public renderChooseButton() {
		return (
			<div className='AirportSearchLandingScreen w-100'>
				<div className='display-flex align-items-center py-2'>
					<p className='palette--c-neutral-1 m-0'>
						Do you want to find{' '}
						{this.state.isCountry ? 'an airport' : 'a country'}{' '}
						instead?
					</p>
					<HoverableButton
						className='ml-2 border-radius-1'
						colorType='avio-blue'
						title={`Find ${
							this.state.isCountry ? 'airport' : 'country'
						}`}
						onClick={() =>
							this.handleGoTo(
								this.state.isCountry ? '/' : '/countries'
							)
						}
					/>
				</div>
			</div>
		);
	}
}

export const LandingScreen: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(LandingLayout(LandingScreenComponent));
