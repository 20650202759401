import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { connectNLP } from '../../../../utilities/connect-navigate';
import { RequestManager } from '../../../../utilities/request';
import { StatusMarker } from '../../../ui-components/statusmarker/status-marker';
import { dimConvert } from '../../../../utilities/dim.transform';
import { isInQuery } from '../../../../utilities/helper-fuctions';

const mapStateProps = (store: any) => ({
    length: store.measures.length,
});

const mapDispatchProps = (dispatch: any) => ({});

class ReferenceAirportsComponent extends React.Component<any, any> {
    public state = {
        airport: false,
        error: false,
        loading: false,
    };

    public componentDidMount() {
        this.fetch();
    }

    public async fetch() {
        try {
            const response = await RequestManager.get(`/airports/${this.props.aid}`);

            if (!response) {
                throw new Error('error');
            }

            this.setState({ loading: false, airport: response });
        } catch (err) {
            this.setState({ loading: false, error: err });
        }
    }

    public render(): any {
        if (this.state.loading || this.state.error) {
            return null;
        }

        const name = get(this.state.airport, 'name');
        const pre = this.props.type === 'METAR and TAF' ? 'are' : 'is';

        const currentTargetDimension = get(this.props.length, 'targetDimension', null);
        const isMeter = currentTargetDimension === 'm';
        const isFt = currentTargetDimension === 'ft';
        const targetDimension = isMeter ? 'km' : isFt ? 'NM' : 'km';
        const distance = dimConvert(this.props.distance, 'km', targetDimension, 0);

        return (
            <div className="display-flex p-1 align-items-center">
                <p className="m-0 palette--c-neutral-6">
                    {this.props.type} for this airport {pre} not available. Here's the {this.props.type} for
                    <StatusMarker
                        tColor="yellow-1"
                        bgColor="yellow-3"
                        text={name}
                        type="airportname"
                        classNames="pointer ml-2"
                        onClick={() => this.navigate()}
                    />
                    , which is {distance} {targetDimension} far.
                </p>
            </div>
        );
    }

    public navigate() {
        const utmSource = isInQuery('utm_source');
        this.props?.router?.navigate({ pathname: `/airports/${this.props?.aid}`, search: utmSource });
    }
}

export const ReferenceAirports: any = connect(mapStateProps, mapDispatchProps, null, { forwardRef: true })(
    connectNLP(ReferenceAirportsComponent)
);
