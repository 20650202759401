import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../actions/details.actions';
import { UserDetailsActions } from '../../actions/user-details.actions';
import { AIPModal } from '../../components/aip/aip-modal';
import { Footer } from '../../components/footer/footer';
import { GridContainer } from '../../components/grid/grid.container';
import { GridItem } from '../../components/grid/grid.item';
import { MainHeaderButtons } from '../../components/header/main-header-buttons/main-header-buttons';
import { Search } from '../../components/search/search';
import { HoverableButton } from '../../components/ui-components/hoverable-buttons/hoverable-buttons';
import { Spinner } from '../../components/ui-components/spinner/spinner';
import { Authorities } from '../../components/widgets/airport/authority/authority';
import { AirportGeneralData } from '../../components/widgets/airport/general-data/airport-general-data';
import { MapWidget } from '../../components/widgets/airport/map/map';
import { OpeningHours } from '../../components/widgets/airport/opening-hours/opening-hours';
import { OperationData } from '../../components/widgets/airport/operational-data/operation-data';
import { OperationalNotes } from '../../components/widgets/airport/operational-notes/operational-notes';
import { Providers } from '../../components/widgets/airport/providers/providers';
import { Runways } from '../../components/widgets/airport/runways/runways';
import { Weather } from '../../components/widgets/airport/weather/weather';
import { DetailsLayout } from '../../layouts/details/details.layout';
import {
	arrayToClass,
	isInQuery,
	setTitleAndMeta,
} from '../../utilities/helper-fuctions';
import { RequestManager } from '../../utilities/request';
import { logoLink } from '../../utilities/variables';
import { Modals } from '../modals/modals';
import './details.screen.scss';

const mapStateProps = (store: any) => ({
	details: store.details,
	user: store.user.user,
	widgetSuccess: store.details.widgetSuccess,
	open: store.details.open,
});

const mapDispatchProps = (dispatch: any) => ({
	toggleMobile: () => dispatch(DetailsActions.toggleOpenMobile()),
	saveUserDetails: () => dispatch(UserDetailsActions.saveUserDetails({})),
	setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
	setOpen: (bool: boolean) => dispatch(DetailsActions.setOpen(bool)),
	setOpenMobile: (bool: boolean) =>
		dispatch(DetailsActions.setOpenMobile(bool)),
});

class DetailsScreenComponent extends React.Component<any, any> {
	public static PADDING_CLASSES = 'px-3 px-md-5';
	public dataAIP$: AIPModal;

	public state: any = {
		...this.state,
		isEdit: false,
		isContribute: false,
		airport: null,
		isScrolled: false,
		aipOpen: false,
	};

	public async componentDidMount(): Promise<any> {
		await this.fetchAirport();

		const doc: any = document.getElementById('Scrollable');
		doc.addEventListener(
			'scroll',
			(e) => {
				const scrollTop = e.target.scrollTop;

				if (scrollTop < 110 && this.state.isScrolled) {
					this.setState({ isScrolled: false });
				} else if (!this.state.isScrolled && scrollTop > 110) {
					this.setState({ isScrolled: true });
				}
			},
			false
		);
	}

	public componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	) {
		if (
			this.props.widgetSuccess !== prevProps.widgetSuccess &&
			(this.props.widgetSuccess === 'general-data' ||
				this.props.widgetSuccess === 'operations')
		) {
			this.fetchAirport();
			this.props.setSuccess();
		}

		if (this.props?.router?.params.aid !== prevProps.router?.params?.aid) {
			this.fetchAirport();
		}
	}

	public async fetchAirport(): Promise<any> {
		this.setState({ loading: true, error: false, airport: false });

		try {
			const response = await RequestManager.get(
				`/airports/${this.props?.router?.params?.aid}`
			);

			if (!response) {
				throw new Error('Empty response');
			}

			this.setState({ airport: response }, () =>
				this.handleTitleChange()
			);
		} catch (err) {
			this.setState({ error: err });
		} finally {
			this.setState({ loading: false });
		}
	}

	public handleTitleChange() {
		const airport = this.state?.airport;
		const name = airport?.name;
		const icao = airport?.icao;
		const iata = airport?.iata;
		const localIdentifier = airport?.localIdentifier;
		const type = (airport?.type || '').toLowerCase();
		const servedCity = airport?.servedCity;
		const country = airport?.country?.name;
		const servedCityGoverningDistrict =
			airport?.country?.servedCityGoverningDistrict;

		const codesString = `${icao ? icao + '/' : ''}${
			iata ? iata + '/' : ''
		}${localIdentifier ? localIdentifier : ''}`;
		const titleString = `${name} ${type} ${codesString}`;
		const metaString = `FREE information on ${name} (${codesString}), an ${type} ${
			servedCity ? 'serving the city of ' + servedCity : ''
		}${
			servedCityGoverningDistrict
				? ', ' + servedCityGoverningDistrict
				: ''
		} ${
			country ? 'in ' + country : ''
		}. Detailed information on FBOs, Runways and authorities at ${name}.`;

		setTitleAndMeta(titleString, metaString);
	}

	public render(): React.ReactElement {
		const { isEdit, isContribute, isScrolled } = this.state;

		const classes = arrayToClass([
			'DetailsScreen h-100 overflow-hidden',
			this.props.details.open ? 'open' : 'closed',
		]);

		const marginClasses = arrayToClass([
			isEdit || isContribute
				? 'SmallerAirportDetails'
				: 'NormalAirportDetails',
		]);

		const searchClasses = arrayToClass([
			'Search display-flex align-items-center Paddings position-absolute w-100',
			isScrolled ? 'White elevation-1' : 'Grey',
			marginClasses,
		]);

		const scrollableContainer = arrayToClass([
			'w-100 Paddings display-flex flex-column',
			isEdit || isContribute
				? 'ScrollableDetailsContainer'
				: 'ScrollableDetailsContainerBigger',
		]);

		return (
			<div className={classes}>
				<div className={searchClasses}>{this.renderSearch()}</div>
				<div className={marginClasses} />
				<div className={scrollableContainer} id='Scrollable'>
					{this.renderWidgets()}
					{this.renderFooter()}
				</div>
				<Modals {...this.props} />
				{this.state?.aipOpen && (
					<AIPModal
						data={this.state.airport}
						onClose={() => this.setState({ aipOpen: false })}
					/>
				)}
			</div>
		);
	}

	private renderSearch(): React.ReactElement {
		const { isEdit, isContribute } = this.state;
		const imageContainerClasses = arrayToClass([
			'ImageContainer display-flex align-items-center pointer',
			this.props?.open ? 'imageOpen' : 'imageClosed',
		]);

		return (
			<div className='w-100'>
				<div className='my-3'>
					<div className='DetailsHeader w-100 display-flex align-items-center'>
						<div
							className={imageContainerClasses}
							onClick={() => this.handleToMain()}
						>
							<img
								src={logoLink}
								alt='aviowiki'
								className='AvioLogo mr-2'
							/>
						</div>

						<div className='display-flex flex-fill justify-content-end align-items-center'>
							<p className='palette--c-neutral-5 display-none display-sm-block m-0 mr-4'>
								Trusted by professionals worldwide.
							</p>
							<MainHeaderButtons
								isDetails={true}
								changeToEditMode={() => this.changeToEditMode()}
							/>
						</div>
					</div>
					<p className='palette--c-neutral-5 display-sm-none m-0 w-100 display-flex align-items-end justify-content-end'>
						Trusted by professionals worldwide.
					</p>
				</div>
				<Search className='SearchbarOnDetails w-100' />
				<div className='w-100 py-3 display-flex align-items-end'>
					{this.renderButtons()}
				</div>
				{(isEdit || isContribute) && this.renderSaveEditButton()}
			</div>
		);
	}

	public renderButtons() {
		const isAdmin = (get(this.props.user, 'roles') || []).includes('ADMIN');

		return (
			<div className='w-100 display-flex'>
				<div className='display-lg-none w-100 display-flex justify-content-start'>
					<HoverableButton
						colorType='contrast'
						type='button'
						className='border-radius-1'
						title='Nearby airports'
						onClick={() => this.props.toggleMobile()}
					/>
				</div>

				{isAdmin && (
					<div className='w-100 display-flex justify-content-end'>
						<HoverableButton
							colorType='avio-green'
							type='button'
							className='border-radius-1'
							title='AIP data entry'
							onClick={() => this.setState({ aipOpen: true })}
						/>
					</div>
				)}
			</div>
		);
	}

	private renderSaveEditButton(): React.ReactElement {
		const text = this.state.isEdit
			? 'Now, you can edit the widget position and size'
			: "Now, you can edit the widgets' content";

		return (
			<div className='LayoutSettingsSaveContainer w-100 border-1 palette--bc-primary-4 border-radius-1 palette--bgc-green-1 py-2 Paddings display-flex my-4'>
				<div className='flex-fill display-flex align-items-center'>
					<span className='SaveEditLayoutIcon palette--c-green-2 material-icons mr-2'>
						check_circle
					</span>
					<p className='m-0 palette--c-neutral-6'>{text}</p>
				</div>
				<HoverableButton
					titleLeft='Save Layout'
					icon='check'
					colorType='avio-green'
					className='SaveButton'
					onClick={() => this.handleSave()}
				/>
			</div>
		);
	}

	public handleToMain() {
		this.props.setOpen(false);
		this.props.setOpenMobile(false);
		const utmSource = isInQuery('utm_source');
		this.props?.router?.navigate({ pathname: '/', search: utmSource });
	}

	private handleSave(): void {
		this.setState({ isEdit: false, isContribute: false });

		if (this.props.saveUserDetails) {
			this.props.saveUserDetails();
		}
	}

	private renderWidgets(): React.ReactElement {
		const classes = arrayToClass([
			'AirportDetails',
			'w-100 flex-fill',
			DetailsScreen.PADDING_CLASSES,
		]);

		return (
			<div className={classes}>
				{this.state.loading &&
					!this.state.error &&
					!this.state.airport &&
					this.renderLoading()}
				{!this.state.loading &&
					this.state.error &&
					!this.state.airport &&
					this.renderError()}
				{!this.state.loading &&
					!this.state.error &&
					this.state.airport &&
					this.renderData()}
			</div>
		);
	}

	private renderLoading(): React.ReactElement {
		return <Spinner size='large' />;
	}

	private renderError(): React.ReactElement {
		const status = get(this.state.error, 'response.status');
		const isNotFound = status === 404;
		const message = isNotFound
			? get(this.state.error, 'response.data.message')
			: 'There was an error. We are working to fix the issue and will be back soon!';

		return (
			<div className='h-100 display-flex justify-content-center align-items-center p-8'>
				<p className='palette--c-neutral-5'>{message}</p>
			</div>
		);
	}

	private renderData(): React.ReactElement {
		const success = this.props?.widgetSuccess;
		const airport = this.state?.airport;

		return (
			<div className='GridContainerWrapper'>
				{airport?.archived && (
					<div className='palette--bgc-red-1 border-radius-1 border-1 palette--bc-red-2 p-2 display-flex'>
						<span className='material-icons palette--c-red-2 mr-2'>
							error
						</span>
						<p className='m-0'>Permanently closed</p>
					</div>
				)}

				<GridContainer
					aid={this.props?.router?.params?.aid}
					editable={get(this.state, 'isEdit')}
				>
					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-2'
						identifier='general-data'
						title='General data'
					>
						<AirportGeneralData airport={airport} />
					</GridItem>

					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-2'
						identifier='authorities'
						title='Authorities'
					>
						<Authorities
							airport={airport}
							refresh={
								success === 'authority' ||
								success === 'authority-delete'
							}
						/>
					</GridItem>

					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-2'
						identifier='weather'
						title='weather'
					>
						<Weather airport={airport} />
					</GridItem>

					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-0'
						identifier='availability'
						title='availability'
					>
						<OpeningHours
							airport={airport}
							refresh={success === 'availabilities'}
						/>
					</GridItem>

					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-0'
						identifier='map'
						title='map'
					>
						<MapWidget airport={airport} />
					</GridItem>

					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-0'
						identifier='runways'
						title='runways'
					>
						<Runways
							airport={airport}
							refresh={
								success === 'runways' ||
								success === 'runways-delete'
							}
						/>
					</GridItem>

					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-2'
						identifier='operations'
						title='Operations'
					>
						<OperationData airport={airport} />
					</GridItem>

					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-2'
						identifier='operational-notes'
						title='Operational notes'
					>
						<OperationalNotes
							airport={airport}
							refresh={
								success === 'operational-note' ||
								success === 'operational-note-delete'
							}
						/>
					</GridItem>

					<GridItem
						wrapperClassName='elevation-1'
						contentClassName='p-0'
						identifier='providers'
						title='providers'
					>
						<Providers
							airport={airport}
							refresh={
								success === 'provider' ||
								success === 'provider-delete'
							}
						/>
					</GridItem>
				</GridContainer>
			</div>
		);
	}

	private renderFooter(): React.ReactElement {
		const classes = arrayToClass([
			this.state.loading ? 'position-absolute absolute-bottom-0' : '',
			'w-100',
		]);

		return (
			<div className={classes}>
				<Footer />
			</div>
		);
	}

	private changeToEditMode() {
		this.setState({ isEdit: true });
	}
}

export const DetailsScreen: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(DetailsLayout(DetailsScreenComponent));
