import * as React from 'react';
import { arrayToClass, randomId } from '../../../../utilities/helper-fuctions';
import { Tooltip } from '../../popover/popover';
import './label.scss';

export class Label extends React.Component<any, any> {
    public state = {
        open: false,
    };

    public render() {
        const className = arrayToClass([
            'display-flex align-items-center justify-content-start',
            this.props.ignoreTopMargin ? '' : 'mt-2',
            this.props.className,
        ]);

        const labelClasses = arrayToClass([
            'm-0 input-label',
            this.props.info ? 'mr-2' : '',
            this.props.isSimple ? 'Simple palette--c-neutral-5' : 'fw-bold palette--c-neutral-6',
        ]);

        return (
            <div className={className}>
                <h5 className={labelClasses}>{this.props.label}</h5>
                {this.props.info && <Tooltip tooltip={this.renderPopover()} trigger={this.renderPopoverElement()} />}
            </div>
        );
    }

    public renderPopover(): any {
        return (
            <div className="PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1">{this.renderInfo()}</div>
        );
    }

    public renderInfo() {
        const info = this.props.info || '';
        const infoArray = info.split('\n');

        return infoArray.map((infoElement) => {
            const lineToArray = infoElement.split('|');

            return (
                <div key={randomId()} className="display-flex align-items-center flex-wrap py-9">
                    {lineToArray.map((element) => {
                        if (!element) {
                            return null;
                        }

                        if (element.match(/^[A-Z0-9 ]*$/g) || element === 'Do not specified') {
                            return (
                                <span
                                    key={randomId()}
                                    className="mr-1 palette--c-neutral-6 palette--bgc-neutral-1 border-radius-1 px-1 py-9"
                                >
                                    {element}
                                </span>
                            );
                        }

                        const elementToWords = element.split(' ');

                        return elementToWords.map((word) => (
                            <span key={randomId()} className="mr-1 palette--c-neutral-2">
                                {word}
                            </span>
                        ));
                    })}
                </div>
            );
        });
    }

    public renderPopoverElement() {
        return (
            <p
                className="palette--c-primary-4 m-0 material-icons pointer"
                onMouseEnter={() => this.toggle(true)}
                onMouseLeave={() => this.toggle(false)}
            >
                info_outline
            </p>
        );
    }

    public toggle(value) {
        this.setState({ open: value });
    }
}
