import * as React from 'react';
import { DateTimePicker } from '../../../../ui-components/inputs/date-picker/date-time-picker';
import { Input } from '../../../../ui-components/inputs/inputs/input';
import { TextInput } from '../../../../ui-components/inputs/inputs/text-input';
import { formatOptions } from '../../../../ui-components/inputs/select/formatter';
import { Select } from '../../../../ui-components/inputs/select/select';
import { SwitchSelect } from '../../../../ui-components/inputs/select/switch-select';
import { BlocksContribution } from './blocks-contribution';
import {
	afis,
	AIRFRAME,
	catHelis,
	DAYS,
	DIRECTION,
	eUpToI,
	faaCatAirplane,
	FILING,
	icaoCatAirplane,
	PURPOSE,
	RULES,
	STATUSES,
} from './constants';
import { FlightContribution } from './flight-types-contribution';

export const availabilityFields = (elmnt) => [
	{
		type: 'validFrom',
		title: 'Valid From',
		component: (
			<DateTimePicker
				field={elmnt.form.getField('validFrom')}
				inputClassName='palette--c-neutral-6'
				classes='NewData'
			/>
		),
	},
	{
		type: 'validTo',
		title: 'Valid To',
		component: (
			<DateTimePicker
				field={elmnt.form.getField('validTo')}
				inputClassName='palette--c-neutral-6'
				classes='NewData'
			/>
		),
	},
	{
		type: 'scheduleUnits',
		title: 'Days',
		component: (
			<Select
				field={elmnt.form.getField('scheduleUnits')}
				classes='NewData'
				options={formatOptions(DAYS)}
			/>
		),
	},
];

export const atcBlockFields = (elmnt: BlocksContribution) => [
	{
		type: 'afisOnly',
		title: 'Level of service',
		component: (
			<Select
				classes='NewData'
				field={elmnt.atc.getField('afisOnly')}
				options={formatOptions(afis)}
			/>
		),
	},
	{
		type: 'enRtfAvailable',
		title: 'English speeking operator',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.atc.getField('enRtfAvailable')}
			/>
		),
	},
];

export const arffBlockFields = (elmnt: BlocksContribution) => [
	{
		type: 'icaoCatAirplane',
		title: 'ICAO ARFF category',
		component: (
			<Select
				classes='NewData'
				field={elmnt.arff.getField('icaoCatAirplane')}
				options={formatOptions(icaoCatAirplane)}
			/>
		),
	},
	{
		type: 'faaCatAirplane',
		title: 'FAA ARFF category',
		component: (
			<Select
				classes='NewData'
				field={elmnt.arff.getField('faaCatAirplane')}
				options={formatOptions(faaCatAirplane)}
			/>
		),
	},
	{
		type: 'faa139Certified',
		title: '14 CFR §139',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.arff.getField('faa139Certified')}
			/>
		),
	},
	{
		type: 'catHelicopters',
		title: 'ARFF helicopters',
		component: (
			<Select
				classes='NewData'
				field={elmnt.arff.getField('catHelicopters')}
				options={formatOptions(catHelis)}
			/>
		),
	},
	{
		type: 'waterQuantity',
		title: 'Water capacity',
		component: (
			<Input
				field={elmnt.arff.getField('waterQuantity')}
				classes='NewData'
				inputType='number'
			/>
		),
	},
	{
		type: 'complementaryQuantity',
		title: 'Complimentary Extinguishing Agent available capacity',
		component: (
			<Input
				field={elmnt.arff.getField('complementaryQuantity')}
				classes='NewData'
				inputType='number'
			/>
		),
	},
	{
		type: 'arffVehicles',
		title: 'ARFF vehicles',
		component: (
			<Input
				field={elmnt.arff.getField('arffVehicles')}
				classes='NewData'
				inputType='number'
			/>
		),
	},
	{
		type: 'dischargeCapacity',
		title: 'All ARFF vehicles capacity',
		component: (
			<Input
				field={elmnt.arff.getField('dischargeCapacity')}
				classes='NewData'
				inputType='number'
			/>
		),
	},
	{
		type: 'extensionAvailable',
		title: 'Category upgrade available',
		component: (
			<SwitchSelect
				classes='NewData'
				field={elmnt.arff.getField('extensionAvailable')}
			/>
		),
	},
	{
		type: 'extensionNotice',
		title: 'Notice for Extension (hours)',
		component: (
			<Input
				field={elmnt.arff.getField('extensionNotice')}
				classes='NewData'
				inputType='number'
			/>
		),
	},
	{
		type: 'extensionUpToIcao',
		title: 'Upgrade available up to',
		component: (
			<Select
				classes='NewData'
				field={elmnt.arff.getField('extensionUpToIcao')}
				options={formatOptions(eUpToI)}
			/>
		),
	},
	{
		type: 'firestationRemote',
		title: 'Remote ARFF service',
		component: (
			<SwitchSelect
				field={elmnt.arff.getField('firestationRemote')}
				classes='NewData'
			/>
		),
	},
	{
		type: 'firestationNotes',
		title: 'Remote ARFF service notes',
		component: (
			<TextInput
				field={elmnt.arff.getField('firestationNotes')}
				classes='NewData'
				textType='sentences'
			/>
		),
	},
];

export const limitedMovementFields = (elmnt) => [
	{
		type: 'priorNoticeRequired',
		title: 'Prior notice required',
		component: (
			<Input
				inputType='number'
				field={elmnt.form.getField('priorNoticeRequired')}
				classes='NewData'
			/>
		),
	},
	{
		type: 'costsInvolved',
		title: 'Costs involved',
		component: (
			<SwitchSelect
				field={elmnt.form.getField('costsInvolved')}
				classes='NewData'
			/>
		),
	},
	{
		type: 'other',
		title: 'Other',
		component: (
			<TextInput
				field={elmnt.form.getField('other')}
				classes='NewData'
				type='textarea'
				rows={3}
				textType='sentences'
			/>
		),
	},
];

export const flightTypesFields = (elmnt: FlightContribution) => [
	{
		type: 'rules',
		title: 'Rules',
		component: (
			<Select
				isMulti={true}
				field={elmnt.form.getField('rules')}
				classes='NewData'
				options={formatOptions(RULES)}
			/>
		),
	},
	{
		type: 'filing',
		title: 'Filing',
		component: (
			<Select
				isMulti={true}
				field={elmnt.form.getField('filing')}
				classes='NewData'
				options={formatOptions(FILING)}
			/>
		),
	},
	{
		type: 'airframe',
		title: 'Airframe',
		component: (
			<Select
				isMulti={true}
				field={elmnt.form.getField('airframe')}
				classes='NewData'
				options={formatOptions(AIRFRAME)}
			/>
		),
	},
	{
		type: 'status',
		title: 'Status',
		component: (
			<Select
				isMulti={true}
				field={elmnt.form.getField('status')}
				classes='NewData'
				options={formatOptions(STATUSES)}
			/>
		),
	},
	{
		type: 'purpose',
		title: 'Purpose',
		component: (
			<Select
				isMulti={true}
				field={elmnt.form.getField('purpose')}
				classes='NewData'
				options={formatOptions(PURPOSE)}
			/>
		),
	},
	{
		type: 'direction',
		title: 'Direction',
		component: (
			<Select
				isMulti={true}
				field={elmnt.form.getField('direction')}
				classes='NewData'
				options={formatOptions(DIRECTION)}
			/>
		),
	},
];
