import { get } from 'lodash';
import { stringify } from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { LocalstorageActions } from '../../../actions/localstorage.actions';
import { UserActions } from '../../../actions/user.actions';
import { connectNLP } from '../../../utilities/connect-navigate';
import { getQuery } from '../../../utilities/helper-fuctions';
import { LocalStorage } from '../../../utilities/local-storage';
import { AIPModal } from '../../aip/aip-modal';
import { Dropdown } from '../../ui-components/dropdown/dropdown';
import { HoverableButton } from '../../ui-components/hoverable-buttons/hoverable-buttons';
import { Tooltip } from '../../ui-components/popover/popover';
import { GeneralDataForm } from '../../widgets/airport/general-data/general-data-form';
import './main-header-buttons.scss';

const mapStateProps = (store: any) => ({
	user: store.user.user,
	modalIdentifier: store.localStorage.modalIdentifier,
});

const mapDispatchProps = (dispatch: any) => ({
	logout: () => dispatch(UserActions.logout()),
	setIdentifier: (identifier) =>
		dispatch(LocalstorageActions.setModalIdentifier(identifier)),
});

class MainHeaderButtonsComponent extends React.Component<any, any> {
	public dropdown$: Dropdown;

	public state: any = {
		admin: false,
		aipOpen: false,
	};

	public componentDidMount(): void {
		const roles = get(this.props.user, 'roles', []);
		this.setState({ admin: roles.includes('ADMIN') });
	}

	public render(): React.ReactElement {
		return (
			<div className='LoginDropownContainer'>
				{this.renderHeader()}
				{this.props.modalIdentifier === 'create_new_airport' && (
					<GeneralDataForm
						onClose={() => this.props.setIdentifier(null)}
						isEdit={false}
					/>
				)}
			</div>
		);
	}

	public renderHeader(): any {
		if (this.props.user) {
			return this.renderLogedInButtons();
		}

		return this.renderNotLogedInButtons();
	}

	public renderNotLogedInButtons(): React.ReactElement {
		return (
			<div className='display-flex'>
				{!this.props.isAdd &&
					!this.props.isSearch &&
					this.renderCopyPopover()}
				{this.renderLoginButton()}
				{this.renderSignInButton()}
				{this.renderMenu()}
			</div>
		);
	}

	public renderSignInButton(): React.ReactElement {
		return (
			<div className='display-inline-box'>
				<HoverableButton
					className='SignInButton border-radius-1 elevation-1 mx-2'
					colorType='avio-blue'
					title='Register Free'
					onClick={() => this.handleGoTo('/registration')}
				/>
			</div>
		);
	}

	public renderLoginButton(): React.ReactElement {
		return (
			<div className='display-inline-box'>
				<HoverableButton
					className='border-radius-1 elevation-1 display-flex align-items-center'
					colorType='neutral'
					onClick={() => this.handleGoTo('/login')}
					icon='lock'
				/>
			</div>
		);
	}

	public renderMenuToggle(): React.ReactElement {
		return (
			<div className='display-inline-box'>
				<HoverableButton
					className='border-radius-1 elevation-1 display-flex align-items-center'
					colorType='neutral'
					onClick={() => this.dropdown$.open()}
					icon='menu'
				/>
			</div>
		);
	}

	public handleGoTo(endpoint, params = {}, isUser = false) {
		if (!!this.dropdown$) {
			this.dropdown$.close();
		}

		if (isUser) {
			LocalStorage.setItem(
				'redirectLink',
				`${window.location.pathname}${window.location.search}`
			);
		}

		const queries = stringify(params);
		this.props?.router?.navigate({ pathname: endpoint, search: queries });
	}

	public renderMenuDropdown(): React.ReactElement {
		const isLogedIn = get(this.props, 'user');

		return (
			<div className='palette--bgc-neutral-1 border-radius-1 elevation-1'>
				{this.props.isDetails && (
					<div
						className='Widgets w-100 display-flex align-items-center p-2 border-bottom-1 palette--bc-neutral-3 pointer'
						onClick={() => this.editModeClick()}
					>
						<span className='LogedInUserIcons pointer mr-2 material-icons palette--c-secondary-4'>
							view_quilt
						</span>
						<p className='pointer m-0 palette--c-secondary-4 flex-fill'>
							Reorder widgets
						</p>
					</div>
				)}

				{isLogedIn && this.renderLoggedInDropdownContent()}
				{!isLogedIn && this.renderSettingsOption()}
			</div>
		);
	}

	public renderSettingsOption() {
		return (
			<div>
				<div
					className='Settings display-flex align-items-center p-2 pointer'
					onClick={() => this.handleGoTo('/user')}
				>
					<span className='pointer material-icons palette--c-secondary-4 LogedInUserIcons mr-2'>
						settings
					</span>
					<p className='pointer palette--c-secondary-4 m-0'>
						Settings
					</p>
				</div>
				<div
					className='Airports display-flex align-items-center p-2 pointer'
					onClick={() => this.handleGoTo('/search', getQuery())}
				>
					<span className='material-icons palette--c-secondary-4 LogedInUserIcons mr-2'>
						flight
					</span>
					<p className='palette--c-secondary-4 m-0'>All airports</p>
				</div>
			</div>
		);
	}

	public renderLoggedInDropdownContent(): React.ReactElement {
		const isAdmin = this.state.admin;

		return (
			<div>
				<div className='px-4 py-2'>
					<p className='pointer m-0 palette--c-neutral-6 fw-bold'>
						{get(this.props.user, 'name')}
					</p>
					<span className='pointer palette--c-neutral-5'>
						{get(this.props.user, 'login')}
					</span>
				</div>
				<div
					className='Profile display-flex align-items-center p-2 pointer'
					onClick={() => this.handleGoTo('/user', '', true)}
				>
					<span className='pointer material-icons palette--c-secondary-4 LogedInUserIcons mr-2'>
						account_circle
					</span>
					<p className='pointer palette--c-secondary-4 m-0'>
						User Profile
					</p>
				</div>
				<div
					className='Airports display-flex align-items-center p-2 pointer'
					onClick={() => this.handleGoTo('/search', getQuery())}
				>
					<span className='pointer material-icons palette--c-secondary-4 LogedInUserIcons mr-2'>
						flight
					</span>
					<p className='pointer palette--c-secondary-4 m-0'>
						All airports
					</p>
				</div>
				<div
					className='Admin display-flex align-items-center p-2 pointer'
					onClick={() => this.handleContributionsClick()}
				>
					<span className='pointer material-icons palette--c-secondary-4 LogedInUserIcons mr-2'>
						rate_review
					</span>
					{isAdmin ? (
						<p className='pointer palette--c-secondary-4 m-0'>
							Admin
						</p>
					) : (
						<p className='pointer palette--c-secondary-4 m-0'>
							My Pending Contributions
						</p>
					)}
				</div>

				<div className='w-100 border-top-1 palette--bc-neutral-3 p-2 display-flex justify-content-center'>
					<HoverableButton
						colorType='transparent-green'
						title='Logout'
						onClick={() => this.props.logout && this.props.logout()}
					/>
				</div>
			</div>
		);
	}

	public handleContributionsClick() {
		if (this.state.admin) {
			window.open('/admin/contributions', '_blank');
		} else {
			window.open('/contributions', '_blank');
		}
	}

	public editModeClick(): void {
		this.dropdown$.close();
		if (this.props.changeToEditMode) {
			this.props.changeToEditMode();
		}
	}

	public renderLogedInButtons(): React.ReactElement {
		return (
			<div className='LogedInUserDropdownContainer display-flex'>
				{this.props.isAdd && (
					<div className='display-flex'>
						{this.renderAIPEntry()}
						{this.renderCreateButton()}
					</div>
				)}
				{!this.props.isAdd &&
					!this.props.isSearch &&
					this.renderCopyPopover()}
				{this.renderMenu()}
			</div>
		);
	}

	public renderCopyPopover() {
		return (
			<Tooltip
				tooltip={this.renderPopover()}
				trigger={this.renderCopy()}
			/>
		);
	}

	public renderPopover(): any {
		return (
			<div className='PopoverContent palette--bgc-neutral-6 border-radius-1 px-2 py-1'>
				<span className='palette--c-neutral-1'>Copy URL</span>
			</div>
		);
	}

	public renderCopy() {
		return (
			<HoverableButton
				className='border-radius-1 elevation-1 display-flex align-items-center mr-2'
				colorType='neutral'
				onClick={() => this.handleCopy()}
				onEnter={() => this.setState({ hovered: true })}
				onLeave={() => this.setState({ hovered: false })}
				icon='link'
			/>
		);
	}

	public handleCopy() {
		navigator.clipboard
			.writeText(`${window.location.href}?origin=link-share`)
			.then(() => {
				toast.success('URL is copied to clipboard!', {
					theme: 'light',
				});
			})
			.catch((err) => err);
	}

	public renderMenu(): React.ReactElement {
		return (
			<Dropdown
				direction='right'
				ref={(ref: any) => (this.dropdown$ = ref)}
				trigger={() => this.renderMenuToggle()}
				dropdown={() => this.renderMenuDropdown()}
				backdropClasses='EditBackdrop palette--bgc-neutral-transparent'
				className='DropdownComponent'
			/>
		);
	}

	public open(): void {
		this.props.setIdentifier('create_new_airport');
	}

	public renderAIPEntry() {
		const isAdmin = (get(this.props.user, 'roles') || []).includes('ADMIN');

		if (!isAdmin) {
			return null;
		}

		return (
			<div className='display-flex justify-content-end'>
				<HoverableButton
					colorType='avio-green'
					type='button'
					className='border-radius-1 elevation-1'
					title='AIP data entry'
					onClick={() => this.setState({ aipOpen: true })}
				/>
				{!!this.state?.aipOpen && (
					<AIPModal
						onClose={() => this.setState({ aipOpen: false })}
					/>
				)}
			</div>
		);
	}

	public renderCreateButton(): React.ReactElement {
		return (
			<HoverableButton
				className='border-radius-1 elevation-1 mx-2 mr-2'
				colorType='neutral'
				onClick={() => this.open()}
				titleClasses='display-none display-lg-flex m-0 ml-2'
				titleRight='Create new airport'
				icon='flight'
			/>
		);
	}
}

export const MainHeaderButtons: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(connectNLP(MainHeaderButtonsComponent));
