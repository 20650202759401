import { startOfDay } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { DetailsActions } from '../../../../actions/details.actions';
import { Form } from '../../../ui-components/form/form';
import { Checkbox } from '../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { OperationalNotesCard } from '../../shared/notes/operational-notes-card';
import { Widget } from '../../widget';
import './operational-notes.scss';

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
	setSuccess: () => dispatch(DetailsActions.setSuccess(false)),
});

class OperationalNotesComponent extends Widget<any, any> {
	public modal$;

	public state: any = {
		...this.state,
		countries: [],
		showAll: true,
	};

	public form = new Form({
		showAll: {
			value: true,
			trigger: () =>
				this.setState({ showAll: this.form.getValue('showAll') }),
		},
	});

	public getQuery(): any {
		return {
			dateTime: startOfDay(new Date()),
		};
	}

	public getEndpoint(): string {
		return `/airports/${this.props?.airport?.aid}/operationalnotes`;
	}

	public render(): React.ReactElement {
		let { response = [] } = this.state;

		if (!this.state.showAll) {
			response = response.filter((item) => item.kind === 'AIRPORT');
		}

		return (
			<div className='OperationalNotesComponent w-100'>
				{this.state.loading &&
					!this.state.response &&
					!this.state.error &&
					this.renderLoading()}
				{!this.state.loading &&
					!this.state.response &&
					this.state.error &&
					this.renderError()}
				{!this.state.loading &&
					!this.state.error &&
					this.renderCheckBox()}
				{!this.state.loading &&
					this.state.response &&
					!this.state.error && (
						<OperationalNotesCard items={response} />
					)}
			</div>
		);
	}

	public renderCheckBox() {
		return (
			<Checkbox
				field={this.form.getField('showAll')}
				labelInfos={{ label: 'Show both country and airport notes' }}
				classes='py-2'
			/>
		);
	}
}

export const OperationalNotes: any = connect(
	mapStateProps,
	mapDispatchProps,
	null,
	{ forwardRef: true }
)(OperationalNotesComponent);
