import { format } from 'date-fns';
import * as React from 'react';
import { getLinks } from '../../../../../screens/admin-screens/contributions/helper-functions/getters';
import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { ResourceLoader } from '../../../../other/resource-loader';
import { StatusMarker } from '../../../../ui-components/statusmarker/status-marker';
import './contribution-header.scss';

export class ContributionHeader extends React.Component<any, any> {
	public isMounted = false;

	public state: any = {
		disableLink: false,
		link: null,
		name: null,
		aid: null,
		parent: null,
	};

	public async componentDidMount() {
		this.isMounted = true;
		if (!!this.props?.item) {
			await this.getLinks();
		}
	}

	public componentWillUnmount() {
		this.isMounted = false;
	}

	public async getLinks() {
		const { item, type } = this.props;
		const getter = await getLinks(type, item);
		if (this.isMounted) {
			this.setState({
				link: getter.link,
				name: getter.name,
				parent: getter.parent,
				aid: getter.aid,
			});
		}
	}

	public render(): any {
		const { className, item, type, isTitle, addedTitle } = this.props;
		const { name, parent, aid, link } = this.state;

		if (!this.props?.item) {
			return null;
		}

		const itemPresenterClasses = arrayToClass([
			className ? className : '',
			'w-100 display-flex align-items-center flex-wrap',
		]);

		const wrapperClasses = arrayToClass([
			'Header w-100 position-relative',
			isTitle ? 'p-2' : '',
		]);

		const user = this.props?.item?.contribution?.user;
		const isAirport = type === 'airports';

		const ownLink = isAirport ? link : `${link}/${aid}`;

		return (
			<div className={wrapperClasses}>
				{isTitle && (
					<h4 className='m-0 fw-bold palette--c-neutral-6'>
						Contribution details
					</h4>
				)}
				<div className={itemPresenterClasses}>
					{(!link || !parent) && !isAirport && (
						<div className='AidMarker' />
					)}
					{!!parent && !!link && (
						<div className='AidMarker my-2 my-md-0'>
							<a href={link} target='_blank'>
								<StatusMarker
									tColor='secondary-4'
									bgColor='secondary-1'
									text={parent}
									type='tag'
									id='link'
								/>
							</a>
						</div>
					)}
					<div className='AidMarker my-2 my-md-0'>
						{aid && (
							<a href={ownLink} target='_blank'>
								<StatusMarker
									tColor='secondary-4'
									bgColor='secondary-1'
									text={aid}
									type='tag'
									id='link'
								/>
							</a>
						)}
					</div>
					<div className='NameOfContributedItem display-flex'>
						<p className='palette--c-neutral-6 m-0 my-2 my-md-0'>
							{name}
						</p>
						{addedTitle && (
							<p className='palette--c-neutral-6 m-0 my-2 my-md-0 ml-2'>
								- identifier {addedTitle}
							</p>
						)}
					</div>
					{!user?.name && (
						<ResourceLoader
							renderContent={(rl: ResourceLoader) =>
								this.renderUser(rl.state?.response?.name)
							}
							endpoint={`/users/${user}`}
							dontShowError={true}
							classNames='User'
						/>
					)}
					{!!user?.name && this.renderUser(user?.name)}
					<p className='ContributionTime palette--c-neutral-6 m-0 my-2 my-md-0'>
						{this.dataParser(
							format(
								new Date(item?.contribution?.timestamp),
								'yyyy. MM. dd'
							)
						)}
					</p>
				</div>
			</div>
		);
	}

	private dataParser(data): any {
		if (!data) {
			return '';
		}

		return data;
	}

	private renderUser(user): React.ReactElement {
		return (
			<div className='User display-flex align-items-center'>
				<span className='material-icons UserIcon palette--c-neutral-5'>
					person
				</span>
				<p className='palette--c-neutral-6 m-0 my-2 my-md-0'>
					{this.dataParser(user)}
				</p>
			</div>
		);
	}
}
