import { arrayToClass } from '../../../../../utilities/helper-fuctions';
import { get } from 'lodash';
import * as React from 'react';
import './map-marker.scss';

interface MapMarkerProps {
    src?: string;
    className?: string;
}

export class MapMarker extends React.Component<MapMarkerProps, any> {
    public render(): React.ReactElement {
        const className = arrayToClass(['MarkerIcon', this.props.className || '']);

        return <img src={get(this.props, 'src', '../../../assets/pins/searched/CIVIL.svg')} alt="map-pin" className={className} />;
    }
}
