import * as React from 'react';
import { toast } from 'react-toastify';
import {
	arrayToClass,
	randomId,
} from '../../../../../../utilities/helper-fuctions';
import { LocalStorage } from '../../../../../../utilities/local-storage';
import { Form } from '../../../../../ui-components/form/form';
import { HoverableButton } from '../../../../../ui-components/hoverable-buttons/hoverable-buttons';
import { Checkbox } from '../../../../../ui-components/inputs/checkboxes/checkbox/checkbox';
import { Spinner } from '../../../../../ui-components/spinner/spinner';
import { AtcAdditions } from '../libs/atc-additions';
import { Blocks } from '../libs/blocks';
import { CopyDropdown } from '../libs/copy-dropdown';

export class StepTwo extends React.Component<any, any> {
	public block: { [key: string]: Blocks } = {};
	public atc: AtcAdditions;

	public form = new Form({
		available: {
			value: false,
			trigger: () => this.handleUnchecks('available'),
		},
		unavailable: {
			value: false,
			trigger: () => this.handleUnchecks('unavailable'),
		},
		custom: { value: false, trigger: () => this.handleUnchecks('custom') },
	});

	public state = {
		atc: false,
		available: this.getData('available'),
		availableDays: this.props?.defaultDays || [],
		blocks: this.getData('data'),
		disabledDays: [],
		buttonDisabled: this.getData('button'),
	};

	public getData(type: any = false) {
		const all = LocalStorage.getItem('step-2') || false;

		if (type === 'data') {
			return all?.data || ['default'];
		} else if (type === 'button' && !!all) {
			return all?.button;
		} else if (type === 'available') {
			return all?.data?.available;
		}

		return all || true;
	}

	public setData(data) {
		LocalStorage.setItem('step-2', {
			...data,
			button: this.state?.buttonDisabled,
		});
	}

	public componentDidMount() {
		const stepTwoData = this.getData('data');

		if (Array.isArray(stepTwoData) && stepTwoData) {
			this.updateComponent();
		} else {
			this.setState({ blocks: ['default'] });
		}
	}

	public handleUnchecks(field): void {
		const value = this.form.getValue(field);

		if (value) {
			const fields = ['available', 'unavailable', 'custom'];
			const needToUncheck = fields.filter((f) => f !== field);

			(needToUncheck || []).map((f) => this.form.setValue(f, false));
			this.setState({ available: field }, () =>
				this.handleButtonStatusChange()
			);
		}
	}

	public updateComponent(): any {
		const data = this.getData('data') || [];

		const disabledUnits = data.map((stepOneD) => {
			const available = stepOneD?.data?.available;

			if (!!available) {
				this.setState({ available });
				this.form.setValue(available, true);
			}

			if (available === 'custom') {
				return stepOneD?.data?.scheduleUnits;
			}

			return [];
		});

		this.setState({ disabledDays: (disabledUnits || []).flat() || [] });
	}

	public render(): React.ReactElement {
		return (
			<form className='w-100'>
				<div className='p-4'>
					<div className='w-100 display-flex'>
						<h3 className='palette--c-neutral-6 fw-bold flex-fill'>
							Air Traffic Control service
						</h3>
						<CopyDropdown
							step='step-2'
							onChange={() => this.handleCopy()}
						/>
					</div>
					<div className='w-100 FormContainer my-4'>
						<Checkbox
							field={this.form.getField('available')}
							labelInfos={{ label: 'Available 24h every day' }}
							disabled={this.state?.available === 'available'}
							labelOnLeft={true}
						/>
						<Checkbox
							field={this.form.getField('unavailable')}
							labelInfos={{ label: 'Always unavailable' }}
							classes='px-0 px-md-2'
							disabled={this.state?.available === 'unavailable'}
							labelOnLeft={true}
						/>
						<Checkbox
							field={this.form.getField('custom')}
							labelInfos={{ label: 'Custom' }}
							disabled={this.state?.available === 'custom'}
							labelOnLeft={true}
						/>
					</div>
					{this.state.available === 'available' && (
						<AtcAdditions
							ref={(ref: any) => (this.atc = ref)}
							values={this.state.atc}
							open={true}
						/>
					)}
					{this.state.available === 'custom' && (
						<div className='w-100'>
							{this.renderBlocks()}
							{(this.state.disabledDays || []).length < 8 && (
								<HoverableButton
									colorType='avio-green'
									className='border-radius-1 mt-2'
									title='Add for other days'
									onClick={() => this.handleAdd()}
								/>
							)}
						</div>
					)}
				</div>

				{this.renderFooter()}
			</form>
		);
	}

	public handleAdd(): void {
		const newData = this.getValue();
		this.setData({ data: newData });
		this.setState({ blocks: [...newData, randomId()] });
	}

	public handleCopy(): void {
		this.setState({ blocks: this.getData('data') });
		this.updateComponent();
	}

	public renderBlocks(): React.ReactElement {
		return this.state.blocks.map((block) => {
			const key = block.key || block;

			return (
				<Blocks
					isDisabled={this.state.buttonDisabled}
					key={key}
					ref={(ref: any) => (this.block[key] = ref)}
					block={block.data || false}
					days={this.state.availableDays}
					disabledDays={this.state.disabledDays}
					updateDays={(days) => this.handleUpdate(days)}
					deleteBlock={(days) => this.handleDelete(days, block)}
					enableDeleteButton={(this.state?.blocks || []).length >= 2}
					changeButton={() => this.handleButtonStatusChange()}
					add='atc'
				/>
			);
		});
	}

	public handleButtonStatusChange() {
		const blocks = Object.keys(this.block);
		const blocksState = blocks.filter((block) => {
			if (this.block[block]) {
				const status = this.block[block].checkBlocks();
				return !!status;
			}

			return false;
		});

		if (
			!!(blocksState || []).length &&
			this.state.buttonDisabled !== true
		) {
			this.setState({ buttonDisabled: true });
		} else if (
			!(blocksState || []).length &&
			this.state.buttonDisabled !== false
		) {
			this.setState({ buttonDisabled: false });
		}
	}

	public handleUpdate(days): void {
		const newData = this.getValue();
		this.setData({ data: newData });
		this.setState({ disabledDays: days, blocks: newData });
	}

	public handleDelete(days: any, block: any): void {
		const needToPutBackDays = (this.state.disabledDays || []).filter(
			(d) => !days.includes(d)
		);
		const newData = (this.state.blocks || []).filter((b) => b !== block);

		this.setState({ blocks: newData, disabledDays: needToPutBackDays });
	}

	public renderFooter(): React.ReactElement {
		const isDisabled = this.state?.buttonDisabled;

		return (
			<div className='w-100 display-flex justify-content-end p-4'>
				<HoverableButton
					className='border-radius-1 mr-2'
					colorType='transparent-grey'
					onClick={() => this.handleSkip()}
					title='Skip'
				/>
				<HoverableButton
					className='border-radius-1 mr-2'
					colorType='cancel'
					onClick={() => this.handleBack()}
					title='Back'
				/>
				<HoverableButton
					id='twoLast'
					colorType={
						!isDisabled && !this.props?.loading
							? 'avio-green'
							: 'disabled'
					}
					disabled={isDisabled}
					className='border-radius-1 position-relative'
					onClick={() => this.handleNext()}
					renderChildren={(colors) =>
						this.renderButtonChildren(colors)
					}
				/>
			</div>
		);
	}

	public renderButtonChildren(colors) {
		if (this.props.loading) {
			return <Spinner size='small' className='position-absolute' />;
		} else {
			return (
				<div className='w-100 display-flex align-items-center'>
					<span className={arrayToClass(['mr-1', colors])}>Next</span>
					<span
						className={arrayToClass([
							'material-icons HoverableButtonIcons',
							colors,
						])}
					>
						arrow_forward
					</span>
				</div>
			);
		}
	}

	public async validateDatas(): Promise<any> {
		const blocksKeys = Object.keys(this.block);
		let error = false;

		for (const key of blocksKeys) {
			if (this.block[key]) {
				const validation = await this.block[key].validateBlocks();

				if (validation) {
					error = true;
				}
			}
		}

		return error;
	}

	public async handleNext(): Promise<any> {
		if (this.state.available === 'custom') {
			const validate: any = await this.validateDatas();

			if (validate) {
				return toast.error(
					'There is something wrong with the given times, please review them.',
					{ theme: 'light' }
				);
			}
		}

		const data = this.getValue();

		this.setData({ data: data });

		if (this.props.goForward) {
			this.props.goForward();
		}
	}

	public handleBack(): any {
		const data = this.getValue();
		this.setData({ data: data });

		if (this.props.goBack) {
			this.props.goBack();
		}
	}

	public handleSkip(): any {
		this.setData({ skipped: true });

		if (this.props.goForward) {
			this.props.goForward();
		}
	}

	public getValue(): any {
		const selected = this.state.available;

		if (selected === 'available' || selected === 'unavailable') {
			const hours = { validFrom: '00:00', validTo: '23:59' };
			const atc = this.atc && this.atc.getForm();

			return [
				{
					key: 'key',
					data: {
						scheduleUnits: (this.props.defaultDays || []).map(
							(day) => day.value
						),
						blocks: [
							{
								...hours,
								...atc,
								status:
									selected === 'available'
										? 'FULL'
										: 'CLOSED',
							},
						],
						available: selected,
					},
				},
			];
		}

		if (selected === 'custom') {
			return Object.keys(this.block)
				.map(
					(block: any) =>
						this.block[block] && {
							key: block,
							data: this.block[block].handleSubmit(),
						}
				)
				.filter((block) => !!block);
		}

		return {};
	}
}
